<form [id]="formId" [formGroup]="thirdPartyContactFormGroup" (submit)="submit()">
    <ng-container *ngIf="suggestedContacts?.length">
        <div class="radiobutton-primary position-relative">
            <input
                id="contactType-suggested"
                type="radio"
                value="suggested"
                name="contactType"
                class="d-none"
                [(ngModel)]="contactType"
                (ngModelChange)="contactTypeChanged($event)"
                [disabled]="!suggestedContacts.length"
                [ngModelOptions]="{ standalone: true }"
            />
            <label for="contactType-suggested">Select a directory profile contact</label>
        </div>
        <ng-container *ngIf="contactType === 'suggested'">
            <section class="contacts-section">
                <label>Contacts</label>
                <ngx-select
                    [items]="suggestedContacts"
                    [isFocused]="false"
                    placeholder="Select an existing contact"
                    [(ngModel)]="suggestedContact"
                    (ngModelChange)="setThirdPartyContactDetails($event)"
                    [ngModelOptions]="{ standalone: true }"
                />
            </section>
        </ng-container>
    </ng-container>
    <div class="radiobutton-primary position-relative">
        <input
            id="contactType-provideDetails"
            type="radio"
            value="provideDetails"
            name="contactType"
            class="d-none"
            [(ngModel)]="contactType"
            (ngModelChange)="contactTypeChanged($event)"
            [ngModelOptions]="{ standalone: true }"
        />
        <label for="contactType-provideDetails">Provide contact details</label>
    </div>
    <ng-container *ngIf="contactType === 'provideDetails'">
        <section class="provide-details-section">
            <div class="row">
                <div class="col">
                    <div class="form-group required">
                        <input
                            id="thirdPartyContactFirstName"
                            aria-label="First name"
                            type="text"
                            class="form-control"
                            formControlName="firstName"
                            placeholder="Enter first name"
                            required
                        />
                        <span class="text-danger" *ngIf="showFirstNameLengthError">Maximum Length: 255</span>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <div class="form-group required">
                        <input
                            id="thirdPartyContactLastName"
                            aria-label="Last name"
                            type="text"
                            class="form-control"
                            formControlName="lastName"
                            placeholder="Enter last name"
                            required
                        />
                        <span class="text-danger" *ngIf="showLastNameLengthError">Maximum Length: 255</span>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <div class="form-group required">
                        <input
                            id="thirdPartyContactEmail"
                            aria-label="Email address"
                            type="email"
                            class="form-control"
                            formControlName="email"
                            placeholder="Enter email address"
                            required
                        />
                        <span class="text-danger" *ngIf="showEmailLengthError">Maximum Length: 255</span>
                        <span class="text-danger" *ngIf="showInvalidEmailSyntaxError">
                            The username length is invalid. Maximum size for username 64 characters.
                        </span>
                    </div>
                </div>
            </div>
            <ng-content select="actions"></ng-content>
        </section>
    </ng-container>

    <ng-container *ngIf="primaryVendorContact?.email && includeRemoveOption">
        <div class="radiobutton-primary position-relative">
            <input
                id="contactType-removeContact"
                type="radio"
                value="removeContact"
                name="contactType"
                [(ngModel)]="contactType"
                (ngModelChange)="contactTypeChanged($event)"
                [ngModelOptions]="{ standalone: true }"
                class="d-none"
            />
            <label for="contactType-removeContact">Remove primary contact</label>
        </div>
        <ng-container *ngIf="contactType === 'removeContact'">
            <section class="remove-contact-section">
                We won’t be able to automatically follow up with the third party or reach out to the organization to
                collect the latest information.
            </section>
        </ng-container>
    </ng-container>
</form>
