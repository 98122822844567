export enum ErrorKey {
    DEFAULT = 'error',
    INTERNAL_ERROR = 'internal-error',
    VALIDATION_FAILED = 'validation-failed',
    TOKEN_EXPIRED = 'token-expired',
    RELATIONSHIP_MAX_TAGS = 'relationship-max-tags',
    RELATIONSHIP_TAG_ALREADY_LINKED = 'relationship-tag-already-linked',
    INVALID_LOGIN = 'invalid-login',
    ASSESSMENT_EXPIRED = 'assessment-expired',
    CANNOT_REGISTER_UNDER_EXISTING_CLIENT = 'cannot-register-under-existing-client',
}
