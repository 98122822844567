<div class="recommendations-container">
    @if (showHeading) {
        <h2 class="recommendations-heading" [ngClass]="{ 'mb-3': !vendorName }">Recommendations</h2>
        @if (!!vendorName) {
            <p>
                In order to reduce the risk of this relationship, we recommend that {{ vendorName }}
                address the following gaps in coverage:
            </p>
        }
    }
    @for (recommendationGroup of recommendationGroups | keyvalue; let first = $first; track $index) {
        <div class="row">
            <div class="col">
                <div class="row" [ngClass]="{ 'pt-2': !first }">
                    <div class="col">
                        <h2 class="group-heading">{{ recommendationGroup.key }} recommendations</h2>
                    </div>
                </div>
                @for (recommendation of recommendationGroup.value; let first = $first; track recommendation.id) {
                    <div class="row" [ngClass]="{ 'pt-2': !first }">
                        <div class="col">
                            <p class="mb-0" [innerHTML]="recommendation.description | safeHtml"></p>
                        </div>
                    </div>
                }
            </div>
        </div>
    }
</div>
