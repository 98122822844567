import { Action, createReducer, on } from '@ngrx/store';
import { clearSelectedTag, getTagByIdRequestSuccess, getTagsRequest, getTagsRequestSuccess } from './tag.actions';
import { initialState, TagsState } from './tag.state';

const reducer = createReducer(
    initialState,
    on(getTagsRequest, (state, { pagination }) => ({
        ...state,
        pagination: pagination || null,
        loading: true,
    })),

    on(getTagsRequestSuccess, (state, { tags, total }) => ({
        ...state,
        loading: false,
        pagination: state.pagination && {
            // Persist total only if pagination existed previously
            ...state.pagination,
            total,
        },
        tags,
    })),

    on(getTagByIdRequestSuccess, (state, { tag }) => ({
        ...state,
        selectedTag: tag,
    })),

    on(clearSelectedTag, (state) => ({
        ...state,
        selectedTag: null,
    })),
);

export const tagsReducer = (state: TagsState = initialState, action: Action): TagsState => reducer(state, action);
