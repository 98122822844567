import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subject } from 'rxjs';
import { VisoUser } from '../../../entities/viso-user';
import { RefSelectOption } from '../../../shared/model/select-options';
import { urlValidator } from '../../../shared/validators/url-validator';
import { Relationship } from '../../../entities/relationship';
import {
    RelationshipActions,
    updateRelationship,
    updateRelationshipSuccess,
} from '../redux/actions/relationship.actions';
import { Actions, ofType } from '@ngrx/effects';
import { delay, takeUntil, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { FormUtilsService } from '../../../shared/utils/form-utils.service';
import { noWhitespaceValidator } from '../../../shared/validators/whitespace-validator';

@Component({
    selector: 'app-relationship-details-form-modal',
    templateUrl: './relationship-details-form-modal.component.html',
    styleUrls: ['./relationship-details-form-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RelationshipDetailsFormModalComponent implements OnInit, OnDestroy {
    @Input()
    relationship?: Relationship;

    @Input()
    isVendorManagedByCurrentOrg: boolean;

    relationshipForm: UntypedFormGroup;
    subscribersSelectOptions$: Observable<RefSelectOption<VisoUser | number>[]>;
    private _unsub$ = new Subject<void>();

    RelationshipActions = RelationshipActions;

    constructor(
        private _fb: UntypedFormBuilder,
        private _store$: Store,
        private _actions$: Actions,
        private _activeModal: NgbActiveModal,
        private _router: Router,
        private _formUtils: FormUtilsService,
    ) {}

    get showVendorURLError(): boolean {
        const { errors } = this.relationshipForm.controls.url || {};
        return !!(errors?.pattern || errors?.maxlength);
    }

    get showVendorNameError(): boolean {
        return !!this.relationshipForm.controls.name.errors?.maxlength;
    }

    ngOnInit(): void {
        const { vendorName, description, vendorHomepageUrl } = this.relationship || {};
        this.relationshipForm = this._fb.group({
            name: [
                vendorName || '',
                Validators.compose([Validators.required, noWhitespaceValidator, Validators.maxLength(255)]),
            ],
            url: [vendorHomepageUrl || '', Validators.compose([urlValidator, Validators.maxLength(255)])],
            businessPurpose: [description || ''],
        });
        this.listenActions();
    }

    listenActions() {
        this._actions$
            .pipe(
                ofType(updateRelationshipSuccess),
                tap(() => this.close()),
                delay(250),
                takeUntil(this._unsub$),
            )
            .subscribe(({ relationship }) => {
                this._router.navigate([`requests/${relationship.id}`]);
            });
    }

    close(): void {
        this._activeModal.close();
    }

    ngOnDestroy(): void {
        this._unsub$.next();
    }

    update() {
        const {
            name: vendorName,
            url: vendorHomepageUrl,
            businessPurpose: description,
        } = this._formUtils.getCleanFormGroupValue(this.relationshipForm);
        this.relationship;
        this._store$.dispatch(
            updateRelationship({
                relationship: { ...this.relationship, vendorName, vendorHomepageUrl, description },
            }),
        );
    }
}
