<div class="content-heading">
    <div>
        Assessment Recommendations
        <small>Manage and update assessment recommendations</small>
    </div>
</div>
<div class="row">
    <div class="col-md-6">
        <mat-card>
            <mat-card-header>
                <mat-card-title>Refresh assessment recommendations</mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <form [formGroup]="assessmentRecommendationRefreshForm">
                    <p>Warning: This will update assessment recommendations for existing assessments</p>
                    <mat-form-field class="w-100">
                        <mat-label>Org</mat-label>
                        <mat-select formControlName="selectedOrg">
                            <mat-option [value]="{}">All Orgs</mat-option>
                            <mat-option *ngFor="let org of orgs$ | async" [value]="org">{{ org.name }}</mat-option>
                        </mat-select>
                        <mat-hint>Be careful, this can be dangerous</mat-hint>
                    </mat-form-field>
                </form>
            </mat-card-content>
            <mat-card-actions>
                <button
                    mat-button
                    visoButton="primary"
                    type="submit"
                    [disabled]="isSaving || assessmentRecommendationRefreshForm.invalid"
                    (click)="refreshAssessmentRecommendations()"
                >
                    Refresh
                </button>
            </mat-card-actions>
            <mat-card-footer>
                <mat-progress-bar *ngIf="isSaving" mode="indeterminate"></mat-progress-bar>
            </mat-card-footer>
        </mat-card>
    </div>
</div>
