<div class="modal-body">
    <button type="button" class="btn-close" data-dismiss="modal" aria-hidden="true" (click)="close()"></button>
    <app-assign-business-owner
        [businessOwnerFormGroup]="businessOwnerFormGroup"
        [businessUnits]="businessUnits$ | async"
        [contacts]="contacts$ | async"
        [currentUser]="currentAccount$ | async"
        [orgDomains]="orgDomains$ | async"
        (contactCreationOptionChange)="onContactCreationOptionChanged($event)"
    ></app-assign-business-owner>
</div>
<div class="modal-footer">
    <button mat-button visoButton="secondary" class="me-2" type="button" (click)="close()">Cancel</button>
    <button
        *ngIf="clientId$ | async as clientId"
        mat-button
        visoButton="primary"
        type="button"
        toggleDisableOnActions
        [disabled]="businessOwnerFormGroup.invalid"
        [disablingActions]="[BusinessOwnerActions.AssignRelationshipBusinessOwnerRequest]"
        [enablingActions]="[
            BusinessOwnerActions.AssignRelationshipBusinessOwnerRequestSuccess,
            BusinessOwnerActions.AssignRelationshipBusinessOwnerRequestFailed,
        ]"
        (click)="save(clientId)"
    >
        Save
    </button>
</div>
