import { AsyncPipe } from '@angular/common';
import { ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'cdrAsyncPipe', pure: false })
export class CdrAsyncPipe implements PipeTransform {
    private asyncPipe: AsyncPipe;
    constructor(private _cdr: ChangeDetectorRef) {
        this.asyncPipe = new AsyncPipe(this._cdr);
    }
    transform(value: any): any {
        return this.asyncPipe.transform(value);
    }

    ngOnDestroy() {
        this.asyncPipe.ngOnDestroy();
    }
}
