import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { UpdateAssessmentSummarySectionConfigRequest } from '../../../../shared/assessment-components/components/assessment-summary-config/model/assessment-summary-config-models';

export enum AssessmentSummaryManagementActions {
    UpdateAssessmentSummarySectionConfigRequest = '[Assessment Summary Management] Update Assessment Summary Section Config Request',
    UpdateAssessmentSummarySectionConfigRequestSuccess = '[Assessment Summary Management] Update Assessment Summary Section Config Request Success',
    UpdateAssessmentSummarySectionConfigRequestFailed = '[Assessment Summary Management] Update Assessment Summary Section Config Request Failed',

    GenerateExampleAssessmentSummaryRequest = '[Assessment Summary Management] Generate Example Assessment Summary Request',
    GenerateExampleAssessmentSummaryRequestSuccess = '[Assessment Summary Management] Generate Example Assessment Summary Request Success',
    GenerateExampleAssessmentSummaryRequestFailed = '[Assessment Summary Management] Generate Example Assessment Summary Request Failed',
}

export const updateAssessmentSummarySectionConfigRequest = createAction(
    AssessmentSummaryManagementActions.UpdateAssessmentSummarySectionConfigRequest,
    props<{ assessmentSummarySection: UpdateAssessmentSummarySectionConfigRequest }>(),
);

export const updateAssessmentSummarySectionConfigRequestSuccess = createAction(
    AssessmentSummaryManagementActions.UpdateAssessmentSummarySectionConfigRequestSuccess,
);

export const updateAssessmentSummarySectionConfigRequestFailed = createAction(
    AssessmentSummaryManagementActions.UpdateAssessmentSummarySectionConfigRequestFailed,
    props<{ error: HttpErrorResponse }>(),
);

export const generateExampleAssessmentSummaryRequest = createAction(
    AssessmentSummaryManagementActions.GenerateExampleAssessmentSummaryRequest,
);

export const generateExampleAssessmentSummaryRequestSuccess = createAction(
    AssessmentSummaryManagementActions.GenerateExampleAssessmentSummaryRequestSuccess,
    props<{ summary: string }>(),
);

export const generateExampleAssessmentSummaryRequestFailed = createAction(
    AssessmentSummaryManagementActions.GenerateExampleAssessmentSummaryRequestFailed,
    props<{ error: HttpErrorResponse }>(),
);
