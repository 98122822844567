<header class="content-heading">
    <section>
        Webhooks
        <small>Manage service integrations</small>
    </section>
</header>

<main>
    <section class="pb-4">
        <button mat-button visoButton="primary" (click)="addNewWebhook(manageWebhookModal)">Add Webhook</button>
    </section>

    <section>
        <ngx-datatable
            #webhooksTable
            class="bootstrap"
            [rows]="webhooks$ | async"
            [loadingIndicator]="loadingWebhooks"
            [columnMode]="'force'"
            [rowHeight]="35"
            [headerHeight]="30"
            [scrollbarV]="true"
            [sorts]="[{ prop: 'id', dir: 'desc' }]"
            [count]="webhooksCount$ | async"
        >
            <ngx-datatable-column name="Client" prop="orgName">
                <ng-template let-row="row" ngx-datatable-cell-template>{{ row.orgName }} </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Description" prop="description">
                <ng-template let-row="row" ngx-datatable-cell-template>{{ row.description }} </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Type" prop="name">
                <ng-template let-row="row" ngx-datatable-cell-template>{{ row.name }} </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [width]="100" [resizable]="false" [cellClass]="'p-0'">
                <ng-template let-row="row" ngx-datatable-cell-template>
                    <div class="actions d-flex justify-content-between">
                        <button
                            mat-button
                            visoButton="link"
                            class="ngx-dt-action persistent"
                            (click)="editWebhook(manageWebhookModal, row)"
                        >
                            EDIT
                        </button>
                        <button
                            mat-button
                            visoButton="link"
                            class="ngx-dt-action persistent"
                            (click)="showDeleteWebhookConfirmation(row)"
                        >
                            DELETE
                        </button>
                    </div>
                </ng-template>
            </ngx-datatable-column>
        </ngx-datatable>
    </section>
</main>

<ng-template #manageWebhookModal let-modal>
    <div class="modal-header">
        <div class="modal-title title-text">Add Webhook</div>
    </div>
    <div class="modal-body modal-text">
        <form name="webhooksForm" novalidate [formGroup]="webhooksForm">
            <section>
                <div class="form-group">
                    <label for="destinationUrl"><strong>CLIENT*</strong></label>
                    <select
                        id="destinationType"
                        class="form-control"
                        formControlName="orgId"
                        [disabled]="savingWebhook"
                    >
                        <option [ngValue]="null">Select an option</option>
                        <option [ngValue]="client.id" *ngFor="let client of clients$ | async">{{ client.name }}</option>
                    </select>
                </div>
                <div class="form-group">
                    <label for="destinationUrl"><strong>DESTINATION URL*</strong></label>
                    <input
                        id="destinationUrl"
                        type="text"
                        class="form-control"
                        formControlName="destinationUrl"
                        [disabled]="savingWebhook"
                    />
                </div>
                <div class="form-group">
                    <label for="destinationType"><strong>DESTINATION TYPE*</strong></label>
                    <select
                        id="destinationType"
                        class="form-control"
                        formControlName="destinationType"
                        [disabled]="savingWebhook"
                    >
                        <option [ngValue]="null">Select an option</option>
                        <option [ngValue]="type" *ngFor="let type of webhookTypes">{{ type }}</option>
                    </select>
                </div>
                <div class="form-group">
                    <label for="description">DESCRIPTION</label>
                    <textarea
                        id="description"
                        class="form-control"
                        formControlName="description"
                        [disabled]="savingWebhook"
                    ></textarea>
                </div>
            </section>
            <section>
                <mat-slide-toggle
                    formControlName="allEventsSelected"
                    (change)="toggleAllEvents($event)"
                    [disabled]="savingWebhook"
                    color="primary"
                    labelPosition="before"
                    >ALL EVENTS</mat-slide-toggle
                >

                <div class="mt-2">
                    <ng-container *ngFor="let webhookEvent of webhookEvents">
                        <div class="my-2">{{ webhookEvent.type }}</div>
                        <div class="d-flex justify-content-between flex-wrap">
                            <div *ngFor="let event of webhookEvent.events" class="event-item">
                                <mat-checkbox
                                    [checked]="event.selected"
                                    (change)="selectSingleEvent($event, event)"
                                    [disabled]="savingWebhook"
                                    color="primary"
                                    class="event-item-text"
                                    >{{ event.displayName }}</mat-checkbox
                                >
                            </div>
                        </div>
                    </ng-container>
                </div>
            </section>
        </form>
    </div>
    <div class="modal-footer d-flex justify-content-between">
        <button type="button" mat-button visoButton="warning" [disabled]="savingWebhook" (click)="modal.dismiss()">
            Cancel
        </button>
        <button
            type="button"
            mat-button
            visoButton="primary"
            [disabled]="!isWebhookValidForSubmission || savingWebhook"
            (click)="saveWebhook()"
        >
            {{ savingWebhook ? 'Saving...' : 'Submit' }}
        </button>
    </div>
</ng-template>
