import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Risk, RiskLabels } from '@entities/risk-assessment';
import {
    getRiskLevelFromScore,
    RelationshipRiskToleranceView,
    RiskTolerance,
    RiskToleranceLabels,
    UpdateRiskToleranceSettingsRequest,
} from '@entities/risk-tolerance';
import { RiskThresholdData } from '../../redux/risk-model-settings.selectors';
import { Subject } from 'rxjs';
import { OrgRiskConfig } from '../../../../admin/client-profile/client-profile.model';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { FormUtilsService } from '@shared/utils/form-utils.service';
import { updateRiskToleranceSettingsRequest } from '../../redux/risk-model-settings.actions';
import { Store } from '@ngrx/store';
import { RiskUtilsService } from '@shared/utils/risk-utils.service';
import { noWhitespaceValidator } from '@shared/validators/whitespace-validator';

interface RiskToleranceFormGroup {
    riskLevelDisplayNames: FormArray<FormGroup<RiskLevelFormGroup>>;
    riskToleranceLevel: FormControl<RiskTolerance>;
    selectedExampleRelationship: FormControl<RelationshipRiskToleranceView>;
}

interface RiskLevelFormGroup {
    riskLevel: FormControl<Risk>;
    displayName: FormControl<string>;
}

@Component({
    selector: 'app-risk-tolerance-form-dialog',
    templateUrl: './risk-tolerance-form-dialog.component.html',
    styleUrls: ['./risk-tolerance-form-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RiskToleranceFormDialogComponent implements OnInit, OnDestroy {
    @Input({ required: true })
    riskToleranceThresholds: Map<RiskTolerance, Map<Risk, RiskThresholdData>>;

    @Input({ required: true })
    relationshipRiskDistribution: Map<Risk, RelationshipRiskToleranceView[]>;

    @Input({ required: true })
    riskLevelDisplayNames: OrgRiskConfig[];

    @Input({ required: true })
    clientRiskTolerance: RiskTolerance;

    Risk = Risk;
    RiskLabels = RiskLabels;
    riskOrderReverse: Risk[] = [Risk.EXTREME, Risk.HIGH, Risk.MEDIUM, Risk.LOW, Risk.NO_CONTEXT];

    RiskTolerance = RiskTolerance;
    RiskToleranceLabels = RiskToleranceLabels;

    riskToleranceFormGroup: FormGroup<RiskToleranceFormGroup>;

    previewRiskLevelDisplayNames: OrgRiskConfig[] = [];

    filteredPreviewRelationshipRiskDistribution: RelationshipRiskToleranceView[];

    showReadMore: boolean = true;

    private _unsub$: Subject<void> = new Subject<void>();

    constructor(
        private _fb: FormBuilder,
        private _formUtils: FormUtilsService,
        private _store$: Store,
        private _riskUtilsService: RiskUtilsService,
    ) {}

    get getSelectedRiskTolerance() {
        return this.riskToleranceFormGroup.controls.riskToleranceLevel.value;
    }

    get getResidualRiskScorePercentage() {
        let residualRiskScore =
            this.riskToleranceFormGroup.controls.selectedExampleRelationship.value?.residualRiskScore;
        const riskScore = Math.round(residualRiskScore * 100);
        return residualRiskScore != null ? `${riskScore}%` : '-';
    }

    get getInherentRiskScorePercentage() {
        let inherentRiskScore =
            this.riskToleranceFormGroup.controls.selectedExampleRelationship.value?.inherentRiskScore;
        const riskScore = Math.round(inherentRiskScore * 100);
        return inherentRiskScore != null ? `${riskScore}%` : '-';
    }

    get getInherentRiskScore() {
        return this.riskToleranceFormGroup.controls.selectedExampleRelationship.value?.inherentRiskScore;
    }

    get getSelectedExampleInherentRiskLevel() {
        return this.riskToleranceFormGroup.controls.selectedExampleRelationship.value?.inherentRisk;
    }

    get getResidualRiskScore() {
        return this.riskToleranceFormGroup.controls.selectedExampleRelationship.value?.residualRiskScore;
    }

    get getSelectedExampleResidualRiskLevel() {
        return this.riskToleranceFormGroup.controls.selectedExampleRelationship.value?.residualRisk;
    }

    ngOnInit(): void {
        this.filteredPreviewRelationshipRiskDistribution = this.getSortedListOfExampleRelationships();
        this.previewRiskLevelDisplayNames = JSON.parse(JSON.stringify(this.riskLevelDisplayNames));

        this.riskToleranceFormGroup = this._fb.group({
            riskLevelDisplayNames: this._fb.array<FormGroup<RiskLevelFormGroup>>(
                this.riskOrderReverse.map((riskLevel) => {
                    const riskLabelName = RiskLabels[riskLevel];
                    const userUpdatedDisplayName = this.riskLevelDisplayNames.find(
                        (riskConfig) => riskConfig.riskLevel === riskLevel,
                    )?.displayName;
                    return this._fb.group({
                        riskLevel: this._fb.control(riskLevel, Validators.required),
                        displayName: this._fb.control(
                            userUpdatedDisplayName !== riskLabelName ? userUpdatedDisplayName : null,
                            [Validators.maxLength(45), noWhitespaceValidator],
                        ),
                    });
                }),
            ),
            riskToleranceLevel: this._fb.control(
                this.clientRiskTolerance || RiskTolerance.MINIMAL,
                Validators.required,
            ),
            selectedExampleRelationship: this._fb.control(null),
        });

        this.riskToleranceFormGroup.controls.riskLevelDisplayNames.valueChanges
            .pipe(takeUntil(this._unsub$))
            .subscribe((changedDisplayName) => {
                this.previewRiskLevelDisplayNames = changedDisplayName
                    .map((changed) => ({ ...changed, displayName: changed.displayName?.trim() }))
                    .filter(({ displayName }) => !!displayName?.length)
                    .map((changed) => ({ riskLevel: changed.riskLevel, displayName: changed.displayName }));
            });
    }

    getSortedListOfExampleRelationships(): RelationshipRiskToleranceView[] {
        return Array.from(this.relationshipRiskDistribution.values())
            .flatMap((arr) => arr)
            .sort((a, b) => (a.vendorName.toLowerCase() < b.vendorName.toLowerCase() ? -1 : 1));
    }

    filterExampleRelationships(): void {
        if (typeof this.riskToleranceFormGroup.controls.selectedExampleRelationship.value !== 'string') {
            return;
        }

        let options = this.getSortedListOfExampleRelationships();
        const filterValue = (
            this.riskToleranceFormGroup.controls.selectedExampleRelationship.value as string
        ).toLowerCase();
        this.filteredPreviewRelationshipRiskDistribution = options.filter((option) =>
            `${option.vendorName} (${option.businessUnitName})`.toLowerCase().includes(filterValue),
        );
    }

    getRiskToleranceSummary = (riskTolerance: RiskTolerance): string => {
        return this._riskUtilsService.getRiskToleranceSummary(riskTolerance);
    };

    getRiskToleranceDescription = (riskTolerance: RiskTolerance): string => {
        return this._riskUtilsService.getRiskToleranceDescription(riskTolerance);
    };

    onToleranceChanged(toleranceNumber: number) {
        let selectedTolerance;
        switch (toleranceNumber) {
            case 0:
                selectedTolerance = RiskTolerance.MINIMAL;
                break;
            case 1:
                selectedTolerance = RiskTolerance.MODERATE;
                break;
            case 2:
                selectedTolerance = RiskTolerance.SIGNIFICANT;
                break;
        }

        this.riskToleranceFormGroup.controls.riskToleranceLevel.setValue(selectedTolerance);
    }

    setInitialTolerance() {
        switch (this.clientRiskTolerance) {
            case RiskTolerance.MINIMAL:
                return 0;
            case RiskTolerance.MODERATE:
                return 1;
            case RiskTolerance.SIGNIFICANT:
                return 2;
        }
    }

    displayWithFn(option?: RelationshipRiskToleranceView): string | undefined {
        return option ? `${option.vendorName} (${option.businessUnitName})` : undefined;
    }

    resetAllRiskLabels() {
        this.riskOrderReverse.forEach((riskLevel) => {
            this.getRiskDisplayNameFormGroup(riskLevel).controls.displayName.setValue(null);
        });
    }

    getRiskDisplayNameFormGroup(riskLevel: Risk) {
        return this.riskToleranceFormGroup.controls.riskLevelDisplayNames.controls.find(
            (fe) => fe.controls.riskLevel.value === riskLevel,
        );
    }

    getRelationshipName(relationshipRiskToleranceView: RelationshipRiskToleranceView): string {
        return `${relationshipRiskToleranceView.vendorName} (${relationshipRiskToleranceView.businessUnitName})`;
    }

    getPreviewRiskLevelDisplayName(currentRiskLevel: Risk, riskScore: number) {
        if (riskScore === null || riskScore === undefined) {
            return null;
        }
        const riskLevelBasedOnSelectedTolerance = getRiskLevelFromScore(
            currentRiskLevel,
            riskScore,
            this.riskToleranceFormGroup.controls.riskToleranceLevel.value,
            this.riskToleranceThresholds,
        );
        return this.previewRiskLevelDisplayNames.find(
            (orgRiskConfig) => orgRiskConfig.riskLevel === riskLevelBasedOnSelectedTolerance,
        )?.displayName;
    }

    getExistingRiskLevelDisplayName(currentRiskLevel: Risk, riskScore: number) {
        if (riskScore === null || riskScore === undefined) {
            return null;
        }
        const riskLevelBasedOnSelectedTolerance = getRiskLevelFromScore(
            currentRiskLevel,
            riskScore,
            this.riskToleranceFormGroup.controls.riskToleranceLevel.value,
            this.riskToleranceThresholds,
        );
        return this.riskLevelDisplayNames.find(
            (orgRiskConfig) => orgRiskConfig.riskLevel === riskLevelBasedOnSelectedTolerance,
        )?.displayName;
    }

    updateRiskToleranceSettings(): void {
        this._store$.dispatch(
            updateRiskToleranceSettingsRequest({ request: this.getUpdateRiskToleranceSettingsPayload() }),
        );
    }

    private getUpdateRiskToleranceSettingsPayload(): UpdateRiskToleranceSettingsRequest {
        const { riskToleranceLevel, riskLevelDisplayNames } = this._formUtils.getCleanTypedFormGroupValue<
            FormGroup<RiskToleranceFormGroup>
        >(this.riskToleranceFormGroup, true);

        const updatedRiskLevelDisplayNames = this.riskLevelDisplayNames.map((riskConfig) => {
            const update = riskLevelDisplayNames.find(({ riskLevel }) => riskLevel === riskConfig.riskLevel);
            return {
                ...riskConfig,
                displayName: update?.displayName?.trim() || RiskLabels[riskConfig.riskLevel],
            };
        });

        return {
            riskToleranceLevel,
            riskLevelDisplayNames: updatedRiskLevelDisplayNames,
        };
    }

    ngOnDestroy(): void {
        this._unsub$.next();
    }
}
