import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import {
    deleteBrandingColorRequest,
    deleteBrandingColorRequestFailure,
    deleteBrandingColorRequestSuccess,
    deleteCompanyImagesRequest,
    deleteCompanyImagesRequestFailure,
    deleteCompanyImagesRequestSuccess,
    openBrandingColorConfirmationModal,
    openDeleteBrandingColorConfirmationModal,
    saveBrandingColorRequest,
    saveBrandingColorRequestFailure,
    saveBrandingColorRequestSuccess,
} from './branding.actions';
import { OrgService } from '@entities/org';
import { of } from 'rxjs';
import { MatConfirmDialogService } from '@shared/components/mat-confirm-dialog/mat-confirm-dialog.service';

@Injectable()
export class BrandingEffects {
    constructor(
        private _actions$: Actions,
        private _orgService: OrgService,
        private _matConfirmDialogService: MatConfirmDialogService,
    ) {}

    saveBrandingColorRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(saveBrandingColorRequest),
            switchMap(({ hex }) =>
                this._orgService.setBrandingColor(hex).pipe(
                    map(() => saveBrandingColorRequestSuccess({ hex })),
                    catchError(() => of(saveBrandingColorRequestFailure())),
                ),
            ),
        ),
    );

    deleteBrandingColorRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(deleteBrandingColorRequest),
            switchMap(() =>
                this._orgService.deleteBrandingColor().pipe(
                    map(() => deleteBrandingColorRequestSuccess()),
                    catchError(() => of(deleteBrandingColorRequestFailure())),
                ),
            ),
        ),
    );

    openDeleteBrandingColorConfirmationModal$ = createEffect(() =>
        this._actions$.pipe(
            ofType(openDeleteBrandingColorConfirmationModal),
            switchMap(() =>
                this._matConfirmDialogService
                    .confirm({
                        title: 'Are you sure you want to reset brand color?',
                        message:
                            'Resetting these changes returns your configuration to the VISO TRUST defaults.\n' +
                            '<strong>The reset is effective immediately</strong>',
                        confirmLabel: 'Save changes',
                    })
                    .pipe(
                        filter(Boolean),
                        map(() => deleteBrandingColorRequest()),
                    ),
            ),
        ),
    );

    openBrandingColorConfirmationModal$ = createEffect(() =>
        this._actions$.pipe(
            ofType(openBrandingColorConfirmationModal),
            switchMap(({ hex }) =>
                this._matConfirmDialogService
                    .confirm({
                        title: 'Are you sure you want to save brand color?',
                        message:
                            'Updates to your brand color will show to your vendors through the collection process and email communications from' +
                            ' VISO TRUST.\n<strong>The updates are effective immediately.</strong>',
                        confirmLabel: 'Save changes',
                    })
                    .pipe(
                        filter(Boolean),
                        map(() => saveBrandingColorRequest({ hex })),
                    ),
            ),
        ),
    );

    deleteCompanyImages$ = createEffect(() =>
        this._actions$.pipe(
            ofType(deleteCompanyImagesRequest),
            switchMap(() =>
                this._orgService.deleteCompanyImages().pipe(
                    map(() => deleteCompanyImagesRequestSuccess()),
                    catchError(() => of(deleteCompanyImagesRequestFailure())),
                ),
            ),
        ),
    );
}
