import { Component, forwardRef, Input, Provider } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TagColorEnum, TagColors } from '../../../entities/tag';

const COLOR_PICKER_VALUE_ACCESSOR_PROVIDER: Provider = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ColorPickerComponent),
    multi: true,
};

@Component({
    selector: 'app-color-picker',
    templateUrl: 'color-picker.component.html',
    styleUrls: ['color-picker.component.scss'],
    providers: [COLOR_PICKER_VALUE_ACCESSOR_PROVIDER],
})
export class ColorPickerComponent implements ControlValueAccessor {
    private _onChange: any;
    // @ts-ignore
    private _onTouched: any;
    value: unknown;
    disabled: boolean;

    @Input() size: 'small' | 'normal' | 'large' = 'normal';
    colorValues = TagColors;
    TagColorEnum = TagColorEnum;

    constructor() {}

    writeValue(value: any): void {
        this.value = value;
    }

    registerOnChange(fn: (_: any) => void): void {
        this._onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this._onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    selectColor(selectedColor: unknown) {
        this.value = selectedColor;
        this._onChange(selectedColor);
    }
}
