import { BaseEntity } from '../../shared';
import { QuestionnaireAnswer } from '../../entities/assessment';
import { AuditReport, AuditReportTypeCode } from '../audit-report';
import { AssuranceLevels } from '../relationship/models/security-control-domain';
import { StaticValidationType } from '../static-validation/static-validation.model';
import { Attestation } from '../attestation/attestation.model';
import { MLErrorType } from '@entities/rtp';

export enum ArtifactRtpValidationStatus {
    ANALYZING = 'ANALYZING',
    ANALYZED = 'ANALYZED',
    ERRORED = 'ERRORED',
}

export const enum ArtifactStatus {
    ACTIVE,
    INACTIVE,
}

export enum ArtifactType {
    FILE_ARTIFACT = 'file',
    QUESTIONNAIRE_ARTIFACT = 'questionnaire',
    EXTERNAL_QUESTIONNAIRE_TYPE = 'external-questionnaire',
    URL_ARTIFACT = 'url',
    ATTESTATION_ARTIFACT = 'attestation',
}

export enum FileArtifactType {
    EXPIRED = 'EXPIRED',
    AUDIT_REPORT = 'AUDIT_REPORT',
    ASSESSMENT = 'ASSESSMENT',
    ALTERNATIVE = 'ALTERNATIVE',
    SUPPLEMENTAL = 'SUPPLEMENTAL',
    PRIVACY = 'PRIVACY',
    CYBER_INSURANCE = 'CYBER_INSURANCE',
}

export enum FileArtifactTypeLabels {
    EXPIRED = 'Expired Artifacts',
    AUDIT_REPORT = 'Third party audits',
    ASSESSMENT = 'Penetration tests',
    CYBER_INSURANCE = 'Cyber insurance',
    PRIVACY = 'Privacy',
    ALTERNATIVE = 'Other attestations',
}

export enum ArtifactValidationStatus {
    NOT_VALIDATED = 'NOT_VALIDATED',
    IN_PROGRESS = 'IN_PROGRESS',
    COMPLETE = 'COMPLETE',
    NOT_REQUIRED = 'NOT_REQUIRED',
}

export enum ArtifactValidationStatusLabels {
    NOT_VALIDATED = 'Not Validated',
    IN_PROGRESS = 'In Progress',
    COMPLETE = 'Complete',
    NOT_REQUIRED = 'Not Required',
}

export enum ControlValidationStatus {
    PRESENT = 'PRESENT',
    DESCRIPTION_ONLY = 'DESCRIPTION_ONLY',
    NOT_PRESENT = 'NOT_PRESENT',
    NOT_APPLICABLE = 'NOT_APPLICABLE',
    UNVALIDATED = 'UNVALIDATED',
    MISSING_INFORMATION = 'MISSING_INFORMATION',
}

export enum ControlValidationStatusLabels {
    PRESENT = 'Present',
    DESCRIPTION_ONLY = 'Description Only',
    NOT_PRESENT = 'Not Present',
    NOT_APPLICABLE = 'Not Applicable',
    UNVALIDATED = 'No Information',
    MISSING_INFORMATION = 'Missing Information',
}

export enum ControlValidationDetectionType {
    NORMAL = 'NORMAL',
    SUBSERVICE = 'SUBSERVICE',
    SHARED_RESPONSIBILITY_MODEL = 'SHARED_RESPONSIBILITY_MODEL',
    EXCEPTION = 'EXCEPTION',
    CUEC = 'CUEC',
}

export enum ControlValidationDetectionTypeLabels {
    NORMAL = 'Normal',
    SUBSERVICE = 'Subservice',
    SHARED_RESPONSIBILITY_MODEL = 'Shared Responsibility Model',
    EXCEPTION = 'Exception',
    CUEC = 'CUEC',
}

export enum SourceType {
    AUDITOR = 'AUDITOR',
    INFERENCE = 'INFERENCE',
    HEURISTIC = 'HEURISTIC',
    IQR = 'IQR',
    RTP_INFERENCE = 'RTP_INFERENCE',
    RTP_IQR = 'RTP_IQR',
    RTP_GENERATED = 'RTP_GENERATED',
}

export enum DetectionSourceTypeDisplayName {
    AUDITOR = 'Human',
    HEURISTIC = 'Heuristic',
    INFERENCE = 'Inference',
    IQR = 'IQR',
    RTP_INFERENCE = 'RTP Inference',
    RTP_IQR = 'RTP IQR',
    RTP_GENERATED = 'RTP Generated',
}

export enum ArtifactOwnershipLevel {
    VISO = 'VISO',
    THIRD_PARTY = 'THIRD_PARTY',
    PRIVATE = 'PRIVATE',
    TRUST_SUBSCRIPTION = 'TRUST_SUBSCRIPTION',
    PUBLIC = 'PUBLIC',
}

export enum ArtifactOwnershipLevelLabels {
    VISO = 'Shareable with clients',
    THIRD_PARTY = 'Third party owned',
    PRIVATE = 'Private to client',
    TRUST_SUBSCRIPTION = 'Trust Subscription',
    PUBLIC = 'Public',
}

export enum AnswerStatus {
    PROCESSING = 'PROCESSING',
    ERROR = 'ERROR',
    COMPLETED = 'COMPLETED',
}

export enum AnswerStatusLabels {
    PROCESSING = 'Processing',
    ERROR = 'Error',
    COMPLETED = 'Completed',
}

export enum AssociationType {
    RELATIONSHIP = 'Relationship',
    ASSESSMENT = 'Assessment',
}

export enum URLArtifactConversionStatus {
    SUCCESS = 'SUCCESS',
    ABORTED = 'ABORTED',
    FAILED = 'FAILED',
}

export enum URLArtifactConversionStatusLabels {
    SUCCESS = 'Successful',
    ABORTED = 'Aborted',
    FAILED = 'Failure',
}

export interface Artifact extends BaseEntity {
    id?: number;
    type: ArtifactType;
    association?: AssociationType; // frontend only
    description?: string;
    createdDate?: any;
    updatedDate?: any;
    expirationDate?: any;
    status?: ArtifactStatus;
    orgId?: number;
    ownership?: ArtifactOwnership;
    creatorId?: number;
    creatorEmail?: string;
    orgControls?: BaseEntity[];
    validated?: boolean;
    validation?: ArtifactValidation;
    thirdPartyProvidedAuditType?: string;
    vendorAuditReport?: AuditReport;
    artifactTypeName?: string;
    fileName?: string;
    answers?: QuestionnaireAnswer[];
    validationResponseStatus?: ValidationResponseStatus;
    qOriginalArtifactId?: number;
    uploadedDate?: any;
    avStatus?: AntiVirusStatus;
}

export enum ValidationResponseStatus {
    NOT_RECEIVED = 'NOT_RECEIVED',
    PROCESSED = 'PROCESSED',
    ERROR = 'ERROR',
}

export enum QuestionnaireArtifactType {
    SUPPLEMENTAL = 'SUPPLEMENTAL',
    CLIENT = 'CLIENT',
}

export enum AntiVirusStatus {
    CLEAN = 'CLEAN',
    INFECTED = 'INFECTED',
    UNKNOWN = 'UNKNOWN',
}

export class FileArtifact implements Artifact {
    constructor(
        public id?: number,
        public artifactType?: FileArtifactType,
        public fileArtifactType?: FileArtifactType, // For Trust Portal Use
        public artifactTypeName?: string,
        public description?: string,
        public createdDate?: any,
        public updatedDate?: any,
        public expirationDate?: any,
        public status?: ArtifactStatus,
        public orgId?: number,
        public ownership?: ArtifactOwnership,
        public creatorId?: number,
        public creatorEmail?: string,
        public orgControls?: BaseEntity[],
        public validated?: boolean,
        public validation?: ArtifactValidation,
        public thirdPartyProvidedAuditType?: string,
        public vendorAuditReport?: AuditReport,
        public hasPassword?: boolean,
        public fileLocation?: string,
        public uploadedDate?: any,
        public canBeDeleted?: boolean,
        public fileName?: string,
        public avStatus?: AntiVirusStatus,
    ) {}

    type: ArtifactType = ArtifactType.FILE_ARTIFACT;
}

export class UrlArtifact extends FileArtifact {
    constructor(
        public url?: string,
        public conversionStatus?: URLArtifactConversionStatus,
    ) {
        super();
    }

    type: ArtifactType = ArtifactType.URL_ARTIFACT;
}

export class QuestionnaireArtifact implements Artifact {
    constructor(
        public id?: number,
        public artifactTypeName?: string,
        public description?: string,
        public createdDate?: any,
        public updatedDate?: any,
        public expirationDate?: any,
        public status?: ArtifactStatus,
        public creatorOrgId?: number,
        public ownership?: ArtifactOwnership,
        public creatorId?: number,
        public creatorEmail?: string,
        public orgControls?: BaseEntity[],
        public validated?: boolean,
        public validation?: ArtifactValidation,
        public questionnaireStatus?: string,
        public answers?: QuestionnaireAnswer[],
        public fileName?: string,
        public followup?: boolean,
        public supplemental?: boolean,
        public parsedExternalQuestionnaire?: boolean,
        public questionnaireType?: QuestionnaireType,
    ) {}

    type: ArtifactType = ArtifactType.QUESTIONNAIRE_ARTIFACT;
}

export interface TrustQuestionnaireFileArtifactResponse {
    id: number;
    createdDate: Date;
    updatedDate: Date;
    fileName: string;
    answerStatus: AnswerStatus;
    parsedQuestionnaireId: number;
    parsedQuestionCount: number;
    parsedAnswerCount: number;
    artifactTypeName: string;
}

export class ExternalQuestionnaireFileArtifact implements Artifact {
    constructor(
        public id?: number,
        public artifactType?: FileArtifactType,
        public fileArtifactType?: FileArtifactType, // For Trust Portal Use
        public artifactTypeName?: string,
        public description?: string,
        public createdDate?: any,
        public updatedDate?: any,
        public expirationDate?: any,
        public status?: ArtifactStatus,
        public orgId?: number,
        public ownership?: ArtifactOwnership,
        public creatorId?: number,
        public creatorEmail?: string,
        public orgControls?: BaseEntity[],
        public validated?: boolean,
        public validation?: ArtifactValidation,
        public thirdPartyProvidedAuditType?: string,
        public vendorAuditReport?: AuditReport,
        public hasPassword?: boolean,
        public fileLocation?: string,
        public uploadedDate?: any,
        public canBeDeleted?: boolean,
        public fileName?: string,
        public answerStatus?: AnswerStatus,
    ) {}

    type: ArtifactType = ArtifactType.EXTERNAL_QUESTIONNAIRE_TYPE;
}

export class AttestationArtifact implements Artifact {
    constructor(
        public id?: number,
        public artifactTypeName?: string,
        public description?: string,
        public createdDate?: any,
        public updatedDate?: any,
        public expirationDate?: any,
        public status?: ArtifactStatus,
        public creatorOrgId?: number,
        public ownership?: ArtifactOwnership,
        public creatorId?: number,
        public creatorEmail?: string,
        public validated?: boolean,
        public validation?: ArtifactValidation,
        public attestations?: Attestation[],
        public fileName?: string,
    ) {}

    type: ArtifactType = ArtifactType.ATTESTATION_ARTIFACT;
}

export class ArtifactValidation implements BaseEntity {
    constructor(
        public id?: number,
        public createdDate?: any,
        public artifactId?: number,
        public artifactType?: string,
        public auditReportId?: number,
        public auditReportType?: AuditReportTypeCode,
        public auditReportCanExpire?: boolean,
        public validationMessage?: string,
        public validatedById?: number,
        public validatedByEmail?: string,
        public status?: ArtifactValidationStatus,
        public expirationDate?: any,
        public expiringSoon?: boolean,
        public expired?: boolean,
        public publishedDate?: any,
        public detectedControls?: ControlValidation[],
        public subservicer?: boolean,
        public subservicerName?: string,
        public subservicerId?: number,
        public subservicerHomepage?: string,
        public subservicerFaviconUrl?: string,
        public auditingBodyName?: string,
        public auditingBodyId?: number,
        public auditingBodyHomepage?: string,
        public auditingBodyFaviconUrl?: string,
        public customControls?: boolean,
        public artifactFileName?: string,
        public relationshipId?: string,
        public auditReportAssuranceLevel?: AssuranceLevels,
        public auditReportAssurance?: number,
        public auditReportGroupName?: string,
        public auditReportGroupRank?: number,
        public isHumanReviewed?: boolean,
        public staticValidationType?: StaticValidationType,
        public rtpStatus?: ArtifactRtpValidationStatus,
        public mlErrorType?: MLErrorType,
    ) {}
}

export class ControlValidation implements BaseEntity {
    constructor(
        public id?: number,
        public controlDomainId?: number,
        public controlId?: number,
        public artifactValidationId?: number,
        public controlValidationStatus?: ControlValidationStatus,
        public sourceType?: SourceType,
        public serviceVersion?: string,
        public createdDate?: any,
        public updatedDate?: any,
        public validationDetections?: ControlValidationDetection[],
        public controlName?: string,
        public lastValidatedById?: number,
        public markForDelete?: boolean, //frontend only
    ) {}
}

export interface ControlValidationDetectionSubservicer {
    subservicerName?: string;
    subservicerId?: number;
    subservicerHomepage?: string;
    subservicerFaviconUrl?: string;
}

export class ControlValidationDetection implements BaseEntity {
    constructor(
        public id?: number,
        public controlId?: number,
        public controlDomainId?: number,
        public artifactId?: number,
        public subservicers?: ControlValidationDetectionSubservicer[],
        public controlValidationId?: number,
        public notes?: string,
        public auditorComment?: string,
        public sectionName?: string,
        public viewerPage?: string,
        public controlIdsText?: string,
        public createdBy?: string,
        public updatedBy?: string,
        public createdDate?: any,
        public updatedDate?: any,
        public serviceVersion?: string,
        public type?: ControlValidationDetectionType,
        public sourceType?: SourceType,
        public confidence?: number,
        public managementResponse?: string,
        public controlAssigned?: number,
        public controlDomainAssigned?: number,
        public isControlAssignedDomainLevel?: boolean,
        public referencedArtifactId?: number,
    ) {}
}

export interface ArtifactOwnership {
    artifactId: number;
    createdById: number;
    createdDate: string;
    id: number;
    ownershipType: ArtifactOwnershipLevel;
    source?: string;
    thirdPartyEmail?: string;
    updatedById?: number;
    updatedDate: string;
}

export interface ArtifactMetadata {
    artifactType?: FileArtifactType;
    vendorAuditReportType?: AuditReportTypeCode;
    artifactThirdPartyProvidedAuditType?: string;
    artifactPassword?: string;
    artifactOwnershipType?: ArtifactOwnershipLevel;
    source?: string;
    thirdPartyEmail?: string;
    artifactIdToSupersede?: number;
}

export interface URLArtifactMetadata
    extends Omit<ArtifactMetadata, 'artifactPassword' | 'artifactThirdPartyProvidedAuditType'> {}

export interface UploadFileArtifactRequest {
    file: File;
    fileArtifactMetaData: ArtifactMetadata;
}

export interface UploadURLArtifactRequest {
    url: string;
    metaData: URLArtifactMetadata;
}

export interface ArtifactUploadResponse {
    successfullyUploadedArtifacts: Artifact[];
    duplicateArtifactFileNames: string[];
}

export interface Entity {
    label: string;
    text: string;
    spanStart: number;
    spanEnd: number;
    pageNumber: number;
}

export interface NamedEntities {
    createdAt: any;
    textractServiceVersion: string;
    messageVersion: string;
    name: string;
    documentPageCount: number;
    entityPages: number[];
    entities: Entity[];
}

export interface EntityCount {
    text: string;
    label: string;
    count: number;
}

export interface NamedEntityCounts {
    createdAt: any;
    textractServiceVersion: string;
    messageVersion: string;
    name: string;
    documentPageCount: number;
    entityCounts: EntityCount[];
}

export interface ArtifactIntelligence {
    namedEntities: NamedEntities;
    namedEntityCounts: NamedEntityCounts;
}

export interface SignedURL {
    url: URL;
    expiration: any;
}

export class ArtifactMetadataFile {
    static readonly CONTROL_VALIDATION_CANDIDATES = new ArtifactMetadataFile(
        'CONTROL_VALIDATION_CANDIDATES',
        'control_validation_candidates.json',
        true,
    );
    static readonly NAMED_ENTITIES = new ArtifactMetadataFile('NAMED_ENTITIES', 'named_entities.json', true);
    static readonly NAMED_ENTITY_COUNTS = new ArtifactMetadataFile(
        'NAMED_ENTITY_COUNTS',
        'named_entity_counts.json',
        true,
    );
    static readonly DECRYPTED_PDF = new ArtifactMetadataFile('DECRYPTED_PDF', '_decrypted.pdf', false);
    static readonly DOCUMENT_EXCEPTIONS = new ArtifactMetadataFile(
        'DOCUMENT_EXCEPTIONS',
        'document_exceptions.txt',
        true,
    );
    static readonly CONTROL_PREDICTIONS = new ArtifactMetadataFile(
        'CONTROL_PREDICTIONS',
        'control_predictions.txt',
        true,
    );
    static readonly VALIDATION_RESULT = new ArtifactMetadataFile(
        'VALIDATION_RESULT',
        '_artifact_validation_result.csv',
        false,
    );

    private constructor(
        public readonly enumName: string,
        public readonly fileName: string,
        public readonly isFile: boolean,
    ) {}
}

export interface ArtifactPasswordResponse {
    artifactId: number;
    id: string;
    password: string;
}

export interface PageRangeLink {
    initialPage: number;
    finalPage?: number;
}

export type ArtifactFilePageLink = PageRangeLink | number | string;

export interface SupplementalQuestionnaireResponse {
    originalQuestionnaireId: number;
    createdDate: Date;
    fileName: string;
    parsedQuestionnaireId: number;
    parsedQuestionCount: number;
    validationResponseStatus: ValidationResponseStatus;
    includeInCollectionRequest: boolean;
    answerAutomatically: boolean;
    manualReviewNeeded: boolean;
}

export interface OpenArtifactFileParams {
    artifactId: number;
    artifactName: string;
    selectedDetectionId?: number;
    viewerPage?: ArtifactFilePageLink;
}

export enum QuestionnaireType {
    VISO = 'VISO',
    SUPPLEMENTAL = 'SUPPLEMENTAL',
    TRUST = 'TRUST',
}

export interface QuestionnaireEvent {
    externalQuestionnaireId: number;
    questionnaireType: QuestionnaireType;
    clientId: number;
    parsedQuestionnaireId: number;
    parsedDate?: Date;
    questionsParsed?: number;
    answerStatus: AnswerStatus;
    eventType: QuestionnaireEventType;
}

export enum QuestionnaireEventType {
    QUESTIONS_PARSED = 'QUESTIONS_PARSED',
    QUESTION_ANSWERED = 'QUESTION_ANSWERED',
    ANSWERING_COMPLETED = 'ANSWERING_COMPLETED',
}
