import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { RelationshipComment } from './relationship-comment.model';

export type EntityResponseType = HttpResponse<RelationshipComment>;

@Injectable({
    providedIn: 'root',
})
export class RelationshipCommentService {
    private resourceUrl = 'api/relationship-comments';

    constructor(private http: HttpClient) {}

    delete(id: number): Observable<HttpResponse<any>> {
        return this.http.delete<any>(`${this.resourceUrl}/${id}`, { observe: 'response' });
    }
}
