import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { AssuranceLevelMeterComponent } from './components';

@NgModule({
    declarations: [AssuranceLevelMeterComponent],
    imports: [CommonModule, NgbTooltipModule],
    exports: [AssuranceLevelMeterComponent],
})
export class AssuranceLevelMeterModule {}
