import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { IntegrationsService } from '../integrations.service';
import { of } from 'rxjs';
import { tap, catchError, map, switchMap, distinctUntilChanged, filter } from 'rxjs/operators';
import { NgbModalWrapperService } from '../../../shared/modal/ngb-modal-wrapper.service';
import { CoupaIntegrationDialogComponent } from '../coupa/coupa-integration-dialog.component';

import {
    putCoupaIntegrationRequest,
    putCoupaIntegrationRequestSuccess,
    putCoupaIntegrationRequestFailed,
    deleteCoupaIntegrationRequest,
    deleteCoupaIntegrationRequestSuccess,
    deleteCoupaIntegrationRequestFailed,
    syncCoupaIntegrationRequest,
    syncCoupaIntegrationRequestSuccess,
    syncCoupaIntegrationRequestFailed,
    getConnectedIntegrationsRequest,
    getConnectedIntegrationsRequestFailed,
    getConnectedIntegrationsRequestSuccess,
    getInterestedIntegrationsRequest,
    getInterestedIntegrationsRequestSuccess,
    getInterestedIntegrationsRequestFailed,
    createInterestedIntegrationRequest,
    createInterestedIntegrationRequestSuccess,
    createInterestedIntegrationRequestFailed,
} from './integrations.actions';
import { ROUTER_NAVIGATED, RouterNavigationAction } from '@ngrx/router-store';
import { ConfirmDialogService } from '../../../shared/components/confirm-dialog/confirm-dialog.service';

@Injectable()
export class IntegrationsEffects {
    constructor(
        private _actions$: Actions,
        private _integrationsService: IntegrationsService,
        private _modalService: NgbModalWrapperService,
        private _confirmDialogService: ConfirmDialogService,
    ) {}

    putCoupaIntegration$ = createEffect(() =>
        this._actions$.pipe(
            ofType(putCoupaIntegrationRequest),
            switchMap(({ config }) =>
                this._integrationsService.putCoupaIntegration(config).pipe(
                    map(({ body }) => putCoupaIntegrationRequestSuccess({ config: body! })),
                    catchError(() => of(putCoupaIntegrationRequestFailed())),
                ),
            ),
        ),
    );

    deleteCoupaIntegration$ = createEffect(() =>
        this._actions$.pipe(
            ofType(deleteCoupaIntegrationRequest),
            switchMap(() =>
                this._confirmDialogService
                    .confirm({
                        title: 'Disconnect Coupa',
                        message: 'Are you sure you want to disconnect your Coupa integration?',
                        confirmLabel: 'Yes, do it!',
                    })
                    .pipe(
                        filter((result) => result),
                        switchMap(() =>
                            this._integrationsService.deleteCoupaIntegration().pipe(
                                map(() => deleteCoupaIntegrationRequestSuccess()),
                                catchError(() => of(deleteCoupaIntegrationRequestFailed())),
                            ),
                        ),
                    ),
            ),
        ),
    );

    syncCoupaIntegration$ = createEffect(() =>
        this._actions$.pipe(
            ofType(syncCoupaIntegrationRequest),
            switchMap(() =>
                this._integrationsService.syncCoupaIntegration().pipe(
                    map(({ body }) => syncCoupaIntegrationRequestSuccess({ resp: body! })),
                    catchError(() => of(syncCoupaIntegrationRequestFailed())),
                ),
            ),
        ),
    );

    openCoupaIntegrationModal$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(ROUTER_NAVIGATED),
                map((action: RouterNavigationAction) =>
                    action.payload.routerState.root.children.find((route) => route.outlet === 'popup'),
                ),
                distinctUntilChanged((prev, next) => prev?.routeConfig.path === next?.routeConfig.path),
                filter((route) => !!route && route.routeConfig.path === 'coupa-integration'),
                switchMap(() =>
                    this._integrationsService.getCoupaIntegration().pipe(
                        map(({ body }) => body),
                        catchError(() => of(null)),
                    ),
                ),
                tap((config) =>
                    this._modalService.open<CoupaIntegrationDialogComponent>(
                        CoupaIntegrationDialogComponent,
                        { config },
                        { size: 'md', backdrop: 'static' },
                    ),
                ),
            ),
        { dispatch: false },
    );

    getConnectedIntegrations$ = createEffect(() =>
        this._actions$.pipe(
            ofType(getConnectedIntegrationsRequest),
            switchMap(() =>
                this._integrationsService.getConnectedIntegrations().pipe(
                    map(({ body }) => getConnectedIntegrationsRequestSuccess({ connectedIntegrations: body })),
                    catchError(() => of(getConnectedIntegrationsRequestFailed())),
                ),
            ),
        ),
    );

    getInterestedIntegrations$ = createEffect(() =>
        this._actions$.pipe(
            ofType(getInterestedIntegrationsRequest),
            switchMap(() =>
                this._integrationsService.getInterestedIntegrations().pipe(
                    map(({ body }) => getInterestedIntegrationsRequestSuccess({ interestedIntegrations: body })),
                    catchError(() => of(getInterestedIntegrationsRequestFailed())),
                ),
            ),
        ),
    );

    createInterestedIntegration$ = createEffect(() =>
        this._actions$.pipe(
            ofType(createInterestedIntegrationRequest),
            switchMap(({ integration }) =>
                this._integrationsService.createInterestedIntegration(integration).pipe(
                    map(() => createInterestedIntegrationRequestSuccess()),
                    catchError(() => of(createInterestedIntegrationRequestFailed())),
                ),
            ),
        ),
    );
}
