import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { map, shareReplay, takeUntil, tap } from 'rxjs/operators';
import { BusinessUnit } from '../../entities/business-unit';
import {
    getBusinessUnitsRequest,
    getBusinessUnitsRequestSuccess,
    reassignBusinessUnitRequest,
    reassignBusinessUnitRequestFailed,
    reassignBusinessUnitRequestSuccess,
} from '../business-unit-management/redux/business-unit-management.actions';
import { RefSelectOption } from '../../shared/model/select-options';

@Component({
    selector: 'app-business-unit-reassign-modal',
    templateUrl: './business-unit-reassign-modal.component.html',
    styleUrls: [],
})
export class BusinessUnitReassignModalComponent implements OnInit, OnDestroy {
    @Input()
    businessUnitId: number;

    businessUnit: BusinessUnit;

    businessUnits$: Observable<BusinessUnit[]>;
    businessUnitSelectOptions$: Observable<RefSelectOption<BusinessUnit | number>[]>;

    isSaving: boolean;

    businessUnitReassignFormGroup: UntypedFormGroup;

    private _unsub = new Subject<void>();

    constructor(
        private _activeModal: NgbActiveModal,
        private _store$: Store,
        private _actions$: Actions,
        private _router: Router,
        private _fb: UntypedFormBuilder,
    ) {}

    get businessUnitEditPopup(): any[] {
        return [
            '/',
            {
                outlets: {
                    popup: `business-unit/${this.businessUnitId}/edit`,
                },
            },
        ];
    }

    ngOnInit() {
        this.businessUnitReassignFormGroup = this._fb.group({
            reassignFromId: [''],
            reassignFromName: [''],
            reassignToId: ['', Validators.required],
        });

        this.loadBusinessUnits();

        this.businessUnits$ = this._actions$.pipe(
            ofType(getBusinessUnitsRequestSuccess),
            map(({ businessUnits }) => businessUnits),
            shareReplay(1),
        );

        this._actions$
            .pipe(
                ofType(getBusinessUnitsRequestSuccess),
                map(({ businessUnits }) => {
                    businessUnits
                        .filter((bu: BusinessUnit) => bu.id.toString() === this.businessUnitId.toString())
                        .map((businessUnit) => {
                            this.businessUnit = businessUnit;
                            this.businessUnitReassignFormGroup.controls.reassignFromName.setValue(businessUnit.name);
                        });
                }),
                takeUntil(this._unsub),
            )
            .subscribe();

        this._actions$
            .pipe(
                ofType(reassignBusinessUnitRequestSuccess),
                tap(() => {
                    this.isSaving = false;
                    this._activeModal.dismiss('success');
                }),
                takeUntil(this._unsub),
            )
            .subscribe();

        this._actions$
            .pipe(
                ofType(reassignBusinessUnitRequestFailed),
                tap(() => (this.isSaving = false)),
                takeUntil(this._unsub),
            )
            .subscribe();

        this.businessUnitSelectOptions$ = this.businessUnits$.pipe(
            map((businessUnits) =>
                businessUnits.map<RefSelectOption<BusinessUnit>>((businessUnit) => ({
                    ref: businessUnit,
                    name: `${businessUnit.name}`,
                    id: `${businessUnit.id}`,
                })),
            ),
            map((selectOptions) => {
                let businessUnitList = [...selectOptions.filter((sub) => sub.ref.id !== this.businessUnit.id)];

                businessUnitList = businessUnitList.sort((a, b) =>
                    a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1,
                );

                return businessUnitList;
            }),
        );
    }

    loadBusinessUnits() {
        this._store$.dispatch(getBusinessUnitsRequest(null));
    }

    clear() {
        this._activeModal.dismiss('cancel');
    }

    goBack() {
        this.clear();
        setTimeout(() => this._router.navigate(this.businessUnitEditPopup), 50);
    }

    reassign() {
        this.isSaving = true;
        this._store$.dispatch(
            reassignBusinessUnitRequest({
                fromBusinessUnitId: this.businessUnitId,
                toBusinessUnitId: this.businessUnitReassignFormGroup.controls.reassignToId.value,
            }),
        );
    }

    ngOnDestroy(): void {
        this._unsub.next();
    }
}
