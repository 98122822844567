import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { SubdomainResponse } from './org-subdomain.model';

export type EntityResponseType = HttpResponse<SubdomainResponse>;

@Injectable({
    providedIn: 'root',
})
export class OrgSubdomainService {
    private resourceUrl = 'api/subdomain';

    constructor(private _http: HttpClient) {}

    getSubdomain(): Observable<SubdomainResponse> {
        return this._http.get<SubdomainResponse>(`${this.resourceUrl}/`);
    }

    setSubdomain(subdomain: string): Observable<SubdomainResponse> {
        return this._http.post<SubdomainResponse>(`${this.resourceUrl}/`, { subdomain });
    }

    deleteSubdomain(): Observable<null> {
        return this._http.post<null>(`${this.resourceUrl}/`, { subdomain: null });
    }
}
