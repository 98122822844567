<div class="modal-header d-flex flex-column">
    <div class="d-flex w-100 align-items-center">
        <h4 class="modal-title">{{ title }}</h4>
        <button
            *ngIf="!onlyConfirmButton"
            type="button"
            class="btn-close"
            aria-label="Close"
            (click)="cancel()"
        ></button>
    </div>
    <p *ngIf="!!subtitle" class="w-100 mt-2 mb-0">{{ subtitle }}</p>
</div>

<div class="modal-body">
    <p class="mb-0">{{ message }}</p>
</div>

<div class="modal-footer">
    <button *ngIf="!onlyConfirmButton" mat-button visoButton="secondary" (click)="cancel()">{{ cancelLabel }}</button>
    <button mat-button visoButton="primary" (click)="confirm()" data-pw="modalConfirmationButton">
        {{ confirmLabel }}
    </button>
</div>
