import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmDialogOptions } from '../confirm-dialog.model';

@Component({
    selector: 'app-confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    styleUrls: ['./confirm-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmDialogComponent {
    @Input()
    options: ConfirmDialogOptions;

    context = {
        confirm: (confirmMessage?: string) => this.confirm(confirmMessage),
        cancel: () => this.cancel(),
    };

    constructor(private _activeModal: NgbActiveModal) {}

    get title(): string {
        return this.options?.title;
    }

    get subtitle(): string {
        return this.options?.subtitle;
    }

    get message(): string {
        return this.options?.message;
    }

    get confirmLabel(): string {
        return this.options?.confirmLabel || 'Accept';
    }

    get cancelLabel(): string {
        return this.options?.cancelLabel || 'Cancel';
    }

    get onlyConfirmButton(): boolean {
        return this.options?.onlyConfirmButton || false;
    }

    confirm(confirmMessage?: string): void {
        this._activeModal.close(confirmMessage || true);
    }

    cancel(): void {
        this._activeModal.close(false);
    }
}
