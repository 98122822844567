<div
    class="radiobutton-group-container"
    [ngClass]="{
        'row-direction': direction === 'row',
        outline: type === 'outline',
        toggle: type === 'toggle',
        'dynamic-option': size === 'dynamic',
    }"
>
    <ng-container *ngFor="let item of groupItemData; let i = index">
        <label
            class="form-check radiobutton-primary radiobutton-item mb-0 clickable"
            [ngClass]="{
                'col-direction': direction === 'column',
                'row-direction': direction === 'row',
                toggle: type === 'toggle',
                outline: type === 'outline',
                checked: !item.disabled && checked === item.value,
                disabled: item.disabled || disabled,
                clickable: !item.disabled && !disabled,
                'lg-option': size === 'large',
                'sm-option': size === 'small',
                'dynamic-option': size === 'dynamic',
            }"
        >
            <input
                class="form-check-input d-none"
                type="radio"
                [name]="radiobuttonGroupName"
                [id]="radiobuttonGroupName + i"
                [value]="item.value"
                (click)="checkRadioButton(item.value)"
                [disabled]="item.disabled || disabled"
                [checked]="!item.disabled && checked === item.value"
            />
            <ng-container
                *ngTemplateOutlet="item.templateRef; context: { item: item, forItem: radiobuttonGroupName + i }"
            ></ng-container>
        </label>
    </ng-container>
</div>
