import { Injectable } from '@angular/core';
import { VisoUser } from '../../entities/viso-user';
import { BaseMenu, LinkMenu, Menu } from '../../layout/sidebar/menu';
import { getCurrentEnvironment } from '../../shared/utils/environment-utils';

@Injectable()
export class MenuService {
    filterMenuByAccount(items: Menu[], visoUser: VisoUser): BaseMenu[] {
        return items
            .filter((menu) => {
                if (menu.roles) {
                    if (!visoUser) {
                        return menu.roles.length === 0;
                    }
                    // If there is a Role exclusion inside the menu Item role list like "!<ROLE_SOMETHING>"
                    // no matter if the other roles match, the menu item will be filtered out
                    const foundExcludeRole = menu.roles.some((e) => e.startsWith('!'));
                    if (foundExcludeRole) {
                        return !visoUser.authorities.some((role) => menu.roles.includes(`!${role}`));
                    } else {
                        return visoUser.authorities.some((role) => menu.roles.includes(role));
                    }
                } else {
                    return true;
                }
            })
            .filter((menu: LinkMenu) => {
                // Only display items in the environment specified in the Environments enum
                if (menu.environments) {
                    return menu.environments.includes(getCurrentEnvironment());
                } else {
                    return true;
                }
            }) as BaseMenu[];
    }
}
