import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { LinkNotification, NotificationStatus } from '../../../../entities/notification';

@Component({
    selector: 'app-link-notification',
    templateUrl: './link-notification.component.html',
    styleUrls: ['./link-notification.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LinkNotificationComponent {
    @Input()
    linkNotification: LinkNotification;

    @Output()
    markAsDismissed = new EventEmitter<void>();

    get isNew(): boolean {
        return this.linkNotification?.status === NotificationStatus.NEW;
    }

    get link(): string {
        return this.linkNotification?.url;
    }

    get anchorText(): string {
        return this.linkNotification?.anchorText;
    }

    get description(): string {
        return this.linkNotification?.description;
    }

    get humanizedCreatedDate(): string {
        return this.linkNotification?.humanizedCreatedDate;
    }
}
