import { ChangeDetectionStrategy, Component, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { ChartDataset, ChartOptions, Chart } from 'chart.js';
import { RiskAssessment } from '../../../entities/risk-assessment';
import { chartOptions } from '../graph-data-constants';
import { ColorUtilsService } from '../../../shared/utils/color-utils.service';
import { RiskUtilsService } from '../../../shared/utils/risk-utils.service';
import zoomPlugin from 'chartjs-plugin-zoom';
import 'chartjs-adapter-moment';
import { BaseChartDirective } from 'ng2-charts';

Chart.register(zoomPlugin);
@Component({
    selector: 'app-risk-timeline',
    templateUrl: './risk-timeline.component.html',
    styleUrls: ['./risk-timeline.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RiskTimelineComponent {
    @Input()
    riskAssessments: RiskAssessment[] = [];

    @Input()
    riskAssessmentsDatasets: ChartDataset[] = [];

    @Output()
    toggleTimelineView = new EventEmitter<void>();

    chartOptions: ChartOptions = {
        ...chartOptions,
        transitions: {
            zoom: {
                animation: {
                    duration: 1000,
                    easing: 'easeInCubic',
                },
            },
        },
        plugins: {
            zoom: {
                limits: {
                    y: {
                        min: 'original',
                        max: 'original',
                    },
                    x: {
                        min: 'original',
                        max: 'original',
                    },
                },
                zoom: {
                    drag: {
                        enabled: true,
                        modifierKey: 'shift',
                    },
                    wheel: {
                        enabled: true,
                        speed: 0.05,
                    },
                    mode: 'x',
                },
                pan: {
                    enabled: true,
                },
            },
            legend: {
                position: 'top',
                align: 'start',
            },
            tooltip: {
                callbacks: {
                    afterBody: (items) => {
                        const riskAssessment = this.riskAssessments[items[0].dataIndex];
                        return `Reason Detail: ${riskAssessment.reasonDetail}`;
                    },
                    labelColor: (context) => {
                        return { backgroundColor: this.getRiskLabelColor(context), borderColor: '#fff' };
                    },
                    label: function (context) {
                        let label = context.dataset.label;

                        if (label) {
                            label += ': ';
                        }
                        if (context.parsed.y !== null) {
                            label += new Intl.NumberFormat('en-US', {
                                style: 'percent',
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0,
                            }).format(context.parsed.y / 100);
                        }
                        return label;
                    },
                },
            },
        },
    };

    @ViewChild(BaseChartDirective)
    private _timeline: BaseChartDirective;

    constructor(
        private _colorsUtilsService: ColorUtilsService,
        private _riskUtilsService: RiskUtilsService,
    ) {}

    resetZoom() {
        this._timeline.chart.resetZoom();
    }

    private getRiskLabelColor(context): string {
        let riskColor = '#fff';
        let riskScore = context.parsed.y / 100;
        riskColor = this._colorsUtilsService.getRiskColor(this._riskUtilsService.getRiskLevelString(riskScore));
        return riskColor;
    }
}
