import { BaseEntity } from '../../shared';
import { RequestStatus } from '../relationship';
import { DataType } from '../data-type';
import { ContextType } from '../context-type';
import { ControlValidationDetection, ControlValidationStatus } from '../artifact';
import { BusinessUnit } from '../business-unit';
import { ControlDomainControlValidationDetectionWithArtifact } from '../relationship/models/security-control-domain';
import { ControlDomainType } from '../control-domain';

export enum RiskWithContext {
    LOW = 'LOW',
    MEDIUM = 'MEDIUM',
    HIGH = 'HIGH',
    EXTREME = 'EXTREME',
}

export enum Risk {
    NO_CONTEXT = 'NO_CONTEXT',
    LOW = 'LOW',
    MEDIUM = 'MEDIUM',
    HIGH = 'HIGH',
    EXTREME = 'EXTREME',
}

export enum RiskLabels {
    NO_CONTEXT = 'No Context',
    LOW = 'Low',
    MEDIUM = 'Medium',
    HIGH = 'High',
    EXTREME = 'Extreme',
}

export enum RiskLevelOrder {
    NO_CONTEXT = 0,
    LOW = 1,
    MEDIUM = 2,
    HIGH = 3,
    EXTREME = 4,
}

export class RiskAssessment implements BaseEntity {
    constructor(
        public id?: number,
        public score?: number,
        public risk?: Risk,
        public riskLevel?: Risk,
        public createdDate?: any,
        public status?: RequestStatus,
        public relationshipId?: number,
        public reasonCode?: string,
        public reasonDetail?: string,
        public inherentRisk?: Risk,
        public inherentRiskScore?: number,
        public relevantControlDomainCount?: number,
        public controlDomainsValidatedCount?: number,
        public controlDomainValidationPercentage?: number,
        public controlDomainsPresentCount?: number,
        public controlDomainPresencePercentage?: number,
        public legacy?: boolean,
        public transitional?: boolean,
        public vendorName?: string,
        public businessUnit?: BusinessUnit,
        public dataTypes?: DataType[],
        public contextTypes?: ContextType[],
        public controlDomainAssessments?: ControlDomainAssessment[],
        public riskScores?: Record<ControlDomainType, RiskScore>,
        public lastUserToSetLegacyEmail?: string,
        public lastUserToSetLegacyFirstName?: string,
        public lastUserToSetLegacyLastName?: string,
    ) {}
}

export type RiskScore = {
    residualRiskLevel: Risk;
    residualRiskScore: number;
};

export class ControlDomainAssessment implements BaseEntity {
    constructor(
        public id?: number,
        public riskAssessmentId?: number,
        public controlDomainId?: number,
        public coverage?: number,
        public weightedPresence?: number,
        public validated?: boolean,
        public relevant?: boolean,
        public status?: ControlValidationStatus,
        public controlAssessments?: ControlAssessment[],
        public validationDetections?: ControlValidationDetection[],
    ) {}
}

export class ControlAssessment implements BaseEntity {
    constructor(
        public id?: number,
        public controlDomainAssessmentId?: number,
        public controlId?: number,
        public controlName?: string,
        public weightedPresence?: number,
        public validated?: boolean,
        public relevant?: boolean,
        public auditReportId?: number,
        public status?: ControlValidationStatus,
        public auditAssurance?: number,
        public auditExpired?: boolean,
        public controlValidationId?: number,
        public detections?: ControlDomainControlValidationDetectionWithArtifact[],
    ) {}
}

export interface SlimRiskAssessment
    extends Pick<
        RiskAssessment,
        'id' | 'inherentRisk' | 'inherentRiskScore' | 'risk' | 'score' | 'legacy' | 'riskScores'
    > {}
