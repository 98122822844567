import { InjectionToken } from '@angular/core';
import { StoreConfig } from '@ngrx/store';
import { RelationshipFrameworkMappingsState } from './redux/request.state';

export const FRAMEWORK_MAPPINGS_CONFIG_TOKEN = new InjectionToken<StoreConfig<RelationshipFrameworkMappingsState>>(
    'Framework Mappings Config',
);

export const FRAMEWORK_MAPPINGS_STORAGE_KEYS = new InjectionToken<Array<keyof RelationshipFrameworkMappingsState>>(
    'FrameworkMappingsStorageKeys',
);

export const FRAMEWORK_MAPPINGS_LOCAL_STORAGE_KEY = new InjectionToken<string[]>('FrameworkMappingsStorage');
