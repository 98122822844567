import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ChartDataset } from 'chart.js';
import { RelationshipComment } from '../../../../entities/relationship-comment';
import { Artifact } from '../../../../entities/artifact';
import { Relationship } from '../../../../entities/relationship';
import { SecurityControlDomain } from '../../../../entities/relationship/models/security-control-domain';
import { PrimaryVendorContact } from '../../../primary-vendor-contact';
import { RiskAssessment } from '../../../../entities/risk-assessment';
import { VisoUser } from '../../../../entities/viso-user';
import { Attachment } from '../../../../entities/attachment';
import { Tag } from '../../../../entities/tag';
import { AssessmentStatus } from '../../../../entities/assessment';
import { DataTypeConfig, OrgAssessmentSummarySections } from '../../../../admin/client-profile/client-profile.model';
import { GroupedControlDomains } from '../../models';
import { PopulatedAssessment } from '../../models/populated-assessment';

@Component({
    selector: 'app-relationship-detail-page-export',
    templateUrl: './relationship-detail-page-export.component.html',
    styleUrls: ['./relationship-detail-page-export.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RelationshipDetailPageExportComponent {
    @Input({ required: true })
    request: Relationship;

    @Input({ required: true })
    relationshipSubscribers: VisoUser[];

    @Input({ required: true })
    primaryVendorContact: PrimaryVendorContact;

    @Input({ required: true })
    businessOwner: VisoUser;

    @Input({ required: true })
    latestRiskAssessment: RiskAssessment;

    @Input({ required: true })
    latestNonTransitionalRiskAssessment: RiskAssessment;

    @Input({ required: true })
    latestCompletedAssessmentDate: Date;

    @Input({ required: true })
    artifacts: Artifact[];

    @Input({ required: true })
    attachments: Attachment[];

    @Input({ required: true })
    riskAssessments: RiskAssessment[];

    @Input({ required: true })
    riskAssessmentsDatasets: ChartDataset[];

    @Input({ required: true })
    securityControlDomain: SecurityControlDomain;

    @Input({ required: true })
    groupedControlDomains: GroupedControlDomains[];

    @Input({ required: true })
    latestAssessment: PopulatedAssessment;

    @Input({ required: true })
    assessmentSummarySectionConfig: OrgAssessmentSummarySections[];

    @Input({ required: true })
    latestAssessmentStatus: AssessmentStatus;

    @Input({ required: true })
    comments: RelationshipComment[];

    @Input({ required: true })
    currentAccount: VisoUser;

    @Input({ required: true })
    userIsTrial: boolean;

    @Input({ required: true })
    dataTypesProfileConfig: DataTypeConfig[];

    @Input({ required: true })
    latestAssessmentFollowUpQuestionnaireCreatedDate: Date;

    @Input({ required: true })
    missingInformationWasRequested: boolean;

    @Input({ required: true })
    pastAssessments: PopulatedAssessment[];

    @Input({ required: true })
    isOnlyLegacyAssessmentPresent: boolean;

    @Input({ required: true })
    isUpForRecertification: boolean;

    @Input({ required: true })
    isRecertificationOverdue: boolean;

    @Input({ required: true })
    isAssessmentInProgress: boolean;

    @Input({ required: true })
    recertificationDueDaysLeftCount: number;

    @Input({ required: true })
    isLatestAssessmentNonDocumentsOnly: boolean;

    @Input({ required: true })
    tags: Tag[];

    @Input({ required: true })
    artifactSupersession: Map<number, number>;

    @Output()
    quillViewsCreated = new EventEmitter<void>();
}
