import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime, filter, map, takeUntil, throttleTime } from 'rxjs/operators';
import { Edge, Network, Node, NodeOptions, Options } from 'vis-network';
import { DataInterface, DataSet, DataView } from 'vis-data';
import { VisoUser } from '@entities/viso-user';
import { RiskNetworkGraphNode } from '../models/risk-network-graph-node';
import { CompleteVendorSearchResult } from '@shared/vendor-components/models/vendor-search-result';
import { Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import { getVendorByIdRequest, getVendorByIdRequestSuccess } from '../redux/relationships.actions';
import { addRelationshipWithVendor } from '../../vendor-directory/redux/vendor-directory.actions';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

interface NodesAndEdges {
    nodes: Node[];
    edges: EdgeWithRelation[];
}

interface EdgeWithRelation extends Edge {
    relation: EdgeRelations;
}

enum NodeGroups {
    Org = 'org',
    Vendor = 'vendor',
    Nth = 'nth',
}

enum EdgeRelations {
    OrgVendor = `org-vendor`,
    VendorNth = `vendor-nth`,
}

@Component({
    selector: 'app-risk-network',
    templateUrl: 'risk-network.component.html',
    styleUrls: ['risk-network.component.scss'],
})
export class RiskNetworkComponent implements OnInit, OnDestroy {
    @Input()
    currentAccount: VisoUser;

    @Input()
    networkExposureOrgId: number;

    @Input()
    set graphNodes(value: RiskNetworkGraphNode[]) {
        this._graphNodes$.next(value);
    }

    @Input()
    triggerShow4thParties: boolean;

    @Output()
    selectedVendorId = new EventEmitter<string>();

    network: Network = null;
    nodesView: DataView<Node>;
    edgesView: DataView<EdgeWithRelation>;
    selectedNthParty$ = new BehaviorSubject<CompleteVendorSearchResult>(null);

    private readonly zoomAnimationTime = 300;
    private zoomSubject$ = new Subject();
    private baseSize = 8;
    private readonly defaultOptions: Options = {
        nodes: {
            shape: 'dot',
            size: this.baseSize,
            borderWidth: 2,
            borderWidthSelected: 10,
            font: {
                align: 'top',
                face: 'Niveau Grotesk',
                background: 'rgba(255, 255, 255, 0.5)',
                color: '#5B6270',
                size: 16,
            },
            shapeProperties: {
                interpolation: false,
            },
        },
        interaction: {
            hideEdgesOnDrag: true,
            hover: true,
            hoverConnectedEdges: true,
            selectable: true,
            multiselect: true,
        },
        edges: {
            color: 'rgba(213, 218, 222, 1)',
            smooth: false,
        },
        groups: {
            [NodeGroups.Org]: {
                color: {
                    background: '#FFF7D1',
                    border: '#F8D220',
                    highlight: { background: '#F8D220', border: 'rgba(248, 210, 32, 0.3)' },
                    hover: { background: '#F8D220', border: '#F8D220' },
                },
            } as NodeOptions,
            [NodeGroups.Vendor]: {
                color: {
                    background: '#9BCED1',
                    border: '#06848C',
                    highlight: { background: '#06848C', border: 'rgba(6, 132, 140, 0.2)' },
                    hover: { background: '#06848C', border: '#06848C' },
                },
            } as NodeOptions,
            [NodeGroups.Nth]: {
                color: {
                    background: '#A8AFE9',
                    border: '#303FCA',
                    highlight: { background: '#303FCA', border: 'rgba(48, 63, 202, 0.2)' },
                    hover: { background: '#303FCA', border: '#303FCA' },
                },
            } as NodeOptions,
        },

        layout: {
            improvedLayout: false,
        },

        physics: {
            enabled: true,
            solver: 'forceAtlas2Based',
            forceAtlas2Based: {
                gravitationalConstant: -200, //This is similar to the barnesHut method except that the falloff is linear instead of quadratic. The connectivity is also taken into account as a factor of the mass. If you want the repulsion to be stronger, decrease the value (so -1000, -2000).
                avoidOverlap: 2, //Accepted range: [0 .. 1]. When larger than 0, the size of the node is taken into account. The distance will be calculated from the radius of the encompassing circle of the node for both the gravity model. Value 1 is maximum overlap avoidance.
                damping: 0.5, //This is the damping factor.
                springConstant: 0.05, //This is how 'sturdy' the springs are. Higher values mean stronger springs. If you want the network to be more compact, decrease the value (so 0.05, 0.01).
                springLength: 100, //This is the length of the spring. If you want the network to be more compact, decrease the value (so 50, 10).
                centralGravity: 0.01, //There is a central gravity attractor to pull the entire network back to the center. This is not dependent on distance. The value is the strength of the attraction. If you want the network to be more spread out, decrease the value (so 0.01, 0.001).
                theta: 0.1, // This parameter determines the boundary between consolidated long range forces and individual short range forces. To oversimplify higher values are faster but generate more errors, lower values are slower but with less errors.
            },
            stabilization: true,
            maxVelocity: 50,
            adaptiveTimestep: true,
        },
    };

    private networkOptions = this.defaultOptions;
    private _graphNodes$ = new BehaviorSubject<RiskNetworkGraphNode[]>([]);
    private _unsub$ = new Subject<void>();
    private stabilizer$ = new Subject<void>();

    stabilizeOnProgress = false;

    clickedVendors: number[] = [];
    loadingProgress = 0;
    loading = true;

    get allNodesSelected(): boolean {
        return this.clickedVendors?.length === this._graphNodes$.value?.length;
    }

    constructor(
        private _cdr: ChangeDetectorRef,
        private _store$: Store,
        private _actions$: Actions,
        private _router: Router,
        private _activatedRoute: ActivatedRoute,
    ) {}

    ngOnInit(): void {
        this._graphNodes$
            .pipe(
                filter((nodes) => Boolean(nodes)),
                map((nodes) => ({ nodes, user: this.currentAccount })),
                map(({ nodes, user }) => this.processVendorNodes(nodes, { id: user.orgId, name: user.orgName })),
                takeUntil(this._unsub$),
            )
            .subscribe((nodesData) => this.draw(nodesData));

        this.zoomSubject$.pipe(takeUntil(this._unsub$), throttleTime(this.zoomAnimationTime)).subscribe((zoom) => {
            const scale = zoom === 'out' ? this.network.getScale() / 2 : this.network.getScale() * 2;
            this.network.moveTo({
                scale,
                animation: { duration: this.zoomAnimationTime, easingFunction: 'linear' },
            });
        });

        this.stabilizer$.pipe(debounceTime(10000), takeUntil(this._unsub$)).subscribe(() => {
            this.network.stopSimulation();
        });

        this._actions$
            .pipe(ofType(getVendorByIdRequestSuccess), takeUntil(this._unsub$))
            .subscribe(({ vendor }) => this.selectedNthParty$.next(vendor));

        const vendorOrgIdRegex: RegExp = /vendorOrgId:(\d+)/;

        this._router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                filter(
                    (event: NavigationEnd) => event.url.includes('/relationship') && event.url.includes('vendorOrgId'),
                ),
                map(() => this._activatedRoute.snapshot.queryParamMap.get('search').match(vendorOrgIdRegex)[1]),
                takeUntil(this._unsub$),
            )
            .subscribe((vendorOrgId) => this.selectedVendorId.emit(vendorOrgId));
    }

    ngOnDestroy(): void {
        this._unsub$.next();
    }

    composeNetwork(nodes: DataInterface<Node, 'id'>, edges: DataInterface<EdgeWithRelation, 'id'>): Network {
        const nodesView = new DataView<Node>(nodes);
        const edgesView = new DataView<EdgeWithRelation>(edges);

        this.nodesView = nodesView;
        this.edgesView = edgesView;

        this.network = new Network(
            document.getElementById('riskNetworkContainer'),
            {
                nodes: nodesView,
                edges: edgesView,
            },
            this.networkOptions,
        );

        this.network.on('afterDrawing', (ctx) => {
            const nodes = (this.network as any).body.nodes;
            for (let nodeId in nodes) {
                const node = this.nodesView.get(nodeId);
                if (nodes.hasOwnProperty(nodeId) && node.group === NodeGroups.Nth) {
                    const connectedNodes = this.network.getConnectedNodes(nodeId);
                    let growingFactor = 0;

                    if (connectedNodes.length === 1) {
                        growingFactor = (this.network.getConnectedNodes(nodeId).length - 1) * 5;
                    } else if (connectedNodes.length > 1 && connectedNodes.length < 4) {
                        growingFactor = (this.network.getConnectedNodes(nodeId).length - 1) * 3;
                    } else if (connectedNodes.length >= 4) {
                        growingFactor = (this.network.getConnectedNodes(nodeId).length - 1) * 2;
                    }

                    nodes[nodeId].options.size = this.baseSize + growingFactor;
                }
            }
        });

        this.network.on('startStabilizing', () => {});

        this.network.on('stabilizationProgress', (params) => {
            let widthFactor = params.iterations / params.total;
            this.loadingProgress = Math.round(widthFactor * 100);
            this.loading = true;
            this._cdr.markForCheck();
        });

        this.network.on('stabilizationIterationsDone', () => {
            this.loadingProgress = 100;
            setTimeout(() => {
                this.loading = false;
                this.stabilizeOnProgress = false;
                this._cdr.markForCheck();
            }, 10);
        });

        this.network.on('click', (params) => {
            let clearSelectedNthParty = false;
            if (params.nodes.length > 0) {
                const clickedNode = params.nodes[0] as string;
                if (clickedNode.includes(`${NodeGroups.Nth}_`)) {
                    // Clicked Nth Party
                    const vendorId = +clickedNode.replace(`${NodeGroups.Nth}_`, '');
                    this._store$.dispatch(getVendorByIdRequest({ vendorId }));
                } else {
                    // Clicked Vendor (Relationship)
                    clearSelectedNthParty = true;
                    this.selectedNthParty$.next(null);
                    const vendorId = +clickedNode.replace(`${NodeGroups.Vendor}_`, '');
                    if (this.clickedVendors.includes(vendorId)) {
                        this.removeVendorNetwork(clickedNode, vendorId);
                    } else {
                        this.addVendorNetwork(vendorId);
                    }
                }
            } else {
                // Clicked empty space
                clearSelectedNthParty = true;
            }

            if (clearSelectedNthParty) {
                this.selectedNthParty$.next(null);
            }
        });

        return this.network;
    }

    processVendorNodes(data: RiskNetworkGraphNode[], currentOrg: { id: number; name: string }): NodesAndEdges {
        const nodes: NodesAndEdges['nodes'] = [];
        const edges: NodesAndEdges['edges'] = [];

        const currentOrgId = currentOrg.id;
        nodes.push({
            id: `${NodeGroups.Org}_${currentOrgId}`,
            group: NodeGroups.Org,
            label: currentOrg.name,
            physics: false,
        });

        data.forEach(({ relationshipId, vendorOrgName }) => {
            nodes.push({
                id: `${NodeGroups.Vendor}_${relationshipId}`,
                group: NodeGroups.Vendor,
                label: vendorOrgName,
            });
            edges.push({
                from: `${NodeGroups.Org}_${currentOrgId}`,
                to: `${NodeGroups.Vendor}_${relationshipId}`,
                relation: EdgeRelations.OrgVendor,
            });
        });

        return { nodes, edges };
    }

    processVendorsNthNodes(data: RiskNetworkGraphNode[], relationshipIds: number[]): NodesAndEdges {
        const nodes: NodesAndEdges['nodes'] = [];
        const edges: NodesAndEdges['edges'] = [];

        data.filter((node) => relationshipIds.includes(node.relationshipId)).forEach(
            ({ relationshipId, riskNetworkDetections }) => {
                riskNetworkDetections
                    .filter((nthPartyNode) =>
                        !!this.networkExposureOrgId
                            ? nthPartyNode.orgId.toString() === this.networkExposureOrgId.toString()
                            : true,
                    ) // Filter for network exposure
                    .forEach(({ orgName, orgId }) => {
                        const nthId = `${NodeGroups.Nth}_${orgId}`;
                        nodes.push({
                            id: nthId,
                            group: NodeGroups.Nth,
                            label: orgName,
                        });
                        edges.push({
                            id: `${NodeGroups.Vendor}_${relationshipId}_${nthId}`,
                            from: `${NodeGroups.Vendor}_${relationshipId}`,
                            to: nthId,
                            relation: EdgeRelations.VendorNth,
                        });
                    });
            },
        );

        return { nodes, edges };
    }

    destroy(): void {
        if (this.network !== null) {
            this.network.destroy();
            this.network = null;
        }
    }

    draw(data: NodesAndEdges): void {
        this.destroy();
        this.composeNetwork(new DataSet(data.nodes), new DataSet(data.edges));
        if (!!this.triggerShow4thParties) {
            this.toggleShownNodes();
        }
    }

    loadRiskNetworkData(vendorIds: number[], stabilize = false): void {
        const { nodes, edges } = this.processVendorsNthNodes(this._graphNodes$.value, vendorIds);
        const nodesSet = this.nodesView.getDataSet();
        const edgesSet = this.edgesView.getDataSet();

        const newNodes = [];
        const newEdges = [];

        nodes.forEach((node) => {
            if (!newNodes.some((newNode) => newNode.id === node.id) && !nodesSet.get(node.id)) {
                newNodes.push(node);
            }
        });

        edges.forEach((edge) => {
            if (!newEdges.some((newEdge) => newEdge.id === edge.id) && !edgesSet.get(edge.id)) {
                newEdges.push(edge);
            }
        });

        if (newNodes.length === 0 && newEdges.length === 0) {
            return;
        }

        if (stabilize) {
            this.network.stabilize();
        } else {
            this.stabilizer$.next();
        }

        nodesSet.add(newNodes);
        edgesSet.add(newEdges);
    }

    removeRiskNetworkNodes(targetNode: string = null) {
        const nodes = this.nodesView.getDataSet();
        const edges = this.edgesView.getDataSet();
        const riskNetworkNodeIdsToRemove = [];
        const edgesIdsToRemove = [];

        if (targetNode) {
            this.network.getConnectedEdges(targetNode).forEach((edge) => {
                if (edges.get(edge).relation === EdgeRelations.VendorNth) {
                    edgesIdsToRemove.push(edge);
                }
            });

            this.network.getConnectedNodes(targetNode).forEach((node) => {
                if (nodes.get(node as string)?.group === NodeGroups.Nth) {
                    const connectedEdges = this.network.getConnectedEdges(node);
                    if (connectedEdges.length === 1) {
                        riskNetworkNodeIdsToRemove.push(node);
                    }
                }
            });
        } else {
            edges.forEach((edge) => {
                if (edge.relation === EdgeRelations.VendorNth) {
                    edgesIdsToRemove.push(edge.id);
                }
            });

            nodes.forEach((node) => {
                if (node.group === NodeGroups.Nth) {
                    riskNetworkNodeIdsToRemove.push(node.id);
                }
            });
            this.network.stabilize();
        }

        if (!riskNetworkNodeIdsToRemove.length && !edgesIdsToRemove.length) {
            return;
        }

        nodes.remove(riskNetworkNodeIdsToRemove);
        edges.remove(edgesIdsToRemove);
    }

    toggleShownNodes() {
        if (!this.allNodesSelected) {
            this.stabilizeOnProgress = true;
            this.clickedVendors = this._graphNodes$.value.map((vendor) => vendor.relationshipId);
            this.loadRiskNetworkData(this.clickedVendors, true);
            this.nodesView.refresh();
        } else {
            this.clickedVendors = [];
            this.removeRiskNetworkNodes();
        }
    }

    zoom(event: MouseEvent, type: 'in' | 'out') {
        event.preventDefault();
        this.zoomSubject$.next(type);
    }

    addVendorNetwork(vendorId: number) {
        this.clickedVendors.push(vendorId);
        this._cdr.markForCheck();
        this.loadRiskNetworkData([vendorId]);
    }

    removeVendorNetwork(nodeId: string, vendorId: number) {
        this.clickedVendors = this.clickedVendors.filter((item) => item !== vendorId);
        this._cdr.markForCheck();
        this.removeRiskNetworkNodes(nodeId);
    }

    addRelationshipClicked(vendor: CompleteVendorSearchResult) {
        this._store$.dispatch(addRelationshipWithVendor({ vendor }));
    }

    viewProfile(vendor: CompleteVendorSearchResult) {
        this._router.navigate([`directory/${vendor.id}`]);
    }
}
