import { Route } from '@angular/router';
import { ClientOffboardingComponent as ClientOffboardingComponent } from './client-offboarding.component';

export const clientOffboardingRoute: Route = {
    path: 'client-offboarding',
    component: ClientOffboardingComponent,
    data: {
        pageTitle: 'Client Offboarding',
    },
};
