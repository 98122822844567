import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { VisoUserRole } from '../../entities/viso-user';
import { SnackbarService } from '../../shared/components/snackbar/snackbar.service';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { tap, takeUntil } from 'rxjs/operators';
import {
    uploadCompanyIconRequest,
    uploadCompanyIconRequestCancelled,
    uploadCompanyIconRequestFailed,
    uploadCompanyIconRequestProgress,
    uploadCompanyIconRequestSuccess,
    uploadCompanyLogoRequest,
    uploadCompanyLogoRequestCancelled,
    uploadCompanyLogoRequestFailed,
    uploadCompanyLogoRequestProgress,
    uploadCompanyLogoRequestSuccess,
} from './redux/your-organization.actions';
import { Subject } from 'rxjs';
import { FileUtilsService } from '../../shared/utils/file-utils.service';
import { LogoType } from './your-organization-logos.model';
import { Org } from '../../entities/org';

@Component({
    selector: 'app-your-organization-logos',
    templateUrl: './your-organization-logos.component.html',
    styleUrls: ['./your-organization-logos.component.scss'],
})
export class YourOrganizationLogosComponent implements OnInit, OnDestroy {
    @Input() org: Org;

    Roles = VisoUserRole;
    LogoTypes = LogoType;

    iconLogoUploadDisabled = true;
    fullLogoUploadDisabled = true;
    iconLogoPercentUploaded: number;
    fullLogoPercentUploaded: number;
    iconLogoisUploading: boolean;
    fullLogoisUploading: boolean;
    iconLogoUploadedFileName: string;
    fullLogoUploadedFileName: string;

    private _unsub$: Subject<void> = new Subject<void>();

    constructor(
        private _snackbarService: SnackbarService,
        private _actions$: Actions,
        private _store$: Store,
        private _fileUtilsService: FileUtilsService,
    ) {}

    get getIconLogoUploadedFileName(): string {
        return this._fileUtilsService.formatFileNameForView(this.iconLogoUploadedFileName);
    }

    get getFullLogoUploadedFileName(): string {
        return this._fileUtilsService.formatFileNameForView(this.fullLogoUploadedFileName);
    }

    ngOnInit(): void {
        this._actions$
            .pipe(
                ofType(uploadCompanyIconRequestProgress),
                tap(({ percent }) => {
                    this.iconLogoPercentUploaded = percent;
                }),
                takeUntil(this._unsub$),
            )
            .subscribe();

        this._actions$
            .pipe(
                ofType(uploadCompanyIconRequestSuccess, uploadCompanyIconRequestFailed),
                tap(() => {
                    this.resetIconLogoUpload();
                }),
                takeUntil(this._unsub$),
            )
            .subscribe();

        this._actions$
            .pipe(
                ofType(uploadCompanyLogoRequestProgress),
                tap(({ percent }) => {
                    this.fullLogoPercentUploaded = percent;
                }),
                takeUntil(this._unsub$),
            )
            .subscribe();

        this._actions$
            .pipe(
                ofType(uploadCompanyLogoRequestSuccess, uploadCompanyLogoRequestFailed),
                tap(() => {
                    this.resetFullLogoUpload();
                }),
                takeUntil(this._unsub$),
            )
            .subscribe();
    }

    uploadFile(event: any, logoType: LogoType): void {
        const file = event.target.files[0];

        if (this.validateMimeType(file, logoType) && this.validateFileSize(file)) {
            if (logoType === LogoType.IconLogo) {
                this.iconLogoisUploading = true;
                this.iconLogoUploadedFileName = file.name;
                this._store$.dispatch(
                    uploadCompanyIconRequest({
                        orgId: this.org.id,
                        file: file,
                    }),
                );
            } else {
                this.fullLogoisUploading = true;
                this.fullLogoUploadedFileName = file.name;
                this._store$.dispatch(
                    uploadCompanyLogoRequest({
                        orgId: this.org.id,
                        file: file,
                    }),
                );
            }
        } else {
            if (logoType === LogoType.IconLogo) this.resetIconLogoUpload();
            if (logoType === LogoType.FullLogo) this.resetFullLogoUpload();
        }
    }

    resetIconLogoUpload(): void {
        this.iconLogoUploadedFileName = null;
        this.iconLogoisUploading = false;
        this.iconLogoPercentUploaded = 0;
        this.iconLogoUploadDisabled = true;
    }

    resetFullLogoUpload(): void {
        this.fullLogoUploadedFileName = null;
        this.fullLogoisUploading = false;
        this.fullLogoPercentUploaded = 0;
        this.fullLogoUploadDisabled = true;
    }

    toggleUpload() {
        this.iconLogoUploadDisabled = !this.iconLogoUploadDisabled;

        if (this.iconLogoUploadDisabled) {
            this.cancelFullLogoUpload();
        }
    }

    cancelIconLogoUpload(): void {
        this._store$.dispatch(uploadCompanyIconRequestCancelled());
        this.resetIconLogoUpload();
    }

    cancelFullLogoUpload(): void {
        this._store$.dispatch(uploadCompanyLogoRequestCancelled());
        this.resetFullLogoUpload();
    }

    private validateFileSize(file: File): boolean {
        if (file.size > 100000 || file.size === 0) {
            this._snackbarService.error('File size must not be empty and less than 100kB.');
            return false;
        }
        return true;
    }

    private validateMimeType(file: File, logoType: LogoType): boolean {
        let isValidMimeType: boolean;

        if (logoType === LogoType.IconLogo) {
            isValidMimeType =
                file.type === 'image/x-icon' ||
                file.type === 'image/jpeg' ||
                file.type === 'image/png' ||
                file.type === 'image/vnd.microsoft.icon';
            if (!isValidMimeType) {
                this._snackbarService.error('File type must be .png, .ico or .jpg');
            }
        } else {
            isValidMimeType = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/gif';
            if (!isValidMimeType) {
                this._snackbarService.error('File type must be .jpg, .png or .gif');
            }
        }
        return isValidMimeType;
    }

    ngOnDestroy(): void {
        this._unsub$.next();
    }
}
