import { Injectable } from '@angular/core';
import { HttpClient, HttpContext, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { VisoUser } from '../../entities/viso-user';
import { UserRegistrationRequest } from '../../entities/user-registration/user-registration.model';
import { BYPASS_SNACKBAR_ON_ERROR } from '../../blocks/interceptor/errorhandler.interceptor';

@Injectable()
export class AccountService {
    private _accountResourceUrl = 'api/account';

    constructor(private http: HttpClient) {}

    fetch(): Observable<VisoUser> {
        return this.http.get<VisoUser>(this._accountResourceUrl, {
            context: new HttpContext().set(BYPASS_SNACKBAR_ON_ERROR, { forAnyStatus: true }),
        });
    }

    signUp(userRegistrationRequest: UserRegistrationRequest): Observable<HttpResponse<void>> {
        return this.http.post<void>(`${this._accountResourceUrl}/registration`, userRegistrationRequest, {
            context: new HttpContext().set(BYPASS_SNACKBAR_ON_ERROR, { forAnyStatus: true }),
            observe: 'response',
        });
    }

    activate(activationToken: string): Observable<HttpResponse<void>> {
        return this.http.put<void>(`${this._accountResourceUrl}/activate`, null, {
            observe: 'response',
            params: new HttpParams().set('activationToken', activationToken),
            context: new HttpContext().set(BYPASS_SNACKBAR_ON_ERROR, { forAnyStatus: true }),
        });
    }
}
