import {
    AfterViewInit,
    Component,
    ComponentRef,
    Directive,
    HostBinding,
    Input,
    OnDestroy,
    OnInit,
    TemplateRef,
    ViewContainerRef,
} from '@angular/core';
import { select, Store } from '@ngrx/store';
import { combineLatest, ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Relationship } from '../../../entities/relationship';
import { VisoUser } from '../../../entities/viso-user';
import { getUserAccount } from '../../session/redux/session.selectors';

enum DisablingReason {
    NoMoreAssessments,
    NoRelationshipContext,
    NoRelationshipBusinessCase,
    NoRelationshipDataTypes,
}

@Component({
    selector: 'disable-start-assessment-wrapper',
    template: `
        <span
            #pop="bs-popover"
            [popover]="popoverDisabled ? null : noAssessmentsLeftTemplate"
            [ngbTooltip]="tooltipDisabled ? null : missingContextTooltip"
            [outsideClick]="true"
            [containerClass]="'assessment-disabled-popover'"
            container="body"
            (mouseenter)="pop.show()"
        >
            <ng-container *ngTemplateOutlet="elementTemplate"></ng-container>
        </span>
        <ng-template #noAssessmentsLeftTemplate>
            <div class="popover-detail" (mouseleave)="pop.hide()">
                <div class="header">
                    <strong>You have reached your limit of assessments.</strong>
                    <em class="fas fa-clipboard-list text-primary"></em>
                </div>
                <div class="body mt-2">
                    Manage third-party risks with AI-powered automation that scales with your organization.
                </div>
                <div class="footer text-bold">
                    <p class="m-1">Unlock more assessments.</p>
                    <p>
                        Click
                        <a class="viso-link" href="mailto:sales@visotrust.com">
                            <strong>here</strong>
                        </a>
                        to contact sales.
                    </p>
                </div>
            </div>
        </ng-template>
        <ng-template #missingContextTooltip>
            <p>There must be an inherent risk score before starting an assessment.</p>
            <p>{{ tooltipMessage }}</p>
        </ng-template>
    `,
})
export class DisableStartAssessmentWrapperComponent {
    @Input()
    elementTemplate: TemplateRef<any>;

    @Input()
    disablingReason: DisablingReason;

    @HostBinding('class')
    @Input()
    customHostClass: string = '';

    get popoverDisabled(): boolean {
        return this.disablingReason !== DisablingReason.NoMoreAssessments;
    }

    get tooltipDisabled(): boolean {
        return ![
            DisablingReason.NoRelationshipDataTypes,
            DisablingReason.NoRelationshipBusinessCase,
            DisablingReason.NoRelationshipContext,
        ].includes(this.disablingReason);
    }

    get tooltipMessage(): string {
        if (this.tooltipDisabled) {
            return;
        }
        switch (this.disablingReason) {
            case DisablingReason.NoRelationshipContext:
                return 'Define the relationship context by selecting the business case and at least one shared data type.';
            case DisablingReason.NoRelationshipDataTypes:
                return 'Finish defining relationship context by selecting at least one shared data type.';
            case DisablingReason.NoRelationshipBusinessCase:
                return 'Finish defining relationship context by selecting at least one business case.';
        }
    }

    constructor(public viewContainerRef: ViewContainerRef) {}
}

@Directive({
    selector: '[disableStartAssessmentOnRules]',
})
export class DisableStartAssessmentOnRulesDirective implements OnInit, AfterViewInit, OnDestroy {
    @Input()
    set disableStartAssessmentOnRules(value: Relationship) {
        this._relationship$.next(value);
    }

    @Input()
    disableStartAssessmentOnRulesHostCustomClass: string = '';

    private _unsub$ = new Subject<void>();
    private _relationship$ = new ReplaySubject<Relationship>();
    private _wrapperContainerRef: ComponentRef<DisableStartAssessmentWrapperComponent>;

    constructor(
        private _templateRef: TemplateRef<any>,
        private _viewContainerRef: ViewContainerRef,
        private _store$: Store,
    ) {}

    ngOnInit(): void {
        this._wrapperContainerRef = this._viewContainerRef.createComponent(DisableStartAssessmentWrapperComponent);
        this._wrapperContainerRef.instance.elementTemplate = this._templateRef;
        this._wrapperContainerRef.instance.customHostClass = this.disableStartAssessmentOnRulesHostCustomClass;
        this._wrapperContainerRef.changeDetectorRef.detectChanges();
    }

    ngAfterViewInit(): void {
        combineLatest([this._store$.pipe(select(getUserAccount)), this._relationship$])
            .pipe(takeUntil(this._unsub$))
            .subscribe(([account, relationship]) => {
                let disablingReason: DisablingReason | null = null;
                if (!this.doesAccountHaveAvailableAssessments(account)) {
                    disablingReason = DisablingReason.NoMoreAssessments;
                }

                if (!this.doesRelationshipHasContext(relationship)) {
                    if (this.doesRelationshipHasBusinessCases(relationship)) {
                        disablingReason = DisablingReason.NoRelationshipDataTypes;
                    } else if (this.doesRelationshipHasDataTypes(relationship)) {
                        disablingReason = DisablingReason.NoRelationshipBusinessCase;
                    } else {
                        disablingReason = DisablingReason.NoRelationshipContext;
                    }
                }
                if (disablingReason !== null) {
                    return this.disableButtonAndSetPopover(disablingReason);
                }
                return this.enableButtonAndSetNoPopover();
            });
    }

    private getButton(): HTMLButtonElement {
        return (this._wrapperContainerRef.location.nativeElement as HTMLElement).querySelector(
            'button',
        ) as HTMLButtonElement;
    }

    private enableButtonAndSetNoPopover(): void {
        this._wrapperContainerRef.instance.disablingReason = null;
        this._wrapperContainerRef.changeDetectorRef.detectChanges();
        this.getButton().disabled = false;
    }

    private disableButtonAndSetPopover(reason: DisablingReason) {
        this._wrapperContainerRef.instance.disablingReason = reason;
        this._wrapperContainerRef.changeDetectorRef.detectChanges();
        this.getButton().disabled = true;
    }

    private doesAccountHaveAvailableAssessments(account: VisoUser): boolean {
        return (
            ((account.clientLicense?.maxRelationshipsAssessed -
                account.clientLicense?.relationshipsAssessedCount) as number) > 0 ||
            account.clientLicense?.maxRelationshipsAssessed === -1
        );
    }

    private doesRelationshipHasContext(relationship: Relationship): boolean {
        return this.doesRelationshipHasBusinessCases(relationship) && this.doesRelationshipHasDataTypes(relationship);
    }

    private doesRelationshipHasBusinessCases(relationship: Relationship): boolean {
        return !!relationship?.contextTypes?.length;
    }

    private doesRelationshipHasDataTypes(relationship: Relationship): boolean {
        return !!relationship?.dataTypes?.length;
    }

    ngOnDestroy(): void {
        this._unsub$.next();
    }
}
