import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { RelationshipService } from '@entities/relationship';
import { SnackbarService } from '@shared/components/snackbar/snackbar.service';
import { RelationshipOnboardingModalComponent } from '../../relationship-tab/relationship-onboarding-modal/relationship-onboarding-modal.component';
import {
    onboardModalCancelled,
    onboardRelationshipFailed,
    onboardRelationshipRequest,
    onboardRelationshipSuccess,
    openOnboardModal,
} from '../actions/onboard.actions';
import { getRelationshipPopulatedLatestAssessment } from '../selectors/assessment.selectors';
import { MatDialogWrapperService } from '@shared/modal/mat-dialog-wrapper.service';

@Injectable()
export class OnboardEffects {
    openOnboardModal$ = createEffect(() =>
        this._actions$.pipe(
            ofType(openOnboardModal),
            withLatestFrom(this._store$.select(getRelationshipPopulatedLatestAssessment)),
            map(([onboardModalInputs, latestAssessment]) => ({
                ...onboardModalInputs,
                latestCompletedAssessmentDate: latestAssessment?.completedDate,
            })),
            switchMap(({ relationship, vendorName, dateOfNextArtifactExpiration, latestCompletedAssessmentDate }) =>
                this._dialogService
                    .open(RelationshipOnboardingModalComponent, {
                        inputs: {
                            relationship,
                            vendorName,
                            dateOfNextArtifactExpiration,
                            latestCompletedAssessmentDate,
                        },
                        config: {
                            maxWidth: 900,
                        },
                        metadata: { modalName: 'Onboard Relationship' },
                    })
                    .afterClosed()
                    .pipe(
                        map((onboardRequest) =>
                            !!onboardRequest
                                ? onboardRelationshipRequest({
                                      relationshipId: relationship.id,
                                      onboardRequest,
                                  })
                                : onboardModalCancelled(),
                        ),
                    ),
            ),
        ),
    );

    onboardRelationship$ = createEffect(() =>
        this._actions$.pipe(
            ofType(onboardRelationshipRequest),
            switchMap(({ relationshipId, onboardRequest }) =>
                this._relationshipService.onboard(relationshipId, onboardRequest).pipe(
                    map((response) => response.body),
                    map((relationshipOnboardedView) => onboardRelationshipSuccess({ relationshipOnboardedView })),
                    catchError(() => of(onboardRelationshipFailed())),
                ),
            ),
        ),
    );

    showOnboardedMessage$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(onboardRelationshipSuccess),
                tap(({ relationshipOnboardedView }) => {
                    const snackbarMessage = `<strong>${relationshipOnboardedView.vendorName}</strong> has been onboarded.`;
                    this._snackbarService.success(snackbarMessage);
                }),
            ),
        { dispatch: false },
    );

    constructor(
        private _store$: Store,
        private _actions$: Actions,
        private _relationshipService: RelationshipService,
        private _snackbarService: SnackbarService,
        private _dialogService: MatDialogWrapperService,
    ) {}
}
