import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { ROUTER_NAVIGATED, RouterNavigationAction } from '@ngrx/router-store';
import { of } from 'rxjs';
import { switchMap, map, catchError, filter, tap } from 'rxjs/operators';
import { BusinessUnitDeleteDialogComponent, BusinessUnitReassignModalComponent } from '../../business-unit';
import { NgbModalWrapperService } from '../../../shared';
import { SnackbarService } from '../../../shared/components/snackbar/snackbar.service';
import {
    getBusinessUnitsRequest,
    getBusinessUnitsRequestSuccess,
    getBusinessUnitsRequestFailed,
    reassignBusinessUnitRequest as reassignBusinessUnitRequest,
    reassignBusinessUnitRequestSuccess as reassignBusinessUnitRequestSuccess,
    reassignBusinessUnitRequestFailed as reassignBusinessUnitRequestFailed,
    createBusinessUnitRequest,
    createBusinessUnitRequestFailed,
    createBusinessUnitRequestSuccess,
    updateBusinessUnitRequest,
    updateBusinessUnitRequestFailed,
    updateBusinessUnitRequestSuccess,
    openDeleteBusinessUnitConfirmationModal,
    deleteBusinessUnitRequestSuccess,
    deleteBusinessUnitRequest,
    deleteBusinessUnitRequestFailed,
    getBusinessUnitsForManagementRequest,
    getBusinessUnitsForManagementRequestFailed,
    getBusinessUnitsForManagementRequestSuccess,
} from './business-unit-management.actions';
import { BusinessUnitService } from '../../../entities/business-unit';

@Injectable()
export class BusinessUnitEffects {
    getBusinessUnitsRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(getBusinessUnitsRequest),
            switchMap(({ req, clientId }) =>
                this._businessUnitService.findAllForCurrentUserOrg(req, clientId).pipe(
                    map((response) =>
                        getBusinessUnitsRequestSuccess({
                            businessUnits: response.body,
                            headers: response.headers,
                        }),
                    ),
                    catchError(() => of(getBusinessUnitsRequestFailed())),
                ),
            ),
        ),
    );

    getBusinessUnitsForManagementRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(getBusinessUnitsForManagementRequest),
            switchMap(({ req }) =>
                this._businessUnitService.findAllForManagement(req).pipe(
                    map((response) =>
                        getBusinessUnitsForManagementRequestSuccess({
                            businessUnits: response.body,
                            headers: response.headers,
                        }),
                    ),
                    catchError(() => of(getBusinessUnitsForManagementRequestFailed())),
                ),
            ),
        ),
    );

    openTransferBusinessUnitModal$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(ROUTER_NAVIGATED),
                map((action: RouterNavigationAction) =>
                    action.payload.routerState.root.children.find((route) => route.outlet === 'popup'),
                ),
                filter((route) => !!route && route.routeConfig.path === 'business-unit/:id/transfer'),
                map((route) => ({ businessUnitId: route.params.id })),
                tap(({ businessUnitId }) =>
                    this._modalService.open<BusinessUnitReassignModalComponent>(
                        BusinessUnitReassignModalComponent,
                        {
                            businessUnitId,
                        },
                        { size: 'md' },
                    ),
                ),
            ),
        { dispatch: false },
    );

    reassignBusinessUnitRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(reassignBusinessUnitRequest),
            switchMap(({ fromBusinessUnitId, toBusinessUnitId }) =>
                this._businessUnitService.reassign(fromBusinessUnitId, toBusinessUnitId).pipe(
                    map((response) => reassignBusinessUnitRequestSuccess()),
                    catchError(() => of(reassignBusinessUnitRequestFailed())),
                ),
            ),
        ),
    );

    reassignBusinessUnitRequestSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(reassignBusinessUnitRequestSuccess),
                tap(() => {
                    this._snackbarService.success('Successfully reassigned business unit');
                }),
            ),
        { dispatch: false },
    );

    createBusinessUnitRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(createBusinessUnitRequest),
            switchMap(({ businessUnit }) =>
                this._businessUnitService.create(businessUnit).pipe(
                    map((response) =>
                        createBusinessUnitRequestSuccess({
                            businessUnit: response.body,
                            headers: response.headers,
                        }),
                    ),
                    catchError(() => of(createBusinessUnitRequestFailed())),
                ),
            ),
        ),
    );

    updateCreateBusinessUnitRequestSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(createBusinessUnitRequestSuccess, updateBusinessUnitRequestSuccess),
                tap(() => {
                    this._snackbarService.success('Successfully saved business unit');
                }),
            ),
        { dispatch: false },
    );

    updateBusinessUnitRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(updateBusinessUnitRequest),
            switchMap(({ businessUnit }) =>
                this._businessUnitService.update(businessUnit).pipe(
                    map((response) => response.body),
                    map((businessUnit) => updateBusinessUnitRequestSuccess({ businessUnit })),
                    catchError(() => of(updateBusinessUnitRequestFailed())),
                ),
            ),
        ),
    );

    openDeleteBusinessUnitConfirmationModal$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(openDeleteBusinessUnitConfirmationModal),
                tap(({ businessUnit }) =>
                    this._modalService.open<BusinessUnitDeleteDialogComponent>(
                        BusinessUnitDeleteDialogComponent,
                        { businessUnit },
                        { size: 'md' },
                    ),
                ),
            ),
        { dispatch: false },
    );

    deleteBusinessUnitRequestSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(deleteBusinessUnitRequestSuccess),
                tap(() => {
                    this._snackbarService.success('Successfully deleted business unit');
                }),
            ),
        { dispatch: false },
    );

    deleteBusinessUnitRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(deleteBusinessUnitRequest),
            switchMap(({ businessUnitId }) =>
                this._businessUnitService.delete(businessUnitId).pipe(
                    map((response) => response.body),
                    map(() => deleteBusinessUnitRequestSuccess()),
                    catchError(() => of(deleteBusinessUnitRequestFailed())),
                ),
            ),
        ),
    );

    constructor(
        private _actions$: Actions,
        private _businessUnitService: BusinessUnitService,
        private _modalService: NgbModalWrapperService,
        private _snackbarService: SnackbarService,
    ) {}
}
