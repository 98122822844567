import { Injectable } from '@angular/core';
import { AssessmentSubmission } from '@entities/assessment';
import { AssessmentSubmissionFormPayload, AttestationsGroups } from '../models';

interface Inputs {
    formPayload: AssessmentSubmissionFormPayload;
    assessmentToken: string;
    secret: string;
    attestationsGroup?: AttestationsGroups;
}

@Injectable()
export class SubmissionHelperService {
    generateSubmissionPayload({
        formPayload,
        assessmentToken,
        secret,
        attestationsGroup,
    }: Inputs): AssessmentSubmission {
        const { email, firstName, lastName } = formPayload;
        const attestations = attestationsGroup?.attestations ?? {};
        const siblingArtifactAttestations = attestationsGroup?.siblingArtifactAttestations ?? {};
        const expiredArtifactAttestations = attestationsGroup?.expiredArtifactAttestations ?? {};
        return {
            assessmentToken,
            secret,
            email,
            firstName,
            lastName,
            attestations,
            siblingArtifactAttestations,
            expiredArtifactAttestations,
            notifyClient: true,
            tosAccepted: true,
        };
    }
}
