import {
    AfterViewInit,
    Component,
    ComponentRef,
    Directive,
    Input,
    OnDestroy,
    OnInit,
    TemplateRef,
    ViewContainerRef,
} from '@angular/core';
import { combineLatest, Subject } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { getUserAccount } from '../../../routes/session/redux/session.selectors';
import { takeUntil } from 'rxjs/operators';
import { VisoUser } from '../../../entities/viso-user';

@Component({
    selector: 'disable-add-relationship-wrapper',
    template: `
        <span
            #pop="bs-popover"
            [popover]="popoverDisabled ? null : noRelationshipsLeftTemplate"
            [outsideClick]="true"
            [containerClass]="'assessment-disabled-popover'"
            container="body"
            (mouseenter)="pop.show()"
        >
            <ng-container *ngTemplateOutlet="elementTemplate"></ng-container>
        </span>
        <ng-template #noRelationshipsLeftTemplate>
            <div class="popover-detail" (mouseleave)="pop.hide()">
                <div class="header">
                    <strong>You have reached your limit of relationships.</strong>
                    <em class="fas fa-clipboard-list text-primary"></em>
                </div>
                <div class="body mt-2">
                    Manage third-party risks with AI-powered automation that scales with your organization.
                </div>
                <div class="footer text-bold">
                    <p class="m-1">Unlock more relationships.</p>
                    <p>
                        Click
                        <a class="viso-link" href="mailto:sales@visotrust.com">
                            <strong>here</strong>
                        </a>
                        to contact sales.
                    </p>
                </div>
            </div>
        </ng-template>
    `,
})
export class DisableAddRelationshipWrapperComponent {
    @Input()
    elementTemplate: TemplateRef<any>;

    @Input()
    popoverDisabled: boolean;

    constructor(public viewContainerRef: ViewContainerRef) {}
}

@Directive({
    selector: '[disableAddRelationshipOnRules]',
})
export class DisableAddRelationshipOnRulesDirective implements OnInit, AfterViewInit, OnDestroy {
    private _unsub$ = new Subject<void>();
    private _wrapperContainerRef: ComponentRef<DisableAddRelationshipWrapperComponent>;

    constructor(
        private _templateRef: TemplateRef<any>,
        private _viewContainerRef: ViewContainerRef,
        private _store$: Store,
    ) {}

    ngOnInit(): void {
        this._wrapperContainerRef = this._viewContainerRef.createComponent(DisableAddRelationshipWrapperComponent);
        this._wrapperContainerRef.instance.elementTemplate = this._templateRef;
        this._wrapperContainerRef.changeDetectorRef.detectChanges();
    }

    ngAfterViewInit(): void {
        combineLatest([this._store$.pipe(select(getUserAccount))])
            .pipe(takeUntil(this._unsub$))
            .subscribe(([account]) => {
                if (!this.doesAccountHaveAvailableRelationships(account)) {
                    return this.disableButtonAndSetPopover();
                }

                return this.enableButtonAndSetNoPopover();
            });
    }

    private getButton(): HTMLButtonElement {
        return (this._wrapperContainerRef.location.nativeElement as HTMLElement).querySelector(
            'button, a',
        ) as HTMLButtonElement;
    }

    private enableButtonAndSetNoPopover(): void {
        this._wrapperContainerRef.instance.popoverDisabled = true;
        this._wrapperContainerRef.changeDetectorRef.detectChanges();
        this.getButton().disabled = false;
    }

    private disableButtonAndSetPopover() {
        this._wrapperContainerRef.instance.popoverDisabled = false;
        this._wrapperContainerRef.changeDetectorRef.detectChanges();
        this.getButton().disabled = true;
    }

    private doesAccountHaveAvailableRelationships(account: VisoUser): boolean {
        return (
            ((account.clientLicense?.maxRelationshipsCreated -
                account.clientLicense?.relationshipsCreatedCount) as number) > 0 ||
            account.clientLicense?.maxRelationshipsCreated === -1
        );
    }

    ngOnDestroy(): void {
        this._unsub$.next();
    }
}
