<div class="row mb-3">
    <div class="col">
        <h3 class="lead mb-0">Data Types Shared</h3>
    </div>
</div>
<ng-container *ngIf="!!dataTypesConfig?.length; else noDataTypes">
    <div class="row data-type" *ngFor="let dataTypeConfig of dataTypesConfig">
        <div class="col">
            <p
                class="mb-0 p-2 d-flex border-top align-items-center"
                [ngClass]="{ 'text-muted': !dataTypeConfig.enabled }"
                (mouseleave)="pop.hide()"
            >
                {{ dataTypeConfig.dataType.name }}
                <em
                    class="fa fa-info-circle text-primary d-none ms-auto"
                    #pop="bs-popover"
                    [popover]="popover"
                    [placement]="'right'"
                    (mouseenter)="pop.show()"
                ></em>
                <ng-template #popover>
                    <div class="popover-detail">
                        {{ dataTypeConfig.dataType.description }}
                    </div>
                </ng-template>
            </p>
        </div>
    </div>
</ng-container>
<ng-template #noDataTypes>
    <p class="text-muted">No Data Types selected</p>
</ng-template>
