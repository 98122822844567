<ng-container *ngIf="bannerDetails$ | async as bannerDetails">
    <div class="row" [collapse]="isCollapsed$ | async" [isAnimated]="true">
        <div class="col">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col d-flex align-items-center">
                            <p class="mb-0">{{ bannerDetails.message }}</p>
                        </div>
                        <div class="col-auto">
                            <button
                                mat-button
                                *ngIf="!!bannerDetails.navigationRoute"
                                class="me-2"
                                visoButton="secondary"
                                [routerLink]="bannerDetails.navigationRoute"
                            >
                                {{ bannerDetails.actionLabel }}
                            </button>
                            <ng-container
                                *ngIf="bannerDetails.bannerCase === BannerCases.NoAssessment; else otherActions"
                            >
                                <button
                                    mat-button
                                    *disableStartAssessmentOnRules="relationship"
                                    class="me-2"
                                    visoButton="secondary"
                                    (click)="bannerDetails.actionDelegate()"
                                >
                                    {{ bannerDetails.actionLabel }}
                                </button>
                            </ng-container>
                            <ng-template #otherActions>
                                <button
                                    mat-button
                                    *ngIf="!!bannerDetails.actionDelegate"
                                    class="me-2"
                                    visoButton="secondary"
                                    (click)="bannerDetails.actionDelegate()"
                                >
                                    {{ bannerDetails.actionLabel }}
                                </button>
                            </ng-template>
                            <button mat-button visoButton="text" (click)="dismissBanner()">Dismiss</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>
