import { environment } from 'src/main/webapp/environments/environment';
import { DEFAULT_VERSION, MigrationManifest, PersistedState } from './migration.model';

export default function createMigrate<T>(
    migrations: MigrationManifest<T>,
    debug?: boolean,
): (state: PersistedState<T>, currentVersion: number) => PersistedState<T> {
    return function (state: PersistedState<T>, currentVersion: number): PersistedState<T> {
        if (!state) {
            if (!environment.production && debug) {
                console.log('migration: no inbound state, skipping migration');
            }
            return undefined;
        }

        const inboundVersion: number =
            state._persist && state._persist.version !== undefined ? state._persist.version : DEFAULT_VERSION;
        if (inboundVersion === currentVersion) {
            if (!environment.production && debug) {
                console.log('migration: versions match, noop migration');
            }
            return state;
        }
        if (inboundVersion > currentVersion) {
            if (!environment.production) {
                console.error('migration: downgrading version is not supported');
            }
            return state;
        }

        const migrationKeys = Object.keys(migrations)
            .map((ver) => parseInt(ver))
            .filter((key) => currentVersion >= key && key > inboundVersion)
            .sort((a, b) => a - b);

        if (!environment.production && debug) console.log('migration: migrationKeys', migrationKeys);
        try {
            const migratedState: any = migrationKeys.reduce((state: any, versionKey) => {
                if (!environment.production && debug) {
                    console.log('migration: running migration for versionKey', versionKey);
                }
                return migrations[versionKey](state);
            }, state);
            return migratedState;
        } catch (err) {
            if (!environment.production && debug) {
                console.log(err);
            }
            return null;
        }
    };
}
