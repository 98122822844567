import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { VanityDomainView, VanityDomainsConfig } from './vanity-domain.model';

@Injectable({
    providedIn: 'root',
})
export class VanityDomainService {
    private resourceUrl = 'api/vanity-domains';

    constructor(private _http: HttpClient) {}

    getVanityDomain(): Observable<VanityDomainView> {
        return this._http.get<VanityDomainView>(`${this.resourceUrl}/`);
    }

    refreshVanityDomain() {
        return this._http.get<VanityDomainView>(`${this.resourceUrl}/?forceRefresh=true`);
    }

    getConfig(): Observable<VanityDomainsConfig> {
        return this._http.get<VanityDomainsConfig>(`${this.resourceUrl}/config`);
    }

    createVanityDomain(domain: string, subdomain: string): Observable<VanityDomainView> {
        return this._http.post<VanityDomainView>(`${this.resourceUrl}/${domain}/${subdomain}`, null);
    }

    deleteVanityDomain(): Observable<null> {
        return this._http.post<null>(`${this.resourceUrl}/delete`, null);
    }
}
