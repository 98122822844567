import { Component, Input, OnInit } from '@angular/core';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';

import { Observable } from 'rxjs';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { YourOrganizationPopupService } from './your-organization-popup.service';
import { Org, OrgDetailsResponse, OrgService, OrgSizeLabels, OrgUrlType } from '../../entities/org';
import { VisoUser, VisoUserRole, VisoUserService } from '../../entities/viso-user';
import { YourOrganizationHelperService } from './your-organization-helper.service';
import { Country, storedCountries } from '../../shared/model/countries';

@Component({
    selector: 'app-your-organization-dialog',
    templateUrl: './your-organization-dialog.component.html',
})
export class YourOrganizationDialogComponent implements OnInit {
    @Input()
    org: OrgDetailsResponse;

    isSaving: boolean;
    Roles = VisoUserRole;

    countries: Country[] = storedCountries;
    OrgSizeLabels = OrgSizeLabels;

    admins: VisoUser[];

    constructor(
        public activeModal: NgbActiveModal,
        private _orgService: OrgService,
        private _visoUserService: VisoUserService,
        private _yourOrgHelper: YourOrganizationHelperService,
    ) {}

    ngOnInit() {
        this.isSaving = false;
        this._visoUserService.findAllForOrg().subscribe((res: HttpResponse<VisoUser[]>) => (this.admins = res.body));
    }

    clear() {
        this.activeModal.dismiss('cancel');
    }

    update() {
        this.isSaving = true;
        this.org.urls[OrgUrlType.PRIVACY_POLICY] = this.org.privacyPolicyUrl;
        this.subscribeToSaveResponse(this._orgService.update(this.org));
    }

    private subscribeToSaveResponse(result: Observable<HttpResponse<Org>>) {
        result.subscribe(
            (res: HttpResponse<Org>) => this.onSaveSuccess(res.body),
            (res: HttpErrorResponse) => this.onSaveError(),
        );
    }

    private onSaveSuccess(result: Org) {
        this._yourOrgHelper.yourOrgChanged$.next();
        this.isSaving = false;
        this.activeModal.dismiss(result);
    }

    private onSaveError() {
        this.isSaving = false;
    }
}

@Component({
    selector: 'app-your-organization-popup',
    template: '',
})
export class YourOrganizationPopupComponent implements OnInit {
    constructor(private yourOrganizationPopupService: YourOrganizationPopupService) {}

    ngOnInit() {
        this.yourOrganizationPopupService.open(YourOrganizationDialogComponent);
    }
}
