<div class="table-responsive">
    <table class="table table-hover table-bordered table-striped">
        <thead>
            <tr>
                <th>File</th>
                <th>Uploaded On</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let attachment of attachments">
                <td>{{ attachment.fileName }}</td>
                <td>{{ attachment.uploadedDate | date: 'mediumDate' }}</td>
            </tr>
        </tbody>
    </table>
</div>
