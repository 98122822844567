import { Component, OnDestroy, OnInit } from '@angular/core';
import { VisoUser } from '../../entities/viso-user';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AppState } from '../../shared/redux/state';
import { select, Store } from '@ngrx/store';
import { getProfileDataTypes } from '../session/redux/session.selectors';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { DataTypeConfig } from '../../admin/client-profile/client-profile.model';
import { BREADCRUMB_CONTAINER_TOKEN } from '../../shared/dynamic-content/dynamic-content-injector';
import { DataSensitivityLevel } from '../../admin/client-profile/data-sensitivity-levels-config/data-sensitivity-level.model';
import { DataTypeCategory } from '../../entities/data-type';
import { DataTypeHelpModalContent } from './datatypes-help.component';
@Component({
    selector: 'app-datatypes',
    templateUrl: './datatypes.component.html',
    styleUrls: ['./datatypes.component.scss'],
})
export class DatatypesComponent implements OnInit, OnDestroy {
    public readonly breadcrumbsContainerToken = BREADCRUMB_CONTAINER_TOKEN;
    currentAccount: VisoUser;
    organizationDataTypes: DataTypeConfig[] = [];
    customerDataTypes: DataTypeConfig[] = [];
    DataSensitivityLevel = DataSensitivityLevel;

    private _unsub: Subject<void> = new Subject<void>();

    constructor(
        private _modalService: NgbModal,
        private _store$: Store<AppState>,
    ) {}

    ngOnInit() {
        this.load();
    }

    openHelp(topic: string, helpText: string) {
        const modalRef = this._modalService.open(DataTypeHelpModalContent);
        modalRef.componentInstance.topic = topic;
        modalRef.componentInstance.helpText = helpText;
    }

    ngOnDestroy(): void {
        this._unsub.next();
    }

    private load() {
        this._store$.pipe(select(getProfileDataTypes), takeUntil(this._unsub)).subscribe((profileDataTypes) => {
            if (profileDataTypes) {
                this.organizationDataTypes = profileDataTypes
                    .filter((d) => d.dataType?.category === DataTypeCategory.ORGANIZATION && d.enabled)
                    .map((d) => d)
                    .sort((a, b) => a.dataType?.name.localeCompare(b.dataType?.name));
                this.customerDataTypes = profileDataTypes
                    .filter((d) => d.dataType?.category === DataTypeCategory.CUSTOMER_OR_PARTNER && d.enabled)
                    .map((d) => d)
                    .sort((a, b) => a.dataType?.name.localeCompare(b.dataType?.name));
            }
        });
    }

    public getSensitivityValueForBar(sensitivity: number): number {
        return +(sensitivity * 100).toFixed(2);
    }
}
