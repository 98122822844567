import { Routes } from '@angular/router';

import { AuthGuard } from '../../shared';
import { ContactPopupComponent } from './contact-dialog.component';
import { VisoUserRole } from '../../entities/viso-user';

export const contactPopupRoute: Routes = [
    {
        path: 'user-new',
        component: ContactPopupComponent,
        data: {
            authorities: [VisoUserRole.OrgAdmin],
            pageTitle: 'User',
        },
        canActivate: [AuthGuard],
        outlet: 'popup',
    },
    {
        path: 'user/:id/edit',
        component: ContactPopupComponent,
        data: {
            authorities: [VisoUserRole.OrgAdmin],
            pageTitle: 'Users',
        },
        canActivate: [AuthGuard],
        outlet: 'popup',
    },
    {
        path: 'user/:id/transfer',
        data: {
            authorities: [VisoUserRole.OrgAdmin],
            pageTitle: 'Users',
        },
        children: [],
        canActivate: [AuthGuard],
        outlet: 'popup',
    },
];
