import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { LayoutType } from '@shared/model/layout-type';
import { AppState } from '@shared/redux/state';
import { LayoutState, headerStateKey } from './layout-state';
import { SpotlightTourType } from '@shared/spotlight-tour/steps';

export const getHeaderState: MemoizedSelector<AppState, LayoutState> =
    createFeatureSelector<LayoutState>(headerStateKey);

export const getLayoutType: MemoizedSelector<AppState, LayoutType> = createSelector(
    getHeaderState,
    (state) => state.layout,
);

export const getAssessmentHeaderColor: MemoizedSelector<AppState, string> = createSelector(
    getHeaderState,
    (state) => state.assessmentHeaderColor,
);

export const getAssessmentLogoVisible: MemoizedSelector<AppState, boolean> = createSelector(
    getHeaderState,
    (state) => state.assessmentLogoVisible,
);

export const getCurrentPageTourType: MemoizedSelector<AppState, SpotlightTourType> = createSelector(
    getHeaderState,
    (state) => state.currentPageTourType,
);

export const currentPageSupportsSpotlightTour: MemoizedSelector<AppState, boolean> = createSelector(
    getCurrentPageTourType,
    (tourType) => !!tourType,
);
