import { Injectable } from '@angular/core';
import { ControlValidationStatus, FileArtifact } from '../../../entities/artifact';
import { ControlDomain } from '../../../entities/control-domain';
import { DetectionItem } from '../models/detection';

@Injectable()
export class DetectionsHelperService {
    getControlsValidatedCount(artifact: FileArtifact): number {
        const controlValidations = artifact.validation?.detectedControls || [];
        const uniqueControlDomainIds = new Set(
            controlValidations
                .filter((cv) => cv.controlValidationStatus !== ControlValidationStatus.UNVALIDATED)
                .map((cv) => cv.controlDomainId),
        );
        return uniqueControlDomainIds.size;
    }

    getDetectionsList(artifact: FileArtifact, controlDomains: ControlDomain[]): DetectionItem[] {
        const controlValidations = artifact.validation?.detectedControls || [];
        return controlValidations
            .flatMap((validation) =>
                validation?.validationDetections?.map<DetectionItem>((detection) => {
                    const {
                        id,
                        controlDomainId,
                        controlId,
                        notes: detectedLanguage,
                        type,
                        controlIdsText: controlIds,
                        viewerPage: location,
                    } = detection;
                    const controlDomain = controlDomains.find((controlDomain) => controlDomain.id === controlDomainId);
                    const controlDomainName = controlDomain?.name;
                    const controlName = controlDomain?.controls?.find((control) => control.id === controlId)?.name;
                    const parsedFirstPageAsString = location?.match(/\d+/)?.[0];
                    const parsedFirstPage = !!parsedFirstPageAsString ? +parsedFirstPageAsString : undefined;
                    return {
                        id,
                        controlDomainId,
                        controlDomainName,
                        controlId,
                        controlName,
                        detectedLanguage,
                        type,
                        controlIds,
                        location,
                        parsedFirstPage,
                    };
                }),
            )
            .sort((a, b) => +a.parsedFirstPage - +b.parsedFirstPage);
    }
}
