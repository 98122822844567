<form id="contactReassignForm" [formGroup]="contactReassignFormGroup" (ngSubmit)="reassign()">
    <div *ngIf="!onlyShowReassignmentForm">
        <div class="modal-header">
            <h4 class="modal-title" id="myContactLabel">Reassign</h4>
            <button type="button" class="btn-close" data-dismiss="modal" aria-hidden="true" (click)="clear()"></button>
        </div>
        <div class="modal-body py-0">
            <p class="mt-3 mb-1">
                Select what you want to reassign from <span class="text-bold">{{ contactFullName }}</span
                >.
            </p>
        </div>

        <div class="modal-body col mw-60">
            <app-checkbox-group
                [direction]="'column'"
                [checkboxGroupName]="'selectReassignmentRadioButtonGroup'"
                [formControl]="reassignType"
            >
                <ng-template checkbox-group-item [value]="ReassignType.SUBSCRIPTIONS" let-forItem="forItem">
                    <span>{{ contact?.subscriptionCount }} subscriptions</span>
                </ng-template>

                <ng-template checkbox-group-item [value]="ReassignType.OWNERSHIPS" let-forItem="forItem">
                    <span>{{ contact?.businessOwnerCount }} ownerships</span>
                </ng-template>
            </app-checkbox-group>
        </div>
    </div>
    <div class="modal-body">
        <p class="mb-2" *ngIf="!onlyShowReassignmentForm">Select a contact to reassign these to.</p>
        <label for="field_reassignTo" class="text-bold mb-0">Contact</label>
        <div class="form-group row">
            <div class="col-lg-12">
                <div *ngIf="(contacts$ | async)?.length" class="list-group pt-2">
                    <ngx-select
                        optionValueField="ref"
                        optionTextField="name"
                        [items]="contactsSelectOptions$ | async"
                        placeholder="Select a contact"
                        (select)="contactSelected($event)"
                        [isFocused]="true"
                        required
                    >
                        <ng-template ngx-select-option let-option>
                            <div [ngClass]="{ 'border-bottom pb-1': option.value.email === currentAccount?.email }">
                                <span
                                    >{{ option.value.firstName }} {{ option.value.lastName }} •
                                    {{ option.value.email }}</span
                                >
                                <span *ngIf="option.value.email === currentAccount?.email"> • (you)</span>
                                <span class="text-muted d-block">{{ option.value.businessUnitName }}</span>
                            </div>
                        </ng-template>
                    </ngx-select>
                </div>
            </div>
        </div>
    </div>

    <ng-container *ngIf="!onlyShowReassignmentForm">
        <hr class="mt-0" />
        <button
            type="submit"
            mat-button
            visoButton="primary"
            class="float-end me-3 mb-3"
            data-pw="saveContactButton"
            toggleDisableOnActions
            [disablingActions]="[ContactActions.ReassignContactRelationshipsRequest]"
            [enablingActions]="[
                ContactActions.ReassignContactRelationshipsRequestSuccess,
                ContactActions.ReassignContactRelationshipsRequestFailed,
            ]"
            [disabled]="contactReassignFormGroup.invalid"
        >
            <span>Reassign</span>
        </button>
        <button
            type="button"
            mat-button
            visoButton="secondary"
            class="float-end me-2 mb-3"
            data-dismiss="modal"
            (click)="clear()"
        >
            <span>Cancel</span>
        </button>
    </ng-container>
</form>
