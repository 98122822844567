import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { ContextType } from '@entities/context-type';
import { AUTOMATION_EMAIL } from '@shared/constants/email.constants';
import { VENDOR_AGREEMENT_URL } from '@shared/constants/url.constants';
import { CollectionTypes } from '../../../models';

@Component({
    selector: 'app-welcome-step',
    templateUrl: './welcome-step.component.html',
    styleUrls: ['./welcome-step.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WelcomeStepComponent {
    @Input({ required: true })
    senderEmail: string;

    @Input({ required: true })
    clientName: string;

    @Input({ required: true })
    businessCases: ContextType[];

    @Input({ required: true })
    isExpirationExtensionAllowed: boolean;

    @Input({ required: true })
    vendorName: string;

    @Input({ required: true })
    showDeclineRemediation: boolean;

    @Input({ required: true })
    riskDimensionsPopoverTemplate: TemplateRef<any>;

    @Input({ required: true })
    collectionType: CollectionTypes;

    @Input({ required: true })
    isRemediationAssessment: boolean;

    @Input({ required: true })
    remediationTargetDate: Date;

    @Input({ required: true })
    followupRequested: boolean;

    @Output()
    beginCollection = new EventEmitter<void>();

    @Output()
    extendExpiration = new EventEmitter<void>();

    @Output()
    forwardRequest = new EventEmitter<void>();

    @Output()
    optOut = new EventEmitter<void>();

    @Output()
    declineRemediation = new EventEmitter<void>();

    @Output()
    navigateToQuestionnaire = new EventEmitter<void>();

    readonly VENDOR_AGREEMENT_URL = VENDOR_AGREEMENT_URL;
    readonly CollectionTypes = CollectionTypes;

    get sentByAutomation(): boolean {
        return this.senderEmail?.toLowerCase() === AUTOMATION_EMAIL;
    }

    get title(): string {
        if (this.followupRequested) {
            return 'We have a few more questions about your program.';
        }

        switch (this.collectionType) {
            case CollectionTypes.RECERTIFICATION:
                return "Let's keep your relationship up to date.";
            case CollectionTypes.ARTIFACT_UPDATE:
                return "Let's keep your artifacts up to date.";
            default:
                return this.isRemediationAssessment
                    ? "Let's work to remediate gaps in this relationship"
                    : 'Goodbye, questionnaire. Hello, partnership.';
        }
    }
}
