<quill-editor
    [formControl]="editorFormControl"
    [styles]="styles"
    [modules]="modules"
    [formats]="formats"
    [placeholder]="placeholder"
    (onEditorCreated)="onEditorCreated()"
    [sanitize]="true"
>
    <div quill-editor-toolbar>
        <span class="ql-formats">
            <button class="ql-bold"></button>
            <button class="ql-italic"></button>
            <button class="ql-underline"></button>
            <button class="ql-strike"></button>
        </span>
        <span class="ql-formats">
            <button class="ql-list" value="ordered"></button>
            <button class="ql-list" value="bullet"></button>
        </span>
        <ng-container *ngIf="fastMentions$ | async as fastMentions">
            <span *ngIf="fastMentions.length" class="ql-formats fast-mentions-container">
                <button
                    class="mention-button fw-bold"
                    [tooltip]="fastMention.mentionTooltip"
                    [container]="'body'"
                    *ngFor="let fastMention of fastMentions"
                    (click)="insertMention(fastMention.mention)"
                >
                    &#64;{{ fastMention.mention.value }}
                </button>
            </span>
        </ng-container>
    </div>
</quill-editor>
