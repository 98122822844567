import { Injectable } from '@angular/core';
import { RemediationStatus } from '@entities/remediation-request/remediation-request.model';

export function isRemediationActive(status: RemediationStatus): boolean {
    return status === RemediationStatus.REQUESTED || status === RemediationStatus.ARTIFACTS_PROVIDED;
}

export function isRemediationRequested(status: RemediationStatus): boolean {
    return status === RemediationStatus.REQUESTED;
}

export function isRemediationCancelled(status: RemediationStatus): boolean {
    return status === RemediationStatus.CANCELLED;
}

export function isRemediationDeclined(status: RemediationStatus): boolean {
    return status === RemediationStatus.DECLINED;
}

@Injectable({
    providedIn: 'root',
})
export class RemediationUtilsService {
    isRemediationActive = isRemediationActive;

    isRemediationRequested = isRemediationRequested;

    isRemediationCancelled = isRemediationCancelled;

    isRemediationDeclined = isRemediationDeclined;
}
