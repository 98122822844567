import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PrimaryVendorContact } from '../../primary-vendor-contact';
import {
    addPrimaryVendorContactRequest,
    addPrimaryVendorContactRequestFailed,
    addPrimaryVendorContactRequestSuccess,
    removePrimaryVendorContactRequest,
    RequestActions,
} from '../../request/redux/request.actions';

@Component({
    selector: 'app-edit-relationship-third-party-contact',
    templateUrl: './edit-relationship-third-party-contact.component.html',
    styleUrls: ['./edit-relationship-third-party-contact.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditRelationshipThirdPartyContactComponent implements OnInit, OnDestroy {
    @Input() requestId: number;
    @Input() primaryVendorContact: PrimaryVendorContact;
    primaryVendorContactUpdated: PrimaryVendorContact;
    RequestActions = RequestActions;
    formStatus: string;
    private _unsub$ = new Subject<void>();

    constructor(
        private _store$: Store,
        private _actions$: Actions,
        private _activeModal: NgbActiveModal,
    ) {}

    ngOnInit(): void {
        this._actions$
            .pipe(
                ofType(addPrimaryVendorContactRequestSuccess, addPrimaryVendorContactRequestFailed),
                takeUntil(this._unsub$),
            )
            .subscribe(() => {
                this.close();
            });
    }

    close(): void {
        this._activeModal.close();
    }

    save(primaryVendorContact: PrimaryVendorContact): void {
        this._store$.dispatch(addPrimaryVendorContactRequest({ primaryVendorContact }));
        this._activeModal.close();
    }

    removeContact(): void {
        const [relationshipId, primaryContactId, primaryContactEmail] = [
            this.requestId,
            this.primaryVendorContact.id,
            this.primaryVendorContact.email,
        ];
        this._store$.dispatch(
            removePrimaryVendorContactRequest({ relationshipId, primaryContactId, primaryContactEmail }),
        );
        this._activeModal.close();
    }

    ngOnDestroy(): void {
        this._unsub$.next();
    }
}
