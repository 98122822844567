import { Routes } from '@angular/router';
import { AuthGuard } from '../shared';
import { VisoUserRole } from '@entities/viso-user';
import { FeatureFlagGuard } from '@shared/guards/feature-flag.guard';
import { FeatureFlags } from '@shared/enums/feature-flags';
import { Error404Component } from './pages/error404/error404.component';
import { Error500Component } from './pages/error500/error500.component';
import { ComingSoonComponent } from './pages/coming-soon/coming-soon.component';
import { addRelationshipRoute } from './add-relationship/add-relationship.constants';
import { pdfArtifactViewRoute } from './pdf-artifact-viewer/pdf-artifact-viewer.constants';
import {
    assessmentDetailsRoute,
    uploadArtifactForAssessmentDetailsRoute,
} from './assessment-details/assessment-details-common/constants';
import { ErrorComponent } from './pages/error/error.component';
import { artifactTypesRoute } from './artifact-types/artifact-types.constants';
import { assessmentEmailViewRoute } from './assessment-email-viewer/assessment-email-viewer.constants';
import { riskAdvisoryRoute } from './risk-advisory/risk-advisory.constants';
import {
    artifactUpdateCollectionRoute,
    assessmentCollectionRoute,
    recertificationCollectionRoute,
} from './assessment-collection/assessment-collection-common/route-constants';

export const routes: Routes = [
    {
        path: '',
        redirectTo: 'relationships',
        pathMatch: 'full',
    },
    {
        path: 'insights',
        loadChildren: () =>
            import('./metabase-dashboard/metabase-dashboard.module').then((m) => m.MetabaseDashboardModule),
    },
    {
        path: '404',
        component: Error404Component,
    },
    {
        path: '500',
        component: Error500Component,
    },
    {
        path: 'accessdenied',
        component: Error500Component,
    },
    {
        path: 'invalid-login',
        data: {
            errorCode: 'Invalid Login!',
            errorMessage:
                'You cannot log in with an email domain in our exclusion list.\nPlease use a company email address.',
        },
        component: ErrorComponent,
    },
    {
        path: 'cannot-register-under-existing-client',
        data: {
            errorCode: 'Your company is already a VISO TRUST customer!',
            errorMessage:
                "We couldn't create a new VISO account for you.\nThere is already an enterprise account associated with your organization.",
        },
        component: ErrorComponent,
    },
    {
        path: 'coming-soon',
        component: ComingSoonComponent,
    },
    {
        path: 'intelligent-questionnaire-response',
        loadChildren: () =>
            import('./trust-profile/questionnaire-answering/questionnaire-answering.module').then(
                (m) => m.QuestionnaireAnsweringModule,
            ),
        data: {
            featureFlag: FeatureFlags.TRUST,
        },
        canActivate: [FeatureFlagGuard],
    },
    {
        path: 'ai-question-and-answer',
        loadChildren: () =>
            import('./trust-profile/ai-question-answering/ai-question-answering.module').then(
                (m) => m.AiQuestionAnsweringModule,
            ),
        data: {
            featureFlag: FeatureFlags.TRUST,
        },
        canActivate: [FeatureFlagGuard],
    },
    {
        path: 'artifact-intelligence',
        loadChildren: () =>
            import('./trust-profile/artifact-intelligence/trust-profile-artifact-intelligence.module').then(
                (m) => m.TrustProfileArtifactIntelligenceModule,
            ),
        data: {
            featureFlag: FeatureFlags.TRUST,
        },
        canActivate: [FeatureFlagGuard],
    },
    {
        path: 'artifact-intelligence/upload-artifacts',
        data: {
            authorities: [VisoUserRole.OrgAdmin],
            featureFlag: FeatureFlags.TRUST,
        },
        children: [],
        canActivate: [AuthGuard, FeatureFlagGuard],
        outlet: 'popup',
    },
    {
        path: 'directory',
        loadChildren: () => import('./vendor-directory/vendor-directory.module').then((m) => m.VendorDirectoryModule),
    },
    {
        path: addRelationshipRoute,
        data: {
            authorities: [VisoUserRole.OrgAdmin, VisoUserRole.OrgUser],
        },
        loadChildren: () => import('./add-relationship/add-relationship.module').then((m) => m.AddRelationshipModule),
        canActivate: [AuthGuard],
        outlet: 'popup',
    },
    {
        path: pdfArtifactViewRoute,
        loadChildren: () =>
            import('./pdf-artifact-viewer/pdf-artifact-viewer.module').then((m) => m.PdfArtifactViewerModule),
        canActivate: [AuthGuard],
        outlet: 'popup',
    },
    {
        path: assessmentEmailViewRoute,
        loadChildren: () =>
            import('./assessment-email-viewer/assessment-email-viewer.module').then(
                (m) => m.AssessmentEmailViewerModule,
            ),
        canActivate: [AuthGuard],
        outlet: 'popup',
    },
    {
        path: assessmentDetailsRoute,
        loadChildren: () =>
            import('./assessments-overview/assessments-overview.module').then((m) => m.AssessmentsOverviewModule),
    },
    {
        path: assessmentDetailsRoute,
        loadChildren: () =>
            import('./assessment-details/assessment-details-page/assessment-details-page.module').then(
                (m) => m.AssessmentDetailsPageModule,
            ),
    },
    {
        path: uploadArtifactForAssessmentDetailsRoute,
        data: {
            authorities: [VisoUserRole.Auditor],
        },
        children: [],
        canActivate: [AuthGuard],
        outlet: 'popup',
    },
    {
        path: assessmentCollectionRoute,
        loadChildren: () =>
            import('./assessment-collection/assessment-collection-page/assessment-collection-page.module').then(
                (m) => m.AssessmentCollectionPageModule,
            ),
    },
    {
        path: recertificationCollectionRoute,
        loadChildren: () =>
            import(
                './assessment-collection/recertification-collection-page/recertification-collection-page.module'
            ).then((m) => m.RecertificationCollectionPageModule),
    },
    {
        path: artifactUpdateCollectionRoute,
        loadChildren: () =>
            import(
                './assessment-collection/artifact-update-collection-page/artifact-update-collection-page.module'
            ).then((m) => m.ArtifactUpdateCollectionPageModule),
    },
    {
        path: artifactTypesRoute,
        loadChildren: () => import('./artifact-types/artifact-types.module').then((m) => m.ArtifactTypesModule),
    },
    {
        path: riskAdvisoryRoute,
        loadChildren: () => import('./risk-advisory/risk-advisory.module').then((m) => m.RiskAdvisoryModule),
    },
    {
        path: '#/*',
        redirectTo: '*',
    },
    {
        path: '**',
        component: Error404Component,
    },
];
