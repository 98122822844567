import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'pascalCase',
})
export class PascalCasePipe implements PipeTransform {
    transform(value: string) {
        return value
            .match(/[a-z]+/gi)
            .map((word: string) => {
                return word.charAt(0).toUpperCase() + word.substr(1).toLowerCase();
            })
            .join('')
            .replace(/([A-Z+])/g, ' $1')
            .trim();
    }
}
