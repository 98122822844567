<div *dynamicContent="breadcrumbsContainerToken">
    <ol class="breadcrumb breadcrumb px-0 pb-0">
        <li class="breadcrumb-item"><a [routerLink]="['/']">Home</a></li>
        <li class="breadcrumb-item active">Tags</li>
    </ol>
</div>

<div class="content-heading">
    <div>Tags</div>
</div>

<div class="card card-border d-flex justify-content-center">
    <p class="m-3">
        Tags are a flexible way to classify and group third parties. Add tags to each vendor individually or bulk add
        tags on the relationship list page. Use these tags to filter on the relationship list and risk insights
        dashboard for better organization. Examples of tags include risk tiering and the product lines a vendor
        supports.
    </p>
</div>

<div class="card card-border d-flex">
    <ng-container *ngIf="tags$ | async as tags">
        <div class="card-header px-4 py-3">
            <ng-container *ngIf="pagination$ | async as pagination">
                <a class="text-bold">{{ pagination?.total || 0 }} Tag{{ pagination?.total !== 1 ? 's' : '' }}</a>
            </ng-container>
            <button
                *jhiHasAnyAuthority="Roles.OrgAdmin"
                mat-button
                visoButton="primary"
                class="float-end"
                [routerLink]="['/', { outlets: { popup: ['tag-new'] } }]"
            >
                Add Tag
            </button>
        </div>

        <ng-container *ngIf="tags.length > 0; else noTagsTemplate">
            <div class="row table-container px-4">
                <div class="col">
                    <ng-container *ngIf="pagination$ | async as pagination">
                        <ngx-datatable
                            #contactList
                            class="bootstrap"
                            [columnMode]="'force'"
                            [footerHeight]="40"
                            [rowHeight]="35"
                            [headerHeight]="30"
                            [scrollbarV]="true"
                            [externalSorting]="true"
                            [observeDims]="true"
                            data-pw="contactListTable"
                            [externalPaging]="true"
                            [virtualization]="false"
                            [rows]="tags"
                            [loadingIndicator]="loading$ | async"
                            (sort)="onSort($event)"
                            [sorts]="pagination.sort | callback: transformSort"
                            [count]="pagination.total"
                            [offset]="pagination.page"
                            [limit]="pagination.size"
                        >
                            <ngx-datatable-column name="Name" prop="name">
                                <ng-template let-row="row" ngx-datatable-cell-template>
                                    <span [tagColor]="row.color">{{ row.name }}</span>
                                </ng-template>
                            </ngx-datatable-column>

                            <ngx-datatable-column name="Usage Count" prop="numberOfUses">
                                <ng-template let-row="row" ngx-datatable-cell-template>
                                    {{ row.numberOfUses }}
                                </ng-template>
                            </ngx-datatable-column>

                            <ngx-datatable-column name="Last Used" prop="lastUsedDate">
                                <ng-template let-row="row" ngx-datatable-cell-template>{{
                                    row.lastUsedDate | date
                                }}</ng-template>
                            </ngx-datatable-column>

                            <ngx-datatable-column
                                *jhiHasNotAuthority="[Roles.OrgUser, Roles.ReadOnly]"
                                [width]="40"
                                cellClass="order-50 text-center bg-white"
                                headerClass="order-50"
                                [resizeable]="false"
                                [sortable]="false"
                                [canAutoResize]="false"
                                [draggable]="false"
                                [frozenRight]="false"
                            >
                                <ng-template let-row="row" ngx-datatable-cell-template>
                                    <div class="additional-actions" placement="bottom left" dropdown container="body">
                                        <button
                                            id="button-container"
                                            dropdownToggle
                                            type="button"
                                            class="btn-icon float-end"
                                            aria-controls="dropdown-container"
                                        >
                                            <em class="fa fa-ellipsis-v"></em>
                                        </button>
                                        <ul
                                            id="dropdown-container"
                                            *dropdownMenu
                                            class="dropdown-menu dropdown-menu-end"
                                            aria-labelledby="button-container"
                                        >
                                            <li role="menuitem">
                                                <a
                                                    class="dropdown-item"
                                                    [routerLink]="[
                                                        '/',
                                                        { outlets: { popup: 'tag/' + row.id + '/edit' } },
                                                    ]"
                                                >
                                                    Edit
                                                </a>
                                            </li>
                                            <hr class="m-0 border-2" />
                                            <li role="menuitem">
                                                <a class="dropdown-item text-danger" (click)="deleteTag(row)">
                                                    Delete
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </ng-template>
                            </ngx-datatable-column>

                            <ngx-datatable-footer>
                                <ng-template
                                    ngx-datatable-footer-template
                                    let-page="page"
                                    let-rowCount="rowCount"
                                    let-pageSize="pageSize"
                                    let-curPage="curPage"
                                >
                                    <datatable-pager
                                        [pagerLeftArrowIcon]="'datatable-icon-left'"
                                        [pagerRightArrowIcon]="'datatable-icon-right'"
                                        [pagerPreviousIcon]="'datatable-icon-prev'"
                                        [pagerNextIcon]="'datatable-icon-skip'"
                                        [page]="curPage"
                                        [size]="pageSize"
                                        [count]="rowCount"
                                        [hidden]="!(rowCount / pageSize > 1)"
                                        (change)="setCurrentPage($event)"
                                    ></datatable-pager>
                                </ng-template>
                            </ngx-datatable-footer>
                        </ngx-datatable>
                    </ng-container>
                </div>
            </div>
        </ng-container>
        <ng-template #noTagsTemplate>
            <ng-container *ngIf="loading$ | async; else noTagsCreatedTemplate">
                <div class="d-flex align-items-center justify-content-center">
                    <div class="sk-spinner sk-spinner-pulse"></div>
                </div>
            </ng-container>
        </ng-template>

        <ng-template #noTagsCreatedTemplate>
            <div class="row" *jhiHasNotAuthority="[Roles.OrgUser, Roles.ReadOnly]">
                <div class="block-center mt-4 wd-xl">
                    <div class="card card-flat">
                        <div class="card-body">
                            <p class="text-center">Create a tag by selecting "Add Tag"</p>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
    </ng-container>
</div>
