import { importRelationshipsRoutes } from './bulk-import.route';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { SharedModule } from '../../shared';
import { RouterModule } from '@angular/router';
import { NgxSelectModule } from 'ngx-select-ex';
import { BulkImportComponent } from './bulk-import.component';
import { FileUploadModule } from '../../shared/file-upload';
import { BulkImportService } from './bulk-import.service';
import { ImportRelationshipsComponent } from './import-relationships/import-relationships.component';
import { ImportHistoryComponent } from './import-history/import-history.component';
import { EffectsModule } from '@ngrx/effects';
import { BulkImportEffects } from './redux/bulk-import.effects';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { MatButtonModule } from '@angular/material/button';
import { MatRadioModule } from '@angular/material/radio';

const ENTITY_STATES = [...importRelationshipsRoutes];

@NgModule({
    imports: [
        SharedModule,
        RouterModule.forChild(ENTITY_STATES),
        NgxSelectModule,
        FileUploadModule,
        EffectsModule.forFeature([BulkImportEffects]),
        NgxDatatableModule,
        MatButtonModule,
        MatRadioModule,
    ],
    declarations: [BulkImportComponent, ImportRelationshipsComponent, ImportHistoryComponent],
    providers: [BulkImportService],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class BulkImportModule {
    constructor() {}
}
