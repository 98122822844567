import { OnInit, Directive, Input, TemplateRef } from '@angular/core';

@Directive({
    selector: '[radiobutton-group-item]',
})
export class RadioButtonGroupItemDirective implements OnInit {
    @Input('value')
    value: unknown;

    @Input('disabled')
    disabled: boolean;

    constructor(public templateRef: TemplateRef<unknown>) {}

    ngOnInit(): void {}
}
