export enum AssessmentSummarySection {
    RISK_SUMMARY = 'RISK_SUMMARY',
    RELATIONSHIP_CONTEXT = 'RELATIONSHIP_CONTEXT',
    ARTIFACT_COLLECTION = 'ARTIFACT_COLLECTION',
    RISK_ANALYSIS = 'RISK_ANALYSIS',
    PRIVACY_EVALUATION = 'PRIVACY_EVALUATION',
    RECOMMENDATIONS = 'RECOMMENDATIONS',
}

export enum AssessmentSummarySectionLabels {
    RISK_SUMMARY = 'Risk Summary',
    RELATIONSHIP_CONTEXT = 'Relationship Context',
    ARTIFACT_COLLECTION = 'Artifact Collection',
    RISK_ANALYSIS = 'Risk Analysis',
    PRIVACY_EVALUATION = 'Privacy Evaluation',
    RECOMMENDATIONS = 'Recommendations',
}

export interface UpdateAssessmentSummarySectionConfigRequest {
    assessmentSummarySectionConfig: Record<AssessmentSummarySection, boolean>;
}
