import { Component, Input } from '@angular/core';
import { Options } from 'ngx-slider-v2';
import { ControlDomain } from '../../../entities/control-domain';

@Component({
    selector: 'app-controls-list',
    templateUrl: './controls-list.component.html',
    styleUrls: ['./controls-list.component.scss'],
})
export class ControlsListComponent {
    @Input() controlDomains: ControlDomain[];

    sliderOptions: Options = {
        floor: 0,
        ceil: 4,
        step: 1,
        readOnly: true,
        disabled: true,
        showSelectionBar: true,
        selectionBarGradient: {
            from: '#FFF',
            to: '#00838F',
        },
        hideLimitLabels: true,
        hidePointerLabels: true,
    };
}
