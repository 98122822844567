import { Pipe, PipeTransform } from '@angular/core';
import { filter } from 'lodash';

@Pipe({
    name: 'pureFilter',
})
export class PureFilterPipe implements PipeTransform {
    transform(array: any, predicate: object): any[] {
        return filter(array, predicate);
    }
}
