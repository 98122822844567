import { Injectable } from '@angular/core';
import * as moment from 'moment';

const enum timePeriod {
    justNow,
    today,
    yesterday,
    older,
}

@Injectable({
    providedIn: 'root',
})
export class TimeService {
    checkTimeDiff(time: Date): timePeriod {
        let period;
        const currentTime = moment();
        const yesterday = moment().subtract(1, 'day');
        const timeStore = moment(time);

        if (currentTime.diff(timeStore, 'minute') < 0) {
            period = timePeriod.older;
        } else if (currentTime.diff(timeStore, 'minute') < 60) {
            period = timePeriod.justNow;
        } else if (timeStore.isSame(currentTime, 'day')) {
            period = timePeriod.today;
        } else if (timeStore.isSame(yesterday, 'day')) {
            period = timePeriod.yesterday;
        } else {
            period = timePeriod.older;
        }
        return period;
    }

    humanizeDate(time: Date): string {
        const date = moment(time);
        const currentTime = moment();
        let message = '';

        if (date.isValid()) {
            const period = this.checkTimeDiff(time);
            switch (period) {
                case timePeriod.justNow:
                    message = `About ${currentTime.diff(date, 'minutes')} minutes ago`;
                    break;
                case timePeriod.today:
                    const hoursPast = currentTime.diff(date, 'hour');
                    message = `${hoursPast} hour${hoursPast > 1 ? 's' : ''} ago`;
                    break;
                case timePeriod.yesterday:
                    message = `Yesterday, ${moment(time).format('hh:mm A')}`;
                    break;
                case timePeriod.older:
                    message = moment(time).format('MMM DD, hh:mm a');
                    break;
            }
        } else {
            message = 'Invalid date.';
        }
        return message;
    }
}
