import { createAction } from '@ngrx/store';

export enum AssessmentCollectionCommonExpiredDocumentsScreenActions {
    ExpiredDocumentsScreenContinued = '[Assessment Collection Common Expired Documents Screen] Expired Documents Screen Continued',
    ExpiredDocumentsScreenSkipped = '[Assessment Collection Common Expired Documents Screen] Expired Documents Screen Skipped',
    ExpiredDocumentsScreenWentBack = '[Assessment Collection Common Expired Documents Screen] Expired Documents Screen Went Back',
}

export const expiredDocumentsScreenContinued = createAction(
    AssessmentCollectionCommonExpiredDocumentsScreenActions.ExpiredDocumentsScreenContinued,
);

export const expiredDocumentsScreenSkipped = createAction(
    AssessmentCollectionCommonExpiredDocumentsScreenActions.ExpiredDocumentsScreenSkipped,
);

export const expiredDocumentsScreenWentBack = createAction(
    AssessmentCollectionCommonExpiredDocumentsScreenActions.ExpiredDocumentsScreenWentBack,
);
