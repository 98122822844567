import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-coming-soon',
    templateUrl: './coming-soon.component.html',
    styleUrls: [],
})
export class ComingSoonComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
