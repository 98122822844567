import { Injectable } from '@angular/core';
import { Action, Store } from '@ngrx/store';
import { Actions, OnInitEffects, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap, tap, withLatestFrom } from 'rxjs';
import { RelationshipService } from '../../../../entities/relationship';
import {
    askQuestionRequest,
    askQuestionRequestFailed,
    askQuestionRequestSuccess,
    loadLocalStorageIiqrResponsesState,
} from '../actions/question-answering.actions';
import { getIiqrResponsesState } from '../request.selectors';

@Injectable()
export class QuestionAnsweringEffects implements OnInitEffects {
    askQuestionRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(askQuestionRequest),
            switchMap(({ relationshipId, question }) =>
                this._requestService.askQuestion(relationshipId, question).pipe(
                    map((iqrResponse) => askQuestionRequestSuccess({ relationshipId, iqrResponse })),
                    catchError((error) => of(askQuestionRequestFailed({ relationshipId, error }))),
                ),
            ),
        ),
    );

    askQuestionRequestSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(askQuestionRequestSuccess, askQuestionRequestFailed),
                withLatestFrom(this._store$.select(getIiqrResponsesState)),
                tap(([, state]) => localStorage.setItem(this._iiqrResponsesKey, JSON.stringify(state))),
            ),
        { dispatch: false },
    );

    private _iiqrResponsesKey = 'iiqrResponses';

    constructor(
        private _store$: Store,
        private _actions$: Actions,
        private _requestService: RelationshipService,
    ) {}

    ngrxOnInitEffects(): Action {
        const localStorageState = JSON.parse(localStorage.getItem(this._iiqrResponsesKey)) ?? {};
        return loadLocalStorageIiqrResponsesState({ localStorageState });
    }
}
