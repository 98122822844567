import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, identity, Observable, Subject } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import {
    deleteOrgSubdomainRequest,
    deleteOrgSubdomainRequestSuccess,
    getOrgSubdomainRequest,
    OrgSubdomainActions,
    setOrgSubdomainRequestSuccess,
} from '../org-subdomain/redux/org-subdomain.actions';
import {
    getVanityDomainsConfigRequest,
    getVanityDomainRequest,
    deleteVanityDomainRequestSuccess,
    deleteVanityDomainRequest,
    VanityDomainActions,
    createVanityDomainRequestSuccess,
    refreshVanityDomainRequest,
} from '../vanity-domain/redux/vanity-domain.actions';
import {
    getDomainVerificationsRequest,
    getPreferredEmailDomainRequest,
} from '../domain-verification/redux/domain-verification.actions';
import { DomainVerification } from '@entities/domain-verification';
import {
    getDomainVerifications,
    getHasValidDomainVerification,
    getPreferredDomainVerification,
} from '../domain-verification/redux/domain-verification.selectors';
import { VanityDomainView } from '@entities/vanity-domain';
import { getVanityDomain } from '../vanity-domain/redux/vanity-domain.selectors';
import { getOrgSubdomain, getOrgSubdomainDomain } from '../org-subdomain/redux/org-subdomain.selectors';
import { Actions, ofType } from '@ngrx/effects';
import { openCustomizeDomainModal } from './redux/manage-domains.actions';

export enum DomainMode {
    NONE,
    VANITY,
    SUBDOMAIN,
}

@Component({
    selector: 'app-manage-domains',
    templateUrl: './manage-domains.component.html',
    styleUrls: ['./manage-domains.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ManageDomainsComponent implements OnInit, OnDestroy {
    preferredDomainVerification$: Observable<DomainVerification>;
    domainVerifications$: Observable<DomainVerification[]>;
    hasValidDomainVerification$: Observable<boolean>;
    vanityDomain$: Observable<VanityDomainView>;
    orgSubdomainDomain$: Observable<string>;
    orgSubdomain$: Observable<string>;

    DomainMode = DomainMode;
    OrgSubdomainActions = OrgSubdomainActions;
    VanityDomainActions = VanityDomainActions;

    domainMode$ = new BehaviorSubject<DomainMode>(DomainMode.NONE);

    private _unsub$ = new Subject<void>();

    @Input()
    set isTabSelected(value: boolean) {
        this._isTabSelected$.next(value);
    }

    get isTabSelected() {
        return this._isTabSelected$.value;
    }

    private _isTabSelected$ = new BehaviorSubject<boolean>(false);

    constructor(
        private _store$: Store,
        private _actions$: Actions,
    ) {}

    ngOnInit(): void {
        this._isTabSelected$.pipe(filter(identity)).subscribe(() => this.refresh());

        this.preferredDomainVerification$ = this._store$.pipe(select(getPreferredDomainVerification));
        this.domainVerifications$ = this._store$.pipe(select(getDomainVerifications));
        this.hasValidDomainVerification$ = this._store$.pipe(select(getHasValidDomainVerification));
        this.vanityDomain$ = this._store$.pipe(
            select(getVanityDomain),
            tap((domain) => {
                if (domain !== null) {
                    this.domainMode$.next(DomainMode.VANITY);
                }
            }),
        );

        this.orgSubdomain$ = this._store$.pipe(
            select(getOrgSubdomain),
            tap((subdomain) => {
                if (subdomain !== null) {
                    this.domainMode$.next(DomainMode.SUBDOMAIN);
                }
            }),
        );

        this.orgSubdomainDomain$ = this._store$.pipe(select(getOrgSubdomainDomain));

        this._actions$
            .pipe(
                ofType(deleteOrgSubdomainRequestSuccess, deleteVanityDomainRequestSuccess),
                tap(() => {
                    this.domainMode$.next(DomainMode.NONE);
                }),
                takeUntil(this._unsub$),
            )
            .subscribe();

        this._actions$
            .pipe(
                ofType(createVanityDomainRequestSuccess),
                tap(() => {
                    this.domainMode$.next(DomainMode.VANITY);
                }),
                takeUntil(this._unsub$),
            )
            .subscribe();

        this._actions$
            .pipe(
                ofType(setOrgSubdomainRequestSuccess),
                tap(() => {
                    this.domainMode$.next(DomainMode.SUBDOMAIN);
                }),
                takeUntil(this._unsub$),
            )
            .subscribe();
    }

    refresh(): void {
        this._store$.dispatch(getOrgSubdomainRequest());
        this._store$.dispatch(getVanityDomainsConfigRequest());
        this._store$.dispatch(getVanityDomainRequest());
        this._store$.dispatch(getDomainVerificationsRequest());
        this._store$.dispatch(getPreferredEmailDomainRequest());
    }

    openCustomizeDomainModal() {
        this._store$.dispatch(openCustomizeDomainModal());
    }

    resetDomain() {
        this._store$.dispatch(deleteOrgSubdomainRequest());
        this._store$.dispatch(deleteVanityDomainRequest());
    }

    onRefreshVanityDomains() {
        this._store$.dispatch(refreshVanityDomainRequest());
    }

    ngOnDestroy(): void {
        this._unsub$.next();
    }
}
