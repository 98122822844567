import { createAction, props } from '@ngrx/store';

export enum MarkActions {
    markRequestsAsArchivedRequest = '[Request] Mark Request as Archived Request',
    markRequestsAsArchivedRequestSuccess = '[Request] Mark Requests as Archived Request Success',
    markRequestAsArchived = '[Request] Mark Request As Archived',
    markRequestAsArchivedCancel = '[Request] Mark Request As Archived Cancel',
    markRequestAsArchivedRequest = '[Request] Mark Request As Archived Request',
    markRequestAsArchivedRequestSuccess = '[Request] Mark Request As Archived Request Success',
    markRequestAsArchivedRequestFailed = '[Request] Mark Request As Archived Request Failed',
    markRequestAsNotOnboarded = '[Request] Mark Request As Not Not Onboarded',
    markRequestAsNotOnboardedRequest = '[Request] Mark Request As Not Onboarded Request',
    markRequestAsNotOnboardedRequestSuccess = '[Request] Mark Request As Not Onboarded Request Success',
    markRequestAsNotOnboardedRequestFailed = '[Request] Mark Request As Not Onboarded Request Failed',
}

export const markRequestsAsArchivedRequest = createAction(
    MarkActions.markRequestsAsArchivedRequest,
    props<{ requestIds: number[] }>(),
);

export const markRequestsAsArchivedRequestSuccess = createAction(
    MarkActions.markRequestsAsArchivedRequestSuccess,
    props<{ allSuccessful: boolean }>(),
);

export const markRequestAsArchived = createAction(MarkActions.markRequestAsArchived, props<{ requestId: number }>());

export const markRequestAsArchivedCancel = createAction(MarkActions.markRequestAsArchivedCancel);

export const markRequestAsArchivedRequest = createAction(
    MarkActions.markRequestAsArchivedRequest,
    props<{ requestId: number }>(),
);

export const markRequestAsArchivedRequestSuccess = createAction(MarkActions.markRequestAsArchivedRequestSuccess);

export const markRequestAsArchivedRequestFailed = createAction(MarkActions.markRequestAsArchivedRequestFailed);

export const markRequestAsNotOnboarded = createAction(
    MarkActions.markRequestAsNotOnboarded,
    props<{ requestId: number }>(),
);

export const markRequestAsNotOnboardedRequest = createAction(
    MarkActions.markRequestAsNotOnboardedRequest,
    props<{ requestId: number }>(),
);

export const markRequestAsNotOnboardedRequestSuccess = createAction(
    MarkActions.markRequestAsNotOnboardedRequestSuccess,
);

export const markRequestAsNotOnboardedRequestFailed = createAction(MarkActions.markRequestAsNotOnboardedRequestFailed);
