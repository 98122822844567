import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { CreateUpdateRiskAdvisoryRequest, RiskAdvisory, SummaryResponse } from './risk-advisory.model';
import { createRequestOption, PaginationParams } from '../../shared';

@Injectable({
    providedIn: 'root',
})
export class RiskAdvisoryService {
    private resourceUrl = 'api/risk-advisory';

    constructor(private http: HttpClient) {}

    getAllRiskAdvisories(req?: PaginationParams): Observable<HttpResponse<RiskAdvisory[]>> {
        const options = createRequestOption(req);
        return this.http.get<RiskAdvisory[]>(this.resourceUrl, { params: options, observe: 'response' });
    }

    getAllRiskAdvisoriesForCurrentClient(req?: PaginationParams): Observable<HttpResponse<RiskAdvisory[]>> {
        const options = createRequestOption(req);
        return this.http.get<RiskAdvisory[]>(`${this.resourceUrl}/client`, { params: options, observe: 'response' });
    }

    getAllRiskAdvisoriesForVendor(vendorId: number, req?: PaginationParams): Observable<HttpResponse<RiskAdvisory[]>> {
        const options = createRequestOption(req);
        return this.http.get<RiskAdvisory[]>(`${this.resourceUrl}/vendor/${vendorId}`, {
            params: options,
            observe: 'response',
        });
    }

    createRiskAdvisory(request: CreateUpdateRiskAdvisoryRequest): Observable<void> {
        return this.http.post<void>(`${this.resourceUrl}`, request);
    }

    updateRiskAdvisory(request: CreateUpdateRiskAdvisoryRequest): Observable<void> {
        return this.http.put<void>(`${this.resourceUrl}/`, request);
    }

    deleteRiskAdvisory(riskAdvisoryId: string): Observable<void> {
        return this.http.delete<void>(`${this.resourceUrl}/${riskAdvisoryId}`);
    }

    startImportProcess(): Observable<any> {
        return this.http.post(`${this.resourceUrl}/import`, { responseType: 'text' });
    }

    summarizeRiskAdvisoryLink(url: string): Observable<SummaryResponse> {
        return this.http.post<SummaryResponse>(`${this.resourceUrl}/summarize`, url);
    }
}
