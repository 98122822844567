<div class="color-picker-wrapper" [ngClass]="size">
    <div ngbDropdown class="d-inline-block">
        <button
            type="button"
            class="btn-trigger"
            mat-button
            visoButton="secondary"
            id="dropdownColorPicker"
            ngbDropdownToggle
            [disabled]="disabled"
        >
            <ng-template
                [ngTemplateOutlet]="colorRepresentationTemplate"
                [ngTemplateOutletContext]="{ colorKey: value }"
            ></ng-template>
        </button>
        <div ngbDropdownMenu class="color-picker-menu" aria-labelledby="dropdownColorPicker">
            <div class="custom-grid-items">
                <button
                    ngbDropdownItem
                    type="button"
                    *ngFor="let color of TagColorEnum | enumKeysToArray"
                    [ngClass]="{ selected: value === color }"
                    (click)="selectColor(color)"
                >
                    <ng-template
                        [ngTemplateOutlet]="colorRepresentationTemplate"
                        [ngTemplateOutletContext]="{ colorKey: color }"
                    ></ng-template>
                </button>
            </div>
        </div>
    </div>
</div>

<ng-template #colorRepresentationTemplate let-colorKey="colorKey">
    <div class="color-wrapper">
        <div class="main-color" [style.background]="colorValues[colorKey]?.primary"></div>
        <div class="background-color" [style.background]="colorValues[colorKey]?.background"></div>
    </div>
</ng-template>
