import { Injectable, Type } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { HttpResponse } from '@angular/common/http';
import { NgbModalWrapperService } from '../../shared';
import { Org, OrgDetailsResponse, OrgService, OrgUrlType } from '../../entities/org';

@Injectable()
export class YourOrganizationPopupService {
    private ngbModalRef: NgbModalRef;

    constructor(
        private _modalService: NgbModalWrapperService,
        private _orgService: OrgService,
    ) {
        this.ngbModalRef = null;
    }

    open(component: Type<any>): Promise<NgbModalRef> {
        return new Promise<NgbModalRef>((resolve, reject) => {
            const isOpen = this.ngbModalRef !== null;
            if (isOpen) {
                resolve(this.ngbModalRef);
            }
            this._orgService.getCurrentOrg().subscribe((orgResponse: HttpResponse<OrgDetailsResponse>) => {
                const org: OrgDetailsResponse = orgResponse.body;
                org.privacyPolicyUrl = orgResponse.body.urls[OrgUrlType.PRIVACY_POLICY];
                this.ngbModalRef = this.orgModalRef(component, org);
                resolve(this.ngbModalRef);
            });
        });
    }

    orgModalRef(component: Type<any>, org: Org): NgbModalRef {
        const modalRef = this._modalService.open(
            component,
            {
                org,
            },
            { size: 'lg', backdrop: 'static' },
        );
        return modalRef;
    }
}
