import { Route } from '@angular/router';
import { SecImportComponent } from './sec-import.component';

export const secImportRoute: Route = {
    path: 'sec-import',
    component: SecImportComponent,
    data: {
        pageTitle: 'SEC 8K Filing Import',
    },
};
