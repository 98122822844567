<div class="tag-select__wrapper">
    <div class="tag-select__selection">
        <ng-container *ngFor="let tag of selectedTags$ | async">
            <button
                class="btn btn-link tag-select__selected"
                [tagColor]="tag.color"
                [hoverColor]="true"
                [disabled]="disabled"
                (click)="unselectTag($event, tag)"
            >
                {{ tag.name }}
            </button>
        </ng-container>
        <div class="tag-select__input-wrapper">
            <input
                type="text"
                placeholder="Search for a tag"
                id="search-tag-input"
                data-pw="searchTagInput"
                [formControl]="searchControl"
                (keydown)="checkKeys($event)"
            />
        </div>
    </div>
    <div class="divider dropdown-divider"></div>
    <div class="tag-select__search-results">
        <ng-container *ngIf="filteredTags$ | async as availableTags">
            <ng-container *ngIf="availableTags.length; else noTagsTemplateRef">
                <button
                    class="tag-select__option btn btn-link"
                    *ngFor="let tag of availableTags"
                    [disabled]="disabled || selectedTags$.value.length >= 20"
                    (click)="selectTag($event, tag)"
                >
                    <span [tagColor]="tag.color">{{ tag.name }}</span>
                </button>
            </ng-container>
        </ng-container>
    </div>
    <ng-container *ngIf="additionalActionsContent">
        <div class="divider dropdown-divider"></div>
        <ng-container [ngTemplateOutlet]="additionalActionsContent.templateRef"></ng-container>
    </ng-container>
</div>

<ng-template #noTagsTemplateRef>
    <span *ngIf="createTagDisabled || !searchControl.value" class="tag-select__option">No tags available.</span>
    <button
        class="tag-select__option tag-select__create-option btn btn-link"
        *ngIf="searchControl.value && !createTagDisabled"
        [disabled]="searchControl.invalid || disabled || selectedTags$.value.length >= 20"
        (click)="addNewTag(searchControl.value)"
    >
        <span>Create new tag "{{ searchControl.value }}"</span>
    </button>
</ng-template>
