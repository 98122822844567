import { Action, createReducer, on } from '@ngrx/store';
import { getControlsByOrgIdRequestSuccess, getControlsRequestSuccess } from './controls.actions';
import { ControlsState, initialState } from './controls.state';

const reducer = createReducer(
    initialState,
    on(getControlsRequestSuccess, (state, action) => ({
        ...state,
        controls: action.controls,
    })),
    on(getControlsByOrgIdRequestSuccess, (state, action) => ({
        ...state,
        orgControls: action.isCurrentOrg ? action.controls : state.orgControls,
    })),
);

export const controlsReducer = (state: ControlsState = initialState, action: Action): ControlsState =>
    reducer(state, action);
