import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Attachment } from './attachment.model';
import { CSRFService } from '../../shared';
import { DateUtilsService } from '../../shared/utils/date-utils.service';
import { UploadRequestAttachmentRequest } from '.';

@Injectable({
    providedIn: 'root',
})
export class AttachmentService {
    private resourceUrl = 'api/attachments';
    private relationshipResourceUrl = 'api/relationships';

    constructor(
        private _http: HttpClient,
        private _dateUtilsService: DateUtilsService,
        private _csrfService: CSRFService,
    ) {}

    find(id: number): Observable<Attachment> {
        return this._http.get<Attachment>(`${this.resourceUrl}/${id}`).pipe(map((res) => this.convertResponse(res)));
    }

    findByRelationship(relationshipId: number): Observable<Attachment[]> {
        return this._http
            .get<Attachment[]>(`${this.relationshipResourceUrl}/${relationshipId}/attachments`)
            .pipe(map((res) => this.convertArrayResponse(res)));
    }

    uploadAttachment(relationshipId: number, requests: UploadRequestAttachmentRequest[]) {
        const formData = new FormData();

        requests.forEach((request) => {
            formData.append('files', request.file, request.file.name);
        });

        const options: any = {
            headers: {
                'X-XSRF-TOKEN': this._csrfService.getCSRF(),
            },
            reportProgress: true,
        };

        return this._http.post(
            `${this.relationshipResourceUrl}/${relationshipId}/attachments/upload`,
            formData,
            options,
        );
    }

    deleteAttachment(relationshipId: number, attachmentId: number): Observable<HttpResponse<any>> {
        return this._http.delete<any>(`${this.relationshipResourceUrl}/${relationshipId}/attachments/${attachmentId}`, {
            observe: 'response',
        });
    }
    downloadAttachment(relationshipId: number, attachmentId: number): Observable<HttpResponse<Blob>> {
        return this._http.get(
            `${this.relationshipResourceUrl}/${relationshipId}/attachments/${attachmentId}/download`,
            {
                observe: 'response',
                responseType: 'blob',
            },
        );
    }

    downloadAttachmentsOnRelationshipAsZip(
        relationshipId: number,
        attachmentIds: number[],
    ): Observable<HttpResponse<Blob>> {
        return this._http.post(
            `${this.relationshipResourceUrl}/${relationshipId}/attachments/download`,
            attachmentIds,
            {
                observe: 'response',
                responseType: 'blob',
            },
        );
    }

    private convertResponse(res: Attachment): Attachment {
        const attachment: Attachment = this.convertItemFromServer(res);
        return attachment;
    }

    private convertArrayResponse(res: Attachment[]): Attachment[] {
        const attachments: Attachment[] = [];
        for (let i = 0; i < res.length; i++) {
            attachments.push(this.convertItemFromServer(res[i]));
        }
        return attachments;
    }

    private convertItemFromServer(attachment: Attachment): Attachment {
        const copy: Attachment = Object.assign({}, attachment);
        copy.uploadedDate = this._dateUtilsService.convertDateTimeFromServer(attachment.uploadedDate);
        return copy;
    }
}
