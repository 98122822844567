export const fieldNamesToSanitize = [
    'password',
    'secretKey',
    'secret',
    'webhook',
    'token',
    'accessToken',
    'artifactPassword',
];
export const headerNamesToSanitize = ['Cookie', 'X-XSRF-TOKEN'];
