import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { MatConfirmDialogService } from '@shared/components/mat-confirm-dialog/mat-confirm-dialog.service';
import { MatDialogWrapperService } from '@shared/modal/mat-dialog-wrapper.service';
import { filter, first, map, switchMap, withLatestFrom } from 'rxjs';
import {
    ConfirmCancellationDialogComponent,
    ConfirmDeclineRemediationDialogComponent,
    ForwardAssessmentDialogComponent,
} from '../../components/dialogs';
import { AssessmentSubmissionContext, StepIds, VendorDetails } from '../../models';
import { FileUploadHelperService, SubmissionHelperService } from '../../services';
import {
    cancelAssessment,
    cancelAssessmentCancel,
    cancelAssessmentRequest,
    createQuestionnaire,
    createQuestionnaireRequest,
    declineRemediation,
    declineRemediationCancel,
    declineRemediationRequest,
    deleteFileArtifactsAndSubmitAssessmentRequest,
    deleteQuestionnaireAndSubmitAssessmentRequest,
    forwardRequest,
    forwardRequestCancel,
    forwardRequestRequest,
    getPublicAssessmentRequest,
    getRecommendationsRequest,
    getSecurityControlDomainRequest,
    loadRecommendations,
    loadSecurityControlDomain,
    onRtpFileArtifactCreation,
    extendExpiration,
    extendAssessmentExpirationCancel,
    extendAssessmentExpirationRequest,
    refreshScopeRelatedArtifactUploadRecommendations,
    refreshSiblingOrExpiredArtifactUploadRecommendations,
    removeArtifact,
    removeArtifactRequest,
    saveQuestionnaire,
    saveQuestionnaireRequest,
    setArtifactPassword,
    setPasswordForArtifactRequest,
    submitAssessment,
    submitAssessmentRequest,
    submitPasscode,
    uploadFiles,
    uploadFilesRequest,
    uploadFileToReplace,
    uploadSupplementalFiles,
} from '../actions';
import {
    areThereUploadedArtifacts,
    getArtifactUploadRecommendations,
    getAssessmentExpiration,
    getAssessmentToken,
    getAttestationGroupsFromRecommendations,
    getClientName,
    getCurrentStepId,
    getExpiredArtifactsUploadedBeforeAssessmentCreation,
    getIsArtifactUpdateFlow,
    getIsThereAQuestionnaire,
    getSecret,
    getSubmissionContext,
    getVendorName,
} from '../selectors';
import { FileArtifactType } from '@entities/artifact';
import { NOOP } from '@shared/redux/actions';
import moment from 'moment';

@Injectable()
export class CommandsEffects {
    submitPasscode$ = createEffect(() =>
        this._actions$.pipe(
            ofType(submitPasscode),
            withLatestFrom(this._store$.select(getAssessmentToken)),
            map(([{ passcode: secret }, assessmentToken]) => getPublicAssessmentRequest({ assessmentToken, secret })),
        ),
    );

    extendAssessmentExpiration$ = createEffect(() =>
        this._actions$.pipe(
            ofType(extendExpiration),
            withLatestFrom(
                this._store$.select(getAssessmentExpiration),
                this._store$.select(getAssessmentToken),
                this._store$.select(getSecret),
            ),
            switchMap(([, expirationDate, assessmentToken, secret]) =>
                this._confirmDialogService
                    .confirm({
                        title: 'Need more time?',
                        message:
                            `The deadline to provide information is ${moment(expirationDate).format('M/D/yyyy')}. ` +
                            `If you need more time to complete this request, you can extend the deadline to ${moment(expirationDate).add(7, 'days').format('M/D/yyyy')}.`,
                        cancelButtonColor: 'secondary',
                        confirmLabel: 'Yes, extend deadline',
                        confirmButtonColor: 'secondary',
                    })
                    .pipe(
                        first(),
                        map((confirm) =>
                            confirm
                                ? extendAssessmentExpirationRequest({ assessmentToken, secret })
                                : extendAssessmentExpirationCancel(),
                        ),
                    ),
            ),
        ),
    );

    cancelAssessment$ = createEffect(() =>
        this._actions$.pipe(
            ofType(cancelAssessment),
            withLatestFrom(
                this._store$.select(getAssessmentToken),
                this._store$.select(getSecret),
                this._store$.select(getClientName),
                this._store$.select(getVendorName),
            ),
            switchMap(([, assessmentToken, secret, clientName, vendorName]) =>
                this._dialogService
                    .open<ConfirmCancellationDialogComponent, VendorDetails | false>(
                        ConfirmCancellationDialogComponent,
                        {
                            inputs: {
                                clientName,
                                vendorName,
                            },
                        },
                    )
                    .afterClosed()
                    .pipe(
                        first(),
                        map((vendorDetails) =>
                            !!vendorDetails
                                ? cancelAssessmentRequest({ assessmentToken, secret, vendorDetails })
                                : cancelAssessmentCancel(),
                        ),
                    ),
            ),
        ),
    );

    forwardRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(forwardRequest),
            withLatestFrom(this._store$.select(getAssessmentToken), this._store$.select(getSecret)),
            switchMap(([, assessmentToken, secret]) =>
                this._dialogService
                    .open<ForwardAssessmentDialogComponent, VendorDetails | false>(ForwardAssessmentDialogComponent)
                    .afterClosed()
                    .pipe(
                        first(),
                        map((vendorDetails) =>
                            !!vendorDetails
                                ? forwardRequestRequest({ assessmentToken, secret, vendorDetails })
                                : forwardRequestCancel(),
                        ),
                    ),
            ),
        ),
    );

    declineRemediation$ = createEffect(() =>
        this._actions$.pipe(
            ofType(declineRemediation),
            withLatestFrom(
                this._store$.select(getAssessmentToken),
                this._store$.select(getSecret),
                this._store$.select(getClientName),
                this._store$.select(getVendorName),
            ),
            switchMap(([, assessmentToken, secret, clientName, vendorName]) =>
                this._dialogService
                    .open<ConfirmDeclineRemediationDialogComponent, VendorDetails | false>(
                        ConfirmDeclineRemediationDialogComponent,
                        {
                            inputs: {
                                clientName,
                                vendorName,
                            },
                        },
                    )
                    .afterClosed()
                    .pipe(
                        first(),
                        map((vendorDetails) =>
                            !!vendorDetails
                                ? declineRemediationRequest({ assessmentToken, secret, vendorDetails })
                                : declineRemediationCancel(),
                        ),
                    ),
            ),
        ),
    );

    loadRecommendations$ = createEffect(() =>
        this._actions$.pipe(
            ofType(loadRecommendations),
            withLatestFrom(this._store$.select(getAssessmentToken), this._store$.select(getSecret)),
            map(([, assessmentToken, secret]) => getRecommendationsRequest({ assessmentToken, secret })),
        ),
    );

    uploadFiles$ = createEffect(() =>
        this._actions$.pipe(
            ofType(uploadFiles),
            withLatestFrom(
                this._store$.select(getAssessmentToken),
                this._store$.select(getSecret),
                this._store$.select(getArtifactUploadRecommendations),
            ),
            map(([{ files, recommendationId }, assessmentToken, secret, recommendations]) => {
                const uploadRecommendation = recommendations.find((rec) => rec.id === recommendationId);
                return uploadFilesRequest({
                    assessmentToken,
                    secret,
                    uploadFileArtifactRequests: this._fileUploadHelperService.createFilesUploadRequest(
                        files,
                        uploadRecommendation,
                    ),
                });
            }),
        ),
    );

    uploadDocumentReplacement$ = createEffect(() =>
        this._actions$.pipe(
            ofType(uploadFileToReplace),
            withLatestFrom(
                this._store$.select(getExpiredArtifactsUploadedBeforeAssessmentCreation),
                this._store$.select(getAssessmentToken),
                this._store$.select(getSecret),
            ),
            map(([{ file, artifactIdToReplace }, expiredArtifacts, assessmentToken, secret]) =>
                uploadFilesRequest({
                    assessmentToken,
                    secret,
                    uploadFileArtifactRequests: [
                        this._fileUploadHelperService.createFileUploadReplacementRequest(
                            file,
                            expiredArtifacts.find((a) => a.id === artifactIdToReplace),
                        ),
                    ],
                }),
            ),
        ),
    );

    setArtifactPassword$ = createEffect(() =>
        this._actions$.pipe(
            ofType(setArtifactPassword),
            withLatestFrom(this._store$.select(getAssessmentToken), this._store$.select(getSecret)),
            map(([{ artifactId, password }, assessmentToken, secret]) =>
                setPasswordForArtifactRequest({ assessmentToken, secret, artifactId, password }),
            ),
        ),
    );

    loadSecurityControlDomain$ = createEffect(() =>
        this._actions$.pipe(
            ofType(loadSecurityControlDomain),
            withLatestFrom(this._store$.select(getAssessmentToken), this._store$.select(getSecret)),
            map(([, assessmentToken, secret]) => getSecurityControlDomainRequest({ assessmentToken, secret })),
        ),
    );

    removeArtifact$ = createEffect(() =>
        this._actions$.pipe(
            ofType(removeArtifact),
            withLatestFrom(this._store$.select(getAssessmentToken), this._store$.select(getSecret)),
            switchMap(([{ artifactId, fileName, artifactType }, assessmentToken, secret]) =>
                this._confirmDialogService
                    .confirm({
                        title: 'Delete Artifact',
                        message: `Are you sure you want to delete <strong>${fileName}</strong>?`,
                        cancelButtonColor: 'secondary',
                        confirmLabel: 'Yes, delete artifact',
                        confirmButtonColor: 'warn',
                    })
                    .pipe(
                        first(),
                        map((confirm) =>
                            confirm
                                ? removeArtifactRequest({ artifactId, assessmentToken, secret, artifactType })
                                : NOOP(),
                        ),
                    ),
            ),
        ),
    );

    createQuestionnaire$ = createEffect(() =>
        this._actions$.pipe(
            ofType(createQuestionnaire),
            withLatestFrom(this._store$.select(getAssessmentToken), this._store$.select(getSecret)),
            map(([, assessmentToken, secret]) => createQuestionnaireRequest({ assessmentToken, secret })),
        ),
    );

    saveQuestionnaire$ = createEffect(() =>
        this._actions$.pipe(
            ofType(saveQuestionnaire),
            withLatestFrom(this._store$.select(getAssessmentToken), this._store$.select(getSecret)),
            map(([{ questionnaire }, assessmentToken, secret]) =>
                saveQuestionnaireRequest({ assessmentToken, secret, questionnaire }),
            ),
        ),
    );

    submitAssessment$ = createEffect(() =>
        this._actions$.pipe(
            ofType(submitAssessment),
            withLatestFrom(
                this._store$.select(getSubmissionContext),
                this._store$.select(getIsThereAQuestionnaire),
                this._store$.select(areThereUploadedArtifacts),
                this._store$.select(getAssessmentToken),
                this._store$.select(getSecret),
                this._store$.select(getAttestationGroupsFromRecommendations),
            ),
            map(
                ([
                    { payload: formPayload },
                    submissionContext,
                    isThereAQuestionnaire,
                    areThereArtifacts,
                    assessmentToken,
                    secret,
                    attestationsGroup,
                ]) => {
                    const payload = this._submissionHelperService.generateSubmissionPayload({
                        formPayload,
                        assessmentToken,
                        secret,
                        attestationsGroup:
                            submissionContext === AssessmentSubmissionContext.FILE_ARTIFACTS ? attestationsGroup : null,
                    });

                    if (submissionContext === AssessmentSubmissionContext.QUESTIONNAIRE && areThereArtifacts) {
                        return deleteFileArtifactsAndSubmitAssessmentRequest({ payload });
                    }

                    if (submissionContext === AssessmentSubmissionContext.FILE_ARTIFACTS && isThereAQuestionnaire) {
                        return deleteQuestionnaireAndSubmitAssessmentRequest({ payload });
                    }

                    return submitAssessmentRequest({ payload });
                },
            ),
        ),
    );

    uploadSupplementalArtifactsRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(uploadSupplementalFiles),
            withLatestFrom(this._store$.select(getSecret), this._store$.select(getAssessmentToken)),
            map(([{ files }, secret, assessmentToken]) => {
                const uploadFileArtifactRequests = files.map((file) => ({
                    file,
                    fileArtifactMetaData: {
                        artifactType: FileArtifactType.SUPPLEMENTAL,
                    },
                }));
                return uploadFilesRequest({ assessmentToken, secret, uploadFileArtifactRequests });
            }),
        ),
    );

    triggerRefreshArtifactUploadRecommendations = createEffect(() =>
        this._actions$.pipe(
            ofType(onRtpFileArtifactCreation),
            withLatestFrom(this._store$.select(getIsArtifactUpdateFlow), this._store$.select(getCurrentStepId)),
            filter(
                ([, isArtifactUpdate, currentStepId]) =>
                    !isArtifactUpdate &&
                    (currentStepId === StepIds.WELCOME || currentStepId === StepIds.INITIAL_ARTIFACT_UPLOAD),
            ),
            map(() => refreshScopeRelatedArtifactUploadRecommendations()),
        ),
    );

    triggerRefreshSiblingOrExpiredArtifactUploadRecommendations$ = createEffect(() =>
        this._actions$.pipe(
            ofType(onRtpFileArtifactCreation),
            map(() => refreshSiblingOrExpiredArtifactUploadRecommendations()),
        ),
    );

    constructor(
        private _store$: Store,
        private _actions$: Actions,
        private _dialogService: MatDialogWrapperService,
        private _confirmDialogService: MatConfirmDialogService,
        private _fileUploadHelperService: FileUploadHelperService,
        private _submissionHelperService: SubmissionHelperService,
    ) {}
}
