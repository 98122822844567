import { BaseEntity } from '../../shared';
import { ControlDomainType } from '../control-domain';
import { RiskScore } from '../risk-assessment';
import { AuditReportTypeCode } from '../audit-report';
import { ControlValidationStatus } from '../artifact';
import { DataSensitivityLevel } from '../../admin/client-profile/data-sensitivity-levels-config/data-sensitivity-level.model';

export enum RecommendationType {
    EXPIRED = 'EXPIRED',
    HIGH_ASSURANCE = 'HIGH_ASSURANCE',
    CONTROL_DOMAINS = 'CONTROL_DOMAINS',
    CERT_ONLY = 'CERT_ONLY',
    COMBINED = 'COMBINED',
    LOW_COVERAGE = 'LOW_COVERAGE',
}

export enum RecommendationGroupLabel {
    ARTIFACT = 'Artifact',
    PROGRAM = 'Program',
}

export interface AssessmentRecommendation extends BaseEntity {
    id: string;
    type: RecommendationType;
    assessmentId: number;
    riskScores: Record<ControlDomainType, RiskScore>;
    createdDate: any;
    description?: string;
}

export interface ControlDomainStatusAssessmentRecommendation extends AssessmentRecommendation {
    type: RecommendationType.CONTROL_DOMAINS;
    controlDomainIds: number[];
    controlValidationStatus: ControlValidationStatus;
}

export interface ControlDomainCoverageAssessmentRecommendation extends AssessmentRecommendation {
    type: RecommendationType.LOW_COVERAGE;
    controlDomainIds: number[];
}

export interface ExpiredArtifactAssessmentRecommendation extends AssessmentRecommendation {
    type: RecommendationType.EXPIRED;
    artifactIds: number[];
}

export interface HighAssuranceArtifactAssessmentRecommendation extends AssessmentRecommendation {
    type: RecommendationType.HIGH_ASSURANCE;
    artifactIds: number[];
    assurance: number;
    dataSensitivityLevel: DataSensitivityLevel;
}

export interface CertOnlyArtifactAssessmentRecommendation extends AssessmentRecommendation {
    type: RecommendationType.CERT_ONLY;
    artifactIds: number[];
    reportAuditReportType: AuditReportTypeCode;
}

export interface CombinedAssessmentRecommendation extends AssessmentRecommendation {
    type: RecommendationType.COMBINED;
}
