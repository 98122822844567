import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
    createVendorRiskAdvisoryRequest,
    createVendorRiskAdvisoryRequestFailed,
    createVendorRiskAdvisoryRequestSuccess,
    summarizeRiskAdvisoryLink,
    summarizeRiskAdvisoryLinkRequestFailed,
    summarizeRiskAdvisoryLinkRequestSuccess,
    updateVendorRiskAdvisoryRequest,
    updateVendorRiskAdvisoryRequestFailed,
    updateVendorRiskAdvisoryRequestSuccess,
} from '../actions/vendor-risk-advisory.actions';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { RiskAdvisoryService } from '@entities/risk-advisory/risk-advisory.service';
import { SnackbarService } from '@shared/components/snackbar/snackbar.service';
import { SummaryResponse } from '@entities/risk-advisory';

@Injectable()
export class VendorRiskAdvisoryEffects {
    createVendorRiskAdvisoryRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(createVendorRiskAdvisoryRequest),
            switchMap(({ request, name }) =>
                this._riskAdvisoryService.createRiskAdvisory(request).pipe(
                    map(() => createVendorRiskAdvisoryRequestSuccess({ name })),
                    catchError((error) => of(createVendorRiskAdvisoryRequestFailed(error))),
                ),
            ),
        ),
    );

    createVendorRiskAdvisoryRequestSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(createVendorRiskAdvisoryRequestSuccess),
                tap(({ name }) => {
                    this._snackbarService.success(`Risk advisory created for ${name}`);
                }),
            ),
        { dispatch: false },
    );

    updateVendorRiskAdvisoryRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(updateVendorRiskAdvisoryRequest),
            switchMap(({ request, name }) =>
                this._riskAdvisoryService.updateRiskAdvisory(request).pipe(
                    map(() => updateVendorRiskAdvisoryRequestSuccess({ name })),
                    catchError((error) => of(updateVendorRiskAdvisoryRequestFailed(error))),
                ),
            ),
        ),
    );

    updateVendorRiskAdvisoryRequestSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(updateVendorRiskAdvisoryRequestSuccess),
                tap(({ name }) => {
                    this._snackbarService.success(`Risk advisory updated for ${name}`);
                }),
            ),
        { dispatch: false },
    );

    summarizeRiskAdvisoryLinkRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(summarizeRiskAdvisoryLink),
            switchMap(({ url }) =>
                this._riskAdvisoryService.summarizeRiskAdvisoryLink(url).pipe(
                    map((summary: SummaryResponse) => summarizeRiskAdvisoryLinkRequestSuccess({ summary })),
                    catchError((error) => of(summarizeRiskAdvisoryLinkRequestFailed(error))),
                ),
            ),
        ),
    );

    constructor(
        private _actions$: Actions,
        private _riskAdvisoryService: RiskAdvisoryService,
        private _snackbarService: SnackbarService,
    ) {}
}
