import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Announcement, CreateAnnouncement } from '../../entities/announcement/announcement.model';

@Injectable()
export class AdminAnnouncementService {
    private resourceUrl = 'api/announcement';

    constructor(private _http: HttpClient) {}

    getAllAnnouncements(): Observable<Announcement[]> {
        return this._http.get<Announcement[]>(`${this.resourceUrl}/all`);
    }

    createAnnouncement(payload: CreateAnnouncement): Observable<void> {
        const formData = new FormData();
        if (payload.images) {
            payload.images.forEach((image) => {
                formData.append('files', image, image.name);
            });
        }
        const { images, ...newPayload } = payload;
        formData.append(
            'announcementCreateRequest',
            new Blob([JSON.stringify(newPayload)], { type: 'application/json' }),
        );
        return this._http.post<void>(this.resourceUrl, formData);
    }

    deleteAnnouncement(announcementId: string): Observable<void> {
        return this._http.delete<void>(`${this.resourceUrl}/${announcementId}`);
    }
}
