import { ResolveFn } from '@angular/router';
import { inject } from '@angular/core';
import { Artifact, ArtifactService } from '../../../entities/artifact';
import { map } from 'rxjs/operators';

export const questionnaireReferencedArtifactsResolver: ResolveFn<Artifact[]> = (route, state) => {
    const questionnaireId = +route.paramMap.get('questionnaireId');
    const artifactService = inject(ArtifactService);
    return artifactService.findReferencedArtifactsForQuestionnaire(questionnaireId).pipe(map((res) => res.body));
};
