import {
    AfterViewInit,
    Directive,
    Input,
    OnDestroy,
    OnInit,
    TemplateRef,
    ViewContainerRef,
    HostListener,
} from '@angular/core';
import { BehaviorSubject, of, Subject } from 'rxjs';
import { debounceTime, filter, switchMap, takeUntil } from 'rxjs/operators';
import { Tag } from '../../../entities/tag';
import { CollapsedItemsComponent } from '../../components/collapsed-items/collapsed-items.component';

interface CollapseItemsContext {
    $implicit: unknown; // current item exposed as implicit value, enables declaring in template variable via let keyword
    index: number; // current index of the item
    first: boolean; // indicates if the item is first in the collection
    last: boolean; // indicates if the item is last in the collection
}

@Directive({
    selector: '[collapseCellItems]',
})
export class CollapseItemsDirective implements AfterViewInit, OnInit, OnDestroy {
    @Input()
    set collapseCellItems(items: unknown[]) {
        this._items = items;
        this._renderList.next(true);
    }

    @Input()
    set collapseCellItemsWidth(newValue: number) {
        this._renderList.next(true);
    }

    private _unsub$ = new Subject<void>();
    private _renderList = new BehaviorSubject(false);
    private _items: unknown[];

    @HostListener('window:resize', ['$event'])
    onResize() {
        this._renderList.next(true);
    }

    constructor(
        private readonly viewRef: ViewContainerRef,
        private readonly templateRef: TemplateRef<CollapseItemsContext>,
    ) {}

    ngAfterViewInit(): void {
        this._renderList.next(true);
    }

    ngOnInit(): void {
        of(null)
            .pipe(
                takeUntil(this._unsub$),
                switchMap(() => this._renderList),
                filter(Boolean),
                debounceTime(100),
            )
            .subscribe(() => {
                this.viewRef.clear();
                if (!this._items?.length) {
                    return;
                }
                this.renderItems();
            });
    }

    ngOnDestroy(): void {
        this._unsub$.next();
        this.viewRef.clear();
    }

    renderItems() {
        const containerWidth = this.viewRef.element.nativeElement.parentElement.offsetWidth;
        if (!containerWidth) {
            return;
        }

        let currentIndex = 0;
        this.viewRef.clear();
        // Progressive rendering
        const interval = setInterval(() => {
            const nextTag = (this._items[currentIndex] || null) as Tag;
            const previousElement = this.viewRef.element.nativeElement.parentElement.querySelector(
                'span:last-child',
            ) as HTMLElement;

            if (
                !previousElement ||
                (previousElement &&
                    previousElement.offsetLeft + previousElement.offsetWidth < containerWidth &&
                    nextTag)
            ) {
                this.renderView(nextTag, currentIndex);
                currentIndex++;
                return;
            }

            let indicatorWidth = this._items.length - currentIndex + 1 > 10 ? 39 : 32;
            const usedSpaceWithIndicator = previousElement.offsetLeft + indicatorWidth;
            let leftItems = [];
            if (containerWidth > previousElement.offsetLeft + previousElement.offsetWidth) {
                // do nothing
            } else if (usedSpaceWithIndicator > containerWidth && this.viewRef.length > 1) {
                this.removeRenderedView(currentIndex - 1);
                this.removeRenderedView(currentIndex - 2);
                leftItems = this._items.slice(currentIndex - 2, this._items.length) as Tag[];
            } else {
                this.removeRenderedView(currentIndex - 1);
                leftItems = this._items.slice(currentIndex - 1, this._items.length) as Tag[];
            }

            if (leftItems.length) {
                this.addCollapsedIndicator(leftItems);
            }

            clearInterval(interval);
        }, 10);
    }

    renderView(tag: Tag, index: number) {
        this.viewRef.createEmbeddedView(this.templateRef, {
            $implicit: tag,
            index: index,
            first: index === 0,
            last: index + 1 === this._items.length,
        });
    }

    removeRenderedView(viewIndex: number) {
        const previousViewRef = this.viewRef.get(viewIndex);
        previousViewRef.destroy();
    }

    addCollapsedIndicator(tags: Tag[]) {
        const componentRef = this.viewRef.createComponent(CollapsedItemsComponent);
        componentRef.instance.tags = tags;
    }
}
