import * as fromRoot from '../../../../shared/redux/state';

export const orgSubdomainStateKey = 'orgSubdomain';

export const initialState: OrgSubdomainState = {
    domain: null,
    subdomain: null,
};

export interface AppState extends fromRoot.AppState {
    [orgSubdomainStateKey]: OrgSubdomainState;
}

export interface OrgSubdomainState {
    domain: string | null;
    subdomain: string | null;
}
