import { ChangeDetectionStrategy, Component, EventEmitter, OnDestroy, OnInit, Output, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Actions, ofType } from '@ngrx/effects';
import { merge, Observable, of, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { FormUtilsService } from '../../../../shared/utils/form-utils.service';
import { noWhitespaceValidator } from '../../../../shared/validators/whitespace-validator';
import { FormRawValue } from '../../../../shared/model/controls-of';
import { ArtifactType, ControlValidationDetectionType } from '../../../../entities/artifact';
import { AssuranceLevels } from '../../../../entities/relationship/models/security-control-domain';
import { AuditReportTypeName } from '../../../../entities/audit-report';
import {
    askQuestionRequest,
    askQuestionRequestSuccess,
    askQuestionRequestFailed,
} from '../../redux/actions/question-answering.actions';
import { InteractiveIqrResponse } from '../rdp-ai-question-answering.model';

interface QuestionFormGroup {
    question: FormControl<string>;
}

@Component({
    selector: 'app-rdp-ai-question-answering',
    templateUrl: './rdp-ai-question-answering.component.html',
    styleUrls: ['./rdp-ai-question-answering.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RdpAiQuestionAnsweringComponent implements OnInit, OnDestroy {
    @Input()
    set iiqrResponse(iiqrResponse: InteractiveIqrResponse) {
        this.clearInput();
        this._iiqrResponse = iiqrResponse;
    }

    @Input()
    hasArtifactsWithValidations: boolean;

    @Input()
    vendorName: string;

    @Output()
    askQuestion: EventEmitter<string> = new EventEmitter<string>();

    @Output()
    removeQuestion: EventEmitter<void> = new EventEmitter<void>();

    @Output()
    goToRiskAnalysis: EventEmitter<void> = new EventEmitter<void>();

    questionFormGroup: FormGroup<QuestionFormGroup>;

    awaitingQuestionResponse$: Observable<boolean>;

    AssuranceLevels = AssuranceLevels;
    ArtifactType = ArtifactType;
    AuditReportType = AuditReportTypeName;
    DetectionType = ControlValidationDetectionType;

    get iiqrResponse(): InteractiveIqrResponse {
        return this._iiqrResponse;
    }

    private _unsub$ = new Subject<void>();
    private _iiqrResponse: InteractiveIqrResponse;

    constructor(
        private _formUtils: FormUtilsService,
        private _fb: FormBuilder,
        private _actions$: Actions,
    ) {}

    ngOnInit(): void {
        this.questionFormGroup = this._fb.group({
            question: this._fb.control('', { validators: [Validators.required, noWhitespaceValidator] }),
        });

        this.awaitingQuestionResponse$ = merge(
            of(false),
            this._actions$.pipe(
                ofType(askQuestionRequest),
                takeUntil(this._unsub$),
                map(() => true),
            ),
            this._actions$.pipe(
                ofType(askQuestionRequestSuccess, askQuestionRequestFailed),
                takeUntil(this._unsub$),
                map(() => false),
            ),
        );

        const questionFormControl = this.questionFormGroup.controls.question;

        this.awaitingQuestionResponse$
            .pipe(takeUntil(this._unsub$))
            .subscribe((awaiting) => (awaiting ? questionFormControl.disable() : questionFormControl.enable()));
    }

    query() {
        const { question } = this._formUtils.getCleanFormGroupValue<FormRawValue<FormGroup<QuestionFormGroup>>>(
            this.questionFormGroup,
        );
        this.askQuestion.emit(question);
    }

    copyAnswerToClipboard(): void {
        navigator.clipboard.writeText(this.iiqrResponse?.response);
    }

    ngOnDestroy(): void {
        this._unsub$.next();
    }

    private clearInput(): void {
        this.questionFormGroup?.controls.question.reset();
    }
}
