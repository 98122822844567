import { BaseEntity } from '../../shared';

export enum AttestationType {
    THIRD_PARTY_AUDITS = 'THIRD_PARTY_AUDITS',
    UPDATED_ARTIFACTS = 'UPDATED_ARTIFACTS',
    SIBLING_DOCS = 'SIBLING_DOCS',
    PEN_TESTS = 'PEN_TESTS',
    PRIVACY_DOCS = 'PRIVACY_DOCS',
    CYBERSECURITY_INSURANCE = 'CYBERSECURITY_INSURANCE',
    ADDITIONAL_DOCS = 'ADDITIONAL_DOCS',
    NO_UPDATED_ARTIFACT = 'NO_UPDATED_ARTIFACT',
}

export enum AttestationTypeLabels {
    THIRD_PARTY_AUDITS = 'Third Party Audits',
    PEN_TESTS = 'Penetration Tests',
    PRIVACY_DOCS = 'Privacy',
    CYBERSECURITY_INSURANCE = 'Cyber Insurance',
    NO_UPDATED_ARTIFACT = 'No Updated Artifact',
    SIBLING_DOCS = 'Sibling document',
    UPDATED_ARTIFACTS = 'Expired document',
}

export enum AttestationValue {
    DONT_HAVE = 'DONT_HAVE',
    WONT_PROVIDE = 'WONT_PROVIDE',
    OTHER_ARTIFACT = 'OTHER_ARTIFACT',
}

export interface Attestation extends BaseEntity {
    attestationType: AttestationType;
    attestationValue: AttestationValue;
}

export interface MissingArtifactAttestation extends Attestation {
    attestationType: AttestationType; // Must be one of SIBLING_DOCS or UPDATED_ARTIFACTS
    referencedArtifactId: number;
}

export const isMissingArtifactAttestation = (attestation: Attestation): attestation is MissingArtifactAttestation =>
    attestation.attestationType === AttestationType.SIBLING_DOCS ||
    attestation.attestationType === AttestationType.UPDATED_ARTIFACTS;

export const AttestationValueLabels = {
    [AttestationValue.DONT_HAVE]: (vendorName: string, attestationName: string) =>
        `${vendorName} does not have artifacts for ${attestationName}.`,
    [AttestationValue.WONT_PROVIDE]: (vendorName: string, attestationName: string) =>
        `${vendorName} will not provide artifacts for ${attestationName}.`,
    [AttestationValue.OTHER_ARTIFACT]: (vendorName: string, attestationName: string) =>
        `Another artifact substantiates ${attestationName}.`,
};

export const ExpiredArtifactAttestationLabels = {
    [AttestationValue.DONT_HAVE]: (
        vendorName: string,
        referencedArtifactFileName: string,
        referencedArtifactTypeName: string,
    ) =>
        `${vendorName} does not have an artifact for the expired ${referencedArtifactTypeName} (${referencedArtifactFileName}).`,
    [AttestationValue.WONT_PROVIDE]: (
        vendorName: string,
        referencedArtifactFileName: string,
        referencedArtifactTypeName: string,
    ) =>
        `${vendorName} will not provide an artifact for the expired ${referencedArtifactTypeName} (${referencedArtifactFileName}).`,
    [AttestationValue.OTHER_ARTIFACT]: () => '',
};

export const SiblingArtifactAttestationLabels = {
    [AttestationValue.DONT_HAVE]: (
        vendorName: string,
        referencedArtifactFileName: string,
        referencedArtifactTypeName: string,
    ) =>
        `${vendorName} does not have a sibling artifact for ${referencedArtifactTypeName} (${referencedArtifactFileName}).`,
    [AttestationValue.WONT_PROVIDE]: (
        vendorName: string,
        referencedArtifactFileName: string,
        referencedArtifactTypeName: string,
    ) =>
        `${vendorName} will not provide a sibling artifact for ${referencedArtifactTypeName} (${referencedArtifactFileName}).`,
    [AttestationValue.OTHER_ARTIFACT]: () => '',
};
