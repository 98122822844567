import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ActiveJwtView, CreateJwtPayload, JwtCreatedResponse } from './jwt-management.model';

@Injectable()
export class JwtManagementService {
    private _resourceUrl = 'api/auth/admin/jwt';

    constructor(private _http: HttpClient) {}

    getActiveJwts(): Observable<ActiveJwtView[]> {
        return this._http.get<ActiveJwtView[]>(this._resourceUrl);
    }

    addJwt(addJwtPayload: CreateJwtPayload): Observable<JwtCreatedResponse> {
        return this._http.post<JwtCreatedResponse>(this._resourceUrl, addJwtPayload);
    }

    revokeJwt(jti: string): Observable<void> {
        return this._http.delete<void>(`${this._resourceUrl}/revoke`, { params: { jti } });
    }
}
