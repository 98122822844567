import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BehaviorSubject, Subject, of } from 'rxjs';
import { delay, switchMap, takeUntil } from 'rxjs/operators';
import { DataType } from '../../../../entities/data-type';
import { DataSensitivityLevel } from '../../../../admin/client-profile/data-sensitivity-levels-config/data-sensitivity-level.model';
import { ItemsFormGroup } from '../models/relationship-context-form';
import { DataTypesSensitivityHash } from '../models/hashes';
import { OrgDataSensitivityConfig } from 'src/main/webapp/app/admin/client-profile/client-profile.model';
import { TagColorEnum } from '@entities/tag';

@Component({
    selector: 'app-data-types-selection',
    templateUrl: './data-types-selection.component.html',
    styleUrls: ['./data-types-selection.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataTypesSelectionComponent implements OnInit, OnDestroy {
    @Input({ required: true })
    dataTypesFormGroup: FormGroup<ItemsFormGroup<DataType>>;

    @Input({ required: true })
    dataTypesBySensitivity: DataTypesSensitivityHash;

    @Input({ required: true })
    profileDataSensitivityLevels: OrgDataSensitivityConfig[];

    showPopoverFor: string;
    TagColor = TagColorEnum;

    private _popoverControl$ = new BehaviorSubject<string>(null);
    private _unsub$ = new Subject<void>();

    DataSensitivityOrder = [
        DataSensitivityLevel.NONE,
        DataSensitivityLevel.LOW,
        DataSensitivityLevel.MEDIUM,
        DataSensitivityLevel.HIGH,
        DataSensitivityLevel.EXTREME,
    ];

    constructor(private _cdr: ChangeDetectorRef) {}

    ngOnInit(): void {
        this._popoverControl$
            .pipe(
                takeUntil(this._unsub$),
                switchMap((value) => {
                    if (value === null) {
                        return of(null).pipe(delay(200));
                    } else {
                        // Discard previous value when the next value is not null
                        return of(value);
                    }
                }),
            )
            .subscribe((value) => {
                this.showPopoverFor = value;
                this._cdr.markForCheck();
            });
    }

    ngOnDestroy(): void {
        this._unsub$.next();
    }

    showPopover(currentTarget: string) {
        this._popoverControl$.next(currentTarget);
    }

    hidePopover() {
        this._popoverControl$.next(null);
    }

    getDataSensitivityLevelLabel = (dataSensitivityLevel: DataSensitivityLevel): string => {
        return this.profileDataSensitivityLevels.find((level) => level.dataSensitivityLevel === dataSensitivityLevel)
            ?.displayName;
    };

    getExampleList(exampleString: string) {
        const newString = exampleString.replace('e.g. ', '');
        return newString.split(',').map((example) => {
            const exampleClean = example.trim();
            return exampleClean.charAt(0).toUpperCase() + exampleClean.slice(1);
        });
    }
}
