<div *dynamicContent="breadcrumbsContainerToken">
    <ol class="breadcrumb breadcrumb px-0 pb-0">
        <li class="breadcrumb-item"><a [routerLink]="['/']">Home</a></li>
        <li class="breadcrumb-item active">Import Relationships</li>
    </ol>
</div>
<div class="content-heading tabset-margins">
    <div>Import Relationships</div>
</div>
<div class="row">
    <div class="col-md-12">
        <p class="mb-1">Import relationships in bulk by filling out an import spreadsheet and sharing it with us.</p>
        <p class="mb-1">
            To learn more about the import functionality please refer to our
            <a href="https://support.visotrust.com/article/r3vtvrvpie-importing-relationship" target="_blank"
                >help documentation</a
            >.
        </p>
        <br />

        <div class="row">
            <div class="col">
                <tabset #importTabs class="viso-tabset tabset-nav">
                    <tab heading="Import Relationships">
                        <div class="row import-relationships-container">
                            <div class="col">
                                <app-import-relationships></app-import-relationships>
                            </div>
                        </div>
                    </tab>
                    <tab heading="History" (selectTab)="resizeWindow()">
                        <div class="row">
                            <div class="col"><app-import-history></app-import-history></div>
                        </div>
                    </tab>
                </tabset>
            </div>
        </div>
    </div>
</div>
