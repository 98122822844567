import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnDestroy,
    OnInit,
    SecurityContext,
    ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { QuillEditorComponent, QuillModules } from 'ngx-quill';
import { BehaviorSubject } from 'rxjs';
import { QuillMentionModuleHelperService } from '../services/quill-mention-module-helper.service';
import { ObjectsToQuillMentionsConverterService } from '../services/objects-to-quill-mentions-converter.service';
import { FastMention, QuillMentionListItem } from '../quill.model';
import { defaultToolbar as toolbar } from '../toolbar-module.conf';
import { defaultAllowedFormats as formats } from '../formats.conf';

@Component({
    selector: 'app-quill-editor-wrapper',
    templateUrl: './quill-editor-wrapper.component.html',
    styleUrls: ['./quill-editor-wrapper.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [QuillMentionModuleHelperService],
})
export class QuillEditorWrapperComponent implements OnInit, OnDestroy {
    @Input()
    set fastMentions(value: FastMention[]) {
        this.fastMentions$.next(value);
    }

    @Input()
    set mentions(value: QuillMentionListItem[]) {
        this._mentions$.next(value);
    }

    @Input()
    editorFormControl: UntypedFormControl;

    @Input()
    styles: any;

    @Input()
    placeholder: string;

    fastMentions$ = new BehaviorSubject<FastMention[]>([]);

    modules: QuillModules;

    formats: string[] = formats;

    @ViewChild(QuillEditorComponent, { static: true })
    private _editor: QuillEditorComponent;
    private _mentions$ = new BehaviorSubject<QuillMentionListItem[]>([]);
    private _defaultModules: QuillModules = {
        toolbar,
    };

    private _observer: MutationObserver;

    constructor(
        private _sanitizer: DomSanitizer,
        private _quillMentionModuleHelperService: QuillMentionModuleHelperService,
        private _objectsToQuillMentionsConverterService: ObjectsToQuillMentionsConverterService,
    ) {}

    ngOnInit(): void {
        this.modules = {
            ...this._defaultModules,
            mention: this._quillMentionModuleHelperService.generateMentionModule(this._mentions$),
        };

        const fastMentions = this.fastMentions$.value;
        if (!fastMentions || !fastMentions.length) {
            this.fastMentions$.next(
                this._objectsToQuillMentionsConverterService.getDefaultFastMentions() as FastMention[],
            );
        }

        this._observer = new MutationObserver(this.mutationObserverCb);
    }

    onEditorCreated(): void {
        const config = { attributes: true, subtree: true };
        const qlEditor = this._editor.editorElem.querySelector('.ql-editor');
        this._observer.observe(qlEditor, config);
    }

    insertMention(fastMention: QuillMentionListItem | Partial<QuillMentionListItem>): void {
        const quillEditor = this._editor.quillEditor;
        const range = quillEditor.getSelection(); // cursor position

        if (!range || range.length != 0) return;
        const position = range.index;

        quillEditor.insertEmbed(position, 'mention', { ...fastMention, denotationChar: '@' }, 'user');
        quillEditor.insertText(position + 1, ' ', 'user');
        quillEditor.setSelection(quillEditor.getLength(), 0);
    }

    private mutationObserverCb: MutationCallback = (mutations) => {
        if (
            mutations.every((mutation) => {
                const attributeName = mutation.attributeName;
                if (!['href', 'cite', 'rel'].includes(attributeName)) {
                    return false;
                }
                if (['href', 'rel'].includes(attributeName) && mutation.target.nodeName !== 'A') {
                    return false;
                }
                if (
                    attributeName === 'rel' &&
                    (mutation.target as HTMLElement).attributes.getNamedItem('rel').value !== 'nofollow'
                ) {
                    return false;
                }
                if (attributeName === 'cite' && !['BLOCKQUOTE', 'Q'].includes(mutation.target.nodeName)) {
                    return false;
                }
                return true;
            })
        ) {
            return;
        }
        const qlEditor = this._editor.editorElem.querySelector('.ql-editor');
        const qlEditorInnerHtml = qlEditor.innerHTML;
        if (this.editorFormControl.value !== qlEditorInnerHtml) {
            this.editorFormControl.setValue(this._sanitizer.sanitize(SecurityContext.HTML, qlEditorInnerHtml));
        }
    };

    ngOnDestroy(): void {
        this._observer.disconnect();
    }
}
