<h2 mat-dialog-title>Configure assessment summary</h2>
<mat-dialog-content>
    <ng-container *ngIf="loadingSummary; else doneLoading">
        <div class="d-flex align-items-center justify-content-center">
            <div class="sk-spinner sk-spinner-pulse"></div>
        </div>
    </ng-container>
    <ng-template #doneLoading>
        <strong *ngIf="isUsingOrgProfileAssessmentConfig; else usingRelationshipAssessmentConfig"
            >These are the default settings for your organization. Updating the sections here will only affect this
            relationship.
        </strong>
        <ng-template #usingRelationshipAssessmentConfig>
            <strong
                >This configuration has been updated. Update and save your changes. You may also restore the defaults
                for your organization.
            </strong>
        </ng-template>
        <div class="mt-4">
            <app-assessment-summary-config-form
                [assessmentSummarySections]="assessmentSummaryConfig$ | async"
                [isCurrentUserOrgAdmin]="isCurrentUserOrgAdmin$ | async"
                [exampleSummary]="exampleSummary$ | async"
                (assessmentSummaryConfigChanged)="assessmentSummaryConfigChanged($event)"
            />
        </div>
    </ng-template>
</mat-dialog-content>
<mat-dialog-actions class="d-flex justify-content-between">
    <div>
        <button
            mat-button
            visoButton="warning"
            class="me-2"
            type="button"
            *ngIf="!isUsingOrgProfileAssessmentConfig"
            (click)="deleteRelationshipAssessmentSummaryConfig()"
        >
            Restore org defaults
        </button>
    </div>
    <div>
        <button mat-button visoButton="secondary" class="me-2" type="button" mat-dialog-close>Cancel</button>
        <button
            mat-button
            visoButton="primary"
            [disabled]="savingConfig || !summaryConfigPayload"
            (click)="updateRelationshipAssessmentSummaryConfig()"
        >
            Save
        </button>
    </div>
</mat-dialog-actions>
