import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { FastMention, QuillMentionListItem } from '../../../../shared/components/quill-wrapper/quill.model';
import { VisoUser, VisoUserRole } from '../../../../entities/viso-user';
import { RelationshipComment } from '../../../../entities/relationship-comment';
import {
    createRequestCommentRequestSuccess,
    deleteRequestCommentRequestSuccess,
} from '../../redux/actions/comments.actions';

@Component({
    selector: 'app-comment-handler',
    templateUrl: './comment-handler.component.html',
    styleUrls: ['./comment-handler.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommentHandlerComponent implements OnInit {
    @Input()
    comments: RelationshipComment[];

    @Input()
    currentAccount: VisoUser;

    @Input()
    mentions: QuillMentionListItem[];

    @Input()
    fastMentions: FastMention[];

    @Output()
    createComment = new EventEmitter<string>();

    @Output()
    deleteComment = new EventEmitter<number>();

    Roles = VisoUserRole;

    cleanForm$: Observable<void>;

    constructor(private _actions$: Actions) {}

    ngOnInit(): void {
        this.cleanForm$ = this._actions$.pipe(
            ofType(createRequestCommentRequestSuccess, deleteRequestCommentRequestSuccess),
        );
    }
}
