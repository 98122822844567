import { CdkStepper } from '@angular/cdk/stepper';
import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { FileArtifactType, FileArtifactTypeLabels } from '@entities/artifact';
import { AttestationValue } from '@entities/attestation';
import { FileLikeObject, FileUploader } from '@shared/file-upload';
import {
    ArtifactUploadRecommendation,
    isExpiredDocumentationRecommendation,
    isSiblingDocumentationRecommendation,
    PublicAssessmentRTPFileArtifact,
} from '../../../models';
import { AuditReportTypeName } from '@entities/audit-report';
import { FilterFunction } from '@shared/file-upload/interfaces';

@Component({
    selector: 'app-artifact-upload-recommendations',
    templateUrl: './artifact-upload-recommendations.component.html',
    styleUrls: ['./artifact-upload-recommendations.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArtifactUploadRecommendations implements OnInit, OnChanges {
    @Input({ required: true })
    recommendations: ArtifactUploadRecommendation[];

    @Input({ required: true })
    vendorName: string;

    @Output()
    wentBack = new EventEmitter<void>();

    @Output()
    continued = new EventEmitter<void>();

    @Output()
    uploadArtifact = new EventEmitter<{ recommendationId: string; file: File }>();

    @Output()
    onWhenAddingFileFailed = new EventEmitter<FilterFunction>();

    @Output()
    setPassword = new EventEmitter<{
        artifact: PublicAssessmentRTPFileArtifact;
        password: string;
    }>();

    @Output()
    removeArtifact = new EventEmitter<PublicAssessmentRTPFileArtifact>();

    @Output()
    updateRecommendation = new EventEmitter<ArtifactUploadRecommendation>();

    @ViewChild(CdkStepper)
    private _stepper: CdkStepper;

    uploader: FileUploader;
    currentAttestationValueControl: FormControl<AttestationValue>;

    AttestationValue = AttestationValue;
    FileArtifactTypeLabels = FileArtifactTypeLabels;

    artifactLabels: Partial<Record<FileArtifactType, string>> = {
        AUDIT_REPORT: 'third party audit',
        ASSESSMENT: 'penetration test',
        PRIVACY: 'privacy document',
        CYBER_INSURANCE: 'cyber insurance document',
    };

    constructor(private _fb: FormBuilder) {}

    ngOnInit(): void {
        this.currentAttestationValueControl = this._fb.control(null);

        this.uploader = new FileUploader({
            url: '',
            maxFileSize: 100000000,
        });

        this.uploader.onWhenAddingFileFailed = (item: FileLikeObject, filter: any, options: any) => {
            this.onWhenAddingFileFailed.emit(filter);
        };
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!!this._stepper) {
            const recommendation = changes.recommendations.currentValue[
                this._stepper.selectedIndex
            ] as ArtifactUploadRecommendation;
            if (!!recommendation?.attestationArtifact) {
                this.currentAttestationValueControl.setValue(null);
                this.currentAttestationValueControl.disable();
            } else {
                this.currentAttestationValueControl.enable();
            }
        }
    }

    getAttestationArtifactAsArray(recommendation: ArtifactUploadRecommendation): PublicAssessmentRTPFileArtifact[] {
        return !!recommendation.attestationArtifact ? [recommendation.attestationArtifact] : [];
    }

    continue(recommendation: ArtifactUploadRecommendation): void {
        if (this._stepper.selectedIndex === this.recommendations.length - 1) {
            this.continued.emit();
        } else {
            this._stepper.next();
        }
        this.processTransitionToNewStep(recommendation);
    }

    goBack(recommendation: ArtifactUploadRecommendation): void {
        if (this._stepper.selectedIndex === 0) {
            this.wentBack.emit();
        } else {
            this._stepper.previous();
        }
        this.processTransitionToNewStep(recommendation);
    }

    emitUpload(recommendationId: string, file: File): void {
        this.uploadArtifact.emit({ recommendationId, file });
    }

    backDisabled(recommendation: ArtifactUploadRecommendation): boolean {
        return (
            !!recommendation.attestationArtifact && !recommendation.attestationArtifact.requiredRTPValidationsReceived
        );
    }

    continueDisabled(recommendation: ArtifactUploadRecommendation): boolean {
        return (
            !this.currentAttestationValueControl.value &&
            (!recommendation.attestationArtifact || !recommendation.attestationArtifact.requiredRTPValidationsReceived)
        );
    }

    classificationIsIncorrect(artifact: PublicAssessmentRTPFileArtifact): boolean {
        return !!artifact && (artifact.fileArtifactTypeOverridden || artifact.auditReportTypeOverridden);
    }

    getIncorrectClassificationLabel(recommendation: ArtifactUploadRecommendation): string {
        if (isExpiredDocumentationRecommendation(recommendation)) {
            const auditTypeName = AuditReportTypeName[recommendation.expiredAuditType];
            return (
                `This artifact was not classified as ${auditTypeName}. ` +
                'Attest that you do not have this artifact or continue if the provided artifact is correct. ' +
                "If you believe this to be in error, don't worry. All assessments go through a human audit."
            );
        }
        if (isSiblingDocumentationRecommendation(recommendation)) {
            const auditTypeName = AuditReportTypeName[recommendation.requiredSiblingAuditType];
            return (
                `This artifact was not classified as ${auditTypeName}. ` +
                'Attest that you do not have this artifact or continue if the provided artifact is correct. ' +
                "If you believe this to be in error, don't worry. All assessments go through a human audit."
            );
        } else {
            const documentLabel = this.artifactLabels[recommendation.fileArtifactType];
            const categoryLabel = FileArtifactTypeLabels[recommendation.fileArtifactType].toLowerCase();
            return (
                `This artifact was not classified as a ${documentLabel}. ` +
                `Attest that you do not have an artifact for ${categoryLabel} or continue if you believe this substantiates ${categoryLabel}. ` +
                "If you believe this to be in error, don't worry. All assessments go through a human audit."
            );
        }
    }

    getAttestationOptions(recommendation: ArtifactUploadRecommendation): { label: string; value: AttestationValue }[] {
        const result = [];
        const fileTypeLabel = FileArtifactTypeLabels[recommendation.fileArtifactType].toLowerCase();
        if (isExpiredDocumentationRecommendation(recommendation)) {
            const auditTypeName = AuditReportTypeName[recommendation.expiredAuditType];
            result.push(
                {
                    label: `${this.vendorName} does not have a more recent ${auditTypeName}.`,
                    value: AttestationValue.DONT_HAVE,
                },
                {
                    label: `${this.vendorName} will not provide a more recent ${auditTypeName}.`,
                    value: AttestationValue.WONT_PROVIDE,
                },
            );
        } else if (isSiblingDocumentationRecommendation(recommendation)) {
            const auditTypeName = AuditReportTypeName[recommendation.requiredSiblingAuditType];
            result.push(
                {
                    label: `${this.vendorName} does not have ${auditTypeName}.`,
                    value: AttestationValue.DONT_HAVE,
                },
                {
                    label: `${this.vendorName} will not provide ${auditTypeName}.`,
                    value: AttestationValue.WONT_PROVIDE,
                },
            );
        } else {
            result.push(
                {
                    label: `${this.vendorName} does not have artifacts for ${fileTypeLabel}.`,
                    value: AttestationValue.DONT_HAVE,
                },
                {
                    label: `${this.vendorName} will not provide artifacts for ${fileTypeLabel}.`,
                    value: AttestationValue.WONT_PROVIDE,
                },
                {
                    label: `Another artifact substantiates ${fileTypeLabel}.`,
                    value: AttestationValue.OTHER_ARTIFACT,
                },
            );
        }
        return result;
    }

    private processTransitionToNewStep(recommendation: ArtifactUploadRecommendation): void {
        recommendation.attestationValue = this.currentAttestationValueControl.value;
        this.updateRecommendation.emit(recommendation);

        const nextRecommendation = this.recommendations[this._stepper.selectedIndex];

        if (!!nextRecommendation.attestationArtifact) {
            this.currentAttestationValueControl.setValue(null);
            this.currentAttestationValueControl.disable();
        } else {
            this.currentAttestationValueControl.enable();
            this.currentAttestationValueControl.setValue(nextRecommendation.attestationValue);
        }
    }
}
