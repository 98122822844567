<div class="content-heading">
    <div>
        Organization Import
        <small>Admin controls for starting the organization import process from crunchbase csv data</small>
    </div>
</div>
<div class="row">
    <div class="col-md-6">
        <div class="card card-default">
            <div class="card-header">
                <p class="card-title">Import Organization Data</p>
            </div>
            <div class="card-body">
                <div class="card-message">
                    Currently this function starts the organization import process from crunchbase csv data.
                </div>
                <button
                    class="mb-2 ms-2"
                    mat-button
                    visoButton="primary"
                    (click)="startImportProcess()"
                    [disabled]="importStarted"
                >
                    Start Import Process
                </button>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-md-6">
        <div class="card card-default">
            <div class="card-header">
                <p class="card-title">Fetch Organization Metadata</p>
            </div>
            <div class="card-body">
                <div class="card-message">
                    Currently this function fetches metadata for orgs that are in the US and do not have descriptions or
                    favicons set.
                </div>
                <div class="card-message text-warning">DO NOT RUN THIS PRIOR TO THE ORG IMPORT COMPLETING.</div>
                <button mat-button visoButton="primary" class="mb-2 ms-2" (click)="startOrgMetadataFetchingProcess()">
                    Fetch Metadata
                </button>
            </div>
        </div>
    </div>
</div>
