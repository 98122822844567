import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { map, shareReplay, takeUntil, tap } from 'rxjs/operators';
import { getUserAccount } from '../session/redux/session.selectors';
import {
    getContactsRequestSuccess,
    getContactsRequest,
    reassignContactRelationshipsRequest,
    reassignContactRelationshipsRequestSuccess,
    ContactActions,
} from '../contact-management/redux/contact-management.actions';
import { RefSelectOption } from '../../shared/model/select-options';
import { VisoUser } from '../../entities/viso-user';

enum ReassignType {
    OWNERSHIPS,
    SUBSCRIPTIONS,
}
@Component({
    selector: 'app-contact-reassign-modal',
    templateUrl: './contact-reassign-modal.component.html',
    styleUrls: ['./contact-reassign-modal.component.scss'],
})
export class ContactReassignModalComponent implements OnInit {
    @Input()
    contactId: number;

    @Input()
    onlyShowReassignmentForm?: boolean;

    contacts$: Observable<VisoUser[]>;
    contactsSelectOptions$: Observable<RefSelectOption<VisoUser | number>[]>;

    currentAccount: VisoUser;

    contact: VisoUser;

    contactReassignFormGroup: UntypedFormGroup;
    ReassignType = ReassignType;
    ContactActions = ContactActions;

    private _unsub = new Subject<void>();

    get reassignType() {
        return this.contactReassignFormGroup.controls.reassignType as UntypedFormControl;
    }

    get contactFullName() {
        return `${this.contact?.firstName} ${this.contact?.lastName}`;
    }

    constructor(
        private _activeModal: NgbActiveModal,
        private _store$: Store,
        private _actions$: Actions,
        private _fb: UntypedFormBuilder,
    ) {}

    ngOnInit() {
        this._store$.pipe(select(getUserAccount), takeUntil(this._unsub)).subscribe((account) => {
            this.currentAccount = account;
        });

        this.contactReassignFormGroup = this._fb.group({
            fromContactId: [this.contactId, Validators.required],
            toContactId: ['', Validators.required],
            reassignType: ['', Validators.required],
        });

        this.loadContacts();

        this.contacts$ = this._actions$.pipe(
            ofType(getContactsRequestSuccess),
            map(({ contacts }) => contacts),
            shareReplay(1),
        );

        this._actions$
            .pipe(
                ofType(getContactsRequestSuccess),
                map(({ contacts }) => {
                    contacts
                        .filter((contact: VisoUser) => contact.id.toString() === this.contactId.toString())
                        .map((contact) => {
                            this.contact = contact;
                        });
                }),
                takeUntil(this._unsub),
            )
            .subscribe();

        this.contactsSelectOptions$ = this.contacts$.pipe(
            map((contacts) =>
                contacts.map<RefSelectOption<VisoUser>>((contact) => ({
                    ref: contact,
                    name: `${contact.firstName} ${contact.lastName}  •  ${contact.email}  •  ${contact.businessUnitName}`,
                })),
            ),
            map((selectOptions) => {
                let contactMatchingCurrentUser = selectOptions.find(
                    (sub) => sub.ref.email.toLowerCase() === this.currentAccount.email.toLowerCase(),
                )?.ref;

                let contactList = [
                    ...selectOptions.filter(
                        (sub) =>
                            sub.ref.email.toLowerCase() !== this.currentAccount.email.toLowerCase() &&
                            sub.ref.id != this.contactId,
                    ),
                ];

                contactList = contactList.sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1));

                if (
                    contactMatchingCurrentUser &&
                    contactMatchingCurrentUser.id.toString() !== this.contactId.toString()
                ) {
                    contactList.splice(0, 0, {
                        ref: contactMatchingCurrentUser,
                        name: `${this.currentAccount.firstName} ${this.currentAccount.lastName}  •  ${this.currentAccount.email}  •  ${contactMatchingCurrentUser.businessUnitName}`,
                    });
                }
                return contactList;
            }),
        );

        this._actions$
            .pipe(
                ofType(reassignContactRelationshipsRequestSuccess),
                tap(() => {
                    this._activeModal.dismiss('success');
                }),
                takeUntil(this._unsub),
            )
            .subscribe();
    }

    contactSelected(selectedContact: VisoUser) {
        this.contactReassignFormGroup.controls.toContactId.setValue(selectedContact?.id);
        if (this.onlyShowReassignmentForm) {
            this.contactReassignFormGroup.controls.reassignType.setValue([
                ReassignType.SUBSCRIPTIONS,
                ReassignType.OWNERSHIPS,
            ]);
        }
    }

    loadContacts() {
        this._store$.dispatch(getContactsRequest(null));
    }

    clear() {
        this._activeModal.dismiss('cancel');
    }

    get contactEditPopup(): any[] {
        return [
            '/',
            {
                outlets: {
                    popup: `user/${this.contactId}/edit`,
                },
            },
        ];
    }

    reassign() {
        const { fromContactId, toContactId, reassignType } = this.contactReassignFormGroup.value;
        this._store$.dispatch(
            reassignContactRelationshipsRequest({
                fromContactId,
                toContactId,
                reassignOwnerships: reassignType.includes(ReassignType.OWNERSHIPS),
                reassignSubscriptions: reassignType.includes(ReassignType.SUBSCRIPTIONS),
            }),
        );
    }
}
