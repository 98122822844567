import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, Validators } from '@angular/forms';

export const urlValidator = Validators.pattern(
    /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|(?!(.*www\.){1})[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/,
);

import { Directive } from '@angular/core';

@Directive({
    selector: '[urlValidation]',
    providers: [{ provide: NG_VALIDATORS, useExisting: UrlValidationDirective, multi: true }],
})
export class UrlValidationDirective implements Validator {
    constructor() {}

    validate(control: AbstractControl): ValidationErrors | null {
        return control.value ? urlValidator(control) : null;
    }
}
