import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable, of } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class UserActivationGuard {
    constructor(private _router: Router) {}

    canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
        const paramName = 'activationToken';
        const activationToken = route.queryParams[paramName];
        if (!activationToken) {
            this._router.navigate(['accessdenied']);
            return of(false);
        }
        return of(true);
    }
}
