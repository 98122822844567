import { MemoizedSelector, createFeatureSelector, createSelector } from '@ngrx/store';
import { ContextType } from '../../../entities/context-type';
import { AppState, ContextTypesState, contextTypesStateKey } from './context-types.state';

export const getContextTypesState: MemoizedSelector<AppState, ContextTypesState> =
    createFeatureSelector<ContextTypesState>(contextTypesStateKey);

export const getContextTypes: MemoizedSelector<AppState, ContextType[]> = createSelector(
    getContextTypesState,
    (state) => state.contextTypes,
);
