import { Pipe, PipeTransform } from '@angular/core';

interface EnumItem {
    value: any;
    label: string;
}

/**
 * Pipe to return true if the number is NOT NaN
 */
@Pipe({
    name: 'enumToArray',
})
export class EnumToArrayPipe implements PipeTransform {
    transform(enumObject: Object, enumLabelObject?: Object): EnumItem[] {
        return Object.keys(enumObject).map<EnumItem>((value) => ({
            value,
            label: enumLabelObject?.[value] || value,
        }));
    }
}

@Pipe({
    name: 'enumKeysToArray',
})
export class EnumKeysToArrayPipe implements PipeTransform {
    transform(enumObject: Object): unknown[] {
        return Object.keys(enumObject);
    }
}
