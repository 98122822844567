import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Notification, CreateLinkNotificationPayload, NotificationConnection } from './notification.model';
import { createRequestOption } from '../../shared';
import { TimeService } from '../../shared/services/time.service';
import { DateUtilsService } from '../../shared/utils/date-utils.service';
import { Apollo, gql } from 'apollo-angular';

export type EntityResponseType = HttpResponse<Notification>;

@Injectable({
    providedIn: 'root',
})
export class NotificationService {
    private _resourceUrl = 'api/notifications';

    constructor(
        private _http: HttpClient,
        private _dateUtilsService: DateUtilsService,
        private _timeService: TimeService,
        private _apollo: Apollo,
    ) {}

    updateStatus(status: string, { recordIds }, clearAll = false): Observable<Notification[]> {
        const queryString = clearAll ? `clearAll=${clearAll}` : `recordIds=${recordIds.join(',')}`;
        return this._http.put<Notification[]>(`${this._resourceUrl}/${status}?${queryString}`, { observe: 'response' });
    }

    findAll(): Observable<HttpResponse<Notification[]>> {
        const options = createRequestOption({ statuses: ['NEW', 'READ'] });
        return this._http
            .get<Notification[]>(this._resourceUrl, {
                params: options,
                observe: 'response',
            })
            .pipe(map((res: HttpResponse<Notification[]>) => this.convertArrayResponse(res)));
    }

    onNewNotificationForCurrentUser(): Observable<Notification> {
        const ON_NEW_NOTIFICATION_FOR_CURRENT_USER = gql`
            subscription ON_NEW_NOTIFICATION_FOR_CURRENT_USER {
                onNewNotificationForCurrentUser {
                    id
                    visoUserId
                    status
                    createdDate
                    modifiedDate
                    tipType
                    description
                    anchorText
                    url
                    data
                    eventNotificationType
                    notificationType
                }
            }
        `;

        return this._apollo
            .subscribe<any>({
                query: ON_NEW_NOTIFICATION_FOR_CURRENT_USER,
            })
            .pipe(
                map((response) => Object.entries(response.data)[0][1] as Notification),
                map((notificationData) => this.convertItemFromServer(notificationData)),
            );
    }

    findAllReadAndNewNotificationsForCurrentUser(): Observable<NotificationConnection> {
        const GET_ALL_READ_AND_NEW_NOTIFICATIONS_FOR_CURRENT_USER = gql`
            query GET_ALL_READ_AND_NEW_NOTIFICATIONS_FOR_CURRENT_USER {
                findAllReadAndNewNotificationsForCurrentUser(first: 20) {
                    edges {
                        node {
                            id
                            visoUserId
                            status
                            createdDate
                            modifiedDate
                            tipType
                            description
                            anchorText
                            url
                            data
                            eventNotificationType
                            notificationType
                        }
                        cursor
                    }
                    pageInfo {
                        startCursor
                        endCursor
                        hasNextPage
                        hasPreviousPage
                    }
                    totalCount
                }
            }
        `;

        return this._apollo
            .query<any>({
                query: GET_ALL_READ_AND_NEW_NOTIFICATIONS_FOR_CURRENT_USER,
                fetchPolicy: 'no-cache',
            })
            .pipe(
                map((response) => Object.entries(response.data)[0][1] as NotificationConnection),
                map((response) => this.convertArrayResponseForPagedNotifications(response)),
            );
    }

    createLinkNotification(linkNotificationPayload: CreateLinkNotificationPayload) {
        return this._http.post(`${this._resourceUrl}/link`, linkNotificationPayload);
    }

    private convertArrayResponse(res: HttpResponse<Notification[]>): HttpResponse<Notification[]> {
        const jsonResponse: Notification[] = res.body;
        const convertedNotifications = this.convertArrayResponseForNotifications(jsonResponse);
        return res.clone({ body: convertedNotifications });
    }

    private convertArrayResponseForPagedNotifications(res: NotificationConnection): NotificationConnection {
        const jsonResponse: Notification[] = res.edges.map((edge) => edge.node);
        const convertedNotifications = this.convertArrayResponseForNotifications(jsonResponse);
        const clone = Object.assign({}, res);
        for (let i = 0; i < convertedNotifications.length; i++) {
            clone.edges[i].node = convertedNotifications[i];
        }
        return clone;
    }

    private convertArrayResponseForNotifications(notifications: Notification[]): Notification[] {
        const body: Notification[] = [];
        for (let i = 0; i < notifications.length; i++) {
            body.push(this.convertItemFromServer(notifications[i]));
        }
        return body;
    }

    /**
     * Convert a returned JSON object to Notification.
     */
    private convertItemFromServer(notification: Notification): Notification {
        const copy: Notification = Object.assign({}, notification);
        copy.createdDate = this._dateUtilsService.convertDateTimeFromServer(notification.createdDate);
        copy.modifiedDate = this._dateUtilsService.convertDateTimeFromServer(notification.modifiedDate);
        copy.humanizedCreatedDate = this._timeService.humanizeDate(notification.createdDate);
        return copy;
    }
}
