import { createAction, props } from '@ngrx/store';
import { PrimaryVendorContact } from '../../../primary-vendor-contact';

export enum PrimaryContactActions {
    GetPrimaryVendorContactRequest = '[Primary Contact] Get Primary Vendor Contact Request',
    GetPrimaryVendorContactRequestSuccess = '[Primary Contact] Get Primary Vendor Contact Request Success',
    GetPrimaryVendorContactRequestFailed = '[Primary Contact] Get Primary Vendor Contact Request Failed',
}

export const getPrimaryVendorContactRequest = createAction(
    PrimaryContactActions.GetPrimaryVendorContactRequest,
    props<{ primaryContactId: number }>(),
);

export const getPrimaryVendorContactRequestSuccess = createAction(
    PrimaryContactActions.GetPrimaryVendorContactRequestSuccess,
    props<{ primaryVendorContact: PrimaryVendorContact }>(),
);

export const getPrimaryVendorContactRequestFailed = createAction(
    PrimaryContactActions.GetPrimaryVendorContactRequestFailed,
);
