<div class="text-truncate document-file-name">
    <a *ngIf="isUrl; else nonUrl" [externalLink]="documentFileName">{{ documentFileName }}</a>
    <ng-template #nonUrl>{{ documentFileName }}</ng-template>
</div>
<div
    *ngIf="includePassword"
    class="include-password-container mat-border"
    [ngClass]="{ 'include-password-border mat-border-secondary': showPasswordInput }"
>
    <button
        *ngIf="!passwordLocked && !showPasswordInput"
        mat-icon-button
        (click)="enterPasswordInput()"
        [disabled]="disabled"
    >
        <i class="fa fa-key"></i>
    </button>
    <div class="d-flex" placement="bottom left" dropdown container="body" *ngIf="passwordLocked && !showPasswordInput">
        <button
            id="button-container"
            dropdownToggle
            mat-icon-button
            [disabled]="disabled"
            aria-controls="dropdown-container"
        >
            <i class="fa fa-key mat-text-secondary"></i>
        </button>
        <ul
            id="dropdown-container"
            *dropdownMenu
            class="dropdown-menu dropdown-menu-start translate-x"
            aria-labelledby="button-container"
            style="--data-translate-value: -120px"
        >
            <li>
                <a class="dropdown-item" (click)="enterPasswordInput(true)">Update Password</a>
            </li>
            <li>
                <a class="dropdown-item dropdown-item-highlight-red" (click)="removePassword()">Remove Password</a>
            </li>
        </ul>
    </div>
    <div class="input-group password-protection-container" *ngIf="showPasswordInput">
        <input
            #passwordInput
            type="password"
            class="form-control password-protection-input"
            [placeholder]="updatePasswordMode ? 'Update Password' : 'Enter Password'"
            [disabled]="disabled"
        />
        <button type="button" mat-button visoButton="primary" class="save-password-action" (click)="savePassword()">
            Save
        </button>
        <button class="discard-password-action" mat-icon-button (click)="discardPassword()" [disabled]="disabled">
            <span class="fa fa-times"></span>
        </button>
    </div>
</div>
<div class="delete-document-file" *ngIf="!showPasswordInput">
    <button mat-icon-button [disabled]="disabled" (click)="onFileRemoved.emit()">
        <i class="fa fa-trash"></i>
    </button>
</div>
