<div class="viso-editor-container">
    <div class="action-container">
        <button type="button" class="centered" mat-icon-button (click)="addOrRemoveBullet()">
            <mat-icon>format_list_bulleted</mat-icon>
        </button>
    </div>
    <div
        class="viso-editor"
        pasteAsPlainText
        [style.height.px]="height"
        id="editableDiv"
        contenteditable="true"
        [formControl]="control"
        [attr.placeholder]="placeholder"
    ></div>
</div>
