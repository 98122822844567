export interface ElasticSearchIndexRequest {
    deleteAndRecreateIndex: boolean;
    esIndex: ESIndex;
}

export enum ESIndex {
    VENDOR = 'VENDOR',
    RELATIONSHIP = 'RELATIONSHIP',
}

export enum ESIndexLabels {
    VENDOR = 'Vendor',
    RELATIONSHIP = 'Relationship',
}
