import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { SnackComponentData } from '@shared/components/snackbar/snack.model';

@Component({
    selector: 'viso-snackbar',
    templateUrl: './snackbar.component.html',
    styleUrls: ['./snackbar.component.scss'],
})
export class VisoSnackbarComponent {
    constructor(@Inject(MAT_SNACK_BAR_DATA) public snack: SnackComponentData) {}
}
