<div style="display: block">
    <canvas
        baseChart
        height="300"
        width="1000"
        type="line"
        [options]="chartOptions"
        [datasets]="riskAssessmentsDatasets"
    >
    </canvas>
</div>
