<div class="scrollbar">
    <table class="table table-condensed bg-transparent table-hover">
        <thead>
            <th>Date</th>
            <th>Sent To</th>
            <th>Template</th>
            <th>Type</th>
        </thead>
        <tbody class="table-group-divider">
            <tr *ngFor="let email of assessmentEmails">
                <td>
                    {{ email.createdDate | date: 'medium' }}
                </td>
                <td>
                    <em *ngIf="email.bounce">
                        <a [routerLink]="[]" (click)="openBounceDetails(email)"
                            ><em class="fa fa-exclamation-circle text-danger me-2"></em
                        ></a>
                    </em>
                    {{ email.targetEmail }}
                </td>
                <td>
                    <a
                        *ngIf="email | callback: showViewEmailButton; else noViewEmailButton"
                        (click)="viewEmailDetails(email)"
                        class="viso-link d-flex align-items-center"
                    >
                        <strong> {{ email.type | replace: formatTemplateRegex : ' ' | titlecase }}</strong>
                        <mat-icon [inline]="true" class="ms-1">open_in_new</mat-icon>
                    </a>
                    <ng-template #noViewEmailButton>
                        <span matTooltip="Email is before retention period">
                            {{ email.type | replace: formatTemplateRegex : ' ' | titlecase }}
                        </span>
                    </ng-template>
                </td>
                <td>
                    <span [ngClass]="emailTypeClass(email)">
                        {{ getEmailType(email) }}
                    </span>
                </td>
            </tr>
        </tbody>
    </table>
</div>
