import { UrlMatcher, UrlSegment } from '@angular/router';

export const assessmentCollectionRoute = 'assessment-request';
export const recertificationCollectionRoute = 'assessment-recertification';
export const artifactUpdateCollectionRoute = 'assessment-artifact-update';

export const assessmentCollectionTokenParamName = 'assessmentToken';
export const assessmentCollectionAuthenticateSubRoute = 'authenticate';

export const assessmentCollectionForwardedSubRoute = 'forwarded';
export const assessmentCollectionSubmittedSubRoute = 'submitted';
export const assessmentCollectionCancelledSubRoute = 'cancelled';
export const assessmentCollectionCompletedSubRoute = 'completed';

export const notInProgressAssessmentUrlMatcher: UrlMatcher = (url: UrlSegment[]) => {
    if (
        url.length === 2 &&
        url[1].path.match(
            `^(${assessmentCollectionForwardedSubRoute}|${assessmentCollectionSubmittedSubRoute}|${assessmentCollectionCancelledSubRoute}|${assessmentCollectionCompletedSubRoute})$`,
        )
    ) {
        return { consumed: url, posParams: { [assessmentCollectionTokenParamName]: url[0] } };
    }
    return null;
};
