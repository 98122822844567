import { FileArtifactType } from '@entities/artifact';

export const expectedArtifactLabels: Partial<Record<FileArtifactType, ReadonlyArray<string>>> = {
    AUDIT_REPORT: [
        'CSA STAR Level 2 Certification/Attestation Report',
        'ISO 27001 Certificate and Statement of Applicability',
        'SOC 2 Type 1/2 Report',
        'PCI DSS Certificate and Attestation of Compliance (on-site)',
        'HITRUST Certification/Validation Report',
    ],
    ASSESSMENT: [
        'External, Internal and Application Penetration Test Reports',
        'Application Security Assessment Reports',
        'Executive summaries or redacted reports',
        'Published security policies/standards and table of contents',
        'CSA STAR, Shared Assessment, PCI DSS, HITRUST or VSA self-assessment',
    ],
    PRIVACY: [
        'Privacy policy',
        'Data protection addendum',
        'Security and privacy whitepapers',
        'CAIQ (CSA star Level 2/1)',
    ],
    CYBER_INSURANCE: ['Certification of insurance (COI)'],
};
