import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { ControlDomain } from '../../../entities/control-domain';
import { AppState } from '../../../shared/redux/state';
import { ControlsState, controlsStateKey, initialState } from './controls.state';

export const getControlsState: MemoizedSelector<AppState, ControlsState> =
    createFeatureSelector<ControlsState>(controlsStateKey);

export const getControls: MemoizedSelector<AppState, ControlDomain[]> = createSelector(
    getControlsState,
    (state) => state.controls || initialState.controls,
);

export const getOrgControls: MemoizedSelector<AppState, ControlDomain[]> = createSelector(
    getControlsState,
    (state) => state.orgControls || initialState.orgControls,
);
