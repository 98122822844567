import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { RiskAssessment } from './risk-assessment.model';
import { RiskAssessmentRefresh } from '../../admin';
import { DateUtilsService } from '../../shared/utils/date-utils.service';

export type EntityResponseType = HttpResponse<RiskAssessment>;

@Injectable({
    providedIn: 'root',
})
export class RiskAssessmentService {
    private resourceUrl = 'api/risk-assessments';

    constructor(
        private http: HttpClient,
        private _dateUtilsService: DateUtilsService,
    ) {}

    refreshModel(riskModelRefresh: RiskAssessmentRefresh): Observable<Object> {
        return this.http.post(`${this.resourceUrl}/refresh`, riskModelRefresh);
    }

    findAllByRelationshipId(relationshipId: number): Observable<HttpResponse<RiskAssessment[]>> {
        return this.http
            .get<RiskAssessment[]>(`api/relationships/${relationshipId}/risk-assessments`, {
                observe: 'response',
            })
            .pipe(map((res: HttpResponse<RiskAssessment[]>) => this.convertArrayResponse(res)));
    }

    private convertArrayResponse(res: HttpResponse<RiskAssessment[]>): HttpResponse<RiskAssessment[]> {
        const jsonResponse: RiskAssessment[] = res.body;
        const body: RiskAssessment[] = [];
        for (let i = 0; i < jsonResponse.length; i++) {
            body.push(this.convertItemFromServer(jsonResponse[i]));
        }
        return res.clone({ body });
    }

    /**
     * Convert a returned JSON object to RiskAssessment.
     */
    private convertItemFromServer(riskAssessment: RiskAssessment): RiskAssessment {
        const copy: RiskAssessment = Object.assign({}, riskAssessment);
        copy.createdDate = this._dateUtilsService.convertDateTimeFromServer(riskAssessment.createdDate);
        return copy;
    }
}
