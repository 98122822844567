<div class="modal-header justify-content-start">
    <div class="d-flex align-items-center justify-content-between">
        <div class="d-flex flex-column">
            <h4 class="modal-title">{{ artifactName }}</h4>
            <div class="d-flex flex-row artifact-metadata">
                <div>Uploaded on {{ artifactUploadedDate | date: 'mediumDate' }}</div>
                <div class="mx-2">&bull;</div>
                <div>{{ controlsValidatedCount }} Controls Validated</div>
                <div class="mx-2">&bull;</div>
                <div>{{ artifactAuditType }}</div>
                <app-assurance-level-meter class="ms-2" [assuranceLevel]="artifactAssuranceLevel" [hideLabel]="true" />
            </div>
        </div>
    </div>
    <button mat-button visoButton="secondary" class="ms-auto" (click)="downloadArtifact()">Download</button>
    <button type="button" class="btn-close ms-3" data-dismiss="modal" aria-hidden="true" (click)="close()"></button>
</div>
<div class="modal-body">
    <div class="row">
        <div class="col">
            <pdf-viewer
                [src]="artifactBlob"
                [page]="page$ | async"
                [selectText]="detectedLanguage$ | async"
                [password]="password"
                height="100%"
                zoom="page-width"
            />
        </div>
        <div class="col-3 detections-wrapper">
            <detections-list
                [detections]="detections"
                [selectedDetection]="selectedDetection$ | async"
                (selectDetection)="selectDetection($event)"
            />
        </div>
    </div>
</div>
