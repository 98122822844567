import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { RelationshipService } from '../../../../entities/relationship';
import { NgbModalWrapperService } from '../../../../shared';
import { LifecycleManagementModalComponent } from '../../lifecycle-management/lifecycle-management-modal/lifecycle-management-modal.component';
import {
    openLifecycleManagementModal,
    updateLifecycleManagement,
    updateLifecycleManagementFailed,
    updateLifecycleManagementSuccess,
} from '../actions/lifecycle-management.actions';
import { getRelationshipPopulatedLatestAssessment } from '../selectors/assessment.selectors';

@Injectable()
export class LifecycleManagementEffects {
    openLifecycleManagementModal$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(openLifecycleManagementModal),
                withLatestFrom(this._store$.pipe(select(getRelationshipPopulatedLatestAssessment))),
                tap(([lifecycleManagementModalInputs, latestAssessment]) =>
                    this._modalService.open<LifecycleManagementModalComponent>(
                        LifecycleManagementModalComponent,
                        {
                            ...lifecycleManagementModalInputs,
                            latestCompletedAssessmentDate: latestAssessment?.completedDate,
                        },
                        {
                            size: 'lg',
                            centered: false,
                        },
                    ),
                ),
            ),
        { dispatch: false },
    );

    updateRelationshipLifecycleManagementRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(updateLifecycleManagement),
            switchMap(({ relationshipId, request }) =>
                this._relationshipService.updateLifecycleManagement(relationshipId, request).pipe(
                    map(() => updateLifecycleManagementSuccess()),
                    catchError(() => of(updateLifecycleManagementFailed())),
                ),
            ),
        ),
    );

    constructor(
        private _actions$: Actions,
        private _store$: Store,
        private _relationshipService: RelationshipService,
        private _modalService: NgbModalWrapperService,
    ) {}
}
