import { createAction, props } from '@ngrx/store';
import { CoupaIntegration, CoupaIntegrationFull, CoupaSupplierSyncResponse } from '../../../entities/coupa';
import { IntegrationType } from '../integrations.model';

export enum IntegrationActions {
    GetCoupaIntegrationRequest = '[Integrations] Get Coupa Config Request',
    GetCoupaIntegrationRequestSuccess = '[Integrations] Get Coupa Config Request Success',
    GetCoupaIntegrationRequestFailed = '[Integrations] Get Coupa Config Request Failed',

    PutCoupaIntegrationRequest = '[Integrations] Put Coupa Config Request',
    PutCoupaIntegrationRequestSuccess = '[Integrations] Put Coupa Config Request Success',
    PutCoupaIntegrationRequestFailed = '[Integrations] Put Coupa Config Request Failed',

    DeleteCoupaIntegrationRequest = '[Integrations] Delete Coupa Config Request',
    DeleteCoupaIntegrationRequestSuccess = '[Integrations] Delete Coupa Config Request Success',
    DeleteCoupaIntegrationRequestFailed = '[Integrations] Delete Coupa Config Request Failed',

    SyncCoupaIntegrationRequest = '[Integrations] Sync Coupa Integration Request',
    SyncCoupaIntegrationRequestSuccess = '[Integrations] Sync Coupa Integration Request Success',
    SyncCoupaIntegrationRequestFailed = '[Integrations] Sync Coupa Integration Request Failed',

    GetConnectedIntegrationsRequest = '[Integrations] Get Connected Integrations Request',
    GetConnectedIntegrationsRequestSuccess = '[Integrations] Get Connected Integrations Request Success',
    GetConnectedIntegrationsRequestFailed = '[Integrations] Get Connected Integrations Request Failed',

    GetInterestedIntegrationsRequest = '[Integrations] Get Interested Integrations Request',
    GetInterestedIntegrationsRequestSuccess = '[Integrations] Get Interested Integrations Request Success',
    GetInterestedIntegrationsRequestFailed = '[Integrations] Get Interested Integrations Request Failed',

    CreateInterestedIntegrationRequest = '[Integrations] Create Interested Integration Request',
    CreateInterestedIntegrationRequestSuccess = '[Integrations] Create Interested Integration Request Success',
    CreateInterestedIntegrationRequestFailed = '[Integrations] Create Interested Integration Request Failed',
}

export const getCoupaIntegrationRequest = createAction(IntegrationActions.GetCoupaIntegrationRequest);

export const getCoupaIntegrationRequestSuccess = createAction(
    IntegrationActions.GetCoupaIntegrationRequestSuccess,
    props<{ config: CoupaIntegration }>(),
);

export const getCoupaIntegrationRequestFailed = createAction(IntegrationActions.GetCoupaIntegrationRequestFailed);

export const putCoupaIntegrationRequest = createAction(
    IntegrationActions.PutCoupaIntegrationRequest,
    props<{ config: CoupaIntegrationFull }>(),
);

export const putCoupaIntegrationRequestSuccess = createAction(
    IntegrationActions.PutCoupaIntegrationRequestSuccess,
    props<{ config: CoupaIntegration }>(),
);

export const putCoupaIntegrationRequestFailed = createAction(IntegrationActions.PutCoupaIntegrationRequestFailed);

export const deleteCoupaIntegrationRequest = createAction(IntegrationActions.DeleteCoupaIntegrationRequest);

export const deleteCoupaIntegrationRequestSuccess = createAction(
    IntegrationActions.DeleteCoupaIntegrationRequestSuccess,
);

export const deleteCoupaIntegrationRequestFailed = createAction(IntegrationActions.DeleteCoupaIntegrationRequestFailed);

export const syncCoupaIntegrationRequest = createAction(IntegrationActions.SyncCoupaIntegrationRequest);

export const syncCoupaIntegrationRequestSuccess = createAction(
    IntegrationActions.SyncCoupaIntegrationRequestSuccess,
    props<{ resp: CoupaSupplierSyncResponse }>(),
);

export const syncCoupaIntegrationRequestFailed = createAction(IntegrationActions.SyncCoupaIntegrationRequestFailed);

export const getConnectedIntegrationsRequest = createAction(IntegrationActions.GetConnectedIntegrationsRequest);

export const getConnectedIntegrationsRequestSuccess = createAction(
    IntegrationActions.GetConnectedIntegrationsRequestSuccess,
    props<{ connectedIntegrations: IntegrationType[] }>(),
);

export const getConnectedIntegrationsRequestFailed = createAction(
    IntegrationActions.GetConnectedIntegrationsRequestFailed,
);

export const getInterestedIntegrationsRequest = createAction(IntegrationActions.GetInterestedIntegrationsRequest);

export const getInterestedIntegrationsRequestSuccess = createAction(
    IntegrationActions.GetInterestedIntegrationsRequestSuccess,
    props<{ interestedIntegrations: IntegrationType[] }>(),
);

export const getInterestedIntegrationsRequestFailed = createAction(
    IntegrationActions.GetInterestedIntegrationsRequestFailed,
);

export const createInterestedIntegrationRequest = createAction(
    IntegrationActions.CreateInterestedIntegrationRequest,
    props<{
        integration: IntegrationType;
    }>(),
);

export const createInterestedIntegrationRequestSuccess = createAction(
    IntegrationActions.CreateInterestedIntegrationRequestSuccess,
);

export const createInterestedIntegrationRequestFailed = createAction(
    IntegrationActions.CreateInterestedIntegrationRequestFailed,
);
