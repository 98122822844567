import * as fromRoot from '../../../../shared/redux/state';
import { DomainVerification } from '@entities/domain-verification';

export const domainVerificationStateKey = 'domainVerification';

export const initialState: DomainVerificationsState = {
    preferredDomainVerification: null,
    domainVerifications: [],
    hasValidDomainVerification: false,
};

export interface AppState extends fromRoot.AppState {
    [domainVerificationStateKey]: DomainVerificationsState;
}

export interface DomainVerificationsState {
    preferredDomainVerification: DomainVerification | null;
    domainVerifications: DomainVerification[];
    hasValidDomainVerification: boolean;
}
