<ul class="ps-3" [ngClass]="{ 'd-flex': orientation === 'horizontal' }">
    @for (step of steps; track $index) {
        <li
            [ngClass]="{
                active: $index === selectedIndex,
                'vertical-border': orientation === 'vertical',
                'horizontal-border': orientation === 'horizontal',
                'mb-3': orientation === 'vertical',
                'ps-3': orientation === 'vertical',
            }"
            class="text-secondary"
        >
            <ng-container *ngIf="step.stepLabel; else showLabelText" [ngTemplateOutlet]="step.stepLabel.template">
            </ng-container>
            <ng-template #showLabelText>
                {{ step.label }}
            </ng-template>
        </li>
        @if (orientation === 'horizontal' && !$last) {
            <div class="mx-2 text-secondary">></div>
        }
    }
</ul>
