import { Injectable } from '@angular/core';

@Injectable()
export class ConvertToCsvService {
    downloadFile(data: any, filename = 'test', headers = []) {
        let csvData = this.convertToCSV(data, headers);
        let blob = new Blob(['\ufeff' + csvData], {
            type: 'text/csv;charset=utf-8;',
        });
        let dwldLink = document.createElement('a');
        let url = URL.createObjectURL(blob);
        let isSafariBrowser =
            navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
        if (isSafariBrowser) {
            dwldLink.setAttribute('target', '_blank');
        }
        dwldLink.setAttribute('href', url);
        dwldLink.setAttribute('download', filename + '.csv');
        dwldLink.style.visibility = 'hidden';
        document.body.appendChild(dwldLink);
        dwldLink.click();
        document.body.removeChild(dwldLink);
    }

    private convertToCSV(objArray: any, headerList: string[]) {
        let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
        let str = '';
        let row = '';
        if (headerList.length > 0) {
            for (let index in headerList) {
                const lastIndex = headerList.length - 1;
                row += headerList[index] + `${+index === lastIndex ? '' : ','}`;
            }
        } else {
            for (let key in array[0]) {
                const lastKey = Object.keys(array[0])[Object.keys(array[0]).length - 1];
                row += key + `${key === lastKey ? '' : ','}`;
            }
        }

        str += row + '\r\n';
        for (let i = 0; i < array.length; i++) {
            let line = '';
            for (let key in array[i]) {
                const lastKey = Object.keys(array[i])[Object.keys(array[i]).length - 1];
                if (key === 'vendorOrgName') {
                    line += `"${array[i][key]}"${key === lastKey ? '' : ','}`;
                } else {
                    line += `${array[i][key]}${key === lastKey ? '' : ','}`;
                }
            }
            str += line + '\r\n';
        }
        return str;
    }
}
