import { createAction, props } from '@ngrx/store';
import { Tag, TagPagination } from '../../../entities/tag';

export enum TagActions {
    GetTagsRequest = '[Tag] Get Tags',
    GetTagsRequestSuccess = '[Tag] Get Tags Success',
    GetTagsRequestFailed = '[Tag] Get Tags Failed',

    SelectTagById = '[Tag] Select Tag By Id',
    ClearSelectedTag = '[Tag] Clear Selected Tag',
    GetTagByIdRequestSuccess = '[Tag] Get Tag By Id Success',
    GetTagByIdRequestFailed = '[Tag] Get Tag By Id Failed',

    CreateTagRequest = '[Tag] Create Tag',
    CreateTagRequestSuccess = '[Tag] Create Tag Success',
    CreateTagRequestFailed = '[Tag] Create Tag Failed',

    UpdateTagRequest = '[Tag] Update Tag',
    UpdateTagRequestSuccess = '[Tag] Update Tag Success',
    UpdateTagRequestFailed = '[Tag] Update Tag Failed',

    DeleteTagRequest = '[Tag] Delete Tag',
    DeleteTagRequestSuccess = '[Tag] Delete Tag Success',
    DeleteTagRequestFailed = '[Tag] Delete Tag Failed',
}

/**
 * Tags Request Redux Action
 * Gets dispatched when requesting all tags is triggered.
 */
export const getTagsRequest = createAction(TagActions.GetTagsRequest, props<{ pagination?: TagPagination }>());
export const getTagsRequestSuccess = createAction(
    TagActions.GetTagsRequestSuccess,
    props<{ tags: Tag[]; total: number }>(),
);
export const getTagsRequestFailed = createAction(TagActions.GetTagsRequestFailed);

/**
 * Tags Request Redux Action
 * Gets dispatched when requesting a tag by Id is triggered.
 */
export const selectTagById = createAction(TagActions.SelectTagById, props<{ id: string }>());
export const clearSelectedTag = createAction(TagActions.ClearSelectedTag);
export const getTagByIdRequestSuccess = createAction(TagActions.GetTagByIdRequestSuccess, props<{ tag: Tag }>());
export const getTagByIdRequestFailed = createAction(TagActions.GetTagByIdRequestFailed);

/**
 * Tags Create Redux Action
 * Gets dispatched when creating a new tag is triggered.
 */
export const createTagRequest = createAction(
    TagActions.CreateTagRequest,
    props<{ tagPayload: Tag; selectTag?: boolean }>(),
);
export const createTagRequestSuccess = createAction(
    TagActions.CreateTagRequestSuccess,
    props<{ newTag: Tag; selectTag?: boolean }>(),
);
export const createTagRequestFailed = createAction(TagActions.CreateTagRequestFailed);

/**
 * Tags Update Redux Action
 * Gets dispatched when updating a new tag is triggered.
 */
export const updateTagRequest = createAction(TagActions.UpdateTagRequest, props<{ tagPayload: Tag }>());
export const updateTagRequestSuccess = createAction(TagActions.UpdateTagRequestSuccess, props<{ updatedTag: Tag }>());
export const updateTagRequestFailed = createAction(TagActions.UpdateTagRequestFailed);

/**
 * Tags Delete Redux Action
 * Gets dispatched when deleting a tag is triggered.
 */
export const deleteTagRequest = createAction(TagActions.DeleteTagRequest, props<{ id: string }>());
export const deleteTagRequestSuccess = createAction(TagActions.DeleteTagRequestSuccess);
export const deleteTagRequestFailed = createAction(TagActions.DeleteTagRequestFailed);
