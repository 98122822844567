import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { isPhoneViewport } from '../../shared/redux/selectors';
import { AppState } from '../../shared/redux/state';

@Component({
    selector: 'app-basic-header',
    templateUrl: './basic-header.component.html',
    styleUrls: ['./basic-header.component.scss'],
})
export class BasicHeaderComponent implements OnInit {
    isPhoneResolution$: Observable<boolean>;

    constructor(private _store$: Store<AppState>) {}

    ngOnInit(): void {
        this.isPhoneResolution$ = this._store$.pipe(select(isPhoneViewport));
    }
}
