import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { routerNavigationAction } from '@ngrx/router-store';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { filter, merge, of, switchMap, takeUntil, tap } from 'rxjs';

@Injectable()
export class ModalEffects {
    dismissPopupsOnNavigation$ = createEffect(
        () =>
            this._modal.activeInstances.pipe(
                filter((instances) => !!instances.length),
                switchMap((instances) =>
                    of(instances).pipe(
                        switchMap((instances) => instances),
                        switchMap((instance) =>
                            instance.shown.pipe(
                                switchMap(() =>
                                    this._actions$.pipe(
                                        ofType(routerNavigationAction),
                                        tap(() => instance.dismiss()),
                                        takeUntil(merge(instance.dismissed, instance.closed)),
                                    ),
                                ),
                            ),
                        ),
                    ),
                ),
            ),
        { dispatch: false },
    );

    constructor(
        private _actions$: Actions,
        private _modal: NgbModal,
    ) {}
}
