import { createAction, props } from '@ngrx/store';
import { VisoUser } from 'src/main/webapp/app/entities/viso-user';

export enum MentionableUsersActions {
    GetRequestMentionableUsersRequest = '[Request] Get Request Mentionable Users Request',
    GetRequestMentionableUsersRequestSuccess = '[Request] Get Request Mentionable Users Request Success',
    GetRequestMentionableUsersRequestFailed = '[Request] Get Request Mentionable Users Request Failed',
}

export const getRequestMentionableUsersRequest = createAction(
    MentionableUsersActions.GetRequestMentionableUsersRequest,
    props<{ requestId: number }>(),
);

export const getRequestMentionableUsersRequestSuccess = createAction(
    MentionableUsersActions.GetRequestMentionableUsersRequestSuccess,
    props<{ mentionableUsers: VisoUser[] }>(),
);

export const getRequestMentionableUsersRequestFailed = createAction(
    MentionableUsersActions.GetRequestMentionableUsersRequestFailed,
);
