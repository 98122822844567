import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-error',
    templateUrl: './error.component.html',
    styleUrls: ['./error.component.scss'],
})
export class ErrorComponent implements OnInit {
    errorCode: string;
    errorMessage: string;

    constructor(private _activatedRoute: ActivatedRoute) {}

    ngOnInit(): void {
        this._activatedRoute.data.subscribe((data) => {
            this.errorCode = data.errorCode;
            this.errorMessage = data.errorMessage;
        });
    }
}
