import { Injectable } from '@angular/core';
import { HttpClient, HttpContext, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
    Artifact,
    ArtifactIntelligence,
    ArtifactMetadataFile,
    ArtifactPasswordResponse,
    ArtifactType,
    ArtifactUploadResponse,
    ArtifactValidation,
    ArtifactValidationStatus,
    ControlValidation,
    FileArtifact,
    QuestionnaireArtifact,
    SignedURL,
    UploadFileArtifactRequest,
    UploadURLArtifactRequest,
    UrlArtifact,
} from './artifact.model';
import { CSRFService } from '../../shared';
import { isArtifactExpiringSoon } from './artifact.helpers';
import { AuditReportService, AuditReportTypeCode } from '../audit-report';
import { DateUtilsService } from '../../shared/utils/date-utils.service';
import { FileArtifactType } from '.';
import { AssuranceLevels, defaultAssuranceLevel } from '../relationship/models/security-control-domain';
import { BYPASS_SNACKBAR_ON_ERROR } from '../../blocks/interceptor/errorhandler.interceptor';
import { StaticValidationType } from '../static-validation/static-validation.model';

export type EntityResponseType = HttpResponse<Artifact>;

@Injectable({
    providedIn: 'root',
})
export class ArtifactService {
    private resourceUrl = 'api/artifacts';
    private relationshipResourceUrl = 'api/relationships';
    private assessmentResourceUrl = 'api/assessments';

    constructor(
        private _http: HttpClient,
        private _auditReportService: AuditReportService,
        private _dateUtilsService: DateUtilsService,
        private _csrfService: CSRFService,
    ) {}

    update(artifact: Artifact): Observable<HttpResponse<any>> {
        return this._http.put(this.resourceUrl, artifact, { observe: 'response' });
    }

    find(id: number): Observable<EntityResponseType> {
        return this._http
            .get<Artifact>(`${this.resourceUrl}/${id}`, { observe: 'response' })
            .pipe(map((res: EntityResponseType) => this.convertResponse(res)));
    }

    getArtifactIntelligence(id: number): Observable<HttpResponse<ArtifactIntelligence>> {
        return this._http
            .get<ArtifactIntelligence>(`${this.resourceUrl}/${id}/intelligence`, {
                observe: 'response',
                context: new HttpContext().set(BYPASS_SNACKBAR_ON_ERROR, { statuses: [404] }),
            })
            .pipe(map((res: HttpResponse<ArtifactIntelligence>) => this.convertIntelligenceResponse(res)));
    }

    findReferencedArtifactsForQuestionnaire(questionnaireId: number): Observable<HttpResponse<Artifact[]>> {
        return this._http
            .get<
                Artifact[]
            >(`${this.resourceUrl}/questionnaire/${questionnaireId}/referenced-artifacts`, { observe: 'response' })
            .pipe(map((res: HttpResponse<Artifact[]>) => this.convertArrayResponse(res)));
    }

    findByRelationship(relationshipId: number): Observable<HttpResponse<Artifact[]>> {
        return this._http
            .get<Artifact[]>(`${this.relationshipResourceUrl}/${relationshipId}/artifacts`, { observe: 'response' })
            .pipe(map((res: HttpResponse<Artifact[]>) => this.convertArrayResponse(res)));
    }

    uploadArtifacts(
        relationshipId: number,
        fileRequests: UploadFileArtifactRequest[],
        urlRequests: UploadURLArtifactRequest[],
    ): Observable<ArtifactUploadResponse> {
        const formData = new FormData();
        const filesMetaData = [];

        fileRequests.forEach((request) => {
            formData.append('files', request.file, request.file.name);
            filesMetaData.push(request.fileArtifactMetaData);
        });

        formData.append(
            'fileArtifactMetaData',
            new Blob([JSON.stringify(filesMetaData)], { type: 'application/json' }),
        );

        formData.append('urlSaveRequests', new Blob([JSON.stringify(urlRequests)], { type: 'application/json' }));

        return this._http.post<ArtifactUploadResponse>(
            `${this.relationshipResourceUrl}/${relationshipId}/artifacts/upload`,
            formData,
            {
                headers: new HttpHeaders({
                    name: 'X-XSRF-TOKEN',
                    value: this._csrfService.getCSRF(),
                }),
            },
        );
    }

    deleteRequestArtifact(relationshipId: number, artifactId: number): Observable<HttpResponse<any>> {
        return this._http.delete<any>(`${this.relationshipResourceUrl}/${relationshipId}/artifacts/${artifactId}`, {
            observe: 'response',
        });
    }

    deleteAssessmentArtifact(assessmentId: number, artifactId: number): Observable<void> {
        return this._http.delete<void>(`${this.assessmentResourceUrl}/${assessmentId}/artifacts/${artifactId}`);
    }

    createArtifactValidation(artifactId: number): Observable<ArtifactValidation> {
        return this._http.post<ArtifactValidation>(`${this.resourceUrl}/${artifactId}/validation`, null);
    }

    addControlValidation(artifactId: number, controlValidation: ControlValidation): Observable<void> {
        return this._http.post<void>(
            `${this.resourceUrl}/${artifactId}/validation/control-validation`,
            controlValidation,
        );
    }

    updateControlValidation(
        artifactId: number,
        controlValidationId: number,
        controlValidation: ControlValidation,
    ): Observable<void> {
        return this._http.post<void>(
            `${this.resourceUrl}/${artifactId}/validation/control-validation`,
            controlValidation,
        );
    }

    deleteControlValidation(artifactId: number, controlValidationId: number): Observable<void> {
        return this._http.delete<void>(
            `${this.resourceUrl}/${artifactId}/validation/control-validation/${controlValidationId}`,
        );
    }

    downloadArtifact(artifactId: number): Observable<HttpResponse<Blob>> {
        return this._http.get(`${this.resourceUrl}/${artifactId}/download`, {
            observe: 'response',
            responseType: 'blob',
        });
    }

    downloadArtifactsOnRelationshipAsZip(
        relationshipId: number,
        artifactIds: number[],
    ): Observable<HttpResponse<Blob>> {
        return this._http.post(`${this.relationshipResourceUrl}/${relationshipId}/artifacts/download`, artifactIds, {
            observe: 'response',
            responseType: 'blob',
        });
    }

    downloadArtifactsOnAssessmentAsZip(assessmentId: number, artifactIds: number[]): Observable<HttpResponse<Blob>> {
        return this._http.post(`${this.assessmentResourceUrl}/${assessmentId}/artifacts/download`, artifactIds, {
            observe: 'response',
            responseType: 'blob',
        });
    }

    getSignedURLForArtifactMetadataFile(
        artifactId: number,
        artifactMetadataFile: ArtifactMetadataFile,
    ): Observable<HttpResponse<SignedURL>> {
        return this._http.get<SignedURL>(
            `${this.resourceUrl}/${artifactId}/metadata/${artifactMetadataFile.enumName}`,
            {
                observe: 'response',
            },
        );
    }

    getArtifactMetadata(artifactId: number): Observable<HttpResponse<Map<ArtifactMetadataFile, boolean>>> {
        return this._http.get<Map<ArtifactMetadataFile, boolean>>(`${this.resourceUrl}/${artifactId}/metadata`, {
            observe: 'response',
        });
    }

    updateArtifactValidationStatus(
        artifactId: number,
        artifactValidationStatus: ArtifactValidationStatus,
    ): Observable<EntityResponseType> {
        return this._http
            .put<Artifact>(`${this.resourceUrl}/${artifactId}/${artifactValidationStatus}`, null, {
                observe: 'response',
            })
            .pipe(map((res: EntityResponseType) => this.convertResponse(res)));
    }

    getArtifactPassword(artifactId: number): Observable<HttpResponse<ArtifactPasswordResponse>> {
        return this._http.get<ArtifactPasswordResponse>(`${this.resourceUrl}/${artifactId}/password`, {
            observe: 'response',
        });
    }

    addQuestionToQuestionnaire(questionnaireId: number, question: string): Observable<void> {
        return this._http.post<void>(`${this.resourceUrl}/questionnaire/${questionnaireId}/question`, question);
    }

    updateQuestionnaireQuestion(questionnaireId: number, questionId: number, question: string): Observable<void> {
        return this._http.put<void>(
            `${this.resourceUrl}/questionnaire/${questionnaireId}/question/${questionId}`,
            question,
        );
    }

    deleteQuestionnaireQuestion(questionnaireId: number, questionId: number): Observable<void> {
        return this._http.delete<void>(`${this.resourceUrl}/questionnaire/${questionnaireId}/question/${questionId}`);
    }

    updateStaticValidation(artifactId: number, staticValidationType: StaticValidationType): Observable<void> {
        return this._http.post<void>(
            `${this.resourceUrl}/${artifactId}/static-validation/${staticValidationType}`,
            null,
        );
    }

    private convertResponse(res: EntityResponseType): EntityResponseType {
        const body: Artifact = this.convertItemFromServer(res.body);
        return res.clone({ body });
    }

    private convertIntelligenceResponse(res: HttpResponse<ArtifactIntelligence>): HttpResponse<ArtifactIntelligence> {
        const body: ArtifactIntelligence = this.convertIntelligenceFromServer(res.body);
        return res.clone({ body });
    }

    private convertArrayResponse(res: HttpResponse<Artifact[]>): HttpResponse<Artifact[]> {
        const jsonResponse: Artifact[] = res.body;
        const body: Artifact[] = [];
        for (let i = 0; i < jsonResponse.length; i++) {
            body.push(this.convertItemFromServer(jsonResponse[i]));
        }
        return res.clone({ body });
    }

    /**
     * Convert a returned JSON object to Artifact.
     */
    convertItemFromServer(artifact: Artifact): Artifact {
        let copy: Artifact = Object.assign({}, artifact);
        if (copy.type === ArtifactType.FILE_ARTIFACT) {
            copy = this.convertFileArtifact(copy);
        } else if (copy.type === ArtifactType.QUESTIONNAIRE_ARTIFACT) {
            copy = this.convertQuestionnaireArtifact(copy);
        } else if (copy.type === ArtifactType.URL_ARTIFACT) {
            copy = this.convertUrlArtifact(copy);
        }

        copy.createdDate = this._dateUtilsService.convertDateTimeFromServer(artifact.createdDate);
        copy.updatedDate = this._dateUtilsService.convertDateTimeFromServer(artifact.updatedDate);
        if (copy.validation) {
            copy.validation.createdDate =
                copy.validation.createdDate &&
                this._dateUtilsService.convertDateTimeFromServer(copy.validation.createdDate);
            copy.validation.expirationDate =
                copy.validation.expirationDate &&
                this._dateUtilsService.convertLocalDateFromServer(copy.validation.expirationDate);
            copy.validation.publishedDate =
                copy.validation.publishedDate &&
                this._dateUtilsService.convertLocalDateFromServer(copy.validation.publishedDate);
            copy.validation.expiringSoon = isArtifactExpiringSoon(copy);
            if (copy.validation.expired) {
                copy.validation.auditReportAssurance = defaultAssuranceLevel;
                copy.validation.auditReportAssuranceLevel = AssuranceLevels.LIMITED;
            }
        }

        return copy;
    }

    convertFileArtifact(fileArtifact: FileArtifact): Artifact {
        let copy: FileArtifact = Object.assign({}, fileArtifact);
        copy.uploadedDate = this._dateUtilsService.convertDateTimeFromServer(fileArtifact.uploadedDate);
        const validatedMlAuditType = fileArtifact.validation
            ? fileArtifact.validation?.auditReportType
            : AuditReportTypeCode.UNVALIDATED;
        copy.artifactTypeName =
            copy.artifactType === FileArtifactType.SUPPLEMENTAL
                ? 'Supplemental Compliance File'
                : this._auditReportService.getAuditTypeFriendlyName(validatedMlAuditType);
        return copy;
    }

    convertQuestionnaireArtifact(questionnaireArtifact: QuestionnaireArtifact): Artifact {
        let copy: QuestionnaireArtifact = Object.assign({}, questionnaireArtifact);
        copy.artifactTypeName = 'Questionnaire';
        if (!copy.fileName) {
            copy.fileName = `Questionnaire Artifact ${this._dateUtilsService.formatDate(
                copy.createdDate,
                'yyyy-MM-dd hh:mm a',
            )}`;
        }

        return copy;
    }

    private convertUrlArtifact(urlArtifact: UrlArtifact): Artifact {
        let copy: UrlArtifact = Object.assign({}, urlArtifact);
        copy.uploadedDate = this._dateUtilsService.convertDateTimeFromServer(urlArtifact.uploadedDate);
        copy.artifactTypeName =
            copy.artifactType === FileArtifactType.SUPPLEMENTAL
                ? 'Supplemental Compliance File'
                : this._auditReportService.getAuditTypeFriendlyName(
                      urlArtifact.validation?.auditReportType || AuditReportTypeCode.NONE,
                  );
        return copy;
    }

    private convertIntelligenceFromServer(artifactIntelligence: ArtifactIntelligence): ArtifactIntelligence {
        return { ...artifactIntelligence };
    }
}
