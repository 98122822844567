import { Injectable } from '@angular/core';
import { Risk } from '../../entities/risk-assessment';

interface BootstrapColors {
    primary: string;
    success: string;
    info: string;
    warning: string;
    danger: string;
}

interface RiskColors {
    noContext: string;
    low: string;
    medium: string;
    high: string;
    extreme: string;
}

interface GraphColors {
    gray: string;
    blue: string;
    dark: string;
    skyBlue: string;
    skyBlueLight: string;
    skyBlueLighter: string;
    blueLight: string;
}

interface BusinessCaseColors {
    dataTransfer: string;
    networkConnection: string;
    dataStorage: string;
    physicalAccess: string;
    personnelDataAccess: string;
    softwareHardwareSupplier: string;
}

enum BusinessCases {
    'Data Transfer' = 'Data Transfer',
    'Network Connection' = 'Network Connection',
    'Data Storage' = 'Data Storage',
    'Physical Access' = 'Physical Access',
    'Personnel Data Access' = 'Personnel Data Access',
    'Software/Hardware Supplier' = 'Software/Hardware Supplier',
}

enum BusinessCasesCssVariable {
    'Data Transfer' = '--business-case-dataTransfer',
    'Network Connection' = '--business-case-networkConnection',
    'Data Storage' = '--business-case-dataStorage',
    'Physical Access' = '--business-case-physicalAccess',
    'Personnel Data Access' = '--business-case-personnelDataAccess',
    'Software/Hardware Supplier' = '--business-case-softwareHardwareSupplier',
}

@Injectable({
    providedIn: 'root',
})
export class ColorUtilsService {
    bootstrapColors: BootstrapColors = {
        primary: '',
        success: '',
        info: '',
        warning: '',
        danger: '',
    };

    riskColors: RiskColors = {
        noContext: '',
        low: '',
        medium: '',
        high: '',
        extreme: '',
    };

    graphColors: GraphColors = {
        gray: '',
        blue: '',
        dark: '',
        skyBlue: '',
        skyBlueLight: '',
        skyBlueLighter: '',
        blueLight: '',
    };

    businessCaseColors: BusinessCaseColors = {
        dataTransfer: '',
        networkConnection: '',
        dataStorage: '',
        physicalAccess: '',
        personnelDataAccess: '',
        softwareHardwareSupplier: '',
    };

    constructor() {
        this.setColors();
    }

    getRiskColor(risk: Risk | string): string {
        switch (risk) {
            case Risk.EXTREME:
                return this.riskColors.extreme;
            case Risk.HIGH:
                return this.riskColors.high;
            case Risk.MEDIUM:
                return this.riskColors.medium;
            case Risk.LOW:
                return this.riskColors.low;
            case Risk.NO_CONTEXT:
            default:
                return this.riskColors.noContext;
        }
    }

    getBusinessCaseColors(businessCases: string[]): string[] {
        return businessCases
            .map<keyof BusinessCaseColors>((businessCase) => {
                switch (businessCase) {
                    case BusinessCases['Data Transfer']:
                        return 'dataTransfer';
                    case BusinessCases['Data Storage']:
                        return 'dataStorage';
                    case BusinessCases['Network Connection']:
                        return 'networkConnection';
                    case BusinessCases['Physical Access']:
                        return 'physicalAccess';
                    case BusinessCases['Personnel Data Access']:
                        return 'personnelDataAccess';
                    case BusinessCases['Software/Hardware Supplier']:
                        return 'softwareHardwareSupplier';
                }
            })
            .map((x) => this.businessCaseColors[x]);
    }

    private setColors(): void {
        this.bootstrapColors = Object.keys(this.bootstrapColors).reduce<BootstrapColors>((colorsObj, colorVariable) => {
            colorsObj[colorVariable] = this.getVariableColorValue(`--bootstrap-${colorVariable}`);
            return colorsObj;
        }, {} as BootstrapColors);

        this.riskColors = Object.keys(this.riskColors).reduce<RiskColors>((colorsObj, colorVariable) => {
            colorsObj[colorVariable] = this.getVariableColorValue(`--risk-${colorVariable}`);
            return colorsObj;
        }, {} as RiskColors);

        this.businessCaseColors = Object.keys(this.businessCaseColors).reduce<BusinessCaseColors>(
            (colorsObj, colorVariable: keyof BusinessCaseColors) => {
                colorsObj[colorVariable] = this.getVariableColorValue(this.mapBusinessCaseCssVariable(colorVariable));
                return colorsObj;
            },
            {} as BusinessCaseColors,
        );

        this.graphColors = Object.keys(this.graphColors).reduce<GraphColors>((colorsObj, colorVariable) => {
            colorsObj[colorVariable] = this.getVariableColorValue(`--graph-${colorVariable}`);
            return colorsObj;
        }, {} as GraphColors);
    }

    private mapBusinessCaseCssVariable(businessCase: keyof BusinessCaseColors): BusinessCasesCssVariable {
        let businessCaseEnumValue;
        switch (businessCase) {
            case 'dataTransfer':
                businessCaseEnumValue = BusinessCases['Data Transfer'];
                break;
            case 'networkConnection':
                businessCaseEnumValue = BusinessCases['Network Connection'];
                break;
            case 'dataStorage':
                businessCaseEnumValue = BusinessCases['Data Storage'];
                break;
            case 'physicalAccess':
                businessCaseEnumValue = BusinessCases['Physical Access'];
                break;
            case 'personnelDataAccess':
                businessCaseEnumValue = BusinessCases['Personnel Data Access'];
                break;
            case 'softwareHardwareSupplier':
                businessCaseEnumValue = BusinessCases['Software/Hardware Supplier'];
                break;
        }
        return BusinessCasesCssVariable[businessCaseEnumValue];
    }

    private getVariableColorValue(colorVariable: string): string {
        return getComputedStyle(document.documentElement).getPropertyValue(colorVariable).trim();
    }
}
