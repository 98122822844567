import { Route } from '@angular/router';

import { DemoToolsComponent } from './demo-tools.component';

export const demoToolsRoute: Route = {
    path: 'demo-tools',
    component: DemoToolsComponent,
    data: {
        pageTitle: 'Demo Tools',
    },
};
