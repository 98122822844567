import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { fadeAnimation2x } from '@shared/animation/basic';

@Component({
    selector: 'app-popover',
    templateUrl: './popover.component.html',
    styleUrls: ['./popover.component.scss'],
    animations: [fadeAnimation2x],
})
export class PopoverComponent {
    @Input() content: string;
    @Input() template: TemplateRef<any>;
    @Input() context: any;
    @Input() arrowVisible: boolean = true;
    @Input() arrowPosition: 'top' | 'right' | 'bottom' | 'left' = 'right';
    @Input() theme: 'light' | 'dark' = 'light';
    @Input() maxWidth: number;
    @Input() maxHeight: number;
    @Input() customContainerClass: string;

    @Output() mouseenter = new EventEmitter<void>();
    @Output() mouseleave = new EventEmitter<void>();

    get maxWidthStyle(): string {
        return !!this.maxWidth ? `${this.maxWidth}px` : 'auto';
    }

    get maxHeightStyle(): string {
        return !!this.maxHeight ? `${this.maxHeight}px` : 'auto';
    }

    get arrowClass() {
        if (!this.arrowVisible) {
            return {};
        }
        return {
            arrow: true,
            'arrow-left': this.arrowPosition === 'left',
            'arrow-right': this.arrowPosition === 'right',
            'arrow-top': this.arrowPosition === 'top',
            'arrow-bottom': this.arrowPosition === 'bottom',
        };
    }

    get containerClasses() {
        return {
            dark: this.theme === 'dark',
            light: this.theme === 'light',
            'offset-top': this.arrowPosition === 'top',
            'offset-right': this.arrowPosition === 'right',
            'offset-bottom': this.arrowPosition === 'bottom',
            'offset-left': this.arrowPosition === 'left',
            [this.customContainerClass]: !!this.customContainerClass,
        };
    }

    onMouseEnter() {
        this.mouseenter.emit();
    }

    onMouseLeave() {
        this.mouseleave.emit();
    }
}
