export type FrameworkMapping = Record<number, string>;

export enum FrameworkMappingType {
    AICPA_TSC_2017 = 'AICPA_TSC_2017',
    BSI_200_1 = 'BSI_200_1',
    EMEA_EU_GDPR = 'EMEA_EU_GDPR',
    ISO_27001_2013 = 'ISO_27001_2013',
    ISO_27001_2022 = 'ISO_27001_2022',
    ISO_27018_2014 = 'ISO_27018_2014',
    ISO_27701_2019 = 'ISO_27701_2019',
    NIST_800_171_REV2 = 'NIST_800_171_REV2',
    NIST_800_53_REV4 = 'NIST_800_53_REV4',
    NIST_800_53_REV5 = 'NIST_800_53_REV5',
    NIST_CSF_V2 = 'NIST_CSF_V2',
    NIST_FFEIC = 'NIST_FFIEC',
    NIST_PRIVACY_1_0 = 'NIST_PRIVACY_1_0',
    US_CA_CPRA_Nov_2022 = 'US_CA_CPRA_Nov_2022',
    US_GLBA_CFR_314_DEC_2023 = 'US_GLBA_CFR_314_DEC_2023',
    US_HIPAA = 'US_HIPAA',
    FEDRAMP_R5 = 'FEDRAMP_R5',
    NY_DFS_23 = 'NY_DFS_23',
}
export enum FrameworkMappingTypeLabels {
    AICPA_TSC_2017 = 'AICPA TSC 2017 (with 2022 revised POF)',
    BSI_200_1 = 'BSI Standard 200-1',
    EMEA_EU_GDPR = 'EMEA EU GDPR',
    ISO_27001_2013 = 'ISO 27001 v2013',
    ISO_27001_2022 = 'ISO 27001 v2022',
    ISO_27018_2014 = 'ISO 27018 v2014',
    ISO_27701_2019 = 'ISO 27701 v2019',
    NIST_800_171_REV2 = 'NIST 800-171 rev2',
    NIST_800_53_REV4 = 'NIST 800-53 rev4',
    NIST_800_53_REV5 = 'NIST 800-53 rev5',
    NIST_CSF_V2 = 'NIST CSF v2.0',
    NIST_FFEIC = 'NIST FFIEC',
    NIST_PRIVACY_1_0 = 'NIST Privacy Framework 1.0',
    US_CA_CPRA_Nov_2022 = 'US-CA CPRA (Nov 2022)',
    US_GLBA_CFR_314_DEC_2023 = 'US GLBA CFR 314 (Dec 2023)',
    US_HIPAA = 'US HIPAA',
    FEDRAMP_R5 = 'US FEDRAMP R5',
    NY_DFS_23 = 'NY DFS 23 NYCRR500',
}
