<div class="table-responsive">
    <table class="table table-striped table-hover">
        <thead>
            <tr>
                <th style="width: 15%">Name</th>
                <th style="width: 50%">Description</th>
                <th style="width: 15%">Business Case</th>
                <th style="width: 20%">Impact</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let control of controlDomains">
                <td>{{ control.name }}</td>
                <td>{{ control.longDescription }}</td>
                <td>
                    <ul>
                        <span *ngFor="let contextType of control.contextTypes | orderBy: 'name'">
                            <li>{{ contextType.name }}</li>
                        </span>
                    </ul>
                </td>
                <td>
                    <ngx-slider
                        class="viso-slider"
                        [options]="sliderOptions"
                        [(value)]="control.influence"
                    ></ngx-slider>
                </td>
            </tr>
        </tbody>
    </table>
</div>
