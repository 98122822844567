export class Webhook {
    id: number;
    orgId: number;
    orgName?: string;
    name: WebhookType;
    webhook: string;
    description: string;
    domainEventTypes: string[];

    constructor() {
        this.id = 0;
        this.orgId = null;
        this.name = null;
        this.webhook = '';
        this.description = '';
        this.domainEventTypes = [];
    }
}

export interface WebhookEvent {
    displayName: string;
    value: string[];
    selected: boolean;
}

export enum WebhookType {
    GENERIC = 'GENERIC',
    SLACK = 'SLACK',
    DISCORD = 'DISCORD',
    TEAMS = 'TEAMS',
}
