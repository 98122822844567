import { createAction, props } from '@ngrx/store';
import { DataType } from '../../../entities/data-type';

export enum DataTypesActions {
    GetDataTypesQueryRequest = '[Data Types] Get DataTypes Query Request',
    GetDataTypesQueryRequestSuccess = '[Data Types] Get DataTypes Query Request Success',
    GetDataTypesQueryRequestFailed = '[Data Types] Get DataTypes Query Request Failed',
}

/**
 * Data Types Query Request Redux Action
 * Gets dispatched when a Data Types query request is triggered.
 */
export const getDataTypesQueryRequest = createAction(
    DataTypesActions.GetDataTypesQueryRequest,
    props<{ enabled: boolean }>(),
);

/**
 * Data Types Query Success Redux Action
 * Gets dispatched when a `DataTypesQueryRequest` is successful.
 */
export const getDataTypesQueryRequestSuccess = createAction(
    DataTypesActions.GetDataTypesQueryRequestSuccess,
    props<{ results: DataType[] }>(),
);

/**
 * Data Types Query Fail Redux Action
 * Gets dispatched when a `ContextTypesQueryRequest` fails.
 */
export const getDataTypesQueryRequestFailed = createAction(DataTypesActions.GetDataTypesQueryRequestFailed);
