import {
    RTPArtifactAvScanned,
    RTPArtifactClassified,
    RTPArtifactErrored,
    RTPArtifactEvent,
    RTPArtifactPagesCompleted,
    RTPArtifactPublishDateSet,
    RTPEvent,
    RTPEventType,
    RTPPageDetectionsClassified,
    RTPPageEvent,
} from './rtp-events.model';

export function isRTPArtifactAvScanned(event: RTPEvent): event is RTPArtifactAvScanned {
    return event.eventType === RTPEventType.RTP_ARTIFACT_AV_SCANNED;
}

export function isRTPArtifactClassified(event: RTPEvent): event is RTPArtifactClassified {
    return event.eventType === RTPEventType.RTP_ARTIFACT_CLASSIFIED;
}

export function isRTPArtifactPublishDateSet(event: RTPEvent): event is RTPArtifactPublishDateSet {
    return event.eventType === RTPEventType.RTP_PUBLISH_DATE_SET;
}

export function isRTPPageDetectionsClassified(event: RTPEvent): event is RTPPageDetectionsClassified {
    return event.eventType === RTPEventType.RTP_PAGE_DETECTIONS_CLASSIFIED;
}

export function isRTPArtifactPagesCompleted(event: RTPEvent): event is RTPArtifactPagesCompleted {
    return event.eventType === RTPEventType.RTP_ARTIFACT_PAGES_COMPLETED;
}

export function isRTPArtifactEvent(event: RTPEvent): event is RTPArtifactEvent {
    const artifactKey: keyof RTPArtifactEvent = 'artifact';
    return artifactKey in event;
}

export function isRtpArtifactErrored(event: RTPEvent): event is RTPArtifactErrored {
    return event.eventType === RTPEventType.RTP_ARTIFACT_ERRORED;
}

export function isRTPPageEvent(event: RTPEvent): event is RTPPageEvent {
    const keys: (keyof RTPPageEvent)[] = [
        'rtpStatus',
        'totalPages',
        'validatedControlDomainIds',
        'securityControlDomain',
    ];
    return keys.every((key) => key in event);
}
