import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
    selector: 'img[appImageFallback]',
})
export class ImageFallbackDirective {
    @Input()
    appImageFallback: string;

    @Input()
    onImageErrorCssClass?: string;

    constructor(private _elemRef: ElementRef<HTMLImageElement>) {}

    @HostListener('error')
    handleImageFallback(): void {
        const element = this._elemRef.nativeElement;
        this.setFallbackImageSource(element);
        if (!!this.onImageErrorCssClass) {
            this.setOnImageErrorCssClass(element);
        }
    }

    private setFallbackImageSource(element: HTMLImageElement) {
        element.src = this.appImageFallback;
    }

    private setOnImageErrorCssClass(element: HTMLImageElement) {
        element.classList.add(this.onImageErrorCssClass);
    }
}
