import { Injectable } from '@angular/core';
import { AssessmentStatus, AssessmentType } from '../../entities/assessment';
import { OrgAssessmentSummarySections } from '../../admin/client-profile/client-profile.model';

export function getAssessmentSummaryForClientConfiguration(
    summary: string,
    clientAssessmentSummarySectionConfig: OrgAssessmentSummarySections[],
): string {
    // Extract section id and content manually from HTML string
    const sectionsRegex = /<section\s+id="([^"]+)">(.*?)<\/section>/gs;
    let match;
    let finalSummary: string = '';
    while ((match = sectionsRegex.exec(summary)) !== null) {
        const id: string = match[1];
        const content: string = match[0];
        const matchingClientConfig = clientAssessmentSummarySectionConfig.find(
            (config) => config.section.toString() === id,
        );
        if (!!matchingClientConfig && matchingClientConfig.visible) {
            finalSummary = finalSummary + content;
        }
    }
    return finalSummary || summary;
}

export function isAssessmentNotAssessed(assessmentStatus: AssessmentStatus): boolean {
    return assessmentStatus === AssessmentStatus.NOT_ASSESSED;
}

export function isAssessmentInProgress(assessmentStatus: AssessmentStatus): boolean {
    return (
        assessmentStatus === AssessmentStatus.STARTED ||
        assessmentStatus === AssessmentStatus.REVIEW_STARTED ||
        assessmentStatus === AssessmentStatus.COLLECTING_INFORMATION
    );
}

export function isAssessmentStarted(assessmentStatus: AssessmentStatus): boolean {
    return assessmentStatus === AssessmentStatus.STARTED;
}

export function isAssessmentCollectingInformation(assessmentStatus: AssessmentStatus): boolean {
    return assessmentStatus === AssessmentStatus.COLLECTING_INFORMATION;
}

export function isAssessmentReviewStarted(assessmentStatus: AssessmentStatus): boolean {
    return assessmentStatus === AssessmentStatus.REVIEW_STARTED;
}

export function isAssessmentCancelledOrDeleted(status: AssessmentStatus): boolean {
    return status === AssessmentStatus.CANCELLED || status === AssessmentStatus.DELETED;
}

export function isAssessmentComplete(status: AssessmentStatus): boolean {
    return status === AssessmentStatus.COMPLETED;
}

export function isAssessmentStartedOrCollectingInformation(assessmentStatus: AssessmentStatus): boolean {
    return (
        assessmentStatus === AssessmentStatus.STARTED || assessmentStatus === AssessmentStatus.COLLECTING_INFORMATION
    );
}

export function isAssessmentFinished(status: AssessmentStatus): boolean {
    return isAssessmentCancelledOrDeleted(status) || isAssessmentComplete(status);
}

export function isAssessmentAwaitingRemediation(status: AssessmentStatus): boolean {
    return status === AssessmentStatus.AWAITING_REMEDIATION;
}

export function isRemediationAssessment(assessmentType: AssessmentType): boolean {
    return assessmentType === AssessmentType.REMEDIATION;
}

@Injectable({
    providedIn: 'root',
})
export class AssessmentUtilsService {
    isAssessmentNotAssessed = isAssessmentNotAssessed;

    isAssessmentInProgress = isAssessmentInProgress;

    isAssessmentStarted = isAssessmentStarted;

    isAssessmentCollectingInformation = isAssessmentCollectingInformation;

    isAssessmentReviewStarted = isAssessmentReviewStarted;

    isAssessmentCancelled = isAssessmentCancelledOrDeleted;

    isAssessmentComplete = isAssessmentComplete;

    isAssessmentStartedOrCollectingInformation = isAssessmentStartedOrCollectingInformation;

    isAssessmentFinished = isAssessmentFinished;

    getAssessmentSummaryForClientConfiguration = getAssessmentSummaryForClientConfiguration;

    isRemediationAssessment = isRemediationAssessment;
}
