import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { Sort, SortDirection } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { RiskAdvisory, RiskAdvisoryTypeLabels } from '../../../entities/risk-advisory';
import { MatDialogWrapperService } from '@shared/modal/mat-dialog-wrapper.service';
import { RiskAdvisoryDetailsComponent } from '../../../routes/risk-advisory/risk-advisory-details/risk-advisory-details.component';
import { VendorRiskAdvisoryDialogComponent } from '@shared/vendor-components/vendor-risk-advisory-dialog/vendor-risk-advisory-dialog.component';

@Component({
    selector: 'app-vendor-risk-advisory-table',
    templateUrl: './vendor-risk-advisory-table.component.html',
    styleUrls: ['./vendor-risk-advisory-table.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VendorRiskAdvisoryTableComponent {
    @Input()
    set data(riskAdvisories: RiskAdvisory[]) {
        this.dataSource.data = riskAdvisories;
        this.loadingRiskAdvisories = false;
    }

    @Input()
    totalRiskAdvisories: number;

    @Input()
    canEdit: boolean;

    @Output() sortChange: EventEmitter<{ column: string; direction: SortDirection }> = new EventEmitter();

    @Output() pageChange: EventEmitter<{ page: number; pageSize: number }> = new EventEmitter();

    @Output() deleteRiskAdvisory: EventEmitter<{ riskAdvisory: RiskAdvisory }> = new EventEmitter();

    RiskAdvisoryTypeLabels = RiskAdvisoryTypeLabels;
    dataSource = new MatTableDataSource<RiskAdvisory>();
    displayedColumns = ['title', 'date', 'type', 'description'];
    itemsPerPage = 10;
    currentPage = 0;
    loadingRiskAdvisories = false;

    constructor(private _dialogService: MatDialogWrapperService) {}

    trackByRiskAdvisoryId(index: number, riskAdvisory: RiskAdvisory) {
        return riskAdvisory.id;
    }

    setCurrentPage(pageEvent: PageEvent) {
        this.currentPage = +pageEvent.pageIndex;
        this.itemsPerPage = pageEvent.pageSize;
        this.loadingRiskAdvisories = true;
        this.pageChange.emit({ page: this.currentPage, pageSize: this.itemsPerPage });
    }

    onDeleteRiskAdvisory(riskAdvisory: RiskAdvisory) {
        this.deleteRiskAdvisory.emit({ riskAdvisory: riskAdvisory });
    }

    openDetailsDialog(riskAdvisory: RiskAdvisory): void {
        this._dialogService.open(RiskAdvisoryDetailsComponent, {
            config: {
                panelClass: 'custom-dialog',
                data: riskAdvisory,
                autoFocus: false,
            },
        });
    }

    openAdvisoryDialogEdit(riskAdvisory: RiskAdvisory): void {
        this._dialogService.open(VendorRiskAdvisoryDialogComponent, {
            inputs: {
                riskAdvisory,
                name: riskAdvisory.orgName,
                isEditing: true,
            },
            config: {
                panelClass: 'custom-dialog',
                data: riskAdvisory,
                autoFocus: false,
            },
        });
    }

    onSortData(sortEvent: Sort) {
        this.loadingRiskAdvisories = true;
        this.sortChange.emit({
            column: sortEvent.active,
            direction: sortEvent.direction,
        });
    }
}
