import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { InteractiveIqrResponse } from '../../rdp-ai-question-answering/rdp-ai-question-answering.model';
import { LastInteractiveIqrResponsesByRelationshipState } from '../request.state';

export enum AiQuestionAnsweringActions {
    LoadLocalStorageIiqrResponsesState = '[Request] Load Local Storage Iiqr Responses State',
    AskQuestionRequest = '[Request] Ask Question Request',
    AskQuestionRequestSuccess = '[Request] Ask Question Request Success',
    AskQuestionRequestFailed = '[Request] Ask Question Request Failed',
}

export const loadLocalStorageIiqrResponsesState = createAction(
    AiQuestionAnsweringActions.LoadLocalStorageIiqrResponsesState,
    props<{ localStorageState: LastInteractiveIqrResponsesByRelationshipState }>(),
);

export const askQuestionRequest = createAction(
    AiQuestionAnsweringActions.AskQuestionRequest,
    props<{ relationshipId: number; question: string }>(),
);

export const askQuestionRequestSuccess = createAction(
    AiQuestionAnsweringActions.AskQuestionRequestSuccess,
    props<{ relationshipId: number; iqrResponse: InteractiveIqrResponse }>(),
);

export const askQuestionRequestFailed = createAction(
    AiQuestionAnsweringActions.AskQuestionRequestFailed,
    props<{ relationshipId: number; error: HttpErrorResponse }>(),
);
