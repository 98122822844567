import { HttpHeaders } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { BusinessUnit, BusinessUnitManagementView } from '../../../entities/business-unit';
import { PaginationParams } from '@shared/model/pagination-params';

export enum BusinessUnitActions {
    GetBusinessUnitsRequest = '[Business Unit] Get Business Units Request',
    GetBusinessUnitsRequestSuccess = '[Business Unit] Get Business Units Request Success',
    GetBusinessUnitsRequestFailed = '[Business Unit] Get Business Units Request Failed',
    ReassignBusinessUnitRequest = '[Business Unit] Reassign Business Unit Relationships Request',
    ReassignBusinessUnitRequestSuccess = '[Business Unit] Reassign Business Unit Relationships Request Success',
    ReassignBusinessUnitRequestFailed = '[Business Unit] Reassign Business Unit Relationships Request Failed',
    CreateBusinessUnitRequest = '[Business Unit] Create Business Unit Request',
    CreateBusinessUnitRequestSuccess = '[Business Unit] Create Business Unit Request Success',
    CreateBusinessUnitRequestFailed = '[Business Unit] Create Business Unit Request Failed',
    UpdateBusinessUnitRequest = '[Business Unit] Update Business Unit Request',
    UpdateBusinessUnitRequestSuccess = '[Business Unit] Update Business Unit Request Success',
    UpdateBusinessUnitRequestFailed = '[Business Unit] Update Business Unit Request Failed',
    DeleteBusinessUnitRequest = '[Business Unit] Delete Business Unit Request',
    DeleteBusinessUnitRequestSuccess = '[Business Unit] Delete Business Unit Request Success',
    DeleteBusinessUnitRequestFailed = '[Business Unit] Delete Business Unit Request Failed',
    OpenDeleteBusinessUnitConfirmationModal = '[Business Unit] Open Delete Business Unit Confirmation Modal',

    GetBusinessUnitsForManagementRequest = '[Business Unit] Get Business Units For Management Request',
    GetBusinessUnitsForManagementRequestSuccess = '[Business Unit] Get Business Units For Management Request Success',
    GetBusinessUnitsForManagementRequestFailed = '[Business Unit] Get Business Units For Management Request Failed',
}

export const createBusinessUnitRequest = createAction(
    BusinessUnitActions.CreateBusinessUnitRequest,
    props<{ businessUnit: BusinessUnit }>(),
);

export const createBusinessUnitRequestSuccess = createAction(
    BusinessUnitActions.CreateBusinessUnitRequestSuccess,
    props<{ businessUnit: BusinessUnit; headers: HttpHeaders }>(),
);

export const createBusinessUnitRequestFailed = createAction(BusinessUnitActions.CreateBusinessUnitRequestFailed);

export const updateBusinessUnitRequest = createAction(
    BusinessUnitActions.UpdateBusinessUnitRequest,
    props<{ businessUnit: BusinessUnit }>(),
);

export const updateBusinessUnitRequestSuccess = createAction(
    BusinessUnitActions.UpdateBusinessUnitRequestSuccess,
    props<{ businessUnit: BusinessUnit }>(),
);

export const updateBusinessUnitRequestFailed = createAction(BusinessUnitActions.UpdateBusinessUnitRequestFailed);

export const deleteBusinessUnitRequest = createAction(
    BusinessUnitActions.DeleteBusinessUnitRequest,
    props<{ businessUnitId: number }>(),
);

export const deleteBusinessUnitRequestSuccess = createAction(BusinessUnitActions.DeleteBusinessUnitRequestSuccess);

export const deleteBusinessUnitRequestFailed = createAction(BusinessUnitActions.DeleteBusinessUnitRequestFailed);

export const getBusinessUnitsRequest = createAction(
    BusinessUnitActions.GetBusinessUnitsRequest,
    props<{ req?: PaginationParams; clientId?: number }>(),
);

export const getBusinessUnitsRequestSuccess = createAction(
    BusinessUnitActions.GetBusinessUnitsRequestSuccess,
    props<{ businessUnits: BusinessUnit[]; headers: HttpHeaders }>(),
);

export const getBusinessUnitsRequestFailed = createAction(BusinessUnitActions.GetBusinessUnitsRequestFailed);

export const reassignBusinessUnitRequest = createAction(
    BusinessUnitActions.ReassignBusinessUnitRequest,
    props<{ fromBusinessUnitId: number; toBusinessUnitId: number }>(),
);

export const reassignBusinessUnitRequestSuccess = createAction(BusinessUnitActions.ReassignBusinessUnitRequestSuccess);

export const reassignBusinessUnitRequestFailed = createAction(BusinessUnitActions.ReassignBusinessUnitRequestFailed);

export const openDeleteBusinessUnitConfirmationModal = createAction(
    BusinessUnitActions.OpenDeleteBusinessUnitConfirmationModal,
    props<{ businessUnit: BusinessUnit }>(),
);

export const getBusinessUnitsForManagementRequest = createAction(
    BusinessUnitActions.GetBusinessUnitsForManagementRequest,
    props<{ req?: any }>(),
);

export const getBusinessUnitsForManagementRequestSuccess = createAction(
    BusinessUnitActions.GetBusinessUnitsForManagementRequestSuccess,
    props<{ businessUnits: BusinessUnitManagementView[]; headers: HttpHeaders }>(),
);

export const getBusinessUnitsForManagementRequestFailed = createAction(
    BusinessUnitActions.GetBusinessUnitsForManagementRequestFailed,
);
