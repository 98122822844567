<div *dynamicContent="breadcrumbsContainerToken">
    <ol class="breadcrumb breadcrumb px-0 pb-0">
        <li class="breadcrumb-item"><a [routerLink]="['/']">Home</a></li>
        <li class="breadcrumb-item active">Business Units</li>
    </ol>
</div>

<div class="content-heading">
    <div>Business Units</div>
</div>
<div class="card card-border">
    <div class="card card-flat">
        <div class="card-body">
            <span
                >You can associate third party relationships with your organizations business units in order to report
                on risk.</span
            >
        </div>
    </div>
</div>
<div class="row">
    <div class="col-xl-12">
        <div class="card card-border d-flex">
            <div class="card-header px-4 py-3">
                <a class="text-bold">{{ numberOfBusinessUnitsHeader$ | async }}</a>
                <button
                    *jhiHasAnyAuthority="[Roles.OrgAdmin, Roles.OrgUser]"
                    mat-button
                    visoButton="primary"
                    class="float-end"
                    [routerLink]="['/', { outlets: { popup: ['business-unit-new'] } }]"
                >
                    Add Business Unit
                </button>
            </div>

            <ng-container
                *ngIf="{
                    businessUnits: businessUnits$ | async,
                } as ctx"
            >
                <ng-container *ngIf="ctx.businessUnits && ctx.businessUnits.length">
                    <div class="row table-container px-4">
                        <div class="col">
                            <ngx-datatable
                                #businessUnitList
                                class="bootstrap"
                                [rows]="ctx.businessUnits"
                                [columnMode]="'force'"
                                [footerHeight]="40"
                                [rowHeight]="35"
                                [headerHeight]="30"
                                [scrollbarV]="true"
                                [externalSorting]="true"
                                (sort)="onSort($event)"
                                [sorts]="tableSort$ | async"
                                [externalPaging]="true"
                                [virtualization]="false"
                                [count]="totalItems"
                                [offset]="page"
                                [limit]="itemsPerPage"
                                [observeDims]="true"
                                data-pw="businessUnitListTable"
                            >
                                <ngx-datatable-column name="Name" prop="name">
                                    <ng-template let-row="row" ngx-datatable-cell-template>
                                        {{ row.name }}
                                    </ng-template>
                                </ngx-datatable-column>
                                <ngx-datatable-column name="Users" prop="userCount"></ngx-datatable-column>
                                <ngx-datatable-column name="Relationships" prop="relationshipCount">
                                    <ng-template let-row="row" ngx-datatable-cell-template>
                                        <a
                                            [routerLink]="['/relationships']"
                                            [queryParams]="{
                                                search:
                                                    'drStatus:[NOT_ONBOARDED|ONBOARDED|ARCHIVED],businessUnitId:' +
                                                    row.id,
                                            }"
                                        >
                                            {{ row.relationshipCount }}
                                        </a>
                                    </ng-template>
                                </ngx-datatable-column>

                                <ngx-datatable-column
                                    [width]="40"
                                    cellClass="order-50 text-center bg-white"
                                    headerClass="order-50"
                                    [resizable]="false"
                                    [sortable]="false"
                                    [canAutoResize]="false"
                                    [draggable]="false"
                                    [frozenRight]="false"
                                    *jhiHasAnyAuthority="[Roles.OrgAdmin, Roles.OrgUser]"
                                >
                                    <ng-template let-row="row" ngx-datatable-cell-template>
                                        <div
                                            class="additional-actions"
                                            placement="bottom left"
                                            dropdown
                                            container="body"
                                        >
                                            <button
                                                id="button-container"
                                                dropdownToggle
                                                type="button"
                                                class="btn-icon float-end"
                                                aria-controls="dropdown-container"
                                            >
                                                <em class="fa fa-ellipsis-v"></em>
                                            </button>
                                            <ul
                                                id="dropdown-container"
                                                *dropdownMenu
                                                class="dropdown-menu dropdown-menu-end"
                                                aria-labelledby="button-container"
                                            >
                                                <li role="menuitem">
                                                    <a
                                                        class="dropdown-item"
                                                        [routerLink]="[
                                                            '/',
                                                            { outlets: { popup: 'business-unit/' + row.id + '/edit' } },
                                                        ]"
                                                    >
                                                        Edit
                                                    </a>
                                                </li>
                                                <li role="menuitem">
                                                    <a
                                                        class="dropdown-item text-danger"
                                                        (click)="deleteBusinessUnit(row)"
                                                    >
                                                        Delete
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </ng-template>
                                </ngx-datatable-column>
                                <ngx-datatable-footer>
                                    <ng-template
                                        ngx-datatable-footer-template
                                        let-page="page"
                                        let-rowCount="rowCount"
                                        let-pageSize="pageSize"
                                        let-curPage="curPage"
                                    >
                                        <datatable-pager
                                            [pagerLeftArrowIcon]="'datatable-icon-left'"
                                            [pagerRightArrowIcon]="'datatable-icon-right'"
                                            [pagerPreviousIcon]="'datatable-icon-prev'"
                                            [pagerNextIcon]="'datatable-icon-skip'"
                                            [page]="curPage"
                                            [size]="pageSize"
                                            [count]="rowCount"
                                            [hidden]="!(rowCount / pageSize > 1)"
                                            (change)="setCurrentPage($event)"
                                        ></datatable-pager>
                                    </ng-template>
                                </ngx-datatable-footer>
                            </ngx-datatable>
                        </div>
                    </div>
                </ng-container>
            </ng-container>
        </div>
    </div>
</div>
