import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Relationship } from '@entities/relationship';
import { Artifact } from '@entities/artifact';
import { SecurityControlDomain } from '@entities/relationship/models/security-control-domain';
import { Email } from '@entities/email';
import { AssessmentRecommendation } from '@entities/recommendation';
import { UploadArtifactsRequest } from '@shared/upload-artifacts/models';
import {
    Assessment,
    AssessmentDetailsView,
    AuditorCancelReason,
    CollectingInformationReason,
} from '@entities/assessment';
import { PrimaryVendorContact } from '../../../primary-vendor-contact';
import { ClientProfile } from '../../../../admin/client-profile/client-profile.model';

export enum VendorAssessmentDetailsActions {
    GetAssessmentDetailsRequest = '[Vendor Assessment Details] Get Assessment Details Request',
    GetAssessmentDetailsRequestSuccess = '[Vendor Assessment Details] Get Assessment Details Request Success',
    GetAssessmentDetailsRequestFailed = '[Vendor Assessment Details] Get Assessment Details Request Failed',

    GetAssessmentEmailsRequest = '[Vendor Assessment Details] Get Assessment Emails Request',
    GetAssessmentEmailsRequestSuccess = '[Vendor Assessment Details] Get Assessment Emails Request Success',
    GetAssessmentEmailsRequestFailed = '[Vendor Assessment Details] Get Assessment Emails Request Failed',

    GetAssessmentRelationshipRequest = '[Vendor Assessment Details] Get Assessment Relationship Request',
    GetAssessmentRelationshipRequestSuccess = '[Vendor Assessment Details] Get Assessment Relationship Request Success',
    GetAssessmentRelationshipRequestFailed = '[Vendor Assessment Details] Get Assessment Relationship Request Failed',

    GetRelationshipArtifactsRequest = '[Vendor Assessment Details] Get Relationship Artifacts Request',
    GetRelationshipArtifactsRequestSuccess = '[Vendor Assessment Details] Get Relationship Artifacts Request Success',
    GetRelationshipArtifactsRequestFailed = '[Vendor Assessment Details] Get Relationship Artifacts Request Failed',

    GetRelationshipSecurityControlDomainRequest = '[Vendor Assessment Details] Get Relationship Security Control Domain Request',
    GetRelationshipSecurityControlDomainRequestSuccess = '[Vendor Assessment Details] Get Relationship Security Control Domain Request Success',
    GetRelationshipSecurityControlDomainRequestFailed = '[Vendor Assessment Details] Get Relationship Security Control Domain Request Failed',

    GetRelationshipArtifactSupersessionRequest = '[Vendor Assessment Details] Get Relationship Artifact Supersession Request',
    GetRelationshipArtifactSupersessionRequestSuccess = '[Vendor Assessment Details] Get Relationship Artifact Supersession Request Success',
    GetRelationshipArtifactSupersessionRequestFailed = '[Vendor Assessment Details] Get Relationship Artifact Supersession Request Failed',

    GetAssessmentPreSummaryRequest = '[Vendor Assessment Details] Get Assessment Pre Summary Request',
    GetAssessmentPreSummaryRequestSuccess = '[Vendor Assessment Details] Get Assessment Pre Summary Request Success',
    GetAssessmentPreSummaryRequestFailed = '[Vendor Assessment Details] Get Assessment Pre Summary Request Failed',

    GetAssessmentRecommendationsRequest = '[Vendor Assessment Details] Get Assessment Recommendations Request',
    GetAssessmentRecommendationsRequestSuccess = '[Vendor Assessment Details] Get Assessment Recommendations Request Success',
    GetAssessmentRecommendationsRequestFailed = '[Vendor Assessment Details] Get Assessment Recommendations Request Failed',

    GetRelationshipPrimaryContactRequest = '[Vendor Assessment Details] Get Relationship Primary Contact Request',
    GetRelationshipPrimaryContactRequestSuccess = '[Vendor Assessment Details] Get Relationship Primary Contact Request Success',
    GetRelationshipPrimaryContactRequestFailed = '[Vendor Assessment Details] Get Relationship Primary Contact Request Failed',

    GetRiskOuputRequest = '[Vendor Assessment Details] Get Risk Output Request',
    GetRiskOuputRequestFailed = '[Vendor Assessment Details] Get Risk Output Request Failed',

    MarkAssessmentAsReviewStarted = '[Vendor Assessment Details] Mark Assessment As Review Started',
    MarkAssessmentAsReviewStartedCancel = '[Vendor Assessment Details] Mark Assessment As Review Started Cancel',
    MarkAssessmentAsReviewStartedRequest = '[Vendor Assessment Details] Mark Assessment As Review Started Request',
    MarkAssessmentAsReviewStartedRequestSuccess = '[Vendor Assessment Details] Mark Assessment As Review Started Request Success',
    MarkAssessmentAsReviewStartedRequestFailed = '[Vendor Assessment Details] Mark Assessment As Review Started Request Failed',

    MarkAssessmentAsCancelled = '[Vendor Assessment Details] Mark Assessment As Cancelled',
    MarkAssessmentAsCancelledCancel = '[Vendor Assessment Details] Mark Assessment As Cancelled Cancel',
    MarkAssessmentAsCancelledRequest = '[Vendor Assessment Details] Mark Assessment As Cancelled Request',
    MarkAssessmentAsCancelledRequestSuccess = '[Vendor Assessment Details] Mark Assessment As Cancelled Request Success',
    MarkAssessmentAsCancelledRequestFailed = '[Vendor Assessment Details] Mark Assessment As Cancelled Request Failed',

    MarkAssessmentAsCollectingInformationRequest = '[Vendor Assessment Details] Mark Assessment As Collecting Information Request',
    MarkAssessmentAsCollectingInformationRequestSuccess = '[Vendor Assessment Details] Mark Assessment As Collecting Information Request Success',
    MarkAssessmentAsCollectingInformationRequestFailed = '[Vendor Assessment Details] Mark Assessment As Collecting Information Request Failed',

    CancelAssessment = '[Vendor Assessment Details] Cancel Assessment',
    CancelAssessmentCancel = '[Vendor Assessment Details] Cancel Assessment Cancel',
    CancelAssessmentRequest = '[Vendor Assessment Details] Cancel Assessment Request',
    CancelAssessmentRequestSuccess = '[Vendor Assessment Details] Cancel Assessment Request Success',
    CancelAssessmentRequestFailed = '[Vendor Assessment Details] Cancel Assessment Request Failed',

    MarkAssessmentAsComplete = '[Vendor Assessment Details] Mark Assessment As Complete',
    MarkAssessmentAsCompleteCancel = '[Vendor Assessment Details] Mark Assessment As Complete Cancel',
    MarkAssessmentAsCompleteRequest = '[Vendor Assessment Details] Mark Assessment As Complete Request',
    MarkAssessmentAsCompleteRequestSuccess = '[Vendor Assessment Details] Mark Assessment As Complete Request Success',
    MarkAssessmentAsCompleteRequestFailed = '[Vendor Assessment Details] Mark Assessment As Complete Request Failed',

    UndoAssessmentCancelledRequest = '[Vendor Assessment Details] Undo Assessment Cancelled Request',
    UndoAssessmentCancelledRequestSuccess = '[Vendor Assessment Details] Undo Assessment Cancelled Request Success',
    UndoAssessmentCancelledRequestFailed = '[Vendor Assessment Details] Undo Assessment Cancelled Request Failed',

    SendAssessmentReminderEmail = '[Vendor Assessment Details] Send Assessment Reminder Email',
    SendAssessmentReminderEmailCancel = '[Vendor Assessment Details] Send Assessment Reminder Email Cancel',
    SendAssessmentReminderEmailRequest = '[Vendor Assessment Details] Send Assessment Reminder Email Request',
    SendAssessmentReminderEmailRequestSuccess = '[Vendor Assessment Details] Send Assessment Reminder Email Request Success',
    SendAssessmentReminderEmailRequestFailed = '[Vendor Assessment Details] Send Assessment Reminder Email Request Failed',

    UploadAssessmentArtifactRequest = '[Vendor Assessment Details] Upload Assessment Artifact Request',
    UploadAssessmentArtifactRequestSuccess = '[Vendor Assessment Details] Upload Assessment Artifact Request Success',
    UploadAssessmentArtifactRequestFailed = '[Vendor Assessment Details] Upload Assessment Artifact Request Failed',

    DeleteVendorArtifact = '[Vendor Assessment Details] Delete Vendor Artifact',
    DeleteAssessmentArtifactRequest = '[Vendor Assessment Details] Delete Assessment Artifact Request',
    DeleteAssessmentArtifactRequestSuccess = '[Vendor Assessment Details] Delete Assessment Artifact Request Success',
    DeleteAssessmentArtifactRequestFailed = '[Vendor Assessment Details] Delete Assessment Artifact Request Failed',

    DeleteRelationshipArtifactRequest = '[Vendor Assessment Details] Delete Relationship Artifact Request',
    DeleteRelationshipArtifactRequestSuccess = '[Vendor Assessment Details] Delete Relationship Artifact Request Success',
    DeleteRelationshipArtifactRequestFailed = '[Vendor Assessment Details] Delete Relationship Artifact Request Failed',

    UpdateRelationshipArtifactSupersessionRequest = '[Vendor Assessment Details] Update Relationship Artifact Supersession Request',
    UpdateRelationshipArtifactSupersessionRequestSuccess = '[Vendor Assessment Details] Update Relationship Artifact Supersession Request Success',
    UpdateRelationshipArtifactSupersessionRequestFailed = '[Vendor Assessment Details] Update Relationship Artifact Supersession Request Failed',

    DownloadAssessmentArtifactsRequest = '[Vendor Assessment Details] Download Assessment Artifacts Request',
    DownloadAssessmentArtifactsRequestSuccess = '[Vendor Assessment Details] Download Assessment Artifacts Request Success',
    DownloadAssessmentArtifactsRequestFailed = '[Vendor Assessment Details] Download Assessment Artifacts Request Failed',

    CreateFollowUpQuestionnaire = '[Vendor Assessment Details] Create Follow-Up Questionnaire',
    CreateFollowUpQuestionnaireCancel = '[Vendor Assessment Details] Create Follow-Up Questionnaire Cancel',
    CreateFollowUpQuestionnaireRequest = '[Vendor Assessment Details] Create Follow-Up Questionnaire Request',
    CreateFollowUpQuestionnaireRequestSuccess = '[Vendor Assessment Details] Create Follow-Up Questionnaire Request Success',
    CreateFollowUpQuestionnaireRequestFailed = '[Vendor Assessment Details] Create Follow-Up Questionnaire Request Failed',

    GetClientProfileRequest = '[Vendor Assessment Details] Get Client Profile Request',
    GetClientProfileRequestSuccess = '[Vendor Assessment Details] Get Client Profile Request Success',
    GetClientProfileRequestFailed = '[Vendor Assessment Details] Get Client Profile Request Failed',

    DeleteAssessment = '[Vendor Assessment Details] Delete Assessment',
    DeleteAssessmentCancel = '[Vendor Assessment Details] Delete Assessment Cancel',
    DeleteAssessmentRequest = '[Vendor Assessment Details] Delete Assessment Request',
    DeleteAssessmentRequestSuccess = '[Vendor Assessment Details] Delete Assessment Request Success',
    DeleteAssessmentRequestFailed = '[Vendor Assessment Details] Delete Assessment Request Failed',
}

export const getClientProfileRequest = createAction(
    VendorAssessmentDetailsActions.GetClientProfileRequest,
    props<{ clientId: number }>(),
);

export const getClientProfileRequestSuccess = createAction(
    VendorAssessmentDetailsActions.GetClientProfileRequestSuccess,
    props<{ clientProfile: ClientProfile }>(),
);

export const getClientProfileRequestFailed = createAction(
    VendorAssessmentDetailsActions.GetClientProfileRequestFailed,
    props<{ error: HttpErrorResponse }>(),
);

export const getAssessmentDetailsRequest = createAction(
    VendorAssessmentDetailsActions.GetAssessmentDetailsRequest,
    props<{ assessmentId: number }>(),
);

export const getAssessmentDetailsRequestSuccess = createAction(
    VendorAssessmentDetailsActions.GetAssessmentDetailsRequestSuccess,
    props<{ assessmentDetails: AssessmentDetailsView }>(),
);

export const getAssessmentDetailsRequestFailed = createAction(
    VendorAssessmentDetailsActions.GetAssessmentDetailsRequestFailed,
    props<{ error: HttpErrorResponse }>(),
);

export const getAssessmentEmailsRequest = createAction(
    VendorAssessmentDetailsActions.GetAssessmentEmailsRequest,
    props<{ assessmentId: number }>(),
);

export const getAssessmentEmailsRequestSuccess = createAction(
    VendorAssessmentDetailsActions.GetAssessmentEmailsRequestSuccess,
    props<{ emails: Email[] }>(),
);

export const getAssessmentEmailsRequestFailed = createAction(
    VendorAssessmentDetailsActions.GetAssessmentEmailsRequestFailed,
    props<{ error: HttpErrorResponse }>(),
);

export const getAssessmentRelationshipRequest = createAction(
    VendorAssessmentDetailsActions.GetAssessmentRelationshipRequest,
    props<{ relationshipId: number }>(),
);

export const getAssessmentRelationshipRequestSuccess = createAction(
    VendorAssessmentDetailsActions.GetAssessmentRelationshipRequestSuccess,
    props<{ relationship: Relationship }>(),
);

export const getAssessmentRelationshipRequestFailed = createAction(
    VendorAssessmentDetailsActions.GetAssessmentRelationshipRequestFailed,
    props<{ error: HttpErrorResponse }>(),
);

export const getRelationshipArtifactsRequest = createAction(
    VendorAssessmentDetailsActions.GetRelationshipArtifactsRequest,
    props<{ relationshipId: number }>(),
);

export const getRelationshipArtifactsRequestSuccess = createAction(
    VendorAssessmentDetailsActions.GetRelationshipArtifactsRequestSuccess,
    props<{ artifacts: Artifact[] }>(),
);

export const getRelationshipArtifactsRequestFailed = createAction(
    VendorAssessmentDetailsActions.GetRelationshipArtifactsRequestFailed,
    props<{ error: HttpErrorResponse }>(),
);

export const getRelationshipSecurityControlDomainRequest = createAction(
    VendorAssessmentDetailsActions.GetRelationshipSecurityControlDomainRequest,
    props<{ relationshipId: number }>(),
);

export const getRelationshipSecurityControlDomainRequestSuccess = createAction(
    VendorAssessmentDetailsActions.GetRelationshipSecurityControlDomainRequestSuccess,
    props<{ securityControlDomain: SecurityControlDomain }>(),
);

export const getRelationshipSecurityControlDomainRequestFailed = createAction(
    VendorAssessmentDetailsActions.GetRelationshipSecurityControlDomainRequestFailed,
    props<{ error: HttpErrorResponse }>(),
);

export const getRelationshipArtifactSupersessionRequest = createAction(
    VendorAssessmentDetailsActions.GetRelationshipArtifactSupersessionRequest,
    props<{ relationshipId: number }>(),
);

export const getRelationshipArtifactSupersessionRequestSuccess = createAction(
    VendorAssessmentDetailsActions.GetRelationshipArtifactSupersessionRequestSuccess,
    props<{ artifactSupersession: Map<number, number> }>(),
);

export const getRelationshipArtifactSupersessionRequestFailed = createAction(
    VendorAssessmentDetailsActions.GetRelationshipArtifactSupersessionRequestFailed,
    props<{ error: HttpErrorResponse }>(),
);

export const getAssessmentPreSummaryRequest = createAction(
    VendorAssessmentDetailsActions.GetAssessmentPreSummaryRequest,
    props<{ assessmentId: number }>(),
);

export const getAssessmentPreSummaryRequestSuccess = createAction(
    VendorAssessmentDetailsActions.GetAssessmentPreSummaryRequestSuccess,
    props<{ preSummary: string }>(),
);

export const getAssessmentPreSummaryRequestFailed = createAction(
    VendorAssessmentDetailsActions.GetAssessmentPreSummaryRequestFailed,
    props<{ error: HttpErrorResponse }>(),
);

export const getAssessmentRecommendationsRequest = createAction(
    VendorAssessmentDetailsActions.GetAssessmentRecommendationsRequest,
    props<{ assessmentId: number }>(),
);

export const getAssessmentRecommendationsRequestSuccess = createAction(
    VendorAssessmentDetailsActions.GetAssessmentRecommendationsRequestSuccess,
    props<{ recommendations: AssessmentRecommendation[] }>(),
);

export const getAssessmentRecommendationsRequestFailed = createAction(
    VendorAssessmentDetailsActions.GetAssessmentRecommendationsRequestFailed,
    props<{ error: HttpErrorResponse }>(),
);

export const getRelationshipPrimaryContactRequest = createAction(
    VendorAssessmentDetailsActions.GetRelationshipPrimaryContactRequest,
    props<{ relationshipId: number }>(),
);

export const getRelationshipPrimaryContactRequestSuccess = createAction(
    VendorAssessmentDetailsActions.GetRelationshipPrimaryContactRequestSuccess,
    props<{ primaryContact: PrimaryVendorContact }>(),
);

export const getRelationshipPrimaryContactRequestFailed = createAction(
    VendorAssessmentDetailsActions.GetRelationshipPrimaryContactRequestFailed,
    props<{ error: HttpErrorResponse }>(),
);

export const markAssessmentAsReviewStarted = createAction(
    VendorAssessmentDetailsActions.MarkAssessmentAsReviewStarted,
    props<{ assessmentId: number }>(),
);

export const markAssessmentAsReviewStartedCancel = createAction(
    VendorAssessmentDetailsActions.MarkAssessmentAsReviewStartedCancel,
);

export const markAssessmentAsReviewStartedRequest = createAction(
    VendorAssessmentDetailsActions.MarkAssessmentAsReviewStartedRequest,
    props<{ assessmentId: number }>(),
);

export const markAssessmentAsReviewStartedRequestSuccess = createAction(
    VendorAssessmentDetailsActions.MarkAssessmentAsReviewStartedRequestSuccess,
);

export const markAssessmentAsReviewStartedRequestFailed = createAction(
    VendorAssessmentDetailsActions.MarkAssessmentAsReviewStartedRequestFailed,
    props<{ error: HttpErrorResponse }>(),
);

export const markAssessmentAsCollectingInformationRequest = createAction(
    VendorAssessmentDetailsActions.MarkAssessmentAsCollectingInformationRequest,
    props<{
        assessmentId: number;
        reason: CollectingInformationReason;
        relationshipId: number;
    }>(),
);

export const markAssessmentAsCollectingInformationRequestSuccess = createAction(
    VendorAssessmentDetailsActions.MarkAssessmentAsCollectingInformationRequestSuccess,
);

export const markAssessmentAsCollectingInformationRequestFailed = createAction(
    VendorAssessmentDetailsActions.MarkAssessmentAsCollectingInformationRequestFailed,
    props<{ error: HttpErrorResponse }>(),
);

export const markAssessmentAsCancelled = createAction(
    VendorAssessmentDetailsActions.MarkAssessmentAsCancelled,
    props<{ assessmentId: number; reason: AuditorCancelReason; relationshipId: number }>(),
);

export const markAssessmentAsCancelledCancel = createAction(
    VendorAssessmentDetailsActions.MarkAssessmentAsCancelledCancel,
);

export const markAssessmentAsCancelledRequest = createAction(
    VendorAssessmentDetailsActions.MarkAssessmentAsCancelledRequest,
    props<{ assessmentId: number; reason: AuditorCancelReason; relationshipId: number; commentMessage: string }>(),
);

export const markAssessmentAsCancelledRequestSuccess = createAction(
    VendorAssessmentDetailsActions.MarkAssessmentAsCancelledRequestSuccess,
);

export const markAssessmentAsCancelledRequestFailed = createAction(
    VendorAssessmentDetailsActions.MarkAssessmentAsCancelledRequestFailed,
    props<{ error: HttpErrorResponse }>(),
);

export const cancelAssessment = createAction(
    VendorAssessmentDetailsActions.CancelAssessment,
    props<{ assessmentId: number }>(),
);

export const cancelAssessmentCancel = createAction(VendorAssessmentDetailsActions.CancelAssessmentCancel);

export const cancelAssessmentRequest = createAction(
    VendorAssessmentDetailsActions.CancelAssessmentRequest,
    props<{ assessmentId: number }>(),
);

export const cancelAssessmentRequestSuccess = createAction(
    VendorAssessmentDetailsActions.CancelAssessmentRequestSuccess,
);

export const cancelAssessmentRequestFailed = createAction(
    VendorAssessmentDetailsActions.CancelAssessmentRequestFailed,
    props<{ error: HttpErrorResponse }>(),
);

export const markAssessmentAsComplete = createAction(
    VendorAssessmentDetailsActions.MarkAssessmentAsComplete,
    props<{ assessmentId: number; relationshipId: number }>(),
);

export const markAssessmentAsCompleteCancel = createAction(
    VendorAssessmentDetailsActions.MarkAssessmentAsCompleteCancel,
);

export const markAssessmentAsCompleteRequest = createAction(
    VendorAssessmentDetailsActions.MarkAssessmentAsCompleteRequest,
    props<{ assessmentId: number; summary: string }>(),
);

export const markAssessmentAsCompleteRequestSuccess = createAction(
    VendorAssessmentDetailsActions.MarkAssessmentAsCompleteRequestSuccess,
);

export const markAssessmentAsCompleteRequestFailed = createAction(
    VendorAssessmentDetailsActions.MarkAssessmentAsCompleteRequestFailed,
    props<{ error: HttpErrorResponse }>(),
);

export const undoAssessmentCancelledRequest = createAction(
    VendorAssessmentDetailsActions.UndoAssessmentCancelledRequest,
    props<{ assessmentId: number }>(),
);

export const undoAssessmentCancelledRequestSuccess = createAction(
    VendorAssessmentDetailsActions.UndoAssessmentCancelledRequestSuccess,
    props<{ assessment: Assessment }>(),
);

export const undoAssessmentCancelledRequestFailed = createAction(
    VendorAssessmentDetailsActions.UndoAssessmentCancelledRequestFailed,
    props<{ error: HttpErrorResponse }>(),
);

export const getRiskOutputRequest = createAction(
    VendorAssessmentDetailsActions.GetRiskOuputRequest,
    props<{ assessmentId: number }>(),
);

export const getRiskOutputRequestFailed = createAction(
    VendorAssessmentDetailsActions.GetRiskOuputRequestFailed,
    props<{ error: HttpErrorResponse }>(),
);

export const sendAssessmentReminderEmail = createAction(
    VendorAssessmentDetailsActions.SendAssessmentReminderEmail,
    props<{ assessmentId: number; emails: Email[]; sentToEmail: string }>(),
);

export const sendAssessmentReminderEmailCancel = createAction(
    VendorAssessmentDetailsActions.SendAssessmentReminderEmailCancel,
);

export const sendAssessmentReminderEmailRequest = createAction(
    VendorAssessmentDetailsActions.SendAssessmentReminderEmailRequest,
    props<{ assessmentId: number; sentToEmail: string }>(),
);

export const sendAssessmentReminderEmailRequestSuccess = createAction(
    VendorAssessmentDetailsActions.SendAssessmentReminderEmailRequestSuccess,
    props<{ sentToEmail: string }>(),
);

export const sendAssessmentReminderEmailRequestFailed = createAction(
    VendorAssessmentDetailsActions.SendAssessmentReminderEmailRequestFailed,
    props<{ error: HttpErrorResponse }>(),
);

export const uploadAssessmentArtifactRequest = createAction(
    VendorAssessmentDetailsActions.UploadAssessmentArtifactRequest,
    props<{ assessmentId: number } & UploadArtifactsRequest>(),
);

export const uploadAssessmentArtifactRequestSuccess = createAction(
    VendorAssessmentDetailsActions.UploadAssessmentArtifactRequestSuccess,
    props<{ duplicateArtifactFileNames: string[] }>(),
);

export const uploadAssessmentArtifactRequestFailed = createAction(
    VendorAssessmentDetailsActions.UploadAssessmentArtifactRequestFailed,
    props<{ error: HttpErrorResponse }>(),
);

export const deleteVendorArtifact = createAction(
    VendorAssessmentDetailsActions.DeleteVendorArtifact,
    props<{ assessmentId: number; artifactId: number; relationshipId: number }>(),
);

export const deleteAssessmentArtifactRequest = createAction(
    VendorAssessmentDetailsActions.DeleteAssessmentArtifactRequest,
    props<{ assessmentId: number; artifactId: number }>(),
);

export const deleteAssessmentArtifactRequestSuccess = createAction(
    VendorAssessmentDetailsActions.DeleteAssessmentArtifactRequestSuccess,
);

export const deleteAssessmentArtifactRequestFailed = createAction(
    VendorAssessmentDetailsActions.DeleteAssessmentArtifactRequestFailed,
    props<{ error: HttpErrorResponse }>(),
);

export const deleteRelationshipArtifactRequest = createAction(
    VendorAssessmentDetailsActions.DeleteRelationshipArtifactRequest,
    props<{ relationshipId: number; artifactId: number }>(),
);

export const deleteRelationshipArtifactRequestSuccess = createAction(
    VendorAssessmentDetailsActions.DeleteRelationshipArtifactRequestSuccess,
);

export const deleteRelationshipArtifactRequestFailed = createAction(
    VendorAssessmentDetailsActions.DeleteRelationshipArtifactRequestFailed,
    props<{ error: HttpErrorResponse }>(),
);

export const updateRelationshipArtifactSupersessionRequest = createAction(
    VendorAssessmentDetailsActions.UpdateRelationshipArtifactSupersessionRequest,
    props<{ relationshipId: number; artifactId: number; artifactIdToSupersede: number }>(),
);

export const updateRelationshipArtifactSupersessionRequestSuccess = createAction(
    VendorAssessmentDetailsActions.UpdateRelationshipArtifactSupersessionRequestSuccess,
);

export const updateRelationshipArtifactSupersessionRequestFailed = createAction(
    VendorAssessmentDetailsActions.UpdateRelationshipArtifactSupersessionRequestFailed,
    props<{ error: HttpErrorResponse }>(),
);

export const downloadAssessmentArtifactsRequest = createAction(
    VendorAssessmentDetailsActions.DownloadAssessmentArtifactsRequest,
    props<{ assessmentId: number; artifactIds: number[] }>(),
);

export const downloadAssessmentArtifactsRequestSuccess = createAction(
    VendorAssessmentDetailsActions.DownloadAssessmentArtifactsRequestSuccess,
    props<{ fileResponseBlob: HttpResponse<Blob> }>(),
);

export const downloadAssessmentArtifactsRequestFailed = createAction(
    VendorAssessmentDetailsActions.DownloadAssessmentArtifactsRequestFailed,
    props<{ error: HttpErrorResponse }>(),
);

export const createFollowUpQuestionnaire = createAction(
    VendorAssessmentDetailsActions.CreateFollowUpQuestionnaire,
    props<{ assessmentId: number }>(),
);

export const createFollowUpQuestionnaireCancel = createAction(
    VendorAssessmentDetailsActions.CreateFollowUpQuestionnaireCancel,
);

export const createFollowUpQuestionnaireRequest = createAction(
    VendorAssessmentDetailsActions.CreateFollowUpQuestionnaireRequest,
    props<{ assessmentId: number }>(),
);

export const createFollowUpQuestionnaireRequestSuccess = createAction(
    VendorAssessmentDetailsActions.CreateFollowUpQuestionnaireRequestSuccess,
);

export const createFollowUpQuestionnaireRequestFailed = createAction(
    VendorAssessmentDetailsActions.CreateFollowUpQuestionnaireRequestFailed,
    props<{ error: HttpErrorResponse }>(),
);

export const deleteAssessment = createAction(VendorAssessmentDetailsActions.DeleteAssessment);

export const deleteAssessmentCancel = createAction(VendorAssessmentDetailsActions.DeleteAssessmentCancel);

export const deleteAssessmentRequest = createAction(
    VendorAssessmentDetailsActions.DeleteAssessmentRequest,
    props<{ assessmentId: number }>(),
);

export const deleteAssessmentRequestSuccess = createAction(
    VendorAssessmentDetailsActions.DeleteAssessmentRequestSuccess,
);

export const deleteAssessmentRequestFailed = createAction(
    VendorAssessmentDetailsActions.DeleteAssessmentRequestFailed,
    props<{ error: HttpErrorResponse }>(),
);
