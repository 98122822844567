import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { tap, takeUntil } from 'rxjs/operators';
import { loginRequest } from '../../session/redux/session.actions';
import {
    activateAccountRequest,
    activateAccountRequestFailed,
    activateAccountRequestSuccess,
} from '../redux/user-account.actions';
import { ErrorKey } from '../../../shared/model/error-keys';

@Component({
    selector: 'app-user-activation',
    templateUrl: './user-activation.component.html',
    styleUrls: ['./user-activation.component.scss'],
})
export class UserActivationComponent implements OnInit {
    accountActivated: boolean;
    accountActivationFailed: boolean;
    accountActivationExpired: boolean;
    activationInProgress: boolean;

    ErrorKey = ErrorKey;

    private _unsub$ = new Subject<void>();

    constructor(
        private route: ActivatedRoute,
        private _store$: Store,
        private _actions$: Actions,
    ) {}

    ngOnInit(): void {
        this.route.queryParams.pipe(takeUntil(this._unsub$)).subscribe((params) => {
            const paramName = 'activationToken';
            const activationToken = params[paramName];
            this.activateAccount(activationToken);
        });

        this._actions$
            .pipe(
                ofType(activateAccountRequestSuccess),
                tap(() => {
                    this.resetFlags();
                    this.accountActivated = true;
                }),
                takeUntil(this._unsub$),
            )
            .subscribe();

        this._actions$
            .pipe(
                ofType(activateAccountRequestFailed),
                tap((response) => {
                    if (response.error.error.errorKey === ErrorKey.TOKEN_EXPIRED) {
                        this.resetFlags();
                        this.accountActivationExpired = true;
                    } else {
                        this.resetFlags();
                        this.accountActivationFailed = true;
                    }
                }),
                takeUntil(this._unsub$),
            )
            .subscribe();
    }

    activateAccount(activationToken: string) {
        this.activationInProgress = true;
        this._store$.dispatch(activateAccountRequest({ activationToken }));
    }

    resetFlags() {
        this.accountActivated = false;
        this.accountActivationFailed = false;
        this.accountActivationExpired = false;
        this.activationInProgress = false;
    }

    login() {
        this._store$.dispatch(loginRequest());
    }
}
