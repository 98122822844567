import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { RouterNavigationAction, ROUTER_NAVIGATED } from '@ngrx/router-store';
import { select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import {
    catchError,
    distinctUntilChanged,
    exhaustMap,
    filter,
    map,
    switchMap,
    tap,
    withLatestFrom,
} from 'rxjs/operators';
import { TagService } from '../../../entities/tag';
import { NgbModalWrapperService } from '../../../shared';
import { TagFormComponent } from '../tag-form/tag-form.component';
import {
    createTagRequest,
    createTagRequestFailed,
    createTagRequestSuccess,
    deleteTagRequest,
    deleteTagRequestFailed,
    deleteTagRequestSuccess,
    getTagByIdRequestFailed,
    getTagByIdRequestSuccess,
    getTagsRequest,
    getTagsRequestFailed,
    getTagsRequestSuccess,
    selectTagById,
    updateTagRequest,
    updateTagRequestFailed,
    updateTagRequestSuccess,
} from './tag.actions';
import { getTagPaginationSelector } from './tag.selectors';

@Injectable()
export class TagEffects {
    getTags$ = createEffect(() =>
        this._actions$.pipe(
            ofType(getTagsRequest),
            switchMap(({ pagination }) =>
                this._tagService.getAll(pagination).pipe(
                    map(({ items: tags, xTotalCount: total }) => getTagsRequestSuccess({ tags, total })),
                    catchError(() => of(getTagsRequestFailed())),
                ),
            ),
        ),
    );

    getTagById$ = createEffect(() =>
        this._actions$.pipe(
            ofType(selectTagById),
            switchMap(({ id }) =>
                this._tagService.getById(id).pipe(
                    map((tag) => getTagByIdRequestSuccess({ tag })),
                    catchError(() => of(getTagByIdRequestFailed())),
                ),
            ),
        ),
    );

    createTag$ = createEffect(() =>
        this._actions$.pipe(
            ofType(createTagRequest),
            exhaustMap(({ tagPayload, selectTag }) =>
                this._tagService.save(tagPayload).pipe(
                    map((newTag) => createTagRequestSuccess({ newTag, ...(selectTag ? { selectTag: true } : {}) })),
                    catchError(() => of(createTagRequestFailed())),
                ),
            ),
        ),
    );

    updateTag$ = createEffect(() =>
        this._actions$.pipe(
            ofType(updateTagRequest),
            exhaustMap(({ tagPayload }) =>
                this._tagService.update(tagPayload).pipe(
                    map((updatedTag) => updateTagRequestSuccess({ updatedTag })),
                    catchError(() => of(updateTagRequestFailed())),
                ),
            ),
        ),
    );

    deleteTag$ = createEffect(() =>
        this._actions$.pipe(
            ofType(deleteTagRequest),
            exhaustMap(({ id }) =>
                this._tagService.delete(id).pipe(
                    map(() => deleteTagRequestSuccess()),
                    catchError(() => of(deleteTagRequestFailed())),
                ),
            ),
        ),
    );

    refreshTags$ = createEffect(() =>
        this._actions$.pipe(
            ofType(createTagRequestSuccess, updateTagRequestSuccess, deleteTagRequestSuccess),
            withLatestFrom(this._store$.pipe(select(getTagPaginationSelector))),
            switchMap(([_, pagination]) => of(getTagsRequest({ pagination }))),
        ),
    );

    openEditTagModal$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(ROUTER_NAVIGATED),
                map((action: RouterNavigationAction) =>
                    action.payload.routerState.root.children.find((route) => route.outlet === 'popup'),
                ),
                distinctUntilChanged((prev, next) => prev?.routeConfig.path === next?.routeConfig.path),
                filter<ActivatedRouteSnapshot>((route) => !!route && route.routeConfig.path === 'tag/:id/edit'),
                tap((route) =>
                    this._modalService.open<TagFormComponent>(
                        TagFormComponent,
                        { selectedTag: route.data?.tag },
                        { size: 'md' },
                    ),
                ),
            ),
        { dispatch: false },
    );

    openCreateTagModal$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(ROUTER_NAVIGATED),
                map((action: RouterNavigationAction) =>
                    action.payload.routerState.root.children.find((route) => route.outlet === 'popup'),
                ),
                distinctUntilChanged((prev, next) => prev?.routeConfig.path === next?.routeConfig.path),
                filter((route) => !!route && route.routeConfig.path === 'tag-new'),
                tap(() => {
                    this._modalService.open<TagFormComponent>(TagFormComponent, {}, { size: 'md' });
                }),
            ),
        { dispatch: false },
    );

    constructor(
        private _actions$: Actions,
        private _store$: Store,
        private _tagService: TagService,
        private _modalService: NgbModalWrapperService,
    ) {}
}
