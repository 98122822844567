<form [formGroup]="dataTypesFormGroup" data-pw="dataTypesContainer">
    <ng-container *ngFor="let sensitivity of DataSensitivityOrder">
        <div class="sensitivity-group" *ngIf="dataTypesBySensitivity[sensitivity]?.length > 0">
            <p class="divider-text text-muted">{{ sensitivity | callback: getDataSensitivityLevelLabel }}</p>
            <ng-container *ngIf="dataTypesBySensitivity[sensitivity]?.length > 0" formArrayName="items">
                <ng-container
                    *ngFor="let data of dataTypesBySensitivity[sensitivity]; let i = index"
                    [formGroupName]="data.index"
                >
                    <div
                        class="checkbox-group-container"
                        placement="right"
                        containerClass="popover"
                        [popover]="dataTypePopover"
                        [popoverContext]="data"
                        [outsideClick]="true"
                        [isOpen]="showPopoverFor && showPopoverFor === data.dataType.name"
                        (mouseenter)="showPopover(data.dataType.name)"
                        (mouseleave)="hidePopover()"
                        triggers="manual"
                    >
                        <mat-checkbox
                            color="primary"
                            class="col-direction right-checkbox outline-checkbox d-block"
                            formControlName="checked"
                            [labelPosition]="'before'"
                        >
                            <div class="label-black">{{ data.dataType.name }}</div>
                            <div *ngIf="data.dataType.custom" class="mt-2 mb-1">
                                <span [tagColor]="TagColor.COLOR_01">Custom</span>
                            </div>
                        </mat-checkbox>
                    </div>
                    <ng-template #dataTypePopover let-dataType="dataType">
                        <div (mouseenter)="showPopover(dataType.name)" (mouseleave)="hidePopover()">
                            <div class="popover-detail">
                                <p class="text-bold">Examples include:</p>
                                <ul *ngIf="dataType.exampleText">
                                    <li *ngFor="let example of dataType.exampleText | callback: getExampleList">
                                        {{ example }}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </ng-template>
                </ng-container>
            </ng-container>
        </div>
    </ng-container>
</form>
