import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PopoverComponent } from './popover/popover.component';
import { PopoverDirective } from './popover-handler/popover-handler.directive';

@NgModule({
    declarations: [PopoverComponent, PopoverDirective],
    imports: [CommonModule],
    exports: [PopoverComponent, PopoverDirective],
})
export class VisoPopoverModule {}
