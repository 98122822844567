import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { FileDropDirective, FileItem, FileUploader } from '../../../shared/file-upload';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, startWith, switchMap, throttleTime } from 'rxjs/operators';
import {
    Announcement,
    AnnouncementTypes,
    AnnouncementTypesLabels,
    CreateAnnouncement,
} from '../../../entities/announcement/announcement.model';
import { FormUtilsService } from '../../../shared/utils/form-utils.service';
import { AdminAnnouncementService } from '../admin-announcement.service';
import { EnumSelectOption } from '../../../shared/model/select-options';

interface AddAnnouncementFormGroup {
    announcementType: AnnouncementTypes;
    title: string;
    message: string;
    externalLinkLabel?: string;
    externalLinkUrl?: string;
}

@Component({
    selector: 'app-announcements-management',
    templateUrl: './announcements-management.component.html',
    styleUrls: ['./announcements-management.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnnouncementsManagementComponent implements OnInit {
    announcements$: Observable<Announcement[]>;
    addAnnouncementFormGroup: UntypedFormGroup;
    uploader: FileUploader;
    imagesPreviews$: Observable<string[]>;
    announcementPreview$: Observable<Announcement>;
    announcementTypes$: Observable<EnumSelectOption[]>;

    onDrag$: Observable<boolean>;

    readonly modules = {
        toolbar: [['bold', 'italic', 'underline', 'strike']],
    };

    @ViewChild(FileDropDirective)
    private _fileDropZone: FileDropDirective;

    private _images$ = new BehaviorSubject<FileItem[]>([]);
    private _triggerGetAnnouncements$ = new BehaviorSubject<void>(null);

    constructor(
        private _fb: UntypedFormBuilder,
        private _sanitizer: DomSanitizer,
        private _formUtils: FormUtilsService,
        private _announcementService: AdminAnnouncementService,
    ) {}

    ngOnInit(): void {
        this.announcements$ = this._triggerGetAnnouncements$.pipe(
            switchMap(() =>
                this._announcementService
                    .getAllAnnouncements()
                    .pipe(
                        map((announcements) =>
                            announcements.sort(
                                (a, b) => new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime(),
                            ),
                        ),
                    ),
            ),
        );

        this.announcementTypes$ = of([
            {
                name: AnnouncementTypesLabels.FEATURE_RELEASE,
                enumValue: AnnouncementTypes.FEATURE_RELEASE,
            },
        ] as EnumSelectOption[]);

        this.addAnnouncementFormGroup = this._fb.group({
            announcementType: ['', Validators.required],
            title: ['', Validators.required],
            message: ['', Validators.required],
            externalLinkLabel: [''],
            externalLinkUrl: [''],
        });

        this.uploader = new FileUploader({
            maxFileSize: 100000000,
            allowedFileType: ['image'],
            url: '',
        });

        this.uploader.onAfterAddingFile = () => {
            this._images$.next(this.uploader.queue);
        };

        this.imagesPreviews$ = this._images$.pipe(
            map((images) => images.map((image) => URL.createObjectURL(image._file))),
        );

        this.announcementPreview$ = this.addAnnouncementFormGroup.valueChanges.pipe(
            startWith({}),
            switchMap((values: AddAnnouncementFormGroup) => {
                const { announcementType, title, message, externalLinkLabel, externalLinkUrl } = values;
                if (!values || !announcementType || !title || !message) {
                    return of(null);
                }
                return of({
                    announcementType,
                    title,
                    message,
                    externalLinkLabel,
                    externalLinkUrl,
                } as Announcement).pipe(
                    switchMap((announcement) =>
                        this.imagesPreviews$.pipe(
                            map(
                                (imagesPreviews) =>
                                    ({
                                        ...announcement,
                                        announcementImages: imagesPreviews.map((image, imageOrder) => ({
                                            imageOrder,
                                            url: this._sanitizer.bypassSecurityTrustResourceUrl(image),
                                        })),
                                    }) as Announcement,
                            ),
                        ),
                    ),
                );
            }),
        );
    }

    ngAfterViewInit(): void {
        this.onDrag$ = this._fileDropZone.fileOver.pipe(
            throttleTime(500, undefined, { leading: true, trailing: true }),
        );
    }

    addAnnouncement(): void {
        const formValue = this._formUtils.getCleanFormGroupValue<AddAnnouncementFormGroup & { images?: File[] }>(
            this.addAnnouncementFormGroup,
        );
        if (this.uploader.queue.length) {
            formValue.images = this.uploader.queue.map((item) => item._file);
        }
        const { announcementType, title, message, images, externalLinkLabel, externalLinkUrl } = formValue;
        const payload: CreateAnnouncement = {
            announcementType,
            title,
            message,
            images,
            externalLinkLabel,
            externalLinkUrl,
        };
        this._announcementService.createAnnouncement(payload).subscribe({
            next: () => {
                this._triggerGetAnnouncements$.next();
            },
            complete: () => {
                this.clearForm();
            },
        });
    }

    deleteAnnouncement(announcement: Announcement): void {
        this._announcementService.deleteAnnouncement(announcement.id).subscribe({
            next: () => {
                this._triggerGetAnnouncements$.next();
            },
        });
    }

    removeImage(index: number): void {
        const item = this.uploader.queue[index];
        this.uploader.removeFromQueue(item);
        this._images$.next(this.uploader.queue);
    }

    clearForm(): void {
        this.addAnnouncementFormGroup.reset({
            announcementType: AnnouncementTypes.FEATURE_RELEASE,
        });
        this.uploader.clearQueue();
        this._images$.next([]);
    }
}
