<div class="content-heading">
    <div>
        Hubspot Management
        <small>Admin controls for managing Hubspot integration</small>
    </div>
</div>
<div class="row">
    <div class="col">
        <h4>Sync Company Data</h4>
        <hr />
    </div>
</div>
<div class="row mb-4">
    <div class="col-lg-6">
        <div class="card card-default">
            <div class="card-body">
                <h4 class="card-title">Sync Company Records</h4>
                <p class="card-text">This will sync the Org records from the current client list with Hubspot.</p>
                <button mat-flat-button color="primary" (click)="syncCompaniesWithHubspot()">
                    Sync Company Records
                </button>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col">
        <h4>Sync Contacts Data</h4>
        <hr />
    </div>
</div>
<div class="row">
    <div class="col-lg-6">
        <div class="card card-default">
            <div class="card-body">
                <h4 class="card-title">Sync Contact Records</h4>
                <p class="card-text">This will sync all Org Admins across all active Clients as Contacts in Hubspot.</p>
                <button mat-flat-button color="primary" (click)="syncContactsWithHubspot()">
                    Sync Contact Records
                </button>
            </div>
        </div>
    </div>
</div>
