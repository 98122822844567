<mat-card [appearance]="'outlined'" [ngClass]="{ disabled: isDisabled, 'out-of-scope': isOutOfScope }">
    <mat-card-header class="row mb-3">
        <div class="col d-flex align-items-center rdp-subtitle" [ngClass]="isOutOfScope ? 'out-of-scope' : ''">
            {{ groupName }}
        </div>
        <div class="col-auto" *ngIf="showRequestInformation">
            <button
                mat-button
                visoButton="link"
                class="p-0 mb-1"
                *ngIf="!(requestingInformation$ | async) && !missingInformationWasRequested"
                (click)="confirmRequestInformation()"
                data-pw="sendFollowupQuestionnaireButton"
            >
                Send Follow-up Questionnaire
            </button>
            <label *ngIf="requestingInformation$ | async" class="text-primary p-0 mb-1 request-information-label"
                >Sending email...</label
            >
            <label
                *ngIf="missingInformationWasRequested && !(requestingInformation$ | async)"
                class="p-0 mb-1 request-information-label"
            >
                Information request sent on {{ latestAssessmentFollowUpQuestionnaireCreatedDate | date: 'mediumDate' }}
            </label>
        </div>
        <div class="col-auto">
            <button exportRemovableAction mat-icon-button class="small" (click)="toggleAll()">
                <mat-icon>{{ allOpened ? 'close_fullscreen' : 'open_in_full' }}</mat-icon>
            </button>
        </div>
    </mat-card-header>
    <mat-card-content>
        <mat-accordion [displayMode]="'flat'" [multi]="true">
            <mat-expansion-panel
                *ngFor="let controlDomainAssessment of controlDomainAssessments; trackBy: trackByControlDomainId"
                (closed)="triggerAccordionToggled(false, controlDomainAssessment)"
                (opened)="triggerAccordionToggled(true, controlDomainAssessment)"
                [expanded]="isAccordionOpened(controlDomainAssessment.controlDomainId)"
                class="mat-elevation-z0"
            >
                <mat-expansion-panel-header>
                    <mat-panel-title [ngClass]="isOutOfScope ? 'out-of-scope' : ''">
                        {{ controlDomainAssessment.controlName }}
                    </mat-panel-title>
                    <mat-panel-description class="justify-content-end pe-2" *ngIf="!isDisabled">
                        <div class="d-flex gap-3">
                            <div
                                *ngIf="controlDomainAssessment.exceptionsCount > 0"
                                class="px-1 flex-shrink-1 flex-grow-0"
                            >
                                <app-badge type="light-gray" [noBorderRadius]="true" class="exceptions-badge"
                                    >Exception</app-badge
                                >
                            </div>
                            <div
                                *ngIf="controlDomainAssessment.subservicerCount > 0"
                                class="px-1 lex-shrink-1 flex-grow-0"
                            >
                                <app-badge type="light-gray" [noBorderRadius]="true" class="subservicer-badge"
                                    >Subservicer</app-badge
                                >
                            </div>
                            <div
                                class="px-1 flex-shrink-1 flex-grow-0"
                                *ngIf="controlDomainAssessment.sharedResponsibility"
                            >
                                <app-badge type="light-gray" [noBorderRadius]="true" class="shared-responsibility-badge"
                                    >Shared Responsibility</app-badge
                                >
                            </div>
                            <div *ngIf="controlDomainAssessment.cuecCount > 0" class="px-1 flex-shrink-1 flex-grow-0">
                                <app-badge type="light-gray" [noBorderRadius]="true" class="cuec-badge">CUEC</app-badge>
                            </div>
                            <div
                                *jhiHasAnyAuthority="[Roles.Auditor, Roles.Support]"
                                class="flex-shrink-1 control-coverage"
                            >
                                <strong class="control-coverage-label">Total Coverage </strong>
                                <strong class="control-coverage-percent"
                                    >{{ controlDomainAssessment.coverage * 100 }}%</strong
                                >
                            </div>
                            <div
                                class="flex-shrink-1 flex-grow-0 d-flex align-items-center"
                                *ngIf="
                                    !!controlDomainAssessment.assuranceLevel &&
                                    groupControlValidationStatus !== SecurityControlDomainGroupStatus.NOT_APPLICABLE &&
                                    groupControlValidationStatus !== SecurityControlDomainGroupStatus.UNVALIDATED &&
                                    groupControlValidationStatus !== SecurityControlDomainGroupStatus.OUT_OF_SCOPE &&
                                    controlDomainAssessment.detectionsTotalCount > 0
                                "
                            >
                                <app-assurance-level-meter
                                    [hideLabel]="true"
                                    [assuranceLevel]="controlDomainAssessment.assuranceLevel"
                                ></app-assurance-level-meter>
                            </div>
                        </div>
                    </mat-panel-description>
                </mat-expansion-panel-header>

                <ng-template matExpansionPanelContent>
                    <p class="ps-2 pe-4 mb-3" [ngClass]="isOutOfScope ? 'out-of-scope' : ''">
                        {{ controlDomainAssessment.longDescription }}
                    </p>
                    <ul class="ps-2 pe-4" *ngIf="!isDisabled">
                        <ng-container *jhiHasAnyAuthority="Roles.Auditor">
                            <ng-container
                                *ngFor="
                                    let individualControl of controlDomainAssessment.individualControls;
                                    trackBy: trackByControlId
                                "
                            >
                                <strong *ngIf="individualControl.detections.length" class="pb-2"
                                    >{{ individualControl.controlName }} ({{
                                        individualControl.detections.length
                                    }})</strong
                                >
                                <ng-container
                                    *ngFor="let detection of individualControl.detections; trackBy: trackById"
                                >
                                    <ng-container
                                        *ngTemplateOutlet="
                                            controlDetection;
                                            context: {
                                                detection: detection | asCommonDetection,
                                            }
                                        "
                                    ></ng-container>
                                </ng-container>
                            </ng-container>

                            <strong *ngIf="controlDomainAssessment.detections.length" class="pb-2"
                                >Domain Level ({{ controlDomainAssessment.detections.length }})</strong
                            >
                            <ng-container
                                *ngFor="let detection of controlDomainAssessment.detections; trackBy: trackById"
                            >
                                <ng-container
                                    *ngTemplateOutlet="
                                        controlDetection;
                                        context: {
                                            detection: detection | asCommonDetection,
                                        }
                                    "
                                ></ng-container>
                            </ng-container>
                        </ng-container>
                        <ng-container
                            *jhiHasAnyAuthority="[Roles.OrgAdmin, Roles.OrgUser, Roles.Support, Roles.ReadOnly]"
                        >
                            <ng-container
                                *ngFor="let detection of controlDomainAssessment.allDetections; trackBy: trackById"
                            >
                                <ng-container
                                    *ngTemplateOutlet="
                                        controlDetection;
                                        context: {
                                            detection: detection | asCommonDetection,
                                        }
                                    "
                                ></ng-container>
                            </ng-container>
                        </ng-container>
                    </ul>
                </ng-template>
            </mat-expansion-panel>
        </mat-accordion>
    </mat-card-content>
</mat-card>

<ng-template #controlDetection let-detection="detection">
    <li class="list-group-item px-0 pt-0" *ngIf="isDetectionAllowed(detection)">
        <app-detection [detection]="detection" [frameworkType]="frameworkType" />
    </li>
</ng-template>
