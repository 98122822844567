/*
 Copyright 2013-2018 the original author or authors from the JHipster project.

 This file is part of the JHipster project, see https://www.jhipster.tech/
 for more information.

 Licensed under the Apache License, Version 2.0 (the "License");
 you may not use this file except in compliance with the License.
 You may obtain a copy of the License at

      http://www.apache.org/licenses/LICENSE-2.0
 Unless required by applicable law or agreed to in writing, software
 distributed under the License is distributed on an "AS IS" BASIS,
 WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 See the License for the specific language governing permissions and
 limitations under the License.
*/
import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { take } from 'rxjs/operators';
import { VisoUserRole } from '../../../entities/viso-user';
import { getUserAuthority } from '../../../routes/session/redux/session.selectors';
import { AppState } from '../../redux/state';

/**
 * @whatItDoes Conditionally excludes an HTML element if current user has not
 * the authorities passed as the `expression`.
 *
 * @howToUse
 * ```
 *     <some-element *jhiHasNotAuthority="VisoUserRole.Admin">...</some-element>
 *
 *     <some-element *jhiHasNotAuthority="[VisoUserRole.Admin, VisoUserRole.OrgAdmin]">...</some-element>
 * ```
 */
@Directive({
    selector: '[jhiHasNotAuthority]',
})
export class HasNotAuthorityDirective {
    private authorities: VisoUserRole[];

    constructor(
        private _templateRef: TemplateRef<any>,
        private _viewContainerRef: ViewContainerRef,
        private _store$: Store<AppState>,
    ) {}

    @Input()
    set jhiHasNotAuthority(value: VisoUserRole | VisoUserRole[]) {
        this.authorities = Array.isArray(value) ? value : [value];
        this.updateView();
    }

    private updateView(): void {
        this._viewContainerRef.clear();
        this._store$.pipe(select(getUserAuthority(this.authorities)), take(1)).subscribe((hasAnyAuthority) => {
            if (!hasAnyAuthority) {
                this._viewContainerRef.createEmbeddedView(this._templateRef);
            }
        });
    }
}
