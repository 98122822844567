import { ResolveFn, Routes } from '@angular/router';

import { AuthGuard } from '../../shared';
import { AttestationsComponent } from './attestations.component';
import { Artifact, ArtifactService } from '@entities/artifact';
import { inject } from '@angular/core';
import { map } from 'rxjs';
import { relationshipNameResolver } from '../questionnaire/resolvers/relationship-name.resolver';
import { relationshipArtifactsResolver } from '../questionnaire/resolvers/relationship-artifacts.resolver';

export const attestationArtifactResolver: ResolveFn<Artifact> = (route, state) => {
    const artifactId = +(route.paramMap.get('artifactId') as string);
    const artifactService = inject(ArtifactService);
    return artifactService.find(artifactId).pipe(map((res) => res.body as Artifact));
};

export const attestationRoutes: Routes = [
    {
        path: 'requests/:relationshipId/assessments/:assessmentId/attestations/:artifactId',
        component: AttestationsComponent,
        resolve: {
            attestations: attestationArtifactResolver,
            relationshipName: relationshipNameResolver,
            relationshipArtifacts: relationshipArtifactsResolver,
        },
        canActivate: [AuthGuard],
    },
];
