import { createAction, props } from '@ngrx/store';
import { VanityDomainView, VanityDomainsConfig } from '@entities/vanity-domain';

export enum VanityDomainActions {
    GetVanityDomainRequest = '[VanityDomains] Get Vanity Domain Request',
    GetVanityDomainRequestSuccess = '[VanityDomains] Get Vanity Domain Request Success',
    GetVanityDomainRequestFailure = '[VanityDomains] Get Vanity Domain Request Failure',

    RefreshVanityDomainRequest = '[VanityDomains] Refresh Vanity Domain Request',

    GetVanityDomainsConfigRequest = '[VanityDomains] Get Vanity Domains Config Request',
    GetVanityDomainsConfigRequestSuccess = '[VanityDomains] Get Vanity Domains Config Request Success',
    GetVanityDomainsConfigRequestFailure = '[VanityDomains] Get Vanity Domains Config Request Failure',

    CreateVanityDomainRequest = '[VanityDomains] Create Vanity Domain Request',
    CreateVanityDomainRequestSuccess = '[VanityDomains] Create Vanity Domain Request Success',
    CreateVanityDomainRequestFailure = '[VanityDomains] Create Vanity Domain Request Failure',

    DeleteVanityDomainRequest = '[VanityDomains] Delete Vanity Domain Request',
    DeleteVanityDomainRequestSuccess = '[VanityDomains] Delete Vanity Domain Request Success',
    DeleteVanityDomainRequestFailure = '[VanityDomains] Delete Vanity Domain Request Failure',
}

export const getVanityDomainRequest = createAction(VanityDomainActions.GetVanityDomainRequest);

export const getVanityDomainRequestSuccess = createAction(
    VanityDomainActions.GetVanityDomainRequestSuccess,
    props<{ status: VanityDomainView }>(),
);

export const getVanityDomainRequestFailure = createAction(VanityDomainActions.GetVanityDomainRequestFailure);

export const refreshVanityDomainRequest = createAction(VanityDomainActions.RefreshVanityDomainRequest);

export const createVanityDomainRequest = createAction(
    VanityDomainActions.CreateVanityDomainRequest,
    props<{ domain: string; subdomain: string }>(),
);

export const createVanityDomainRequestSuccess = createAction(
    VanityDomainActions.CreateVanityDomainRequestSuccess,
    props<{ status: VanityDomainView }>(),
);

export const createVanityDomainRequestFailure = createAction(VanityDomainActions.CreateVanityDomainRequestFailure);

export const getVanityDomainsConfigRequest = createAction(VanityDomainActions.GetVanityDomainsConfigRequest);

export const getVanityDomainsConfigRequestSuccess = createAction(
    VanityDomainActions.GetVanityDomainsConfigRequestSuccess,
    props<{ config: VanityDomainsConfig }>(),
);

export const getVanityDomainsConfigRequestFailure = createAction(
    VanityDomainActions.GetVanityDomainsConfigRequestFailure,
);

export const deleteVanityDomainRequest = createAction(VanityDomainActions.DeleteVanityDomainRequest);

export const deleteVanityDomainRequestSuccess = createAction(VanityDomainActions.DeleteVanityDomainRequestSuccess);

export const deleteVanityDomainRequestFailure = createAction(VanityDomainActions.DeleteVanityDomainRequestFailure);
