import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, map, Observable } from 'rxjs';
import { RecertificationType, ReviewFrequency } from '../../../../entities/relationship';
import { VisoUser, VisoUserRole } from '../../../../entities/viso-user';
import { getUserIsTrial } from '../../../session/redux/session.selectors';
import { openLifecycleManagementModal } from '../../redux/actions/lifecycle-management.actions';

@Component({
    selector: 'app-lifecycle-management',
    templateUrl: './lifecycle-management.component.html',
    styleUrls: ['./lifecycle-management.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LifecycleManagementComponent implements OnInit {
    @Input({ required: true })
    relationshipId: number;

    @Input({ required: true })
    isOnboarded: boolean;

    @Input({ required: true })
    set artifactUpdateType(value: RecertificationType) {
        this.artifactUpdateType$.next(value);
    }

    @Input({ required: true })
    dateOfNextArtifactExpiration: Date;

    @Input({ required: true })
    set recertificationType(value: RecertificationType) {
        this.recertificationType$.next(value);
    }

    @Input({ required: true })
    set reviewFrequency(value: ReviewFrequency) {
        this.reviewFrequency$.next(value);
    }

    @Input({ required: true })
    recertificationDate?: Date;

    @Input({ required: true })
    businessOwner: VisoUser;

    Roles = VisoUserRole;
    RecertificationType = RecertificationType;

    recertificationLabel$: Observable<string>;
    artifactUpdateLabel$: Observable<string>;
    userIsTrial$: Observable<boolean>;
    recertificationType$ = new BehaviorSubject<RecertificationType>(null);
    artifactUpdateType$ = new BehaviorSubject<RecertificationType>(null);
    reviewFrequency$ = new BehaviorSubject<ReviewFrequency>(null);

    private reviewFrequencyPostfixes: Record<ReviewFrequency, string> = {
        THREE_YEARS: '3 years',
        TWO_YEARS: '2 years',
        ANNUAL: 'year',
        SEMIANNUAL: '6 months',
        QUARTERLY: '3 months',
    };

    constructor(private _store$: Store) {}

    ngOnInit(): void {
        this.recertificationLabel$ = combineLatest([this.recertificationType$, this.reviewFrequency$]).pipe(
            map(([recertificationType, reviewFrequency]) =>
                this.computeRecertificationLabel(recertificationType, reviewFrequency),
            ),
        );

        this.artifactUpdateLabel$ = this.artifactUpdateType$.pipe(
            map((artifactUpdateType) => this.computeArtifactUpdateLabel(artifactUpdateType)),
        );

        this.userIsTrial$ = this._store$.pipe(select(getUserIsTrial));
    }

    openLifecycleManagementModal(): void {
        this._store$.dispatch(
            openLifecycleManagementModal({
                relationshipId: this.relationshipId,
                artifactUpdateType: this.artifactUpdateType$.value,
                dateOfNextArtifactExpiration: this.dateOfNextArtifactExpiration,
                recertificationType: this.recertificationType$.value,
                reviewFrequency: this.reviewFrequency$.value,
                recertificationDate: this.recertificationDate,
            }),
        );
    }

    private computeArtifactUpdateLabel(artifactUpdateType: RecertificationType): string {
        if (artifactUpdateType === RecertificationType.NONE) {
            return 'Off';
        }
        let result: string;
        switch (artifactUpdateType) {
            case RecertificationType.MANUAL:
                result = 'Remind business owner';
                break;
            case RecertificationType.AUTOMATIC:
                result = 'Auto assess';
                break;
        }
        return result;
    }

    private computeRecertificationLabel(
        recertificationType: RecertificationType,
        reviewFrequency: ReviewFrequency,
    ): string {
        if (recertificationType === RecertificationType.NONE) {
            return 'Off';
        }
        let result: string;
        switch (recertificationType) {
            case RecertificationType.MANUAL:
                result = 'Remind business owner every';
                break;
            case RecertificationType.AUTOMATIC:
                result = 'Auto assess every';
                break;
        }
        return `${result} ${this.reviewFrequencyPostfixes[reviewFrequency]}`;
    }
}
