import { Component, Input, OnInit } from '@angular/core';
import { Risk, RiskLabels, RiskWithContext } from '@entities/risk-assessment';
import { FormControl } from '@angular/forms';

@Component({
    selector: 'app-risk-override',
    templateUrl: './risk-override.component.html',
    styleUrls: ['./risk-override.component.scss'],
})
export class RiskOverrideComponent implements OnInit {
    @Input({ required: true })
    riskOverrideControl: FormControl<Risk>;

    @Input({ required: true })
    updatedByFullName: string;

    @Input({ required: true })
    lastUpdated: Date;

    @Input({ required: true })
    inherentRisk: Risk;

    @Input({ required: true })
    residualRisk: Risk;

    @Input({ required: true })
    allowChanges: boolean;

    Risk = Risk;
    RiskWithContext = RiskWithContext;
    RiskLabels = RiskLabels;

    riskOverrideSelectVisible: boolean;

    get hasInherentRisk(): boolean {
        return !!this.inherentRisk && this.inherentRisk !== Risk.NO_CONTEXT;
    }

    get hasResidualRisk(): boolean {
        return !!this.residualRisk && this.residualRisk !== Risk.NO_CONTEXT;
    }

    get hasRiskOverride(): boolean {
        return !!this.riskOverrideControl.value && this.riskOverrideControl.value !== Risk.NO_CONTEXT;
    }

    ngOnInit(): void {
        this.riskOverrideSelectVisible = !!this.riskOverrideControl.value;
    }

    clearOverride(): void {
        this.riskOverrideControl.setValue(null);
        this.riskOverrideSelectVisible = false;
    }
}
