import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SensitivityMeterComponent } from './components/sensitivity-meter/sensitivity-meter.component';

@NgModule({
    declarations: [SensitivityMeterComponent],
    imports: [CommonModule],
    exports: [SensitivityMeterComponent],
})
export class SensitivityMeterModule {}
