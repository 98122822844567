import { Tag, TagPagination } from '../../../entities/tag';
import * as fromRoot from '../../../shared/redux/state';

export const tagsStateKey = 'tags';

export interface TagsState {
    tags: Tag[];
    pagination: TagPagination;
    loading: boolean;
    error: any;
    selectedTag: Tag;
}

export const initialState: TagsState = {
    tags: [],
    pagination: null,
    loading: false,
    error: null,
    selectedTag: null,
};

export interface AppState extends fromRoot.AppState {
    tags: TagsState;
}
