import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { businessUnitMgmtRoutes } from './';
import { SharedModule } from '../../shared';
import { BusinessUnitManagementComponent } from './business-unit-management.component';
import { NgxSelectModule } from 'ngx-select-ex';
import { BusinessUnitManagementHelpComponent } from './business-unit-management-help.component';
import { BusinessUnitEffects } from './redux/business-unit-management.effects';
import { EffectsModule } from '@ngrx/effects';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

const ENTITY_STATES = [...businessUnitMgmtRoutes];

@NgModule({
    imports: [
        SharedModule,
        RouterModule.forChild(ENTITY_STATES),
        NgxSelectModule,
        NgxDatatableModule,
        EffectsModule.forFeature([BusinessUnitEffects]),
    ],
    declarations: [BusinessUnitManagementComponent, BusinessUnitManagementHelpComponent],
    providers: [],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class BusinessUnitManagementModule {
    constructor() {}
}
