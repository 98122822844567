<form [formGroup]="businessUnitForm" (ngSubmit)="save()">
    <div class="modal-header">
        <h4 *ngIf="!businessUnit?.id" class="modal-title" id="myBusinessUnitLabel">Add Business Unit</h4>
        <h4 *ngIf="businessUnit?.id" class="modal-title" id="myBusinessUnitLabel">Edit Business Unit</h4>
        <button type="button" class="btn-close" data-dismiss="modal" aria-hidden="true" (click)="clear()"></button>
    </div>
    <div class="modal-body">
        <div class="form-group" [hidden]="!businessUnit?.id">
            <label for="id">ID</label>
            <input type="text" class="form-control" id="id" name="id" formControlName="id" readonly />
        </div>
        <div class="form-group">
            <label class="form-control-label" for="field_name">Name</label>
            <input
                type="text"
                class="form-control"
                name="name"
                id="field_name"
                formControlName="name"
                data-pw="nameInput"
                placeholder="Enter business unit name"
            />
        </div>
    </div>
    <div class="modal-footer d-flex justify-content-end main-action">
        <ng-container *jhiHasAnyAuthority="Roles.OrgAdmin">
            <button
                *ngIf="businessUnit?.id && businessUnit?.userCount > 0"
                type="button"
                class="me-auto"
                mat-button
                visoButton="secondary"
                data-dismiss="modal"
                (click)="transferDismissModal()"
            >
                Reassign Business Unit
            </button>
        </ng-container>

        <button
            type="button"
            mat-button
            visoButton="secondary"
            class="float-end"
            data-dismiss="modal"
            (click)="clear()"
        >
            Cancel
        </button>
        <button
            type="submit"
            [disabled]="businessUnitForm.invalid || isSaving"
            class="float-end"
            mat-button
            visoButton="primary"
            data-pw="saveBusinessUnitButton"
        >
            Save
        </button>
    </div>
</form>
