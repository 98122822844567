import { Routes } from '@angular/router';

import { AuthGuard } from '../../shared';
import { BusinessUnitManagementComponent } from './business-unit-management.component';
import { VisoUserRole } from '../../entities/viso-user';

export const businessUnitMgmtRoutes: Routes = [
    {
        path: 'business-units',
        component: BusinessUnitManagementComponent,
        data: {
            authorities: [VisoUserRole.OrgAdmin, VisoUserRole.OrgUser, VisoUserRole.ReadOnly],
            pageTitle: 'Business Unit Management',
        },
        canActivate: [AuthGuard],
    },
];
