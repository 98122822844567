import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { VisoUser } from '../../../entities/viso-user';

export enum ContactActions {
    GetContactsRequest = '[Contact] Get Contacts Request',
    GetContactsRequestSuccess = '[Contact] Get Contacts Request Success',
    GetContactsRequestFailed = '[Contact] Get Contacts Request Failed',
    CreateContactRequest = '[Contact] Create Contact Request',
    CreateContactRequestSuccess = '[Contact] Create Contact Request Success',
    CreateContactRequestFailed = '[Contact] Create Contact Request Failed',
    UpdateContactRequest = '[Contact] Update Contact Request',
    UpdateContactRequestSuccess = '[Contact] Update Contact Request Success',
    UpdateContactRequestFailed = '[Contact] Update Contact Request Failed',
    ReassignContactRelationshipsRequest = '[Contact] Reassign Relationships Request',
    ReassignContactRelationshipsRequestSuccess = '[Contact] Reassign Relationships Request Success',
    ReassignContactRelationshipsRequestFailed = '[Contact] Reassign Relationships Request Failed',
    DeleteContactRequest = '[Contact] Delete Contact Request',
    DeleteContactRequestSuccess = '[Contact] Delete Contact Request Success',
    DeleteContactRequestFailed = '[Contact] Delete Contact Request Failed',
    OpenDeleteContactConfirmationModal = '[Contact] Open Delete Contact Confirmation Modal',
    OpenUpdateContactStatusConfirmationModal = '[Contact] Open Disable Contact Confirmation Modal',
    UpdateManagedContactStatusRequest = '[Contact] Disable Contact Request',
    UpdateManagedContactStatusRequestSuccess = '[Contact] Disable Contact Request Success',
    UpdateManagedContactStatusRequestFailed = '[Contact] Disable Contact Request Failed',
}

export const getContactsRequest = createAction(
    ContactActions.GetContactsRequest,
    props<{ req?: any; clientId?: number }>(),
);

export const getContactsRequestSuccess = createAction(
    ContactActions.GetContactsRequestSuccess,
    props<{ contacts: VisoUser[]; headers: HttpHeaders }>(),
);

export const getContactsRequestFailed = createAction(ContactActions.GetContactsRequestFailed);

export const createContactRequest = createAction(ContactActions.CreateContactRequest, props<{ contact: VisoUser }>());

export const createContactRequestSuccess = createAction(
    ContactActions.CreateContactRequestSuccess,
    props<{ contact: VisoUser }>(),
);

export const createContactRequestFailed = createAction(
    ContactActions.CreateContactRequestFailed,
    props<{ error: HttpErrorResponse; contact: VisoUser }>(),
);

export const updateContactRequest = createAction(ContactActions.UpdateContactRequest, props<{ contact: VisoUser }>());

export const updateContactRequestSuccess = createAction(
    ContactActions.UpdateContactRequestSuccess,
    props<{ contact: VisoUser }>(),
);

export const updateContactRequestFailed = createAction(
    ContactActions.UpdateContactRequestFailed,
    props<{ error: HttpErrorResponse; contact: VisoUser }>(),
);

export const reassignContactRelationshipsRequest = createAction(
    ContactActions.ReassignContactRelationshipsRequest,
    props<{
        fromContactId: number;
        toContactId: number;
        reassignOwnerships: boolean;
        reassignSubscriptions: boolean;
    }>(),
);

export const reassignContactRelationshipsRequestSuccess = createAction(
    ContactActions.ReassignContactRelationshipsRequestSuccess,
);

export const reassignContactRelationshipsRequestFailed = createAction(
    ContactActions.ReassignContactRelationshipsRequestFailed,
);

export const deleteContactRequest = createAction(ContactActions.DeleteContactRequest, props<{ contactId: number }>());

export const deleteContactRequestSuccess = createAction(ContactActions.DeleteContactRequestSuccess);

export const deleteContactRequestFailed = createAction(ContactActions.DeleteContactRequestFailed);

export const openDeleteContactConfirmationModal = createAction(
    ContactActions.OpenDeleteContactConfirmationModal,
    props<{ contact: VisoUser }>(),
);

export const openUpdateContactStatusConfirmationModal = createAction(
    ContactActions.OpenUpdateContactStatusConfirmationModal,
    props<{ contact: VisoUser }>(),
);

export const updateManagedContactStatusRequest = createAction(
    ContactActions.UpdateManagedContactStatusRequest,
    props<{ contact: VisoUser }>(),
);

export const updateManagedContactStatusRequestSuccess = createAction(
    ContactActions.UpdateManagedContactStatusRequestSuccess,
    props<{ contact: VisoUser }>(),
);

export const updateManagedContactStatusRequestFailed = createAction(
    ContactActions.UpdateManagedContactStatusRequestFailed,
    props<{ error: HttpErrorResponse; contact: VisoUser }>(),
);
