import { UntypedFormControl } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { VisoUser } from '../../entities/viso-user';
import { RefSelectOption } from '../model/select-options';

export function isUniqueContactValidator(
    contacts: VisoUser[] | BehaviorSubject<VisoUser[]> | BehaviorSubject<RefSelectOption<VisoUser>[]>,
    selectingExistingContact: boolean,
    emailOfEditContact?: string,
) {
    return (control: UntypedFormControl) => {
        if ((control && !control.value) || control.disabled) {
            return null;
        }

        const emailProvided = control.value.trim().toLowerCase();

        const _contacts =
            contacts instanceof BehaviorSubject
                ? contacts.value.map((contact) => ((contact as RefSelectOption<VisoUser>).ref ? contact.ref : contact))
                : contacts;

        for (const contact of _contacts) {
            if (
                !selectingExistingContact &&
                emailProvided.toLowerCase() === contact.email.toLowerCase() &&
                emailProvided.toLowerCase() !== emailOfEditContact?.toLowerCase()
            )
                return { emailAlreadyExists: true };
        }

        return null;
    };
}
