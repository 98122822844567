import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, filter, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import { RiskToleranceService } from '@entities/risk-tolerance';
import {
    getRelationshipRiskDistributionRequest,
    getRelationshipRiskDistributionRequestFailed,
    getRelationshipRiskDistributionRequestSuccess,
    getRiskToleranceThresholdsRequest,
    getRiskToleranceThresholdsRequestFailed,
    getRiskToleranceThresholdsRequestSuccess,
    loadRiskToleranceSettings,
    openCustomizeRiskToleranceDialog,
    updateRiskToleranceSettingsRequest,
    updateRiskToleranceSettingsRequestFailed,
    updateRiskToleranceSettingsRequestSuccess,
} from './risk-model-settings.actions';
import { MatDialogWrapperService } from '@shared/modal/mat-dialog-wrapper.service';
import { RiskToleranceFormDialogComponent } from '../risk-tolerance/risk-tolerance-form-dialog/risk-tolerance-form-dialog.component';
import { getProfileRiskTolerance, getRiskLevelDisplayNamesForClient } from '../../session/redux/session.selectors';
import { getRelationshipRiskDistribution, getRiskToleranceThresholds } from './risk-model-settings.selectors';
import { Store } from '@ngrx/store';
import { getCurrentClientProfileRequest } from '../../session/redux/session.actions';
import { MatConfirmDialogService } from '@shared/components/mat-confirm-dialog/mat-confirm-dialog.service';
import { SnackbarService } from '@shared/components/snackbar/snackbar.service';

@Injectable()
export class RiskModelSettingsEffects {
    getRiskToleranceThresholdsRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(getRiskToleranceThresholdsRequest),
            switchMap(() =>
                this._riskToleranceService.getRiskToleranceThresholds().pipe(
                    map((riskToleranceThresholds) =>
                        getRiskToleranceThresholdsRequestSuccess({ riskToleranceThresholds }),
                    ),
                    catchError(() => of(getRiskToleranceThresholdsRequestFailed())),
                ),
            ),
        ),
    );

    getRelationshipRiskDistributionRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(getRelationshipRiskDistributionRequest),
            switchMap(() =>
                this._riskToleranceService.getRelationshipRiskDistribution().pipe(
                    map((relationshipRiskDistribution) =>
                        getRelationshipRiskDistributionRequestSuccess({ relationshipRiskDistribution }),
                    ),
                    catchError(() => of(getRelationshipRiskDistributionRequestFailed())),
                ),
            ),
        ),
    );

    updateRiskToleranceSettingsRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(updateRiskToleranceSettingsRequest),
            exhaustMap(({ request }) =>
                this._matConfirmDialogService
                    .confirm({
                        title: 'Are you sure you want to change your risk tolerance settings?',
                        message:
                            'These settings will apply retroactively to all relationships. This can be reverted to default at any time.',
                        confirmLabel: 'Yes, apply changes',
                    })
                    .pipe(
                        filter(Boolean),
                        switchMap(() =>
                            this._riskToleranceService.updateRiskToleranceSettings(request).pipe(
                                map(() => updateRiskToleranceSettingsRequestSuccess()),
                                catchError(() => of(updateRiskToleranceSettingsRequestFailed())),
                            ),
                        ),
                    ),
            ),
        ),
    );

    updateRiskToleranceSettingsRequestSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(updateRiskToleranceSettingsRequestSuccess),
                tap(() =>
                    this._snackbarService.success(
                        'Risk tolerance settings updated. Refreshing your relationships... You will receive a notification when this process completes.',
                    ),
                ),
            ),
        { dispatch: false },
    );

    openCreateRiskAdvisoryDialog$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(openCustomizeRiskToleranceDialog),
                withLatestFrom(
                    this._store$.select(getRiskLevelDisplayNamesForClient),
                    this._store$.select(getProfileRiskTolerance),
                    this._store$.select(getRiskToleranceThresholds),
                    this._store$.select(getRelationshipRiskDistribution),
                ),
                tap(
                    ([
                        ,
                        riskLevelDisplayNames,
                        clientRiskTolerance,
                        riskToleranceThresholds,
                        relationshipRiskDistribution,
                    ]) =>
                        this._dialogService.open(RiskToleranceFormDialogComponent, {
                            inputs: {
                                riskLevelDisplayNames,
                                clientRiskTolerance,
                                riskToleranceThresholds,
                                relationshipRiskDistribution,
                            },
                            config: {
                                maxWidth: 1000,
                                disableClose: true,
                            },
                        }),
                ),
            ),
        { dispatch: false },
    );

    loadRiskToleranceSettings$ = createEffect(() =>
        this._actions$.pipe(
            ofType(updateRiskToleranceSettingsRequestSuccess, loadRiskToleranceSettings),
            mergeMap(() => [
                getCurrentClientProfileRequest(),
                getRiskToleranceThresholdsRequest(),
                getRelationshipRiskDistributionRequest(),
            ]),
        ),
    );

    constructor(
        private _actions$: Actions,
        private _store$: Store,
        private _riskToleranceService: RiskToleranceService,
        private _dialogService: MatDialogWrapperService,
        private _matConfirmDialogService: MatConfirmDialogService,
        private _snackbarService: SnackbarService,
    ) {}
}
