import { ControlDomain } from '../../../../entities/control-domain';

export function labelControlNameForAuditor(control: ControlDomain): string {
    const controlName = control.name;
    return `${controlName}${control.disabled ? ' (DISABLED)' : !control.relevant ? ' (OUT OF SCOPE)' : ''}`;
}

export function sortByRelevantAndDisabled(controlA: ControlDomain, controlB: ControlDomain): number {
    return +controlB.relevant - +controlA.relevant - (+controlB.disabled - +controlA.disabled);
}
