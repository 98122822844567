import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HideIfOrgUserIsNotBusinessOwnerDirective } from './directives/hide-if-org-user-is-not-business-owner.directive';

@NgModule({
    declarations: [HideIfOrgUserIsNotBusinessOwnerDirective],
    imports: [CommonModule],
    exports: [HideIfOrgUserIsNotBusinessOwnerDirective],
})
export class HideIfOrgUserIsNotBusinessOwnerModule {}
