import { Inject, Injectable, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable()
export class LinkedInScriptInjectorService {
    constructor(
        private _renderer2: Renderer2,
        @Inject(DOCUMENT) private _document: Document,
    ) {}

    injectScript(): void {
        // Include necessary variables
        const _linkedin_partner_id = '4226620';
        const windowRef = window as any;
        windowRef._linkedin_data_partner_ids = windowRef._linkedin_data_partner_ids || [];
        windowRef._linkedin_data_partner_ids.push(_linkedin_partner_id);
        if (!windowRef.lintrk) {
            windowRef.lintrk = function (a, b) {
                windowRef.lintrk.q.push([a, b]);
            };
            windowRef.lintrk.q = [];
        }

        // Create Script & append it to body
        const scriptTag = this._renderer2.createElement('script') as HTMLScriptElement;
        scriptTag.type = 'text/javascript';
        scriptTag.src = 'https://snap.licdn.com/li.lms-analytics/insight.min.js';
        scriptTag.async = true;
        this._renderer2.appendChild(this._document.body, scriptTag);

        // Create NoScript Tag with track img & append it to body
        const noScriptTag = this._renderer2.createElement('noscript');
        const imgTag = this._renderer2.createElement('img') as HTMLImageElement;
        imgTag.height = 1;
        imgTag.width = 1;
        this._renderer2.setStyle(imgTag, 'display', 'none');
        imgTag.alt = '';
        imgTag.src = 'https://px.ads.linkedin.com/collect/?pid=4226620&fmt=gif';
        this._renderer2.appendChild(noScriptTag, imgTag);
        this._renderer2.appendChild(this._document.body, noScriptTag);
    }
}
