<ng-container
    *ngIf="{
        isOnboardedAndRecertificationIsDisabled: !(isNotOnboarded$ | async) && (isRecertificationDisabled$ | async),
        lifecycleStatus: lifecycleStatus$ | async,
        isNotOnboarded: isNotOnboarded$ | async,
    } as globalCtx"
>
    <div class="row stat-boxes-wrapper">
        <ng-container
            *ngIf="{
                inherentRiskLabel: inherentRiskLabel$ | async,
                inherentRiskBoxCssClasses: inherentRiskBoxCssClasses$ | async,
            } as ctx"
        >
            <div class="col-sm-6 col-lg-3 position-relative">
                <div class="card risk-card" [ngClass]="ctx.inherentRiskBoxCssClasses">
                    <div class="card-body">
                        <div class="card-title text-muted mb-0">Inherent Risk</div>
                        <p class="h4 text-bold mb-0">{{ ctx.inherentRiskLabel | riskLevel | async }}</p>
                    </div>
                    <div class="card-footer py-0"></div>
                </div>
            </div>
        </ng-container>
        <ng-container
            *ngIf="{
                residualRiskLabel: residualRiskLabel$ | async,
                isResidualRiskTransitionalAndNotOverridden: isResidualRiskTransitionalAndNotOverridden$ | async,
                residualRiskBoxCssClasses: residualRiskBoxCssClasses$ | async,
                residualRiskHelpText: residualRiskHelpText$ | async,
                riskIsOverridden: riskIsOverridden$ | async,
            } as ctx"
        >
            <div class="col-sm-6 col-lg-3">
                <div class="card risk-card" [ngClass]="ctx.residualRiskBoxCssClasses">
                    <div class="card-body">
                        <div class="d-flex justify-content-between">
                            <div class="card-title text-muted mb-0">
                                Residual Risk {{ ctx.riskIsOverridden ? ' (overridden)' : '' }}
                            </div>
                            <ng-container *jhiHasAnyAuthority="[Roles.OrgAdmin]">
                                <a
                                    exportRemovableAction
                                    class="text-primary cursor-pointer"
                                    (click)="editRiskReview.emit()"
                                >
                                    Edit
                                </a>
                            </ng-container>
                        </div>
                        <div class="d-flex">
                            <p
                                class="h4 text-bold mb-0"
                                [ngClass]="{
                                    'fst-italic text-muted': ctx.isResidualRiskTransitionalAndNotOverridden,
                                }"
                            >
                                {{ ctx.residualRiskLabel | riskLevel | async }}
                                <span *ngIf="ctx.isResidualRiskTransitionalAndNotOverridden">*</span>
                            </p>
                        </div>
                    </div>
                    <div class="card-footer py-0">
                        <small class="text-muted">{{ ctx.residualRiskHelpText }}</small>
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-container
            *ngIf="{
                isUpdatingArtifactsOrRecertifying: isUpdatingArtifactsOrRecertifying$ | async,
            } as ctx"
        >
            <div class="col-sm-6 col-lg-3">
                <div class="card">
                    <div class="card-body">
                        <div class="card-title text-muted mb-0">Lifecycle Status</div>
                        <p
                            class="h4 text-bold mb-0 d-flex justify-content-between"
                            [ngClass]="{
                                'text-muted':
                                    globalCtx.isNotOnboarded || globalCtx.isOnboardedAndRecertificationIsDisabled,
                            }"
                        >
                            {{ globalCtx.lifecycleStatus }}
                            <em
                                *ngIf="ctx.isUpdatingArtifactsOrRecertifying"
                                class="fa fa-sync text-muted ms-auto"
                            ></em>
                        </p>
                    </div>
                    <div class="card-footer py-0">
                        <ng-container *ngIf="globalCtx.isOnboardedAndRecertificationIsDisabled">
                            <small class="text-muted">Not enabled</small>
                        </ng-container>
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-container
            *ngIf="{
                recertificationDate: recertificationDate$ | async,
                isUpForRecertification: isUpForRecertification$ | async,
                isRecertificationOverdue: isRecertificationOverdue$ | async,
                isAssessmentInProgress: isAssessmentInProgress$ | async,
                recertificationDueDaysLeftCount: recertificationDueDaysLeftCount$ | async,
            } as ctx"
        >
            <div class="col-sm-6 col-lg-3">
                <div
                    class="card"
                    [ngClass]="{ 'border-danger': ctx.isRecertificationOverdue && !ctx.isAssessmentInProgress }"
                >
                    <div class="card-body">
                        <div class="card-title text-muted mb-0">Recertification Date</div>
                        <p
                            class="h4 text-bold mb-0"
                            [ngClass]="{
                                'text-danger': ctx.isRecertificationOverdue && !ctx.isAssessmentInProgress,
                                'text-muted':
                                    globalCtx.isNotOnboarded ||
                                    ctx.isAssessmentInProgress ||
                                    globalCtx.isOnboardedAndRecertificationIsDisabled,
                            }"
                        >
                            <ng-container *ngIf="globalCtx.isNotOnboarded; else checkRecertificationInProgress">
                                {{ globalCtx.lifecycleStatus }}
                            </ng-container>
                            <ng-template #checkRecertificationInProgress>
                                <ng-container
                                    *ngIf="
                                        ctx.isAssessmentInProgress && ctx.isUpForRecertification;
                                        else recertificationDate
                                    "
                                >
                                    In Progress
                                </ng-container>
                            </ng-template>
                            <ng-template #recertificationDate>
                                {{
                                    globalCtx.isOnboardedAndRecertificationIsDisabled
                                        ? 'N/A'
                                        : (ctx.recertificationDate | date)
                                }}
                            </ng-template>
                        </p>
                    </div>
                    <div class="card-footer py-0">
                        <small
                            *ngIf="globalCtx.isOnboardedAndRecertificationIsDisabled; else recertificationEnabled"
                            class="text-muted"
                        >
                            Not enabled
                        </small>
                        <ng-template #recertificationEnabled>
                            <small
                                *ngIf="
                                    ctx.isUpForRecertification &&
                                    !ctx.isAssessmentInProgress &&
                                    (ctx.recertificationDueDaysLeftCount || ctx.isRecertificationOverdue)
                                "
                                class="text-danger"
                            >
                                <ng-container
                                    *ngIf="
                                        !!ctx.recertificationDueDaysLeftCount && !ctx.isRecertificationOverdue;
                                        else overdue
                                    "
                                >
                                    Due in {{ ctx.recertificationDueDaysLeftCount }} days
                                </ng-container>
                                <ng-template #overdue> Overdue </ng-template>
                            </small>
                        </ng-template>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</ng-container>
