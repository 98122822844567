import { AfterViewInit, Directive, ElementRef, Input, NgZone, OnDestroy } from '@angular/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { distinctUntilChanged, map, takeUntil } from 'rxjs/operators';

@Directive({
    selector: 'ngx-datatable',
})
export class NgxDatatableDirective implements AfterViewInit, OnDestroy {
    @Input('observeDims') observeDims = false;

    private _unsub$ = new Subject<void>();
    private _observer: ResizeObserver;
    private _datatableBodyElement: HTMLElement;
    private _datatableScroller: HTMLElement;
    private _datatableBodyElementWidth$ = new BehaviorSubject<number>(0);
    private _datatableScrollerWidth$ = new BehaviorSubject<number>(0);

    constructor(
        private _host: DatatableComponent,
        private _elementRef: ElementRef<HTMLElement>,
        private _zone: NgZone,
    ) {}

    ngOnInit(): void {
        combineLatest([this._datatableBodyElementWidth$, this._datatableScrollerWidth$])
            .pipe(
                map(([bodyWidth, scrollerWidth]) => scrollerWidth > bodyWidth),
                distinctUntilChanged(),
                takeUntil(this._unsub$),
            )
            .subscribe((isScrollerWiderThanBody) => {
                this._host._columnTemplates?.forEach((column) => {
                    if (typeof column.frozenRight !== 'undefined') {
                        column.frozenRight = isScrollerWiderThanBody;
                    }
                });
                this._host.translateColumns(this._host.columnTemplates);
            });
    }

    ngAfterViewInit(): void {
        this._datatableBodyElement = this._elementRef.nativeElement.querySelector('.datatable-body');
        this._datatableScroller = this._elementRef.nativeElement.querySelector('.datatable-scroll');

        this._observer = new ResizeObserver((entries) => {
            this._zone.run(() => {
                const bodyEntry = entries.find((entry) => entry.target === this._datatableBodyElement);
                const scrollerEntry = entries.find((entry) => entry.target === this._datatableScroller);
                if (bodyEntry) {
                    this._datatableBodyElementWidth$.next(bodyEntry.contentRect.width);
                }
                if (scrollerEntry) {
                    this._datatableScrollerWidth$.next(scrollerEntry.contentRect.width);
                }
            });
        });

        if (this.observeDims && !!this._datatableBodyElement && !!this._datatableScroller) {
            this._observer.observe(this._datatableBodyElement);
            this._observer.observe(this._datatableScroller);
        }
    }

    ngOnDestroy(): void {
        if (this.observeDims && !!this._datatableBodyElement && !!this._datatableScroller) {
            this._observer.unobserve(this._datatableBodyElement);
            this._observer.unobserve(this._datatableScroller);
        }
        this._unsub$.next();
    }
}
