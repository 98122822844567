import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { filter, tap } from 'rxjs';
import { SnackbarService } from '@shared/components/snackbar/snackbar.service';
import { AuthenticationFailedReasons } from '../../models';
import {
    extendAssessmentExpirationRequestSuccess,
    getPublicAssessmentRequestFailed,
    onWhenAddingFileFailed,
} from '../actions';

@Injectable()
export class FeedbackEffects {
    unknownErrorOnAssessmentAuthentication$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(getPublicAssessmentRequestFailed),
                filter(
                    ({ authenticationFailedReason }) =>
                        authenticationFailedReason === AuthenticationFailedReasons.UNKNOWN,
                ),
                tap(({ error }) => this._snackbarService.error(error.error.errors?.[0])),
            ),
        { dispatch: false },
    );

    onWhenAddingFileFailed$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(onWhenAddingFileFailed),
                tap(({ message }) => this._snackbarService.error(message)),
            ),
        { dispatch: false },
    );

    onWhenAssessmentExpirationExtended$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(extendAssessmentExpirationRequestSuccess),
                tap(() => this._snackbarService.success('Assessment deadline extended.')),
            ),
        { dispatch: false },
    );

    constructor(
        private _actions$: Actions,
        private _snackbarService: SnackbarService,
    ) {}
}
