<div class="modal-header text-center flex-column align-items-center">
    <strong class="text-muted m-0">{{ announcementType }}</strong>
    <h4 class="modal-title">{{ title }}</h4>
</div>
<div class="modal-body">
    <quill-view [content]="message" format="html" theme="snow" [sanitize]="false"></quill-view>
    <ngb-carousel
        *ngIf="!!images && images.length"
        [showNavigationArrows]="hasManyImages"
        [showNavigationIndicators]="hasManyImages"
        [ngClass]="{ 'no-controls': !hasManyImages }"
        class="mt-2"
    >
        <ng-template ngbSlide *ngFor="let image of images; let index = index">
            <img class="img-fluid border" [src]="image" />
        </ng-template>
    </ngb-carousel>
</div>
<div class="modal-footer justify-content-center border-top-0">
    <a
        *ngIf="externalLinkLabel && externalLinkUrl"
        mat-button
        visoButton="secondary"
        [externalLink]="externalLinkUrl"
        >{{ externalLinkLabel }}</a
    >
    <button mat-button visoButton="primary" (click)="dismiss()">Got it</button>
</div>
