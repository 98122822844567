<div class="modal-header">
    <h4 class="modal-title">Delete Contact</h4>
    <button type="button" class="btn-close" data-dismiss="modal" aria-hidden="true" (click)="clear()"></button>
</div>

<div *ngIf="contact.subscriptionCount > 0 || contact.businessOwnerCount > 0">
    <p class="mx-3 mt-3 pb-0">
        <span
            >In order to delete <span class="text-bold">{{ contactFullName }}</span
            >, please reassign their owned relationships. Select a contact to reassign these to.</span
        >
    </p>
    <app-contact-reassign-modal [contactId]="contact.id" [onlyShowReassignmentForm]="true"></app-contact-reassign-modal>
</div>

<div *ngIf="contact.subscriptionCount == 0 && contact.businessOwnerCount == 0" class="modal-header mb-0">
    <p>
        Are you sure you want to delete
        <span class="text-bold">{{ contactFullName }}</span
        >?
    </p>
</div>
<hr *ngIf="contact.subscriptionCount > 0 || contact.businessOwnerCount > 0" class="mt-0" />
<div class="d-block justify-content-start main-action m-3">
    <button
        mat-button
        visoButton="primary"
        class="float-end mb-3"
        toggleDisableOnActions
        [disablingActions]="[ContactActions.DeleteContactRequest, ContactActions.ReassignContactRelationshipsRequest]"
        [enablingActions]="[
            ContactActions.DeleteContactRequestSuccess,
            ContactActions.DeleteContactRequestFailed,
            ContactActions.ReassignContactRelationshipsRequestFailed,
        ]"
        [disabled]="contactReassignModalComponent?.contactReassignFormGroup.invalid"
        (click)="deleteContact()"
    >
        <span *ngIf="contact.subscriptionCount > 0 || contact.businessOwnerCount > 0">Reassign and </span>
        <span>Delete Contact</span>
    </button>
    <button
        type="button"
        mat-button
        visoButton="secondary"
        class="float-end mb-3 me-2"
        data-dismiss="modal"
        (click)="clear()"
    >
        <span>Cancel</span>
    </button>
</div>
