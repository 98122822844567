import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { AppState } from '@shared/redux/state';
import { RiskModelSettingsState, riskModelSettingsStateKey } from './risk-model-settings.state';
import { RelationshipRiskToleranceView, RiskTolerance } from '@entities/risk-tolerance';
import { Risk } from '@entities/risk-assessment';

export interface RiskThresholdData {
    score: number;
    threshold: string;
}

export function getRiskThresholdText(thresholdsMap: { [key: string]: number }, currentRiskLevel: Risk): string {
    switch (currentRiskLevel) {
        case Risk.NO_CONTEXT:
            return '0%';
        case Risk.LOW:
            return `0% > ${Math.round(thresholdsMap[currentRiskLevel] * 100)}%`;
        case Risk.MEDIUM:
            return `${Math.round(thresholdsMap[Risk.LOW] * 100)}% > ${Math.round(thresholdsMap[currentRiskLevel] * 100)}%`;
        case Risk.HIGH:
            return `${Math.round(thresholdsMap[Risk.MEDIUM] * 100)}% > ${Math.round(thresholdsMap[currentRiskLevel] * 100)}%`;
        case Risk.EXTREME:
            return `${Math.round(thresholdsMap[Risk.HIGH] * 100)}% > ${Math.round(thresholdsMap[currentRiskLevel] * 100)}%`;
    }
}

export const getRiskModelSettingsState: MemoizedSelector<AppState, RiskModelSettingsState> =
    createFeatureSelector<RiskModelSettingsState>(riskModelSettingsStateKey);

export const getRiskToleranceThresholds: MemoizedSelector<
    AppState,
    Map<RiskTolerance, Map<Risk, RiskThresholdData>>
> = createSelector(getRiskModelSettingsState, (state) => {
    return new Map(
        Object.entries(state.riskToleranceThresholds).map(([riskTolerance, value]) => [
            RiskTolerance[riskTolerance],
            new Map(
                Object.entries(value).map(([riskLevel, score]) => {
                    return [
                        Risk[riskLevel],
                        {
                            score,
                            threshold: getRiskThresholdText(value, Risk[riskLevel]),
                        },
                    ];
                }),
            ),
        ]),
    );
});

export const getRelationshipRiskDistribution: MemoizedSelector<
    AppState,
    Map<Risk, RelationshipRiskToleranceView[]>
> = createSelector(
    getRiskModelSettingsState,
    (state) => new Map(Object.entries(state.relationshipRiskDistribution).map(([risk, views]) => [Risk[risk], views])),
);
