import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FileArtifact } from '@entities/artifact';
import { FileLikeObject, FileUploader } from '@shared/file-upload';
import { FilterFunction } from '@shared/file-upload/interfaces';

@Component({
    selector: 'app-supplemental-questionnaire-links',
    templateUrl: './supplemental-questionnaire-links.component.html',
    styleUrls: ['./supplemental-questionnaire-links.component.scss'],
})
export class SupplementalQuestionnaireLinksComponent {
    @Input({ required: true })
    supplementalQuestionnaireLinks!: string[];

    @Input()
    supplementalArtifacts: FileArtifact[] = [];

    @Input()
    contactEmail?: string;

    @Output()
    uploadArtifacts = new EventEmitter<File[]>();

    @Output()
    uploadFailed = new EventEmitter<FilterFunction>();

    @Output()
    removeArtifact = new EventEmitter<FileArtifact>();

    @Output()
    downloadWithAnchorElement = new EventEmitter<string>();

    uploader: FileUploader;

    ngOnInit(): void {
        this.setupFileUploader();
    }

    private setupFileUploader(): void {
        this.uploader = new FileUploader({
            url: '',
            maxFileSize: 100000000,
        });

        this.uploader.onWhenAddingFileFailed = (item: FileLikeObject, filter: any, options: any) =>
            this.uploadFailed.emit(filter);
    }

    downloadFile(url: string): void {
        this.downloadWithAnchorElement.emit(url);
    }

    deleteFileArtifact(artifact: FileArtifact): void {
        this.removeArtifact.emit(artifact);
    }
}
