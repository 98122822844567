import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { VisoButtonColors, VisoButtonTypes } from '@shared/viso-button/viso-button.directive';

@Component({
    selector: 'app-mat-confirm-dialog',
    templateUrl: './mat-confirm-dialog.component.html',
    styleUrls: ['./mat-confirm-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MatConfirmDialogComponent {
    @Input({ required: true })
    title: string;

    @Input()
    subtitle?: string;

    @Input({ required: true })
    message: string;

    @Input()
    onlyConfirmButton?: boolean;

    // Confirm button
    @Input()
    confirmLabel = 'Accept';

    @Input()
    confirmLabelIcon?: string;

    @Input()
    confirmButtonStyle?: VisoButtonTypes = 'primary';

    @Input()
    confirmButtonColor?: VisoButtonColors = 'primary';

    // Cancel button
    @Input()
    cancelLabel = 'Cancel';

    @Input()
    cancelLabelIcon?: string;

    @Input()
    cancelButtonStyle?: VisoButtonTypes = 'secondary';

    @Input()
    cancelButtonColor?: VisoButtonColors = 'primary';

    constructor(private _activeDialog: MatDialogRef<MatConfirmDialogComponent>) {}

    confirm(confirmMessage?: string): void {
        this._activeDialog.close(confirmMessage || true);
    }

    cancel(): void {
        this._activeDialog.close(false);
    }
}
