import { Injectable } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { UserStatus, VisoUser, VisoUserGroupLabels } from '../../../../entities/viso-user';
import { QuillMentionListItem } from '../quill.model';

@Injectable({
    providedIn: 'root',
})
export class QuillMentionParseHelperService {
    private _quillMentionRegex = /(<span class=\S+mention\S+(?:[^>]*)?>.*?<\/span>.*?<\/span>.*?<\/span>)/g;
    private _visoMentionRegex = /(<@[UG].*?>)/g;

    constructor(private _domSanitizer: DomSanitizer) {}

    convertHtmlMentions(htmlContent: string): string {
        const mentionMatches = htmlContent.match(this._quillMentionRegex);
        let convertedHtmlContent = htmlContent;

        if (!mentionMatches) {
            return htmlContent;
        }

        for (let i = 0; i < mentionMatches.length; i++) {
            const match = mentionMatches[i];
            convertedHtmlContent = convertedHtmlContent.replace(match, this.convertHtmlMentionMatch(match));
        }

        return convertedHtmlContent;
    }

    convertVisoMentions(htmlContent: string, visoUsers: VisoUser[]): SafeHtml {
        const mentionMatches = htmlContent.match(this._visoMentionRegex);
        let convertedHtmlContent = htmlContent;

        if (!mentionMatches) {
            return this._domSanitizer.bypassSecurityTrustHtml(htmlContent);
        }

        for (let i = 0; i < mentionMatches.length; i++) {
            const match = mentionMatches[i];
            convertedHtmlContent = convertedHtmlContent.replace(
                match,
                this.convertVisoMentionMatch(match, i, visoUsers),
            );
        }

        return this._domSanitizer.bypassSecurityTrustHtml(convertedHtmlContent);
    }

    private convertHtmlMentionMatch(htmlMentionMatch: string): string {
        const mentionMatchChunks = htmlMentionMatch.split('data-');
        const id = mentionMatchChunks.find((chunk) => chunk.startsWith('id'))?.split('"')?.[1];

        return `<@${id}>`;
    }

    private convertVisoMentionMatch(visoMentionTag: string, index: number, visoUsers: VisoUser[]): string {
        const id = +visoMentionTag.replace(/(<@[UG])/, '').replace('>', '');
        let value: string;
        let link: string;
        const isUser = visoMentionTag.startsWith('<@U');
        if (isUser) {
            const user = visoUsers.find((user) => user.id === id);
            value = `${user?.firstName} ${user?.lastName} ${user.status === UserStatus.INACTIVE ? ' (Inactive)' : ''}`;
            link = `user-profile/${user?.id}`;
        } else {
            value = VisoUserGroupLabels[Object.keys(VisoUserGroupLabels)[id]];
        }
        return this.buildQuillMentionTag(
            {
                id: id,
                value,
                link,
            } as QuillMentionListItem,
            index,
        );
    }

    private buildQuillMentionTag(mentionData: QuillMentionListItem, index: number): string {
        const { id, value, link } = mentionData;
        const actualValue = !!link
            ? `<a href='/user-profile/${id}' target='_blank' onClick=window.open(event.target.href)>${value}</a>`
            : value;
        const linkDataAttr = !!link ? `data-link='/user-profile/${id}'` : '';
        return `
            <span class="mention" data-index="${index}" data-denotation-char="@" data-id="${id}" data-value="${actualValue}" ${linkDataAttr}>
                <span contenteditable="false">
                    <span class="ql-mention-denotation-char">@</span>
                    ${actualValue}
                </span>
            </span>
        `;
    }
}
