import { createAction, props } from '@ngrx/store';
import { RelationshipComment } from 'src/main/webapp/app/entities/relationship-comment';

export enum CommentActions {
    GetRequestCommentsRequest = '[Request] Get Request Comments Request',
    GetRequestCommentsRequestSuccess = '[Request] Get Request Comments Request Success',
    GetRequestCommentsRequestFailed = '[Request] Get Request Comments Request Failed',
    CreateRequestCommentRequest = '[Request] Create Request Comment Request',
    CreateRequestCommentRequestSuccess = '[Request] Create Request Comment Request Success',
    CreateRequestCommentRequestFailed = '[Request] Create Request Comment Request Failed',
    DeleteRequestCommentRequest = '[Request] Delete Request Comment Request',
    DeleteRequestCommentRequestSuccess = '[Request] Delete Request Comment Request Success',
    DeleteRequestCommentRequestFailed = '[Request] Delete Request Comment Request Failed',
}

export const getRequestCommentsRequest = createAction(
    CommentActions.GetRequestCommentsRequest,
    props<{ requestId: number }>(),
);

export const getRequestCommentsRequestSuccess = createAction(
    CommentActions.GetRequestCommentsRequestSuccess,
    props<{ comments: RelationshipComment[] }>(),
);

export const getRequestCommentsRequestFailed = createAction(CommentActions.GetRequestCommentsRequestFailed);

export const createRequestCommentRequest = createAction(
    CommentActions.CreateRequestCommentRequest,
    props<{ requestId: number; commentMessage: string }>(),
);

export const createRequestCommentRequestSuccess = createAction(CommentActions.CreateRequestCommentRequestSuccess);

export const createRequestCommentRequestFailed = createAction(CommentActions.CreateRequestCommentRequestFailed);

export const deleteRequestCommentRequest = createAction(
    CommentActions.DeleteRequestCommentRequest,
    props<{ requestId: number; commentId: number }>(),
);

export const deleteRequestCommentRequestSuccess = createAction(CommentActions.DeleteRequestCommentRequestSuccess);

export const deleteRequestCommentRequestFailed = createAction(CommentActions.DeleteRequestCommentRequestFailed);
