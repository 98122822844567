import { Routes } from '@angular/router';

import {
    logsRoute,
    demoToolsRoute,
    riskModelRoute,
    assessmentRecommendationRoute,
    elasticsearchIndexRoute,
    artifactManagementRoute,
    orgImportRoute,
    secImportRoute,
    webhooksRoute,
    clientProfileRoute,
    licenseManagementRoute,
    notificationsRoute,
    clientOffboardingRoute,
    jwtManagementRoute,
    announcementsRoute,
    featureFlagManagementRoute,
    hubspotManagementRoute,
} from './';

import { AuthGuard } from '../shared';
import { VisoUserRole } from '../entities/viso-user';

const ADMIN_ROUTES = [
    logsRoute,
    demoToolsRoute,
    riskModelRoute,
    assessmentRecommendationRoute,
    elasticsearchIndexRoute,
    artifactManagementRoute,
    orgImportRoute,
    secImportRoute,
    webhooksRoute,
    licenseManagementRoute,
    notificationsRoute,
    jwtManagementRoute,
    announcementsRoute,
    featureFlagManagementRoute,
    hubspotManagementRoute,
    clientProfileRoute,
    clientOffboardingRoute,
];

export const adminState: Routes = [
    {
        path: 'admin',
        data: {
            authorities: [VisoUserRole.Admin, VisoUserRole.Support],
        },
        canActivate: [AuthGuard],
        children: ADMIN_ROUTES,
    },
];
