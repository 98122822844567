import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { LifecycleManagementFormGroup } from '../../models/lifecycle-management.model';
import { getUserAccount } from '../../../session/redux/session.selectors';
import { first } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { LicenseType, VisoUserRole } from '@entities/viso-user';
import { RecertificationType, Relationship, ReviewFrequency } from '@entities/relationship';
import { LifecycleManagementHelperService } from '../../services/lifecycle-management-helper.service';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-relationship-onboarding-modal',
    templateUrl: './relationship-onboarding-modal.component.html',
    styleUrls: ['./relationship-onboarding-modal.component.scss'],
    providers: [LifecycleManagementHelperService],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RelationshipOnboardingModalComponent implements OnInit {
    @Input({ required: true })
    relationship: Relationship;

    @Input({ required: true })
    vendorName: string;

    @Input({ required: true })
    dateOfNextArtifactExpiration: Date;

    @Input({ required: true })
    latestCompletedAssessmentDate?: Date;

    Roles = VisoUserRole;

    userIsTrial: boolean;

    clientLicenseHasAvailableManagedVendors = true;

    onboardingFormGroup: FormGroup<{
        lifecycleManagementFormGroup: FormGroup<LifecycleManagementFormGroup>;
        approvalSummary: FormControl<string>;
    }>;

    constructor(
        private _dialogRef: MatDialogRef<RelationshipOnboardingModalComponent>,
        private _store$: Store,
        private _fb: FormBuilder,
        private _lifecycleManagementHelper: LifecycleManagementHelperService,
    ) {}

    ngOnInit(): void {
        this.onboardingFormGroup = this._fb.group({
            lifecycleManagementFormGroup: this._fb.group({
                artifactUpdateSettings: this._fb.group({
                    artifactUpdateEnabled: this._fb.control(
                        this.relationship.artifactUpdateType !== RecertificationType.NONE,
                    ),
                    artifactUpdateType: this._fb.control(this.relationship.artifactUpdateType),
                }),
                recertificationSettings: this._fb.group({
                    recertificationEnabled: this._fb.control(
                        this.relationship.recertificationType !== RecertificationType.NONE,
                    ),
                    basedOnDate: this._fb.control(
                        this._lifecycleManagementHelper.computeBasedOnDate(
                            this.relationship.recertificationDate,
                            this.latestCompletedAssessmentDate,
                            this.relationship.reviewFrequency,
                        ),
                    ),
                    recertificationType: this._fb.control(this.relationship.recertificationType),
                    reviewFrequency: this._fb.control(this.relationship.reviewFrequency ?? ReviewFrequency.ANNUAL),
                    recertificationDate: this._fb.control(this.relationship.recertificationDate),
                }),
            }),
            approvalSummary: this._fb.control(null),
        });
        this._store$
            .pipe(
                select(getUserAccount),
                first(),
                map((account) => account?.clientLicense?.licenseType === LicenseType.TRIAL),
                filter((userIsTrial) => userIsTrial),
            )
            .subscribe(() => {
                this.onboardingFormGroup.controls.lifecycleManagementFormGroup.disable();
                this.userIsTrial = true;
            });

        this._store$
            .pipe(
                select(getUserAccount),
                first(),
                map(
                    (account) =>
                        account?.clientLicense?.maxRelationshipsWithLifecycleManagement -
                            account?.clientLicense?.relationshipsWithLifecycleManagementCount <=
                            0 && account?.clientLicense?.maxRelationshipsWithLifecycleManagement !== -1,
                ),
                filter((clientLicenseHasAvailableManagedVendors) => clientLicenseHasAvailableManagedVendors),
            )
            .subscribe(() => {
                this.onboardingFormGroup.controls.lifecycleManagementFormGroup.disable();
                this.clientLicenseHasAvailableManagedVendors = false;
            });
    }

    cancel(): void {
        this._dialogRef.close();
    }

    confirm(): void {
        const approvalSummary = this.onboardingFormGroup.controls.approvalSummary.getRawValue();
        const lifecycleManagementUpdateRequest =
            this.userIsTrial || !this.clientLicenseHasAvailableManagedVendors
                ? null
                : { ...this.onboardingFormGroup.controls.lifecycleManagementFormGroup.getRawValue() };

        this._dialogRef.close({
            lifecycleManagementUpdateRequest,
            approvalSummary,
        });
    }
}
