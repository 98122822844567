import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { QuillMentionListItem } from '../quill.model';

@Injectable()
export class QuillMentionModuleHelperService {
    generateMentionModule(mentionList$: BehaviorSubject<QuillMentionListItem[]>) {
        return {
            allowedChars: /^[A-Za-z0-9\sÅÄÖåäö]*$/,
            source: (searchTerm, renderList) => {
                const mentionList = mentionList$.value;
                if (!mentionList) {
                    return;
                }
                if (searchTerm.length === 0) {
                    renderList(mentionList, searchTerm);
                } else {
                    renderList(
                        mentionList.filter(
                            (mention) => mention.value.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1,
                        ),
                        searchTerm,
                    );
                }
            },
        };
    }
}
