import { UntypedFormControl } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';

export function hasDomainValidator(domains: string[] | BehaviorSubject<string[]>) {
    return (control: UntypedFormControl) => {
        if (control && !control.value) {
            return null;
        }

        const emailDomain = control.value.trim().toLowerCase().replaceAll(/.*@/g, '');

        const _domains = domains instanceof BehaviorSubject ? domains.value : domains;

        for (const domain of _domains) {
            if (emailDomain === domain) return null;
        }

        return { invalidEmailDomain: true };
    };
}
