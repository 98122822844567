import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function specialCharactersValidator(options?: {
    customRegex?: RegExp;
    type?: 'include' | 'exclude';
}): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        if (control && !control.value) {
            return null;
        }

        const regex = options?.customRegex || /^[A-Za-z0-9 ]*$/;

        if (options?.type === 'include') {
            return regex.test(control.value) ? { specialCharacters: { included: true } } : null;
        }

        return regex.test(control.value) ? null : { specialCharacters: { excluded: true } };
    };
}
