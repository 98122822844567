import { createAction, props } from '@ngrx/store';
import { SecurityControlDomain } from '../../../../entities/relationship/models/security-control-domain';
import { AllControlDetectionsToggleTrack, ControlDetectionToggleTrack } from '../../models';

export enum SecurityControlDomainActions {
    GetRelationshipSecurityControlDomainRequest = '[Request] Get Relationship Security Control Domain Request',
    GetRelationshipSecurityControlDomainRequestSuccess = '[Request] Get Relationship Security Control Domain Request Success',
    GetRelationshipSecurityControlDomainRequestFailed = '[Request] Get Relationship Security Control Domain Request Failed',
    TrackSecurityControlDomainControlDetectionToggle = '[Request] Track Security Control Domain Control Detection Toggle',
    TrackSecurityControlDomainAllControlDetectionsToggle = '[Request] Track Security Control Domain All Control Detections Toggle',
}

export const getRelationshipSecurityControlDomainRequest = createAction(
    SecurityControlDomainActions.GetRelationshipSecurityControlDomainRequest,
    props<{ requestId: number }>(),
);

export const getRelationshipSecurityControlDomainRequestSuccess = createAction(
    SecurityControlDomainActions.GetRelationshipSecurityControlDomainRequestSuccess,
    props<{ securityControlDomain: SecurityControlDomain }>(),
);

export const getRelationshipSecurityControlDomainRequestFailed = createAction(
    SecurityControlDomainActions.GetRelationshipSecurityControlDomainRequestFailed,
);

export const trackSecurityControlDomainControlDetectionToggle = createAction(
    SecurityControlDomainActions.TrackSecurityControlDomainControlDetectionToggle,
    props<ControlDetectionToggleTrack>(),
);

export const trackSecurityControlDomainAllControlDetectionsToggle = createAction(
    SecurityControlDomainActions.TrackSecurityControlDomainAllControlDetectionsToggle,
    props<AllControlDetectionsToggleTrack>(),
);
