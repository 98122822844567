import { JsonPipe, Location } from '@angular/common';
import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/main/webapp/environments/environment';

export interface ErrorModalData {
    error: any;
    errorSubheader?: string;
    errorMainMessage?: string;
    errorMainMessageHtml?: string;
    errorMainData?: Map<string, string>;
    errorDetails?: {};
    buttonText?: string;
    defaultDismiss?: boolean;
    showModal?: boolean;
}

@Component({
    selector: 'global-error-modal',
    templateUrl: 'global-error-modal.component.html',
    styleUrls: ['./global-error-modal.component.scss'],
})
export class GlobalErrorModalComponent {
    @ViewChild('mainData', { static: true }) mainData: ElementRef;
    @ViewChild('moreInfo', { static: true }) moreInfo: ElementRef;
    @ViewChild('moreInfoBtn', { read: ElementRef, static: false }) moreInfoBtn: ElementRef;

    @Input() data: ErrorModalData;

    errorMainDataStr: string;
    errorDetailsStr: string;

    isProduction = environment.production;

    get window(): Window {
        return window;
    }

    constructor(
        private location: Location,
        private _activeModal: NgbActiveModal,
    ) {
        // try run manually in case angular renderer crashed and is not able to process lifecycle events
        setTimeout(() => {
            try {
                this.ngAfterViewInit();
            } catch (e) {}
        });
    }

    prepareErrorStrings() {
        // in case angular renderer crashed, no directives will work, so need to build HTML manually
        if (this.data.errorMainData && this.data.errorMainData.size) {
            this.errorMainDataStr = '';
            this.data.errorMainData.forEach((value, key, map) => {
                this.errorMainDataStr += `<div><span>${key}:</span> <span>${value}</span></div>`;
            });
        }

        if (this.data.errorDetails !== null && this.data.errorDetails !== undefined) {
            try {
                this.errorDetailsStr = new JsonPipe().transform(this.data.errorDetails);
            } catch (e) {
                try {
                    this.errorDetailsStr = this.data.errorDetails.toString();
                } catch (e2) {
                    // cannot format error details in any way
                    console.error(e);
                    console.error(e2);
                }
            }
        }
    }

    // this event won't be called in case angular renderer has crashed
    ngAfterViewInit() {
        if (this.errorMainDataStr) {
            this.mainData.nativeElement.innerHTML = this.errorMainDataStr;
            this.mainData.nativeElement.classList.remove('d-none');
        }

        if (!this.errorDetailsStr) {
            this.moreInfoBtn.nativeElement.classList.add('d-none');
        }
    }

    showMoreInfo() {
        if (this.errorDetailsStr) {
            this.moreInfo.nativeElement.innerText = this.errorDetailsStr;
        }

        this.moreInfo.nativeElement.classList.remove('d-none');
        this.moreInfoBtn.nativeElement.classList.add('d-none');
    }

    reloadApp() {
        this.window.location.href = this.location.prepareExternalUrl('/');
        this.window.location.reload();
    }

    closeModal() {
        this._activeModal.close();
    }
}
