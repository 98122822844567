import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { SharedModule } from '../../shared';
import { questionnaireRoutes } from '.';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxSelectModule } from 'ngx-select-ex';
import { EffectsModule } from '@ngrx/effects';
import { MatButtonModule } from '@angular/material/button';
import { QuestionnaireComponent } from './questionnaire.component';
import { QuestionnaireEffects } from './redux/questionnaire.effects';
import { TextFieldModule } from '@angular/cdk/text-field';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTabsModule } from '@angular/material/tabs';
import { ArtifactIntelligenceModule } from '../../shared/artifact-intelligence';
import { QuestionnaireResponseComponent } from './questionnaire-response';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { QuestionnaireEditComponent } from './questionnaire-edit/questionnaire-edit.component';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';
import { MatSnackBarModule } from '@angular/material/snack-bar';

const ROUTES = [...questionnaireRoutes];

@NgModule({
    imports: [
        SharedModule,
        RouterModule.forChild(ROUTES),
        FormsModule,
        ReactiveFormsModule,
        NgxSelectModule,
        EffectsModule.forFeature([QuestionnaireEffects]),
        MatButtonModule,
        TextFieldModule,
        MatExpansionModule,
        MatTabsModule,
        ArtifactIntelligenceModule,
        MatSidenavModule,
        MatListModule,
        MatInputModule,
        MatFormFieldModule,
        MatCardModule,
        MatSnackBarModule,
    ],
    declarations: [QuestionnaireComponent, QuestionnaireResponseComponent, QuestionnaireEditComponent],
})
export class QuestionnaireModule {}
