import { Injectable } from '@angular/core';
import { Artifact, ArtifactType, ArtifactValidationStatus } from '@entities/artifact';
import { AuditReportTypeCode, AuditReportTypeName } from '@entities/audit-report';
import { VisoUser, VisoUserRole } from '@entities/viso-user';

@Injectable({
    providedIn: 'root',
})
export class ArtifactUtilsService {
    getArtifactListDisplayName(artifact: Artifact): string {
        if (artifact.type.toLowerCase() === ArtifactType.QUESTIONNAIRE_ARTIFACT) {
            return 'Questionnaire';
        }
        if (!!artifact.validation) {
            const validationInProgressAndImmaterial =
                artifact.validation.status === ArtifactValidationStatus.IN_PROGRESS &&
                artifact.validation.auditReportType === AuditReportTypeCode.NONE;
            if (!validationInProgressAndImmaterial) {
                return AuditReportTypeName[artifact.validation.auditReportType || AuditReportTypeCode.NONE];
            }
        }
        return artifact.thirdPartyProvidedAuditType ?? artifact.artifactTypeName;
    }

    canUserDeleteArtifact(user: VisoUser, artifact: Artifact) {
        return user.authorities.some(
            (role) => VisoUserRole.Support.includes(role) || VisoUserRole.ReadOnly.includes(role),
        )
            ? artifact.creatorId === user.id
            : true;
    }
}
