import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgxSelectModule } from 'ngx-select-ex';

import { SharedModule } from '../../shared';
import {
    ContactPopupService,
    ContactDialogComponent,
    ContactPopupComponent,
    ContactDeleteDialogComponent,
    contactPopupRoute,
    ContactReassignModalComponent,
    ContactStatusDialogComponent,
} from '.';
import { VisoCheckboxGroupModule } from '../../shared/viso-checkbox-group/viso-checkbox-group.module';

const ENTITY_STATES = [...contactPopupRoute];

@NgModule({
    imports: [SharedModule, RouterModule.forChild(ENTITY_STATES), NgxSelectModule, VisoCheckboxGroupModule],
    declarations: [
        ContactDialogComponent,
        ContactDeleteDialogComponent,
        ContactPopupComponent,
        ContactReassignModalComponent,
        ContactStatusDialogComponent,
    ],
    providers: [ContactPopupService],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SubscriberModule {}
