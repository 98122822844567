import { Component, OnInit } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { tap, takeUntil } from 'rxjs/operators';
import { ImportJobView } from '../import-job.model';
import {
    confirmUploadedImportRequestSuccess,
    downloadImportedFileRequest,
    getImportHistoryRequest,
    getImportHistoryRequestSuccess,
} from '../redux/bulk-import.actions';

@Component({
    selector: 'app-import-history',
    templateUrl: './import-history.component.html',
    styleUrls: ['./import-history.component.scss'],
})
export class ImportHistoryComponent implements OnInit {
    imports: ImportJobView[];

    private _unsub: Subject<void> = new Subject<void>();

    constructor(
        private _store$: Store,
        private _actions$: Actions,
    ) {}

    ngOnInit(): void {
        this.imports = [];

        this.loadImportHistory();

        this._actions$
            .pipe(
                ofType(confirmUploadedImportRequestSuccess),
                tap(() => this.loadImportHistory()),
                takeUntil(this._unsub),
            )
            .subscribe();

        this._actions$
            .pipe(
                ofType(getImportHistoryRequestSuccess),
                tap(({ importHistory }) => (this.imports = importHistory)),
                takeUntil(this._unsub),
            )
            .subscribe();
    }

    loadImportHistory(): void {
        this._store$.dispatch(getImportHistoryRequest());
    }

    downloadImportedFile(importJobId: number): void {
        this._store$.dispatch(downloadImportedFileRequest({ importJobId }));
    }
}
