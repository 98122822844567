import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class AssessmentRecommendationService {
    private resourceUrl = 'api/recommendations';

    constructor(private http: HttpClient) {}

    refreshRecommendations(orgId?: number): Observable<void> {
        return this.http.post<void>(`${this.resourceUrl}/refresh`, { orgId });
    }
}
