import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AssessmentRecommendation } from '@entities/recommendation';
import { Risk, RiskAssessment } from '@entities/risk-assessment';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { FormUtilsService } from '@shared/utils/form-utils.service';
import { RemediationRequest } from '@entities/remediation-request/remediation-request.model';
import { RemediationUtilsService } from '@shared/utils/remediation-utils.service';
import { PrimaryVendorContact } from '../../../primary-vendor-contact';

export enum ReviewRiskDialogResultAction {
    ACCEPT_RISK,
    REQUEST_REMEDIATION,
    CANCEL_REMEDIATION,
    REVOKE_RISK_ACCEPTANCE,
}

export type ReviewRiskDialogResult = {
    action: ReviewRiskDialogResultAction;
    data?: {
        riskOverride?: Risk;
        riskReviewNote?: string;
    };
};

export type RiskReviewForm = {
    riskOverride: FormControl<Risk>;
    riskReviewNote: FormControl<string>;
};

@Component({
    selector: 'app-review-risk-dialog',
    templateUrl: './review-risk-dialog.component.html',
    styleUrls: ['./review-risk-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReviewRiskDialogComponent implements OnInit {
    @Input({ required: true })
    riskReviewNote: string;

    @Input({ required: true })
    primaryContact: PrimaryVendorContact;

    @Input({ required: true })
    vendorName: string;

    @Input({ required: true })
    recommendations: AssessmentRecommendation[];

    @Input({ required: true })
    remediationRequest: RemediationRequest;

    @Input({ required: true })
    isRiskAccepted: boolean;

    @Input({ required: true })
    latestRiskAssessment: RiskAssessment;

    @Input({ required: true })
    riskOverrideLastAppliedByFullName: string;

    @Input({ required: true })
    riskOverrideLastAppliedDate: Date;

    @Input({ required: true })
    latestAssessmentInProgress: boolean;

    riskReviewFormGroup: FormGroup<RiskReviewForm>;

    get inherentRisk(): Risk {
        return this.latestRiskAssessment?.inherentRisk;
    }

    get residualRisk(): Risk {
        return this.latestRiskAssessment?.legacy
            ? this.latestRiskAssessment?.riskScores.SECURITY.residualRiskLevel
            : this.latestRiskAssessment?.risk;
    }

    get awaitingRiskReview(): boolean {
        return !this.isRiskAccepted && !this.isRemediationActive;
    }

    get riskOverride(): Risk {
        return this.latestRiskAssessment.legacy ? this.latestRiskAssessment?.risk : null;
    }

    get isRemediationActive(): boolean {
        return this._remediationUtils.isRemediationActive(this.remediationRequest?.status);
    }

    get isRemediationDeclined(): boolean {
        return this._remediationUtils.isRemediationDeclined(this.remediationRequest?.status);
    }

    get areThereRecommendations(): boolean {
        return !!this.recommendations?.length;
    }

    get recommendationsText(): string {
        return this.areThereRecommendations
            ? `In order to reduce the risk of this relationship, we recommend that ${this.vendorName} address the following gaps in coverage:`
            : 'There are no recommendations for this relationship.';
    }

    get showCancelRemediationButton(): boolean {
        return this.isRemediationActive;
    }

    get showRevokeRiskAcceptanceButton(): boolean {
        return this.isRiskAccepted;
    }

    get showRequestRemediationButton(): boolean {
        return !this.latestAssessmentInProgress && this.awaitingRiskReview && this.areThereRecommendations;
    }

    get showRecommendations(): boolean {
        return !this.latestAssessmentInProgress || this.isRemediationActive;
    }

    get disableRequestRemediationButton(): boolean {
        return !this.primaryContact;
    }

    constructor(
        private _dialogRef: MatDialogRef<ReviewRiskDialogComponent, ReviewRiskDialogResult>,
        private _fb: FormBuilder,
        private _formUtils: FormUtilsService,
        private _remediationUtils: RemediationUtilsService,
    ) {}

    ngOnInit(): void {
        this.riskReviewFormGroup = this._fb.group({
            riskOverride: [this.riskOverride],
            riskReviewNote: [this.riskReviewNote],
        });
    }

    acceptRisk(): void {
        const { riskOverride, riskReviewNote } = this._formUtils.getCleanTypedFormGroupValue(this.riskReviewFormGroup);
        this._dialogRef.close({
            action: ReviewRiskDialogResultAction.ACCEPT_RISK,
            data: { riskOverride, riskReviewNote },
        });
    }

    requestRemediation(): void {
        const { riskReviewNote } = this._formUtils.getCleanTypedFormGroupValue(this.riskReviewFormGroup);
        this._dialogRef.close({
            action: ReviewRiskDialogResultAction.REQUEST_REMEDIATION,
            data: { riskReviewNote },
        });
    }

    cancelRemediationRequest(): void {
        const { riskReviewNote } = this._formUtils.getCleanTypedFormGroupValue(this.riskReviewFormGroup);
        this._dialogRef.close({
            action: ReviewRiskDialogResultAction.CANCEL_REMEDIATION,
            data: { riskReviewNote },
        });
    }

    revokeRiskAcceptance(): void {
        const { riskReviewNote } = this._formUtils.getCleanTypedFormGroupValue(this.riskReviewFormGroup);
        this._dialogRef.close({
            action: ReviewRiskDialogResultAction.REVOKE_RISK_ACCEPTANCE,
            data: { riskReviewNote },
        });
    }
}
