import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToggleDisableOnActionsDirective } from './toggle-disable-on-actions.directive';

@NgModule({
    declarations: [ToggleDisableOnActionsDirective],
    imports: [CommonModule],
    exports: [ToggleDisableOnActionsDirective],
})
export class ToggleDisableOnActionsModule {}
