import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { TagColorDirective } from './directives/tag-color.directive';
import { TagActionsContentDirective } from './directives/tag-actions-content.directive';
import { TagSelectComponent } from './components/tag-select/tag-select.component';

@NgModule({
    declarations: [TagColorDirective, TagActionsContentDirective, TagSelectComponent],
    imports: [CommonModule, ReactiveFormsModule],
    exports: [TagColorDirective, TagActionsContentDirective, TagSelectComponent],
})
export class TagsModule {}
