import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { logrocketMetareducer } from './logrocket.metareducer';

@NgModule({
    imports: [
        StoreModule.forFeature(
            'log-rocket',
            {},
            {
                metaReducers: [logrocketMetareducer],
            },
        ),
    ],
})
export class LogRocketModule {}
