<div class="notification-body">
    <div *ngIf="isNew" class="notification-new"></div>
    <div class="notification-title">
        {{ description }}
        <a [externalLink]="link" class="link text-bold">
            {{ anchorText }}
        </a>
    </div>
    <div class="notification-time text-muted text-sm">
        {{ humanizedCreatedDate }}
    </div>
    <div class="notification-action" (click)="markAsDismissed.emit()">
        <em class="fa fa-check x text-primary"></em>
    </div>
</div>
