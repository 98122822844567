import { createAction, props } from '@ngrx/store';
import { ContextType } from '../../../entities/context-type';

export enum ContextTypesActions {
    GetContextTypesQueryRequest = '[Context Types] Get Context Types Query Request',
    GetContextTypesQueryRequestSuccess = '[Context Types] Get Context Types Query Request Success',
    GetContextTypesQueryRequestFailed = '[Context Types] Get Context Types Query Request Failed',
}

/**
 * Context Types Query Request Redux Action
 * Gets dispatched when a context types query request is triggered.
 */
export const getContextTypesQueryRequest = createAction(ContextTypesActions.GetContextTypesQueryRequest);

/**
 * Context Types Query Success Redux Action
 * Gets dispatched when a `ContextTypesQueryRequest` is successful.
 */
export const getContextTypesQueryRequestSuccess = createAction(
    ContextTypesActions.GetContextTypesQueryRequestSuccess,
    props<{ contextTypes: ContextType[] }>(),
);

/**
 * Context Types Query Fail Redux Action
 * Gets dispatched when a `ContextTypesQueryRequest` fails.
 */
export const getContextTypesQueryRequestFailed = createAction(ContextTypesActions.GetContextTypesQueryRequestFailed);
