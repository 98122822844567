import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { BehaviorSubject, EMPTY, Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, filter, map, switchMap, tap } from 'rxjs/operators';
import { OrgStatus, OrgView } from '../../entities/org';
import { ConfirmDialogService } from '../../shared/components/confirm-dialog/confirm-dialog.service';
import { SnackbarService } from '../../shared/components/snackbar/snackbar.service';
import { ClientOffboardingService } from './client-offboarding.service';

@Component({
    selector: 'app-client-offboarding',
    templateUrl: './client-offboarding.component.html',
    styleUrls: ['./client-offboarding.component.scss'],
})
export class ClientOffboardingComponent implements OnInit {
    pageSizeOptions = [10, 25, 50];
    TableType = TableType;
    OrgStatus = OrgStatus;

    refreshOrgs$ = new Subject<TableType>();
    offboardOrg$ = new Subject<TableType>();
    onboardOrg$ = new Subject<TableType>();
    refreshTables$ = new Subject<void>();

    onboardedOrgs$ = new BehaviorSubject<OrgView[]>([]);
    offboardedOrgs$ = new BehaviorSubject<OrgView[]>([]);

    totalOnboardedOrgs$ = new BehaviorSubject<number>(0);
    totalOffboardedOrgs$ = new BehaviorSubject<number>(0);

    loadingOnboarded$ = new BehaviorSubject<boolean>(true);
    loadingOffboarded$ = new BehaviorSubject<boolean>(true);

    get onboardedPage(): number {
        return this._onboardedState.page;
    }
    get offboardedPage(): number {
        return this._offboardedState.page;
    }
    get onboardedPageSize(): number {
        return this._onboardedState.pageSize;
    }
    get offboardedPageSize(): number {
        return this._offboardedState.pageSize;
    }
    get onboardedSearchTerm(): string {
        return this._onboardedState.searchTerm;
    }
    get offboardedSearchTerm(): string {
        return this._offboardedState.searchTerm;
    }

    set onboardedPage(page: number) {
        this.set({ page }, TableType.ONBOARD);
    }
    set offboardedPage(page: number) {
        this.set({ page }, TableType.OFFBOARD);
    }
    set onboardedPageSize(pageSize: number) {
        this.set({ pageSize }, TableType.ONBOARD);
    }
    set offboardedPageSize(pageSize: number) {
        this.set({ pageSize }, TableType.OFFBOARD);
    }
    set onboardedSearchTerm(searchTerm: string) {
        this.set({ searchTerm }, TableType.ONBOARD);
    }
    set offboardedSearchTerm(searchTerm: string) {
        this.set({ searchTerm }, TableType.OFFBOARD);
    }

    private _onboardedState: State = {
        page: 1,
        pageSize: this.pageSizeOptions[0],
        searchTerm: '',
    };

    private _offboardedState: State = {
        page: 1,
        pageSize: this.pageSizeOptions[0],
        searchTerm: '',
    };

    constructor(
        private _snackbarService: SnackbarService,
        private _orgOffboardingService: ClientOffboardingService,
        private _confirmDialogService: ConfirmDialogService,
    ) {}

    ngOnInit(): void {
        this.refreshOrgs$
            .pipe(
                filter((tableType) => tableType === TableType.OFFBOARD),
                tap(() => this.activateLoader(true, TableType.OFFBOARD)),
                debounceTime(200),
                switchMap(() => this.getOrgs(TableType.OFFBOARD)),
                tap(() => this.activateLoader(false, TableType.OFFBOARD)),
            )
            .subscribe((res) => {
                this.offboardedOrgs$.next(res.orgs);
                this.totalOffboardedOrgs$.next(res.total);
            });

        this.refreshOrgs$
            .pipe(
                filter((tableType) => tableType === TableType.ONBOARD),
                tap(() => this.activateLoader(true, TableType.ONBOARD)),
                debounceTime(200),
                switchMap(() => this.getOrgs(TableType.ONBOARD)),
                tap(() => this.activateLoader(false, TableType.ONBOARD)),
            )
            .subscribe((res) => {
                this.onboardedOrgs$.next(res.orgs);
                this.totalOnboardedOrgs$.next(res.total);
            });

        this.offboardOrg$
            .pipe(
                debounceTime(200),
                switchMap((orgId) => this._orgOffboardingService.offboard(orgId)),
                tap(() => this.refreshTables$.next()),
                catchError((error) => this.onError(error)),
            )
            .subscribe((successMsg) => this._snackbarService.info('Watch your notifications!', successMsg));

        this.onboardOrg$
            .pipe(
                debounceTime(200),
                switchMap((orgId) => this._orgOffboardingService.onboard(orgId)),
                tap(() => this.refreshTables$.next()),
            )
            .subscribe(() => this._snackbarService.success('Org successfully Onboarded'));

        this.refreshTables$.subscribe(() => {
            this.refreshOrgs$.next(TableType.OFFBOARD);
            this.refreshOrgs$.next(TableType.ONBOARD);
        });

        this.refreshTables$.next();
    }

    offboardOrg(orgId: number, orgName: string) {
        this._confirmDialogService
            .confirm({
                title: 'Offboard Organization',
                message:
                    'Are you sure you want to offboard ' +
                    orgName +
                    '? ' +
                    'This will cancel any incomplete Assessments, mark all Relationships as archived and will change this Organizations license to TRIAL (freemium).\n\n' +
                    'Note that any Relationships/Assessments will not have their statuses changed back if you choose to onboard ' +
                    'this Organization again.',
                confirmLabel: 'Yes, Offboard Organization',
                cancelLabel: 'No, Cancel',
            })
            .pipe(filter((result) => result))
            .subscribe(() => this.offboardOrg$.next(orgId));
    }

    onboardOrg(orgId: number, orgName: string) {
        this._confirmDialogService
            .confirm({
                title: 'Onboard Organization',
                message:
                    'Are you sure you want to onboard ' +
                    orgName +
                    '?\n\n' +
                    'Note: This operation will not modify existing archived Relationships and cancelled Assessments for this Organization.',
                confirmLabel: 'Yes, Onboard Organization',
                cancelLabel: 'No, Cancel',
            })
            .pipe(filter((result) => result))
            .subscribe(() => this.onboardOrg$.next(orgId));
    }

    changePageSize(pageSize: number, tableType: TableType) {
        tableType === TableType.ONBOARD ? (this.onboardedPageSize = pageSize) : (this.offboardedPageSize = pageSize);
    }

    private getOrgs(tableType: TableType): Observable<{ total: number; orgs: OrgView[] }> {
        const statusesToQueryBy =
            tableType === TableType.ONBOARD ? [OrgStatus.ONBOARDED, OrgStatus.OFFBOARDING] : [OrgStatus.NOT_ONBOARDED];

        const { page, pageSize, searchTerm } =
            tableType === TableType.ONBOARD ? this._onboardedState : this._offboardedState;

        return this._orgOffboardingService
            .findAllByStatusAndNameLike(statusesToQueryBy, page - 1, pageSize, searchTerm)
            .pipe(
                map((res) => ({
                    total: +res.headers.get('x-total-count'),
                    orgs: res.body,
                })),
                catchError(() => EMPTY),
            );
    }

    private onError(error: HttpErrorResponse): Observable<null> {
        this._snackbarService.error(error.message);
        return of(null);
    }

    private set(patch: Partial<State>, tableType: TableType) {
        const state = tableType === TableType.ONBOARD ? this._onboardedState : this._offboardedState;
        Object.assign(state, patch);
        this.refreshOrgs$.next(tableType);
    }

    private activateLoader(active: boolean, tableType: TableType) {
        tableType === TableType.ONBOARD ? this.loadingOnboarded$.next(active) : this.loadingOffboarded$.next(active);
    }
}

interface State {
    page: number;
    pageSize: number;
    searchTerm: string;
}

enum TableType {
    ONBOARD,
    OFFBOARD,
}
