<div class="content-heading">
    <div>
        Artifact Management
        <small>Admin controls for managing artifacts</small>
    </div>
</div>
<div class="row">
    <div class="col-lg-6">
        <div class="card card-default">
            <div class="card-body">
                <h4 class="card-title">Refresh Risk Network</h4>
                <p class="card-text">
                    Refreshes risk network data from ML for all file artifacts by downloading, parsing, and reindexing
                    risk network NER detections from S3.
                </p>
                <button mat-button visoButton="primary" (click)="refreshRiskNetwork()">Refresh Risk Network</button>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-lg-6">
        <div class="card card-default">
            <div class="card-body">
                <h4 class="card-title">Calculate Missing File Hashes</h4>
                <p class="card-text">
                    Currently this function calculates file hashes for all artifacts missing a file hash.
                </p>
                <button mat-button visoButton="primary" (click)="generateFileHashes()">Generate file hashes</button>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-lg-6">
        <div class="card card-default">
            <div class="card-body">
                <h4 class="card-title">Push Public/Viso Artifacts to Trust Profiles</h4>
                <p class="text-gray-dark fst-italic">
                    Note: Clients will not receive notifications when their Relationship(s) are updated to avoid spam.
                </p>
                This action performs the following steps:
                <ol>
                    <li>
                        Transfers all public artifacts to the Trust Profiles of the Vendors they belong to (if they do
                        not have them already).
                    </li>
                    <li>
                        Transfers those artifacts to any Relationships that exist with those Vendors (if they do not
                        have them already).
                    </li>
                    <li>Runs the risk model and refreshes indexes for all Relationships.</li>
                </ol>
                <button mat-button visoButton="primary" (click)="displayArtifactTransferConfirmation()">
                    Transfer Artifacts
                </button>
            </div>
        </div>
    </div>
</div>
