import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { RouterModule } from '@angular/router';
import { datatypesRoute } from './';
import { DatatypesComponent } from './datatypes.component';
import { DataTypeHelpModalContent } from './datatypes-help.component';
import { EffectsModule } from '@ngrx/effects';
import { DataTypesEffects } from './redux/data-types.effects';
import { MatTabsModule } from '@angular/material/tabs';
import { MatListModule } from '@angular/material/list';
import { ChipModule } from '../../shared/chip/chip.module';
import { SensitivityMeterModule } from '../../shared/sensitivity-meter/sensitivity-meter.module';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { DynamicContentModule } from '../../shared/dynamic-content/dynamic-content.module';

const ENTITY_STATES = [...datatypesRoute];

@NgModule({
    imports: [
        RouterModule.forChild(ENTITY_STATES),
        EffectsModule.forFeature([DataTypesEffects]),
        MatTabsModule,
        MatListModule,
        ChipModule,
        SensitivityMeterModule,
        CommonModule,
        MatButtonModule,
        DynamicContentModule,
    ],
    declarations: [DatatypesComponent, DataTypeHelpModalContent],
    providers: [],
    exports: [],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class DatatypesModule {}
