import { createAction, props } from '@ngrx/store';
import { Relationship } from '../../../../entities/relationship';
import { AssessmentView } from '../../../../entities/assessment';
import { PrimaryVendorContact } from '../../../primary-vendor-contact';
import { StartAssessmentPayload } from '../../models/start-assessment-payload';
import { VisoUser } from '../../../../entities/viso-user';
import { HasEmails } from '../../models/populated-assessment';

export enum AssessmentActions {
    OpenStartAssessmentModal = '[Request] Open Start Assessment Modal',
    StartAssessmentRequest = '[Request] Start Assessment Request',
    StartAssessmentRequestSuccess = '[Request] Start Assessment Request Success',
    StartAssessmentRequestFailed = '[Request] Start Assessment Request Failed',
    GetRequestLatestAssessmentRequest = '[Request] Get Request Latest Assessment Request',
    GetRequestLatestAssessmentRequestSuccess = '[Request] Get Request Latest Assessment Request Success',
    GetRequestLatestAssessmentRequestFailed = '[Request] Get Request Latest Assessment Request Failed',
    CancelRequestLatestAssessmentRequest = '[Request] Cancel Request Latest Assessment Request',
    CancelRequestLatestAssessmentRequestSuccess = '[Request] Cancel Request Latest Assessment Request Success',
    CancelRequestLatestAssessmentRequestFailed = '[Request] Cancel Request Latest Assessment Request Failed',
    CancelRequestLatestAssessment = '[Request] Cancel Request Latest Assessment',
    GetRequestAssessmentsRequest = '[Request] Get Request Assessments Request',
    GetRequestAssessmentsRequestSuccess = '[Request] Get Request Assessments Request Success',
    GetRequestAssessmentsRequestFailed = '[Request] Get Request Assessments Request Failed',
    GetRequestAssessmentEmailsRequest = '[Request] Get Request Assessment Emails Request',
    GetRequestAssessmentEmailsRequestSuccess = '[Request] Get Request Assessment Emails Request Success',
    GetRequestAssessmentEmailsRequestFailed = '[Request] Get Request Assessment Emails Request Failed',
    OpenRecertifyAssessmentModal = '[Request] Open Recertify Assessment Modal',
    CreateFollowUpQuestionnaireRequest = '[Request] Create Follow Up Questionnaire Request',
    CreateFollowUpQuestionnaireRequestSuccess = '[Request] Create Follow Up Questionnaire Request Success',
    GetOrgUsersRequest = '[Request] Get Org Users Request',
    GetOrgUsersRequestSuccess = '[Request] Get Org Users Request Success',
    GetOrgUsersRequestFailed = '[Request] Get Org Users Request Failed',
    SendAssessmentReminderEmailRequest = '[Request] Send Assessment Reminder Email Request',
    SendAssessmentReminderEmailRequestSuccess = '[Request] Send Assessment Reminder Email Request Success',
    SendAssessmentReminderEmailRequestFailed = '[Request] Send Assessment Reminder Email Request Failed',
    ConfirmProceedWithAvailableData = '[Request] Confirm Proceed With Available Data',
    ProceedWithAvailableDataRequest = '[Request] Proceed With Available Data Request',
    ProceedWithAvailableDataRequestSuccess = '[Request] Proceed With Available Data Request Success',
    ProceedWithAvailableDataRequestFailed = '[Request] Proceed With Available Data Request Failed',
}

export const openStartAssessmentModal = createAction(
    AssessmentActions.OpenStartAssessmentModal,
    props<{
        request: Relationship;
        primaryContact: PrimaryVendorContact;
        recertification: boolean;
    }>(),
);

export const startAssessmentRequest = createAction(
    AssessmentActions.StartAssessmentRequest,
    props<{ payload: StartAssessmentPayload }>(),
);

export const startAssessmentRequestSuccess = createAction(
    AssessmentActions.StartAssessmentRequestSuccess,
    props<{ email: string }>(),
);

export const startAssessmentRequestFailed = createAction(AssessmentActions.StartAssessmentRequestFailed);

export const getRequestLatestAssessmentRequest = createAction(
    AssessmentActions.GetRequestLatestAssessmentRequest,
    props<{ requestId: number }>(),
);

export const getRequestLatestAssessmentRequestSuccess = createAction(
    AssessmentActions.GetRequestLatestAssessmentRequestSuccess,
    props<{ latestAssessment: AssessmentView }>(),
);

export const getRequestLatestAssessmentRequestFailed = createAction(
    AssessmentActions.GetRequestLatestAssessmentRequestFailed,
);

export const cancelRequestLatestAssessmentRequest = createAction(
    AssessmentActions.CancelRequestLatestAssessmentRequest,
    props<{ requestId: number }>(),
);

export const cancelRequestLatestAssessmentRequestSuccess = createAction(
    AssessmentActions.CancelRequestLatestAssessmentRequestSuccess,
);

export const cancelRequestLatestAssessmentRequestFailed = createAction(
    AssessmentActions.CancelRequestLatestAssessmentRequestFailed,
);

export const cancelRequestLatestAssessment = createAction(
    AssessmentActions.CancelRequestLatestAssessment,
    props<{ requestId: number }>(),
);

export const getRequestAssessmentsRequest = createAction(
    AssessmentActions.GetRequestAssessmentsRequest,
    props<{ requestId: number }>(),
);

export const getRequestAssessmentsRequestSuccess = createAction(
    AssessmentActions.GetRequestAssessmentsRequestSuccess,
    props<{ assessments: AssessmentView[] }>(),
);

export const getRequestAssessmentsRequestFailed = createAction(AssessmentActions.GetRequestAssessmentsRequestFailed);

export const getRequestAssessmentEmailsRequest = createAction(
    AssessmentActions.GetRequestAssessmentEmailsRequest,
    props<{ assessmentId: number }>(),
);

export const getRequestAssessmentEmailsRequestSuccess = createAction(
    AssessmentActions.GetRequestAssessmentEmailsRequestSuccess,
    props<{ assessmentId: number } & HasEmails>(),
);

export const getRequestAssessmentEmailsRequestFailed = createAction(
    AssessmentActions.GetRequestAssessmentEmailsRequestFailed,
);

export const createFollowUpQuestionnaireRequest = createAction(
    AssessmentActions.CreateFollowUpQuestionnaireRequest,
    props<{ assessmentId: number }>(),
);

export const createFollowUpQuestionnaireRequestSuccess = createAction(
    AssessmentActions.CreateFollowUpQuestionnaireRequestSuccess,
);

export const getOrgUsersRequest = createAction(AssessmentActions.GetOrgUsersRequest, props<{ orgId: number }>());

export const getOrgUsersRequestSuccess = createAction(
    AssessmentActions.GetOrgUsersRequestSuccess,
    props<{ users: VisoUser[] }>(),
);

export const getOrgUsersRequestFailed = createAction(AssessmentActions.GetOrgUsersRequestFailed);

export const sendAssessmentReminderEmailRequest = createAction(
    AssessmentActions.SendAssessmentReminderEmailRequest,
    props<{ assessmentId: number }>(),
);

export const sendAssessmentReminderEmailRequestSuccess = createAction(
    AssessmentActions.SendAssessmentReminderEmailRequestSuccess,
    props<{ assessmentId: number }>(),
);

export const sendAssessmentReminderEmailRequestFailed = createAction(
    AssessmentActions.SendAssessmentReminderEmailRequestFailed,
);

export const confirmProceedWithAvailableData = createAction(
    AssessmentActions.ConfirmProceedWithAvailableData,
    props<{ assessmentId: number }>(),
);

export const proceedWithAvailableDataRequest = createAction(
    AssessmentActions.ProceedWithAvailableDataRequest,
    props<{ assessmentId: number }>(),
);

export const proceedWithAvailableDataRequestSuccess = createAction(
    AssessmentActions.ProceedWithAvailableDataRequestSuccess,
    props<{ assessmentId: number }>(),
);

export const proceedWithAvailableDataRequestFailed = createAction(
    AssessmentActions.ProceedWithAvailableDataRequestFailed,
);
