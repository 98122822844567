import { RemediationStatus } from '../../entities/remediation-request/remediation-request.model';
import { AssessmentRemediationEvent } from './models/assessment-events';

const requested: AssessmentRemediationEvent = {
    title: 'Remediation requested by',
    subtitle: 'Waiting for third party',
    remediationStatus: RemediationStatus.REQUESTED,
    current: false,
    lowerText: {
        label: 'Requested by',
        value: '',
    },
    done: false,
};

const artifactsProvided: AssessmentRemediationEvent = {
    title: 'Remediation requested by', // Title is intentionally still 'requested by...'
    subtitle: null,
    remediationStatus: RemediationStatus.ARTIFACTS_PROVIDED,
    current: false,
    lowerText: {
        label: 'Requested by',
        value: '',
    },
    done: false,
};

const declined: AssessmentRemediationEvent = {
    title: 'Remediation declined',
    subtitle: null,
    remediationStatus: RemediationStatus.DECLINED,
    current: false,
    lowerText: {
        label: null,
        value: null,
    },
    done: false,
};

const expired: AssessmentRemediationEvent = {
    title: 'Remediation missed',
    subtitle: null,
    remediationStatus: RemediationStatus.EXPIRED,
    current: false,
    lowerText: {
        label: null,
        value: null,
    },
    done: false,
};

const cancelled: AssessmentRemediationEvent = {
    title: 'Remediation cancelled',
    subtitle: null,
    remediationStatus: RemediationStatus.CANCELLED,
    current: false,
    lowerText: {
        label: null,
        value: null,
    },
    done: false,
};

export const remediationTimelineNodes: Record<RemediationStatus, AssessmentRemediationEvent[]> = {
    REQUESTED: [{ ...requested, current: true }],
    ARTIFACTS_PROVIDED: [{ ...artifactsProvided, current: true, done: true }],
    DECLINED: [
        { ...requested, done: true },
        { ...declined, current: true, done: true },
    ],
    OBSOLETE: [{ ...requested, done: true, current: true }],
    EXPIRED: [
        { ...requested, done: true },
        { ...expired, current: true, done: true },
    ],
    CANCELLED: [
        { ...requested, done: true },
        { ...cancelled, current: true, done: true },
    ],
};
