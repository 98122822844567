import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
    deleteOrgSubdomainRequest,
    deleteOrgSubdomainRequestFailure,
    deleteOrgSubdomainRequestSuccess,
    getOrgSubdomainRequest,
    getOrgSubdomainRequestFailure,
    getOrgSubdomainRequestSuccess,
    setOrgSubdomainRequest,
    setOrgSubdomainRequestFailure,
    setOrgSubdomainRequestSuccess,
} from './org-subdomain.actions';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

import { OrgSubdomainService } from '@entities/org-subdomain';

@Injectable()
export class OrgSubdomainEffects {
    getOrgSubdomains$ = createEffect(() =>
        this._actions$.pipe(
            ofType(getOrgSubdomainRequest),
            switchMap(() =>
                this._orgSubdomainService.getSubdomain().pipe(
                    map((response) => getOrgSubdomainRequestSuccess({ response })),
                    catchError(() => of(getOrgSubdomainRequestFailure())),
                ),
            ),
        ),
    );

    setOrgSubdomain$ = createEffect(() =>
        this._actions$.pipe(
            ofType(setOrgSubdomainRequest),
            switchMap(({ subdomain }) =>
                this._orgSubdomainService.setSubdomain(subdomain).pipe(
                    map((response) => setOrgSubdomainRequestSuccess({ response })),
                    catchError(() => of(setOrgSubdomainRequestFailure())),
                ),
            ),
        ),
    );

    deleteOrgSubdomain$ = createEffect(() =>
        this._actions$.pipe(
            ofType(deleteOrgSubdomainRequest),
            switchMap(() =>
                this._orgSubdomainService.deleteSubdomain().pipe(
                    map(() => deleteOrgSubdomainRequestSuccess()),
                    catchError(() => of(deleteOrgSubdomainRequestFailure())),
                ),
            ),
        ),
    );

    constructor(
        private _actions$: Actions,
        private _orgSubdomainService: OrgSubdomainService,
    ) {}
}
