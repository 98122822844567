import { Component, Input } from '@angular/core';
import { VisoUser, VisoUserRole } from '@entities/viso-user';
import { PrimaryVendorContact } from '../../../primary-vendor-contact';
import { Relationship } from '@entities/relationship';

@Component({
    selector: 'app-third-party-contact',
    templateUrl: './third-party-contact.component.html',
    styleUrls: ['./third-party-contact.component.scss'],
})
export class ThirdPartyContactComponent {
    @Input()
    relationshipId: number;

    @Input()
    relationship: Relationship;

    @Input()
    primaryVendorContact: PrimaryVendorContact;

    @Input({ required: true })
    businessOwner: VisoUser;

    Roles = VisoUserRole;

    get editLink() {
        return [
            '/',
            {
                outlets: {
                    popup: `relationships/${this.relationshipId}/third-party-contact`,
                },
            },
        ];
    }

    get thirdPartyContactExists(): boolean {
        return !!this.primaryVendorContact;
    }

    get thirdPartyContactName(): string {
        if (!this.thirdPartyContactExists) {
            return '';
        }
        const { firstName, lastName } = this.primaryVendorContact;
        return `${firstName} ${lastName}`;
    }

    get thirdPartyContactEmail(): string {
        return this.primaryVendorContact?.email || '';
    }

    get doesThirdPartyEmailBounced(): boolean {
        return this.primaryVendorContact.emailInvalid;
    }
}
