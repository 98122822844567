<header class="content-heading">
    <section>
        Client Profile
        <small>Manage Client Profile for Orgs</small>
    </section>
</header>

<main>
    <section class="pb-4">
        <div *ngIf="orgs$ | async as orgs">
            <form class="org-section" name="clientProfileForm" novalidate [formGroup]="clientProfileForm">
                <span class="org-section-label" for="org">Organization</span>
                <select class="form-control" id="org" formControlName="orgId">
                    <option [ngValue]="null">Select an organization</option>
                    <option [ngValue]="clientOrg.id" *ngFor="let clientOrg of orgs; trackBy: trackOrgById">
                        {{ clientOrg.name }}
                    </option>
                </select>
            </form>
        </div>
    </section>

    <section>
        <div class="card card-default">
            <div class="card-body">
                <tabset [justified]="true" class="min-height">
                    <tab>
                        <ng-template tabHeading>Risk Levels</ng-template>
                        <div class="no-org-selected mt-4" *ngIf="!getOrgIdValue">
                            <i class="fa fa-info-circle"></i>
                            <p>Please select an organization</p>
                        </div>

                        <section *ngIf="loadingProfile">
                            <div class="d-flex align-items-center justify-content-center">
                                <div class="sk-spinner sk-spinner-pulse"></div>
                            </div>
                        </section>

                        <app-risk-levels-config
                            *ngIf="getOrgIdValue && !loadingProfile"
                            [clientProfileId]="clientProfileId"
                            [riskLevels]="riskLevelsConfig"
                        ></app-risk-levels-config>
                    </tab>
                    <tab>
                        <ng-template tabHeading>Data Sensitivity Levels</ng-template>
                        <div class="no-org-selected mt-4" *ngIf="!getOrgIdValue">
                            <i class="fa fa-info-circle"></i>
                            <p>Please select an organization</p>
                        </div>

                        <section *ngIf="loadingProfile">
                            <div class="d-flex align-items-center justify-content-center">
                                <div class="sk-spinner sk-spinner-pulse"></div>
                            </div>
                        </section>

                        <app-data-sensitivity-levels-config
                            *ngIf="getOrgIdValue && !loadingProfile"
                            [clientProfileId]="clientProfileId"
                            [dataSensitivityLevels]="dataSensitivityLevels"
                            (refreshClientProfile)="refreshClientProfile()"
                        ></app-data-sensitivity-levels-config>
                    </tab>
                    <tab>
                        <ng-template tabHeading>Data Types</ng-template>
                        <div class="no-org-selected mt-4" *ngIf="!getOrgIdValue">
                            <i class="fa fa-info-circle"></i>
                            <p>Please select an organization</p>
                        </div>

                        <section *ngIf="loadingProfile">
                            <div class="d-flex align-items-center justify-content-center">
                                <div class="sk-spinner sk-spinner-pulse"></div>
                            </div>
                        </section>

                        <app-data-types-config
                            *ngIf="getOrgIdValue && !loadingProfile"
                            [clientProfileId]="clientProfileId"
                            [dataTypes]="dataTypesConfig"
                            [orgId]="getOrgIdValue"
                            [profileControls]="controlsConfig"
                            (dataTypeSubmitted)="updateClientProfileData()"
                        ></app-data-types-config>
                    </tab>
                    <tab>
                        <ng-template tabHeading>Org Controls</ng-template>
                        <div class="no-org-selected mt-4" *ngIf="!getOrgIdValue">
                            <i class="fa fa-info-circle"></i>
                            <p>Please select an organization</p>
                        </div>

                        <section *ngIf="loadingProfile">
                            <div class="d-flex align-items-center justify-content-center">
                                <div class="sk-spinner sk-spinner-pulse"></div>
                            </div>
                        </section>

                        <app-controls-config
                            [clientProfileId]="clientProfileId"
                            [orgId]="getOrgIdValue"
                            [profileControls]="controlsConfig"
                            (controlStatusChange)="refreshClientProfile()"
                        ></app-controls-config>
                    </tab>
                </tabset>
            </div>
        </div>
    </section>
</main>
