import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DateUtilsService } from '../utils/date-utils.service';

@Injectable()
export class SearchFilterService {
    constructor(
        private _dateUtilsService: DateUtilsService,
        private _router: Router,
        private _route: ActivatedRoute,
    ) {}

    addMultipleFilters(filters: any) {
        let searchFilter: any;

        for (const key in filters) {
            if (
                (Array.isArray(filters[key]) && filters[key].length > 0) ||
                (!Array.isArray(filters[key]) && filters[key])
            ) {
                searchFilter = {
                    ...searchFilter,
                    [key]: {
                        date:
                            Array.isArray(filters[key]) &&
                            filters[key].some((f: any) => this._dateUtilsService.isAValidDate(f)),
                        equals: true,
                        values: Array.isArray(filters[key]) ? filters[key] : [filters[key]],
                    },
                };
            } else if (searchFilter[key]) {
                delete searchFilter[key];
            }
        }

        return searchFilter;
    }

    addFilterParamToUrl(searchFilterString: string): void {
        this._router.navigate([], {
            relativeTo: this._route,
            queryParams: {
                search: searchFilterString,
            },
            queryParamsHandling: 'merge',
        });
    }

    clearAllUrlParams(): void {
        this._router.navigate([], {
            relativeTo: this._route,
            queryParams: {},
        });
    }

    formatFiltersToString(filters: any): string {
        let filtersParamsString: string = '';
        for (const key in filters) {
            const isFirstParam = filtersParamsString.length === 0;
            filtersParamsString += isFirstParam ? '' : ',';
            if (filters[key].keyword) {
                filtersParamsString += filters[key].values.map((v: string) => `text~${v}`);
            } else if (filters[key].date) {
                if (filters[key].values[0]) {
                    filtersParamsString += `${key}>:${filters[key].values[0]},`;
                }

                if (filters[key].values[1]) {
                    filtersParamsString += `${key}<:${filters[key].values[1]}`;
                }
            } else {
                if (filters[key].equals) {
                    const singleValue = filters[key].values.length === 1;
                    filtersParamsString += singleValue
                        ? `${key}:${filters[key].values[0]}`
                        : `${key}:[${filters[key].values.join('|')}]`;
                } else {
                    filtersParamsString += filters[key].values.map((v: string) => `${key}!${v}`);
                }
            }
        }
        return filtersParamsString;
    }
}
