import { Action, createReducer, on } from '@ngrx/store';
import { ContextTypesState, initialState } from './context-types.state';
import { getContextTypesQueryRequestSuccess } from './context-types.actions';

const reducer = createReducer(
    initialState,
    on(getContextTypesQueryRequestSuccess, (state, { contextTypes }) => ({
        ...state,
        contextTypes,
    })),
);

export function contextTypesReducer(state: ContextTypesState = initialState, action: Action): ContextTypesState {
    return reducer(state, action);
}
