import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { Org, VendorFromExistingRelationshipRequest } from '../../../entities/org';
import { CreateRelationshipRequest, Relationship } from '../../../entities/relationship';
import { CompleteVendorSearchResult } from '../../../shared/vendor-components/models/vendor-search-result';

export enum AddRelationshipActions {
    CreateRelationshipRequest = '[AddRelationship] Create Relationship Request',
    CreateRelationshipRequestSuccess = '[AddRelationship] Create Relationship Request Success',
    CreateRelationshipRequestFailed = '[AddRelationship] Create Relationship Request Failed',
    SearchVendorsByHomepageUrlRequest = '[AddRelationship] Search Vendors By HomepageUrl Request',
    SearchVendorsByHomepageUrlRequestSuccess = '[AddRelationship] Search Vendors By HomepageUrl Request Success',
    SearchVendorsByHomepageUrlRequestFailed = '[AddRelationship] Search Vendors By HomepageUrl Request Failed',
    GetVendorFromExistingRelationshipRequest = '[AddRelationship] Get Vendor From Existing Relationship Request',
    GetVendorFromExistingRelationshipRequestSuccess = '[AddRelationship] Get Vendor From Existing Relationship Request Success',
    GetVendorFromExistingRelationshipRequestFailed = '[AddRelationship] Get Vendor From Existing Relationship Request Failed',
}

export const createRelationshipRequest = createAction(
    AddRelationshipActions.CreateRelationshipRequest,
    props<{ createRelationshipPayload: CreateRelationshipRequest }>(),
);

export const createRelationshipRequestSuccess = createAction(
    AddRelationshipActions.CreateRelationshipRequestSuccess,
    props<{ relationship: Relationship }>(),
);

export const createRelationshipRequestFailed = createAction(AddRelationshipActions.CreateRelationshipRequestFailed);

export const searchVendorsByHomepageUrlRequest = createAction(
    AddRelationshipActions.SearchVendorsByHomepageUrlRequest,
    props<{ homepageUrl: string }>(),
);

export const searchVendorsByHomepageUrlRequestSuccess = createAction(
    AddRelationshipActions.SearchVendorsByHomepageUrlRequestSuccess,
    props<{ vendor: CompleteVendorSearchResult }>(),
);

export const searchVendorsByHomepageUrlRequestFailed = createAction(
    AddRelationshipActions.SearchVendorsByHomepageUrlRequestFailed,
    props<{ error: HttpErrorResponse }>(),
);

export const getVendorFromExistingRelationshipRequest = createAction(
    AddRelationshipActions.GetVendorFromExistingRelationshipRequest,
    props<{ vendorFromExistingRelationshipRequest: VendorFromExistingRelationshipRequest }>(),
);

export const getVendorFromExistingRelationshipRequestSuccess = createAction(
    AddRelationshipActions.GetVendorFromExistingRelationshipRequestSuccess,
    props<{ organization: Org }>(),
);

export const getVendorFromExistingRelationshipRequestFailed = createAction(
    AddRelationshipActions.GetVendorFromExistingRelationshipRequestFailed,
    props<{ error: HttpErrorResponse }>(),
);
