import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { VisoUser, VisoUserRole } from '../../../../entities/viso-user';

interface SubscriberFullName {
    fullname: string;
}

@Component({
    selector: 'app-subscribers',
    templateUrl: './subscribers.component.html',
    styleUrls: ['./subscribers.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubscribersComponent {
    @Input()
    set subscribers(value: VisoUser[]) {
        this.relationshipSubscribers$.next(
            value?.map((subscriber) => ({
                ...subscriber,
                fullname: `${subscriber.firstName} ${subscriber.lastName}`,
            })),
        );
    }

    @Input()
    export = false;

    @Input()
    relationshipId: number;

    @Output()
    unsubscribeSubscriber = new EventEmitter<number>();

    relationshipSubscribers$ = new BehaviorSubject<(VisoUser & SubscriberFullName)[]>([]);

    Roles = VisoUserRole;

    get addLink() {
        return [
            '/',
            {
                outlets: {
                    popup: `relationships/${this.relationshipId}/subscriber`,
                },
            },
        ];
    }

    get subscriberListExists(): boolean {
        return !!this.relationshipSubscribers$.value?.length;
    }
}
