import { Injectable } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute, Params } from '@angular/router';
import { filter, map, withLatestFrom } from 'rxjs/operators';

export const defaultRLPSearchParam = 'drStatus:[NOT_ONBOARDED|ONBOARDED]';

export interface RouterUtilsServiceRoute {
    url: string;
    hasQueryParams: boolean;
    queryParams?: Params;
}

@Injectable({
    providedIn: 'root',
})
export class RouterUtilsService {
    private _previousRoute: RouterUtilsServiceRoute;
    private _currentRoute: RouterUtilsServiceRoute;

    constructor(_router: Router, _route: ActivatedRoute) {
        _router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                map((event: NavigationEnd) => event.url.split('?')[0]),
                withLatestFrom(_route.queryParams),
                map(([url, queryParams]) => ({ url, queryParams, hasQueryParams: !!Object.keys(queryParams).length })),
            )
            .subscribe((route) => {
                this._previousRoute = this._currentRoute;
                this._currentRoute = route;
            });
    }

    getPreviousRoute(): RouterUtilsServiceRoute {
        return this._previousRoute;
    }
}
