import { FileDownloadEffects } from '../../file-download/redux/effects';
import { LegacyHashUrlsRedirectEffect } from './legacy-hash-urls-redirect.effect';
import { ModalEffects } from '../../modal/redux/effects';
import { GlobalVendorEffect } from './global-vendor.effect';
import { GlobalEmailViewerEffects } from './global-email-viewer.effect';

export const effects = [
    FileDownloadEffects,
    ModalEffects,
    LegacyHashUrlsRedirectEffect,
    GlobalVendorEffect,
    GlobalEmailViewerEffects,
];
