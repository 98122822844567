import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChipComponent } from './components/chip/chip.component';
import { MatChipsModule } from '@angular/material/chips';

@NgModule({
    declarations: [ChipComponent],
    imports: [CommonModule, MatChipsModule],
    exports: [ChipComponent],
})
export class ChipModule {}
