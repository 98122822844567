import { BaseEntity } from '../../shared';
import { ContextType } from '../context-type';
import { RiskAssessment, RiskDelta } from '../risk-assessment';
import { DataType } from '../data-type';
import { VisoUser } from '../viso-user';
import { PrimaryVendorContact } from '../../routes/primary-vendor-contact';
import { AssessmentStatus } from '../../entities/assessment';
import { Tag } from '../tag';
import { OrgSize, OrgUrlType } from '../org';
import { OrgAssessmentSummarySections } from '../../admin/client-profile/client-profile.model';

export enum RequestStatus {
    NOT_ONBOARDED = 'NOT_ONBOARDED',
    ARCHIVED = 'ARCHIVED',
    ONBOARDED = 'ONBOARDED',
}

export enum ReviewFrequency {
    THREE_YEARS = 'THREE_YEARS',
    TWO_YEARS = 'TWO_YEARS',
    ANNUAL = 'ANNUAL',
    SEMIANNUAL = 'SEMIANNUAL',
    QUARTERLY = 'QUARTERLY',
}

export enum ReviewFrequencyMonths {
    THREE_YEARS = 36,
    TWO_YEARS = 24,
    ANNUAL = 12,
    SEMIANNUAL = 6,
    QUARTERLY = 3,
}

export enum RecertificationType {
    MANUAL = 'MANUAL',
    AUTOMATIC = 'AUTOMATIC',
    NONE = 'NONE',
}

export type SuggestedContact = Required<Pick<PrimaryVendorContact, 'email' | 'firstName' | 'lastName'>>;

export interface CreateRelationshipRequest {
    businessOwner: VisoUser;
    description?: string;
    tags: BaseEntity[];
    vendor: {
        id: number;
        name: string;
        homepage: string;
    };
}

export class Relationship implements BaseEntity {
    nextReview: string;

    constructor(
        public id?: number,
        public description?: string,
        public startDate?: any,
        public endDate?: any,
        public createdDate?: any,
        public updatedDate?: any,
        public recertificationType?: RecertificationType,
        public recertificationDate?: any,
        public status?: RequestStatus,
        public reviewFrequency?: ReviewFrequency,
        public creatorId?: number,
        public clientId?: number,
        public vendorId?: number,
        public businessOwner?: VisoUser,
        public businessUnitName?: string,
        public businessUnitId?: number,
        public contextTypes: ContextType[] = [],
        public dataTypes: DataType[] = [],
        public clientName?: string,
        public vendorName?: string,
        public vendorOrgSize?: OrgSize,
        public vendorDomains?: string[],
        public vendorIndustry?: string,
        public vendorHomepageUrl?: string,
        public vendorFaviconUrl?: string,
        public vendorManagedById?: number,
        public vendorDescription?: string,
        public vendorFoundedDate?: any,
        public vendorUrls?: Map<OrgUrlType, string>,
        public vendorSuggestedContacts?: SuggestedContact[],
        public subscribers?: VisoUser[],
        public riskDelta?: RiskDelta,
        public latestRiskAssessment?: RiskAssessment,
        public latestNonTransitionalRiskAssessment?: RiskAssessment,
        public latestAssessmentId?: number,
        public latestAssessmentStatus?: AssessmentStatus,
        public creator?: VisoUser,
        public primaryContact?: PrimaryVendorContact,
        public creatorFirstName?: string,
        public serviceCountries?: string[],
        public clientDecidesDataLocation?: boolean,
        public dataCountries?: string[],
        public dataStorageComment?: string,
        public tags?: Tag[],
        public artifactUpdateEnabled?: boolean,
        public artifactUpdateType?: RecertificationType,
        public riskOverrideAppliedDate?: Date,
        public assessmentSummarySections?: OrgAssessmentSummarySections[],
        public riskAccepted?: boolean,
    ) {}
}

export interface RelationshipOnboardedView {
    vendorName: string;
    recertificationType: RecertificationType;
    approvalSummary?: string;
}

export interface LifecycleManagementUpdateRequest {
    artifactUpdateSettings?: {
        artifactUpdateType: RecertificationType;
    };
    recertificationSettings?: {
        recertificationDate: Date;
        recertificationType: RecertificationType;
        reviewFrequency: ReviewFrequency;
    };
}

export interface RelationshipAssessmentSummarySectionConfigResponse {
    id: number;
    assessmentSummarySections: OrgAssessmentSummarySections[];
}
