<div *ngIf="!request" class="spinner-container">
    <div class="d-flex align-items-center justify-content-center">
        <div class="sk-spinner sk-spinner-pulse"></div>
    </div>
</div>
<app-header
    class="d-print-none"
    [hidden]="!request"
    [request]="request"
    [latestAssessmentStatus]="latestAssessmentStatus$ | async"
    [isPreviousViewFiltered]="isPreviousViewFiltered"
    [previousNavigatedUrlQueryParams]="previousNavigatedUrlQueryParams"
    [onPrint]="showPrintArea$ | async"
    [isUpForRecertification]="isUpForRecertification$ | async"
    [currentUser]="currentAccount$ | async"
    [businessOwner]="businessOwner$ | async"
    (onboard)="openOnboardModal()"
    (archive)="archiveRequest()"
    (markAsNotOnboarded)="markAsNotOnboarded()"
    (startAssessment)="startAssessment()"
    (confirmRecertifyAssessment)="confirmRecertifyAssessment()"
    (downloadPDF)="exportRelationshipAsPDF()"
    (openCreateRiskAdvisory)="openCreateRiskAdvisory()"
    [hasOrgUserOnboardEnabled]="hasOrgUserOnboardEnabled$ | async"
    [hasLicenseManagementEnabled]="hasLicenseManagementEnabled$ | async"
></app-header>
<ng-container *hideIfOrgUserIsNotBusinessOwner="businessOwner$ | async">
    <app-in-line-callout
        *jhiHasAnyAuthority="[Roles.OrgAdmin, Roles.OrgUser]"
        [relationship]="request"
        (startAssessment)="startAssessment()"
    />
</ng-container>
<app-stat-boxes
    class="d-print-none"
    [hidden]="!(request$ | async)"
    [request]="request$ | async"
    [latestAssessment]="latestAssessment$ | async"
    [latestRiskAssessment]="latestRiskAssessment$ | async"
    [latestNonTransitionalRiskAssessment]="latestNonTransitionalRiskAssessment$ | async"
    [isUpForRecertification]="isUpForRecertification$ | async"
    [isRecertificationOverdue]="isRecertificationOverdue$ | async"
    [isAssessmentInProgress]="isAssessmentInProgress$ | async"
    [recertificationDueDaysLeftCount]="recertificationDueDaysLeftCount$ | async"
    (editRiskReview)="openReviewRiskDialog()"
/>
<ng-container *ngIf="isCurrentUserAuditor || isCurrentUserSupport">
    <div class="row mt-0">
        <div class="col">
            <p class="text-light">
                {{ request.clientName }} has a
                {{ getRiskTolerance(senderClientProfile$ | async) }}
                risk tolerance.
            </p>
        </div>
    </div>
</ng-container>
<div [hidden]="!request" class="d-print-none">
    <div class="row">
        <div class="col">
            <ng-container
                *ngIf="{
                    selectedTab: tabContentTrigger$ | async,
                } as tabGroupCtx"
            >
                <mat-tab-group
                    id="mainTabset"
                    #mainTabset
                    mat-stretch-tabs="false"
                    class="mb-3 viso-tabset"
                    animationDuration="0"
                    dynamicHeight
                >
                    <mat-tab label="Relationship" [id]="MainTabs.RELATIONSHIP">
                        <app-relationship-tab
                            [relationship]="request"
                            [dataTypesConfig]="dataTypesProfileConfig$ | async"
                            [businessOwner]="businessOwner$ | async"
                            [primaryVendorContact]="primaryVendorContact$ | async"
                            [latestCompletedAssessmentDate]="latestCompletedAssessmentDate$ | async"
                            [tags]="relationshipTags$ | async"
                            [subscribers]="relationshipSubscribers$ | async"
                            [currentUser]="currentAccount$ | async"
                            [dateOfNextArtifactExpiration]="dateOfNextArtifactExpiration$ | async"
                            (unlinkTag)="unlinkTagToRelationship($event)"
                            (linkTag)="linkTagToRelationship($event)"
                            (unsubscribeSubscriber)="unsubscribeSubscriber($event)"
                        />
                    </mat-tab>
                    <mat-tab label="Assessments" [id]="MainTabs.ASSESSMENT">
                        <ng-template matTabContent>
                            <app-assessment-list
                                [assessmentSummarySectionConfig]="assessmentSummarySectionConfig$ | async"
                                [relationship]="request"
                                [latestAssessment]="latestAssessment$ | async"
                                [assessments]="pastAssessments$ | async"
                                [businessOwner]="businessOwner$ | async"
                                [isOnlyLegacyAssessmentPresent]="isOnlyLegacyAssessmentPresent$ | async"
                                [isUpForRecertification]="isUpForRecertification$ | async"
                                [isRecertificationOverdue]="isRecertificationOverdue$ | async"
                                (startAssessment)="startAssessment()"
                                (confirmRecertifyAssessment)="confirmRecertifyAssessment()"
                                (cancelAssessment)="cancelAssessment()"
                                (editRiskReview)="openReviewRiskDialog()"
                            />
                        </ng-template>
                    </mat-tab>
                    <mat-tab label="Artifacts and Risk Analysis" [id]="MainTabs.ARTIFACTS_AND_RISK_ANALYSIS">
                        <ng-container *ngIf="artifacts$ | async as artifacts">
                            <div class="row mt-3">
                                <div class="col d-flex align-items-center">
                                    <h3 class="lead mb-0">Artifacts ({{ artifacts.length }})</h3>
                                </div>
                                <div class="col text-end d-flex justify-content-end">
                                    <ng-container *hideIfOrgUserIsNotBusinessOwner="businessOwner$ | async">
                                        <button
                                            *jhiHasNotAuthority="[Roles.Admin, Roles.ReadOnly]"
                                            mat-button
                                            visoButton="secondary"
                                            [routerLink]="addArtifactRouterLink"
                                            [replaceUrl]="true"
                                            class="me-2 allow-pointer-events"
                                            data-pw="addArtifact"
                                            [disabled]="isCurrentUserSupport || !doesRelationshipHaveContext"
                                            [disableTooltip]="doesRelationshipHaveContext"
                                            ngbTooltip="Add Context to this Relationship before uploading artifacts"
                                            data-pw="addArtifact"
                                        >
                                            Add Artifact
                                        </button>
                                    </ng-container>
                                    <button
                                        type="button"
                                        class="shrink centered"
                                        mat-icon-button
                                        *jhiHasNotAuthority="Roles.ReadOnly"
                                        toggleDisableOnActions
                                        [disablingActions]="[ArtifactActions.DownloadRelationshipArtifactsAsZip]"
                                        [enablingActions]="[
                                            FileDownloadActions.SaveFileFromResBlob,
                                            ArtifactActions.DownloadRelationshipArtifactsAsZipFailed,
                                        ]"
                                        [disabled]="!artifacts.length"
                                        (click)="downloadArtifacts()"
                                    >
                                        <mat-icon>download</mat-icon>
                                    </button>
                                </div>
                            </div>
                            <div class="row mb-4">
                                <div class="col">
                                    <app-artifacts-list
                                        *ngIf="artifacts.length; else noArtifacts"
                                        [artifacts]="artifacts"
                                        [currentAccount]="currentAccount$ | async"
                                        [messages]="{ emptyMessage: '' }"
                                        [request]="request"
                                        [artifactSupersession]="artifactSupersession$ | async"
                                        [artifactRtpEventMap]="artifactRtpEvents$ | async"
                                        (deleteArtifact)="deleteArtifact($event)"
                                        (openArtifact)="openArtifact($event)"
                                    />
                                    <ng-template #noArtifacts>
                                        <div class="text-center">
                                            <p>We have nothing to show you yet</p>
                                            <ng-container *hideIfOrgUserIsNotBusinessOwner="businessOwner$ | async">
                                                <button
                                                    mat-button
                                                    visoButton="secondary"
                                                    color="default"
                                                    *jhiHasNotAuthority="[Roles.Admin, Roles.ReadOnly]"
                                                    class="allow-pointer-events"
                                                    [routerLink]="addArtifactRouterLink"
                                                    [replaceUrl]="true"
                                                    [disabled]="isCurrentUserSupport || !doesRelationshipHaveContext"
                                                    [disableTooltip]="doesRelationshipHaveContext"
                                                    ngbTooltip="Add Context to this Relationship before uploading artifacts"
                                                    data-pw="addArtifact"
                                                >
                                                    Add a file to this relationship
                                                </button>
                                            </ng-container>
                                        </div>
                                    </ng-template>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 mb-3">
                                    <h3 class="lead mb-0">Risk Analysis</h3>
                                </div>
                                <div class="col-12">
                                    <p>
                                        Display framework detections
                                        <em
                                            class="fa fa-info-circle me-1 mat-text-secondary"
                                            matTooltip="These annotations do not change or influence the risk score, risk analysis, or assessment summary."
                                        >
                                        </em>
                                    </p>
                                    <mat-form-field class="dense-3 w-25 mb-4">
                                        <mat-select
                                            class="framework-select"
                                            [formControl]="frameworkMappingFormControl"
                                        >
                                            <mat-option></mat-option>
                                            <mat-option
                                                *ngFor="
                                                    let frameworkMappingType of FrameworkMappingType
                                                        | enumToArray: FrameworkMappingTypeLabels
                                                "
                                                [value]="frameworkMappingType.value"
                                            >
                                                {{ frameworkMappingType.label }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="col">
                                    <mat-tab-group
                                        id="riskAnalysisTabset"
                                        mat-stretch-tabs="true"
                                        class="viso-tabset"
                                        animationDuration="0"
                                        dynamicHeight
                                    >
                                        <ng-container
                                            *ngFor="
                                                let controlGroup of groupedControls$ | async;
                                                trackBy: trackByControlGroup
                                            "
                                        >
                                            <ng-container
                                                *ngIf="{
                                                    controlDomainTypeEnabled:
                                                        (controlGroup.featureFlagEnabled$ | async) &&
                                                        !controlGroup.allControlsDisabled,
                                                } as ctx"
                                            >
                                                <mat-tab [id]="controlGroup.tabDomId">
                                                    <ng-template mat-tab-label>
                                                        <mat-icon
                                                            *ngIf="!ctx.controlDomainTypeEnabled"
                                                            fontSet="material-icons-two-tone"
                                                            class="me-2"
                                                            >lock
                                                        </mat-icon>
                                                        {{ controlGroup.controlDomainTypeLabel }}
                                                    </ng-template>

                                                    <mat-card
                                                        *ngIf="!ctx.controlDomainTypeEnabled"
                                                        [appearance]="'outlined'"
                                                        class="disabled-dimension-message mt-3 border-0"
                                                    >
                                                        <mat-card-content>
                                                            <p>
                                                                Contact
                                                                <a class="viso-link" href="mailto:sales@visotrust.com"
                                                                    ><strong>sales&#64;visotrust.com</strong></a
                                                                >
                                                                to enable this dimension
                                                            </p>
                                                        </mat-card-content>
                                                    </mat-card>

                                                    <app-security-control-domains
                                                        class="d-block mt-3"
                                                        [relationship]="request"
                                                        [ngClass]="{
                                                            'disabled-dimension': !ctx.controlDomainTypeEnabled,
                                                        }"
                                                        [securityControlDomain]="securityControlDomain$ | async"
                                                        [controls]="controlGroup.controlDomains"
                                                        [latestAssessmentStatus]="latestAssessmentStatus$ | async"
                                                        [latestAssessmentFollowUpQuestionnaireCreatedDate]="
                                                            latestAssessmentFollowUpQuestionnaireCreatedDate$ | async
                                                        "
                                                        [missingInformationWasRequested]="
                                                            missingInformationWasRequested$ | async
                                                        "
                                                        [assessmentSentToEmail]="
                                                            (latestAssessment$ | async)?.sentToEmail
                                                        "
                                                        [isLatestAssessmentNonDocumentsOnly]="
                                                            isLatestAssessmentNonDocumentsOnly$ | async
                                                        "
                                                        [isStatusRiskBased]="controlGroup.isStatusRiskBased"
                                                        [isStatusCompliantBased]="controlGroup.isStatusCompliantBased"
                                                        [isStatusInformationBased]="
                                                            controlGroup.isStatusInformationBased
                                                        "
                                                        [isOutOfScope]="controlGroup.allControlsOutOfScope"
                                                        [isNotEnabled]="controlGroup.allControlsDisabled"
                                                        [showOutOfScopeDomains]="
                                                            controlGroup.allControlsOutOfScope ||
                                                            controlGroup.allControlsDisabled
                                                        "
                                                        [artifactSupersession]="artifactSupersession$ | async"
                                                        [frameworkMappings]="frameworkMappings$ | async"
                                                        [selectedFramework]="selectedFrameworkMappingType$ | async"
                                                        (confirmRequestInformation)="confirmRequestInformation()"
                                                    >
                                                        <ng-container
                                                            no-controls-in-scope-message
                                                            *ngIf="!controlGroup.hideNoControlsInScopeMessage"
                                                        >
                                                            <p class="m-0">
                                                                No
                                                                {{ controlGroup.controlDomainTypeLabel }}
                                                                control domains in scope.
                                                            </p>
                                                        </ng-container>
                                                    </app-security-control-domains>
                                                </mat-tab>
                                            </ng-container>
                                        </ng-container>
                                    </mat-tab-group>
                                </div>
                            </div>
                        </ng-container>
                    </mat-tab>
                    <ng-container *ngIf="{ isRdpAiQAEnabled: hasRDPAIQAEnabled$ | async } as ctx">
                        <ng-template #disabledRDPAIQA>
                            Contact <strong>support&#64;visotrust.com</strong> to enable AI Q&A.
                        </ng-template>
                        <mat-tab [id]="MainTabs.AI_QA" [disabled]="!ctx.isRdpAiQAEnabled">
                            <ng-template mat-tab-label>
                                <span
                                    [ngbTooltip]="disabledRDPAIQA"
                                    container="body"
                                    [disableTooltip]="ctx.isRdpAiQAEnabled"
                                    >AI Q&A</span
                                >
                            </ng-template>
                            <app-rdp-ai-question-answering
                                [iiqrResponse]="iiqrResponse$ | async"
                                [hasArtifactsWithValidations]="hasArtifactsWithValidations$ | async"
                                [vendorName]="request.vendorName"
                                (askQuestion)="askQuestion($event)"
                                (goToRiskAnalysis)="goToRiskAnalysis()"
                            />
                        </mat-tab>
                    </ng-container>
                    <mat-tab label="Risk Timeline" [id]="MainTabs.RISK_TIMELINE">
                        <ng-container *ngIf="!isRiskTimelineListView; else riskTimelineList">
                            <app-risk-timeline
                                [riskAssessments]="riskAssessments$ | async"
                                [riskAssessmentsDatasets]="riskAssessmentsDatasets$ | async"
                                (toggleTimelineView)="toggleTimelineView()"
                            />
                        </ng-container>
                        <ng-template #riskTimelineList>
                            <app-risk-timeline-list
                                [riskAssessments]="riskAssessments$ | async"
                                (toggleTimelineView)="toggleTimelineView()"
                            />
                        </ng-template>
                    </mat-tab>
                    <mat-tab label="Attachments" [id]="MainTabs.ATTACHMENTS">
                        <div class="card attachments-container mt-3">
                            <div
                                class="card-header d-flex flex-row justify-content-between align-items-center border-bottom"
                            >
                                <div>
                                    <p class="card-title">Relationship Attachments</p>
                                    <p class="m-0">
                                        Add files you want to associate to this relationship. Attachments will not be
                                        assessed.
                                    </p>
                                </div>
                                <button
                                    type="button"
                                    mat-button
                                    visoButton="primary"
                                    [routerLink]="uploadAttachmentsRouterLink"
                                    *jhiHasNotAuthority="Roles.ReadOnly"
                                >
                                    Add Attachments
                                </button>
                            </div>
                            <div class="ms-auto me-3 pt-3" *jhiHasNotAuthority="Roles.ReadOnly">
                                <button
                                    *ngIf="hasAttachments$ | async"
                                    type="button"
                                    class="shrink centered"
                                    mat-icon-button
                                    [disabled]="!anyAttachmentSelected"
                                    toggleDisableOnActions
                                    [disablingActions]="[AttachmentActions.DownloadRelationshipAttachmentsAsZip]"
                                    [enablingActions]="[
                                        FileDownloadActions.SaveFileFromResBlob,
                                        AttachmentActions.DownloadRelationshipAttachmentsAsZipFailed,
                                    ]"
                                    (click)="downloadSelectedAttachments()"
                                >
                                    <mat-icon>download</mat-icon>
                                </button>
                            </div>
                            <app-attachments
                                [attachments]="attachments$ | async"
                                (onAttachmentSelected)="onAttachmentSelected($event)"
                                (deleteAttachment)="deleteAttachment($event)"
                                (downloadAttachment)="downloadAttachment($event)"
                            />
                        </div>
                    </mat-tab>
                    <mat-tab label="Details" [id]="MainTabs.DETAILS">
                        <app-vendor-details-tab
                            [vendorOrgId]="request.vendorId"
                            [isTabSelected]="tabGroupCtx.selectedTab === MainTabs.DETAILS"
                        ></app-vendor-details-tab>
                    </mat-tab>
                    <mat-tab label="Comments" [id]="MainTabs.COMMENTS">
                        <app-comment-handler
                            [comments]="comments$ | async"
                            [currentAccount]="currentAccount$ | async"
                            [mentions]="mentions$ | async"
                            [fastMentions]="fastMentions$ | async"
                            (createComment)="createComment($event)"
                            (deleteComment)="deleteComment($event)"
                        />
                    </mat-tab>
                </mat-tab-group>
            </ng-container>
        </div>
    </div>
</div>

<app-relationship-detail-page-export
    *ngIf="showPrintArea$ | async"
    pdfExportable
    actionRemover
    class="d-none d-print-block"
    [request]="request"
    [relationshipSubscribers]="relationshipSubscribers$ | async"
    [primaryVendorContact]="primaryVendorContact$ | async"
    [businessOwner]="businessOwner$ | async"
    [latestRiskAssessment]="latestRiskAssessment$ | async"
    [latestNonTransitionalRiskAssessment]="latestNonTransitionalRiskAssessment$ | async"
    [latestCompletedAssessmentDate]="latestCompletedAssessmentDate$ | async"
    [artifacts]="artifacts$ | async"
    [groupedControlDomains]="groupedControls$ | async"
    [controlDomains]="controls$ | async"
    [riskAssessments]="riskAssessments$ | async"
    [riskAssessmentsDatasets]="riskAssessmentsDatasets$ | async"
    [securityControlDomain]="securityControlDomain$ | async"
    [latestAssessment]="latestAssessment$ | async"
    [latestAssessmentStatus]="latestAssessmentStatus$ | async"
    [comments]="comments$ | async"
    [currentAccount]="currentAccount$ | async"
    [userIsTrial]="userIsTrial$ | async"
    [dataTypesProfileConfig]="dataTypesProfileConfig$ | async"
    [latestAssessmentFollowUpQuestionnaireCreatedDate]="latestAssessmentFollowUpQuestionnaireCreatedDate$ | async"
    [missingInformationWasRequested]="missingInformationWasRequested$ | async"
    [pastAssessments]="pastAssessments$ | async"
    [isOnlyLegacyAssessmentPresent]="isOnlyLegacyAssessmentPresent$ | async"
    [isUpForRecertification]="isUpForRecertification$ | async"
    [isRecertificationOverdue]="isRecertificationOverdue$ | async"
    [isAssessmentInProgress]="isAssessmentInProgress$ | async"
    [recertificationDueDaysLeftCount]="recertificationDueDaysLeftCount$ | async"
    [isLatestAssessmentNonDocumentsOnly]="isLatestAssessmentNonDocumentsOnly$ | async"
    [tags]="relationshipTags$ | async"
    [attachments]="attachments$ | async"
    [artifactSupersession]="artifactSupersession$ | async"
    [assessmentSummarySectionConfig]="assessmentSummarySectionConfig$ | async"
/>
