import { Route } from '@angular/router';
import { FeatureFlagManagementComponent } from './feature-flag-management.component';

export const featureFlagManagementRoute: Route = {
    path: 'feature-flags',
    component: FeatureFlagManagementComponent,
    data: {
        pageTitle: 'Feature Flags',
    },
};
