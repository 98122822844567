import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { AppState } from '../../../shared/redux/state';
import { Tag, TagPagination } from '../../../entities/tag';
import { TagsState, tagsStateKey } from './tag.state';

export const getControlsState: MemoizedSelector<AppState, TagsState> = createFeatureSelector<TagsState>(tagsStateKey);

export const getTagsSelector: MemoizedSelector<AppState, Tag[]> = createSelector(
    getControlsState,
    (state) => state.tags,
);

export const getTagPaginationSelector: MemoizedSelector<AppState, TagPagination> = createSelector(
    getControlsState,
    (state) => state.pagination,
);

export const getSelectedTagSelector: MemoizedSelector<AppState, Tag> = createSelector(
    getControlsState,
    (state) => state.selectedTag,
);

export const getLoadingSelector: MemoizedSelector<AppState, boolean> = createSelector(
    getControlsState,
    (state) => state.loading,
);
