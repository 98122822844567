import { Observable, of, Subject } from 'rxjs';
import { catchError, concatMap, map, switchMap, tap } from 'rxjs/operators';
import { ErrorHandler, Injectable, Injector, NgZone } from '@angular/core';
import { Location } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import {
    ErrorModalData,
    GlobalErrorModalComponent,
} from '../components/global-error-modal/global-error-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import LogRocket from 'logrocket';
import { environment } from 'src/main/webapp/environments/environment';

export class LightError extends Error {
    name = 'LightError';
    constructor(
        public message: string,
        public options?: {
            subheader?: string;
            messageHtml?: string;
            buttonText?: string;
            details?: {};
        },
    ) {
        super(message);
        Object.setPrototypeOf(this, LightError.prototype);
    }
}
export class ModerateError extends Error {
    error: Error;
    critical: boolean;

    constructor(error: Error, message?: string, critical?: boolean) {
        super(message);

        this.error = error;
        this.critical = critical;
        Object.setPrototypeOf(this, ModerateError.prototype);
    }
}

export class SilentError extends Error {
    name = 'SilentError';
    constructor(
        public message: string,
        public showModal: boolean,
    ) {
        super(message);
        Object.setPrototypeOf(this, SilentError.prototype);
    }
}

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
    private _errorSubject$: Subject<unknown>;

    get window() {
        return window;
    }

    constructor(
        private _injector: Injector,
        private _location: Location,
    ) {
        this._errorSubject$ = new Subject();

        this._errorSubject$
            .pipe(
                concatMap((error) =>
                    of(error).pipe(
                        map(() => this.prepareErrorDialogData(error)),
                        tap((data) => {
                            if (environment.logrocketClientId) {
                                LogRocket.captureException(data.error);
                            }
                        }),
                        switchMap((data: unknown) => {
                            const ngZone = this._injector.get<NgZone>(NgZone);
                            return ngZone.run(() => {
                                // TODO: Disabling error message in production, remove it in next sprint
                                const errorModalData = data as ErrorModalData;
                                if (!environment.production && errorModalData.showModal) {
                                    return this.openDialog(errorModalData);
                                } else {
                                    return of(true);
                                }
                            });
                        }),
                        catchError((openDialogError) => {
                            this.globalErrorHandlerFailed(openDialogError);
                            return of(null);
                        }),
                    ),
                ),
            )
            .subscribe();
    }

    handleError(error: any): void {
        this.window.console.error(error);
        if (error?.error instanceof Object) {
            this.window.console.error(error.error);
        }
        // TODO: send request
        try {
            this._errorSubject$.next(error);
        } catch (handlerError) {
            this.globalErrorHandlerFailed(handlerError);
        }
    }

    openDialog(data: ErrorModalData) {
        const modalService = this._injector.get<NgbModal>(NgbModal);
        const modalRef = modalService.open(GlobalErrorModalComponent, {
            centered: true,
            backdrop: 'static',
        });
        modalRef.componentInstance.data = data;
        modalRef.componentInstance.prepareErrorStrings();
        return modalRef.closed as Observable<any>;
    }

    // generates ErrorDialogData (error data for dialogs) basing on error object
    private prepareErrorDialogData(error: any): ErrorModalData {
        // Listen for LightError in promises rejection
        if (error.promise && error.rejection && error.rejection instanceof LightError) {
            error = error.rejection;
        }

        // Custom LightError
        if (error instanceof LightError) {
            return {
                error,
                errorSubheader: error.options?.subheader,
                errorMainMessage: error.message,
                errorMainMessageHtml: error.options?.messageHtml,
                buttonText: error.options?.buttonText,
                errorDetails: error.options?.details,
            };
        }

        if (error instanceof ModerateError) {
            const nestedData = this.prepareErrorDialogData(error.error);
            return {
                error,
                defaultDismiss: !error.critical,
                errorMainData: nestedData.errorMainData,
                errorDetails: {
                    message: error.critical ? 'Critical Error' : 'Moderate Error',
                    error: nestedData.errorDetails,
                },
            };
        }

        if (error instanceof SilentError) {
            return {
                error,
                showModal: error.showModal,
            };
        }

        // Listen for default promise errors
        if (error.promise && error.rejection) {
            const nestedData = this.prepareErrorDialogData(error.rejection);
            return {
                error,
                defaultDismiss: nestedData.defaultDismiss,
                errorMainData: nestedData.errorMainData,
                errorDetails: {
                    message: error.message,
                    error: nestedData.errorDetails,
                    stack: error.stack && error.stack.split('\n'),
                },
            };
        }

        // Logic error in Typescript/Javascript
        if (error instanceof Error) {
            return {
                error,
                defaultDismiss: true,
                errorDetails: {
                    message: error.message,
                    stack: error.stack && error.stack.split('\n'),
                },
            };
        }

        // Http error
        if (error instanceof HttpErrorResponse) {
            const errorMainData = new Map();
            if (error.error) {
                const message = error.error.message || (error.error.error && error.error.error.message);
                if (message) {
                    errorMainData.set('Message', message);
                }
            }

            let headers: { [key: string]: string | string[] };
            try {
                headers = {};
                error.headers.keys().forEach((key) => {
                    const headerValues = error.headers.getAll(key);
                    headers[key] = headerValues.length === 1 ? headerValues[0] : headerValues;
                });
            } catch (e) {
                this.window.console.error(e);
            }

            const errorDetails = {
                responseBody: error.error,
                url: error.url,
                type: error.type,
                ok: error.ok,
                status: error.status,
                statusText: error.statusText,
                headers,
            };

            return {
                error,
                defaultDismiss: true,
                errorMainData,
                errorDetails,
            };
        }

        return { error, errorDetails: error };
    }

    private globalErrorHandlerFailed(error: unknown) {
        // exception while displaying modal to the user
        this.window.console.error(
            'GlobalErrorHandler failed to display error dialog for above error, due to following error:',
        );
        this.window.console.error(error);

        if (this.window.confirm('Application has crashed. Do you want to refresh it?') === true) {
            this.window.location.href = this._location.prepareExternalUrl('/');
        }
    }
}
