import { Action, createReducer, on } from '@ngrx/store';
import { setViewport } from './actions';
import { AppState, initialState } from './state';

const reducer = createReducer(
    initialState,
    on(setViewport, (state, { width }) => ({
        ...state,
        resolution: width,
    })),
);

export function appReducer(state: AppState = initialState, action: Action): AppState {
    return reducer(state, action);
}
