<nav class="navbar topnavbar basic-navbar" role="navigation">
    <div class="navbar-header basic-header">
        <a class="navbar-brand" href="/">
            <div class="brand-logo">
                <img
                    *ngIf="!(isPhoneResolution$ | async)"
                    class="img-fluid"
                    src="content/img/viso_navbar_brand_text_only.png"
                    alt="App Logo"
                />
                <img
                    *ngIf="isPhoneResolution$ | async"
                    class="img-fluid basic-logo"
                    src="content/img/viso_navbar_brand.png"
                    alt="App Logo"
                />
            </div>
        </a>
    </div>

    <ul class="navbar-nav flex-row">
        <li class="nav-item">
            <span class="support-text">
                Need help? Email us at <a href="mailto:support@visotrust.com">support&#64;visotrust.com</a>
            </span>
        </li>
    </ul>
</nav>
