import { createAction, props } from '@ngrx/store';

export enum YourOrganizationActions {
    UploadCompanyLogoRequest = '[Your Organization] Upload Company Logo Request',
    UploadCompanyLogoRequestSuccess = '[Your Organization] Upload Company Logo Request Success',
    UploadCompanyLogoRequestFailed = '[Your Organization] Upload Company Logo Request Failed',
    UploadCompanyLogoRequestCancelled = '[Your Organization] Upload Company Logo Request Cancelled',
    UploadCompanyLogoRequestProgress = '[Your Organization] Upload Company Logo Request Progress',

    UploadCompanyIconRequest = '[Your Organization] Upload Company Icon Request',
    UploadCompanyIconRequestSuccess = '[Your Organization] Upload Company Icon Request Success',
    UploadCompanyIconRequestFailed = '[Your Organization] Upload Company Icon Request Failed',
    UploadCompanyIconRequestCancelled = '[Your Organization] Upload Company Icon Request Cancelled',
    UploadCompanyIconRequestProgress = '[Your Organization] Upload Company Icon Request Progress',
}

export const uploadCompanyLogoRequest = createAction(
    YourOrganizationActions.UploadCompanyLogoRequest,
    props<{ orgId: number; file: File }>(),
);

export const uploadCompanyLogoRequestCancelled = createAction(
    YourOrganizationActions.UploadCompanyLogoRequestCancelled,
);

export const uploadCompanyLogoRequestSuccess = createAction(YourOrganizationActions.UploadCompanyLogoRequestSuccess);

export const uploadCompanyLogoRequestFailed = createAction(YourOrganizationActions.UploadCompanyLogoRequestFailed);

export const uploadCompanyLogoRequestProgress = createAction(
    YourOrganizationActions.UploadCompanyLogoRequestProgress,
    props<{ percent: number }>(),
);

export const uploadCompanyIconRequest = createAction(
    YourOrganizationActions.UploadCompanyIconRequest,
    props<{ orgId: number; file: File }>(),
);

export const uploadCompanyIconRequestCancelled = createAction(
    YourOrganizationActions.UploadCompanyIconRequestCancelled,
);

export const uploadCompanyIconRequestSuccess = createAction(YourOrganizationActions.UploadCompanyIconRequestSuccess);

export const uploadCompanyIconRequestFailed = createAction(YourOrganizationActions.UploadCompanyIconRequestFailed);

export const uploadCompanyIconRequestProgress = createAction(
    YourOrganizationActions.UploadCompanyIconRequestProgress,
    props<{ percent: number }>(),
);
