<ngx-extended-pdf-viewer
    #pdfViewer
    [src]="src"
    [textLayer]="true"
    [height]="height"
    [zoom]="zoom"
    [page]="page"
    [password]="password"
    [ignoreKeyboard]="true"
    [useBrowserLocale]="true"
    [showToolbar]="true"
    [showSidebarButton]="false"
    [showFindButton]="true"
    [showPagingButtons]="true"
    [showDrawEditor]="false"
    [showTextEditor]="false"
    [showZoomButtons]="true"
    [showPresentationModeButton]="false"
    [showOpenFileButton]="false"
    [showPrintButton]="false"
    [showDownloadButton]="false"
    [showSecondaryToolbarButton]="false"
    [showRotateButton]="false"
    [showHandToolButton]="true"
    [showScrollingButton]="false"
    [showSpreadButton]="false"
    [showPropertiesButton]="false"
    [showStampEditor]="false"
></ngx-extended-pdf-viewer>
