import {
    ChangeDetectorRef,
    Directive,
    InjectionToken,
    Injector,
    Input,
    OnDestroy,
    TemplateRef,
    ViewContainerRef,
} from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';

@Directive({
    selector: '[dynamicContent]',
})
export class DynamicContentDirective implements OnDestroy {
    private subscription: Subscription;
    private lastContainer: ViewContainerRef;

    constructor(
        private templateRef: TemplateRef<any>,
        private injector: Injector,
    ) {}

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }

        if (this.lastContainer) {
            this.lastContainer.clear();
        }
    }

    @Input() set dynamicContent(containerToken: InjectionToken<BehaviorSubject<ViewContainerRef>>) {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }

        this.subscription = this.injector.get(containerToken).subscribe((viewContainerRef) => {
            if (this.lastContainer) {
                this.lastContainer.clear();
            }
            this.lastContainer = viewContainerRef;
            if (viewContainerRef) {
                viewContainerRef.createEmbeddedView(this.templateRef);
                viewContainerRef.injector.get(ChangeDetectorRef).detectChanges();
            }
        });
    }
}
