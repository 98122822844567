<mat-card>
    <mat-card-header>
        <img class="integration-icon" src="{{ integrationDefinition.faviconSrc }}" alt="Integration Icon" />
        <ng-container *ngIf="isConnectedToIntegration">
            <app-badge class="top-right" [type]="'light-primary'" extraCssClasses="lead me-2">Connected</app-badge>
        </ng-container>
    </mat-card-header>
    <mat-card-header>
        <mat-card-title class="text-bold">{{
            IntegrationTypeLabels[integrationDefinition.integrationType]
        }}</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <p>{{ integrationDefinition.description }}</p>
    </mat-card-content>
    <mat-card-footer>
        <mat-divider></mat-divider>
        <ng-container *ngIf="integrationDefinition.comingSoon; else available">
            <mat-card-actions
                placement="top"
                container="body"
                [ngbTooltip]="isInterestedInIntegration ? 'We will notify you when this integration is released' : ''"
            >
                <ng-container *ngIf="isInterestedInIntegration">
                    <mat-icon class="text-md opacity-25 check-icon">check</mat-icon>
                </ng-container>
                <button
                    class="integration-action-button p-2"
                    mat-button
                    visoButton="link"
                    color="primary"
                    [disabled]="isInterestedInIntegration"
                    (click)="keepMeUpdated.emit(integrationDefinition.integrationType)"
                >
                    <span>Keep me updated</span>
                </button>
            </mat-card-actions>
        </ng-container>
        <ng-template #available>
            <mat-card-actions>
                <ng-container
                    *ngTemplateOutlet="
                        isConnectedToIntegration
                            ? integrationDefinition.connectedTemplate
                            : integrationDefinition.disconnectedTemplate
                    "
                ></ng-container>
            </mat-card-actions>
        </ng-template>
    </mat-card-footer>
</mat-card>
