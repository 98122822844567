import { ChangeDetectionStrategy, Component, Input, Output, EventEmitter } from '@angular/core';
import { RiskAssessment } from '../../../entities/risk-assessment';

@Component({
    selector: 'app-risk-timeline-list',
    templateUrl: './risk-timeline-list.component.html',
    styleUrls: ['./risk-timeline-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RiskTimelineListComponent {
    @Input()
    riskAssessments: RiskAssessment[] = [];

    @Output()
    toggleTimelineView = new EventEmitter<void>();
}
