import { Route } from '@angular/router';

import { WebhooksComponent } from './webhooks.component';

export const webhooksRoute: Route = {
    path: 'webhooks',
    component: WebhooksComponent,
    data: {
        pageTitle: 'Webhooks',
    },
};
