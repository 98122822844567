import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { QuillMentionParseHelperService } from '../../../../shared/components/quill-wrapper/services/quill-mention-parse-helper.service';
import { RelationshipService } from '../../../../entities/relationship';
import { RelationshipCommentService } from '../../../../entities/relationship-comment';
import {
    getRequestCommentsRequest,
    getRequestCommentsRequestSuccess,
    getRequestCommentsRequestFailed,
    createRequestCommentRequest,
    createRequestCommentRequestFailed,
    createRequestCommentRequestSuccess,
    deleteRequestCommentRequest,
    deleteRequestCommentRequestFailed,
    deleteRequestCommentRequestSuccess,
} from '../actions/comments.actions';

@Injectable()
export class CommentEffects {
    createRequestCommentRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(createRequestCommentRequest),
            map(({ requestId, commentMessage }) => ({
                requestId,
                commentMessage: this._quillMentionParseHelperService.convertHtmlMentions(commentMessage),
            })),
            switchMap(({ requestId, commentMessage }) =>
                this._relationshipService.addComment(requestId, commentMessage).pipe(
                    map(() => createRequestCommentRequestSuccess()),
                    catchError(() => of(createRequestCommentRequestFailed())),
                ),
            ),
        ),
    );

    getRequestCommentsRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(getRequestCommentsRequest),
            switchMap(({ requestId }) =>
                this._relationshipService.getComments(requestId).pipe(
                    map((response) => response.body),
                    map((comments) => getRequestCommentsRequestSuccess({ comments })),
                    catchError(() => of(getRequestCommentsRequestFailed())),
                ),
            ),
        ),
    );

    deleteRequestCommentRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(deleteRequestCommentRequest),
            switchMap(({ requestId, commentId }) =>
                this._relationshipCommentService.delete(commentId).pipe(
                    map(() => deleteRequestCommentRequestSuccess()),
                    catchError(() => of(deleteRequestCommentRequestFailed())),
                ),
            ),
        ),
    );

    constructor(
        private _actions$: Actions,
        private _relationshipCommentService: RelationshipCommentService,
        private _relationshipService: RelationshipService,
        private _quillMentionParseHelperService: QuillMentionParseHelperService,
    ) {}
}
