import { createAction, props } from '@ngrx/store';

export enum BrandingActions {
    OpenBrandingColorConfirmationModal = '[Branding] Open Color Confirmation Modal',
    OpenDeleteBrandingColorConfirmationModal = '[Branding] Open Delete Color Confirmation Modal',

    SaveBrandingColorRequest = '[Branding] Save Color Request',
    SaveBrandingColorRequestSuccess = '[Branding] Save Color Request Success',
    SaveBrandingColorRequestFailure = '[Branding] Save Color Request Failure',

    DeleteBrandingColorRequest = '[Branding] Delete Color Request',
    DeleteBrandingColorRequestSuccess = '[Branding] Delete Color Request Success',
    DeleteBrandingColorRequestFailure = '[Branding] Delete Color Request Failure',

    DeleteCompanyImagesRequest = '[Branding] Delete Company Images Request',
    DeleteCompanyImagesRequestSuccess = '[Branding] Delete Company Images Request Success',
    DeleteCompanyImagesRequestFailure = '[Branding] Delete Company Images Request Failure',
}

export const openBrandingColorConfirmationModal = createAction(
    BrandingActions.OpenBrandingColorConfirmationModal,
    props<{ hex: string }>(),
);

export const openDeleteBrandingColorConfirmationModal = createAction(
    BrandingActions.OpenDeleteBrandingColorConfirmationModal,
);

export const saveBrandingColorRequest = createAction(
    BrandingActions.SaveBrandingColorRequest,
    props<{ hex: string }>(),
);

export const saveBrandingColorRequestSuccess = createAction(
    BrandingActions.SaveBrandingColorRequestSuccess,
    props<{ hex: string }>(),
);

export const deleteBrandingColorRequest = createAction(BrandingActions.DeleteBrandingColorRequest);

export const deleteBrandingColorRequestSuccess = createAction(BrandingActions.DeleteBrandingColorRequestSuccess);

export const deleteBrandingColorRequestFailure = createAction(BrandingActions.DeleteBrandingColorRequestFailure);

export const saveBrandingColorRequestFailure = createAction(BrandingActions.SaveBrandingColorRequestFailure);

export const deleteCompanyImagesRequest = createAction(BrandingActions.DeleteCompanyImagesRequest);

export const deleteCompanyImagesRequestSuccess = createAction(BrandingActions.DeleteCompanyImagesRequestSuccess);

export const deleteCompanyImagesRequestFailure = createAction(BrandingActions.DeleteCompanyImagesRequestFailure);
