import { Routes } from '@angular/router';

import { IntegrationsComponent } from './integrations.component';
import { AuthGuard } from '../../shared';
import { VisoUserRole } from '../../entities/viso-user';

export const integrationsRoutes: Routes = [
    {
        path: 'integrations',
        component: IntegrationsComponent,
        data: {
            authorities: [VisoUserRole.OrgAdmin],
            pageTitle: 'Integrations',
        },
        canActivate: [AuthGuard],
    },
    {
        path: 'coupa-integration',
        children: [],
        data: {
            authorities: [VisoUserRole.OrgAdmin],
            pageTitle: 'Coupa Integration',
        },
        canActivate: [AuthGuard],
        outlet: 'popup',
    },
];
