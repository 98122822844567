import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { getIsAuthenticated } from '../../routes/session/redux/session.selectors';
import { AppState } from '../redux/state';

@Injectable({
    providedIn: 'root',
})
export class NoSessionGuard {
    constructor(private _store$: Store<AppState>) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this._store$.pipe(
            select(getIsAuthenticated),
            map((isAuthenticated) => !isAuthenticated),
            take(1),
        );
    }
}
