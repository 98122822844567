import { Injectable } from '@angular/core';
import { SnackbarService } from '@shared/components/snackbar/snackbar.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { uploadFilesRequestSuccess } from '../actions';
import { filter, tap } from 'rxjs/operators';

@Injectable()
export class SnackbarEffects {
    errorOnDuplicateUploadToPublicAssessment$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(uploadFilesRequestSuccess),
                filter(({ duplicateArtifactFileNames }) => !!duplicateArtifactFileNames.length),
                tap(({ duplicateArtifactFileNames }) =>
                    this._snackbarService.error(
                        'One or more artifacts are identical to a file already collected by this client for your relationship, they are not necessary to provide again at this time:' +
                            duplicateArtifactFileNames.map((dup) => `<li>${dup}</li>`).join(''),
                        {
                            config: { duration: 10000 },
                        },
                    ),
                ),
            ),
        { dispatch: false },
    );

    constructor(
        private _actions$: Actions,
        private _snackbarService: SnackbarService,
    ) {}
}
