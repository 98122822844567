import { Route } from '@angular/router';
import { HubspotManagementComponent } from './hubspot-management.component';

export const hubspotManagementRoute: Route = {
    path: 'hubspot-management',
    component: HubspotManagementComponent,
    data: {
        pageTitle: 'Hubspot Management',
    },
};
