import {
    AfterContentInit,
    Component,
    ContentChildren,
    forwardRef,
    Input,
    Provider,
    QueryList,
    ViewEncapsulation,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CheckboxGroupItemDirective } from '../checkbox-group-item/checkbox-group-item.directive';
import { CheckboxGroupItemData } from '../models/checkbox-group.model';
import { MAT_CHECKBOX_DEFAULT_OPTIONS, MatCheckbox } from '@angular/material/checkbox';

const CHECK_BOX_VALUE_ACCESSOR: Provider = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CheckboxGroupComponent),
    multi: true,
};

@Component({
    selector: 'app-checkbox-group',
    templateUrl: './checkbox-group.component.html',
    styleUrls: ['./checkbox-group.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [
        CHECK_BOX_VALUE_ACCESSOR,
        {
            provide: MAT_CHECKBOX_DEFAULT_OPTIONS,
            useValue: { clickAction: 'noop' },
        },
    ],
})
export class CheckboxGroupComponent implements ControlValueAccessor, AfterContentInit {
    @Input() direction: 'row' | 'column' = 'row';
    @Input() checkboxGroupName: string;
    @Input() checkboxPosition: 'right' | 'left' = 'left';

    @ContentChildren(CheckboxGroupItemDirective) checkboxGroupItem: QueryList<CheckboxGroupItemDirective>;

    checkedValues: unknown[] = [];
    disabled = false;
    groupItemData: CheckboxGroupItemData[] = [];

    private _onTouched: Function = () => {};
    private _onChanged: Function = (value: unknown) => {};

    ngAfterContentInit(): void {
        this.checkboxGroupItem.forEach((item) =>
            this.groupItemData.push({
                value: item.value,
                disabled: item.disabled,
                templateRef: item.templateRef,
            }),
        );
    }

    check(value: unknown, checkboxRef: MatCheckbox) {
        checkboxRef.toggle();
        this._onTouched();
        if (checkboxRef.checked) {
            this.checkedValues = [...this.checkedValues, value];
        } else {
            this.checkedValues = this.checkedValues.filter((item) => item !== value);
        }
        this._onChanged(this.checkedValues);
    }

    writeValue(value: unknown | unknown[]): void {
        if (Array.isArray(value)) {
            this.checkedValues = [...value];
        } else {
            this.checkedValues.push(value ?? this.groupItemData[0]?.value);
        }
    }

    registerOnChange(fn: any): void {
        this._onChanged = fn;
    }

    registerOnTouched(fn: any): void {
        this._onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    checkStatus(item: CheckboxGroupItemData, checkedValues: unknown[]) {
        return checkedValues.some((selectedValue) => selectedValue === item.value);
    }
}
