import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { Store, select } from '@ngrx/store';
import { Observable, filter, forkJoin, map, of, switchMap, take } from 'rxjs';
import { Artifact, ArtifactService, FileArtifact } from '../../../entities/artifact';
import { getControls } from '../../controls/redux/controls.selectors';
import { DetectionItem } from '../models/detection';
import { DetectionsHelperService } from './detections-helper.service';
import { AuditReportService } from '../../../entities/audit-report';

interface ModalData {
    artifact: Artifact;
    artifactAuditType: string;
    controlsValidatedCount: number;
    detections: DetectionItem[];
    password?: string;
    pdfResponseBlob: HttpResponse<Blob>;
}

@Injectable()
export class PdfArtifactViewerModalHelperService {
    constructor(
        private _store$: Store,
        private _artifactService: ArtifactService,
        private _auditReportService: AuditReportService,
        private _detectionsHelperService: DetectionsHelperService,
    ) {}

    getModalData(artifactId: number): Observable<ModalData> {
        return forkJoin([
            this._artifactService.find(artifactId).pipe(map((res) => res.body)),
            this._artifactService.downloadArtifact(artifactId),
        ]).pipe(
            switchMap(([artifact, pdfResponseBlob]) =>
                (artifact as FileArtifact).hasPassword
                    ? this._artifactService.getArtifactPassword(artifactId).pipe(
                          map((res) => res.body),
                          map(({ password }) => ({ artifact, password, pdfResponseBlob })),
                      )
                    : of({ artifact, password: null, pdfResponseBlob }),
            ),
            switchMap(({ artifact, password, pdfResponseBlob }) =>
                this._store$.pipe(select(getControls)).pipe(
                    filter((controls) => !!controls.length),
                    map((controls) => ({ artifact, controls, password, pdfResponseBlob })),
                    take(1),
                ),
            ),
            map(({ artifact, controls, password, pdfResponseBlob }) => ({
                artifact,
                pdfResponseBlob,
                password,
                artifactAuditType: this._auditReportService.getAuditTypeFriendlyName(
                    artifact.validation?.auditReportType,
                ),
                controlsValidatedCount: this._detectionsHelperService.getControlsValidatedCount(artifact),
                detections: this._detectionsHelperService.getDetectionsList(artifact, controls),
            })),
        );
    }
}
