import * as fromRoot from '../../../shared/redux/state';
import { ContextType } from '../../../entities/context-type';

export const contextTypesStateKey = 'contextTypes';

export const initialState: ContextTypesState = {
    contextTypes: [],
};

export interface AppState extends fromRoot.AppState {
    [contextTypesStateKey]: ContextTypesState;
}

export interface ContextTypesState {
    contextTypes: ContextType[];
}
