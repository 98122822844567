import { Component, OnDestroy, OnInit } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import {
    UntypedFormBuilder,
    UntypedFormControl,
    UntypedFormGroup,
    ValidationErrors,
    ValidatorFn,
    Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { tap, takeUntil, take } from 'rxjs/operators';
import { FormUtilsService } from '../../shared/utils/form-utils.service';
import { noWhitespaceValidator } from '../../shared/validators/whitespace-validator';
import { signUpRequest, signUpRequestFailed, signUpRequestSuccess } from './redux/user-account.actions';
import { environment } from '../../../environments/environment';
import { LinkedInScriptInjectorService } from './linked-in-script-injector.service';
import { HubspotScriptInjectorService } from './hubspot-script-injector.service';

export type CharValidatorType = 'lowercase' | 'uppercase' | 'number' | 'symbol';

@Component({
    selector: 'app-user-registration',
    templateUrl: './user-registration.component.html',
    styleUrls: ['./user-registration.component.scss'],
    providers: [LinkedInScriptInjectorService, HubspotScriptInjectorService],
})
export class UserRegistrationComponent implements OnInit, OnDestroy {
    submitSuccess: boolean;
    creatingAccount: boolean;

    showAccountCreationError: boolean;
    accountCreationError: string;
    utmSource: string;
    utmCampaign: string;
    utmMedium: string;
    passwordVisibility: boolean;

    userRegistrationForm: UntypedFormGroup;

    environment = environment;

    private _unsub$ = new Subject<void>();

    constructor(
        private _fb: UntypedFormBuilder,
        private _formUtils: FormUtilsService,
        private _store$: Store,
        private _actions$: Actions,
        private _linkedInScriptInjectorService: LinkedInScriptInjectorService,
        private _hubspotScriptInjectorService: HubspotScriptInjectorService,
        private route: ActivatedRoute,
    ) {}

    get showRequiredEmailError(): boolean {
        const emailFormControl = this.userRegistrationForm.controls.email;
        return emailFormControl.errors?.required && this.isEmailDirtyOrTouched;
    }

    get showInvalidEmailError(): boolean {
        const emailFormControl = this.userRegistrationForm.controls.email;
        return emailFormControl.errors?.email && emailFormControl.touched;
    }

    get showEmailMaxLengthError(): boolean {
        const emailFormControl = this.userRegistrationForm.controls.email;
        return emailFormControl.errors?.maxlength && this.isEmailDirtyOrTouched;
    }

    get showRequiredPasswordError(): boolean {
        const passwordFormControl = this.userRegistrationForm.controls.password;
        return passwordFormControl.errors?.required && this.isPasswordDirtyOrTouched;
    }

    get showEmailIncludedOnPasswordError(): boolean {
        const passwordFormControl = this.userRegistrationForm.controls.password;
        return passwordFormControl.errors?.emailIncluded && this.isPasswordDirtyOrTouched;
    }

    get isButtonEnabled(): boolean {
        return (
            !this.userRegistrationForm.controls.email.errors?.required &&
            !this.userRegistrationForm.controls.email.errors &&
            !this.userRegistrationForm.controls.password.errors?.required &&
            !this.hasPasswordRequirementsError
        );
    }

    get hasPasswordMinLength(): boolean {
        const passwordFormControl = this.userRegistrationForm.controls.password;
        return passwordFormControl.dirty && passwordFormControl.value && !passwordFormControl.hasError('minlength');
    }

    get hasPasswordAtLeastOneSymbol(): boolean {
        const passwordFormControl = this.userRegistrationForm.controls.password;
        return passwordFormControl.dirty && passwordFormControl.value && !passwordFormControl.hasError('symbol');
    }

    get hasPasswordAtLeastOneNumber(): boolean {
        const passwordFormControl = this.userRegistrationForm.controls.password;
        return passwordFormControl.dirty && passwordFormControl.value && !passwordFormControl.hasError('number');
    }

    get hasPasswordLowerCaseLetters(): boolean {
        const passwordFormControl = this.userRegistrationForm.controls.password;
        return passwordFormControl.dirty && passwordFormControl.value && !passwordFormControl.hasError('lowercase');
    }

    get hasPasswordUpperCaseLetters(): boolean {
        const passwordFormControl = this.userRegistrationForm.controls.password;
        return passwordFormControl.dirty && passwordFormControl.value && !passwordFormControl.hasError('uppercase');
    }

    get showPasswordRequirementsError(): boolean {
        return this.userRegistrationForm.controls.password.dirty && this.hasPasswordRequirementsError;
    }

    get hasPasswordRequirementsError(): boolean {
        return (
            !this.hasPasswordMinLength ||
            !this.hasPasswordAtLeastOneSymbol ||
            !this.hasPasswordAtLeastOneNumber ||
            !this.hasPasswordLowerCaseLetters ||
            !this.hasPasswordUpperCaseLetters
        );
    }

    get hasPasswordRequirementsSuccess(): boolean {
        return (
            this.hasPasswordMinLength &&
            this.hasPasswordAtLeastOneSymbol &&
            this.hasPasswordAtLeastOneNumber &&
            this.hasPasswordLowerCaseLetters &&
            this.hasPasswordUpperCaseLetters
        );
    }

    private get isEmailDirtyOrTouched(): boolean {
        const emailFormControl = this.userRegistrationForm.controls.email;
        return emailFormControl.dirty || emailFormControl.touched;
    }

    private get isPasswordDirtyOrTouched(): boolean {
        const passwordFormControl = this.userRegistrationForm.controls.password;
        return passwordFormControl.dirty || passwordFormControl.touched;
    }

    ngOnInit(): void {
        this.route.queryParams.pipe(take(1)).subscribe((params) => {
            this.utmCampaign = params.utm_campaign;
            this.utmSource = params.utm_source;
            this.utmMedium = params.utm_medium;
        });

        this.userRegistrationForm = this._fb.group({
            email: [
                '',
                [
                    Validators.required,
                    Validators.minLength(5),
                    Validators.maxLength(100),
                    Validators.email,
                    noWhitespaceValidator,
                ],
            ],
            password: [
                '',
                [
                    Validators.required,
                    noWhitespaceValidator,
                    Validators.maxLength(72),
                    Validators.minLength(12),
                    this.atLeastOneCharTypeValidator('lowercase'),
                    this.atLeastOneCharTypeValidator('uppercase'),
                    this.atLeastOneCharTypeValidator('number'),
                    this.atLeastOneCharTypeValidator('symbol'),
                    this.passwordCustomValidation,
                ],
            ],
            utm_source: this.utmSource || '',
            utm_campaign: this.utmCampaign || '',
            utm_medium: this.utmMedium || '',
        });

        this._actions$
            .pipe(
                ofType(signUpRequestSuccess),
                tap(() => {
                    this.showAccountCreationError = false;
                    this.submitSuccess = true;
                    this.creatingAccount = false;
                }),
                takeUntil(this._unsub$),
            )
            .subscribe();

        this._actions$
            .pipe(
                ofType(signUpRequestFailed),
                tap((response) => {
                    this.accountCreationError =
                        (response.error.error?.errors?.length ?? false)
                            ? response.error.error.errors[0]
                            : 'An error has occurred';
                    this.showAccountCreationError = true;
                    this.submitSuccess = false;
                    this.creatingAccount = false;
                }),
                takeUntil(this._unsub$),
            )
            .subscribe();

        if (environment.production) {
            this._linkedInScriptInjectorService.injectScript();
            this._hubspotScriptInjectorService.injectScript();
        }
    }

    registerUser() {
        this.showAccountCreationError = false;
        this.userRegistrationForm.markAllAsTouched();

        if (!this.userRegistrationForm.invalid && !this.showPasswordRequirementsError) {
            this.creatingAccount = true;
            const userRegistration = this._formUtils.getCleanFormGroupValue(this.userRegistrationForm);
            this._store$.dispatch(signUpRequest({ request: userRegistration }));
        }
    }

    registerWithSocialLogin(event: MouseEvent): void {
        const idpIds = {
            google: environment.oktaGoogleIdpId,
            microsoft: environment.oktaMicrosoftIdpId,
        };

        const authPath = environment.baseUrl + '/oauth2/authorization/oidc';
        const loginPath = environment.baseUrl + '/login/oauth2/code/oidc';

        const queryParams = new URLSearchParams({
            client_id: environment.oktaApplicationClientId,
            idp: idpIds[event.currentTarget['dataset'].idp],
            redirect_uri: loginPath,
            response_mode: 'query',
            response_type: 'code',
            scope: ['openid', 'email', 'profile'].join('%20'),
        });

        window.location.href = `${authPath}?${queryParams.toString()}`;
    }

    private passwordCustomValidation(control: UntypedFormControl) {
        if (control && !control.value) {
            return null;
        }

        const userEmail = control.parent.get('email');
        const emailUsername = userEmail.value.trim().toLowerCase().replaceAll(/@.*/g, '');
        const emailUsernameItems = emailUsername.split('.');

        if (userEmail.value && emailUsernameItems.length > 0) {
            for (const usernameItem of emailUsernameItems) {
                if (usernameItem.length >= 4 && control.value.includes(usernameItem)) {
                    return { emailIncluded: true };
                }
            }
        }

        return null;
    }

    private atLeastOneCharTypeValidator(
        type: CharValidatorType,
    ): (control: UntypedFormControl) => null | ValidationErrors {
        const validations: { [type in CharValidatorType]: ValidatorFn } = {
            lowercase: (control) => (/(?=.*[a-z])/.test(control.value) ? null : { lowercase: true }),
            uppercase: (control) => (/(?=.*[A-Z])/.test(control.value) ? null : { uppercase: true }),
            number: (control) => (/\d/.test(control.value) ? null : { number: true }),
            symbol: (control) =>
                /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(control.value) ? null : { symbol: true },
        };

        return (control) => {
            if (control && !control.value) {
                return null;
            }

            return validations[type](control);
        };
    }

    ngOnDestroy(): void {
        this._unsub$.next();
    }
}
