import {
    AfterContentInit,
    Component,
    ContentChildren,
    forwardRef,
    HostBinding,
    Input,
    OnInit,
    Provider,
    QueryList,
    ViewEncapsulation,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { RadioButtonGroupItemDirective } from '../../directives/radiobutton-group-item/radiobutton-group-item.directive';
import { RadioButtonGroupItemData } from './radiobutton-group.model';

const RADIO_BUTTON_VALUE_ACCESSOR: Provider = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => RadioButtonGroupComponent),
    multi: true,
};

@Component({
    selector: 'app-radiobutton-group',
    templateUrl: './radiobutton-group.component.html',
    styleUrls: ['./radiobutton-group.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [RADIO_BUTTON_VALUE_ACCESSOR],
})
export class RadioButtonGroupComponent implements ControlValueAccessor, OnInit, AfterContentInit {
    @Input() type: 'outline' | 'toggle' = 'outline';
    @Input() direction: 'row' | 'column' = 'row';
    @Input() radiobuttonGroupName: string;
    @Input() size: 'small' | 'normal' | 'large' | 'dynamic' = 'normal';
    @Input() defaultValue: unknown;

    @ContentChildren(RadioButtonGroupItemDirective) radioButtonGroupItem: QueryList<RadioButtonGroupItemDirective>;

    @HostBinding('class') hostClass: string;

    checked!: unknown;
    disabled = false;
    groupItemData: RadioButtonGroupItemData[] = [];

    private _onTouched: Function = () => {};
    private _onChanged: Function = (value: unknown) => {};

    ngOnInit(): void {
        this.hostClass = this.type === 'toggle' ? 'd-grid' : '';
    }

    ngAfterContentInit(): void {
        this.radioButtonGroupItem.forEach((item) =>
            this.groupItemData.push({
                value: item.value,
                disabled: item.disabled,
                templateRef: item.templateRef,
            }),
        );
    }

    checkRadioButton(value: unknown) {
        this._onTouched();
        this.checked = value;
        this._onChanged(value);
    }

    writeValue(value: unknown): void {
        this.checked = value ?? (this.defaultValue !== undefined ? this.defaultValue : this.groupItemData[0]?.value);
    }

    registerOnChange(fn: any): void {
        this._onChanged = fn;
    }

    registerOnTouched(fn: any): void {
        this._onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }
}
