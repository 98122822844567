<div class="manage-domains">
    <ng-container
        *ngIf="{
            preferredDomainVerification: preferredDomainVerification$ | async,
            domainVerifications: domainVerifications$ | async,
            hasValidDomainVerification: hasValidDomainVerification$ | async,
        } as ctx"
    >
        <app-domain-verifications
            [preference]="ctx.preferredDomainVerification"
            [hasValidVerification]="ctx.hasValidDomainVerification"
            [verifications]="ctx.domainVerifications"
        />
    </ng-container>

    <div
        *ngIf="{
            orgSubdomain: orgSubdomain$ | async,
            orgSubdomainDomain: orgSubdomainDomain$ | async,
            vanityDomain: vanityDomain$ | async,
        } as ctx"
        [ngSwitch]="domainMode$ | async"
    >
        <h4 class="mt-4 mb-1">Customize domain</h4>
        <ng-container *ngSwitchCase="DomainMode.SUBDOMAIN">
            URLs from this instance will come from your customized subdomain.
            <div class="mt-2">
                Subdomain<br />
                <strong>{{ ctx.orgSubdomain }}.{{ ctx.orgSubdomainDomain }}</strong>
            </div>
        </ng-container>

        <ng-container *ngSwitchCase="DomainMode.VANITY">
            URLs from this instance will come from your customized vanity domain.
            <app-vanity-domain [vanityDomain]="ctx.vanityDomain" (refreshVanityDomains)="onRefreshVanityDomains()" />
        </ng-container>
        <span *ngSwitchDefault>Your URLs aren't customized, yet.</span>
    </div>

    <div class="d-inline-flex mt-2">
        <button mat-button visoButton="secondary" (click)="openCustomizeDomainModal()">
            <mat-icon>edit_outline</mat-icon> Edit domain
        </button>
        <button
            *ngIf="(domainMode$ | async) != DomainMode.NONE"
            mat-button
            class="mx-2"
            visoButton="warning"
            (click)="resetDomain()"
            toggleDisableOnActions
            [disablingActions]="[
                OrgSubdomainActions.SetOrgSubdomainRequest,
                OrgSubdomainActions.DeleteOrgSubdomainRequest,
                VanityDomainActions.CreateVanityDomainRequest,
                VanityDomainActions.DeleteVanityDomainRequest,
            ]"
            [enablingActions]="[
                OrgSubdomainActions.SetOrgSubdomainRequestSuccess,
                OrgSubdomainActions.SetOrgSubdomainRequestFailure,
                OrgSubdomainActions.DeleteOrgSubdomainRequestSuccess,
                OrgSubdomainActions.DeleteOrgSubdomainRequestFailure,
                VanityDomainActions.CreateVanityDomainRequestSuccess,
                VanityDomainActions.CreateVanityDomainRequestFailure,
                VanityDomainActions.DeleteVanityDomainRequestSuccess,
                VanityDomainActions.DeleteVanityDomainRequestFailure,
            ]"
        >
            <mat-icon>delete_outline</mat-icon> Reset to default
        </button>
    </div>
</div>
