<div class="card risk-card mb-0" [ngClass]="risk | callback: getRiskClasses : transitionalRisk">
    <div class="card-body">
        <div class="card-title text-muted mb-0">{{ riskCategoryLabel }}</div>
        <p class="h4 text-bold mb-0" [ngClass]="{ 'fst-italic text-muted': transitionalRisk || !risk }">
            {{ risk ? (risk | riskLevel | async) : '-' }}
            <span *ngIf="transitionalRisk">*</span>
        </p>
    </div>
    <div class="card-footer py-0">
        <small class="text-muted">{{ captionText }}</small>
    </div>
</div>
