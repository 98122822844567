<div class="row mb-3">
    <div class="col">
        <h3 class="lead">Third Party Contact</h3>
    </div>
    <div class="col-auto" *hideIfOrgUserIsNotBusinessOwner="businessOwner">
        <a
            exportRemovableAction
            class="text-primary"
            [routerLink]="editLink"
            [replaceUrl]="true"
            *jhiHasNotAuthority="Roles.ReadOnly"
        >
            {{ thirdPartyContactExists ? 'Edit' : 'Add' }}
        </a>
    </div>
</div>
<div *ngIf="thirdPartyContactExists; else noThirdPartyContact" class="row">
    <div class="col">
        <p class="mb-0">{{ thirdPartyContactName }}</p>
        <strong class="mb-0">{{ thirdPartyContactEmail }}</strong>
        <em
            *ngIf="doesThirdPartyEmailBounced"
            class="fa fa-exclamation-circle text-danger ps-2"
            ngbTooltip="Emails have not been successful sending to this recipient"
        ></em>
    </div>
</div>
<ng-template #noThirdPartyContact>
    <p class="text-muted">
        Adding a third party contact allows us to reach out to the organization to collect information on your behalf.
    </p>
</ng-template>
