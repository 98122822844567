import { Injectable } from '@angular/core';

@Injectable()
export class SettingsService {
    public user: any;
    public app: any;
    public layout: any;

    constructor() {
        // User Settings
        // -----------------------------------
        this.user = {
            name: 'John',
            job: 'ng-developer',
            picture: '',
        };

        // App Settings
        // -----------------------------------
        this.app = {
            name: 'VISO TRUST',
            description: 'Vendor Due Diligence',
            year: new Date().getFullYear(),
        };

        // Layout Settings
        // -----------------------------------
        this.layout = {
            isFixed: true,
            isCollapsed: true,
            isBoxed: false,
            isRTL: false,
            horizontal: false,
            isFloat: false,
            asideHover: false,
            theme: null,
            asideScrollbar: false,
            isCollapsedText: false,
            useFullLayout: false,
            hiddenFooter: false,
            offsidebarOpen: false,
            asideToggled: false,
            viewAnimation: 'ng-fadeInUp',
        };
    }
}
