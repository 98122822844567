<button mat-icon-button disableRipple id="help-button" [matMenuTriggerFor]="menu">
    <mat-icon fontSet="material-symbols-outlined">help</mat-icon>
</button>

<mat-menu #menu="matMenu" [overlapTrigger]="false">
    <button
        mat-menu-item
        [disabled]="!pageSupportsSpotlightTour"
        [matTooltip]="spotlightTooltipMessage"
        matTooltipPosition="left"
        aria-label="Activate spotlight tour"
        (click)="activateSpotlightTour.emit()"
    >
        Page walkthrough
        <mat-icon fontSet="material-symbols-outlined">partner_exchange</mat-icon>
    </button>
    <button mat-menu-item (click)="helpCenterOpened.emit()" aria-label="Open help docs drawer">
        Help center
        <mat-icon>article</mat-icon>
    </button>
    <a mat-menu-item [href]="'mailto:' + SUPPORT_EMAIL" [attr.aria-label]="'Email ' + SUPPORT_EMAIL">
        Support
        <mat-icon>email</mat-icon>
    </a>
    <a mat-menu-item target="_blank" [href]="SHARE_FEEDBACK_URL" aria-label="Share feedback">
        Share feedback
        <mat-icon>thumbs_up_down</mat-icon>
    </a>
</mat-menu>
