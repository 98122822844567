import { Directive, HostListener } from '@angular/core';

@Directive({
    selector: 'textarea[noPastedLines]',
})
export class NoPastedLinesDirective {
    @HostListener('paste', ['$event'])
    onInput(event: ClipboardEvent) {
        event.preventDefault();
        const pastedText = event.clipboardData.getData('text');
        let pasted = true;

        try {
            if (!document.execCommand('insertText', false, pastedText.replace(/\r?\n/g, ' '))) {
                pasted = false;
            }
        } catch (e) {
            pasted = false;
        }

        if (!pasted) {
            event.target['value'] = event.target['value'] + pastedText.replace(/\r?\n/g, ' ');
        }
    }
}
