import { Directive, Input, ElementRef, ComponentRef, ViewContainerRef, HostListener, TemplateRef } from '@angular/core';
import { Overlay, OverlayRef, PositionStrategy } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { PopoverComponent } from '../popover/popover.component';

@Directive({
    selector: '[appPopover]',
})
export class PopoverDirective {
    @Input('appPopover') content: string | TemplateRef<any>;
    @Input() popoverContext: any;
    @Input() popoverHostSelector?: string;
    @Input() popoverTheme: 'dark' | 'light' = 'light';
    @Input() popoverPosition: 'top' | 'bottom' | 'left' | 'right' = 'right';
    @Input() popoverContainerClass?: string;
    @Input() popoverMaxWidth?: number;
    @Input() popoverMaxHeight?: number;

    private overlayRef: OverlayRef;
    private popoverComponentRef: ComponentRef<PopoverComponent>;
    private hideTimeout: any;

    constructor(
        private overlay: Overlay,
        private elementRef: ElementRef,
        private viewContainerRef: ViewContainerRef,
    ) {}

    @HostListener('mouseenter')
    showPopover(): void {
        clearTimeout(this.hideTimeout);
        if (!this.popoverComponentRef) {
            const positionStrategy = this.getPositionStrategy();
            this.overlayRef = this.overlay.create({ positionStrategy });
            this.popoverComponentRef = this.overlayRef.attach(
                new ComponentPortal(PopoverComponent, this.viewContainerRef),
            );
            if (this.content instanceof TemplateRef) {
                this.popoverComponentRef.instance.template = this.content;
                this.popoverComponentRef.instance.context = this.popoverContext;
            } else {
                this.popoverComponentRef.instance.content = this.content;
            }
            this.popoverComponentRef.instance.arrowPosition = this.popoverPosition;
            this.popoverComponentRef.instance.theme = this.popoverTheme;
            this.popoverComponentRef.instance.customContainerClass = this.popoverContainerClass;
            this.popoverComponentRef.instance.maxWidth = this.popoverMaxWidth;
            this.popoverComponentRef.instance.maxHeight = this.popoverMaxHeight;
            this.popoverComponentRef.changeDetectorRef.detectChanges();
        }
    }

    @HostListener('mouseleave')
    hidePopover(): void {
        if (this.overlayRef.hasAttached()) {
            this.overlayRef.detach();
            this.popoverComponentRef = null;
        }
    }

    private getPositionStrategy(): PositionStrategy {
        let positionStrategy: PositionStrategy;
        let hostElement = this.popoverHostSelector
            ? this.elementRef.nativeElement.closest(this.popoverHostSelector)
            : this.elementRef;
        hostElement = hostElement || this.elementRef;

        switch (this.popoverPosition) {
            case 'top':
                positionStrategy = this.overlay
                    .position()
                    .flexibleConnectedTo(hostElement)
                    .withPositions([{ originX: 'center', originY: 'top', overlayX: 'center', overlayY: 'bottom' }]);
                break;
            case 'bottom':
                positionStrategy = this.overlay
                    .position()
                    .flexibleConnectedTo(hostElement)
                    .withPositions([{ originX: 'center', originY: 'bottom', overlayX: 'center', overlayY: 'top' }]);
                break;
            case 'left':
                positionStrategy = this.overlay
                    .position()
                    .flexibleConnectedTo(hostElement)
                    .withPositions([{ originX: 'start', originY: 'center', overlayX: 'end', overlayY: 'center' }]);
                break;
            case 'right':
                positionStrategy = this.overlay
                    .position()
                    .flexibleConnectedTo(hostElement)
                    .withPositions([{ originX: 'end', originY: 'center', overlayX: 'start', overlayY: 'center' }]);
                break;
        }

        return positionStrategy;
    }
}
