<h2>Demo Tools</h2>
<form name="editForm" role="form" novalidate (ngSubmit)="showResetClientDataConfirmation()" [formGroup]="demoDataForm">
    <div class="card">
        <div class="card-header">
            <h4 class="modal-title" id="resetDemoForClient">Reset Demo</h4>
        </div>
        <div class="card-body">
            <section class="demo-tools-loading" *ngIf="loadingResettingData">
                <div class="d-flex align-items-center justify-content-center">
                    <div class="sk-spinner sk-spinner-pulse"></div>
                </div>
            </section>
            <section *ngIf="!loadingResettingData">
                <div class="form-group">
                    <label class="form-control-label" for="field_clientOrg">Client Org</label>
                    <select
                        class="form-control"
                        id="field_clientOrg"
                        name="clientOrg"
                        formControlName="clientOrgId"
                        data-pw="clientOrg"
                        required
                    >
                        <option [ngValue]="null"></option>
                        <option [ngValue]="clientOrg.id" *ngFor="let clientOrg of orgs; trackBy: trackOrgById">
                            {{ clientOrg.name }}
                        </option>
                    </select>
                </div>
                <div class="form-group">
                    <label class="form-control-label" for="field_vendorCount">Vendor Count</label>
                    <input
                        type="number"
                        class="form-control"
                        name="vendorCount"
                        id="field_vendorCount"
                        formControlName="vendorCount"
                        min="0"
                        required
                        data-pw="vendorCount"
                    />
                </div>
                <div class="form-group">
                    <label class="form-control-label" for="field_validationWeight"
                        >% of Generated Relationships with Fully Validated Controls</label
                    >
                    <input
                        type="number"
                        class="form-control"
                        name="validationWeight"
                        id="field_validationWeight"
                        formControlName="validationWeight"
                        min="0"
                        max="100"
                        required
                    />
                </div>
                <div class="form-group">
                    <label class="form-control-label" for="field_onboardingWeight"
                        >% of Fully Validated Relationships that are Onboarded</label
                    >
                    <input
                        type="number"
                        class="form-control"
                        name="onboardingWeight"
                        id="field_onboardingWeight"
                        formControlName="onboardingWeight"
                        min="0"
                        max="100"
                        required
                    />
                </div>
                <button
                    type="submit"
                    [disabled]="!demoDataForm.valid"
                    mat-button
                    visoButton="primary"
                    data-pw="demoToolsSubmit"
                >
                    <span class="fa fa-save"></span>&nbsp;<span>Submit</span>
                </button>
            </section>
        </div>
    </div>
</form>
<form
    name="editForm"
    role="form"
    novalidate
    (ngSubmit)="createArtifactUpdateAssessment()"
    [formGroup]="artifactUpdateForm"
>
    <div class="card">
        <div class="card-header">
            <h4 class="modal-title" id="artifactUpdateDemoForClient">
                Generate Relationship in Updating Artifacts Lifecycle for Client Org
            </h4>
        </div>
        <div class="card-body">
            <section class="demo-tools-loading" *ngIf="loadingResettingData">
                <div class="d-flex align-items-center justify-content-center">
                    <div class="sk-spinner sk-spinner-pulse"></div>
                </div>
            </section>
            <section *ngIf="!loadingResettingData">
                <div class="form-group">
                    <label class="form-control-label" for="field_clientOrg">Client Org</label>
                    <select
                        class="form-control"
                        id="field_clientOrg"
                        name="clientOrg"
                        formControlName="clientOrgId"
                        data-pw="clientOrg"
                        required
                    >
                        <option [ngValue]="null"></option>
                        <option [ngValue]="clientOrg.id" *ngFor="let clientOrg of orgs; trackBy: trackOrgById">
                            {{ clientOrg.name }}
                        </option>
                    </select>
                </div>
                <div class="form-group">
                    <label class="form-control-label" for="field_vendorCount">Vendor Count</label>
                    <input
                        type="number"
                        class="form-control"
                        name="vendorCount"
                        id="field_vendorCount"
                        formControlName="vendorCount"
                        min="0"
                        required
                        data-pw="vendorCount"
                    />
                </div>
                <button
                    type="submit"
                    [disabled]="!artifactUpdateForm.valid"
                    mat-button
                    visoButton="primary"
                    data-pw="artifactUpdateDemoForClientSubmit"
                >
                    <span class="fa fa-save"></span>&nbsp;<span>Submit</span>
                </button>
            </section>
        </div>
    </div>
    <form
        name="editForm"
        role="form"
        novalidate
        (ngSubmit)="createRecertificationAssessment()"
        [formGroup]="recertificationForm"
    >
        <div class="card">
            <div class="card-header">
                <h4 class="modal-title" id="recertificationDemoForClient">
                    Generate Relationship in Recertification Lifecycle for Client Org
                </h4>
            </div>
            <div class="card-body">
                <section class="demo-tools-loading" *ngIf="loadingResettingData">
                    <div class="d-flex align-items-center justify-content-center">
                        <div class="sk-spinner sk-spinner-pulse"></div>
                    </div>
                </section>
                <section *ngIf="!loadingResettingData">
                    <div class="form-group">
                        <label class="form-control-label" for="field_clientOrg">Client Org</label>
                        <select
                            class="form-control"
                            id="field_clientOrg"
                            name="clientOrg"
                            formControlName="clientOrgId"
                            data-pw="clientOrg"
                            required
                        >
                            <option [ngValue]="null"></option>
                            <option [ngValue]="clientOrg.id" *ngFor="let clientOrg of orgs; trackBy: trackOrgById">
                                {{ clientOrg.name }}
                            </option>
                        </select>
                    </div>
                    <div class="form-group">
                        <label class="form-control-label" for="field_vendorCount">Vendor Count</label>
                        <input
                            type="number"
                            class="form-control"
                            name="vendorCount"
                            id="field_vendorCount"
                            formControlName="vendorCount"
                            min="0"
                            required
                            data-pw="vendorCount"
                        />
                    </div>
                    <button
                        type="submit"
                        [disabled]="!recertificationForm.valid"
                        mat-button
                        visoButton="primary"
                        data-pw="recertificationDemoForClientSubmit"
                    >
                        <span class="fa fa-save"></span>&nbsp;<span>Submit</span>
                    </button>
                </section>
            </div>
        </div>
    </form>
</form>
<form
    name="editForm"
    role="form"
    novalidate
    (ngSubmit)="showRiskNetworkDetectionsConfirmation()"
    [formGroup]="riskNetworkForm"
>
    <div class="card">
        <div class="card-header">
            <h4 class="modal-title">Generate Risk Network Org Detections For Client Org</h4>
        </div>
        <div class="card-body">
            <section class="demo-tools-loading" *ngIf="loadingResettingData">
                <div class="d-flex align-items-center justify-content-center">
                    <div class="sk-spinner sk-spinner-pulse"></div>
                </div>
            </section>
            <section *ngIf="!loadingResettingData">
                <div class="form-group">
                    <label class="form-control-label" for="field_clientOrg">Client Org</label>
                    <select class="form-control" name="clientOrg" formControlName="clientOrgId" data-pw="clientOrg">
                        <option [ngValue]="null"></option>
                        <option [ngValue]="clientOrg.id" *ngFor="let clientOrg of orgs; trackBy: trackOrgById">
                            {{ clientOrg.name }}
                        </option>
                    </select>
                </div>
                <div class="form-group">
                    <label class="form-control-label" for="field_minPerRelationship"
                        >Minimum Number of Detections Per Relationship</label
                    >
                    <input
                        id="field_minPerRelationship"
                        type="number"
                        class="form-control"
                        name="minPerRelationship"
                        formControlName="minPerRelationship"
                        min="0"
                        data-pw="minPerRelationship"
                    />
                </div>
                <div class="form-group">
                    <label class="form-control-label" for="field_maxPerRelationship"
                        >Maximum Number of Detections Per Relationship</label
                    >
                    <input
                        id="field_maxPerRelationship"
                        type="number"
                        class="form-control"
                        name="maxPerRelationship"
                        formControlName="maxPerRelationship"
                        data-pw="maxPerRelationship"
                    />
                </div>
                <button
                    type="submit"
                    [disabled]="!riskNetworkForm.valid"
                    mat-button
                    visoButton="primary"
                    data-pw="riskNetworkDemoForClientSubmit"
                >
                    <span class="fa fa-save"></span>&nbsp;<span>Submit</span>
                </button>
            </section>
        </div>
    </div>
</form>
