import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { OrgStatus, OrgView } from '../../entities/org/org.model';
import { BYPASS_SNACKBAR_ON_ERROR } from '../../blocks/interceptor/errorhandler.interceptor';

@Injectable({
    providedIn: 'root',
})
export class ClientOffboardingService {
    private resourceUrl = 'api/orgs';

    constructor(private http: HttpClient) {}

    findAllByStatusAndNameLike(
        statuses: OrgStatus[],
        page: number,
        pageSize: number,
        name: string,
    ): Observable<HttpResponse<OrgView[]>> {
        const encodedStatuses = encodeURIComponent(statuses.join(','));
        const encodedName = encodeURIComponent(name);
        return this.http.get<OrgView[]>(
            `${this.resourceUrl}/all/${encodedStatuses}?page=${page}&size=${pageSize}&sort=name&sort=id&name=${encodedName}`,
            { observe: 'response' },
        );
    }

    offboard(orgId: number): Observable<any> {
        return this.http.delete(`${this.resourceUrl}/${orgId}/offboard`, {
            context: new HttpContext().set(BYPASS_SNACKBAR_ON_ERROR, { forAnyStatus: true }),
            responseType: 'text',
        });
    }

    onboard(orgId: number): Observable<any> {
        return this.http.put<any>(`${this.resourceUrl}/${orgId}/onboard`, null);
    }
}
