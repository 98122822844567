import { Injectable } from '@angular/core';
import { SnackOptions } from './snack.model';
import { VisoSnackbarComponent } from './snackbar.component';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
    providedIn: 'root',
})
export class SnackbarService {
    constructor(private _snackBar: MatSnackBar) {}

    success(message: string = '', options?: SnackOptions): void {
        this.openSnackbar(message, 'snack-success', options);
    }

    error(message: string = '', options?: SnackOptions): void {
        this.openSnackbar(message, 'snack-error', options);
    }

    warning(message: string = '', options?: SnackOptions): void {
        this.openSnackbar(message, 'snack-warning', options);
    }

    info(message: string = '', options?: SnackOptions): void {
        this.openSnackbar(message, 'snack-info', options);
    }

    private openSnackbar(message: string, panelClass: string, options: SnackOptions = {}): void {
        this._snackBar.openFromComponent(VisoSnackbarComponent, {
            data: {
                action: options.action,
                message,
            },
            duration: 6000,
            horizontalPosition: 'center',
            verticalPosition: 'top',
            panelClass,
            ...options.config,
        });
    }
}
