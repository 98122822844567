import { Component, OnInit } from '@angular/core';
import { ElasticsearchIndexService } from './elasticsearch-index.service';
import { SnackbarService } from '../../shared/components/snackbar/snackbar.service';
import { Observable, of } from 'rxjs';
import { RefSelectOption } from '../../shared/model/select-options';
import { ElasticSearchIndexRequest, ESIndex, ESIndexLabels } from './elasticsearch-index.model';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
    selector: 'app-es-index',
    templateUrl: './elasticsearch-index.component.html',
    styleUrls: ['./elasticsearch-index.component.scss'],
})
export class ElasticsearchIndexComponent implements OnInit {
    esIndexes$: Observable<RefSelectOption[]> = of([
        {
            ref: {
                enumValue: ESIndex.RELATIONSHIP,
                recommendDeletion: false,
            },
            name: ESIndexLabels.RELATIONSHIP,
        },
        {
            ref: {
                enumValue: ESIndex.VENDOR,
                recommendDeletion: false,
            },
            name: ESIndexLabels.VENDOR,
        },
    ]);

    reindexESIndexFormGroup: UntypedFormGroup;
    showIndexDeletionRecommendation: boolean;

    constructor(
        private _elasticsearchIndexService: ElasticsearchIndexService,
        private _snackbarService: SnackbarService,
        private _fb: UntypedFormBuilder,
    ) {}

    ngOnInit(): void {
        this.reindexESIndexFormGroup = this._fb.group({
            esIndex: [null, Validators.required],
            deleteAndRecreateIndex: [false, Validators.required],
        });

        this.reindexESIndexFormGroup.controls.esIndex.valueChanges.subscribe((option) => {
            this.reindexESIndexFormGroup.controls.deleteAndRecreateIndex.setValue(option.recommendDeletion);
            this.showIndexDeletionRecommendation = option.recommendDeletion;
        });
    }

    reindex(): void {
        const request = {
            ...this.reindexESIndexFormGroup.value,
            esIndex: this.reindexESIndexFormGroup.controls.esIndex.value.enumValue,
        };
        this._elasticsearchIndexService.reindexAll(request as ElasticSearchIndexRequest).subscribe({
            next: (successMsg) => {
                this._snackbarService.success(successMsg);
            },
            error: (e) => {
                this._snackbarService.error(`${e}`);
            },
        });
    }

    clearForm(): void {
        this.reindexESIndexFormGroup.reset();
    }
}
