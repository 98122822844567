import { Route } from '@angular/router';

import { ElasticsearchIndexComponent } from './elasticsearch-index.component';

export const elasticsearchIndexRoute: Route = {
    path: 'es-index',
    component: ElasticsearchIndexComponent,
    data: {
        pageTitle: 'Elasticsearch Index Management',
    },
};
