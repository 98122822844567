<div class="modal-header">
    <span class="fas fa-check-circle mb-2"></span>
    <h2 class="modal-title" data-pw="firstAssessmentStartedModalHeader">Assessment successfully started.</h2>
</div>
<div class="modal-body">
    <p class="text-bold">We’ve got you 100% covered between our proprietary AI platform and team of expert auditors.</p>
    <p>Continue on with your day and let us do the work for you. We'll email you when the assessment is complete.</p>
</div>

<div class="modal-footer">
    <div class="modal-actions">
        <button type="button" mat-button visoButton="primary" class="me-2" data-dismiss="modal" (click)="clear()">
            Got it
        </button>
    </div>
</div>
