import { Directive, HostBinding, Input } from '@angular/core';
import { Risk } from '@entities/risk-assessment';

@Directive({ selector: '[riskTextColor]' })
export class RiskTextColorDirective {
    @Input({ alias: 'riskTextColor', required: true })
    risk: Risk;

    @HostBinding('class.risk-text-no-context')
    get isNoContext(): boolean {
        return this.risk === Risk.NO_CONTEXT;
    }

    @HostBinding('class.risk-text-low')
    get isLow(): boolean {
        return this.risk === Risk.LOW;
    }

    @HostBinding('class.risk-text-medium')
    get isMedium(): boolean {
        return this.risk === Risk.MEDIUM;
    }

    @HostBinding('class.risk-text-high')
    get isHigh(): boolean {
        return this.risk === Risk.HIGH;
    }

    @HostBinding('class.risk-text-extreme')
    get isExtreme(): boolean {
        return this.risk === Risk.EXTREME;
    }
}
