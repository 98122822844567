import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { UserStatus, VisoUser, VisoUserRole } from '../../entities/viso-user';
import { Actions, ofType } from '@ngrx/effects';
import {
    createContactRequestSuccess,
    deleteContactRequestSuccess,
    getContactsRequest,
    getContactsRequestFailed,
    getContactsRequestSuccess,
    openDeleteContactConfirmationModal,
    openUpdateContactStatusConfirmationModal,
    updateManagedContactStatusRequestSuccess,
    reassignContactRelationshipsRequestSuccess,
    updateContactRequestSuccess,
} from './redux/contact-management.actions';
import { filter, map, take, takeUntil, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppState } from '../../shared/redux/state';
import { HttpHeaders } from '@angular/common/http';
import { BREADCRUMB_CONTAINER_TOKEN } from '../../shared/dynamic-content/dynamic-content-injector';

interface TableSortItem {
    prop: string;
    dir: string;
}

@Component({
    selector: 'app-contact-management',
    templateUrl: './contact-management.component.html',
    styleUrls: ['./contact-management.component.scss'],
})
export class ContactManagementComponent implements OnInit, OnDestroy {
    public readonly breadcrumbsContainerToken = BREADCRUMB_CONTAINER_TOKEN;
    @ViewChild('contactList') contactList: any;

    Roles = VisoUserRole;
    UserStatus = UserStatus;

    contactsLoading: boolean;

    queryCount: any;
    itemsPerPage: any;
    totalCurrentItems: number;
    page: number;
    predicate: any;
    previousPage: any;
    reverse: any;
    contacts$ = new BehaviorSubject<VisoUser[]>([]);
    numberOfContactsHeader$ = new BehaviorSubject<string>('0 Users');

    tableSort$: BehaviorSubject<TableSortItem[]> = new BehaviorSubject<TableSortItem[]>([{ prop: 'name', dir: 'asc' }]);

    private _unsub = new Subject<void>();

    constructor(
        private _router: Router,
        private _actions$: Actions,
        private _store$: Store<AppState>,
        private _route: ActivatedRoute,
    ) {
        this.itemsPerPage = 20;
        this.page = 0;
    }

    ngOnInit() {
        this._actions$
            .pipe(
                ofType(
                    createContactRequestSuccess,
                    updateContactRequestSuccess,
                    deleteContactRequestSuccess,
                    reassignContactRelationshipsRequestSuccess,
                    updateManagedContactStatusRequestSuccess,
                ),
                tap(() => {
                    this.loadAll();
                }),
                takeUntil(this._unsub),
            )
            .subscribe();

        this._actions$
            .pipe(
                ofType(getContactsRequestSuccess, getContactsRequestFailed),
                tap(() => {
                    this.contactsLoading = false;
                }),
                takeUntil(this._unsub),
            )
            .subscribe();

        // Will run when paginated
        this._actions$
            .pipe(
                ofType(getContactsRequestSuccess),
                filter(({ headers }) => !!headers.get('x-total-count')),
                takeUntil(this._unsub),
            )
            .subscribe(({ contacts, headers }) => {
                const totalContactCount = +headers.get('x-total-count');
                this.numberOfContactsHeader$.next(`${totalContactCount} ${totalContactCount == 1 ? 'User' : 'Users'} `);
                this.onSuccess(contacts, headers);
            });

        this.tableSort$
            .pipe(
                map((sortData) => sortData && sortData[0]),
                takeUntil(this._unsub),
            )
            .subscribe((sortItem) => {
                this.predicate = sortItem.prop;
                this.reverse = sortItem.dir !== 'desc';
            });

        this._route.queryParams.pipe(take(1)).subscribe(({ sort, page }) => {
            const sortPredicateFromUrl: string = !!sort ? (Array.isArray(sort) ? sort[0] : sort) : undefined;

            if (sortPredicateFromUrl) {
                const [prop, dir] = sortPredicateFromUrl.split(',');
                this.tableSort$.next([{ prop, dir }]);
            }

            this.page = +page || this.page;
        });

        this.loadAll();
    }

    loadAll() {
        this.contactsLoading = true;
        this._store$.dispatch(
            getContactsRequest({
                req: {
                    page: this.page,
                    size: this.itemsPerPage,
                    sort: this.sort(),
                },
            }),
        );
    }

    setCurrentPage({ page }) {
        this.page = +page - 1;
        this._router.navigate([], {
            relativeTo: this._route,
            queryParams: { page: this.page },
            queryParamsHandling: 'merge',
        });
        this.loadAll();
    }

    loadPage(page: number) {
        if (page !== this.previousPage) {
            this.previousPage = page;
            this.transition();
        }
    }

    transition() {
        this._router.navigate(['/contacts'], {
            queryParams: {
                page: this.page,
                size: this.itemsPerPage,
                sort: this.predicate + ',' + (this.reverse ? 'asc' : 'desc'),
            },
        });
        this.loadAll();
    }

    clear() {
        this.page = 0;
        this._router.navigate([
            '/contacts',
            {
                page: this.page,
                sort: this.predicate + ',' + (this.reverse ? 'asc' : 'desc'),
            },
        ]);
        this.loadAll();
    }

    trackId(index: number, item: VisoUser) {
        return item.id;
    }

    onSort({ sorts }) {
        this.predicate = sorts[0].prop;
        this.reverse = sorts[0].dir !== 'desc';
        this.contactList.offset = this.page;
        this._router.navigate([], {
            relativeTo: this._route,
            queryParams: { sort: `${this.predicate},${sorts[0].dir}` },
            queryParamsHandling: 'merge',
        });
        this.loadAll();
    }

    deleteContact(contact: VisoUser) {
        setTimeout(() => this._store$.dispatch(openDeleteContactConfirmationModal({ contact })), 50);
    }

    updateManagedContactStatus(contact: VisoUser) {
        setTimeout(() => this._store$.dispatch(openUpdateContactStatusConfirmationModal({ contact })), 50);
    }

    getRowInactiveClass = (contact: VisoUser) => ({
        inactive: contact.status === UserStatus.INACTIVE,
    });

    formatUserRole(role: VisoUserRole): string {
        return role.replace('ROLE_', '').replace(/_/g, ' ');
    }

    private onSuccess(data: VisoUser[], headers: HttpHeaders): void {
        this.totalCurrentItems = +headers.get('x-total-count');
        this.queryCount = data.length;

        this.contacts$.next(data);
    }

    private sort() {
        if (this.predicate === 'name') {
            this.predicate = ['firstName', 'lastName'];
        } else if (this.predicate === 'subscriptions') {
            this.predicate = 'subscriptionCount';
        } else if (this.predicate === 'ownerships') {
            this.predicate = 'businessOwnerCount';
        } else if (this.predicate === 'businessUnitName') {
            this.predicate = 'businessUnit.name';
        }
        const result = [this.predicate + ',' + (this.reverse ? 'asc' : 'desc')];
        if (this.predicate !== 'id') {
            result.push('id');
        }
        return result;
    }

    ngOnDestroy(): void {
        this._unsub.next();
    }
}
