import { GuidedTour } from 'ngx-guided-tour';
import { relationshipsListTour, welcomeTour } from '@shared/spotlight-tour/tours';

export enum SpotlightTourType {
    WELCOME = 'WELCOME',
    RLP = 'RLP',
}

export const ToursByType: Record<SpotlightTourType, GuidedTour> = {
    WELCOME: welcomeTour(),
    RLP: relationshipsListTour(),
};
