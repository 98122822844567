import { Injectable, Type } from '@angular/core';
import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { NgbModalWrapperService } from '../../modal/ngb-modal-wrapper.service';
import { ModalData } from '../../model/modal-structures';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { ConfirmDialogOptions } from './confirm-dialog.model';
import { MatConfirmDialogService } from '../mat-confirm-dialog/mat-confirm-dialog.service';

/**
 * @deprecated Please use {@link MatConfirmDialogService}.
 */
@Injectable({
    providedIn: 'root',
})
export class ConfirmDialogService {
    constructor(private _modalWrapperService: NgbModalWrapperService) {}

    confirm<TResult = boolean>(options: ConfirmDialogOptions): Observable<TResult> {
        const modal = this._modalWrapperService.open<ConfirmDialogComponent, TResult>(
            ConfirmDialogComponent,
            {
                options,
            },
            { size: 'md', centered: true, backdrop: 'static' },
        );
        return modal.closed as Observable<TResult>;
    }

    confirmWithComponent<TComponent, TResult = boolean>(
        component: Type<TComponent>,
        componentData?: ModalData<TComponent>,
        options?: NgbModalOptions,
    ): Observable<TResult> {
        const modal = this._modalWrapperService.open<TComponent, TResult>(component, componentData, {
            ...{ size: 'md', centered: true, backdrop: 'static' },
            ...(options || {}),
        });
        return modal.closed as Observable<TResult>;
    }
}
