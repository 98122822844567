<form [formGroup]="businessUnitReassignFormGroup" (ngSubmit)="reassign()">
    <div class="modal-header">
        <h4 class="modal-title" id="myBusinessUnitLabel">Reassign Relationships to Another Business Unit</h4>
        <button type="button" class="btn-close" data-dismiss="modal" aria-hidden="true" (click)="clear()"></button>
    </div>
    <div class="modal-body">
        <div class="form-group row">
            <label class="col-md-3 col-form-label align-self-end" for="field_reassignFrom">Reassign from</label>
            <div class="col-lg-8">
                <input
                    type="text"
                    class="form-control"
                    name="reassignFrom"
                    id="field_reassignFrom"
                    formControlName="reassignFromName"
                    readonly
                />
            </div>
        </div>
        <div class="form-group row">
            <label class="col-md-3 col-form-label align-self-end" for="field_reassignTo">Reassign to</label>
            <div class="col-lg-8">
                <ng-container *ngIf="businessUnits$ | async as businessUnits">
                    <div *ngIf="businessUnits?.length" class="list-group pt-2">
                        <ngx-select
                            [allowClear]="true"
                            [items]="businessUnitSelectOptions$ | async"
                            optionValueField="id"
                            optionTextField="name"
                            placeholder="Select business unit"
                            formControlName="reassignToId"
                            required
                        >
                        </ngx-select>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
    <div class="modal-footer d-block justify-content-start main-action">
        <button type="button" mat-button visoButton="secondary" data-dismiss="modal" (click)="goBack()">
            Back to Edit
        </button>
        <button
            type="submit"
            class="float-end"
            mat-button
            visoButton="primary"
            data-pw="saveBusinessUnitButton"
            [disabled]="!businessUnitReassignFormGroup.valid || isSaving"
        >
            Save
        </button>
        <button
            type="button"
            mat-button
            visoButton="secondary"
            class="float-end"
            data-dismiss="modal"
            (click)="clear()"
        >
            Cancel
        </button>
    </div>
</form>
