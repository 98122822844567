import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VisoButtonDirective } from './viso-button.directive';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
    declarations: [VisoButtonDirective],
    imports: [CommonModule, MatButtonModule, MatIconModule],
    exports: [VisoButtonDirective, MatButtonModule, MatIconModule],
})
export class VisoButtonModule {}
