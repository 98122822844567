import { createAction, props } from '@ngrx/store';
import { VisoUser } from 'src/main/webapp/app/entities/viso-user';

export enum BusinessOwnerActions {
    GetRelationshipBusinessOwnerRequest = '[Request] Get Relationship Business Owner Request',
    GetRelationshipBusinessOwnerRequestSuccess = '[Request] Get Relationship Business Owner Request Success',
    GetRelationshipBusinessOwnerRequestFailed = '[Request] Get Relationship Business Owner Request Failed',
    AssignRelationshipBusinessOwnerRequest = '[Request] Assign Relationship Business Owner Request',
    AssignRelationshipBusinessOwnerRequestSuccess = '[Request] Assign Relationship Business Owner Request Success',
    AssignRelationshipBusinessOwnerRequestFailed = '[Request] Assign Relationship Business Owner Request Failed',
}

export const getRelationshipBusinessOwnerRequest = createAction(
    BusinessOwnerActions.GetRelationshipBusinessOwnerRequest,
    props<{ requestId: number }>(),
);

export const getRelationshipBusinessOwnerRequestSuccess = createAction(
    BusinessOwnerActions.GetRelationshipBusinessOwnerRequestSuccess,
    props<{ businessOwner: VisoUser }>(),
);

export const getRelationshipBusinessOwnerRequestFailed = createAction(
    BusinessOwnerActions.GetRelationshipBusinessOwnerRequestFailed,
);

export const assignRelationshipBusinessOwnerRequest = createAction(
    BusinessOwnerActions.AssignRelationshipBusinessOwnerRequest,
    props<{ requestId: number; businessOwner: VisoUser }>(),
);

export const assignRelationshipBusinessOwnerRequestSuccess = createAction(
    BusinessOwnerActions.AssignRelationshipBusinessOwnerRequestSuccess,
    props<{ requestId: number }>(),
);

export const assignRelationshipBusinessOwnerRequestFailed = createAction(
    BusinessOwnerActions.AssignRelationshipBusinessOwnerRequestFailed,
);
