import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Announcement } from './announcement.model';

@Injectable({
    providedIn: 'root',
})
export class AnnouncementService {
    private resourceUrl = 'api/announcement';

    constructor(private _http: HttpClient) {}

    getMostRecentAnnouncement(): Observable<Announcement> {
        return this._http.get<Announcement>(`${this.resourceUrl}/most-recent`);
    }
}
