import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared';
import { RouterModule } from '@angular/router';
import { userProfileRoutes } from './';
import { UserProfileComponent } from './user-profile.component';
import { EffectsModule } from '@ngrx/effects';
import { UserProfileEffects } from './redux/user-profile.effects';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { NgxSelectModule } from 'ngx-select-ex';
import { AccessTokenModalComponent } from './access-token-modal/access-token-modal.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { RevokeAccessTokenModalComponent } from './revoke-access-token-modal/revoke-access-token-modal.component';

const ENTITY_STATES = [...userProfileRoutes];

@NgModule({
    imports: [
        SharedModule,
        RouterModule.forChild(ENTITY_STATES),
        EffectsModule.forFeature([UserProfileEffects]),
        MatDatepickerModule,
        NgxSelectModule,
        MatFormFieldModule,
    ],
    declarations: [UserProfileComponent, AccessTokenModalComponent, RevokeAccessTokenModalComponent],
})
export class UserProfileModule {}
