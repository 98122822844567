import { Directive, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { FileUploaderOptions, FilterFunction, IFileUploader } from './interfaces';

@Directive({ selector: '[ng2FileSelect]' })
export class FileSelectDirective {
    @Input() uploader?: IFileUploader;

    @Output() onFileSelected: EventEmitter<File[]> = new EventEmitter<File[]>();

    protected element: ElementRef;

    constructor(element: ElementRef) {
        this.element = element;
    }

    getOptions(): FileUploaderOptions | undefined {
        return this.uploader?.options;
    }

    getFilters(): FilterFunction[] {
        return this.uploader?.options?.filters;
    }

    @HostListener('change')
    onChange(): void {
        const files = Array.from<File>(this.element.nativeElement.files || []);
        const options = this.getOptions();
        const filters = this.getFilters();
        const count = this.uploader?.queue.length;
        this.uploader?.addToQueue(files, options, filters);

        if (this.uploader?.queue.length !== count) {
            this.onFileSelected.emit(files);
        }

        // Empty selection after upload to ensure if the same file is uploaded again,
        // the 'changed' event on the input will fire again.
        this.element.nativeElement.value = '';
    }
}
