import { Route } from '@angular/router';

import { LicenseManagementComponent } from './license-management.component';

export const licenseManagementRoute: Route = {
    path: 'license-management',
    component: LicenseManagementComponent,
    data: {
        pageTitle: 'License Management',
    },
};
