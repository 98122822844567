import { Pipe, PipeTransform } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { DataSensitivityLevel } from '../../../admin/client-profile/data-sensitivity-levels-config/data-sensitivity-level.model';
import { getProfileDataSensitivityLevels } from '../../../routes/session/redux/session.selectors';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Pipe({
    name: 'dataSensitivityLevel',
})
export class DataSensitivityLevelPipe implements PipeTransform {
    constructor(private _store$: Store) {}

    transform(value: DataSensitivityLevel): Observable<string> {
        return this._store$
            .pipe(select(getProfileDataSensitivityLevels))
            .pipe(
                map(
                    (sensitivityLevels) =>
                        sensitivityLevels.find((sensitivityLevel) => sensitivityLevel.dataSensitivityLevel === value)
                            .displayName,
                ),
            );
    }
}
