import { Injectable } from '@angular/core';
import { RequestStatus } from '../../entities/relationship';
import { DateUtilsService } from './date-utils.service';

@Injectable({
    providedIn: 'root',
})
export class AssessmentRecertificationUtilsService {
    constructor(private _dateUtilsService: DateUtilsService) {}

    isRelationshipUpForRecertification(recertificationDate: string | Date, relationshipStatus: RequestStatus): boolean {
        return (
            recertificationDate &&
            this.areThere30DaysOrLessToRecertification(recertificationDate) &&
            relationshipStatus === RequestStatus.ONBOARDED
        );
    }

    isRelationshipAssessmentExpired(recertificationDate: string | Date): boolean {
        return recertificationDate && this.isPassTheRecertificationDate(recertificationDate);
    }

    private areThere30DaysOrLessToRecertification(recertificationDate: string | Date): boolean {
        const date = this._dateUtilsService.convertDateTimeFromServer(recertificationDate);
        const daysDiff = this._dateUtilsService.numDaysFromToday(date);
        return daysDiff <= 30;
    }

    private isPassTheRecertificationDate(recertificationDate: string | Date): boolean {
        const date = this._dateUtilsService.convertDateTimeFromServer(recertificationDate);
        const daysDiff = this._dateUtilsService.numDaysFromToday(date);
        return daysDiff < 0;
    }
}
