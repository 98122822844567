import { InjectionToken } from '@angular/core';
import { StoreConfig } from '@ngrx/store';
import { RelationshipsState } from './relationships-state';

export const RELATIONSHIPS_CONFIG_TOKEN = new InjectionToken<StoreConfig<RelationshipsState>>('Relationships Config');

export const RELATIONSHIPS_STORAGE_KEYS = new InjectionToken<Array<keyof RelationshipsState>>(
    'RelationshipStorageKeys',
);

export const RELATIONSHIPS_LOCAL_STORAGE_KEY = new InjectionToken<string[]>('RelationshipStorage');
