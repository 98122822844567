import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ControlsOf } from '@shared/model/controls-of';
import { VendorDetails } from '../../../models';
import { FormUtilsService } from '@shared/utils/form-utils.service';

@Component({
    selector: 'app-forward-assessment-dialog',
    templateUrl: './forward-assessment-dialog.component.html',
    styleUrls: ['./forward-assessment-dialog.component.scss'],
})
export class ForwardAssessmentDialogComponent implements OnInit {
    forwardFormGroup: FormGroup<ControlsOf<VendorDetails>>;

    constructor(
        private _dialogRef: MatDialogRef<ForwardAssessmentDialogComponent>,
        private _fb: FormBuilder,
        private _formUtil: FormUtilsService,
    ) {}

    ngOnInit(): void {
        this.forwardFormGroup = this._fb.group({
            firstName: this._fb.control('', { validators: Validators.required }),
            lastName: this._fb.control('', { validators: Validators.required }),
            email: this._fb.control('', { validators: [Validators.required, Validators.email] }),
        });
    }

    confirm(): void {
        this._dialogRef.close(this._formUtil.getCleanTypedFormGroupValue(this.forwardFormGroup));
    }
}
