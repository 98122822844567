<nav
    class="assessment-header"
    role="navigation"
    *ngIf="{ headerColor: headerColor$ | async, logoVisible: logoVisible$ | async } as ctx"
    [ngClass]="{ 'custom-header-color': !!ctx.headerColor, 'logo-hidden': !ctx.logoVisible }"
    [style.color]="isFgBlack(ctx.headerColor) ? 'black' : 'white'"
    [style.background-color]="ctx.headerColor === undefined ? 'white' : ctx.headerColor ? ctx.headerColor : '#fff500'"
>
    <div class="header-title">Assessment Collection</div>
    <a class="navbar-brand" href="/">
        <img
            *ngIf="!(isPhoneResolution$ | async)"
            class="img-fluid brand-logo"
            src="content/img/viso_navbar_brand_text_only.png"
            alt="App Logo"
        />
        <img
            *ngIf="isPhoneResolution$ | async"
            class="img-fluid basic-logo"
            src="content/img/viso_navbar_brand.png"
            alt="App Logo"
        />
    </a>
</nav>
