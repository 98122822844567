export interface Announcement {
    id: string;
    announcementType: AnnouncementTypes;
    title: string;
    message: string;
    announcementImages?: {
        imageOrder: number;
        url: string;
    }[];
    externalLinkLabel?: string;
    externalLinkUrl?: string;
    createdDate: string;
}

export enum AnnouncementTypes {
    FEATURE_RELEASE = 'FEATURE_RELEASE',
}

export enum AnnouncementTypesLabels {
    FEATURE_RELEASE = 'Feature Release',
}

export interface CreateAnnouncement extends Omit<Announcement, 'id' | 'images' | 'createdDate'> {
    images: File[];
}
