<mat-tab-group [mat-stretch-tabs]="false" animationDuration="0" class="viso-tabset">
    <mat-tab *ngFor="let answerType of QuestionnaireAnswerTypeTabLabels | keyvalue: keepOrder">
        <ng-template mat-tab-label>
            {{ answerType.value }}
            <app-badge type="light" [noBorderRadius]="true" class="ms-2">
                {{ filterByAnswerType(answerType.key).length }}
            </app-badge>
        </ng-template>

        <div class="row" *ngIf="!filterByAnswerType(answerType.key).length">
            <div class="col">
                <p class="no-answers"><strong>Nothing to show here!</strong></p>
            </div>
        </div>

        <mat-expansion-panel *ngFor="let answer of filterByAnswerType(answerType.key)" class="mt-3 supplemental">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <strong>{{ answer.question }}</strong>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="answer-response-container">
                <div class="mt-3" *ngIf="answer.answerType; else noResponses"><strong>Answer</strong></div>
                <p>{{ answer.answer }}</p>
                <ng-container *ngIf="answer.controlValidation?.validationDetections?.length">
                    <strong>Detection references</strong>
                    <p>
                        These are the detections identified in the provided artifacts that support the above response.
                        The assurance of an artifact is indicated with Advanced as the highest level possible.
                    </p>
                    <ng-container
                        *ngFor="
                            let detection of answer.controlValidation.validationDetections
                                | callback: populateDetections
                        "
                    >
                        <li class="list-group-item px-0 pt-0">
                            <app-detection [detection]="detection" />
                        </li>
                    </ng-container>
                </ng-container>
            </div>
        </mat-expansion-panel>
    </mat-tab>
</mat-tab-group>
<ng-template #noResponses>
    <div class="mt-3"><strong>Answer</strong></div>
    <p>No responses have been provided for this question.</p>
</ng-template>
