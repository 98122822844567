import { VisoUserRole } from '../../entities/viso-user';

export interface ActiveJwtView {
    jwtId: string;
    expirationDate: Date;
    firstName: string;
    lastName: string;
    email: string;
    createdDate: Date;

    // Frontend Use only
    fullName: string;
}

export enum JwtExpirationPeriods {
    THIRTY_DAYS = 'THIRTY_DAYS',
    SIXTY_DAYS = 'SIXTY_DAYS',
    NINETY_DAYS = 'NINETY_DAYS',
    SIX_MONTHS = 'SIX_MONTHS',
    ONE_YEAR = 'ONE_YEAR',
    CUSTOM = 'CUSTOM',
}

export enum JwtExpirationPeriodLabels {
    THIRTY_DAYS = 'Thirty Days',
    SIXTY_DAYS = 'Sixty Days',
    NINETY_DAYS = 'Ninety Days',
    SIX_MONTHS = 'Six Months',
    ONE_YEAR = 'One Year',
    CUSTOM = 'Custom Date',
}

export interface CreateJwtPayload {
    visoUserId: number;
    jwtExpirationPeriod: JwtExpirationPeriods;
    customExpirationDate?: Date;
}

export interface JwtCreatedResponse {
    email: string;
    firstName: string;
    lastName: string;
    roles: VisoUserRole[];
    accessToken: string;
    jti: string;
    expirationDate: Date;
}
