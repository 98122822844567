<div class="modal-header">
    <h4 class="modal-title">Update Contact Status</h4>
    <button type="button" class="btn-close" data-dismiss="modal" aria-hidden="true" (click)="clear()"></button>
</div>

<div *ngIf="(contact.subscriptionCount > 0 || contact.businessOwnerCount > 0) && activeManagedUser()">
    <p class="mx-3 mt-3 pb-0">
        <span
            >In order to deactivate <span class="text-bold">{{ contactFullName }}</span
            >, please reassign their owned relationships. Select a contact to reassign these to.</span
        >
    </p>
    <app-contact-reassign-modal [contactId]="contact.id" [onlyShowReassignmentForm]="true"></app-contact-reassign-modal>
</div>

<div *ngIf="contact.subscriptionCount === 0 && contact.businessOwnerCount === 0" class="modal-header mb-0">
    <p>
        Are you sure you want to {{ activeManagedUser() ? 'Deactivate' : 'Activate' }}
        <span class="text-bold">{{ contactFullName }}</span
        >?
    </p>
</div>
<hr *ngIf="contact.subscriptionCount > 0 || contact.businessOwnerCount > 0" class="mt-0" />

<span *ngIf="activeManagedUser()">
    <div class="d-block justify-content-start main-action m-3">
        <button
            class="float-end mb-3"
            mat-button
            visoButton="primary"
            toggleDisableOnActions
            [disablingActions]="[
                ContactActions.UpdateManagedContactStatusRequest,
                ContactActions.ReassignContactRelationshipsRequest,
            ]"
            [enablingActions]="[
                ContactActions.UpdateManagedContactStatusRequestSuccess,
                ContactActions.UpdateManagedContactStatusRequestFailed,
                ContactActions.ReassignContactRelationshipsRequestFailed,
            ]"
            [disabled]="contactReassignModalComponent?.contactReassignFormGroup.invalid"
            (click)="updateManagedContactStatus()"
        >
            <span *ngIf="contact.subscriptionCount > 0 || contact.businessOwnerCount > 0">Reassign and </span>
            <span>Deactivate Contact</span>
        </button>
    </div>
</span>

<span *ngIf="!activeManagedUser()">
    <div class="d-block justify-content-start main-action m-3">
        <button
            class="float-end mb-3"
            mat-button
            visoButton="primary"
            toggleDisableOnActions
            [disablingActions]="[ContactActions.UpdateManagedContactStatusRequest]"
            [enablingActions]="[
                ContactActions.UpdateManagedContactStatusRequestSuccess,
                ContactActions.UpdateManagedContactStatusRequestFailed,
            ]"
            (click)="updateManagedContactStatus()"
        >
            <span>Activate Contact</span>
        </button>
    </div>
</span>
<button
    type="button"
    mat-button
    visoButton="secondary"
    class="float-end mb-3 me-2"
    data-dismiss="modal"
    (click)="clear()"
>
    <span>Cancel</span>
</button>
