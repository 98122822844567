import { createAction, props } from '@ngrx/store';
import { LifecycleManagementUpdateRequest } from '../../../../entities/relationship';
import { LifecycleManagementModalProps } from '../../models/lifecycle-management.model';

export enum LifecycleManagementActions {
    OpenLifecycleManagementModal = '[Request] Open Lifecycle Management Modal',
    UpdateLifecycleManagement = '[Request] Update Lifecycle Management',
    UpdateLifecycleManagementSuccess = '[Request] Update Lifecycle Management Success',
    UpdateLifecycleManagementFailed = '[Request] Update Lifecycle Management Failed',
}

export const openLifecycleManagementModal = createAction(
    LifecycleManagementActions.OpenLifecycleManagementModal,
    props<LifecycleManagementModalProps>(),
);

export const updateLifecycleManagement = createAction(
    LifecycleManagementActions.UpdateLifecycleManagement,
    props<{ relationshipId: number; request: LifecycleManagementUpdateRequest }>(),
);

export const updateLifecycleManagementSuccess = createAction(
    LifecycleManagementActions.UpdateLifecycleManagementSuccess,
);

export const updateLifecycleManagementFailed = createAction(LifecycleManagementActions.UpdateLifecycleManagementFailed);
