import { Directive, ElementRef, Renderer2, Input, ViewContainerRef, AfterContentInit } from '@angular/core';
import { MatAnchor } from '@angular/material/button';
import { ThemePalette } from '@angular/material/core';

export type VisoAnchorTypes = 'primary' | 'secondary' | 'secondary-alt' | 'text' | 'warning' | 'success';

@Directive({
    selector: 'a[mat-button][visoButton]',
})
export class VisoAnchorDirective implements AfterContentInit {
    @Input() color: string;
    @Input() visoButton: VisoAnchorTypes | undefined = null;

    constructor(
        private matButton: MatAnchor,
        // @ts-ignore
        private viewContainerRef: ViewContainerRef,
        private elementRef: ElementRef,
        private renderer: Renderer2,
    ) {
        this.loadVisoButton();
    }

    ngAfterContentInit(): void {
        this.loadVisoButton();
    }

    loadVisoButton(): void {
        this.renderer.addClass(this.elementRef.nativeElement, 'viso-button');
        this.renderer.addClass(this.elementRef.nativeElement, 'link');
        this.matButton.disableRipple = true;

        if (this.visoButton === 'primary') {
            this.matButton.color = (this.color || 'primary') as ThemePalette;
            this.renderer.removeClass(this.elementRef.nativeElement, 'mat-unthemed');
            this.renderer.removeClass(this.elementRef.nativeElement, 'mat-mdc-button');
            this.renderer.addClass(this.elementRef.nativeElement, 'mat-mdc-unelevated-button');
            this.renderer.addClass(this.elementRef.nativeElement, 'mdc-button--unelevated');
        }

        if (this.visoButton === 'secondary') {
            this.matButton.color = (this.color || 'primary') as ThemePalette;
            this.renderer.removeClass(this.elementRef.nativeElement, 'mat-unthemed');
            this.renderer.removeClass(this.elementRef.nativeElement, 'mat-mdc-button');
            this.renderer.addClass(this.elementRef.nativeElement, 'mdc-button--outlined');
            this.renderer.addClass(this.elementRef.nativeElement, 'mat-mdc-outlined-button');
        }

        if (this.visoButton === 'secondary-alt') {
            this.matButton.color = (this.color || 'accent') as ThemePalette;
            this.renderer.addClass(this.elementRef.nativeElement, 'tonal');
        }

        if (this.visoButton === 'text') {
            this.matButton.color = (this.color || 'primary') as ThemePalette;
        }

        if (this.visoButton === 'warning') {
            this.matButton.color = (this.color || 'warn') as ThemePalette;
            this.renderer.removeClass(this.elementRef.nativeElement, 'mat-unthemed');
            this.renderer.removeClass(this.elementRef.nativeElement, 'mat-mdc-button');
            this.renderer.addClass(this.elementRef.nativeElement, 'mat-mdc-unelevated-button');
            this.renderer.addClass(this.elementRef.nativeElement, 'mdc-button--unelevated');
        }
        if (this.visoButton === 'success') {
            this.renderer.addClass(this.elementRef.nativeElement, 'success');
        }
    }
}
