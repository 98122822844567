import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { VisoUser, VisoUserRole } from '../../../../entities/viso-user';
import { Relationship } from '../../../../entities/relationship';

@Component({
    selector: 'app-relationship-details',
    templateUrl: './relationship-details.component.html',
    styleUrls: ['./relationship-details.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RelationshipDetailsComponent {
    @Input()
    relationship: Relationship;

    @Input({ required: true })
    businessOwner: VisoUser;

    @Input()
    export = false;

    readonly descriptionMaxLength = 150;

    Roles = VisoUserRole;
    User = VisoUser;

    private _showMoreDescription = false;

    get showMoreDescription(): boolean {
        return this.export || this._showMoreDescription;
    }

    get editLink() {
        return [
            '/',
            {
                outlets: {
                    popup: `relationship/${this.relationship?.id}/edit/details`,
                },
            },
        ];
    }

    get createdDate(): Date {
        return this.relationship?.createdDate;
    }

    get homepage(): string {
        return this.relationship?.vendorHomepageUrl;
    }

    get isVendorManaged(): boolean {
        return this.relationship?.vendorManagedById !== null;
    }

    get businessPurpose(): string {
        return this.relationship?.description;
    }

    get creator(): VisoUser {
        return this.relationship?.creator;
    }

    get showtoggleDescriptionLength(): boolean {
        return this.businessPurpose?.length > this.descriptionMaxLength;
    }

    toggleDescriptionLength(): void {
        this._showMoreDescription = !this._showMoreDescription;
    }
}
