<div class="modal-header d-flex flex-column">
    <div class="d-flex w-100 align-items-center">
        <h4 class="modal-title">Lifecycle Management</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="close()"></button>
    </div>
</div>
<div class="modal-body mb-4">
    <app-lifecycle-management-form
        [businessOwner]="relationshipBusinessOwner$ | async"
        [primaryContact]="relationshipPrimaryContact$ | async"
        [licenseHasManagedVendorsAvailable]="clientLicenseHasAvailableManagedVendors"
        [lifecycleManagementFormGroup]="lifecycleManagementFormGroup"
        [dateOfNextArtifactExpiration]="dateOfNextArtifactExpiration"
        [latestCompletedAssessmentDate]="latestCompletedAssessmentDate"
        [relationshipHasPrimaryContact]="relationshipHasPrimaryContact$ | async"
    />
</div>
<div class="modal-footer">
    <button mat-button visoButton="secondary" type="button" (click)="close()">Cancel</button>
    <button mat-button visoButton="primary" type="button" (click)="save()">Save</button>
</div>
