import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { finalize, map } from 'rxjs/operators';
import { Org, OrgService } from '../../entities/org';
import { SnackbarService } from '../../shared/components/snackbar/snackbar.service';
import { AssessmentRecommendationService } from './assessment-recommendation.service';

interface AssessmentRecommendationRefreshForm {
    selectedOrg: FormControl<Org>;
}

@Component({
    selector: 'app-assessment-recommendation',
    templateUrl: './assessment-recommendation.component.html',
    styleUrls: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssessmentRecommendationComponent implements OnInit {
    orgs$: Observable<Org[]>;
    assessmentRecommendationRefreshForm: FormGroup<AssessmentRecommendationRefreshForm>;

    isSaving: boolean;

    constructor(
        private _cdr: ChangeDetectorRef,
        private _fb: FormBuilder,
        private _assessmentRecommendationService: AssessmentRecommendationService,
        private _orgService: OrgService,
        private _snackbarService: SnackbarService,
    ) {}

    ngOnInit(): void {
        this.assessmentRecommendationRefreshForm = this._fb.group({
            selectedOrg: this._fb.control(null, { validators: Validators.required }),
        });

        this.orgs$ = this._orgService.clients().pipe(map((response) => response.body));
    }

    refreshAssessmentRecommendations() {
        this.isSaving = true;
        const { id: orgId, name: orgName } = this.getSelectedOrg() || {};

        this._assessmentRecommendationService
            .refreshRecommendations(orgId)
            .pipe(
                finalize(() => {
                    this.isSaving = false;
                    this._cdr.detectChanges();
                }),
            )
            .subscribe(() => {
                this._snackbarService.success(
                    !!orgId
                        ? `Assessment recommendations refreshed for: ${orgName}`
                        : `Assessment recommendations refreshed for all orgs.`,
                );
            });
    }

    private getSelectedOrg(): Org | null {
        return this.assessmentRecommendationRefreshForm.value.selectedOrg;
    }
}
