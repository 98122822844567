import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
    QueryList,
    ViewChildren,
} from '@angular/core';
import { QuillViewComponent } from 'ngx-quill';
import { BehaviorSubject, forkJoin } from 'rxjs';
import { take } from 'rxjs/operators';
import { RelationshipComment } from '../../../../entities/relationship-comment';
import { UserStatus, VisoUser } from '../../../../entities/viso-user';

interface Comment extends RelationshipComment {
    canCurrentUserDelete: boolean;
    isAuthorInactive: boolean;
    safeHtml?: string;
}

@Component({
    selector: 'app-comment-list',
    templateUrl: './comment-list.component.html',
    styleUrls: ['./comment-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommentListComponent implements AfterViewInit {
    @Input()
    set comments(value: RelationshipComment[]) {
        this.comments$.next(
            value?.map<Comment>((comment) => ({
                ...comment,
                canCurrentUserDelete: this.isCurrentUserAuthor(comment),
                isAuthorInactive: this.isAuthorInactive(comment),
            })),
        );
    }

    @Input()
    currentAccount: VisoUser;

    @Output()
    deleteComment = new EventEmitter<number>();

    @Output()
    quillViewsCreated = new EventEmitter<void>();

    comments$ = new BehaviorSubject<Comment[]>([]);

    @ViewChildren(QuillViewComponent)
    private _quillViews: QueryList<QuillViewComponent>;

    ngAfterViewInit(): void {
        forkJoin(this._quillViews.map((quillView) => quillView.onEditorCreated.pipe(take(1))))
            .pipe(take(1))
            .subscribe(() => this.quillViewsCreated.emit());
    }

    private isCurrentUserAuthor(comment: RelationshipComment): boolean {
        return this.currentAccount && comment.authorEmail === this.currentAccount.email;
    }

    private isAuthorInactive(comment: RelationshipComment): boolean {
        return comment.authorStatus === UserStatus.INACTIVE;
    }
}
