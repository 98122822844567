<div class="detection-wrapper row bg-light border" [ngClass]="isSelected ? 'selected' : ''">
    <div class="col">
        <div class="row border-bottom py-3">
            <div class="col">
                <strong>{{ controlName }}</strong>
            </div>
        </div>
        <div class="row border-bottom py-3">
            <div class="col">
                <strong class="d-block">Detected Language</strong>
                <p class="mb-0 detected-language">{{ detectedLanguage }}</p>
            </div>
        </div>
        <div class="row">
            <div class="col py-3 border-end">
                <strong class="d-block">Type</strong>
                <app-badge [ngClass]="detectionTypecssBadgeClass">{{ detectionTypeLabel }}</app-badge>
            </div>
            <div class="col py-3 border-end">
                <strong class="d-block">Control IDs</strong>
                <p class="mb-0">{{ controlIds }}</p>
            </div>
            <div class="col py-3">
                <strong class="d-block">Location</strong>
                <p class="mb-0">{{ location }}</p>
            </div>
        </div>
    </div>
</div>
