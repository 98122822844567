import { createAction, props } from '@ngrx/store';

export enum SharedActions {
    SetViewport = '[Shared] Set Viewport',
    NOOP = 'noop',
}

export const NOOP = createAction(SharedActions.NOOP);

export const setViewport = createAction(SharedActions.SetViewport, props<{ width: number }>());
