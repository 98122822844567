import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';

import { AppState, OrgSubdomainState, orgSubdomainStateKey } from './org-subdomain.state';

export const getOrgSubdomainsState: MemoizedSelector<AppState, OrgSubdomainState> =
    createFeatureSelector<OrgSubdomainState>(orgSubdomainStateKey);

export const getOrgSubdomainDomain: MemoizedSelector<AppState, string> = createSelector(
    getOrgSubdomainsState,
    (state) => state.domain,
);

export const getOrgSubdomain: MemoizedSelector<AppState, string> = createSelector(
    getOrgSubdomainsState,
    (state) => state.subdomain,
);
