import * as fromRoot from '../../../shared/redux/state';
import { RelationshipAdditionalFilters } from '../models/relationship-additional-filters.model';
import { RelationshipColumnGroup } from '../models/relationship-column.model';
import { ColumnNames } from '../relationships.constants';
import { VisoUserRole } from '@entities/viso-user';

export const relationshipsStateKey = 'relationships';

export const initialState: RelationshipsState = {
    columnsGroups: [
        {
            columnGroupName: 'Vendor Review',
            columns: [
                { columnName: ColumnNames.relationship, visible: true },
                { columnName: ColumnNames.client, visible: true, roles: [VisoUserRole.Auditor, VisoUserRole.Support] },
                { columnName: ColumnNames.status, visible: true },
                { columnName: ColumnNames.risk, visible: true },
                { columnName: ColumnNames.score, visible: false },
                { columnName: ColumnNames.inherentRisk, visible: false },
                { columnName: ColumnNames.inherentRiskScore, visible: false },
                { columnName: ColumnNames.residualRisk, visible: false },
                { columnName: ColumnNames.lastUpdated, visible: true },
                { columnName: ColumnNames.remediationRequested, visible: true },
            ],
        },
        {
            columnGroupName: 'Assessment Details',
            columns: [
                { columnName: ColumnNames.assessmentPhase, visible: true },
                { columnName: ColumnNames.recommendationCount, visible: true },
                { columnName: ColumnNames.assessmentCreatedDate, visible: false },
                { columnName: ColumnNames.phaseDate, visible: true },
                { columnName: ColumnNames.recertificationDate, visible: true },
                { columnName: ColumnNames.recertificationType, visible: true },
            ],
        },
        {
            columnGroupName: 'Metadata',
            columns: [
                { columnName: ColumnNames.artifactUpdateType, visible: false },
                { columnName: ColumnNames.tags, visible: true },
                { columnName: ColumnNames.businessUnit, visible: true },
                { columnName: ColumnNames.businessOwnerEmail, visible: false },
                { columnName: ColumnNames.businessOwnerName, visible: true },
                { columnName: ColumnNames.primaryContactEmail, visible: false },
                { columnName: ColumnNames.primaryContactName, visible: false },
                { columnName: ColumnNames.createdBy, visible: true },
                { columnName: ColumnNames.createdOn, visible: true },
            ],
        },
    ],
    additionalFilters: null,
};

export interface AppState extends fromRoot.AppState {
    relationships: RelationshipsState;
}

export interface RelationshipsState {
    columnsGroups: RelationshipColumnGroup[];
    additionalFilters: RelationshipAdditionalFilters;
}
