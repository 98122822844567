<div class="row mt-3">
    <div class="col">
        <ng-container
            *ngIf="{
                vendorDetails: vendorDetails$ | async,
                riskAdvisories: riskAdvisories$ | async,
                totalRiskAdvisories: totalRiskAdvisoryCount$ | async,
                isCurrentUserAdmin: isCurrentUserAdmin$ | async,
                isCurrentUserSupportAuditAdmin: isCurrentUserSupportAuditAdmin$ | async,
            } as ctx"
        >
            <app-vendor-details
                *ngIf="!isEditingDirectoryRecord"
                [vendorDetails]="ctx.vendorDetails"
                [riskAdvisories]="ctx.riskAdvisories"
                [userCanEdit]="ctx.isCurrentUserSupportAuditAdmin"
                (onEditClicked)="toggleEdit()"
                (onRefreshDetailsClicked)="refreshVendorDetails(ctx.vendorDetails)"
                [totalRiskAdvisories]="ctx.totalRiskAdvisories"
                [refreshingVendorDetails]="refreshingVendorDetails"
                [showRefreshDetailsButton]="showRefreshVendorDetailsButton"
                (onRiskAdvisoryPageChange)="onRiskAdvisoryPageChange($event)"
                (onRiskAdvisorySortChange)="onRiskAdvisorySortChange($event)"
                (deleteRiskAdvisory)="deleteRiskAdvisory($event)"
            />
            <app-vendor-details-form
                *ngIf="isEditingDirectoryRecord"
                [vendorDetails]="ctx.vendorDetails"
                [isCurrentUserAdmin]="ctx.isCurrentUserAdmin"
                (onDoneClicked)="toggleEdit()"
                (onAddDomain)="addNewDomain($event)"
                (onUpdateVendorDetails)="updateVendorDetails($event, ctx.isCurrentUserAdmin)"
            />
        </ng-container>
    </div>
</div>
