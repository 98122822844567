import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RadioButtonGroupComponent } from './components/radiobutton-group/radiobutton-group.component';
import { RadioButtonGroupItemDirective } from './directives/radiobutton-group-item/radiobutton-group-item.directive';

@NgModule({
    declarations: [RadioButtonGroupComponent, RadioButtonGroupItemDirective],
    imports: [CommonModule],
    exports: [RadioButtonGroupComponent, RadioButtonGroupItemDirective],
})
export class RadioButtonGroupModule {}
