import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-supplemental-questionnaire-step',
    templateUrl: './supplemental-questionnaire-step.component.html',
    styleUrl: './supplemental-questionnaire-step.component.scss',
})
export class SupplementalQuestionnaireStepComponent {
    @Input({ required: true })
    clientName: string;
}
