import { Routes } from '@angular/router';
import { UserActivationGuard } from '../../shared/guards/user-activation.guard';
import { NoSessionGuard } from '../../shared/guards/no-session.guard';
import { UserActivationComponent } from './user-activation/user-activation.component';
import { UserRegistrationComponent } from './user-registration.component';

export const userRegistrationRoutes: Routes = [
    {
        path: 'sign-up',
        component: UserRegistrationComponent,
        canActivate: [NoSessionGuard],
    },
    {
        path: 'activate',
        component: UserActivationComponent,
        canActivate: [UserActivationGuard],
    },
];
