<div class="row mb-3">
    <div class="col">
        <h3 class="lead mb-0">Tags</h3>
    </div>
    <div class="col-auto">
        <div class="mb-1" exportRemovableAction>
            <ng-container *jhiHasAnyAuthority="[Roles.OrgAdmin, Roles.OrgUser]">
                <a
                    class="text-primary clickable"
                    *hideIfOrgUserIsNotBusinessOwner="businessOwner"
                    [matMenuTriggerFor]="menu"
                >
                    Add
                </a>
            </ng-container>
            <mat-menu #menu="matMenu">
                <app-tag-select
                    (click)="$event.stopPropagation()"
                    [ngModel]="tags"
                    (addTag)="selectLinkTag($event)"
                    (removeTag)="selectUnlinkTag($event)"
                ></app-tag-select>
            </mat-menu>
        </div>
    </div>
</div>
<div class="row" *ngIf="tags?.length > 0; else noTagsRef">
    <div class="col">
        <button
            *ngFor="let tag of tags"
            [tagColor]="tag.color"
            class="btn me-2 mb-2"
            [hoverColor]="canDeleteTags"
            toggleDisableOnActions
            [disablingActions]="[RequestActions.LinkTagRequest, RequestActions.UnlinkTagRequest]"
            [enablingActions]="[
                RequestActions.LinkTagRequestSuccess,
                RequestActions.LinkTagRequestFailed,
                RequestActions.UnlinkTagRequestSuccess,
                RequestActions.UnlinkTagRequestFailed,
            ]"
            (click)="canDeleteTags && selectUnlinkTag(tag)"
        >
            {{ tag.name }}
        </button>
    </div>
</div>
<ng-template #noTagsRef>
    <p class="text-muted">No tags added yet.</p>
</ng-template>
