import { createSelector, MemoizedSelector } from '@ngrx/store';
import moment from 'moment';
import { AppState } from '../assessment-collection.state';
import { getPublicAssessment } from './main.selectors';

const getExtensionAllowedDate: MemoizedSelector<AppState, moment.Moment> = createSelector(
    getPublicAssessment,
    (publicAssessment) => {
        if (!publicAssessment?.expireDate) {
            return null;
        }
        return moment(publicAssessment.expireDate).subtract(7, 'd');
    },
);

export const getIsExpirationExtensionAllowed: MemoizedSelector<AppState, boolean> = createSelector(
    getExtensionAllowedDate,
    (extensionAllowedDate) => !!extensionAllowedDate && moment().isAfter(extensionAllowedDate, 'day'),
);
