import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FileArtifactType } from '@entities/artifact';
import { FileLikeObject, FileUploader } from '@shared/file-upload';
import {
    ArtifactUploadRecommendation,
    CollectionTypes,
    ExpiredArtifactReplacement,
    PublicAssessmentRTPFileArtifact,
} from '../../../models';
import { AssessmentRecommendation } from '@entities/recommendation';
import { FilterFunction } from '@shared/file-upload/interfaces';

@Component({
    selector: 'app-initial-artifact-upload',
    templateUrl: './initial-artifact-upload.component.html',
    styleUrls: ['./initial-artifact-upload.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InitialArtifactUploadComponent implements OnInit {
    @Input({ required: true })
    uploadedArtifacts: PublicAssessmentRTPFileArtifact[];

    @Input({ required: true })
    expiredArtifactReplacements: ExpiredArtifactReplacement[];

    @Input({ required: true })
    collectionType: CollectionTypes;

    @Input({ required: true })
    uploadedStatusesOfInScopeFileTypes: Partial<Record<FileArtifactType, boolean>>;

    @Input({ required: true })
    artifactUploadRecommendations: ArtifactUploadRecommendation[];

    @Input({ required: true })
    previousAssessmentRecommendations: AssessmentRecommendation[];

    @Input({ required: true })
    vendorName: string;

    @Input({ required: true })
    disableContinueButton: boolean;

    @Input({ required: true })
    classificationInProgress: boolean;

    @Output()
    uploadArtifacts = new EventEmitter<File[]>();

    @Output()
    uploadAndReplace = new EventEmitter<{ file: File; artifactIdToReplace: number }>();

    @Output()
    wentBack = new EventEmitter<void>();

    @Output()
    continued = new EventEmitter<void>();

    @Output()
    setPassword = new EventEmitter<{
        artifact: PublicAssessmentRTPFileArtifact;
        password: string;
    }>();

    @Output()
    removeArtifact = new EventEmitter<PublicAssessmentRTPFileArtifact>();

    @Output()
    uploadFailed = new EventEmitter<FilterFunction>();

    uploader: FileUploader;

    get title(): string {
        return this.collectionType === CollectionTypes.ARTIFACT_UPDATE
            ? "Let's keep your artifacts up to date!"
            : 'A security assessment is as easy as uploading artifacts.';
    }

    get description(): string {
        return this.collectionType === CollectionTypes.ARTIFACT_UPDATE
            ? 'Upload a new version of your expired artifacts or add artifacts to reduce the risk of the relationship.'
            : "Provide artifacts that describe your company's security and compliance program. In seconds, our AI will " +
                  'extract risk-relevant information. Every assessment is reviewed by a team of experts.';
    }

    get showExpiredArtifactsDescription(): boolean {
        return this.collectionType !== CollectionTypes.ARTIFACT_UPDATE && !!this.expiredArtifactReplacements?.length;
    }

    get showArtifactExamples(): boolean {
        return this.collectionType !== CollectionTypes.ARTIFACT_UPDATE;
    }

    get showPreviousAssessmentRecommendations(): boolean {
        return !!this.previousAssessmentRecommendations?.length;
    }

    get artifactListPopulated(): boolean {
        return !!this.uploadedArtifacts?.length || !!this.expiredArtifactReplacements?.length;
    }

    ngOnInit(): void {
        this.setupFileUploader();
    }

    private setupFileUploader(): void {
        this.uploader = new FileUploader({
            url: '',
            maxFileSize: 100000000,
        });

        this.uploader.onWhenAddingFileFailed = (item: FileLikeObject, filter: any, options: any) =>
            this.uploadFailed.emit(filter);
    }
}
