<div
    class="drop-container"
    #dropContainer
    appElementVisibility
    (isIntersecting)="fileDropIsVisibleOnDom($event)"
    [isContinuous]="true"
>
    <span>
        <mat-icon [inline]="true" class="me-2">upload_file</mat-icon>
        {{ uploadLabel }}
    </span>
</div>
