import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ArtifactService } from '../../../entities/artifact';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import {
    addQuestionToQuestionnaireRequest,
    addQuestionToQuestionnaireRequestFailed,
    addQuestionToQuestionnaireRequestSuccess,
    deleteQuestionnaireQuestionRequest,
    deleteQuestionnaireQuestionRequestFailed,
    deleteQuestionnaireQuestionRequestSuccess,
    updateQuestionnaireQuestionRequest,
    updateQuestionnaireQuestionRequestFailed,
    updateQuestionnaireQuestionRequestSuccess,
} from './questionnaire.actions';

@Injectable()
export class QuestionnaireEffects {
    addQuestionToQuestionnaireRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(addQuestionToQuestionnaireRequest),
            switchMap(({ questionnaireId, question }) =>
                this._artifactService.addQuestionToQuestionnaire(questionnaireId, question).pipe(
                    map(() => addQuestionToQuestionnaireRequestSuccess()),
                    catchError(() => of(addQuestionToQuestionnaireRequestFailed())),
                ),
            ),
        ),
    );

    updateQuestionnaireQuestionRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(updateQuestionnaireQuestionRequest),
            switchMap(({ questionnaireId, questionId, question }) =>
                this._artifactService.updateQuestionnaireQuestion(questionnaireId, questionId, question).pipe(
                    map(() => updateQuestionnaireQuestionRequestSuccess()),
                    catchError(() => of(updateQuestionnaireQuestionRequestFailed())),
                ),
            ),
        ),
    );

    deleteQuestionnaireQuestionRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(deleteQuestionnaireQuestionRequest),
            switchMap(({ questionnaireId, questionId }) =>
                this._artifactService.deleteQuestionnaireQuestion(questionnaireId, questionId).pipe(
                    map(() => deleteQuestionnaireQuestionRequestSuccess()),
                    catchError(() => of(deleteQuestionnaireQuestionRequestFailed())),
                ),
            ),
        ),
    );

    constructor(
        private _actions$: Actions,
        private _artifactService: ArtifactService,
    ) {}
}
