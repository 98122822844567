import { Directive, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { merge, Subject } from 'rxjs';
import { delay, map, takeUntil, tap } from 'rxjs/operators';

@Directive({
    selector: 'button[toggleDisableOnActions]',
})
export class ToggleDisableOnActionsDirective implements OnInit, OnDestroy {
    @Input()
    disablingActions: string[] = [];

    @Input()
    enablingActions: string[] = [];

    @Input()
    onLoadingText: string = '';

    @Input()
    enablingDelay: boolean = true;

    private _unsub$ = new Subject<void>();
    private _delay = 500;

    constructor(
        private _elem: ElementRef<HTMLButtonElement>,
        private _actions$: Actions,
    ) {}

    ngOnInit(): void {
        const initialHtml = this._elem.nativeElement.innerHTML;
        merge(
            this._actions$.pipe(
                ofType(...this.disablingActions),
                map(() => true),
            ),
            this._actions$.pipe(
                ofType(...this.enablingActions),
                map(() => false),
                delay(this.enablingDelay ? this._delay : 0),
            ),
        )
            .pipe(
                tap((disableState) => {
                    this._elem.nativeElement.disabled = disableState;
                    if (this.onLoadingText) {
                        this._elem.nativeElement.innerHTML = disableState ? this.onLoadingText : initialHtml;
                    }
                }),
                takeUntil(this._unsub$),
            )
            .subscribe();
    }

    ngOnDestroy(): void {
        this._unsub$.next();
    }
}
