import { animate, animateChild, group, query, state, style, transition, trigger } from '@angular/animations';

export const navsearchAnimation = [
    trigger('clearExpand', [
        state('1', style({ opacity: '*' })),
        state('0', style({ opacity: '0', cursor: 'auto' })),
        transition('1<=>0', animate(200)),
    ]),
    trigger('searchExpand', [
        state('1', style({ width: '*', color: '#77818A' })),
        state('0', style({ width: '0px' })),
        transition('0=>1', [
            group([
                style({ width: '0px' }),
                animate(200, style({ width: '*' })),
                query('@inputExpand', [
                    style({ width: '0' }),
                    animate(
                        200,
                        style({
                            width: '*',
                        }),
                    ),
                ]),
                query('@clearExpand', [animateChild()], { optional: true }),
            ]),
        ]),
        transition('1=>0', [
            group([
                style({ width: '*' }),
                animate(
                    100,
                    style({
                        width: '0px',
                    }),
                ),
                query('@inputExpand', [
                    animate(
                        100,
                        style({
                            width: '0',
                            opacity: '0',
                        }),
                    ),
                    style({ display: 'none' }),
                ]),
                query('@clearExpand', [animateChild()]),
            ]),
        ]),
    ]),
    trigger('inputExpand', [
        state('0', style({ width: '0', display: 'none' })),
        transition('0=>1', [style({ display: 'initial' })]),
    ]),
];
