export enum DataSensitivityLevel {
    NONE = 'NONE',
    LOW = 'LOW',
    MEDIUM = 'MEDIUM',
    HIGH = 'HIGH',
    EXTREME = 'EXTREME',
}

export enum DataSensitivityLevelLabels {
    NONE = 'None',
    LOW = 'Low Sensitivity',
    MEDIUM = 'Medium Sensitivity',
    HIGH = 'High Sensitivity',
    EXTREME = 'Extreme Sensitivity',
}
