import { Risk } from '@entities/risk-assessment';
import { OrgRiskConfig } from '../../admin/client-profile/client-profile.model';

export enum RiskTolerance {
    MINIMAL = 'MINIMAL',
    MODERATE = 'MODERATE',
    SIGNIFICANT = 'SIGNIFICANT',
}

export enum RiskToleranceLabels {
    MINIMAL = 'Minimal',
    MODERATE = 'Moderate',
    SIGNIFICANT = 'Significant',
}

export enum RiskToleranceOrdinal {
    MINIMAL,
    MODERATE,
    SIGNIFICANT,
}

export interface RelationshipRiskToleranceView {
    residualRisk: Risk;
    inherentRisk: Risk;
    residualRiskScore: number;
    inherentRiskScore: number;
    vendorName: string;
    businessUnitName: string;
}

export interface UpdateRiskToleranceSettingsRequest {
    riskToleranceLevel: RiskTolerance;
    riskLevelDisplayNames: OrgRiskConfig[];
}
