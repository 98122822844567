import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HasAnyAuthorityDirective, HasNotAuthorityDirective } from './directives';

@NgModule({
    declarations: [HasAnyAuthorityDirective, HasNotAuthorityDirective],
    imports: [CommonModule],
    exports: [HasAnyAuthorityDirective, HasNotAuthorityDirective],
})
export class AuthDirectivesModule {}
