import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { NotificationStatus, TipNotification, TipNotificationType } from '@entities/notification';
import { addRelationshipRoute } from '../../../../routes/add-relationship/add-relationship.constants';

@Component({
    selector: 'app-tip-notification',
    templateUrl: './tip-notification.component.html',
    styleUrls: ['./tip-notification.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TipNotificationComponent {
    @Input()
    tipNotification: TipNotification;

    @Output()
    markAsDismissed = new EventEmitter<MouseEvent>();

    TipNotificationType = TipNotificationType;

    get isNew(): boolean {
        return this.tipNotification.status === NotificationStatus.NEW;
    }

    get tipLink(): any[] {
        switch (this.tipNotification.tipType) {
            case TipNotificationType.ADD_LOGO:
                return ['/your-organization'];
            case TipNotificationType.ADD_RELATIONSHIP:
                return [
                    '/',
                    {
                        outlets: {
                            popup: addRelationshipRoute,
                        },
                    },
                ];
            case TipNotificationType.EXPLORE_RELATIONSHIP:
                return [`/requests/${this.tipNotification?.relationshipId}`];
            case TipNotificationType.VISO_RELATIONSHIP:
                return [`/requests/${this.tipNotification?.relationshipId}`];
            default:
                return [''];
        }
    }
}
