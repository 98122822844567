import { Route } from '@angular/router';
import { JwtManagementComponent } from './jwt-management.component';

export const jwtManagementRoute: Route = {
    path: 'jwt-management',
    component: JwtManagementComponent,
    data: {
        pageTitle: 'JWT Management',
    },
};
