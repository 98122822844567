import { DataSensitivityLevel } from '../../admin/client-profile/data-sensitivity-levels-config/data-sensitivity-level.model';
import { BaseEntity } from './../../shared';

export enum DataTypeCategory {
    ORGANIZATION = 'ORGANIZATION',
    CUSTOMER_OR_PARTNER = 'CUSTOMER_OR_PARTNER',
}

export class DataType implements BaseEntity {
    constructor(
        public id?: number,
        public name?: string,
        public sensitivity?: number,
        public sensitivityLevel?: DataSensitivityLevel,
        public description?: string,
        public clientDataDefinitionId?: number, // TODO: remove
        public orgId?: number,
        public exampleText?: string,
        public category?: DataTypeCategory,
        public custom?: boolean, // read-only
        public relevantControlDomainIds?: number[],
    ) {}
}
