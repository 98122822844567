<div class="search-container">
    <button
        [@clearExpand]="{ value: +!expanded, params: { finalOpacity: +!expanded } }"
        disableRipple
        mat-icon-button
        (click)="onSearchClicked(myInput)"
    >
        <mat-icon>search</mat-icon>
    </button>
    <div [@searchExpand]="expanded" class="search-input-wrapper">
        <mat-form-field appearance="outline" class="search-form-field">
            <mat-icon matIconPrefix class="pt-0 pb-0 pe-0">search</mat-icon>
            <input
                [@inputExpand]="expanded"
                #myInput
                matInput
                class="search-input"
                placeholder="Search"
                type="text"
                [matAutocomplete]="auto"
                [(ngModel)]="searchText"
                (keyup)="listenKeyUp($event)"
                (ngModelChange)="search($event)"
            />
            <button
                matSuffix
                [@clearExpand]="{
                    value: searchText.length > 0 && expanded,
                    params: { finalOpacity: searchText.length > 0 ? 1 : 0 },
                }"
                mat-icon-button
                (click)="close(); myInput.focus()"
                class="shrink me-2"
            >
                <mat-icon>close</mat-icon>
            </button>
        </mat-form-field>
    </div>
</div>

<mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
    <mat-option
        *ngFor="let relationship of relationships"
        [value]="relationship.vendorOrgName"
        (click)="vendorSelected = true"
        [routerLink]="['/requests', relationship.id]"
    >
        {{ relationship.vendorOrgName }}
    </mat-option>
</mat-autocomplete>
