import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Routes } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { skip, take } from 'rxjs/operators';
import { Tag } from '../../entities/tag';
import { VisoUserRole } from '../../entities/viso-user';
import { AuthGuard } from '../../shared';
import { AppState } from '../../shared/redux/state';
import { selectTagById } from './redux/tag.actions';
import { getSelectedTagSelector } from './redux/tag.selectors';
import { TagManagementComponent } from './tag-management/tag-management.component';

@Injectable()
export class GetTagResolver {
    constructor(private _store$: Store<AppState>) {}
    resolve(route: ActivatedRouteSnapshot): Observable<Tag> {
        this._store$.dispatch(selectTagById({ id: route.params.id }));
        return this._store$.pipe(select(getSelectedTagSelector), skip(1), take(1));
    }
}

export const tagRoutes: Routes = [
    {
        path: 'tags',
        component: TagManagementComponent,
        data: {
            authorities: [VisoUserRole.OrgAdmin, VisoUserRole.OrgUser, VisoUserRole.ReadOnly],
            pageTitle: 'Tag Management',
        },
        canActivate: [AuthGuard],
    },
];

export const tagPopupRoutes: Routes = [
    {
        path: 'tag-new',
        children: [],
        data: {
            authorities: [VisoUserRole.OrgAdmin],
            pageTitle: 'Add tag',
        },
        canActivate: [AuthGuard],
        outlet: 'popup',
    },
    {
        path: 'tag/:id/edit',
        children: [],
        data: {
            authorities: [VisoUserRole.OrgAdmin],
            pageTitle: 'Edit Tag',
        },
        canActivate: [AuthGuard],
        outlet: 'popup',
        resolve: {
            tag: GetTagResolver,
        },
    },
];
