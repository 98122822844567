import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AuditReport, AuditReportTypeCode, AuditReportTypeName, PublicAuditReport } from './audit-report.model';
import { createRequestOption } from '../../shared';

export type AuditReportArrayResponseType = HttpResponse<AuditReport[]>;
export type PublicAuditReportArrayResponseType = HttpResponse<PublicAuditReport[]>;

@Injectable({
    providedIn: 'root',
})
export class AuditReportService {
    private _resourceUrl = 'api/audit-reports';
    private _publicAuditReportResourceUrl = 'api/public-audit-reports';

    constructor(private _http: HttpClient) {}

    query(req?: any): Observable<AuditReportArrayResponseType> {
        const options = createRequestOption(req);
        return this._http
            .get<AuditReport[]>(this._resourceUrl, { params: options, observe: 'response' })
            .pipe(map((res: AuditReportArrayResponseType) => this.convertArrayResponse(res)));
    }

    queryPublicAuditReports(): Observable<PublicAuditReportArrayResponseType> {
        return this._http.get<PublicAuditReport[]>(this._publicAuditReportResourceUrl, { observe: 'response' });
    }

    private convertArrayResponse(res: AuditReportArrayResponseType): AuditReportArrayResponseType {
        const jsonResponse: AuditReport[] = res.body;
        const body: AuditReport[] = [];
        for (let i = 0; i < jsonResponse.length; i++) {
            body.push(this.convert(jsonResponse[i]));
        }
        return res.clone({ body });
    }

    /**
     * Convert a AuditReport to a JSON which can be sent to the server.
     */
    private convert(auditReport: AuditReport): AuditReport {
        const copy: AuditReport = Object.assign({}, auditReport);
        return copy;
    }

    getAuditTypeFriendlyName(userProvidedAuditType: AuditReportTypeCode): string {
        const auditType: AuditReportTypeCode = userProvidedAuditType || AuditReportTypeCode.NONE;
        return AuditReportTypeName[auditType] !== undefined ? AuditReportTypeName[auditType] : auditType;
    }
}
