<p class="text-bold">Relationship distribution</p>
<canvas
    baseChart
    [data]="barChartData"
    [options]="barChartOptions"
    [plugins]="barChartPlugins"
    [legend]="false"
    [type]="'bar'"
>
</canvas>
