import { Injectable, OnDestroy } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import LogRocket from 'logrocket';
import { filter, switchMap, takeUntil, withLatestFrom } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { getUserAccount, getUserAuthority } from '../../routes/session/redux/session.selectors';
import { getServerSessionCompleted } from '../../routes/session/redux/session.actions';
import { AppState } from '../redux/state';
import { VisoUserRole } from '../../entities/viso-user';
import LogRocketFuzzySearchSanitizer from 'src/main/webapp/logrocket/FuzzySearchSanitizer';
import { fieldNamesToSanitize, headerNamesToSanitize } from 'src/main/webapp/logrocket/sanitation-constants';
import { getPublicAssessmentRequestSuccess } from '../../routes/assessment-collection/assessment-collection-common/redux/actions';

@Injectable({
    providedIn: 'root',
})
export class LogrocketService implements OnDestroy {
    private _unsub: Subject<void> = new Subject<void>();
    private initialized = false;

    constructor(
        private _store$: Store<AppState>,
        private _actions$: Actions,
    ) {
        if (environment.logrocketClientId) {
            this._actions$.pipe(ofType(getPublicAssessmentRequestSuccess), takeUntil(this._unsub)).subscribe(() => {
                if (!this.initialized) {
                    this.initialized = true;
                    this.startLogRocket(false);
                }
            });

            this._actions$
                .pipe(
                    ofType(getServerSessionCompleted),
                    filter(({ error }) => !error),
                    switchMap(() =>
                        this._store$
                            .pipe(select(getUserAccount))
                            .pipe(withLatestFrom(this._store$.pipe(select(getUserAuthority(VisoUserRole.Auditor))))),
                    ),
                    takeUntil(this._unsub),
                )
                .subscribe(([account, isAuditor]) => {
                    if (!account) {
                        return;
                    }

                    if (!this.initialized) {
                        this.initialized = true;
                        this.startLogRocket(!isAuditor);
                    }

                    const {
                        authorities,
                        clientLicense: { licenseType },
                        email,
                        firstName,
                        id,
                        lastName,
                        orgDomains,
                        orgId,
                        orgName,
                    } = account;

                    const domains = orgDomains.join(',');
                    const name = [firstName, lastName].filter(Boolean).join(' ');
                    const role = authorities[0];

                    LogRocket.identify(`${id}`, {
                        domains,
                        email,
                        licenseType,
                        name,
                        orgId,
                        orgName,
                        role,
                    });
                });
        }
    }

    ngOnDestroy(): void {
        this._unsub.next();
    }

    startLogRocket(maskData: boolean): void {
        const { requestSanitizer, responseSanitizer } = new LogRocketFuzzySearchSanitizer(
            fieldNamesToSanitize,
            headerNamesToSanitize,
            maskData,
        );

        LogRocket.init(environment.logrocketClientId, {
            release: environment.production ? environment.commitHash : undefined,
            dom: {
                isEnabled: true,
                inputSanitizer: maskData && environment.production ? true : false,
                textSanitizer: maskData && environment.production ? true : false,
            },
            network: {
                requestSanitizer,
                responseSanitizer,
            },
        });
    }
}
