<form [formGroup]="configForm" (ngSubmit)="save()">
    <div class="modal-header">
        <h4 class="modal-title">Coupa Configuration</h4>
        <button type="button" class="btn-close" data-dismiss="modal" aria-hidden="true" (click)="clear()"></button>
    </div>

    <div class="modal-body p-4">
        <div class="form-group required">
            <label>Instance</label>
            <input
                type="text"
                class="form-control"
                name="instance"
                id="field_instance"
                data-pw="instanceInput"
                formControlName="instance"
                placeholder="Instance"
                required
            />
        </div>
        <div class="form-group required">
            <label>Access Key</label>
            <input
                type="text"
                class="form-control"
                name="accessKey"
                id="field_access_key"
                data-pw="accessKeyInput"
                formControlName="accessKey"
                placeholder="Access Key"
                required
            />
        </div>
        <div class="form-group required">
            <label>Secret Key</label>
            <input
                type="password"
                class="form-control"
                name="secretKey"
                id="field_secret_key"
                data-pw="secretKeyInput"
                formControlName="secretKey"
                placeholder="Secret Key"
                required
            />
        </div>
        <div class="form-group required">
            <label>Scopes</label>
            <input
                type="text"
                class="form-control"
                name="scopes"
                id="field_scopes"
                data-pw="scopesInput"
                formControlName="scopes"
                placeholder="Scopes"
                required
            />
        </div>
    </div>

    <div class="modal-footer d-flex justify-content-end main-action">
        <button
            type="button"
            mat-button
            visoButton="secondary"
            class="float-end"
            data-dismiss="modal"
            (click)="clear()"
        >
            <span>Cancel</span>
        </button>
        <button
            type="submit"
            [disabled]="configForm.invalid"
            toggleDisableOnActions
            [disablingActions]="[IntegrationActions.PutCoupaIntegrationRequest]"
            [enablingActions]="[
                IntegrationActions.PutCoupaIntegrationRequestSuccess,
                IntegrationActions.PutCoupaIntegrationRequestFailed,
            ]"
            (click)="save()"
            class="float-end"
            mat-button
            visoButton="primary"
            data-pw="saveConfigButton"
        >
            <span>Save</span>
        </button>
    </div>
</form>
