import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SelectableDetectionItem } from '../../models/detection';
import { ControlValidationDetectionType } from '../../../../entities/artifact';

@Component({
    selector: 'detection',
    templateUrl: './detection.component.html',
    styleUrls: ['./detection.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DetectionComponent {
    @Input({ required: true })
    detection: SelectableDetectionItem;

    get controlName(): string {
        return this.detection.controlName ?? this.detection.controlDomainName;
    }

    get detectedLanguage(): string {
        return this.detection.detectedLanguage || '-';
    }

    get detectionTypeLabel(): string {
        if (this.detection.type === ControlValidationDetectionType.SHARED_RESPONSIBILITY_MODEL) {
            return `${this.detection.type.toLowerCase().replace(/_/g, ' ').replace(' model', '')}`;
        }
        return `${this.detection.type.toLowerCase()}`;
    }

    get detectionTypecssBadgeClass(): string {
        if (this.detection.type === ControlValidationDetectionType.SHARED_RESPONSIBILITY_MODEL) {
            return `${this.detection.type.toLowerCase().replace(/_/g, '-').replace('-model', '')}-badge`;
        }
        return `${this.detection.type.toLowerCase()}-badge`;
    }

    get controlIds(): string {
        return this.detection.controlIds || '-';
    }

    get location(): string {
        return this.detection.location || '-';
    }

    get isSelected(): boolean {
        return this.detection.selected;
    }
}
