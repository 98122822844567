<section class="edit-columns-modal-title">
    <span>Edit Columns</span>
    <button type="button" class="btn-close" aria-label="Close" (click)="closeModal()"></button>
</section>

<section class="edit-columns-modal-body">
    <div class="suggested-grouping-option">
        <mat-slide-toggle
            color="primary"
            [(ngModel)]="useSuggestedGroups"
            class="full-width reverse space-between"
            (change)="toggleUseSuggestedGroups()"
        >
            Use suggested groupings
        </mat-slide-toggle>
    </div>

    <hr class="separator" />

    <div class="columns-enabled-count">{{ totalColumnsSelected }} out of {{ totalColumns }} columns enabled.</div>

    <ng-container *ngIf="columnsGroups">
        <div
            class="column-group pt-2"
            *ngFor="let columnGroup of columnsGroups"
            cdkDropList
            (cdkDropListDropped)="onDrop($event, columnGroup)"
        >
            <div class="column-group-name">{{ columnGroup.columnGroupName }}</div>

            <div
                class="column-item"
                *ngFor="let column of columnGroup.columns"
                cdkDrag
                cdkDragLockAxis="y"
                [cdkDragDisabled]="column.columnName === vendorOrgNameColumn"
            >
                <mat-checkbox
                    [(ngModel)]="column.visible"
                    [disabled]="column.columnName === vendorOrgNameColumn"
                    color="primary"
                    >{{ column.columnName }}</mat-checkbox
                >
                <span class="fa fa-grip-lines column-item-sortable"></span>
            </div>
        </div>
    </ng-container>

    <div class="edit-columns-modal-footer">
        <button type="button" mat-button visoButton="link" class="float-start" (click)="closeModal()">Cancel</button>
        <button mat-button visoButton="primary" class="text-white" type="button" (click)="applyColumnsChanges()">
            Apply
        </button>
    </div>
</section>
