import { Route } from '@angular/router';

import { NotificationsComponent } from './notifications/notifications.component';

export const notificationsRoute: Route = {
    path: 'notifications',
    component: NotificationsComponent,
    data: {
        pageTitle: 'Notifications',
    },
};
