<div class="row">
    <div class="col pe-0">
        <mat-card>
            <mat-card-content>
                <div class="row">
                    <div class="col">
                        <div class="row">
                            <div class="col d-flex justify-content-between align-items-center text-nowrap">
                                <strong>
                                    {{ artifactTypeName }}
                                </strong>
                                <button
                                    mat-flat-button
                                    class="upload-replacement-pill text-nowrap"
                                    (click)="fileInput.click()"
                                >
                                    <mat-icon class="upload-icon" fontSet="material-symbols-outlined">upload</mat-icon>
                                    Upload replacement
                                </button>
                                <input
                                    #fileInput
                                    data-pw="fileInput"
                                    type="file"
                                    ng2FileSelect
                                    class="d-none"
                                    [uploader]="uploader"
                                    (onFileSelected)="emitUploadReplacement($event[0])"
                                />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col d-flex flex-column text-muted">
                                <span> {{ fileName }} </span>
                                <span> Uploaded on {{ uploadedDate | date: 'MM/dd/yyyy' }} </span>
                                <span> Deemed invalid on {{ invalidDate | date: 'MM/dd/yyyy' }} </span>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>
