import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ComponentRef,
    Directive,
    Input,
    OnDestroy,
    OnInit,
    TemplateRef,
    ViewContainerRef,
} from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { LicenseType } from '../../../entities/viso-user';
import { getUserAccount } from '../../../routes/session/redux/session.selectors';
import { AppState } from '../../redux/state';
import { TALK_TO_SALES_URL } from '@shared/constants/url.constants';
import { AvailbleBSPositions } from 'ngx-bootstrap/positioning/models';

type ElementThatCanBeDisabled = HTMLButtonElement | HTMLAnchorElement;

enum NodeNames {
    Button = 'BUTTON',
    Anchor = 'A',
}

const nodeNames = Object.values(NodeNames).map((x) => x.toLowerCase());

@Component({
    selector: 'disable-on-trial-wrapper',
    template: `
        <span
            #pop="bs-popover"
            [popover]="popoverDisabled ? null : popover"
            [containerClass]="containerClass"
            container="body"
            [outsideClick]="true"
            [placement]="popoverPlacement"
            (mouseenter)="pop.show()"
        >
            <ng-container *ngTemplateOutlet="elementTemplate"></ng-container>
        </span>
        <ng-template #popover>
            <div class="popover-detail" (mouseleave)="pop.hide()">
                <ng-container *ngTemplateOutlet="popoverBodyTemplate"></ng-container>
                <div class="footer text-bold">
                    <p class="m-1">Unlock premium features today.</p>
                    <a [externalLink]="TALK_TO_SALES_URL"> Click here to connect with our team to learn more. </a>
                </div>
            </div>
        </ng-template>
    `,
})
export class TrialDisableWrapperContainerComponent {
    @Input()
    elementTemplate: TemplateRef<any>;

    @Input()
    popoverDisabled: boolean = true;

    @Input()
    popoverBodyTemplate: TemplateRef<any>;

    @Input()
    popoverPlacement: AvailbleBSPositions;

    @Input()
    popoverCustomClass: string;

    get containerClass(): string {
        return `disable-on-trial-popover ${this.popoverCustomClass}`;
    }

    constructor(public viewContainerRef: ViewContainerRef) {}

    TALK_TO_SALES_URL = TALK_TO_SALES_URL;
}

@Directive({
    selector: '[disableOnTrial]',
})
export class DisableOnTrialDirective implements OnInit, OnDestroy, AfterViewInit {
    @Input()
    disableOnTrial: boolean | '' = true;

    @Input()
    disableOnTrialHidePopover: boolean = false;

    @Input()
    disableOnTrialDisplayLock: boolean = true;

    @Input()
    disableOnTrialLockIconColor: 'primary' | 'muted' = 'primary';

    @Input()
    disableOnTrialPopoverTemplate: TemplateRef<any>;

    @Input()
    disableOnTrialPopoverPlacement: AvailbleBSPositions = 'auto';

    @Input()
    disableOnTrialPopoverCustomClass: string = '';

    private _wrapperContainerRef: ComponentRef<TrialDisableWrapperContainerComponent>;
    private _unsub$ = new Subject<void>();

    constructor(
        private _cdr: ChangeDetectorRef,
        private _templateRef: TemplateRef<any>,
        private _viewContainerRef: ViewContainerRef,
        private _store$: Store<AppState>,
    ) {}

    get popoverDisabled(): boolean {
        return this.disableOnTrialHidePopover;
    }

    get displayLock(): boolean {
        return this.disableOnTrialDisplayLock;
    }

    get popoverTemplate(): TemplateRef<any> {
        return this.disableOnTrialPopoverTemplate;
    }

    get popoverPlacement(): AvailbleBSPositions {
        return this.disableOnTrialPopoverPlacement;
    }

    get popoverCustomClass(): string {
        return this.disableOnTrialPopoverCustomClass;
    }

    get lockIconColor(): 'primary' | 'muted' {
        return this.disableOnTrialLockIconColor;
    }

    ngOnInit(): void {
        if (this.disableOnTrial) {
            this._wrapperContainerRef = this._viewContainerRef.createComponent(TrialDisableWrapperContainerComponent);
            this._wrapperContainerRef.instance.elementTemplate = this._templateRef;
            this._wrapperContainerRef.changeDetectorRef.detectChanges();
        } else {
            this._viewContainerRef.createEmbeddedView(this._templateRef);
        }
    }

    ngAfterViewInit(): void {
        if (this.disableOnTrial) {
            this._store$
                .pipe(
                    select(getUserAccount),
                    map((account) => account?.clientLicense?.licenseType),
                    filter((licenseType) => !!licenseType && licenseType === LicenseType.TRIAL),
                    takeUntil(this._unsub$),
                )
                .subscribe(() => this.updateElement());
        }
    }

    ngOnDestroy(): void {
        this._unsub$.next();
    }

    private updateElement(): void {
        let element: ElementThatCanBeDisabled | HTMLElement = (
            this._wrapperContainerRef.location.nativeElement as HTMLElement
        ).querySelector(nodeNames.join(',')) as ElementThatCanBeDisabled;
        if (!element) {
            // use first direct child instead if no Anchor or button
            element = (this._wrapperContainerRef.location.nativeElement as HTMLElement).children[0]
                .children[0] as HTMLElement;
        }
        this.disableElement(element);
        if (!this.popoverDisabled) {
            this.setPopover();
        }
        if (this.displayLock) {
            this.setLockIconToElement(element);
        }
    }

    private disableElement(element: ElementThatCanBeDisabled | HTMLElement): void {
        switch (element.nodeName) {
            case NodeNames.Anchor:
                const anchor = element as HTMLAnchorElement;
                anchor.href = 'javascript:void(0)';
                break;
            case NodeNames.Button:
                const button = element as HTMLButtonElement;
                button.disabled = true;
                break;
            default:
                const section = element as HTMLElement;
                section.className += ' locked-feature-disabled-section';
        }
        element.className += ' disabled';
    }

    private setPopover(): void {
        this._wrapperContainerRef.instance.popoverBodyTemplate = this.popoverTemplate;
        this._wrapperContainerRef.instance.popoverPlacement = this.popoverPlacement;
        this._wrapperContainerRef.instance.popoverCustomClass = this.popoverCustomClass;
        this._wrapperContainerRef.instance.popoverDisabled = false;
        this._cdr.detectChanges();
    }

    private setLockIconToElement(element: ElementThatCanBeDisabled | HTMLElement): void {
        if (element.querySelector('.trial-disable-icon')) {
            return;
        }
        let colorClass;
        switch (this.lockIconColor) {
            case 'primary':
                colorClass = 'text-primary';
                break;
            case 'muted':
                colorClass = 'text-muted';
                break;
            default:
                colorClass = 'text-primary';
        }
        element.insertAdjacentHTML('beforeend', `<em class="fa fa-lock trial-disable-icon ms-2 ${colorClass}"></em>`);
    }
}
