import { Route } from '@angular/router';
import { RiskModelComponent } from './risk-model.component';

export const riskModelRoute: Route = {
    path: 'risk-model',
    component: RiskModelComponent,
    data: {
        pageTitle: 'Risk Model',
    },
};
