<div *dynamicContent="breadcrumbsContainerToken">
    <ol class="breadcrumb breadcrumb px-0 pb-0">
        <li class="breadcrumb-item"><a [routerLink]="['/']">Home</a></li>
        <li class="breadcrumb-item active">Risk model settings</li>
    </ol>
</div>

<div class="content-heading">
    <div>Risk model</div>
</div>
<div class="row">
    <div class="col">
        <app-risk-tolerance
            [clientRiskTolerance]="clientRiskTolerance$ | async"
            [riskToleranceThresholds]="riskToleranceThresholds$ | async"
            [relationshipRiskDistribution]="relationshipRiskDistribution$ | async"
            [riskLevelDisplayNames]="riskLevelDisplayNames$ | async"
            (customizeRiskTolerance)="openCustomizeRiskToleranceDialog()"
        ></app-risk-tolerance>
    </div>
</div>
