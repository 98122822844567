import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Tag, TagColorEnum } from '../../../entities/tag';
import { AppState } from '../../../shared/redux/state';
import { specialCharactersValidator } from '../../../shared/validators/special-characters-validator';
import { noWhitespaceValidator } from '../../../shared/validators/whitespace-validator';
import {
    clearSelectedTag,
    createTagRequest,
    createTagRequestSuccess,
    TagActions,
    updateTagRequest,
    updateTagRequestSuccess,
} from '../redux/tag.actions';
@Component({
    selector: 'app-tag-form',
    templateUrl: './tag-form.component.html',
    styleUrls: ['./tag-form.component.scss'],
})
export class TagFormComponent implements OnInit, OnDestroy {
    @Input()
    selectedTag: Tag;

    tagForm: UntypedFormGroup;
    TagActionsRef = TagActions;
    private _unsub$ = new Subject<void>();

    constructor(
        private _fb: UntypedFormBuilder,
        private _activeModal: NgbActiveModal,
        private _store$: Store<AppState>,
        private _actions$: Actions,
    ) {}

    ngOnInit(): void {
        this.tagForm = this._fb.group({
            name: [
                this.selectedTag?.name || '',
                [Validators.required, specialCharactersValidator(), Validators.maxLength(30), noWhitespaceValidator],
            ],
            color: [this.selectedTag?.color || TagColorEnum.COLOR_01, [Validators.required]],
        });
        this._actions$
            .pipe(ofType(createTagRequestSuccess, updateTagRequestSuccess), takeUntil(this._unsub$))
            .subscribe((_) => this._activeModal.dismiss());
    }

    ngOnDestroy(): void {
        this._unsub$.next();
        this._store$.dispatch(clearSelectedTag());
    }

    save(): void {
        const { name, color } = this.tagForm.value;
        if (!this.selectedTag) {
            this._store$.dispatch(createTagRequest({ tagPayload: { name: name.trim(), color } }));
        } else {
            this._store$.dispatch(
                updateTagRequest({ tagPayload: { name: name.trim(), color, id: this.selectedTag.id } }),
            );
        }
    }

    clear(): void {
        this._activeModal.dismiss('cancel');
    }
}
