import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    QueryList,
    ViewChildren,
} from '@angular/core';
import { DetectionItem, SelectableDetectionItem } from '../../models/detection';
import { BehaviorSubject, Observable, Subject, combineLatest, filter, map, takeUntil, withLatestFrom } from 'rxjs';

@Component({
    selector: 'detections-list',
    templateUrl: './detections-list.component.html',
    styleUrls: ['./detections-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DetectionsListComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input({ required: true })
    set detections(value: DetectionItem[]) {
        this._detections$.next(value);
    }

    @Input()
    set selectedDetection(value: DetectionItem) {
        this._selectedDetection$.next(value);
    }

    @Output()
    selectDetection = new EventEmitter<DetectionItem>();

    detections$: Observable<SelectableDetectionItem[]>;

    @ViewChildren('detectionListItem', { read: ElementRef<HTMLElement> })
    private _detectionListItems: QueryList<ElementRef<HTMLElement>>;
    private _detections$ = new BehaviorSubject<DetectionItem[]>([]);
    private _selectedDetection$ = new BehaviorSubject<DetectionItem>(null);
    private _unsub$ = new Subject<void>();

    ngOnInit(): void {
        this.detections$ = combineLatest([this._detections$, this._selectedDetection$]).pipe(
            map(([detections, selectedDetection]) =>
                detections.map<SelectableDetectionItem>((detection) => ({
                    ...detection,
                    selected: detection.id === selectedDetection?.id,
                })),
            ),
        );
    }

    ngAfterViewInit(): void {
        this._selectedDetection$
            .pipe(
                filter((detection) => !!detection),
                withLatestFrom(this._detections$),
                map(([selectedDetection, detections]) =>
                    detections.findIndex((detection) => detection.id === selectedDetection.id),
                ),
                map((selectedDetectionIndex) => this._detectionListItems.get(selectedDetectionIndex)),
                takeUntil(this._unsub$),
            )
            .subscribe((elementToScrollInto) =>
                elementToScrollInto.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'center' }),
            );
    }

    detectionIdTrackBy(index: number, detection: SelectableDetectionItem): number {
        return detection.id;
    }

    ngOnDestroy(): void {
        this._unsub$.next();
    }
}
