import { Route } from '@angular/router';
import { AssessmentRecommendationComponent } from './assessment-recommendation.component';

export const assessmentRecommendationRoute: Route = {
    path: 'assessment-recommendation',
    component: AssessmentRecommendationComponent,
    data: {
        pageTitle: 'Assessment Recommendation',
    },
};
