import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Artifact, ArtifactValidationStatus, ArtifactValidationStatusLabels } from '../../../../entities/artifact';

@Component({
    selector: 'app-artifact-list-export',
    templateUrl: './artifact-list-export.component.html',
    styleUrls: ['./artifact-list-export.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArtifactListExportComponent {
    @Input()
    artifacts: Artifact[];

    ArtifactValidationStatus = ArtifactValidationStatus;
    ArtifactValidationStatusLabels = ArtifactValidationStatusLabels;

    isArtifactStatusNotRequired(artifact: Artifact): boolean {
        if (!artifact.validation) {
            return false;
        }
        return artifact.validation?.status === ArtifactValidationStatus.NOT_REQUIRED;
    }

    isArtifactExpiringSoonOrExpired(artifact: Artifact): boolean {
        if (!artifact.validation || this.isArtifactStatusNotRequired(artifact)) {
            return false;
        }
        return artifact.validation.expiringSoon || artifact.validation.expired;
    }
}
