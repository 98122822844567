<div class="content-heading">
    <div>
        JWTs Management
        <small>Manage JWT for Users</small>
    </div>
</div>
<div class="row">
    <div class="col-md-8">
        <div class="card card-default">
            <div class="card-header">
                <p class="card-title">Active JWT List</p>
            </div>
            <div class="card-body">
                <input
                    type="text"
                    class="form-control mb-3 mw-50"
                    [formControl]="jwtListFilter"
                    placeholder="Filter by user here"
                />
                <ngx-datatable
                    class="bootstrap"
                    [columnMode]="'force'"
                    [rowHeight]="35"
                    [headerHeight]="30"
                    [rows]="jwtsFiltered$ | async"
                    [scrollbarV]="true"
                    [count]="(jwtsFiltered$ | async).length"
                    [sorts]="[{ prop: 'User', dir: 'desc' }]"
                >
                    <ngx-datatable-column name="User Name" prop="fullName"></ngx-datatable-column>
                    <ngx-datatable-column name="User Email" prop="email"></ngx-datatable-column>
                    <ngx-datatable-column name="Id Token" prop="jwtId"></ngx-datatable-column>
                    <ngx-datatable-column name="Expiration Date" prop="expirationDate">
                        <ng-template let-value="value" ngx-datatable-cell-template>
                            {{ value | date }}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Creation Date" prop="createdDate">
                        <ng-template let-value="value" ngx-datatable-cell-template>
                            {{ value | date }}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column
                        [width]="100"
                        cellClass="text-center p-0"
                        [resizable]="false"
                        [sortable]="false"
                        [canAutoResize]="false"
                        [draggable]="false"
                    >
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            <div class="additional-actions">
                                <button mat-button visoButton="link" color="accent" (click)="revokeJwt(row)">
                                    Revoke
                                </button>
                            </div>
                        </ng-template>
                    </ngx-datatable-column>
                </ngx-datatable>
            </div>
        </div>
    </div>
    <div class="col-md-4">
        <div class="card card-default">
            <div class="card-header">
                <p class="card-title">Create new JWT</p>
            </div>
            <div class="card-body">
                <form [formGroup]="addJwtFormGroup" (submit)="addJwt()">
                    <div class="form-group required">
                        <label>Org</label>
                        <ngx-select
                            [allowClear]="true"
                            [items]="orgs$ | async"
                            formControlName="org"
                            placeholder="Select an Org"
                            optionTextField="name"
                            optionValueField="ref"
                        ></ngx-select>
                    </div>
                    <div class="form-group required">
                        <label>User</label>
                        <ngx-select
                            [allowClear]="true"
                            [items]="users$ | async"
                            formControlName="user"
                            placeholder="Select a User"
                            optionTextField="name"
                            optionValueField="ref"
                        ></ngx-select>
                    </div>
                    <div class="form-group required">
                        <label>Expiration Period</label>
                        <ngx-select
                            [allowClear]="true"
                            [items]="jwtExpirationPeriods$ | async"
                            formControlName="expirationPeriod"
                            placeholder="Select an Expiration Period"
                            optionTextField="name"
                            optionValueField="enumValue"
                        ></ngx-select>
                    </div>
                    <div *ngIf="showCustomDateField$ | async" class="form-group required">
                        <label for="customDate">Custom Date</label>
                        <div class="date-form-group">
                            <input
                                id="customDate"
                                matInput
                                [matDatepicker]="customDatePicker"
                                class="form-control"
                                placeholder="yyyy-mm-dd"
                                formControlName="customDate"
                                [min]="today"
                            />
                            <mat-datepicker-toggle [for]="customDatePicker"></mat-datepicker-toggle>
                            <mat-datepicker #customDatePicker></mat-datepicker>
                        </div>
                    </div>
                    <div class="form-group text-end">
                        <button type="button" mat-button visoButton="secondary" class="me-2" (click)="clearForm()">
                            Cancel
                        </button>
                        <button type="submit" mat-button visoButton="primary" [disabled]="addJwtFormGroup.invalid">
                            Submit
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<ng-template #accessTokenModal let-modal>
    <div class="modal-header">
        <div class="modal-title">New Jwt was created</div>
        <button
            type="button"
            class="btn-close"
            data-dismiss="modal"
            aria-hidden="true"
            (click)="modal.close()"
        ></button>
    </div>
    <div class="modal-body modal-text">
        <div class="input-group mb-3">
            <input type="text" class="form-control" [value]="latestAccessToken" readonly />
            <div class="input-group-append">
                <button
                    mat-stroked-button
                    class="no-left-radius"
                    type="button"
                    title="copy to clipboard"
                    (click)="copyAccessTokenToClipboard()"
                >
                    <em class="fas fa-clipboard"></em>
                </button>
            </div>
        </div>
        <strong
            >Note: Please copy the access token to a safe location as this is the only time you’ll have access to
            it.</strong
        >
    </div>
</ng-template>
