import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { FeatureFlagService } from '../services/featureflag.service';
import { Observable, of } from 'rxjs';
import { filter, switchMap, take } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class FeatureFlagGuard {
    constructor(
        private _router: Router,
        private _featureFlagService: FeatureFlagService,
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        const featureFlag = route.data?.featureFlag;
        return this.checkFeatureFlag(featureFlag);
    }

    checkFeatureFlag(featureFlag: string): Observable<boolean> {
        return this._featureFlagService.flagsLoaded.pipe(
            filter(({ loaded }) => loaded),
            take(1),
            switchMap((flags) => {
                if (!!featureFlag && flags[featureFlag]) {
                    return of(true);
                }
                this._router.navigate(['/']);
                return of(false);
            }),
        );
    }
}
