<div class="d-inline-block mb-n5 mt-3">
    <a class="ms-2 float-start me-auto"
        ><strong>Scroll to zoom in and out • Hold Shift and drag in an area to zoom in</strong></a
    >
    <div class="row float-end">
        <div class="col">
            <button mat-button visoButton="secondary" class="ms-auto" (click)="resetZoom()">Reset Zoom</button>
            <button mat-button visoButton="primary" class="ms-2" (click)="toggleTimelineView.emit()">List View</button>
        </div>
    </div>
</div>
<div class="d-block">
    <canvas baseChart height="80" type="line" [options]="chartOptions" [datasets]="riskAssessmentsDatasets"> </canvas>
</div>
