import { RTPArtifactClassified, RTPEvent } from '@entities/rtp';
import { createAction, props } from '@ngrx/store';
import { PublicAssessmentRTPFileArtifact } from '../../models';

export enum AssessmentCollectionCommonRTPActions {
    StartRTP = '[Assessment Collection Common RTP] Start RTP',
    StopRTP = '[Assessment Collection Common RTP] Stop RTP',
    OnRTPEvent = '[Assessment Collection Common RTP] On RTP Event',
    OnRTPArtifactClassifiedEvent = '[Assessment Collection Common RTP] On RTP Artifact Classified Event',
    OnRTPArtifactPublishDateSetEvent = '[Assessment Collection Common RTP] On RTP Artifact Publish Date Set Event',
    OnRtpFileArtifactCreation = '[Assessment Collection Common RTP] On Rtp File Artifact Creation',
}

export const startRTP = createAction(AssessmentCollectionCommonRTPActions.StartRTP);

export const stopRTP = createAction(AssessmentCollectionCommonRTPActions.StopRTP);

export const onRTPEvent = createAction(
    AssessmentCollectionCommonRTPActions.OnRTPEvent,
    props<{ rtpEvent: RTPEvent }>(),
);

export const onRTPArtifactClassifiedEvent = createAction(
    AssessmentCollectionCommonRTPActions.OnRTPArtifactClassifiedEvent,
    props<{ rtpEvent: RTPArtifactClassified }>(),
);

export const onRTPArtifactPublishDateSetEvent = createAction(
    AssessmentCollectionCommonRTPActions.OnRTPArtifactPublishDateSetEvent,
);

export const onRtpFileArtifactCreation = createAction(
    AssessmentCollectionCommonRTPActions.OnRtpFileArtifactCreation,
    props<{ rtpFileArtifacts: PublicAssessmentRTPFileArtifact[] }>(),
);
