import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { BadgeModule } from '../badge';
import { ReplacePipeModule } from '@shared/pipes/replace-pipe/replace-pipe.module';
import { QuillWrapperModule } from '../components/quill-wrapper/quill-wrapper.module';
import { SafeHtmlPipeModule } from '@shared/pipes/safe-html-pipe/safe-html-pipe.module';
import { RiskUtilsModule } from '../risk-utils/risk-utils.module';
import { VisoButtonModule } from '../viso-button/viso-button.module';
import { CallbackPipeModule } from '../pipes/callback-pipe';
import {
    AssessmentStatusTimelineComponent,
    AssessmentEmailHistoryComponent,
    EmailDetailsContent,
    AssessmentSummaryComponent,
    AssessmentRiskDiffComponent,
    AssessmentOverviewComponent,
    RecommendationsListComponent,
    AssessmentSummaryConfigFormComponent,
} from './components';
import { DataSensitivityLevelPipeModule } from '@shared/pipes/data-sensitivity-level-pipe/data-sensitivity-level-pipe.module';
import { MatListModule } from '@angular/material/list';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { EnumToArrayPipeModule } from '@shared/pipes/enum-to-array-pipe/enum-to-array-pipe.module';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
    declarations: [
        AssessmentStatusTimelineComponent,
        AssessmentEmailHistoryComponent,
        AssessmentSummaryComponent,
        AssessmentRiskDiffComponent,
        AssessmentOverviewComponent,
        EmailDetailsContent,
        RecommendationsListComponent,
        AssessmentSummaryConfigFormComponent,
    ],
    imports: [
        CommonModule,
        RouterModule,
        MatIconModule,
        MatCardModule,
        BadgeModule,
        ReplacePipeModule,
        SafeHtmlPipeModule,
        QuillWrapperModule,
        RiskUtilsModule,
        VisoButtonModule,
        CallbackPipeModule,
        DataSensitivityLevelPipeModule,
        MatInputModule,
        MatListModule,
        MatSlideToggleModule,
        EnumToArrayPipeModule,
        ReactiveFormsModule,
        MatTooltipModule,
    ],
    exports: [
        AssessmentStatusTimelineComponent,
        AssessmentEmailHistoryComponent,
        AssessmentSummaryComponent,
        AssessmentRiskDiffComponent,
        AssessmentOverviewComponent,
        EmailDetailsContent,
        RecommendationsListComponent,
        AssessmentSummaryConfigFormComponent,
    ],
})
export class AssessmentComponentsModule {}
