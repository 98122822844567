<div class="container-top-half pb-0 pe-0">
    <div class="row">
        <div class="col-4">
            @if (!forFollowup) {
                <div class="row">
                    <div class="col">
                        <div class="assessment-collection-step-title">
                            We have {{ answers?.length }} {{ answers?.length > 1 ? 'questions' : 'question' }}
                            about your company's practices.
                        </div>
                    </div>
                </div>
                <div class="row mb-4">
                    <div class="col">
                        Don't want to answer a lengthy questionnaire? <br />
                        <a class="text-secondary" href="javascript:void(0)" (click)="wentToCollectArtifacts.emit()"
                            >Upload artifacts</a
                        >
                        instead.
                    </div>
                </div>
            } @else {
                <div class="row">
                    <div class="col">
                        <div class="assessment-collection-step-title">
                            We still have a few questions about your company's practices.
                        </div>
                    </div>
                </div>
            }
        </div>
        <div class="col-auto pb-4">
            <mat-divider class="h-100" [vertical]="true"></mat-divider>
        </div>
        <div class="col">
            <div class="fixed-height pe-4 pb-4">
                @if (!(questionnaire$ | async)) {
                    <div class="d-flex align-items-center justify-content-center">
                        <div class="sk-spinner sk-spinner-pulse"></div>
                    </div>
                } @else {
                    @for (answerFormGroup of questionnaireFormArray.controls; track $index) {
                        <form class="pt-2" [ngClass]="{ 'question-margin': !$first }" [formGroup]="answerFormGroup">
                            <div class="question-heading ps-4 mb-3" [attr.question-number]="$index + 1">
                                <p class="mb-2">{{ answers[$index].question }}</p>
                                <small>
                                    Control domain:
                                    {{ ControlDomainTypeLabels[answers[$index].controlDomainType] }} -
                                    {{ answers[$index].controlDomainName }}
                                </small>
                            </div>

                            <div class="d-flex align-items-center mb-3">
                                <mat-form-field class="w-100 dense-3" [floatLabel]="'auto'">
                                    <mat-label>Select response</mat-label>
                                    <mat-select formControlName="answerType">
                                        <mat-option
                                            *ngFor="
                                                let answerType of QuestionnaireAnswerType
                                                    | enumToArray: QuestionnaireAnswerTypeTabLabels
                                            "
                                            [value]="answerType.value"
                                        >
                                            {{ answerType.label }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <div
                                    class="mx-3 text-secondary guidance-text"
                                    [appPopover]="guidancePopover"
                                    popoverPosition="left"
                                    popoverTheme="dark"
                                >
                                    Guidance
                                </div>
                                <ng-template #guidancePopover>
                                    <div class="guidance-popover p-3">
                                        <div>{{ answers[$index].controlDomainDescription }}</div>
                                    </div>
                                </ng-template>
                            </div>

                            <mat-form-field class="w-100 dense-3 mb-3" [floatLabel]="'auto'">
                                <mat-label>Add description</mat-label>
                                <textarea matInput formControlName="answer"></textarea>
                            </mat-form-field>
                        </form>
                    }
                }
            </div>
        </div>
    </div>
</div>
<div class="container-bottom-half">
    <div class="d-flex justify-content-between">
        <button type="button" mat-stroked-button color="secondary" (click)="wentBack.emit()">Go back</button>
        <button type="button" mat-flat-button color="secondary" (click)="attemptToContinue()" data-pw="continueButton">
            Continue
        </button>
    </div>
</div>
