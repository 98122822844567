import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { NgxSelectModule } from 'ngx-select-ex';
import { FileUploadModule } from '../file-upload';
import { AuthDirectivesModule } from '../auth-directives';
import { UploadAttachmentsModalComponent } from './components';
import { DocumentFileModule } from '@shared/document-file/document-file.module';

@NgModule({
    declarations: [UploadAttachmentsModalComponent],
    imports: [
        CommonModule,
        FileUploadModule,
        TabsModule,
        ReactiveFormsModule,
        NgxSelectModule,
        AuthDirectivesModule,
        DocumentFileModule,
    ],
    exports: [UploadAttachmentsModalComponent],
})
export class UploadAttachmentsModule {}
