import { animate, style, transition, trigger } from '@angular/animations';

export const fadeAnimation = trigger('fadeAnimation', [
    transition(':enter', [style({ opacity: 0 }), animate('300ms', style({ opacity: 1 }))]),
]);

export const fadeAnimation2x = trigger('fadeAnimation', [
    transition(':enter', [style({ opacity: 0 }), animate('150ms', style({ opacity: 1 }))]),
]);

export const listItemAnimation = [
    trigger('items', [
        transition(':leave', [
            style({ transform: 'scale(1)', opacity: 1, height: '*' }),
            animate('200ms ease-in', style({ transform: 'scale(0)', opacity: 0, height: '0px', margin: '0px' })),
        ]),
    ]),
];
