import { createAction, props } from '@ngrx/store';
import { ControlDomain } from '../../../entities/control-domain';

export enum ControlActions {
    GetControlsByOrgIdRequest = '[Controls] Get Controls by Org Id Request',
    GetControlsByOrgIdRequestSuccess = '[Controls] Get Controls by Org Id Request Success',
    GetControlsByOrgIdRequestFailed = '[Controls] Get Controls by Org Id Request Failed',
    GetControlsRequest = '[Controls] Get Controls Request',
    GetControlsRequestSuccess = '[Controls] Get Controls Request Success',
    GetControlsRequestFailed = '[Controls] Get Controls Request Failed',
    GetCurrentAccountControlsRequest = '[Controls] Get Current Account Controls Request',
}

export const getControlsByOrgIdRequest = createAction(
    ControlActions.GetControlsByOrgIdRequest,
    props<{ orgId?: number; isCurrentOrg?: boolean }>(),
);

export const getControlsByOrgIdRequestSuccess = createAction(
    ControlActions.GetControlsByOrgIdRequestSuccess,
    props<{ orgId?: number; controls: ControlDomain[]; isCurrentOrg?: boolean }>(),
);

export const getControlsByOrgIdRequestFailed = createAction(ControlActions.GetControlsByOrgIdRequestFailed);

export const getControlsRequest = createAction(ControlActions.GetControlsRequest);

export const getControlsRequestSuccess = createAction(
    ControlActions.GetControlsRequestSuccess,
    props<{ controls: ControlDomain[] }>(),
);

export const getControlsRequestFailed = createAction(ControlActions.GetControlsRequestFailed);

export const getCurrentAccountControlsRequest = createAction(ControlActions.GetCurrentAccountControlsRequest);
