import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AssuranceLevels } from '../../../../entities/relationship/models/security-control-domain';

@Component({
    selector: 'app-assurance-level-meter',
    templateUrl: './assurance-level-meter.component.html',
    styleUrls: ['./assurance-level-meter.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssuranceLevelMeterComponent {
    @Input()
    assuranceLevel: AssuranceLevels;

    @Input()
    hideTooltip: boolean = false;

    @Input()
    hideLabel: boolean = false;

    @Input()
    direction: 'horizontal' | 'vertical' = 'horizontal';

    get assuranceLevelClass(): string {
        return this.assuranceLevel?.valueOf().toLocaleLowerCase() || 'no-assurance';
    }
}
