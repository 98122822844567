<div ng2FileDrop class="dropzone" [uploader]="uploader" [ngClass]="{ 'on-drag': (onDrag$ | async) }">
    <div class="on-drag-area border border-primary text-primary rounded justify-content-center align-items-center">
        <p>Drop files here.</p>
    </div>
    <div class="modal-header">
        <h4 class="modal-title">{{ titleText }}</h4>
        <button type="button" class="btn-close" data-dismiss="modal" aria-hidden="true" (click)="close()"></button>
    </div>
    <div class="modal-body">
        <div class="container-fluid">
            <div class="row mb-2">
                <div class="col">
                    <p>Add files you want to associate to a relationship that do not need to be assessed.</p>
                </div>
            </div>
            <div class="row mb-2">
                <div class="col">
                    <button class="btn btn-block p-3 drag-drop-message-container" (click)="fileInput.click()">
                        <span class="text-dark">
                            Drag and Drop or <span class="text-bold text-secondary">Browse</span>
                        </span>
                    </button>
                    <input #fileInput type="file" ng2FileSelect multiple class="d-none" [uploader]="uploader" />
                </div>
            </div>
            <div class="row">
                <div class="col-12" *ngFor="let item of uploader.queue">
                    <app-document-file
                        class="mb-2"
                        [documentFileName]="item?.file?.name"
                        [disabled]="uploadingFiles$ | async"
                        (onFileRemoved)="removeItem(item)"
                    ></app-document-file>
                </div>
                <div class="mt-3" *ngIf="uploader.queue.length === 0">No attachments uploaded yet.</div>
            </div>
        </div>
    </div>
    <div class="modal-footer text-end">
        <button class="btn btn-primary" [disabled]="uploadDisabled$ | async" (click)="upload()">Upload</button>
    </div>
</div>
