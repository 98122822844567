<div mat-dialog-title>
    Domain Verification Configuration
    <button mat-icon-button mat-dialog-close>
        <mat-icon>close</mat-icon>
    </button>
</div>
<mat-dialog-content>
    <div class="form-group">
        <label>DKIM Pending Host</label>
        <div class="field-value">{{ verification.dkimPendingHost || 'DKIM Verified' }}</div>
        <button
            *ngIf="!!verification.dkimPendingHost"
            class="mt-2"
            mat-button
            visoButton="secondary"
            title="copy to clipboard"
            (click)="copyDkimPendingHostToClipboard()"
        >
            <em class="far fa-clone fa-flip-vertical"></em>
            Copy to clipboard
        </button>
    </div>

    <div class="form-group">
        <label>DKIM Pending Text Value</label>
        <div class="field-value">{{ verification.dkimPendingTextValue || 'DKIM Verified' }}</div>

        <button
            *ngIf="!!verification.dkimPendingTextValue"
            class="mt-2"
            mat-button
            visoButton="secondary"
            title="copy to clipboard"
            (click)="copyDkimPendingTextValueToClipboard()"
        >
            <em class="far fa-clone fa-flip-vertical"></em>
            Copy to clipboard
        </button>
    </div>

    <div class="form-group">
        <label>Return Path Domain</label>
        <div class="field-value">{{ verification.returnPathDomain }}</div>
        <button
            class="mt-2"
            mat-button
            title="copy to clipboard"
            visoButton="secondary"
            (click)="copyReturnPathDomainToClipboard()"
        >
            <em class="far fa-clone fa-flip-vertical"></em>
            Copy to clipboard
        </button>
    </div>

    <div class="form-group">
        <label>Return Path CNAME Value</label>
        <div class="field-value">{{ verification.returnPathCnameValue }}</div>
        <button
            class="mt-2"
            mat-button
            visoButton="secondary"
            title="copy to clipboard"
            (click)="copyReturnCnamePathValueToClipboard()"
        >
            <em class="far fa-clone fa-flip-vertical"></em>
            Copy to clipboard
        </button>
    </div>
</mat-dialog-content>
