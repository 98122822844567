import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { Announcement } from '../../../entities/announcement/announcement.model';

export enum AnnouncementActions {
    GetMostRecentAnnouncementRequest = '[Announcements] Get Most Recent Announcement Request',
    GetMostRecentAnnouncementRequestSuccess = '[Announcements] Get Most Recent Announcement Request Success',
    GetMostRecentAnnouncementRequestFailed = '[Announcements] Get Most Recent Announcement Request Failed',
}

export const getMostRecentAnnouncementRequest = createAction(AnnouncementActions.GetMostRecentAnnouncementRequest);

export const getMostRecentAnnouncementRequestSuccess = createAction(
    AnnouncementActions.GetMostRecentAnnouncementRequestSuccess,
    props<{ announcement: Announcement }>(),
);

export const getMostRecentAnnouncementRequestFailed = createAction(
    AnnouncementActions.GetMostRecentAnnouncementRequestFailed,
    props<{ error: HttpErrorResponse }>(),
);
