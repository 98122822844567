import { HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { SupplementalQuestionnaireResponse } from '../../../../entities/artifact';
import { UploadArtifactsRequest } from '../../../../shared/upload-artifacts';
import { UpdateSupplementalQuestionnaireConfigRequest } from '../model/supplemental-questionnaire-models';

export enum SupplementalQuestionnaireManagementActions {
    GetSupplementalQuestionnairesRequest = '[Supplemental Questionnaire Management] Get Supplemental Questionnaires Request',
    GetSupplementalQuestionnairesRequestSuccess = '[Supplemental Questionnaire Management] Get Supplemental Questionnaires Request Success',
    GetSupplementalQuestionnairesRequestFailed = '[Supplemental Questionnaire Management] Get Supplemental Questionnaires Request Failed',

    UploadSupplementalQuestionnairesRequest = '[Supplemental Questionnaire Management] Upload Supplemental Questionnaires Request',
    UploadSupplementalQuestionnairesRequestSuccess = '[Supplemental Questionnaire Management] Upload Supplemental Questionnaires Request Success',
    UploadSupplementalQuestionnairesRequestFailed = '[Supplemental Questionnaire Management] Upload Supplemental Questionnaires Request Failed',

    UpdateSupplementalQuestionnaireConfigRequest = '[Supplemental Questionnaire Management] Update Supplemental Questionnaire Config Request',
    UpdateSupplementalQuestionnaireConfigRequestSuccess = '[Supplemental Questionnaire Management] Update Supplemental Questionnaire Config Request Success',
    UpdateSupplementalQuestionnaireConfigRequestFailed = '[Supplemental Questionnaire Management] Update Supplemental Questionnaire Config Request Failed',

    DeleteSupplementalQuestionnaireRequest = '[Supplemental Questionnaire Management] Delete Supplemental Questionnaire Request',
    DeleteSupplementalQuestionnaireRequestSuccess = '[Supplemental Questionnaire Management] Delete Supplemental Questionnaire Request Success',
    DeleteSupplementalQuestionnaireRequestFailed = '[Supplemental Questionnaire Management] Delete Supplemental Questionnaire Request Failed',

    DownloadOriginalSupplementalQuestionnaireRequest = '[Supplemental Questionnaire Management] Download Original Supplemental Questionnaire Request',
    DownloadOriginalSupplementalQuestionnaireRequestSuccess = '[Supplemental Questionnaire Management] Download Original Supplemental Questionnaire Request Success',
    DownloadOriginalSupplementalQuestionnaireRequestFailed = '[Supplemental Questionnaire Management] Download Original Supplemental Questionnaire Request Failed',
}

export const getSupplementalQuestionnairesRequest = createAction(
    SupplementalQuestionnaireManagementActions.GetSupplementalQuestionnairesRequest,
    props<{ pageable?: any }>(),
);

export const getSupplementalQuestionnairesRequestSuccess = createAction(
    SupplementalQuestionnaireManagementActions.GetSupplementalQuestionnairesRequestSuccess,
    props<{ questionnaires: SupplementalQuestionnaireResponse[]; headers: HttpHeaders }>(),
);

export const getSupplementalQuestionnairesRequestFailed = createAction(
    SupplementalQuestionnaireManagementActions.GetSupplementalQuestionnairesRequestFailed,
    props<{ error: HttpErrorResponse }>(),
);

export const uploadSupplementalQuestionnairesRequest = createAction(
    SupplementalQuestionnaireManagementActions.UploadSupplementalQuestionnairesRequest,
    props<UploadArtifactsRequest>(),
);

export const uploadSupplementalQuestionnairesRequestSuccess = createAction(
    SupplementalQuestionnaireManagementActions.UploadSupplementalQuestionnairesRequestSuccess,
);

export const uploadSupplementalQuestionnairesRequestFailed = createAction(
    SupplementalQuestionnaireManagementActions.UploadSupplementalQuestionnairesRequestFailed,
    props<{ error: HttpErrorResponse }>(),
);

export const deleteSupplementalQuestionnaireRequest = createAction(
    SupplementalQuestionnaireManagementActions.DeleteSupplementalQuestionnaireRequest,
    props<{ questionnaireId: number }>(),
);

export const deleteSupplementalQuestionnaireRequestSuccess = createAction(
    SupplementalQuestionnaireManagementActions.DeleteSupplementalQuestionnaireRequestSuccess,
);

export const deleteSupplementalQuestionnaireRequestFailed = createAction(
    SupplementalQuestionnaireManagementActions.DeleteSupplementalQuestionnaireRequestFailed,
    props<{ error: HttpErrorResponse }>(),
);

export const downloadOriginalSupplementalQuestionnaireRequest = createAction(
    SupplementalQuestionnaireManagementActions.DownloadOriginalSupplementalQuestionnaireRequest,
    props<{ questionnaireId: number }>(),
);

export const downloadOriginalSupplementalQuestionnaireRequestSuccess = createAction(
    SupplementalQuestionnaireManagementActions.DownloadOriginalSupplementalQuestionnaireRequestSuccess,
    props<{ fileResponseBlob: HttpResponse<Blob> }>(),
);

export const downloadOriginalSupplementalQuestionnaireRequestFailed = createAction(
    SupplementalQuestionnaireManagementActions.DownloadOriginalSupplementalQuestionnaireRequestFailed,
    props<{ error: HttpErrorResponse }>(),
);

export const updateSupplementalQuestionnaireConfigRequest = createAction(
    SupplementalQuestionnaireManagementActions.UpdateSupplementalQuestionnaireConfigRequest,
    props<{ updateRequest: UpdateSupplementalQuestionnaireConfigRequest }>(),
);

export const updateSupplementalQuestionnaireConfigRequestSuccess = createAction(
    SupplementalQuestionnaireManagementActions.UpdateSupplementalQuestionnaireConfigRequestSuccess,
);

export const updateSupplementalQuestionnaireConfigRequestFailed = createAction(
    SupplementalQuestionnaireManagementActions.UpdateSupplementalQuestionnaireConfigRequestFailed,
    props<{ error: HttpErrorResponse }>(),
);
