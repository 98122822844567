import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DynamicContainerDirective } from './dynamic-container.directive';
import { DynamicContentDirective } from './dynamic-content.directive';

@NgModule({
    declarations: [DynamicContainerDirective, DynamicContentDirective],
    imports: [CommonModule],
    exports: [DynamicContainerDirective, DynamicContentDirective],
})
export class DynamicContentModule {}
