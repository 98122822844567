import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { AppState } from '../../../trust-profile/trust-profile-common/redux/trust-profile.state';
import { DomainVerificationsState, domainVerificationStateKey } from './domain-verifications.state';
import { DomainVerification } from '@entities/domain-verification';

export const getYourOrganizationState: MemoizedSelector<AppState, DomainVerificationsState> =
    createFeatureSelector<DomainVerificationsState>(domainVerificationStateKey);

export const getPreferredDomainVerification: MemoizedSelector<AppState, DomainVerification | null> = createSelector(
    getYourOrganizationState,
    (state) => state.preferredDomainVerification,
);

export const getDomainVerifications: MemoizedSelector<AppState, Array<DomainVerification>> = createSelector(
    getYourOrganizationState,
    (state) => state.domainVerifications,
);

export const getHasValidDomainVerification: MemoizedSelector<AppState, boolean> = createSelector(
    getYourOrganizationState,
    (state) => state.hasValidDomainVerification,
);
