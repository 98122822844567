import { CoupaIntegration, CoupaIntegrationFull, CoupaSupplierSyncResponse } from '../../entities/coupa';
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BYPASS_SNACKBAR_ON_ERROR } from '../../blocks/interceptor/errorhandler.interceptor';
import { IntegrationType } from './integrations.model';

@Injectable()
export class IntegrationsService {
    private basePath = 'api/integrations';
    private coupaPath = `${this.basePath}/coupa/config`;
    private suppliersPath = `${this.basePath}/coupa/suppliers/sync`;

    constructor(private _http: HttpClient) {}

    getCoupaIntegration(): Observable<HttpResponse<CoupaIntegration>> {
        return this._http.get<CoupaIntegration>(this.coupaPath, {
            observe: 'response',
            context: new HttpContext().set(BYPASS_SNACKBAR_ON_ERROR, { statuses: [404] }),
        });
    }

    putCoupaIntegration(config: CoupaIntegrationFull): Observable<HttpResponse<CoupaIntegration>> {
        return this._http.put<CoupaIntegration>(this.coupaPath, config, { observe: 'response' });
    }

    deleteCoupaIntegration(): Observable<HttpResponse<any>> {
        return this._http.delete<any>(this.coupaPath, {
            observe: 'response',
        });
    }

    syncCoupaIntegration(): Observable<HttpResponse<CoupaSupplierSyncResponse>> {
        return this._http.post<CoupaSupplierSyncResponse>(this.suppliersPath, null, {
            observe: 'response',
        });
    }

    getConnectedIntegrations(): Observable<HttpResponse<IntegrationType[]>> {
        return this._http.get<IntegrationType[]>(this.basePath, {
            observe: 'response',
        });
    }

    getInterestedIntegrations(): Observable<HttpResponse<IntegrationType[]>> {
        return this._http.get<IntegrationType[]>(`${this.basePath}/interested`, {
            observe: 'response',
        });
    }

    createInterestedIntegration(integration: IntegrationType): Observable<HttpResponse<void>> {
        return this._http.post<void>(`${this.basePath}/interested/${integration}`, null, {
            observe: 'response',
        });
    }
}
