import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Observable, map, skip, startWith } from 'rxjs';
import { FileArtifact } from '../../../../entities/artifact/artifact.model';
import { AssuranceLevels } from '../../../../entities/relationship/models/security-control-domain';
import { saveFileFromResBlob } from '../../../../shared/file-download/redux/actions';
import { DetectionItem } from '../../models/detection';

@Component({
    selector: 'pdf-artifact-with-detections-viewer-modal',
    templateUrl: './pdf-artifact-with-detections-viewer-modal.component.html',
    styleUrls: ['./pdf-artifact-with-detections-viewer-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PdfArtifactWithDetectionsViewerModalComponent implements OnInit {
    @Input({ required: true })
    artifact: FileArtifact;

    @Input({ required: true })
    artifactAuditType: string;

    @Input({ required: true })
    controlsValidatedCount: number;

    @Input({ required: true })
    detections: DetectionItem[];

    @Input()
    initialSelectedDetectionId?: number;

    @Input()
    password: string;

    @Input({ required: true })
    pdfResponseBlob: HttpResponse<Blob>;

    @Input()
    viewerPage?: number;

    selectedDetection$ = new BehaviorSubject<DetectionItem>(null);
    detectedLanguage$: Observable<string>;
    page$: Observable<number>;

    constructor(
        private _activeModal: NgbActiveModal,
        private _store$: Store,
    ) {}

    get artifactName(): string {
        return this.artifact.fileName;
    }

    get artifactBlob(): Blob {
        return this.pdfResponseBlob.body;
    }

    get artifactUploadedDate(): Date {
        return this.artifact.uploadedDate;
    }

    get artifactAssuranceLevel(): AssuranceLevels {
        return this.artifact.validation?.auditReportAssuranceLevel;
    }

    ngOnInit(): void {
        if (!!this.initialSelectedDetectionId) {
            this.selectedDetection$.next(
                this.detections.find((detection) => detection.id === this.initialSelectedDetectionId),
            );
        }

        this.detectedLanguage$ = this.selectedDetection$.pipe(map((detection) => detection?.detectedLanguage));

        this.page$ = this.selectedDetection$.pipe(
            skip(!!this.viewerPage ? 1 : 0),
            map((detection) => detection?.parsedFirstPage),
            startWith(this.viewerPage),
        );
    }

    selectDetection(detection: DetectionItem) {
        this.selectedDetection$.next(detection);
    }

    close(): void {
        this._activeModal.close();
    }

    downloadArtifact(): void {
        this._store$.dispatch(saveFileFromResBlob({ fileResponseBlob: this.pdfResponseBlob }));
    }
}
