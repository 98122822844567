import { createAction, props } from '@ngrx/store';
import { OpenArtifactFileParams } from '../../../entities/artifact/';
export enum PdfArtifactViewerActions {
    OpenPDFArtifactView = '[PDF Artifact Viewer] OpenPDFArtifactView',
}

export const openPDFArtifactView = createAction(
    PdfArtifactViewerActions.OpenPDFArtifactView,
    props<Omit<OpenArtifactFileParams, 'artifactName'>>(),
);
