<div mat-dialog-title>
    Let's start customizing your emails
    <button mat-icon-button mat-dialog-close>
        <mat-icon>close</mat-icon>
    </button>
</div>
<mat-dialog-content class="customize-email-dialog">
    Please input your preferred name &amp; mailbox for the domain.

    <small class="email-address-preview-label">Email address preview</small>

    <form [formGroup]="formGroup">
        <div class="email-address-preview">
            Emails will appear from:
            <strong
                >"{{ displayInput.value || 'Sender Name' }}" &lt;{{ mailboxInput.value || 'mailbox' }}&#64;{{
                    domainInput.value || preference?.domain || 'domain.com'
                }}&gt;</strong
            >
        </div>
        <mat-form-field class="w-100">
            <mat-label>Domain</mat-label>
            <mat-select #domainInput formControlName="domain" placeholder="Select a verified domain">
                <mat-option
                    class="select-item"
                    *ngFor="let verification of verifications"
                    [value]="verification.domain"
                >
                    {{ verification.domain }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field class="w-100 sender">
            <mat-label>Sender name</mat-label>
            <input
                #displayInput
                type="text"
                matInput
                class="form-control mt-2"
                formControlName="emailDisplay"
                placeholder="Name"
            />
        </mat-form-field>

        <mat-form-field class="w-100">
            <mat-label>Mailbox</mat-label>
            <input
                #mailboxInput
                type="text"
                matInput
                class="form-control mt-2"
                formControlName="emailLocalpart"
                placeholder="Mailbox"
            />
        </mat-form-field>
    </form>
</mat-dialog-content>
<mat-dialog-actions class="justify-content-end">
    <button type="button" mat-button visoButton="primary" [disabled]="formGroup.invalid" (click)="save()">
        Save configuration
    </button>
</mat-dialog-actions>
