import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'trimFileExtension',
})
export class TrimFileExtensionPipe implements PipeTransform {
    transform(value: string) {
        return value.replace(/\.[^/.]+$/g, '').trim();
    }
}
