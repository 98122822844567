import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';

import { SettingsService } from '../../core/settings/settings.service';
import { ThemesService } from '../../core/themes/themes.service';
import { select, Store } from '@ngrx/store';
import { AppState } from '@shared/redux/state';
import { logoutRequest } from '../../routes/session/redux/session.actions';
import { getUserAccount } from '../../routes/session/redux/session.selectors';
import { takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { VisoUser, VisoUserRole } from '@entities/viso-user';
import {
    closeNotificationsDropdown,
    openNotificationsDropdown,
    startSpotlightTourForCurrentPage,
} from '../redux/layout.actions';
import { relationshipsStateKey } from '../../routes/relationships/redux/relationships-state';
import { isPhoneViewport } from '@shared/redux/selectors';
import { BREADCRUMB_CONTAINER_TOKEN } from '@shared/dynamic-content/dynamic-content-injector';
import { currentPageSupportsSpotlightTour } from '../redux/layout.selectors';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
    @Output()
    helpCenterOpened = new EventEmitter<void>();

    @Output()
    helpCenterClosed = new EventEmitter<void>();

    currentPageSupportsSpotlightTour$: Observable<boolean>;

    breadcrumbsContainerToken = BREADCRUMB_CONTAINER_TOKEN;
    isPhoneResolution$: Observable<boolean>;
    isNavSearchVisible: boolean;
    isOrgDomainOverridden: boolean;
    account: VisoUser;
    window = window;

    private _unsub: Subject<void> = new Subject<void>();

    get isNotAdminRole(): boolean {
        return this.account && !this.account.authorities?.includes(VisoUserRole.Admin);
    }

    constructor(
        public settings: SettingsService,
        public themeService: ThemesService,
        private _store$: Store<AppState>,
    ) {}

    ngOnInit() {
        this.isNavSearchVisible = false;
        this._store$.pipe(select(getUserAccount), takeUntil(this._unsub)).subscribe((account) => {
            this.account = account;
            if (this.account && !this.isUserDomainInStoredDomainsForOrg()) {
                this.themeService.setTheme('AUDITOR');
                this.isOrgDomainOverridden = true;
            }
        });
        this.isPhoneResolution$ = this._store$.pipe(select(isPhoneViewport));
        this.currentPageSupportsSpotlightTour$ = this._store$.select(currentPageSupportsSpotlightTour);
    }

    logout() {
        this._store$.dispatch(logoutRequest());
        this.removeStateStorage();
        this.account = undefined;
    }

    toggleCollapsedSidebar() {
        this.settings.layout.isCollapsed = !this.settings.layout.isCollapsed;
        setTimeout(() => {
            window.dispatchEvent(new Event('resize'));
        }, 250);
    }

    isCollapsedText() {
        return this.settings.layout.isCollapsedText;
    }

    notificationsDropdownChange(isOpen: boolean) {
        if (!isOpen) {
            this._store$.dispatch(closeNotificationsDropdown());
        } else {
            this._store$.dispatch(openNotificationsDropdown());
        }
    }

    activateSpotlightTour(): void {
        this.helpCenterClosed.emit();
        this._store$.dispatch(startSpotlightTourForCurrentPage());
    }

    ngOnDestroy(): void {
        this._unsub.next();
    }

    private isUserDomainInStoredDomainsForOrg(): boolean {
        const userEmailDomain = this.account.email.split('@')[1];
        return !!this.account.orgDomains.find((domain) => userEmailDomain === domain);
    }

    private removeStateStorage() {
        localStorage.removeItem(`storage__${relationshipsStateKey}`);
    }
}
