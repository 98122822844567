import { Component, OnInit } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { Org, OrgService } from '../../entities/org';
import { RiskAssessmentService } from '../../entities/risk-assessment';
import { RiskAssessmentRefresh } from './risk-assessment-refresh.model';
import { UntypedFormGroup } from '@angular/forms';
import { SnackbarService } from '../../shared/components/snackbar/snackbar.service';
import { finalize } from 'rxjs/operators';

@Component({
    selector: 'app-risk-model',
    templateUrl: './risk-model.component.html',
    styleUrls: [],
})
export class RiskModelComponent implements OnInit {
    orgs: Org[];
    isSaving: boolean;
    riskAssessmentRefresh: RiskAssessmentRefresh = new RiskAssessmentRefresh();
    riskAssessmentRefreshForm: UntypedFormGroup;

    constructor(
        private _riskAssessmentService: RiskAssessmentService,
        private _orgService: OrgService,
        private _snackbarService: SnackbarService,
    ) {}

    refreshRiskModel() {
        this.isSaving = true;
        if (this.riskAssessmentRefresh?.orgId) {
            const orgName = this.orgs.find((o) => o.id === this.riskAssessmentRefresh.orgId).name;
            this._riskAssessmentService
                .refreshModel(this.riskAssessmentRefresh)
                .pipe(finalize(() => (this.isSaving = false)))
                .subscribe((x) => {
                    this._snackbarService.success(`Risk model refreshed for: ${orgName}`);
                });
        } else {
            this._riskAssessmentService
                .refreshModel(this.riskAssessmentRefresh)
                .pipe(finalize(() => (this.isSaving = false)))
                .subscribe((x) => {
                    this._snackbarService.success(`Risk model refreshed for all orgs.`);
                });
        }
    }

    ngOnInit(): void {
        this._orgService.clients().subscribe((res: HttpResponse<Org[]>) => {
            this.orgs = res.body;
        });
    }

    trackOrgById(index: number, item: Org) {
        return item.id;
    }
}
