import { Component } from '@angular/core';
import { SnackbarService } from '../../shared/components/snackbar/snackbar.service';
import { OrgService } from '../../entities/org';

@Component({
    selector: 'app-org-import',
    templateUrl: './org-import.component.html',
    styleUrls: ['./org-import.component.scss'],
})
export class OrgImportComponent {
    importStarted: boolean;

    constructor(
        private _orgService: OrgService,
        private _snackbarService: SnackbarService,
    ) {}

    startImportProcess() {
        this.importStarted = true;
        this._orgService.startImportProcess().subscribe(() => {
            this._snackbarService.info('Org import process started. Watch your notifications!');
        });
    }

    startOrgMetadataFetchingProcess() {
        this._orgService.startOrgMetadataFetchingProcess().subscribe(() => {
            this._snackbarService.info('Watch your notifications!');
        });
    }
}
