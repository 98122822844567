import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { RelationshipAdditionalFilters } from '../models/relationship-additional-filters.model';
import { RelationshipColumnGroup } from '../models/relationship-column.model';
import { AppState, initialState, RelationshipsState, relationshipsStateKey } from './relationships-state';

export const getRelationshipsState: MemoizedSelector<AppState, RelationshipsState> =
    createFeatureSelector<RelationshipsState>(relationshipsStateKey);

export const getRelationshipsColumnGroups: MemoizedSelector<AppState, RelationshipColumnGroup[]> = createSelector(
    getRelationshipsState,
    (state) => state.columnsGroups || initialState.columnsGroups,
);

export const getRelationshipsColumnsInitialState: MemoizedSelector<AppState, RelationshipColumnGroup[]> =
    createSelector(getRelationshipsState, (state) => initialState.columnsGroups);

export const getRelationshipsAdditionalFiltersState: MemoizedSelector<AppState, RelationshipAdditionalFilters> =
    createSelector(getRelationshipsState, (state) => state.additionalFilters);
