import { Route } from '@angular/router';
import { OrgImportComponent } from './org-import.component';

export const orgImportRoute: Route = {
    path: 'org-import',
    component: OrgImportComponent,
    data: {
        pageTitle: 'Organization Import',
    },
};
