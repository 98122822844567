<div class="d-flex">
    <span matSnackBarLabel [innerHtml]="snack.message | safeHtml"></span>
    <span matSnackBarActions>
        <ng-container *ngIf="snack.action && snack.action.text">
            <button mat-button matSnackBarAction (click)="snack.action.onClick()">
                {{ snack.action.text }}
            </button>
        </ng-container>
    </span>
</div>
