import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Org, OrgService } from '@entities/org';
import { WebhookEvents } from './webhooks.constants';
import { Webhook, WebhookEvent, WebhookType } from './webhooks.model';
import { WebhooksService } from './webhooks.service';
import { SnackbarService } from '@shared/components/snackbar/snackbar.service';
import { ConfirmDialogService } from '@shared/components/confirm-dialog/confirm-dialog.service';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { catchError, delay, filter, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { FormUtilsService } from '@shared/utils/form-utils.service';
import { noWhitespaceValidator } from '@shared/validators/whitespace-validator';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
    selector: 'app-webhooks',
    templateUrl: './webhooks.component.html',
    styleUrls: ['./webhooks.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WebhooksComponent implements OnInit, OnDestroy {
    manageWebhookModal: NgbModalRef;
    allEventsSelected: boolean = true;
    currentWebhook: Webhook;
    webhookEvents = WebhookEvents;
    webhookTypes: string[];
    savingWebhook: boolean;
    loadingWebhooks: boolean;
    webhooksForm: UntypedFormGroup;

    clients$: Observable<Org[]>;
    webhooks$ = new BehaviorSubject<Webhook[]>([]);
    webhooksCount$: Observable<number>;

    private _unsub = new Subject<void>();
    private _webhooksRequestTrigger$ = new BehaviorSubject<void>(null);

    constructor(
        private _modalService: NgbModal,
        private _orgService: OrgService,
        private _webhooksService: WebhooksService,
        private _snackbarService: SnackbarService,
        private _confirmDialogService: ConfirmDialogService,
        private _fb: UntypedFormBuilder,
        private _formUtils: FormUtilsService,
    ) {}

    get isWebhookValidForSubmission(): boolean {
        return (
            !!this.webhooksForm.controls.orgId.value &&
            !!this.webhooksForm.controls.destinationUrl.value?.trim() &&
            !!this.webhooksForm.controls.destinationType.value
        );
    }

    ngOnInit(): void {
        this.clients$ = this._orgService.clients().pipe(map((res) => res.body));
        this.webhookTypes = Object.keys(WebhookType);

        this._webhooksRequestTrigger$
            .pipe(
                tap(() => (this.loadingWebhooks = true)),
                switchMap(() => this._webhooksService.getWebhooks().pipe(catchError(() => of([] as Webhook[])))),
                tap(() => (this.loadingWebhooks = false)),
                takeUntil(this._unsub),
            )
            .subscribe((webhooks) => this.webhooks$.next(webhooks));

        this.webhooksCount$ = this.webhooks$.pipe(
            delay(50),
            map((webhooks) => webhooks.length),
        );
    }

    getAllWebhooks(): void {
        this._webhooksRequestTrigger$.next();
    }

    addNewWebhook(manageWebhookModal: any): void {
        this.buildWebhooksForm();
        this.toggleAllEvents({ checked: true });

        this.manageWebhookModal = this._modalService.open(manageWebhookModal, {
            centered: true,
            backdrop: 'static',
        });
    }

    editWebhook(manageWebhookModal: any, webhook: Webhook): void {
        this.buildWebhooksForm(webhook);
        this.setWebhookFromObject(webhook);

        this.manageWebhookModal = this._modalService.open(manageWebhookModal, {
            centered: true,
            backdrop: 'static',
        });
    }

    showDeleteWebhookConfirmation(webhook: Webhook): void {
        this._confirmDialogService
            .confirm({
                title: 'Delete Webhook',
                message: 'Are you sure you want to delete this webhook?',
                confirmLabel: 'Yes, do it!',
            })
            .pipe(
                filter((result) => result),
                map(() => this.deleteWebhook(webhook)),
                take(1),
            )
            .subscribe();
    }

    deleteWebhook(webhook: Webhook): void {
        const orgId = webhook.orgId;
        const webhookId = webhook.id;

        this._webhooksService.deleteWebhook(orgId, webhookId).subscribe({
            next: () => this._snackbarService.success('Webhook deleted successfully.'),
            complete: () => this.getAllWebhooks(),
        });
    }

    toggleAllEvents(event: Partial<MatSlideToggleChange>): void {
        const allSelected = event.checked;
        const eventsArray: UntypedFormArray = this.webhooksForm.controls.events as UntypedFormArray;

        for (const webhookEvent of this.webhookEvents) {
            webhookEvent.events = webhookEvent.events.map((e) => ({
                ...e,
                selected: allSelected,
            }));
        }

        if (allSelected) {
            eventsArray.controls = [];
            for (const webhookEvent of this.webhookEvents) {
                webhookEvent.events.forEach((e) => {
                    eventsArray.controls.push(new UntypedFormControl(e.value));
                });
            }
        } else {
            eventsArray.controls = [];
        }
    }

    selectSingleEvent($event: MatCheckboxChange, event: WebhookEvent): void {
        const isChecked = $event.checked;
        const eventsArray: UntypedFormArray = this.webhooksForm.controls.events as UntypedFormArray;
        const allEventsSelected = this.webhooksForm.controls.allEventsSelected.value;
        event.selected = isChecked;

        if (isChecked) {
            eventsArray.push(new UntypedFormControl(event.value));
        } else {
            eventsArray.controls = eventsArray.controls.filter((c) => c.value !== event.value);
        }

        if (!isChecked && allEventsSelected) {
            this.webhooksForm.controls.allEventsSelected.setValue(false);
        }

        if (isChecked && this.areAllEventsChecked(eventsArray)) {
            this.webhooksForm.controls.allEventsSelected.setValue(true);
        }
    }

    saveWebhook(): void {
        const selectedOrgId = this.webhooksForm.controls.orgId.value;
        this.savingWebhook = true;

        const webhook: Webhook = this.getWebhookObjectFromForm(this.webhooksForm);

        //We don't send id for new webhooks
        if (webhook.id === 0) {
            delete webhook.id;
        }

        this._webhooksService.saveWebhook(selectedOrgId, webhook).subscribe(
            () => {
                this.savingWebhook = false;
                this._snackbarService.success('Webhook saved successfully.');
            },
            () => {
                this.savingWebhook = false;
            },
            () => {
                this.manageWebhookModal.close();
                this.getAllWebhooks();
            },
        );
    }

    ngOnDestroy(): void {
        this._unsub.next();
    }

    private buildWebhooksForm(webhook?: Webhook) {
        this.webhooksForm = this._fb.group({
            id: [webhook?.id || 0],
            orgId: [webhook?.orgId || null, [Validators.required]],
            destinationUrl: [webhook?.webhook || '', [Validators.required, noWhitespaceValidator]],
            destinationType: [webhook?.name || null, [Validators.required]],
            description: [webhook?.description || '', [noWhitespaceValidator]],
            allEventsSelected: [webhook ? this.areAllEventsSelected(webhook) : true],
            events: webhook
                ? new UntypedFormArray(webhook?.domainEventTypes.map((e) => new UntypedFormControl(e)))
                : new UntypedFormArray([]),
        });
    }

    private getWebhookObjectFromForm(webhooksForm: UntypedFormGroup): Webhook {
        const {
            id,
            orgId,
            destinationUrl: webhook,
            destinationType: name,
            description,
        } = this._formUtils.getCleanFormGroupValue(webhooksForm);
        const domainEventTypes = this.getSelectedWebhookEvents();
        return {
            id,
            orgId,
            webhook,
            name,
            description,
            domainEventTypes,
        };
    }

    private getSelectedWebhookEvents(): string[] {
        return this.webhookEvents.reduce((selectedEvents, webhook) => {
            return [
                ...selectedEvents,
                ...webhook.events.filter((event) => event.selected).flatMap((event) => event.value),
            ];
        }, [] as string[]);
    }

    private areAllEventsSelected(webhook: Webhook): boolean {
        return this.webhookEvents.every((w) =>
            w.events.every((e) =>
                e.value.every((domainEventType) => webhook.domainEventTypes.includes(domainEventType)),
            ),
        );
    }

    private areAllEventsChecked(eventsArray: UntypedFormArray): boolean {
        for (const webhookEvent of this.webhookEvents) {
            const allSelected = webhookEvent.events.every((e) => eventsArray.controls.some((c) => c.value === e.value));
            if (!allSelected) return false;
        }
        return true;
    }

    private setWebhookFromObject(webhook: Webhook) {
        for (const webhookEvent of this.webhookEvents) {
            webhookEvent.events = webhookEvent.events.map((e) => ({
                ...e,
                selected: e.value.every((domainEventType) => webhook.domainEventTypes.includes(domainEventType)),
            }));
        }
    }
}
