import { MemoizedSelector, createSelector } from '@ngrx/store';
import { AppState } from '../assessment-collection.state';
import {
    isAssessmentCancelledOrDeleted,
    isAssessmentComplete,
    isAssessmentReviewStarted,
} from '@shared/utils/assessment-utils.service';
import { AssessmentCancelledReason } from '@entities/assessment';
import { getAssessmentCollectionState, getPublicAssessment } from './main.selectors';
import { getAssessmentStatus } from './assessment-data.selectors';

export const getShowSubmitted: MemoizedSelector<AppState, boolean> = createSelector(getAssessmentStatus, (status) =>
    isAssessmentReviewStarted(status),
);

export const getShowCompleted: MemoizedSelector<AppState, boolean> = createSelector(getAssessmentStatus, (status) =>
    isAssessmentComplete(status),
);

export const getShowCancelledOrDeleted: MemoizedSelector<AppState, boolean> = createSelector(
    getAssessmentStatus,
    (status) => isAssessmentCancelledOrDeleted(status),
);

export const getCancelledReason: MemoizedSelector<AppState, AssessmentCancelledReason> = createSelector(
    getPublicAssessment,
    (publicAssessment) => publicAssessment?.cancelledReason,
);

export const getJustForwarded: MemoizedSelector<AppState, boolean> = createSelector(
    getAssessmentCollectionState,
    (state) => state?.justForwarded,
);

export const getJustCancelled: MemoizedSelector<AppState, boolean> = createSelector(
    getAssessmentCollectionState,
    (state) => state?.justCancelled,
);

export const getRemediationJustDeclined: MemoizedSelector<AppState, boolean> = createSelector(
    getAssessmentCollectionState,
    (state) => state?.remediationJustDeclined,
);
