import { HttpResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';

export enum FileDownloadActions {
    SaveFileFromResBlob = '[File Download] Save File From Res Blob',
    DownloadWithAnchorElement = '[File Download] Download With Anchor Element',
    OpenFileFromResBlob = '[File Download] Open File From Res Blob',
}

export const saveFileFromResBlob = createAction(
    FileDownloadActions.SaveFileFromResBlob,
    props<{ fileResponseBlob: HttpResponse<Blob> }>(),
);

export const downloadWithAnchorElement = createAction(
    FileDownloadActions.DownloadWithAnchorElement,
    props<{ url: string }>(),
);

export const openFileFromResBlob = createAction(
    FileDownloadActions.OpenFileFromResBlob,
    props<{ fileResponseBlob: HttpResponse<Blob> }>(),
);
