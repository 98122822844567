import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, of } from 'rxjs';
import { catchError, delay, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { ConfirmDialogService } from '../../../../shared/components/confirm-dialog/confirm-dialog.service';
import { RelationshipService } from '../../../../entities/relationship';
import {
    markRequestAsArchived,
    markRequestAsArchivedRequest,
    markRequestAsArchivedRequestFailed,
    markRequestAsArchivedRequestSuccess,
    markRequestAsNotOnboarded,
    markRequestAsNotOnboardedRequest,
    markRequestAsNotOnboardedRequestFailed,
    markRequestAsNotOnboardedRequestSuccess,
    markRequestsAsArchivedRequest,
    markRequestsAsArchivedRequestSuccess,
} from '../actions/mark.actions';
import { SnackbarService } from '../../../../shared/components/snackbar/snackbar.service';

@Injectable()
export class MarkEffects {
    markRequestsAsArchivedRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(markRequestsAsArchivedRequest),
            switchMap(({ requestIds }) =>
                this._relationshipService.markAsArchived(requestIds).pipe(
                    map(
                        (response) =>
                            markRequestsAsArchivedRequestSuccess({
                                allSuccessful: response.allSuccessful,
                            }),
                        catchError(() => EMPTY),
                    ),
                ),
            ),
        ),
    );

    markRequestAsArchived$ = createEffect(() =>
        this._actions$.pipe(
            ofType(markRequestAsArchived),
            switchMap(({ requestId }) => {
                return this._confirmService
                    .confirm({
                        title: 'Archive Relationship',
                        message: `Are you sure you want to archive this relationship? Any assessments in progress will be cancelled.`,
                        confirmLabel: 'Yes, archive',
                    })
                    .pipe(mergeMap((result) => (result ? of(markRequestAsArchivedRequest({ requestId })) : EMPTY)));
            }),
        ),
    );

    markRequestAsArchivedRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(markRequestAsArchivedRequest),
            switchMap(({ requestId }) =>
                this._relationshipService.markAsArchived([requestId]).pipe(
                    map(() => markRequestAsArchivedRequestSuccess()),
                    catchError(() => of(markRequestAsArchivedRequestFailed())),
                ),
            ),
        ),
    );

    markRequestAsArchivedRequestSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(markRequestAsArchivedRequest),
                delay(1000),
                tap(() => {
                    this._snackbarService.success('Relationship Archived');
                    this._router.navigate(['/']);
                }),
            ),
        { dispatch: false },
    );

    markRequestAsNotOnboarded$ = createEffect(() =>
        this._actions$.pipe(
            ofType(markRequestAsNotOnboarded),
            switchMap(({ requestId }) => {
                return this._confirmService
                    .confirm({
                        title: 'Offboard Relationship',
                        message: `Are you sure you want to offboard this relationship? Any assessments in progress will be cancelled and Lifecycle Management will be disabled until this relationship is onboarded.`,
                        confirmLabel: 'Yes, offboard',
                    })
                    .pipe(mergeMap((result) => (result ? of(markRequestAsNotOnboardedRequest({ requestId })) : EMPTY)));
            }),
        ),
    );

    markRequestAsNotOnboardedRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(markRequestAsNotOnboardedRequest),
            switchMap(({ requestId }) =>
                this._relationshipService.markAsNotOnboarded([requestId]).pipe(
                    map(() => markRequestAsNotOnboardedRequestSuccess()),
                    catchError(() => of(markRequestAsNotOnboardedRequestFailed())),
                ),
            ),
        ),
    );

    constructor(
        private _router: Router,
        private _actions$: Actions,
        private _confirmService: ConfirmDialogService,
        private _relationshipService: RelationshipService,
        private _snackbarService: SnackbarService,
    ) {}
}
