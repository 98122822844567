import { ApolloError } from '@apollo/client';
import { createAction, props } from '@ngrx/store';
import { Notification } from '@entities/notification';
import { LayoutType } from '@shared/model/layout-type';
import { SpotlightTourType } from '@shared/spotlight-tour/steps';

export enum LayoutActions {
    RefreshNotifications = '[Layout] Refresh Notifications Request',
    NotificationsQuerySuccess = '[Layout] Notifications Query Success',
    CloseNotificationsDropdown = '[Layout] Close Notifications Dropdown',
    OpenNotificationsDropdown = '[Layout] Open Notifications Dropdown',
    MarkAllNotificationsAsRead = '[Layout] Mark All Notifications as Read',
    MarkNotificationAsDismissed = '[Layout] Mark Notification as Dismissed',
    MarkAllNotificationsAsDismissed = '[Layout] Mark All Notifications as Dismissed',
    NotificationsStatusUpdatedSuccess = '[Layout] Notifications Status Updated Success',
    SetLayout = '[Layout] Set Layout',
    GetNewNotificationsSubscription = '[Layout] Get New Notifications Subscription',
    GetNewNotificationsSubscriptionSuccess = '[Layout] Get New Notifications Subscription Success',
    GetNewNotificationsSubscriptionFailed = '[Layout] Get New Notifications Subscription Failed',
    StartNotificationPollingFallback = '[Layout] Backup Notification Polling Started',
    SetAssessmentHeaderColor = '[Layout] Set Assessment Header Color',
    SetAssessmentLogoVisible = '[Layout] Set Assessment Logo Visible',
    StartSpotlightTour = '[Layout] Start Spotlight Tour',
    StartSpotlightTourForCurrentPage = '[Layout] Start Spotlight Tour For Current Page',
    SetPageSpotlightTourType = '[Layout] Start Page Spotlight Tour Type',
}

export const startNotificationPollingFallback = createAction(LayoutActions.StartNotificationPollingFallback);

/**
 * Layout Refresh Notifications Polling Redux Action
 * Gets dispatched when the user makes a change in the notifications list
 */
export const refreshNotifications = createAction(LayoutActions.RefreshNotifications);

/**
 * Layout Notifications Query Success Redux Action
 * Gets dispatched when the notifications are successfully polled
 */
export const notificationsQuerySuccess = createAction(
    LayoutActions.NotificationsQuerySuccess,
    props<{ results: Notification[]; totalCount: number }>(),
);

/**
 * Layout Change Notifications Dropdown State Redux Action
 * Gets dispatched when the notifications dropdown open state changes
 */
export const closeNotificationsDropdown = createAction(LayoutActions.CloseNotificationsDropdown);

export const openNotificationsDropdown = createAction(LayoutActions.OpenNotificationsDropdown);

/**
 * Mark All Notifications as Read Redux Action
 * Gets dispatched when notifications dropdown closes
 */
export const markAllNotificationsAsRead = createAction(
    LayoutActions.MarkAllNotificationsAsRead,
    props<{ notificationIds: number[] }>(),
);

/**
 * Mark Notification as dismissed Redux Action
 */
export const markNotificationAsDismissed = createAction(
    LayoutActions.MarkNotificationAsDismissed,
    props<{ notifications: Notification[] }>(),
);

/**
 * Mark All Notifications as dismissed Redux Action
 */
export const markAllNotificationsAsDismissed = createAction(LayoutActions.MarkAllNotificationsAsDismissed);

/**
 * Notifications Status Updated Success Redux Action
 * Gets dispatched when notification status update is successful
 */
export const notificationsStatusUpdatedSuccess = createAction(LayoutActions.NotificationsStatusUpdatedSuccess);

export const setLayout = createAction(LayoutActions.SetLayout, props<{ layout: LayoutType }>());

export const getNewNotificationsSubscription = createAction(LayoutActions.GetNewNotificationsSubscription);
export const getNewNotificationsSubscriptionSuccess = createAction(
    LayoutActions.GetNewNotificationsSubscriptionSuccess,
    props<{ newNotification: Notification }>(),
);
export const getNewNotificationsSubscriptionFailed = createAction(
    LayoutActions.GetNewNotificationsSubscriptionFailed,
    props<{ error: ApolloError }>(),
);

export const setAssessmentHeaderColor = createAction(
    LayoutActions.SetAssessmentHeaderColor,
    props<{ color: string }>(),
);

export const setAssessmentLogoVisible = createAction(
    LayoutActions.SetAssessmentLogoVisible,
    props<{ visible: boolean }>(),
);

export const setPageSpotlightTourType = createAction(
    LayoutActions.SetPageSpotlightTourType,
    props<{ tourType: SpotlightTourType }>(),
);

export const startSpotlightTour = createAction(
    LayoutActions.StartSpotlightTour,
    props<{ tourType: SpotlightTourType }>(),
);

export const startSpotlightTourForCurrentPage = createAction(LayoutActions.StartSpotlightTourForCurrentPage);
