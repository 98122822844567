import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, combineLatest, merge, of, Subject } from 'rxjs';
import { filter, map, takeUntil, tap } from 'rxjs/operators';
import { noWhitespaceValidator } from '../../shared/validators/whitespace-validator';
import { RefSelectOption } from '../../shared/model/select-options';
import { BusinessUnit } from 'src/main/webapp/app/entities/business-unit';
import { VisoUser } from 'src/main/webapp/app/entities/viso-user';

@Component({
    selector: 'app-assign-business-owner',
    templateUrl: './assign-business-owner.component.html',
    styleUrls: ['./assign-business-owner.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssignBusinessOwnerComponent implements OnInit, OnDestroy {
    @Input()
    businessOwnerFormGroup: UntypedFormGroup;

    @Input()
    currentUser: VisoUser;

    @Input()
    contacts: RefSelectOption<VisoUser>[];

    @Input()
    containerType: 'assignBusinessOwnermodal' | 'createRelationshipModal' = 'assignBusinessOwnermodal';

    @Input()
    set businessUnits(values: RefSelectOption<BusinessUnit>[]) {
        this._businessUnits$.next(values);
    }
    get businessUnits() {
        return this._businessUnits$.value;
    }

    @Input()
    orgDomains: string[] = [];

    @Output()
    contactCreationOptionChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    selectContactFormControl = new UntypedFormControl(true);
    selectBusinessUnitFormControl = new UntypedFormControl(true);

    private _unsub$ = new Subject<void>();
    private _businessUnits$ = new BehaviorSubject<RefSelectOption<BusinessUnit>[]>([]);

    get showContactList(): boolean {
        return this.selectContactFormControl.value;
    }

    get showBusinessUnitList(): boolean {
        return this.selectBusinessUnitFormControl.value;
    }

    get showContactAlreadyExistsError(): boolean {
        const emailFormControl = this.businessOwnerFormGroup.controls.email;
        return emailFormControl.hasError('emailAlreadyExists') && this.isEmailDirtyOrTouched;
    }

    get showInvalidEmailDomainError(): boolean {
        const emailFormControl = this.businessOwnerFormGroup.controls.email;
        return emailFormControl.hasError('invalidEmailDomain') && this.isEmailDirtyOrTouched;
    }

    get showInvalidEmailSyntaxError(): boolean {
        const emailFormControl = this.businessOwnerFormGroup.controls.email;
        return emailFormControl.hasError('email') && this.isEmailDirtyOrTouched;
    }

    private get isEmailDirtyOrTouched(): boolean {
        const emailFormControl = this.businessOwnerFormGroup.controls.email;
        return emailFormControl.dirty || emailFormControl.touched;
    }

    get showFirstNameLengthError(): boolean {
        return !!this.businessOwnerFormGroup.controls.firstName.errors?.maxlength;
    }

    get showLastNameLengthError(): boolean {
        return !!this.businessOwnerFormGroup.controls.lastName.errors?.maxlength;
    }

    get showEmailLengthError(): boolean {
        const emailFormControl = this.businessOwnerFormGroup.controls.email;
        return emailFormControl.hasError('maxlength');
    }

    ngOnInit(): void {
        const controls = this.businessOwnerFormGroup.controls;

        this.selectContactFormControl.valueChanges
            .pipe(
                tap((selectContact: boolean) => {
                    if (selectContact) {
                        controls.firstName.disable();
                        controls.lastName.disable();
                        controls.email.disable();
                        controls.contact.enable();
                    } else {
                        controls.firstName.enable();
                        controls.lastName.enable();
                        controls.email.enable();
                        controls.contact.disable();
                    }
                    controls.firstName.setValue('');
                    controls.lastName.setValue('');
                    controls.email.setValue('');
                    controls.contact.setValue(null);
                    controls.firstName.updateValueAndValidity({ emitEvent: false });
                    controls.lastName.updateValueAndValidity({ emitEvent: false });
                    controls.email.updateValueAndValidity({ emitEvent: false });

                    this.contactCreationOptionChange.emit(selectContact);
                }),
                takeUntil(this._unsub$),
            )
            .subscribe();

        combineLatest([
            this._businessUnits$.pipe(filter((bu) => !!bu.length)),
            merge(of(controls.contact.value), controls.contact.valueChanges),
        ])
            .pipe(
                map(([, contact]) => contact),
                tap((contact: VisoUser) => {
                    const { firstName, lastName, email, businessUnitId, orgId, managedByIdp } = contact || {};
                    controls.firstName.setValue(firstName);
                    controls.lastName.setValue(lastName);
                    controls.email.setValue(email);
                    const businessUnit = this.businessUnits.find((bu) => bu.ref.id === businessUnitId)?.ref;
                    controls.businessUnit.setValue(businessUnit);
                    controls.businessUnitName.setValue(businessUnit?.name);
                    controls.orgId.setValue(orgId);
                    controls.managedByIdp.setValue(managedByIdp);
                }),
                takeUntil(this._unsub$),
            )
            .subscribe();

        controls.businessUnit.valueChanges
            .pipe(
                tap((bu: BusinessUnit) => {
                    controls.businessUnitName.setValue(bu?.name);
                }),
                takeUntil(this._unsub$),
            )
            .subscribe();

        this.selectBusinessUnitFormControl.valueChanges
            .pipe(
                tap((selectBusinessUnit: boolean) => {
                    if (selectBusinessUnit) {
                        controls.businessUnit.setValidators(Validators.required);
                        controls.businessUnitName.clearValidators();
                    } else {
                        controls.businessUnit.clearValidators();
                        controls.businessUnitName.setValidators(
                            Validators.compose([Validators.required, noWhitespaceValidator]),
                        );
                    }
                    controls.businessUnit.setValue('');
                    controls.businessUnitName.setValue('');
                    controls.businessUnit.updateValueAndValidity({ emitEvent: false });
                    controls.businessUnitName.updateValueAndValidity({ emitEvent: false });
                }),
                takeUntil(this._unsub$),
            )
            .subscribe();
    }

    ngOnDestroy(): void {
        this._unsub$.next();
    }
}
