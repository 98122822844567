import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { RecertificationType } from '@entities/relationship';
import { EventNotification, EventNotificationType, NotificationStatus } from '@entities/notification';

@Component({
    selector: 'app-event-notification',
    templateUrl: './event-notification.component.html',
    styleUrls: ['./event-notification.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventNotificationComponent implements OnChanges {
    @Input()
    eventNotification: EventNotification;

    @Output()
    markAsDismissed = new EventEmitter<MouseEvent>();

    get isNew(): boolean {
        return this.eventNotification.status === NotificationStatus.NEW;
    }

    get eventLinkQueryParams(): any {
        switch (this.eventNotification.eventNotificationType) {
            case EventNotificationType.ASSESSMENT_ARTIFACT_UPDATE_COMPLETED:
            case EventNotificationType.ASSESSMENT_COMPLETE:
            case EventNotificationType.ASSESSMENT_CANCELLED_AUTOMATICALLY:
            case EventNotificationType.UPCOMING_RECERTIFICATION:
            case EventNotificationType.AUTOMATIC_RECERTIFICATION:
            case EventNotificationType.RECERTIFICATION_OVERDUE:
            case EventNotificationType.ASSESSMENT_CANCELLED:
                return { assessmentTabSelected: 'true' };
            default:
                return undefined;
        }
    }

    get eventLink(): string {
        switch (this.eventNotification.eventNotificationType) {
            case EventNotificationType.ASSESSMENT_ARTIFACT_UPDATE_COMPLETED:
            case EventNotificationType.ASSESSMENT_COMPLETE:
            case EventNotificationType.ASSESSMENT_CANCELLED_AUTOMATICALLY:
            case EventNotificationType.UPCOMING_RECERTIFICATION:
            case EventNotificationType.AUTOMATIC_RECERTIFICATION:
            case EventNotificationType.RECERTIFICATION_OVERDUE:
            case EventNotificationType.ASSESSMENT_CANCELLED:
                return `/requests/${this.eventNotification?.data?.recordId}`;

            case EventNotificationType.ARTIFACT_EXPIRING:
            case EventNotificationType.ARTIFACT_EXPIRED:
            case EventNotificationType.SUPPLEMENTAL_QUESTIONNAIRES_ANSWERED_AUTOMATICALLY:
            case EventNotificationType.ARTIFACT_INTELLIGENCE_AVAILABLE:
            case EventNotificationType.ARTIFACTS_TRANSFERRED_FROM_TRUST_PROFILE:
                return `/requests/${this.eventNotification?.data?.recordId}`;
            case EventNotificationType.ORG_PUBLIC_SEARCH_INFO_COMPLETED:
                return `/directory/${this.eventNotification?.data?.recordId}`;
            default:
                return '';
        }
    }

    get dataText(): string {
        return this.eventNotification?.data?.text;
    }

    get notificationText(): string {
        return this.eventNotification?.notificationText;
    }

    get humanizedCreatedDate(): string {
        return this.eventNotification?.humanizedCreatedDate;
    }

    ngOnChanges(): void {
        this.formatNotificationText();
    }

    private formatNotificationText(): void {
        switch (this.eventNotification.eventNotificationType) {
            case EventNotificationType.ASSESSMENT_COMPLETE:
                this.eventNotification.notificationText = 'assessment completed.';
                break;
            case EventNotificationType.ASSESSMENT_CANCELLED_AUTOMATICALLY:
                this.eventNotification.notificationText = 'assessment could not be delivered and was cancelled.';
                break;
            case EventNotificationType.UPCOMING_RECERTIFICATION:
                const daysTill = this.eventNotification.data?.daysTill;
                const recertificationType = this.eventNotification.data?.recertificationType;
                let s = `recertification is due in `;
                if (!!recertificationType && recertificationType == RecertificationType.AUTOMATIC) {
                    s = `automatic recertification will start in `;
                }
                if (!!daysTill) {
                    const dayText = daysTill === 1 ? 'day' : 'days';
                    this.eventNotification.notificationText = `${s} ${daysTill} ${dayText}.`;
                }
                break;
            case EventNotificationType.AUTOMATIC_RECERTIFICATION:
                this.eventNotification.notificationText = 'automatic recertification started.';
                break;
            case EventNotificationType.RECERTIFICATION_OVERDUE:
                this.eventNotification.notificationText = 'recertification is overdue.';
                break;
            case EventNotificationType.ASSESSMENT_CANCELLED:
                this.eventNotification.notificationText = 'assessment cancelled.';
                break;
            case EventNotificationType.ARTIFACT_EXPIRING:
                this.eventNotification.notificationText = 'artifact(s) expiring soon.';
                break;
            case EventNotificationType.ARTIFACT_EXPIRED:
                this.eventNotification.notificationText = 'artifact(s) expired.';
                break;
            case EventNotificationType.ASSESSMENT_ARTIFACT_UPDATE_COMPLETED:
                this.eventNotification.notificationText = 'assessment update (for expired artifacts) completed.';
                break;
            case EventNotificationType.ARTIFACTS_TRANSFERRED_FROM_TRUST_PROFILE:
                this.eventNotification.notificationText = 'new Artifact Intelligence is available.';
                break;
            default:
                break;
        }
    }
}
