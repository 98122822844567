import { Routes } from '@angular/router';

import { BulkImportComponent as ImportRelationshipsComponent } from './bulk-import.component';
import { AuthGuard, LockedFeatureGuard } from '../../shared';
import { VisoUserRole } from '../../entities/viso-user';

export const importRelationshipsRoutes: Routes = [
    {
        path: 'import-relationships',
        component: ImportRelationshipsComponent,
        data: {
            authorities: [VisoUserRole.OrgAdmin],
            pageTitle: 'Import Relationships',
            lockOnTrial: true,
        },
        canActivate: [AuthGuard, LockedFeatureGuard],
    },
];
