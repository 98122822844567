import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { withLatestFrom } from 'rxjs/operators';
import { Tag } from '../../../entities/tag';
import { VisoUserRole } from '../../../entities/viso-user';
import { NgbModalWrapperService } from '../../../shared';
import { getTagsRequest } from '../redux/tag.actions';
import { getLoadingSelector, getTagPaginationSelector, getTagsSelector } from '../redux/tag.selectors';
import { TagDeleteDialogComponent } from '../tag-delete-dialog/tag-delete-dialog.component';
import { BREADCRUMB_CONTAINER_TOKEN } from '../../../shared/dynamic-content/dynamic-content-injector';

@Component({
    selector: 'app-tag-management',
    templateUrl: './tag-management.component.html',
    styleUrls: ['./tag-management.component.scss'],
})
export class TagManagementComponent implements OnInit {
    public readonly breadcrumbsContainerToken = BREADCRUMB_CONTAINER_TOKEN;
    Roles = VisoUserRole;
    tags$ = this._store$.pipe(select(getTagsSelector));
    pagination$ = this._store$.pipe(select(getTagPaginationSelector));
    loading$ = this._store$.pipe(select(getLoadingSelector));

    private _initialPagination = {
        page: 0,
        size: 20,
        sort: ['name,asc'],
    };

    constructor(
        private _store$: Store,
        private _modalService: NgbModalWrapperService,
        private _router: Router,
        private _route: ActivatedRoute,
    ) {}

    ngOnInit(): void {
        this._route.queryParams.pipe(withLatestFrom(this.pagination$)).subscribe(([params, pagination]) => {
            const paginationCopy = pagination ? { ...pagination } : this._initialPagination;
            if (params.sort && params.sort !== paginationCopy.sort) {
                paginationCopy.sort = [params.sort];
            }

            if (params.page && params.page !== paginationCopy.page) {
                paginationCopy.page = params.page;
            }
            this._store$.dispatch(getTagsRequest({ pagination: paginationCopy }));
        });
    }

    onSort({ sorts }) {
        const { dir, prop } = sorts[0];
        const sorting = `${prop},${dir}`;
        this._router.navigate([], {
            relativeTo: this._route,
            queryParams: { sort: sorting },
            queryParamsHandling: 'merge',
        });
    }

    deleteTag(selectedTag: Tag): void {
        this._modalService.open<TagDeleteDialogComponent>(
            TagDeleteDialogComponent,
            { selectedTag },
            { size: 'sm', backdrop: 'static' },
        );
    }

    transformSort(sortings: string[]) {
        return sortings.map((sort) => {
            const [prop, dir] = sort.split(',') || [];
            return { dir, prop };
        });
    }

    setCurrentPage({ page }) {
        const newPage = +page - 1;
        this._router.navigate([], {
            relativeTo: this._route,
            queryParams: { page: newPage },
            queryParamsHandling: 'merge',
        });
    }
}
