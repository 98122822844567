import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup } from '@angular/forms';
import { LifecycleManagementFormGroup } from '../../models/lifecycle-management.model';
import { RecertificationType, ReviewFrequency } from '../../../../entities/relationship';
import { select, Store } from '@ngrx/store';
import { updateLifecycleManagement } from '../../redux/actions/lifecycle-management.actions';
import { first, Observable } from 'rxjs';
import { getRelationshipBusinessOwner, getRelationshipPrimaryVendorContact } from '../../redux/request.selectors';
import { filter, map } from 'rxjs/operators';
import { LifecycleManagementHelperService } from '../../services/lifecycle-management-helper.service';
import { getUserAccount } from '../../../session/redux/session.selectors';
import { PrimaryVendorContact } from '../../../primary-vendor-contact';
import { VisoUser } from '@entities/viso-user';
import { FormUtilsService } from '@shared/utils/form-utils.service';

@Component({
    selector: 'app-lifecycle-management-modal',
    templateUrl: './lifecycle-management-modal.component.html',
    styleUrls: ['./lifecycle-management-modal.component.scss'],
    providers: [LifecycleManagementHelperService],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LifecycleManagementModalComponent implements OnInit {
    @Input({ required: true })
    relationshipId: number;

    @Input({ required: true })
    artifactUpdateEnabled: boolean;

    @Input({ required: true })
    dateOfNextArtifactExpiration: Date;

    @Input({ required: true })
    recertificationType: RecertificationType;

    @Input({ required: true })
    artifactUpdateType: RecertificationType;

    @Input({ required: true })
    reviewFrequency?: ReviewFrequency;

    @Input({ required: true })
    recertificationDate?: Date;

    @Input({ required: true })
    latestCompletedAssessmentDate?: Date;

    lifecycleManagementFormGroup: FormGroup<LifecycleManagementFormGroup>;

    relationshipBusinessOwner$: Observable<VisoUser>;
    relationshipPrimaryContact$: Observable<PrimaryVendorContact>;
    relationshipHasPrimaryContact$: Observable<boolean>;

    clientLicenseHasAvailableManagedVendors: boolean = true;

    readonly today = new Date();

    constructor(
        private _store$: Store,
        private _activeModal: NgbActiveModal,
        private _fb: FormBuilder,
        private _lifecycleManagementHelper: LifecycleManagementHelperService,
        private _formUtils: FormUtilsService,
    ) {}

    ngOnInit(): void {
        this.relationshipHasPrimaryContact$ = this._store$
            .select(getRelationshipPrimaryVendorContact)
            .pipe(map((pc) => !!pc));

        this.relationshipBusinessOwner$ = this._store$.select(getRelationshipBusinessOwner);

        this.relationshipPrimaryContact$ = this._store$.select(getRelationshipPrimaryVendorContact);

        this.lifecycleManagementFormGroup = this._fb.group({
            artifactUpdateSettings: this._fb.group({
                artifactUpdateEnabled: this._fb.control(this.artifactUpdateType !== RecertificationType.NONE),
                artifactUpdateType: this._fb.control(this.artifactUpdateType),
            }),
            recertificationSettings: this._fb.group({
                recertificationEnabled: this._fb.control(this.recertificationType !== RecertificationType.NONE),
                basedOnDate: this._fb.control(
                    this._lifecycleManagementHelper.computeBasedOnDate(
                        this.recertificationDate,
                        this.latestCompletedAssessmentDate,
                        this.reviewFrequency,
                    ),
                ),
                recertificationType: this._fb.control(this.recertificationType),
                reviewFrequency: this._fb.control(this.reviewFrequency ?? ReviewFrequency.ANNUAL),
                recertificationDate: this._fb.control(this.recertificationDate),
            }),
        });

        this._store$
            .pipe(
                select(getUserAccount),
                first(),
                map(
                    (account) =>
                        account?.clientLicense?.maxRelationshipsWithLifecycleManagement -
                            account?.clientLicense?.relationshipsWithLifecycleManagementCount <=
                            0 &&
                        account?.clientLicense?.maxRelationshipsWithLifecycleManagement !== -1 &&
                        !this.artifactUpdateEnabled &&
                        this.recertificationType === RecertificationType.NONE,
                ),
                filter((clientLicenseHasAvailableManagedVendors) => clientLicenseHasAvailableManagedVendors),
            )
            .subscribe(() => {
                this.lifecycleManagementFormGroup.disable();
                this.clientLicenseHasAvailableManagedVendors = false;
            });
    }

    close(): void {
        this._activeModal.close();
    }

    save(): void {
        const {
            artifactUpdateSettings: { artifactUpdateType },
            recertificationSettings: { recertificationDate, recertificationType, reviewFrequency },
        } = this._formUtils.getCleanTypedFormGroupValue(this.lifecycleManagementFormGroup);
        this._store$.dispatch(
            updateLifecycleManagement({
                relationshipId: this.relationshipId,
                request: {
                    artifactUpdateSettings: {
                        artifactUpdateType,
                    },
                    recertificationSettings: {
                        recertificationDate,
                        recertificationType,
                        reviewFrequency,
                    },
                },
            }),
        );
        this._activeModal.close();
    }
}
