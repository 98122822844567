import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { RelationshipService } from '../../../../entities/relationship';
import {
    getRelationshipSecurityControlDomainRequest,
    getRelationshipSecurityControlDomainRequestFailed,
    getRelationshipSecurityControlDomainRequestSuccess,
} from '../actions/security-control-domains.actions';

@Injectable()
export class SecurityControlDomainEffects {
    getRelationshipSecurityControlDomainRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(getRelationshipSecurityControlDomainRequest),
            switchMap(({ requestId: relationshipId }) =>
                this._relationshipService.getSecurityControlDomain(relationshipId).pipe(
                    map((securityControlDomain) =>
                        getRelationshipSecurityControlDomainRequestSuccess({ securityControlDomain }),
                    ),
                    catchError(() => of(getRelationshipSecurityControlDomainRequestFailed())),
                ),
            ),
        ),
    );

    constructor(
        private _actions$: Actions,
        private _relationshipService: RelationshipService,
    ) {}
}
