import { Component, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, of, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, takeUntil } from 'rxjs/operators';
import { InternalTagColorEnum, Tag } from '../../../entities/tag';

@Component({
    selector: 'app-collapsed-cell-items',
    templateUrl: './collapsed-items.component.html',
    styleUrls: ['./collapsed-items.component.scss'],
})
export class CollapsedItemsComponent implements OnInit, OnDestroy {
    TagColorEnum = InternalTagColorEnum;
    tags: Tag[] = [];
    dropdownOpen = false;
    private _dropdownControl$ = new BehaviorSubject(false);
    private _unsub$ = new Subject<void>();

    ngOnInit() {
        of(this.dropdownOpen)
            .pipe(
                takeUntil(this._unsub$),
                switchMap((_) => this._dropdownControl$),
                debounceTime(100),
                distinctUntilChanged(),
            )
            .subscribe((isOpen) => (this.dropdownOpen = isOpen));
    }

    ngOnDestroy(): void {
        this._unsub$.next();
    }
    toggleDropdown(value: boolean) {
        this._dropdownControl$.next(value);
    }
}
