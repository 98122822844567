import { Action, createReducer, on } from '@ngrx/store';
import { initialState, RiskModelSettingsState } from './risk-model-settings.state';
import {
    getRelationshipRiskDistributionRequestSuccess,
    getRiskToleranceThresholdsRequestSuccess,
} from './risk-model-settings.actions';
import { Risk } from '@entities/risk-assessment';

const reducer = createReducer(
    initialState,
    on(getRiskToleranceThresholdsRequestSuccess, (state, { riskToleranceThresholds }) => ({
        ...state,
        riskToleranceThresholds: Object.fromEntries(
            Object.entries(riskToleranceThresholds).map(([riskTolerance, innerMap]) => [
                riskTolerance,
                Object.fromEntries(Object.entries(innerMap).map(([riskLevel, score]) => [Risk[riskLevel], score])),
            ]),
        ),
    })),
    on(getRelationshipRiskDistributionRequestSuccess, (state, { relationshipRiskDistribution }) => ({
        ...state,
        relationshipRiskDistribution: Object.fromEntries(
            Object.entries(relationshipRiskDistribution).map(([risk, views]) => [risk, views]),
        ),
    })),
);

export const riskModelSettingsReducer = (
    state: RiskModelSettingsState = initialState,
    action: Action,
): RiskModelSettingsState => reducer(state, action);
