import * as fromRoot from '../../../shared/redux/state';
import { RelationshipRiskToleranceView } from '@entities/risk-tolerance';

export const riskModelSettingsStateKey = 'risk-model-settings';

export const initialState: RiskModelSettingsState = {
    riskToleranceThresholds: {},
    relationshipRiskDistribution: {},
};

export interface AppState extends fromRoot.AppState {
    [riskModelSettingsStateKey]: RiskModelSettingsState;
}

interface RiskToleranceThresholds {
    [key: string]: {
        [key: string]: number;
    };
}

export interface RiskModelSettingsState {
    riskToleranceThresholds: RiskToleranceThresholds;
    relationshipRiskDistribution: { [key: string]: RelationshipRiskToleranceView[] };
}
