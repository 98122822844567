import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ContextType } from '../../../../entities/context-type';
import { ItemFormGroup, ItemsFormGroup } from '../models/relationship-context-form';

@Component({
    selector: 'app-business-cases-selection',
    templateUrl: 'business-cases-selection.component.html',
    styleUrls: ['business-cases-selection.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BusinessCasesSelectionComponent {
    @Input()
    businessCasesFormGroup: FormGroup<ItemsFormGroup<ContextType>>;

    @Input() clientOrgName: string;

    @Input() thirdPartyOrgName: string;

    clientOrgNameRegex = /\$clientOrgName/g;
    thirdPartyOrgNameRegex = /\$thirdPartyOrgName/g;

    get contextTypeFormItems(): FormGroup<ItemFormGroup<ContextType>>[] {
        return this.businessCasesFormGroup.controls.items.controls;
    }

    getContextTypeRef(item: FormGroup<ItemFormGroup<ContextType>>): ContextType {
        return item.controls.ref.value;
    }
}
