import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { FrameworkMappingsService } from '@entities/framework/framework-mappings.service';
import {
    getFrameworkMappingsRequest,
    getFrameworkMappingsRequestFailed,
    getFrameworkMappingsRequestSuccess,
} from '../actions/framework-mapping.actions';

@Injectable()
export class FrameworkMappingsEffects {
    getMappingFrameworksRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(getFrameworkMappingsRequest),
            switchMap(({ relationshipId, framework }) =>
                this._frameworkMappingsService.getFrameworkMappingsByType(framework).pipe(
                    map((mappings) =>
                        getFrameworkMappingsRequestSuccess({
                            relationshipId,
                            mappings,
                            frameworkMappingType: framework,
                        }),
                    ),
                    catchError(() => of(getFrameworkMappingsRequestFailed())),
                ),
            ),
        ),
    );

    constructor(
        private _actions$: Actions,
        private _frameworkMappingsService: FrameworkMappingsService,
    ) {}
}
