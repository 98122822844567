import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ArtifactValidation, FileArtifact } from '@entities/artifact';
import { AuditReportTypeName } from '@entities/audit-report';
import { FileLikeObject, FileUploader } from '@shared/file-upload';
import { FilterFunction } from '@shared/file-upload/interfaces';

@Component({
    selector: 'app-replace-file-artifact-item',
    templateUrl: './replace-file-artifact-item.component.html',
    styleUrl: './replace-file-artifact-item.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReplaceFileArtifactItemComponent implements OnInit {
    @Input({ required: true })
    artifactToReplace: FileArtifact;

    @Output()
    uploadReplacement = new EventEmitter<{ file: File; artifactIdToReplace: number }>();

    @Output()
    onWhenAddingFileFailed = new EventEmitter<FilterFunction>();

    uploader: FileUploader;

    get artifactValidation(): ArtifactValidation {
        return this.artifactToReplace.validation;
    }

    get artifactTypeName(): string {
        return AuditReportTypeName[this.artifactValidation.auditReportType];
    }

    get fileName(): string {
        return this.artifactToReplace.fileName;
    }

    get uploadedDate(): Date {
        return this.artifactToReplace.createdDate;
    }

    get invalidDate(): Date {
        return this.artifactValidation.expirationDate;
    }

    ngOnInit(): void {
        this.uploader = new FileUploader({
            url: '',
            maxFileSize: 100000000,
        });

        this.uploader.onWhenAddingFileFailed = (item: FileLikeObject, filter: FilterFunction, options: any) => {
            this.onWhenAddingFileFailed.emit(filter);
        };
    }

    emitUploadReplacement(file: File): void {
        this.uploadReplacement.emit({ file, artifactIdToReplace: this.artifactToReplace.id });
    }
}
