import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PasteAsPlainTextDirective } from './paste-plain-text.directive';

@NgModule({
    declarations: [PasteAsPlainTextDirective],
    imports: [CommonModule],
    exports: [PasteAsPlainTextDirective],
})
export class PasteAsPlainTextModule {}
