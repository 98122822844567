import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { routerNavigatedAction } from '@ngrx/router-store';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { NgbModalWrapperService } from '../../../shared';
import { PdfArtifactViewerModalHelperService } from '../services';
import {
    pdfArtifactViewArtifactIdParam,
    pdfArtifactViewRoute,
    pdfArtifactViewSelectedDetectionIdParam,
    pdfArtifactViewViewerPageParam,
} from '../pdf-artifact-viewer.constants';
import { PdfArtifactWithDetectionsViewerModalComponent } from '../components';

@Injectable()
export class PdfArtifactViewerEffects {
    openAddRelationshipModal$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(routerNavigatedAction),
                map((action) => action.payload.routerState.root.children.find((route) => route.outlet === 'popup')),
                filter((route) => !!route && route.routeConfig.path === pdfArtifactViewRoute),
                map((route) => ({
                    artifactId: +route.params?.[pdfArtifactViewArtifactIdParam],
                    initialSelectedDetectionId: +route.queryParams?.[pdfArtifactViewSelectedDetectionIdParam],
                    viewerPage: +route.queryParams?.[pdfArtifactViewViewerPageParam],
                })),
                switchMap(({ artifactId, initialSelectedDetectionId, viewerPage }) =>
                    this._modalHelper
                        .getModalData(artifactId)
                        .pipe(map((modalData) => ({ ...modalData, initialSelectedDetectionId, viewerPage }))),
                ),
                tap(
                    ({
                        artifact,
                        artifactAuditType,
                        controlsValidatedCount,
                        detections,
                        initialSelectedDetectionId,
                        password,
                        pdfResponseBlob,
                        viewerPage,
                    }) =>
                        this._modalService.open<PdfArtifactWithDetectionsViewerModalComponent>(
                            PdfArtifactWithDetectionsViewerModalComponent,
                            {
                                artifact,
                                artifactAuditType,
                                controlsValidatedCount,
                                detections,
                                initialSelectedDetectionId,
                                password,
                                pdfResponseBlob,
                                viewerPage,
                            },
                            { fullscreen: true },
                        ),
                ),
            ),
        { dispatch: false },
    );

    constructor(
        private _actions$: Actions,
        private _modalHelper: PdfArtifactViewerModalHelperService,
        private _modalService: NgbModalWrapperService,
    ) {}
}
