import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { filter, map, tap, withLatestFrom } from 'rxjs';
import {
    artifactUpdateCollectionRoute,
    assessmentCollectionAuthenticateSubRoute,
    assessmentCollectionCancelledSubRoute,
    assessmentCollectionCompletedSubRoute,
    assessmentCollectionForwardedSubRoute,
    assessmentCollectionRoute,
    assessmentCollectionSubmittedSubRoute,
    recertificationCollectionRoute,
} from '../../route-constants';
import { CollectionTypes } from '../../models';
import {
    getAssessmentCollectionType,
    getAssessmentToken,
    getJustCancelled,
    getJustForwarded,
    getRemediationJustDeclined,
    getShowCancelledOrDeleted,
    getShowCompleted,
    getShowSubmitted,
} from '../selectors';
import {
    cancelAssessmentRequestSuccess,
    declineRemediationRequestSuccess,
    forwardRequestRequestSuccess,
    redirectToAuthenticate,
    redirectToNotInProgressState,
    submitPasscodeSuccess,
} from '../actions';

@Injectable()
export class RedirectsEffects {
    redirectAfterAuthentication$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(submitPasscodeSuccess),
                withLatestFrom(
                    this._store$.select(getAssessmentToken),
                    this._store$.select(getAssessmentCollectionType),
                ),
                tap(([, assessmentToken, collectionType]) => {
                    let url: string;
                    switch (collectionType) {
                        case CollectionTypes.INITIAL_ASSESSMENT:
                            url = `${assessmentCollectionRoute}/${assessmentToken}`;
                            break;
                        case CollectionTypes.RECERTIFICATION:
                            url = `${recertificationCollectionRoute}/${assessmentToken}`;
                            break;
                        case CollectionTypes.ARTIFACT_UPDATE:
                            url = `${artifactUpdateCollectionRoute}/${assessmentToken}`;
                            break;
                        default:
                            break;
                    }
                    this._router.navigateByUrl(url);
                }),
            ),
        { dispatch: false },
    );

    redirectToAuthenticate$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(redirectToAuthenticate),
                withLatestFrom(
                    this._store$.select(getAssessmentToken),
                    this._store$.select(getAssessmentCollectionType),
                ),
                tap(([, assessmentToken, collectionType]) => {
                    let url: string;
                    switch (collectionType) {
                        case CollectionTypes.INITIAL_ASSESSMENT:
                            url = `${assessmentCollectionRoute}/${assessmentToken}/${assessmentCollectionAuthenticateSubRoute}`;
                            break;
                        case CollectionTypes.RECERTIFICATION:
                            url = `${recertificationCollectionRoute}/${assessmentToken}/${assessmentCollectionAuthenticateSubRoute}`;
                            break;
                        case CollectionTypes.ARTIFACT_UPDATE:
                            url = `${artifactUpdateCollectionRoute}/${assessmentToken}/${assessmentCollectionAuthenticateSubRoute}`;
                            break;
                        default:
                            break;
                    }
                    this._router.navigateByUrl(url);
                }),
            ),
        { dispatch: false },
    );

    redirectToSubmittedState$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(redirectToNotInProgressState),
                withLatestFrom(
                    this._store$.select(getShowSubmitted),
                    this._store$.select(getAssessmentToken),
                    this._store$.select(getAssessmentCollectionType),
                ),
                filter(([, isSubmitted]) => isSubmitted),
                tap(([, , assessmentToken, collectionType]) => {
                    let url: string;
                    switch (collectionType) {
                        case CollectionTypes.INITIAL_ASSESSMENT:
                            url = `${assessmentCollectionRoute}/${assessmentToken}/${assessmentCollectionSubmittedSubRoute}`;
                            break;
                        case CollectionTypes.RECERTIFICATION:
                            url = `${recertificationCollectionRoute}/${assessmentToken}/${assessmentCollectionSubmittedSubRoute}`;
                            break;
                        case CollectionTypes.ARTIFACT_UPDATE:
                            url = `${artifactUpdateCollectionRoute}/${assessmentToken}/${assessmentCollectionSubmittedSubRoute}`;
                            break;
                        default:
                            break;
                    }
                    this._router.navigateByUrl(url);
                }),
            ),
        { dispatch: false },
    );

    redirectToCancelledState$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(redirectToNotInProgressState),
                withLatestFrom(
                    this._store$.select(getShowCancelledOrDeleted),
                    this._store$.select(getJustCancelled),
                    this._store$.select(getRemediationJustDeclined),
                    this._store$.select(getAssessmentToken),
                    this._store$.select(getAssessmentCollectionType),
                ),
                filter(
                    ([, isCancelled, justCancelled, remediationJustDeclined]) =>
                        isCancelled || justCancelled || remediationJustDeclined,
                ),
                tap(([, , , , assessmentToken, collectionType]) => {
                    let url: string;
                    switch (collectionType) {
                        case CollectionTypes.INITIAL_ASSESSMENT:
                            url = `${assessmentCollectionRoute}/${assessmentToken}/${assessmentCollectionCancelledSubRoute}`;
                            break;
                        case CollectionTypes.RECERTIFICATION:
                            url = `${recertificationCollectionRoute}/${assessmentToken}/${assessmentCollectionCancelledSubRoute}`;
                            break;
                        case CollectionTypes.ARTIFACT_UPDATE:
                            url = `${artifactUpdateCollectionRoute}/${assessmentToken}/${assessmentCollectionCancelledSubRoute}`;
                            break;
                        default:
                            break;
                    }
                    this._router.navigateByUrl(url);
                }),
            ),
        { dispatch: false },
    );

    redirectToCompletedState$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(redirectToNotInProgressState),
                withLatestFrom(
                    this._store$.select(getShowCompleted),
                    this._store$.select(getAssessmentToken),
                    this._store$.select(getAssessmentCollectionType),
                ),
                filter(([, isCompleted]) => isCompleted),
                tap(([, , assessmentToken, collectionType]) => {
                    let url: string;
                    switch (collectionType) {
                        case CollectionTypes.INITIAL_ASSESSMENT:
                            url = `${assessmentCollectionRoute}/${assessmentToken}/${assessmentCollectionCompletedSubRoute}`;
                            break;
                        case CollectionTypes.RECERTIFICATION:
                            url = `${recertificationCollectionRoute}/${assessmentToken}/${assessmentCollectionCompletedSubRoute}`;
                            break;
                        case CollectionTypes.ARTIFACT_UPDATE:
                            url = `${artifactUpdateCollectionRoute}/${assessmentToken}/${assessmentCollectionCompletedSubRoute}`;
                            break;
                        default:
                            break;
                    }
                    this._router.navigateByUrl(url);
                }),
            ),
        { dispatch: false },
    );

    redirectToForwardedState$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(redirectToNotInProgressState),
                withLatestFrom(
                    this._store$.select(getJustForwarded),
                    this._store$.select(getAssessmentToken),
                    this._store$.select(getAssessmentCollectionType),
                ),
                filter(([, getJustForwarded]) => getJustForwarded),
                tap(([, , assessmentToken, collectionType]) => {
                    let url: string;
                    switch (collectionType) {
                        case CollectionTypes.INITIAL_ASSESSMENT:
                            url = `${assessmentCollectionRoute}/${assessmentToken}/${assessmentCollectionForwardedSubRoute}`;
                            break;
                        case CollectionTypes.RECERTIFICATION:
                            url = `${recertificationCollectionRoute}/${assessmentToken}/${assessmentCollectionForwardedSubRoute}`;
                            break;
                        case CollectionTypes.ARTIFACT_UPDATE:
                            url = `${artifactUpdateCollectionRoute}/${assessmentToken}/${assessmentCollectionForwardedSubRoute}`;
                            break;
                        default:
                            break;
                    }
                    this._router.navigateByUrl(url);
                }),
            ),
        { dispatch: false },
    );

    justMarkedNotInProgressRedirect$ = createEffect(() =>
        this._actions$.pipe(
            ofType(cancelAssessmentRequestSuccess, forwardRequestRequestSuccess, declineRemediationRequestSuccess),
            map(() => redirectToNotInProgressState()),
        ),
    );

    constructor(
        private _router: Router,
        private _store$: Store,
        private _actions$: Actions,
    ) {}
}
