<div class="panel-title">Get your AI-powered cyber risk assessments today.</div>
<div class="panel-subtitle">
    Take control of your third-party security by using VISO TRUST's AI-powered security assessment. Get started by
    assessing your vendors today, for free!
</div>
<div class="panel-items">
    <div class="panel-item">
        <img src="content/img/risk.png" />
        <div class="panel-item-description">
            <section>
                <strong>Reduce overall cyber risk</strong> and make informed decisions
                <strong>earlier in the procurement</strong> process
            </section>
        </div>
    </div>

    <div class="panel-item">
        <img src="content/img/time.png" />
        <div class="panel-item-description">
            <section>
                Assess your <strong>entire vendor population</strong> and <strong>cut down response time</strong> with
                AI
            </section>
        </div>
    </div>

    <div class="panel-item">
        <img src="content/img/ai.png" />

        <div class="panel-item-description">
            <section><strong>Eliminate outdated security questionnaires</strong> and manual processes</section>
        </div>
    </div>

    <div class="panel-item">
        <img src="content/img/trust.png" />
        <div class="panel-item-description">
            <section>
                Analyze and report on your <strong>third-party risk posture</strong> over time and
                <strong>build more trust</strong> with customers and partners
            </section>
        </div>
    </div>

    <div class="panel-item">
        <img src="content/img/automate.png" />
        <div class="panel-item-description">
            <section>Automate and manage <strong>the lifecycle of your vendors in one place.</strong></section>
        </div>
    </div>
</div>
