import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { VisoEditorComponent } from './viso-editor.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ContentEditableReactiveModule } from '../../content-editable-reactive/content-editable-reactive.module';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { PasteAsPlainTextModule } from '../../paste-plain-text/paste-plain-text.module';

@NgModule({
    declarations: [VisoEditorComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        ContentEditableReactiveModule,
        MatButtonModule,
        MatIconModule,
        PasteAsPlainTextModule,
    ],
    exports: [VisoEditorComponent],
})
export class VisoEditorModule {}
