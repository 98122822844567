import { Component, ElementRef, EventEmitter, HostBinding, Input, Output, ViewChild } from '@angular/core';

@Component({
    selector: 'app-document-file',
    templateUrl: './document-file.component.html',
    styleUrls: ['./document-file.component.scss'],
})
export class DocumentFileComponent {
    @Input() documentFileName: string;
    @Input() disabled: boolean;
    @Input() includePassword: boolean;
    @Input() isUrl: boolean;
    @Output() onPasswordSaved = new EventEmitter<string>();
    @Output() onFileRemoved = new EventEmitter<void>();

    @ViewChild('passwordInput') passwordInput: ElementRef;
    @HostBinding('class.border-none') showPasswordInput: boolean = false;

    passwordLocked: boolean;
    updatePasswordMode: boolean;

    enterPasswordInput(update: boolean = false): void {
        setTimeout(() => this.passwordInput.nativeElement.focus(), 100);
        this.showPasswordInput = true;
        this.updatePasswordMode = update;
    }

    removePassword(): void {
        this.passwordLocked = false;
        this.updatePasswordMode = false;
        this.onPasswordSaved.emit('');
    }

    savePassword(): void {
        const passwordText = this.passwordInput.nativeElement.value.trim();
        if (passwordText) {
            this.onPasswordSaved.emit(passwordText);
            this.passwordLocked = true;
            this.showPasswordInput = false;
        }
    }

    discardPassword(): void {
        this.showPasswordInput = false;
    }
}
