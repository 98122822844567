<div *dynamicContent="breadcrumbsContainerToken">
    <ol class="breadcrumb breadcrumb px-0 pb-0">
        <li class="breadcrumb-item"><a [routerLink]="['/']">Home</a></li>
        <li class="breadcrumb-item active">Data Types</li>
    </ol>
</div>

<div class="viso-heading">
    <div class="col">
        Data Types
        <div class="viso-subheading">
            Data types are our definition of what's at stake with each vendor relationship. They represent your digital
            assets that might get shared with a vendor and increase the inherent risk of a relationship.
        </div>
    </div>
    <div class="col-auto">
        <div class="viso-legend">
            <p class="title">
                Sensitivity Level
                <a
                    class="strong"
                    (click)="
                        openHelp(
                            'Sensitivity Level',
                            'Sensitivity is relative, and doesn\'t directly establish Inherent Risk. For example, Medium Data Sensitivity could result in an Extreme Inherent Risk level.'
                        )
                    "
                    ><em class="fa fa-question-circle text-muted ms-2"></em
                ></a>
            </p>
            <app-sensitivity-meter
                [sensitivity]="0"
                [sensitivityLevel]="DataSensitivityLevel.EXTREME"
                [legend]="true"
            ></app-sensitivity-meter>
        </div>
    </div>
</div>
<div class="row">
    <div class="col">
        <mat-tab-group
            id="organizationTabset"
            #organizationTabset
            mat-stretch-tabs="true"
            class="mb-3 viso-tabset"
            animationDuration="0"
            dynamicHeight
        >
            <mat-tab label="Organization">
                <mat-list>
                    <mat-list-item
                        *ngFor="let organizationDataType of organizationDataTypes"
                        class="mdc-list-item--with-trailing-icon viso-mat-list"
                        [lines]="2"
                    >
                        <span matListItemAvatar>
                            <app-chip
                                [type]="organizationDataType.enabled ? 'basic-outline' : 'danger-outline'"
                                [condensed]="true"
                            >
                                {{ organizationDataType.enabled ? 'Active' : 'Inactive' }}
                            </app-chip></span
                        >
                        <span
                            matListItemTitle
                            class="title"
                            [ngClass]="organizationDataType.dataType.custom ? 'custom' : ''"
                            >{{ organizationDataType.dataType.custom ? 'CUSTOM' : 'DEFAULT' }}</span
                        >
                        <span matListItemLine class="name">{{ organizationDataType.dataType.name }}</span>
                        <span matListItemLine class="description">{{ organizationDataType.dataType.description }}</span>
                        <span matListItemMeta
                            ><app-sensitivity-meter
                                [sensitivity]="getSensitivityValueForBar(organizationDataType.dataType.sensitivity)"
                                [sensitivityLevel]="organizationDataType.dataType.sensitivityLevel"
                            ></app-sensitivity-meter
                        ></span>
                    </mat-list-item>
                </mat-list>
            </mat-tab>
            <mat-tab label="Customer">
                <mat-list>
                    <mat-list-item
                        *ngFor="let customerDataType of customerDataTypes"
                        class="mdc-list-item--with-trailing-icon viso-mat-list"
                    >
                        <span matListItemAvatar>
                            <app-chip
                                [type]="customerDataType.enabled ? 'basic-outline' : 'danger-outline'"
                                [condensed]="true"
                                >{{ customerDataType.enabled ? 'Active' : 'Inactive' }}</app-chip
                            ></span
                        >
                        <span matListItemTitle class="title" [ngClass]="{ custom: customerDataType.dataType.custom }">{{
                            customerDataType.dataType.custom ? 'CUSTOM' : 'DEFAULT'
                        }}</span>
                        <span matListItemLine class="name">{{ customerDataType.dataType.name }}</span>
                        <span matListItemLine class="description">{{ customerDataType.dataType.description }}</span>
                        <span matListItemMeta
                            ><app-sensitivity-meter
                                [sensitivity]="getSensitivityValueForBar(customerDataType.dataType.sensitivity)"
                                [sensitivityLevel]="customerDataType.dataType.sensitivityLevel"
                            ></app-sensitivity-meter
                        ></span>
                    </mat-list-item>
                </mat-list>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>
