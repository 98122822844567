import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { DataType } from './data-type.model';
import { createRequestOption } from '../../shared';

export type EntityResponseType = HttpResponse<DataType>;

@Injectable({
    providedIn: 'root',
})
export class DataTypeService {
    private resourceUrl = 'api/data-types';

    constructor(private http: HttpClient) {}

    query(req?: any): Observable<HttpResponse<DataType[]>> {
        const options = createRequestOption(req);
        return this.http
            .get<DataType[]>(this.resourceUrl, { params: options, observe: 'response' })
            .pipe(map((res: HttpResponse<DataType[]>) => this.convertArrayResponse(res)));
    }

    private convertArrayResponse(res: HttpResponse<DataType[]>): HttpResponse<DataType[]> {
        const jsonResponse: DataType[] = res.body;
        const body: DataType[] = [];
        for (let i = 0; i < jsonResponse.length; i++) {
            body.push(this.convertItemFromServer(jsonResponse[i]));
        }
        return res.clone({ body });
    }

    /**
     * Convert a returned JSON object to DataType.
     */
    private convertItemFromServer(dataType: DataType): DataType {
        const copy: DataType = Object.assign({}, dataType);
        return copy;
    }
}
