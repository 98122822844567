import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ContextType } from './context-type.model';
import { createRequestOption } from '../../shared';

@Injectable({
    providedIn: 'root',
})
export class ContextTypeService {
    private resourceUrl = 'api/context-types';

    constructor(private _http: HttpClient) {}

    query(req?: any): Observable<ContextType[]> {
        const options = createRequestOption(req);
        return this._http.get<ContextType[]>(this.resourceUrl, { params: options });
    }
}
