import { Injectable } from '@angular/core';
import { HttpClient, HttpContext } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BYPASS_SNACKBAR_ON_ERROR } from '../../blocks/interceptor/errorhandler.interceptor';
import { ElasticSearchIndexRequest } from './elasticsearch-index.model';

@Injectable()
export class ElasticsearchIndexService {
    private resourceUrl = 'api/elasticsearch';

    constructor(private http: HttpClient) {}

    reindexAll(elasticSearchIndexRequest: ElasticSearchIndexRequest): Observable<string> {
        return this.http.post(`${this.resourceUrl}/reindex`, elasticSearchIndexRequest, {
            context: new HttpContext().set(BYPASS_SNACKBAR_ON_ERROR, { forAnyStatus: true }),
            responseType: 'text',
        });
    }
}
