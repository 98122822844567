import { integrationsRoutes } from './integrations.route';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { SharedModule } from '../../shared';
import { RouterModule } from '@angular/router';
import { NgxSelectModule } from 'ngx-select-ex';
import { IntegrationsComponent } from './integrations.component';
import { EffectsModule } from '@ngrx/effects';
import { MatButtonModule } from '@angular/material/button';
import { IntegrationsEffects } from './redux/integrations.effects';
import { IntegrationsService } from './integrations.service';
import { CoupaIntegrationDialogComponent } from './coupa/coupa-integration-dialog.component';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { IntegrationTileComponent } from './integration-tile/integration-tile.component';

const ENTITY_STATES = [...integrationsRoutes];

@NgModule({
    imports: [
        SharedModule,
        RouterModule.forChild(ENTITY_STATES),
        NgxSelectModule,
        EffectsModule.forFeature([IntegrationsEffects]),
        MatButtonModule,
        MatCardModule,
        MatDividerModule,
        MatProgressBarModule,
    ],
    declarations: [IntegrationsComponent, CoupaIntegrationDialogComponent, IntegrationTileComponent],
    providers: [IntegrationsService],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class IntegrationsModule {
    constructor() {}
}
