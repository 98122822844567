import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { VisoUserRole } from '../../../entities/viso-user';
import { AppState } from '../../../shared/redux/state';
import { getUserAccount } from '../../session/redux/session.selectors';
import { RelationshipColumnGroup } from '../models/relationship-column.model';
import { setColumnsState } from '../redux/relationships.actions';
import { getRelationshipsColumnGroups, getRelationshipsColumnsInitialState } from '../redux/relationships.selectors';
import { ColumnNames } from '../relationships.constants';

@Component({
    selector: 'relationships-edit-columns',
    templateUrl: './edit-columns.component.html',
    styleUrls: ['./edit-columns.component.scss'],
})
export class RelationshipsEditColumnsComponent implements OnInit, OnDestroy {
    columnsGroups: RelationshipColumnGroup[];
    useSuggestedGroups: boolean = true;
    vendorOrgNameColumn: string = ColumnNames.relationship;
    currentUserAuthorities: string[];

    private _unsub: Subject<void> = new Subject<void>();
    private _vendorReviewColumnGroup = 'Vendor Review';
    private _columnsGroupsInitialState: RelationshipColumnGroup[];

    constructor(
        public activeModal: NgbActiveModal,
        private _store$: Store<AppState>,
    ) {}

    get totalColumnsSelected() {
        let columnsSelected = 0;
        this.columnsGroups?.forEach((cg) =>
            cg.columns.forEach((c) => {
                if (c.visible) columnsSelected++;
            }),
        );

        return columnsSelected;
    }

    get totalColumns() {
        return this.columnsGroups?.reduce((r, o) => r + o.columns.length, 0);
    }

    ngOnInit() {
        this._store$.pipe(select(getUserAccount), takeUntil(this._unsub)).subscribe((account) => {
            this.currentUserAuthorities = account.authorities;
        });

        this._store$.pipe(select(getRelationshipsColumnGroups), takeUntil(this._unsub)).subscribe((columnsGroups) => {
            this.columnsGroups = this.authorizeColumns(this.copyArrayWithoutReference(columnsGroups));
            this.useSuggestedGroups = this.columnsGroups.length > 1;
        });

        this._store$
            .pipe(select(getRelationshipsColumnsInitialState), takeUntil(this._unsub))
            .subscribe((columnsGroupsInitialState) => {
                this._columnsGroupsInitialState = this.copyArrayWithoutReference(columnsGroupsInitialState);
            });
    }

    closeModal() {
        this.activeModal.dismiss('cancel');
    }

    onDrop(event: CdkDragDrop<string[]>, columnGroup: RelationshipColumnGroup) {
        if (
            !(
                (columnGroup.columnGroupName === this._vendorReviewColumnGroup || !columnGroup.columnGroupName) &&
                event.currentIndex === 0
            )
        ) {
            moveItemInArray(columnGroup.columns, event.previousIndex, event.currentIndex);
        }
    }

    applyColumnsChanges() {
        this._store$.dispatch(setColumnsState({ results: this.columnsGroups }));
        this.activeModal.dismiss('cancel');
    }

    toggleUseSuggestedGroups() {
        if (this.useSuggestedGroups) {
            this.columnsGroups = this.authorizeColumns(this.copyArrayWithoutReference(this._columnsGroupsInitialState));
        } else {
            const uniqueColumnGroup = [];
            uniqueColumnGroup.push({
                columnGroupName: '',
                columns: [],
            });
            for (const columnGroup of this.columnsGroups) {
                uniqueColumnGroup[0].columns.push(...columnGroup.columns);
            }
            this.columnsGroups = uniqueColumnGroup.slice(0);
        }
    }

    ngOnDestroy() {
        this._unsub.next();
    }

    private authorizeColumns(columnsGroups: RelationshipColumnGroup[]): RelationshipColumnGroup[] {
        columnsGroups.forEach((cg) => {
            cg.columns.forEach((c, index, array) => {
                if (!this.isUserAuthorizedToSeeColumn(c.roles)) {
                    array.splice(index, 1);
                }
            });
        });
        return columnsGroups;
    }

    private isUserAuthorizedToSeeColumn(roles: VisoUserRole[]) {
        return !roles || this.currentUserAuthorities.some((cr) => roles?.includes(cr as VisoUserRole));
    }

    private copyArrayWithoutReference(array: any[]) {
        return JSON.parse(JSON.stringify(array));
    }
}
