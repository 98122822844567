export const DEFAULT_VERSION = -1;

export interface PersistState {
    version: number;
    rehydrated: boolean;
}

export type PersistedState<T> = T & { _persist: PersistState };

export interface MigrationManifest<T> {
    [key: number]: (state: PersistedState<T>) => PersistedState<T>;
}

export interface MigrationConfig<Store> {
    version?: number;
    migrate?: PersistMigrate<Store>;
    debug?: boolean;
    blackList?: string[];
}

export type PersistMigrate<S> = (state: PersistedState<S>, currentVersion: number) => PersistedState<S>;
