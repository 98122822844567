<div *dynamicContent="breadcrumbsContainerToken">
    <ol class="breadcrumb breadcrumb px-0 pb-0">
        <li class="breadcrumb-item"><a [routerLink]="['/']">Home</a></li>
        <li class="breadcrumb-item"><a [routerLink]="['/your-organization']">Your Organization</a></li>
        <li class="breadcrumb-item active">Supplemental Questionnaire Management</li>
    </ol>
</div>

<div class="viso-heading row">
    <div class="col">
        Supplemental Questionnaire Management
        <div class="viso-subheading">Manage supplemental questionnaires for your organization</div>
    </div>
    <div class="col-auto">
        <button mat-fab extended class="fab-outlined" (click)="downloadQuestionnaire()">
            <mat-icon>file_download</mat-icon>
            Download
        </button>
    </div>
</div>

<mat-card>
    <mat-card-header *ngIf="!loadingQuestionnaireArtifact">
        <strong>{{ questionnaireArtifact.fileName | trimFileExtension }}</strong>
    </mat-card-header>
    <mat-card-content>
        <form class="questionnaire-form" [formGroup]="questionnaireFormGroup">
            <section
                *ngFor="let questionFormGroup of questionnaireFormGroup.controls.questions.controls; index as i"
                [formGroup]="questionFormGroup"
            >
                <mat-form-field class="questionnaire-edit-form">
                    <mat-label>Question {{ i + 1 }}</mat-label>
                    <input matInput formControlName="question" id="{{ 'question' + i }}" />
                    <mat-hint *ngIf="questionFormGroup.value.manualReviewNeeded"
                        >This question needs manual review</mat-hint
                    >
                    <button
                        *ngIf="!questionFormGroup.enabled && canEditQuestionnaire"
                        matSuffix
                        mat-icon-button
                        (click)="editQuestion(questionFormGroup, i)"
                        color="primary"
                    >
                        <mat-icon>edit</mat-icon>
                    </button>
                    <button
                        *ngIf="!questionFormGroup.enabled && canEditQuestionnaire"
                        matSuffix
                        mat-icon-button
                        (click)="deleteQuestion(questionFormGroup)"
                        color="warn"
                    >
                        <mat-icon>delete</mat-icon>
                    </button>
                    <button
                        *ngIf="questionFormGroup.enabled && canEditQuestionnaire"
                        matSuffix
                        mat-icon-button
                        (click)="cancelEditQuestion(questionFormGroup)"
                        color="primary"
                    >
                        <mat-icon>close</mat-icon>
                    </button>
                    <button
                        *ngIf="questionFormGroup.enabled && canEditQuestionnaire"
                        matSuffix
                        mat-icon-button
                        (click)="saveQuestion(questionFormGroup)"
                        [disabled]="questionFormGroup.controls.question.invalid"
                        color="primary"
                    >
                        <mat-icon>done</mat-icon>
                    </button>
                </mat-form-field>
            </section>
        </form>
    </mat-card-content>
    <mat-card-actions>
        <button
            mat-fab
            extended
            class="mt-2 fab-outlined"
            (click)="addQuestion()"
            [disabled]="!!newQuestion && !canEditQuestionnaire"
        >
            <mat-icon>add_circle</mat-icon>
            Add Question
        </button>
    </mat-card-actions>
</mat-card>
