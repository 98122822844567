<div *ngIf="vanityDomain?.domain" class="mt-2">
    <div class="d-inline-flex">
        <div
            [ngClass]="{
                pending: status === VanityDomainStatus.PENDING,
                active: status === VanityDomainStatus.ACTIVE,
                error: status === VanityDomainStatus.ERROR,
                'status-pill': true,
            }"
            [ngSwitch]="status"
            [appPopover]="cfStatusPopover"
            popoverTheme="dark"
        >
            <ng-template #cfStatusPopover>
                <div class="px-2 py-2">
                    Domain: <strong>{{ vanityDomain.cloudflareStatus.toUpperCase() }}</strong
                    ><br />
                    SSL: <strong>{{ vanityDomain.cloudflareSslStatus.toUpperCase() }}</strong>
                </div>
            </ng-template>
            <ng-container *ngSwitchCase="VanityDomainStatus.PENDING"
                ><mat-icon>help_outline</mat-icon>Pending</ng-container
            >
            <ng-container *ngSwitchCase="VanityDomainStatus.ACTIVE"><mat-icon>check</mat-icon>Verified</ng-container>
            <ng-container *ngSwitchCase="VanityDomainStatus.ERROR"><mat-icon>error</mat-icon>Unverified</ng-container>
        </div>
        <div *ngIf="status !== VanityDomainStatus.ACTIVE">
            <button mat-icon-button (click)="loadAll()"><mat-icon>refresh</mat-icon></button>
        </div>
    </div>
    <div class="mt-1 text-light" *ngIf="status !== VanityDomainStatus.ACTIVE">
        <p>
            If the domain fails to verify with refreshing, please check the CNAME record in your organization's domain
            configuration.
        </p>
        <p>Note that the URL will not be used for outgoing assessments until this page shows "Verified".</p>
    </div>
    <div class="mt-4">
        Vanity domain<br />
        <strong> {{ vanityDomain.subdomain }}.{{ vanityDomain.domain }} </strong>
    </div>
</div>
