import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Tag } from '../../../../entities/tag';
import { VisoUser, VisoUserRole } from '../../../../entities/viso-user';
import { Relationship } from '../../../../entities/relationship';
import { RequestActions } from '../../redux/request.actions';

@Component({
    selector: 'app-tags',
    templateUrl: './tags.component.html',
    styleUrls: ['./tags.component.scss'],
})
export class TagsComponent {
    @Input({ required: true }) tags: Tag[];
    @Input() relationshipId: number;
    @Input() relationship: Relationship;
    @Input({ required: true }) businessOwner: VisoUser;
    @Input() canDeleteTags: boolean = true;

    @Output() unlinkTag = new EventEmitter<Tag>();
    @Output() linkTag = new EventEmitter<Tag>();

    RequestActions = RequestActions;
    Roles = VisoUserRole;

    selectUnlinkTag(tag: Tag) {
        this.unlinkTag.emit(tag);
    }

    selectLinkTag(tag: Tag) {
        this.linkTag.emit(tag);
    }
}
