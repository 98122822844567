import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MiddleTruncateDirective } from './middle-truncate.directive';

@NgModule({
    declarations: [MiddleTruncateDirective],
    imports: [CommonModule],
    exports: [MiddleTruncateDirective],
})
export class TruncateModule {}
