import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';

interface ResizingEvent {
    isResizing: boolean;
    startingCursorX: number;
    startingWidth: number;
}

@Directive({
    selector: '[resizableDrawer]',
})
export class ResizableDrawerDirective {
    private readonly sidenavMinWidth = 500;
    private readonly sidenavMaxWidth = 2000;
    private resizeHandle: HTMLElement;

    private resizingEvent: ResizingEvent = {
        isResizing: false,
        startingCursorX: 0,
        startingWidth: 0,
    };

    constructor(
        private el: ElementRef,
        private renderer: Renderer2,
    ) {
        this.resizeHandle = this.renderer.createElement('div');
        this.renderer.addClass(this.resizeHandle, 'resize-handler');
        this.renderer.appendChild(this.el.nativeElement, this.resizeHandle);
        this.renderer.listen(this.resizeHandle, 'mousedown', (event) => this.onResizeHandleMouseDown(event));

        // handle styling
        document.documentElement.style.setProperty('--sidenav-width', '500px');

        this.renderer.setStyle(this.el.nativeElement, 'height', '100%');
        this.renderer.setStyle(this.el.nativeElement, 'width', 'var(--sidenav-width)');
        this.renderer.setStyle(this.el.nativeElement, 'border', '1px solid #ccc');
        this.renderer.setStyle(this.resizeHandle, 'background', '#ccc');
        this.renderer.setStyle(this.resizeHandle, 'width', '3px');
        this.renderer.setStyle(this.resizeHandle, 'height', '100%');
        this.renderer.setStyle(this.resizeHandle, 'display', 'block');
        this.renderer.setStyle(this.resizeHandle, 'position', 'absolute');
        this.renderer.setStyle(this.resizeHandle, 'top', '0');
        this.renderer.setStyle(this.resizeHandle, 'left', '0');
        this.renderer.setStyle(this.resizeHandle, 'z-index', '9999');
        this.renderer.setStyle(this.resizeHandle, 'cursor', 'ew-resize');
    }

    private onResizeHandleMouseDown(event: MouseEvent): void {
        this.resizingEvent = {
            isResizing: true,
            startingCursorX: event.clientX,
            startingWidth: this.sidenavWidth,
        };

        // Removing pointer events, since interaction within the frame can jack up the resizing.
        this.renderer.setStyle(this.el.nativeElement, 'pointer-events', 'none');
        event.preventDefault();
    }

    @HostListener('document:mousemove', ['$event'])
    onMouseMove(event: MouseEvent) {
        if (!this.resizingEvent.isResizing) {
            return;
        }

        const cursorDeltaX = this.resizingEvent.startingCursorX - event.clientX;
        const newWidth = this.resizingEvent.startingWidth + cursorDeltaX;
        this.sidenavWidth = newWidth;
    }

    @HostListener('document:mouseup')
    onMouseUp() {
        this.resizingEvent.isResizing = false;
        this.renderer.removeStyle(this.el.nativeElement, 'pointer-events');
    }

    private set sidenavWidth(width: number) {
        const clampedWidth = Math.min(Math.max(width, this.sidenavMinWidth), this.sidenavMaxWidth);
        document.body.style.setProperty('--sidenav-width', `${clampedWidth}px`);
    }

    private get sidenavWidth(): number {
        const widthString = getComputedStyle(document.body).getPropertyValue('--sidenav-width');
        const parsedWidth = parseInt(widthString, 10);

        // Check if the parsed value is NaN, and if so, return a default value
        return isNaN(parsedWidth) ? this.sidenavMinWidth : parsedWidth;
    }
}
