import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExternalLinkDirective } from './directives';

@NgModule({
    declarations: [ExternalLinkDirective],
    imports: [CommonModule],
    exports: [ExternalLinkDirective],
})
export class ExternalLinkModule {}
