import { Component } from '@angular/core';
import { ArtifactManagementService } from './artifact-management.service';
import { SnackbarService } from '../../shared/components/snackbar/snackbar.service';
import { environment } from '../../../environments/environment';
import { filter, take } from 'rxjs/operators';
import { ConfirmDialogService } from '../../shared/components/confirm-dialog/confirm-dialog.service';

@Component({
    selector: 'app-artifact-management',
    templateUrl: './artifact-management.component.html',
    styleUrls: ['./artifact-management.component.scss'],
})
export class ArtifactManagementComponent {
    constructor(
        private _fileArtifactManagementService: ArtifactManagementService,
        private _snackbarService: SnackbarService,
        private _confirmDialogService: ConfirmDialogService,
    ) {}

    generateFileHashes() {
        this._fileArtifactManagementService
            .calculateFileHashForArtifactsMissingFileHash()
            .subscribe((msg) => this.showSuccessNotification(msg));
    }

    refreshRiskNetwork() {
        this._fileArtifactManagementService
            .refreshRiskNetworkData()
            .subscribe((msg) => this.showSuccessNotification(msg));
    }

    displayArtifactTransferConfirmation(): void {
        this._confirmDialogService
            .confirm({
                title: 'Transfer Artifacts',
                subtitle: `BaseUrl: ${environment.baseUrl}`,
                message:
                    'Are you sure you want to begin transferring all Public/Viso-owned artifacts to their respective Trust Profiles?',
                confirmLabel: 'Yes, do it!',
            })
            .pipe(
                filter((result) => result),
                take(1),
            )
            .subscribe(() => this.transferArtifactsToTrustProfiles());
    }

    private transferArtifactsToTrustProfiles(): void {
        this._fileArtifactManagementService
            .transferPublicArtifactsToTrustProfiles()
            .subscribe((msg) => this.showSuccessNotification(msg));
    }

    private showSuccessNotification(successMsg: string) {
        this._snackbarService.success(successMsg);
    }
}
