import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { VendorSearchUtilsService } from '../../../entities/org/vendor-search-utils.service';
import { BaseVendorComponent } from '../models/base-vendor-component';
import { CompleteVendorSearchResult } from '../models/vendor-search-result';
import { VisoUserRole } from '../../../entities/viso-user';

@Component({
    selector: 'app-vendor-tile',
    templateUrl: './vendor-tile.component.html',
    styleUrls: ['./vendor-tile.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VendorTileComponent extends BaseVendorComponent {
    @Output()
    addRelationship = new EventEmitter<CompleteVendorSearchResult>();

    @Output()
    viewProfile = new EventEmitter<CompleteVendorSearchResult>();

    constructor(vendorSearchUtilsService: VendorSearchUtilsService) {
        super(vendorSearchUtilsService);
    }

    protected readonly Roles = VisoUserRole;
}
