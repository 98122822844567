import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { LifecycleManagementFormGroup } from '../../models/lifecycle-management.model';
import { ReviewFrequencyLabels } from '../../../relationships/models/review-frequency';
import { takeUntil } from 'rxjs/operators';
import { RecertificationType, ReviewFrequency, ReviewFrequencyMonths } from '@entities/relationship';
import { merge, Observable, Subject } from 'rxjs';
import moment from 'moment';
import { select, Store } from '@ngrx/store';
import { getUserIsTrial } from '../../../session/redux/session.selectors';
import { TALK_TO_SALES_URL } from '@shared/constants/url.constants';
import { PrimaryVendorContact } from '../../../primary-vendor-contact';
import { VisoUser } from '@entities/viso-user';

@Component({
    selector: 'app-lifecycle-management-form',
    templateUrl: './lifecycle-management-form.component.html',
    styleUrls: ['./lifecycle-management-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LifecycleManagementFormComponent implements OnInit, OnDestroy {
    @Input({ required: true })
    lifecycleManagementFormGroup: FormGroup<LifecycleManagementFormGroup>;

    @Input({ required: true })
    dateOfNextArtifactExpiration?: Date;

    @Input({ required: true })
    latestCompletedAssessmentDate?: Date;

    @Input({ required: true })
    relationshipHasPrimaryContact: boolean;

    @Input({ required: true })
    businessOwner: VisoUser;

    @Input({ required: true })
    primaryContact: PrimaryVendorContact;

    @Input()
    licenseHasManagedVendorsAvailable: boolean;

    userIsTrial$: Observable<boolean>;

    TALK_TO_SALES_URL = TALK_TO_SALES_URL;
    ReviewFrequency = ReviewFrequency;
    ReviewFrequencyLabels = ReviewFrequencyLabels;
    RecertificationType = RecertificationType;
    private _unsub$ = new Subject<void>();

    get showArtifactUpdateOptions(): boolean {
        return this.lifecycleManagementFormGroup.controls.artifactUpdateSettings.controls.artifactUpdateEnabled.value;
    }

    get showLatestExpiringArtifactDate(): boolean {
        return (
            this.lifecycleManagementFormGroup.controls.artifactUpdateSettings.controls.artifactUpdateEnabled.value &&
            !!this.dateOfNextArtifactExpiration
        );
    }

    get showRecertificationOptions(): boolean {
        return this.lifecycleManagementFormGroup.controls.recertificationSettings.controls.recertificationEnabled.value;
    }

    get nextRecertificationDate(): Date {
        return this.lifecycleManagementFormGroup.controls.recertificationSettings.controls.recertificationDate.value;
    }

    get basedOnDate(): Date {
        return this.lifecycleManagementFormGroup.controls.recertificationSettings.controls.basedOnDate.value;
    }

    get showRecertificationDateHint(): boolean {
        const recertificationSettings = this.lifecycleManagementFormGroup.controls.recertificationSettings;
        return (
            recertificationSettings.controls.recertificationEnabled.value &&
            !!recertificationSettings.controls.basedOnDate.value
        );
    }

    get showNoPreviousAssessmentDateHint(): boolean {
        return (
            !this.latestCompletedAssessmentDate &&
            this.lifecycleManagementFormGroup.controls.recertificationSettings.controls.recertificationType.value ===
                RecertificationType.AUTOMATIC
        );
    }

    get showDateResetLink(): boolean {
        if (!!this.latestCompletedAssessmentDate) {
            return !moment(this.basedOnDate).isSame(this.latestCompletedAssessmentDate, 'date');
        }
        return false;
    }

    get recertificationDate(): Date {
        return this.lifecycleManagementFormGroup.controls.recertificationSettings.controls.recertificationDate.value;
    }

    constructor(private _store$: Store) {}

    ngOnInit(): void {
        this.userIsTrial$ = this._store$.pipe(select(getUserIsTrial));

        const artifactUpdateSettingsFormGroup = this.lifecycleManagementFormGroup.controls.artifactUpdateSettings;
        artifactUpdateSettingsFormGroup.controls.artifactUpdateEnabled.valueChanges
            .pipe(takeUntil(this._unsub$))
            .subscribe((enabled) => {
                let method = RecertificationType.NONE;
                if (enabled) {
                    method = !this.relationshipHasPrimaryContact
                        ? RecertificationType.MANUAL
                        : RecertificationType.AUTOMATIC;
                }
                artifactUpdateSettingsFormGroup.controls.artifactUpdateType.setValue(method);
            });

        const recertificationSettingsFormGroup = this.lifecycleManagementFormGroup.controls.recertificationSettings;
        recertificationSettingsFormGroup.controls.recertificationEnabled.valueChanges
            .pipe(takeUntil(this._unsub$))
            .subscribe((enabled) => {
                let method = RecertificationType.NONE;
                if (enabled) {
                    method = !this.relationshipHasPrimaryContact
                        ? RecertificationType.MANUAL
                        : RecertificationType.AUTOMATIC;
                }
                const reviewFrequency = enabled ? ReviewFrequency.ANNUAL : null;
                recertificationSettingsFormGroup.controls.recertificationType.setValue(method);
                recertificationSettingsFormGroup.controls.reviewFrequency.setValue(reviewFrequency);
                if (!enabled) {
                    recertificationSettingsFormGroup.controls.recertificationDate.reset();
                }
            });

        merge(
            recertificationSettingsFormGroup.controls.basedOnDate.valueChanges,
            recertificationSettingsFormGroup.controls.reviewFrequency.valueChanges,
        )
            .pipe(takeUntil(this._unsub$))
            .subscribe(() => {
                const basedOnDate = recertificationSettingsFormGroup.controls.basedOnDate.value;
                const reviewFrequency = recertificationSettingsFormGroup.controls.reviewFrequency.value;
                const newRecertificationDate = moment(basedOnDate)
                    .add(ReviewFrequencyMonths[reviewFrequency], 'months')
                    .toDate();
                recertificationSettingsFormGroup.controls.recertificationDate.setValue(newRecertificationDate, {
                    emitEvent: false,
                });
            });
    }

    resetBasedOnDate(): void {
        this.lifecycleManagementFormGroup.controls.recertificationSettings.controls.basedOnDate.setValue(
            this.latestCompletedAssessmentDate,
        );
    }

    ngOnDestroy(): void {
        this._unsub$.next();
    }
}
