import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { AppState } from '../../../trust-profile/trust-profile-common/redux/trust-profile.state';
import { VanityDomainsState, vanityDomainStateKey } from './vanity-domain.state';
import { VanityDomainView } from '@entities/vanity-domain';

export const getVanityDomainsState: MemoizedSelector<AppState, VanityDomainsState> =
    createFeatureSelector<VanityDomainsState>(vanityDomainStateKey);

export const getVanityCname: MemoizedSelector<AppState, string> = createSelector(
    getVanityDomainsState,
    (state) => state.cname,
);

export const getVanityDomain: MemoizedSelector<AppState, VanityDomainView> = createSelector(
    getVanityDomainsState,
    (state) => state.vanityDomain,
);

export const getOrgDomains: MemoizedSelector<AppState, string[]> = createSelector(
    getVanityDomainsState,
    (state) => state.orgDomains,
);
