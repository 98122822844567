import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Tag } from '../../../entities/tag';
import { AppState } from '../../../shared/redux/state';
import { deleteTagRequest, deleteTagRequestSuccess, TagActions } from '../redux/tag.actions';

@Component({
    selector: 'app-tag-delete-dialog',
    templateUrl: './tag-delete-dialog.component.html',
    styleUrls: ['./tag-delete-dialog.component.scss'],
})
export class TagDeleteDialogComponent implements OnInit, OnDestroy {
    @Input() selectedTag: Tag;
    TagActionsRef = TagActions;

    private _unsub$ = new Subject<void>();

    constructor(
        private _activeModal: NgbActiveModal,
        private _store$: Store<AppState>,
        private _actions$: Actions,
    ) {}

    ngOnInit(): void {
        this._actions$
            .pipe(ofType(deleteTagRequestSuccess), takeUntil(this._unsub$))
            .subscribe((_) => this._activeModal.dismiss());
    }

    ngOnDestroy(): void {
        this._unsub$.next();
    }

    deleteTag(id: string): void {
        this._store$.dispatch(deleteTagRequest({ id }));
    }

    clear(): void {
        this._activeModal.dismiss('cancel');
    }
}
