import { Injectable, Type } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { BusinessUnitService, BusinessUnitManagementView } from '../../entities/business-unit';
import { NgbModalWrapperService } from '../../shared';
import { BusinessUnitDialogComponent } from './business-unit-dialog.component';

@Injectable()
export class BusinessUnitPopupService {
    private ngbModalRef: NgbModalRef;

    constructor(
        private modalService: NgbModalWrapperService,
        private businessUnitService: BusinessUnitService,
    ) {
        this.ngbModalRef = null;
    }

    open(component: Type<BusinessUnitDialogComponent>, id?: number | any): Promise<NgbModalRef> {
        return new Promise<NgbModalRef>((resolve, reject) => {
            const isOpen = this.ngbModalRef !== null;
            if (isOpen) {
                resolve(this.ngbModalRef);
            }

            if (id) {
                this.businessUnitService.findForManagement(id).subscribe((bu: BusinessUnitManagementView) => {
                    this.ngbModalRef = this.businessUnitModalRef(component, bu);
                    resolve(this.ngbModalRef);
                });
            } else {
                // setTimeout used as a workaround for getting ExpressionChangedAfterItHasBeenCheckedError
                setTimeout(() => {
                    this.ngbModalRef = this.businessUnitModalRef(component, null);
                    resolve(this.ngbModalRef);
                }, 0);
            }
        });
    }

    businessUnitModalRef(
        component: Type<BusinessUnitDialogComponent>,
        businessUnit: BusinessUnitManagementView,
    ): NgbModalRef {
        // We don't need to add `id` to metadata, since it's part of the route params that trigger opening this modal.
        const modalRef = this.modalService.open(component, { businessUnit }, { size: 'md', backdrop: 'static' });
        modalRef.result.then(
            (result) => {
                this.ngbModalRef = null;
            },
            (reason) => {
                this.ngbModalRef = null;
            },
        );
        return modalRef;
    }
}
