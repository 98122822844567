<header class="content-heading">
    <section>
        License Management
        <small>Manage licenses for Orgs</small>
    </section>
</header>

<div class="pb-4">
    <div *ngIf="orgs$ | async as orgs">
        <form class="org-section" name="clientProfileForm" novalidate [formGroup]="licenseManagementForm">
            <span class="org-section-label" for="org">Organization</span>
            <select class="form-control" id="org" formControlName="orgId">
                <option [ngValue]="null">Select an organization</option>
                <option [ngValue]="clientOrg.id" *ngFor="let clientOrg of orgs">
                    {{ clientOrg.name }}
                </option>
            </select>
        </form>
    </div>
</div>

<div class="container float-start">
    <div class="row">
        <div class="col-md">
            <div class="card card-default">
                <div class="card-header">
                    <p class="card-title">Update Org Licenses and Restrictions</p>
                </div>
                <div class="card-body">
                    <form
                        name="editForm"
                        role="form"
                        novalidate
                        (ngSubmit)="submitLicenseChanges()"
                        [formGroup]="licenseManagementForm"
                    >
                        <div class="no-org-selected mt-4" *ngIf="!orgIdValue">
                            <i class="fa fa-info-circle"></i>
                            <p>Please select an organization</p>
                        </div>

                        <section *ngIf="loadingLicense">
                            <div class="d-flex align-items-center justify-content-center">
                                <div class="sk-spinner sk-spinner-pulse"></div>
                            </div>
                        </section>

                        <ng-container *ngIf="orgIdValue && !loadingLicense">
                            <div class="form-group">
                                <label for="field_license" class="form-control-label"> Current License: </label>
                                <select
                                    class="form-control"
                                    formControlName="licenseType"
                                    (change)="onLicenseTypeChange($event)"
                                >
                                    <option
                                        [value]="licenseType.value"
                                        *ngFor="let licenseType of LicenseType | enumToArray: LicenseTypeLabel"
                                    >
                                        {{ licenseType.label }}
                                    </option>
                                </select>
                            </div>
                            <div class="form-group">
                                <label class="form-control-label">Contract Start Date: </label>
                                <div class="date-form-group">
                                    <input
                                        id="contractStartDate"
                                        matInput
                                        [matDatepicker]="contractStartDatePicker"
                                        class="form-control"
                                        placeholder="yyyy-mm-dd"
                                        formControlName="contractStartDate"
                                    />
                                    <mat-datepicker-toggle [for]="contractStartDatePicker"></mat-datepicker-toggle>
                                    <mat-datepicker #contractStartDatePicker></mat-datepicker>
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="form-control-label">Contract End Date: </label>
                                <div class="date-form-group">
                                    <input
                                        id="contractEndDate"
                                        matInput
                                        [matDatepicker]="contractEndDatePicker"
                                        class="form-control"
                                        placeholder="yyyy-mm-dd"
                                        formControlName="contractEndDate"
                                    />
                                    <mat-datepicker-toggle [for]="contractEndDatePicker"></mat-datepicker-toggle>
                                    <mat-datepicker #contractEndDatePicker></mat-datepicker>
                                </div>
                            </div>
                            <hr />
                            <div class="form-group">
                                <label class="form-control-label" for="field_maxRelationshipsAssessed">
                                    Max Relationships Assessed
                                </label>
                                <input
                                    class="form-control"
                                    (wheel)="$event.preventDefault()"
                                    type="number"
                                    formControlName="maxRelationshipsAssessed"
                                    min="-1"
                                />
                            </div>
                            <div class="form-group">
                                <label class="form-control-label">
                                    Current Relationships Assessed Count
                                    <span class="text-muted"
                                        >({{ licenseManagementForm.controls.relationshipsAssessedCount.value }})</span
                                    >
                                </label>
                            </div>
                            <hr />
                            <div class="form-group">
                                <label class="form-control-label" for="field_maxRelationshipsCreated">
                                    Max Relationships Created
                                </label>
                                <input
                                    class="form-control"
                                    (wheel)="$event.preventDefault()"
                                    type="number"
                                    formControlName="maxRelationshipsCreated"
                                    min="-1"
                                />
                            </div>
                            <div class="form-group">
                                <label class="form-control-label">
                                    Current Relationships Created Count
                                    <span class="text-muted"
                                        >({{ licenseManagementForm.controls.relationshipsCreatedCount.value }})</span
                                    >
                                </label>
                            </div>
                            <hr />
                            <div class="form-group">
                                <label class="form-control-label" for="field_maxRelationshipsOnboarded">
                                    Max Third Party Onboarded
                                </label>
                                <input
                                    class="form-control"
                                    (wheel)="$event.preventDefault()"
                                    type="number"
                                    formControlName="maxRelationshipsOnboarded"
                                    min="-1"
                                />
                            </div>
                            <div class="form-group">
                                <label class="form-control-label">
                                    Current Onboarded Count
                                    <span class="text-muted"
                                        >({{ licenseManagementForm.controls.relationshipsOnboardedCount.value }})</span
                                    >
                                </label>
                            </div>
                            <hr />
                            <div class="form-group">
                                <label class="form-control-label" for="field_maxRelationshipsWithLifecycleManagement">
                                    Max Relationships With Lifecycle Management Enabled
                                </label>
                                <input
                                    class="form-control"
                                    (wheel)="$event.preventDefault()"
                                    type="number"
                                    formControlName="maxRelationshipsWithLifecycleManagement"
                                    min="-1"
                                />
                            </div>
                            <div class="form-group">
                                <label class="form-control-label">
                                    Current Relationships With Lifecycle Management Enabled Count
                                    <span class="text-muted"
                                        >({{
                                            licenseManagementForm.controls.relationshipsWithLifecycleManagementCount
                                                .value
                                        }})</span
                                    >
                                </label>
                            </div>
                            <hr />
                            <div class="form-group">
                                <label class="form-control-label" for="field_maxConciergeAssessments">
                                    Max Concierge Assessments
                                </label>
                                <input
                                    class="form-control"
                                    (wheel)="$event.preventDefault()"
                                    type="number"
                                    formControlName="maxConciergeAssessments"
                                    min="-1"
                                />
                            </div>
                            <div class="form-group">
                                <label class="form-control-label">
                                    Current Concierge Assessment Count
                                    <span class="text-muted"
                                        >({{ licenseManagementForm.controls.conciergeAssessmentCount.value }})</span
                                    >
                                </label>
                            </div>
                            <hr />
                            <div class="form-group">
                                <label class="form-control-label" for="field_maxSupplementalQuestionnaires">
                                    Max Supplemental Questionnaires
                                </label>
                                <input
                                    class="form-control"
                                    (wheel)="$event.preventDefault()"
                                    type="number"
                                    formControlName="maxSupplementalQuestionnaires"
                                    min="-1"
                                />
                            </div>
                            <div class="form-group">
                                <label class="form-control-label">
                                    Current Supplemental Questionnaires Count
                                    <span class="text-muted"
                                        >({{
                                            licenseManagementForm.controls.supplementalQuestionnairesCount.value
                                        }})</span
                                    >
                                </label>
                            </div>
                            <hr />
                            <div class="form-group">
                                <label class="form-control-label" for="field_maxTrustQuestionnaires">
                                    Max Trust Questionnaires
                                </label>
                                <input
                                    class="form-control"
                                    type="number"
                                    (wheel)="$event.preventDefault()"
                                    formControlName="maxTrustQuestionnaires"
                                    min="-1"
                                />
                            </div>
                            <div class="form-group">
                                <label class="form-control-label">
                                    Current Trust Questionnaires Count
                                    <span class="text-muted"
                                        >({{ licenseManagementForm.controls.trustQuestionnairesCount.value }})</span
                                    >
                                </label>
                            </div>
                            <hr />
                            <div class="form-group">
                                <label class="form-control-label" for="field_maxTprmAiQa">
                                    Max TPRM AI Q&A Questions
                                </label>
                                <input
                                    class="form-control"
                                    (wheel)="$event.preventDefault()"
                                    type="number"
                                    formControlName="maxTprmAiQa"
                                    min="-1"
                                />
                            </div>
                            <div class="form-group">
                                <label class="form-control-label">
                                    Current TPRM AI Q&A Question Count
                                    <span class="text-muted"
                                        >({{ licenseManagementForm.controls.tprmAiQaCount.value }})</span
                                    >
                                </label>
                            </div>
                            <hr />
                            <div class="form-group">
                                <label class="form-control-label" for="field_maxTrustAiQa">
                                    Max Trust AI Q&A Questions
                                </label>
                                <input
                                    class="form-control"
                                    (wheel)="$event.preventDefault()"
                                    type="number"
                                    formControlName="maxTrustAiQa"
                                    min="-1"
                                />
                            </div>
                            <div class="form-group">
                                <label class="form-control-label">
                                    Current Trust AI Q&A Question Count
                                    <span class="text-muted"
                                        >({{ licenseManagementForm.controls.trustAiQaCount.value }})</span
                                    >
                                </label>
                            </div>
                            <ng-container *ngIf="editing">
                                <button
                                    type="submit"
                                    [disabled]="!licenseManagementForm.valid"
                                    class="me-2"
                                    mat-button
                                    visoButton="primary"
                                >
                                    <em class="fa fa-save me-2"></em>Submit
                                </button>
                                <button (click)="cancel()" type="button" mat-button visoButton="warning">
                                    <em class="fa fa-undo me-2"></em>Cancel
                                </button>
                            </ng-container>

                            <button
                                *ngIf="!editing"
                                (click)="edit()"
                                [disabled]="!licenseManagementForm.valid"
                                mat-button
                                visoButton="primary"
                            >
                                <em class="fa fa-edit me-2"></em>Edit
                            </button>
                        </ng-container>
                    </form>
                </div>
            </div>
        </div>
        <div class="col-md">
            <div class="card card-default">
                <div class="card-header">
                    <p class="card-title">Glossary</p>
                </div>
                <div class="card-body">
                    <h6>Licenses</h6>
                    <ul>
                        <li *ngFor="let licenseDescription of licenseDescriptions">
                            <p>
                                <span class="text-bold">{{ licenseDescription.license }}:</span>
                                {{ licenseDescription.description }}
                            </p>
                        </li>
                    </ul>
                    <h6>Max Relationships Assessed</h6>
                    <ul>
                        <li>
                            The total number of Relationships with > 0 Non-Cancelled assessments available for the Org.
                        </li>
                    </ul>
                    <h6>Current Relationships Assessed Count</h6>
                    <ul>
                        <li>The current count of Relationships with > 0 Non-Cancelled Assessments for this Org.</li>
                    </ul>
                    <h6>Max Relationships Created</h6>
                    <ul>
                        <li>The total number of Relationships this Org can create.</li>
                    </ul>
                    <h6>Current Relationships Created Count</h6>
                    <ul>
                        <li>The current count of Relationships created by the Org</li>
                    </ul>
                    <h6>Max Third Party Onboarded</h6>
                    <ul>
                        <li>The total number of Onboarded Third Parties allowed for an Org.</li>
                    </ul>
                    <h6>Current Third Party Onboarded Count</h6>
                    <ul>
                        <li>The current count of Third Parties onboarded by the Org.</li>
                    </ul>
                    <h6>Max Relationships With Lifecycle Management Enabled</h6>
                    <ul>
                        <li>
                            The total number of Relationships with Lifecycle Management enabled for the selected Org.
                        </li>
                    </ul>
                    <h6>Current Relationships With Lifecycle Management Enabled Count</h6>
                    <ul>
                        <li>
                            The current count of Relationships with Lifecycle Management enabled for the selected Org.
                        </li>
                    </ul>
                    <h6>Max Supplemental Questionnaires</h6>
                    <ul>
                        <li>The total number of Supplemental Questionnaires an Org can upload to their Org Profile.</li>
                    </ul>
                    <h6>Current Supplemental Questionnaires Count</h6>
                    <ul>
                        <li>
                            The current count of Supplemental Questionnaires uploaded by an Org to their Org Profile.
                        </li>
                    </ul>
                    <h6>Max Trust Questionnaires</h6>
                    <ul>
                        <li>The total number of Trust Questionnaires an Org can upload.</li>
                    </ul>
                    <h6>Current Trust Questionnaires Count</h6>
                    <ul>
                        <li>The current count of Trust Questionnaires uploaded by an Org.</li>
                    </ul>
                    <h6>Max TPRM AI Q&A Questions</h6>
                    <ul>
                        <li>The total number of TPRM AI Q&A questions can be asked by an Org.</li>
                    </ul>
                    <h6>Current TPRM AI Q&A Count</h6>
                    <ul>
                        <li>The current count of TPRM AI Q&A questions asked by an Org.</li>
                    </ul>
                    <h6>Max Trust AI Q&A Questions</h6>
                    <ul>
                        <li>The total number of Trust AI Q&A questions can be asked by an Org.</li>
                    </ul>
                    <h6>Current Trust AI Q&A Count</h6>
                    <ul>
                        <li>The current count of Trust AI Q&A questions asked by an Org.</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
