import * as fromRoot from '../../../shared/redux/state';
import { UserProfile, VisoUser } from '@entities/viso-user';
import { ClientProfile } from '../../../admin/client-profile/client-profile.model';

export const sessionStateKey: string = 'session';

export interface SessionState {
    isAuthenticated: boolean;
    account: VisoUser;
    clientProfile: ClientProfile;
    sessionLoaded: boolean;
    userProfile: UserProfile;
    userIsTrial: boolean;
}

export const initialState: SessionState = {
    isAuthenticated: false,
    account: null,
    clientProfile: null,
    sessionLoaded: false,
    userProfile: null,
    userIsTrial: false,
};

export interface AppState extends fromRoot.AppState {
    session: SessionState;
}
