import { Routes } from '@angular/router';

import { QuestionnaireComponent } from './questionnaire.component';
import { AuthGuard } from '../../shared';
import { QuestionnaireEditComponent } from './questionnaire-edit/questionnaire-edit.component';
import { VisoUserRole } from '../../entities/viso-user';
import { FeatureFlags } from '../../shared/enums/feature-flags';
import { FeatureFlagGuard } from '../../shared/guards/feature-flag.guard';
import { questionnaireResolver } from './resolvers/questionnaire.resolver';
import { relationshipNameResolver } from './resolvers/relationship-name.resolver';
import { questionnaireReferencedArtifactsResolver } from './resolvers/questionnaire-referenced-artifacts.resolver';

export enum QuestionnaireRouteType {
    RELATIONSHIP = 'RELATIONSHIP',
    TRUST_QUESTIONNAIRE = 'TRUST_QUESTIONNAIRE',
    SUPPLEMENTAL_QUESTIONNAIRES = 'SUPPLEMENTAL_QUESTIONNAIRES',
    GENERIC_QUESTIONNAIRE = 'GENERIC_QUESTIONNAIRE',
}

export const questionnaireRoutes: Routes = [
    {
        path: 'requests/:relationshipId/questionnaire/:questionnaireId',
        component: QuestionnaireComponent,
        data: {
            questionnaireRouteType: QuestionnaireRouteType.RELATIONSHIP,
        },
        resolve: {
            questionnaire: questionnaireResolver,
            artifacts: questionnaireReferencedArtifactsResolver,
            relationshipName: relationshipNameResolver,
        },
        canActivate: [AuthGuard],
    },
    {
        path: 'intelligent-questionnaire-response/trust-questionnaire/:questionnaireId',
        component: QuestionnaireComponent,
        data: {
            authorities: [VisoUserRole.OrgAdmin],
            featureFlag: FeatureFlags.TRUST,
            questionnaireRouteType: QuestionnaireRouteType.TRUST_QUESTIONNAIRE,
        },
        resolve: {
            questionnaire: questionnaireResolver,
            artifacts: questionnaireReferencedArtifactsResolver,
        },
        canActivate: [AuthGuard, FeatureFlagGuard],
    },
    {
        path: 'your-organization/supplemental-questionnaire/:questionnaireId/edit',
        component: QuestionnaireEditComponent,
        data: {
            questionnaireRouteType: QuestionnaireRouteType.SUPPLEMENTAL_QUESTIONNAIRES,
        },
        canActivate: [AuthGuard],
    },
    {
        path: 'questionnaire/:questionnaireId',
        component: QuestionnaireComponent,
        data: {
            questionnaireRouteType: QuestionnaireRouteType.GENERIC_QUESTIONNAIRE,
        },
        resolve: {
            questionnaire: questionnaireResolver,
            artifacts: questionnaireReferencedArtifactsResolver,
        },
        canActivate: [AuthGuard],
    },
];
