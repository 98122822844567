import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataSensitivityLevelPipe } from './data-sensitivity-level.pipe';

@NgModule({
    declarations: [DataSensitivityLevelPipe],
    imports: [CommonModule],
    exports: [DataSensitivityLevelPipe],
    providers: [DataSensitivityLevelPipe],
})
export class DataSensitivityLevelPipeModule {}
