import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CheckboxGroupItemDirective } from './checkbox-group-item/checkbox-group-item.directive';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { CheckboxGroupComponent } from './checkbox-group/checkbox-group.component';
import { CallbackPipeModule } from '../pipes/callback-pipe';
import { FormsModule } from '@angular/forms';

@NgModule({
    declarations: [CheckboxGroupComponent, CheckboxGroupItemDirective],
    imports: [CommonModule, MatCheckboxModule, CallbackPipeModule, FormsModule],
    exports: [CheckboxGroupComponent, CheckboxGroupItemDirective],
})
export class VisoCheckboxGroupModule {}
