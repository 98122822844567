import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { DomainVerification } from '../../../../entities/domain-verification';
import { SnackbarService } from '../../../../shared/components/snackbar/snackbar.service';

@Component({
    templateUrl: './view-dkim-configuration-dialog.component.html',
    styleUrls: ['./view-dkim-configuration-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ViewDkimConfigurationDialogComponent {
    @Input({ required: true })
    verification: DomainVerification;

    constructor(private _snackbarService: SnackbarService) {}

    private copyToClipboard(text: string) {
        navigator.clipboard.writeText(text);
        this._snackbarService.success('Copied to clipboard!');
    }

    copyDkimPendingTextValueToClipboard(): void {
        this.copyToClipboard(this.verification.dkimPendingTextValue);
    }

    copyDkimPendingHostToClipboard(): void {
        this.copyToClipboard(this.verification.dkimPendingHost);
    }

    copyReturnPathDomainToClipboard() {
        this.copyToClipboard(this.verification.returnPathDomain);
    }

    copyReturnCnamePathValueToClipboard(): void {
        this.copyToClipboard(this.verification.returnPathCnameValue);
    }
}
