import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { RouterNavigationAction, ROUTER_NAVIGATED } from '@ngrx/router-store';
import { of } from 'rxjs';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';
import { AttachmentService } from '../../../../entities/attachment';
import { ConfirmDialogService } from '../../../../shared/components/confirm-dialog/confirm-dialog.service';
import { SnackbarService } from '../../../../shared/components/snackbar/snackbar.service';
import { saveFileFromResBlob } from '../../../../shared/file-download/redux/actions';
import { NgbModalWrapperService } from '../../../../shared/modal/ngb-modal-wrapper.service';
import { UploadAttachmentsModalComponent } from '../../../../shared/upload-attachments';
import {
    deleteRequestAttachmentRequest,
    deleteRequestAttachmentRequestFailed,
    deleteRequestAttachmentRequestSuccess,
    getRequestAttachmentRequest,
    getRequestAttachmentRequestSuccess,
    downloadAttachment,
    downloadAttachmentFailed,
    downloadRelationshipAttachmentsAsZip,
    downloadRelationshipAttachmentsAsZipFailed,
    getRequestAttachmentsRequestFailed,
    uploadRequestAttachmentRequest,
    uploadRequestAttachmentRequestFailed,
    uploadRequestAttachmentRequestSuccess,
} from '../actions/attachments.actions';

@Injectable()
export class AttachmentEffects {
    getRequestAttachmentsRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(getRequestAttachmentRequest),
            switchMap(({ requestId }) =>
                this._attachmentService.findByRelationship(requestId).pipe(
                    map((attachments) => getRequestAttachmentRequestSuccess({ attachments })),
                    catchError(() => of(getRequestAttachmentsRequestFailed())),
                ),
            ),
        ),
    );

    deleteRequestAttachmentRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(deleteRequestAttachmentRequest),
            switchMap(({ requestId, attachmentId }) =>
                this._confirmDialogService
                    .confirm({
                        title: 'Delete Attachment',
                        message: 'Are you sure you want to permanently delete this attachment?',
                        confirmLabel: 'Yes, do it!',
                    })
                    .pipe(
                        filter((result) => result),
                        switchMap(() =>
                            this._attachmentService.deleteAttachment(requestId, attachmentId).pipe(
                                map(() => deleteRequestAttachmentRequestSuccess({ requestId })),
                                catchError(() => of(deleteRequestAttachmentRequestFailed())),
                            ),
                        ),
                    ),
            ),
        ),
    );

    downloadAttachment$ = createEffect(() =>
        this._actions$.pipe(
            ofType(downloadAttachment),
            switchMap(({ requestId, attachmentId }) =>
                this._attachmentService.downloadAttachment(requestId, attachmentId).pipe(
                    map((fileResponseBlob) => saveFileFromResBlob({ fileResponseBlob })),
                    catchError(() => of(downloadAttachmentFailed())),
                ),
            ),
        ),
    );

    downloadRelationshipAttachmentsAsZip$ = createEffect(() =>
        this._actions$.pipe(
            ofType(downloadRelationshipAttachmentsAsZip),
            switchMap(({ requestId, attachmentIds }) =>
                this._attachmentService.downloadAttachmentsOnRelationshipAsZip(requestId, attachmentIds).pipe(
                    map((fileResponseBlob) => saveFileFromResBlob({ fileResponseBlob })),
                    catchError((error) => of(downloadRelationshipAttachmentsAsZipFailed())),
                ),
            ),
        ),
    );

    uploadRequestAttachmentRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(uploadRequestAttachmentRequest),
            switchMap(({ requests, requestId }) =>
                this._attachmentService.uploadAttachment(requestId, requests).pipe(
                    map(() => uploadRequestAttachmentRequestSuccess({ requestId })),
                    catchError((error) => of(uploadRequestAttachmentRequestFailed())),
                ),
            ),
        ),
    );

    displaySnackbarForDocumentsUploaded$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(uploadRequestAttachmentRequestSuccess),
                tap(() => this._snackbarService.success('Documents uploaded')),
            ),
        { dispatch: false },
    );

    openAddAttachmentModalForRequest$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(ROUTER_NAVIGATED),
                map((action: RouterNavigationAction) =>
                    action.payload.routerState.root.children.find((route) => route.outlet === 'popup'),
                ),
                filter((route) => !!route && route.routeConfig.path === 'relationships/:requestId/add-attachment'),
                map((route) => route.params.requestId),
                tap((requestId) =>
                    this._modalService.open<UploadAttachmentsModalComponent>(
                        UploadAttachmentsModalComponent,
                        {
                            actions: {
                                requestAction: uploadRequestAttachmentRequest,
                                requestSuccessAction: uploadRequestAttachmentRequestSuccess,
                                requestFailedAction: uploadRequestAttachmentRequestFailed,
                                requestExtraParams: { requestId },
                            },
                        },
                        { size: 'xl' },
                    ),
                ),
            ),
        { dispatch: false },
    );

    constructor(
        private _actions$: Actions,
        private _attachmentService: AttachmentService,
        private _confirmDialogService: ConfirmDialogService,
        private _modalService: NgbModalWrapperService,
        private _snackbarService: SnackbarService,
    ) {}
}
