import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { DateUtilsService } from '../../shared/utils/date-utils.service';
import { VisoUser } from '../../entities/viso-user';
import { createRequestOption } from '../../shared';

export type EntityResponseType = HttpResponse<VisoUser>;

@Injectable({
    providedIn: 'root',
})
export class ContactService {
    private resourceUrl = 'api/contacts';

    constructor(
        private http: HttpClient,
        private _dateUtilsService: DateUtilsService,
    ) {}

    create(contact: VisoUser): Observable<EntityResponseType> {
        const copy = this.convert(contact);
        return this.http
            .post<VisoUser>(this.resourceUrl, copy, {
                observe: 'response',
            })
            .pipe(map((res: EntityResponseType) => this.convertResponse(res)));
    }

    update(contact: VisoUser): Observable<EntityResponseType> {
        const copy = this.convert(contact);
        return this.http
            .put<VisoUser>(this.resourceUrl, copy, {
                observe: 'response',
            })
            .pipe(map((res: EntityResponseType) => this.convertResponse(res)));
    }

    find(id: number): Observable<EntityResponseType> {
        return this.http
            .get<VisoUser>(`${this.resourceUrl}/${id}`, { observe: 'response' })
            .pipe(map((res: EntityResponseType) => this.convertResponse(res)));
    }

    findAllForCurrentUserOrg(req?: any, clientId?: number): Observable<HttpResponse<VisoUser[]>> {
        let options = createRequestOption(req);
        if (clientId !== undefined) {
            options = options.set('clientId', clientId);
        }
        return this.http
            .get<VisoUser[]>(this.resourceUrl, { params: options, observe: 'response' })
            .pipe(map((res: HttpResponse<VisoUser[]>) => this.convertArrayResponse(res)));
    }

    delete(id: number): Observable<HttpResponse<void>> {
        return this.http.delete<void>(`${this.resourceUrl}/${id}`, { observe: 'response' });
    }

    unsubscribe(contactId: number, relationshipId: number): Observable<HttpResponse<any>> {
        return this.http.delete<any>(`${this.resourceUrl}/${contactId}/unsubscribe`, {
            params: new HttpParams().set('relationshipId', String(relationshipId)),
            observe: 'response',
        });
    }

    subscribe(contactId: number, relationshipId: number) {
        return this.http.post<any>(
            `${this.resourceUrl}/${contactId}/subscribe`,
            {
                observe: 'response',
            },
            { params: new HttpParams().set('relationshipId', String(relationshipId)) },
        );
    }

    reassignRelationships(
        fromContactId: number,
        toContactId: number,
        reassignOwnerships: boolean,
        reassignSubscriptions: boolean,
    ): Observable<HttpResponse<any>> {
        return this.http.put<any>(
            `${this.resourceUrl}/reassign`,
            { fromContactId, toContactId, reassignOwnerships, reassignSubscriptions },
            {
                observe: 'response',
            },
        );
    }

    private convertResponse(res: EntityResponseType): EntityResponseType {
        const body: VisoUser = this.convertItemFromServer(res.body);
        return res.clone({ body });
    }

    private convertArrayResponse(res: HttpResponse<VisoUser[]>): HttpResponse<VisoUser[]> {
        const jsonResponse: VisoUser[] = res.body;
        const body: VisoUser[] = [];
        for (let i = 0; i < jsonResponse.length; i++) {
            body.push(this.convertItemFromServer(jsonResponse[i]));
        }
        return res.clone({ body });
    }

    /**
     * Convert a returned JSON object to VisoUser.
     */
    private convertItemFromServer(visoUser: VisoUser): VisoUser {
        const copy: VisoUser = Object.assign({}, visoUser);
        copy.createdDate = this._dateUtilsService.convertDateTimeFromServer(visoUser.createdDate);
        copy.updatedDate = this._dateUtilsService.convertDateTimeFromServer(visoUser.updatedDate);
        return copy;
    }

    /**
     * Convert a VisoUser to JSON which can be sent to the server.
     */
    private convert(contact: VisoUser): VisoUser {
        const copy: VisoUser = Object.assign({}, contact);
        return copy;
    }
}
