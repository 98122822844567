import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';
import { NgbDropdownModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { NgxSelectModule } from 'ngx-select-ex';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { NoPastedLinesModule } from '../../shared/no-pasted-lines/no-pasted-lines.module';
import { AssuranceLevelMeterModule } from '../assurance-level-meter';
import { BadgeModule } from '../badge';
import { CallbackPipeModule } from '../pipes/callback-pipe';
import { AuthDirectivesModule } from '../auth-directives';
import { ToggleDisableOnActionsModule } from '../toggle-disable-on-actions/toggle-disable-on-actions.module';
import { ActionNotificationModule } from './../action-notification/action-notification.module';
import { VendorComponentsModule } from '../vendor-components/vendor-components.module';
import { CustomSelectModule } from '../custom-select/custom-select.module';
import { VisoButtonModule } from '../viso-button/viso-button.module';
import { DynamicContentModule } from '../dynamic-content/dynamic-content.module';
import { ExternalLinkModule } from '../external-link';
import { TruncateModule } from '../truncate/truncate.module';
import { RiskUtilsModule } from '../risk-utils/risk-utils.module';
import { SecurityControlDomainsComponent } from './components/control-domains/security-control-domains/security-control-domains.component';
import { ControlGroupComponent } from './components/control-domains/control-group/control-group.component';
import { DetectionComponent } from './components/control-domains/detection/detection.component';
import { ArtifactDetailComponent } from './components/artifacts/artifact-detail/artifact-detail.component';
import { ArtifactDetailControlsComponent } from './components/artifacts/artifact-detail-controls/artifact-detail-controls.component';
import { ArtifactDetailFormComponent } from './components/artifacts/artifact-detail-form/artifact-detail-form.component';
import { ArtifactsHandlerComponent } from './components/artifacts/artifacts-handler/artifacts-handler.component';
import { ArtifactsListComponent } from './components/artifacts/artifacts-list/artifacts-list.component';
import { AddControlModalComponent } from './components/artifacts/add-control-modal/add-control-modal.component';
import { ArtifactDetailHeaderComponent } from './components/artifacts/artifact-detail-header/artifact-detail-header.component';
import { ArtifactDetailIntelligenceComponent } from './components/artifacts/artifact-detail-intelligence/artifact-detail-intelligence.component';
import { ArtifactDetailFilesComponent } from './components/artifacts/artifact-detail-files/artifact-detail-files.component';
import { AsCommonDetectionPipe } from './pipes/artifactDetectionToDetection.pipe';
import { ARTIFACT_ACTIONS_CONTAINER_TOKEN } from './tokens';
import { ChipModule } from '../chip/chip.module';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { HideIfOrgUserIsNotBusinessOwnerModule } from '../hide-if-org-user-is-not-business-owner/hide-if-org-user-is-not-business-owner.module';
import { MatSelectModule } from '@angular/material/select';
import { EnumToArrayPipeModule } from '@shared/pipes/enum-to-array-pipe/enum-to-array-pipe.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { OrderByPipeModule } from '@shared/pipes/order-by-pipe/order-by-pipe.module';

@NgModule({
    declarations: [
        SecurityControlDomainsComponent,
        ControlGroupComponent,
        ArtifactDetailComponent,
        ArtifactDetailControlsComponent,
        ArtifactDetailFormComponent,
        ArtifactsHandlerComponent,
        ArtifactsListComponent,
        AddControlModalComponent,
        ArtifactDetailHeaderComponent,
        ArtifactDetailIntelligenceComponent,
        ArtifactDetailFilesComponent,
        DetectionComponent,
        AsCommonDetectionPipe,
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        RouterModule,
        TooltipModule,
        PopoverModule,
        AccordionModule,
        TabsModule,
        CollapseModule,
        NgbTooltipModule,
        NgxSelectModule,
        NgxDatatableModule,
        NgbDropdownModule,
        AssuranceLevelMeterModule,
        BadgeModule,
        CallbackPipeModule,
        AuthDirectivesModule,
        ToggleDisableOnActionsModule,
        ActionNotificationModule,
        VendorComponentsModule,
        CustomSelectModule,
        VisoButtonModule,
        MatButtonModule,
        BsDropdownModule,
        DynamicContentModule,
        NoPastedLinesModule,
        ExternalLinkModule,
        MatDatepickerModule,
        MatCheckboxModule,
        TruncateModule,
        MatCardModule,
        MatExpansionModule,
        RiskUtilsModule,
        ChipModule,
        MatTableModule,
        MatSortModule,
        HideIfOrgUserIsNotBusinessOwnerModule,
        MatSelectModule,
        MatTooltipModule,
        EnumToArrayPipeModule,
        OrderByPipeModule,
    ],
    exports: [
        SecurityControlDomainsComponent,
        ControlGroupComponent,
        ArtifactDetailComponent,
        ArtifactDetailControlsComponent,
        ArtifactDetailFormComponent,
        ArtifactsHandlerComponent,
        ArtifactsListComponent,
        AddControlModalComponent,
        ArtifactDetailHeaderComponent,
        ArtifactDetailIntelligenceComponent,
        ArtifactDetailFilesComponent,
        DetectionComponent,
        AsCommonDetectionPipe,
    ],
    providers: [{ provide: ARTIFACT_ACTIONS_CONTAINER_TOKEN, useValue: new BehaviorSubject(null) }],
})
export class ArtifactIntelligenceModule {}
