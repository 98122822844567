import { createAction, props } from '@ngrx/store';
import { FrameworkMapping, FrameworkMappingType } from '@entities/framework/models/framework-mapping.model';

export enum FrameworkMappingActions {
    GetFrameworkMappingsRequest = '[Request] Get Framework Mappings Request',
    GetFrameworkMappingsRequestSuccess = '[Request] Get Framework Mappings Request Success',
    GetFrameworkMappingsRequestFailed = '[Request] Get Framework Mappings Request Failed',
}

export const getFrameworkMappingsRequest = createAction(
    FrameworkMappingActions.GetFrameworkMappingsRequest,
    props<{ relationshipId: number; framework: FrameworkMappingType }>(),
);

export const getFrameworkMappingsRequestSuccess = createAction(
    FrameworkMappingActions.GetFrameworkMappingsRequestSuccess,
    props<{ relationshipId: number; mappings: FrameworkMapping; frameworkMappingType: FrameworkMappingType }>(),
);

export const getFrameworkMappingsRequestFailed = createAction(
    FrameworkMappingActions.GetFrameworkMappingsRequestFailed,
);
