export const suggestedControlIds = [
    {
        controlId: 1,
        controlDomainId: 1,
        socControlIds: ['CC6.6'],
        isoControlIds: [],
    },
    {
        controlId: 2,
        controlDomainId: 2,
        socControlIds: ['CC1.4'],
        isoControlIds: ['A.7.1.1'],
    },
    {
        controlId: 3,
        controlDomainId: 2,
        socControlIds: ['CC1.4', 'CC1.5', 'CC2.1'],
        isoControlIds: ['A.7.1.2'],
    },
    {
        controlId: 4,
        controlDomainId: 3,
        socControlIds: ['CC6.6', 'CC6.7'],
        isoControlIds: ['A.10.1.1', 'A.10.1.2'],
    },
    {
        controlId: 5,
        controlDomainId: 4,
        socControlIds: ['CC6.1', 'CC6.7'],
        isoControlIds: ['A.10.1.1', 'A.10.1.2'],
    },
    {
        controlId: 6,
        controlDomainId: 5,
        socControlIds: ['P6.6', 'CC2.2', 'CC2.3', 'CC7.2', 'CC7.3', 'CC7.4', 'CC7.5'],
        isoControlIds: [
            'A.12.4.1',
            'A.12.4.2',
            'A.12.4.3',
            'A.12.4.4',
            'A.16.1.1',
            'A.16.1.2',
            'A.16.1.3',
            'A.16.1.4',
            'A.16.1.5',
            'A.16.1.6',
            'A.16.1.7',
        ],
    },
    {
        controlId: 7,
        controlDomainId: 6,
        socControlIds: ['CC9.2'],
        isoControlIds: ['A.15.1.1', 'A.15.1.2', 'A.15.2.1', 'A.15.2.2'],
    },
    {
        controlId: 8,
        controlDomainId: 7,
        socControlIds: ['CC7.1', 'CC7.2', 'CC7.4', 'CC3.2'],
        isoControlIds: ['A.12.6.1'],
    },
    {
        controlId: 9,
        controlDomainId: 7,
        socControlIds: ['CC7.5'],
        isoControlIds: [],
    },
    {
        controlId: 10,
        controlDomainId: 8,
        socControlIds: ['CC4.1'],
        isoControlIds: ['A.18.2.3'],
    },
    {
        controlId: 11,
        controlDomainId: 9,
        socControlIds: ['CC6.5', 'C1.2', 'P4.3'],
        isoControlIds: ['A.11.2.7'],
    },
    {
        controlId: 12,
        controlDomainId: 9,
        socControlIds: ['P4.2', 'CC2.2'],
        isoControlIds: ['A.11.2.7', 'A.8.3.1', 'A.8.3.2'],
    },
    {
        controlId: 13,
        controlDomainId: 10,
        socControlIds: ['CC1.4', 'CC2.2'],
        isoControlIds: ['A.7.2.2'],
    },
    {
        controlId: 14,
        controlDomainId: 11,
        socControlIds: ['CC6.4'],
        isoControlIds: ['A.11.1.1', 'A.11.1.2', 'A.11.1.3', 'A.11.1.5'],
    },
    {
        controlId: 15,
        controlDomainId: 12,
        socControlIds: ['CC6.7', 'CC6.8'],
        isoControlIds: [''],
    },
    {
        controlId: 16,
        controlDomainId: 12,
        socControlIds: ['CC6.7', 'CC6.8'],
        isoControlIds: [''],
    },
    {
        controlId: 17,
        controlDomainId: 12,
        socControlIds: ['CC6.7', 'CC6.8'],
        isoControlIds: ['A.11.2.9'],
    },
    {
        controlId: 18,
        controlDomainId: 12,
        socControlIds: ['CC6.7', 'CC6.8'],
        isoControlIds: ['A.12.2.1'],
    },
    {
        controlId: 19,
        controlDomainId: 13,
        socControlIds: ['CC8.1'],
        isoControlIds: [
            'A.9.4.5',
            'A.12.1.2',
            'A.12.1.4',
            'A.14.2.1',
            'A.14.2.2',
            'A.14.2.3',
            'A.14.2.4',
            'A.14.2.5',
            'A.14.2.6',
            'A.14.2.8',
            'A.14.3.1',
        ],
    },
    {
        controlId: 20,
        controlDomainId: 13,
        socControlIds: ['CC8.1'],
        isoControlIds: ['A.14.2.1'],
    },
    {
        controlId: 21,
        controlDomainId: 13,
        socControlIds: ['CC8.1'],
        isoControlIds: [],
    },
    {
        controlId: 22,
        controlDomainId: 13,
        socControlIds: ['CC8.1'],
        isoControlIds: [],
    },
    {
        controlId: 23,
        controlDomainId: 14,
        socControlIds: ['CC6.1', 'CC6.2', 'CC6.3', 'CC6.6'],
        isoControlIds: ['A.9.2.3'],
    },
    {
        controlId: 24,
        controlDomainId: 14,
        socControlIds: ['CC6.1', 'CC6.2', 'CC6.3', 'CC6.7'],
        isoControlIds: ['A.9.2.4'],
    },
    {
        controlId: 25,
        controlDomainId: 14,
        socControlIds: ['CC6.1', 'CC6.2', 'CC6.3', 'CC6.8'],
        isoControlIds: ['A.9.1.1', 'A.9.1.2', 'A.9.2.1', 'A.9.2.2'],
    },
    {
        controlId: 26,
        controlDomainId: 14,
        socControlIds: ['CC6.1', 'CC6.2', 'CC6.3', 'CC6.8'],
        isoControlIds: ['A.9.2.5'],
    },
    {
        controlId: 27,
        controlDomainId: 14,
        socControlIds: ['CC6.1', 'CC6.2', 'CC6.3', 'CC6.8'],
        isoControlIds: ['A.9.2.1', 'A.9.2.6'],
    },
    {
        controlId: 28,
        controlDomainId: 15,
        socControlIds: ['CC6.4'],
        isoControlIds: ['A.11.1.1', 'A.11.1.2', 'A.11.1.3', 'A.11.1.5'],
    },
];
