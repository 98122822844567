import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared';
import { NgxSelectModule } from 'ngx-select-ex';
import { AssignBusinessOwnerComponent } from './assign-business-owner.component';

@NgModule({
    imports: [SharedModule, NgxSelectModule],
    declarations: [AssignBusinessOwnerComponent],
    exports: [AssignBusinessOwnerComponent],
})
export class AssignBusinessOwnerModule {
    constructor() {}
}
