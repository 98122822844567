import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EnumKeysToArrayPipe, EnumToArrayPipe } from './enum-to-array.pipe';

@NgModule({
    declarations: [EnumToArrayPipe, EnumKeysToArrayPipe],
    imports: [CommonModule],
    exports: [EnumToArrayPipe, EnumKeysToArrayPipe],
})
export class EnumToArrayPipeModule {}
