import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, forkJoin } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { ContextType } from '../../../entities/context-type';
import { DataType } from '../../../entities/data-type';
import { Relationship, RelationshipService } from '../../../entities/relationship';
import { RiskUtilsService } from '../../../shared/utils/risk-utils.service';
import { OrgDataSensitivityConfig } from '../../../admin/client-profile/client-profile.model';
import { getProfileDataSensitivityLevels, getProfileDataTypes } from '../../session/redux/session.selectors';
import { getOrgControls } from '../../controls/redux/controls.selectors';
import { getContextTypes } from '../../context-types/redux/context-types.selectors';
import { ContextTypeHash } from './models/hashes';
import { getRelationshipSelectedDataTypes } from '../redux/request.selectors';

@Injectable()
export class RelationshipContextModalHelperService {
    constructor(
        private _store$: Store,
        private _relationshipService: RelationshipService,
        private _riskUtilsService: RiskUtilsService,
    ) {}

    getModalData(
        relationshipId: number,
    ): Observable<[Relationship, ContextType[], DataType[], OrgDataSensitivityConfig[]]> {
        return forkJoin([
            this.getRelationship(relationshipId),
            this.getBusinessCases(),
            this.getDataTypes(),
            this.getProfileDataSensitivityLevels(),
        ]);
    }

    private getRelationship(relationshipId: number): Observable<Relationship> {
        return this._relationshipService.find(relationshipId).pipe(map((res) => res.body));
    }

    private getBusinessCases(): Observable<ContextType[]> {
        return forkJoin([
            this._store$.select(getContextTypes).pipe(
                filter((contextTypes) => !!contextTypes?.length),
                take(1),
            ),
            this._store$.select(getOrgControls).pipe(
                filter((controls) => !!controls?.length),
                take(1),
            ),
        ]).pipe(
            map(([contextTypes, controls]) => {
                const contextTypeHash = controls.reduce<ContextTypeHash>((hash, control) => {
                    control.contextTypes.forEach(
                        (contextType) => (hash[contextType.id] = !!hash[contextType.id] ? hash[contextType.id] + 1 : 1),
                    );
                    return hash;
                }, {});
                return contextTypes.sort((a, b) => (contextTypeHash[b.id] || 0) - (contextTypeHash[a.id] || 0));
            }),
        );
    }

    private getDataTypes(): Observable<DataType[]> {
        return forkJoin([
            this._store$.select(getProfileDataTypes).pipe(take(1)),
            this._store$.select(getRelationshipSelectedDataTypes).pipe(take(1)),
        ]).pipe(
            map(([dataTypeConfigs, relationshipSelectedDataTypes]) =>
                dataTypeConfigs
                    .filter(
                        (config) =>
                            config.enabled ||
                            relationshipSelectedDataTypes.map((dt) => dt.id).includes(config.dataType.id),
                    )
                    .map((config) => config.dataType),
            ),
            map((dataTypes) => dataTypes.sort(this._riskUtilsService.sortBySensitivityThenName).reverse()),
        );
    }

    private getProfileDataSensitivityLevels(): Observable<OrgDataSensitivityConfig[]> {
        return this._store$.select(getProfileDataSensitivityLevels).pipe(take(1));
    }
}
