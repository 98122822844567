import { createAction, props } from '@ngrx/store';
import { RTPEvent } from '../../../../entities/rtp/rtp-events.model';
import { ApolloError } from '@apollo/client';

export enum RtpActions {
    OnRelationshipRtpEventCancelled = '[Request] On Relationship Rtp Event Cancelled',
    OnRelationshipRtpEvent = '[Request] On Relationship Rtp Event',
    OnRelationshipRtpEventSuccess = '[Request] On Relationship Rtp Event Success',
    OnRelationshipRtpEventFailed = '[Request] On Relationship Rtp Event Failed',
}

export const onRelationshipRtpEventCancelled = createAction(RtpActions.OnRelationshipRtpEventCancelled);

export const onRelationshipRtpEvent = createAction(
    RtpActions.OnRelationshipRtpEvent,
    props<{
        relationshipId: number;
    }>(),
);

export const onRelationshipRtpEventSuccess = createAction(
    RtpActions.OnRelationshipRtpEventSuccess,
    props<{ relationshipRtpEvent: RTPEvent }>(),
);

export const onRelationshipRtpEventFailed = createAction(
    RtpActions.OnRelationshipRtpEventFailed,
    props<{ error: ApolloError }>(),
);
