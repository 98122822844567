<app-card>
    <app-card-toolbar>
        <div app-card-toolbar-item><strong>Company logos</strong></div>
    </app-card-toolbar>
    <app-card-content>
        <p>
            It is beneficial for you to upload a logo because we use your logo to build trust in our transactional
            messaging with third parties.
        </p>

        <div class="row mt-1">
            <div class="col-md-auto">
                <strong>Icon</strong>
                <div class="d-flex justify-content-center align-items-center icon-logo-upload-area">
                    <img
                        class="organization-icon"
                        *ngIf="org.faviconUrl && !iconLogoisUploading"
                        [alt]="org.name + ' Company Icon'"
                        [src]="org.faviconUrl"
                    />
                    <ng-container *jhiHasAnyAuthority="Roles.OrgAdmin">
                        <button
                            *ngIf="!iconLogoisUploading"
                            mat-button
                            visoButton="secondary"
                            class="btn-sm file-picker"
                            [ngClass]="{ 'd-none': org.faviconUrl }"
                            (click)="uploader.click()"
                        >
                            Choose File
                        </button>
                        <input hidden type="file" #uploader (change)="uploadFile($event, LogoTypes.IconLogo)" />
                    </ng-container>

                    <div *ngIf="iconLogoisUploading" class="d-flex flex-column">
                        <div class="flex-shrink-0 flex-grow-1">
                            <div class="mb-2 file-name text-center text-truncate">
                                {{ getIconLogoUploadedFileName }}
                            </div>

                            <div class="p-0">
                                <progressbar
                                    class="progress-bar-primary progress-bar-resize custom-progress"
                                    [value]="iconLogoPercentUploaded"
                                ></progressbar>
                            </div>
                        </div>
                        <div class="text-center">
                            <button
                                type="button"
                                mat-button
                                visoButton="link"
                                class="btn-xs"
                                (click)="cancelIconLogoUpload()"
                            >
                                Cancel Upload
                            </button>
                        </div>
                    </div>
                </div>

                <div class="mt-2">
                    <ul class="icon-logo-specs">
                        <li><strong>Ideal Dimensions:</strong> 32 px x 32 px</li>
                        <li><strong>File Type:</strong> png, jpg, ico</li>
                        <li><strong>Max upload size:</strong> 100 KB</li>
                    </ul>
                </div>
            </div>
            <div class="col">
                <strong>Full Logo</strong>
                <div class="d-flex justify-content-center align-items-center full-logo-upload-area">
                    <img
                        class="organization-logo"
                        *ngIf="org.logoUrl && !fullLogoisUploading"
                        [alt]="org.name + ' Company Logo'"
                        [src]="org.logoUrl"
                    />
                    <ng-container *jhiHasAnyAuthority="Roles.OrgAdmin">
                        <button
                            *ngIf="!fullLogoisUploading"
                            mat-button
                            visoButton="secondary"
                            class="btn-sm file-picker"
                            [ngClass]="{ 'd-none': org.logoUrl }"
                            (click)="uploader.click()"
                        >
                            Choose File
                        </button>
                        <input hidden type="file" #uploader (change)="uploadFile($event, LogoTypes.FullLogo)" />
                    </ng-container>

                    <div *ngIf="fullLogoisUploading" class="d-flex flex-column">
                        <div class="flex-shrink-0 flex-grow-1">
                            <div class="mb-2 file-name text-center text-truncate">
                                {{ getFullLogoUploadedFileName }}
                            </div>

                            <div class="p-0">
                                <progressbar
                                    class="progress-bar-primary progress-bar-resize custom-progress"
                                    [value]="fullLogoPercentUploaded"
                                ></progressbar>
                            </div>
                        </div>
                        <div class="text-center">
                            <button
                                type="button"
                                mat-button
                                visoButton="link"
                                class="btn-xs"
                                (click)="cancelFullLogoUpload()"
                            >
                                Cancel Upload
                            </button>
                        </div>
                    </div>
                </div>

                <div class="mt-2">
                    <ul class="icon-logo-specs">
                        <li><strong>Ideal Dimensions:</strong> 400 px x 150 px</li>
                        <li><strong>File Type:</strong> png, jpg, gif</li>
                        <li><strong>Max upload size:</strong> 100 KB</li>
                    </ul>
                </div>
            </div>
        </div>
    </app-card-content>
</app-card>
