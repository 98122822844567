<a [routerLink]="tipLink">
    <div class="notification-body">
        <div *ngIf="isNew" class="notification-new"></div>
        <div class="notification-title">
            <ng-container [ngSwitch]="tipNotification.tipType">
                <ng-container *ngSwitchCase="TipNotificationType.ADD_LOGO">
                    Improve your vendor response rate by
                    <span class="link text-primary text-bold">uploading your company logo.</span>
                </ng-container>
                <ng-container *ngSwitchCase="TipNotificationType.ADD_RELATIONSHIP">
                    Start assessing your vendors by
                    <span class="link text-primary text-bold">adding a relationship into the platform.</span>
                </ng-container>
                <ng-container *ngSwitchCase="TipNotificationType.EXPLORE_RELATIONSHIP">
                    See what VISO TRUST can show you about your third party relationships by assessing your vendors by
                    <span class="link text-primary text-bold">checking out an existing relationship.</span>
                </ng-container>
                <ng-container *ngSwitchCase="TipNotificationType.VISO_RELATIONSHIP">
                    We’ve created a relationship with VISO TRUST so you can see what an assessed third party looks like.
                    <span class="link text-primary text-bold">Check out VISO's security posture</span>
                </ng-container>
            </ng-container>
        </div>
        <div class="notification-subtitle text-muted">Tips and Suggestions</div>
        <div class="notification-action" (click)="markAsDismissed.emit($event)">
            <em class="fa fa-check x text-primary"></em>
        </div>
    </div>
</a>
