import { Component, Input } from '@angular/core';
import { Risk } from '../../../entities/risk-assessment';

@Component({
    selector: 'app-stat-box',
    templateUrl: 'stat-box.component.html',
    styleUrls: ['stat-box.component.scss'],
})
export class StatBoxComponent {
    @Input() riskCategoryLabel = 'Inherent Risk Level';
    @Input() risk: Risk;
    @Input() transitionalRisk = false;
    @Input() captionText = 'Imported';

    getRiskClasses = (risk: Risk, transitional: boolean) => {
        return {
            [this.getRisk(risk)]: true,
            transitional: transitional,
        };
    };

    private getRisk(risk: Risk): string {
        switch (risk) {
            case Risk.EXTREME:
            case Risk.HIGH:
            case Risk.MEDIUM:
            case Risk.LOW:
                return risk.toString().toLowerCase();
        }
        return '';
    }
}
