import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RiskChipComponent } from './risk-chip/risk-chip.component';
import { RiskLevelConfigPipe } from './risk-level-config.pipe';
import { MatChipsModule } from '@angular/material/chips';
import { MatRiskChipComponent } from './mat-risk-chip/mat-risk-chip.component';
import { RiskTextColorDirective } from './risk-text-color/risk-text-color.directive';

@NgModule({
    declarations: [RiskChipComponent, RiskLevelConfigPipe, MatRiskChipComponent, RiskTextColorDirective],
    imports: [CommonModule, MatChipsModule],
    exports: [RiskChipComponent, RiskLevelConfigPipe, MatRiskChipComponent, RiskTextColorDirective],
})
export class RiskUtilsModule {}
