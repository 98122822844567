import { ReviewFrequency } from '../../../entities/relationship';

export interface ReviewFrequencyOptionItem {
    label: ReviewFrequencyLabels;
    value: ReviewFrequency;
}

export enum ReviewFrequencyLabels {
    QUARTERLY = 'Every 3 months',
    SEMIANNUAL = 'Every 6 months',
    ANNUAL = 'Every year',
    TWO_YEARS = 'Every 2 years',
    THREE_YEARS = 'Every 3 years',
}

export const reviewFrequencies: ReviewFrequencyOptionItem[] = [
    { label: ReviewFrequencyLabels.QUARTERLY, value: ReviewFrequency.QUARTERLY },
    { label: ReviewFrequencyLabels.SEMIANNUAL, value: ReviewFrequency.SEMIANNUAL },
    { label: ReviewFrequencyLabels.ANNUAL, value: ReviewFrequency.ANNUAL },
    { label: ReviewFrequencyLabels.TWO_YEARS, value: ReviewFrequency.TWO_YEARS },
    { label: ReviewFrequencyLabels.THREE_YEARS, value: ReviewFrequency.THREE_YEARS },
];
