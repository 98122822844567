import { StoreConfig } from '@ngrx/store';
import { environment } from 'src/main/webapp/environments/environment';
import { storageMetareducer } from '../../../shared/redux/meta-reducers/storage.metareducer';
import createMigrate from '../../../shared/redux/migration/create-migration';
import { RelationshipsState } from './relationships-state';
import { RelationshipsActions } from './relationships.actions';
import { relationshipsMigrations } from './relationships.migrations';

export function getRelationshipConfig(localStorageKey: string, storeKeys: string[]): StoreConfig<RelationshipsState> {
    return {
        metaReducers: [
            storageMetareducer(
                storeKeys,
                localStorageKey,
                [
                    RelationshipsActions.SetColumnsState,
                    RelationshipsActions.ClearAllFilters,
                    RelationshipsActions.SetAdditionalFiltersState,
                ],
                {
                    version: 16,
                    debug: !environment.production,
                    migrate: createMigrate<RelationshipsState>(relationshipsMigrations, !environment.production), // Apply migrations
                },
            ),
        ],
    };
}
