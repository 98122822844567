import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NoPastedLinesDirective } from './no-pasted-lines.directive';

@NgModule({
    declarations: [NoPastedLinesDirective],
    imports: [CommonModule],
    exports: [NoPastedLinesDirective],
})
export class NoPastedLinesModule {}
