import { CdkStep, CdkStepper } from '@angular/cdk/stepper';
import { AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FileArtifact, FileArtifactType, QuestionnaireArtifact } from '@entities/artifact';
import { ContextType } from '@entities/context-type';
import { AssessmentRecommendation } from '@entities/recommendation';
import { Store } from '@ngrx/store';
import { downloadWithAnchorElement } from '@shared/file-download/redux/actions';
import { combineLatest, map, Observable, Subject, takeUntil } from 'rxjs';
import {
    AdditionalInformationSubSteps,
    ArtifactUploadRecommendation,
    AssessmentSubmissionFormPayload,
    CollectionTypes,
    ExpiredArtifactReplacement,
    PublicAssessmentRTPFileArtifact,
    StepIds,
} from '../../../models';
import {
    cancelAssessment,
    continueFromInitialUpload,
    continueFromWelcomePage,
    declineRemediation,
    expiredDocumentsScreenContinued,
    expiredDocumentsScreenSkipped,
    expiredDocumentsScreenWentBack,
    extendExpiration,
    forwardRequest,
    goBackFromInitialUpload,
    goBackFromSubmission,
    navigateToCollectArtifacts,
    navigateToQuestionnaire,
    navigateToSupplementalQuestionnaireFromSubmission,
    onWhenAddingFileFailed,
    removeArtifact,
    saveQuestionnaire,
    setArtifactPassword,
    setArtifactUploadRecommendation,
    setCurrentStep,
    submitAssessment,
    uploadFiles,
    uploadFileToReplace,
    uploadSupplementalFiles,
} from '../../../redux/actions';
import {
    getArtifactUploadRecommendations,
    getAssessmentCollectionType,
    getBusinessCases,
    getClientBrandingColor,
    getClientId,
    getClientLogoUrl,
    getClientName,
    getCurrentCollectArtifactsSubStep,
    getCurrentStepId,
    getExpiredArtifactIdsToReplace,
    getExpiredArtifactReplacements,
    getFollowupControlDomainIds,
    getHasSupplementalQuestionnaireConfigured,
    getIsExpirationExtensionAllowed,
    getIsFollowupRequested,
    getIsRemediationAssessment,
    getIsSubmitted,
    getPreviousAssessmentRecommendations,
    getQuestionnaireArtifact,
    getRelevantControlDomainIds,
    getRemediationTargetDate,
    getSenderEmail,
    getSortedRtpFileArtifacts,
    getSupplementalQuestionnaireArtifacts,
    getSupplementalQuestionnaireContactEmail,
    getSupplementalQuestionnaireLinks,
    getUploadedStatusesOfInScopeFileTypes,
    getVendorName,
} from '../../../redux/selectors';
import { FilterFunction } from '@shared/file-upload/interfaces';
import { FeatureFlagService } from '@shared/services/featureflag.service';
import { FeatureFlags } from '@shared/enums/feature-flags';
import { BreakpointObserver } from '@angular/cdk/layout';
import { STATIC_SITE_URL, VISO_LOGO_URL } from '@shared/constants/url.constants';
import { SUPPORT_EMAIL } from '@shared/constants/email.constants';

@Component({
    selector: 'app-assessment-collection',
    templateUrl: './assessment-collection.component.html',
    styleUrls: ['./assessment-collection.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssessmentCollectionComponent implements OnInit, AfterViewInit, OnDestroy {
    clientId$: Observable<number>;
    clientName$: Observable<string>;
    clientLogoUrl$: Observable<string>;
    clientBrandingColor$: Observable<string>;
    senderEmail$: Observable<string>;
    vendorName$: Observable<string>;
    businessCases$: Observable<ContextType[]>;
    rtpFileArtifacts$: Observable<PublicAssessmentRTPFileArtifact[]>;
    artifactUploadRecommendations$: Observable<ArtifactUploadRecommendation[]>;
    uploadedStatusesOfInScopeFileTypes$: Observable<Partial<Record<FileArtifactType, boolean>>>;
    currentCollectArtifactsSubStep$: Observable<AdditionalInformationSubSteps>;
    expiredArtifactReplacements$: Observable<ExpiredArtifactReplacement[]>;
    questionnaire$: Observable<QuestionnaireArtifact>;
    hasSupplementalQuestionnaireConfigured$: Observable<boolean>;
    isSubmitted$: Observable<boolean>;
    isExpirationExtensionAllowed$: Observable<boolean>;
    isRemediationAssessment$: Observable<boolean>;
    remediationTargetDate$: Observable<Date>;
    previousAssessmentRecommendations$: Observable<AssessmentRecommendation[]>;
    supplementalQuestionnaireLinks$: Observable<string[]>;
    supplementalArtifacts$: Observable<FileArtifact[]>;
    getSupplementalQuestionnaireContactEmail$: Observable<string>;
    additionalInformationStepLabel$: Observable<string>;
    isFollowupRequested$: Observable<boolean>;
    collectionType$: Observable<CollectionTypes>;
    followupControlDomainIds$: Observable<number[]>;
    relevantControlDomainIds$: Observable<number[]>;
    expiredArtifactIdsToReplace$: Observable<Map<number, number>>;
    disableInitialUploadContinue$: Observable<boolean>;
    artifactClassificationInProgress$: Observable<boolean>;
    useCompactOrientation$: Observable<boolean>;
    hasBrandingEnabled$: Observable<boolean>;

    StepIds = StepIds;
    AdditionalInformationSubSteps = AdditionalInformationSubSteps;

    readonly STATIC_SITE_URL = STATIC_SITE_URL;
    readonly VISO_LOGO_URL = VISO_LOGO_URL;
    readonly SUPPORT_EMAIL = SUPPORT_EMAIL;
    readonly VISO_ORG_ID = 1;

    @ViewChild(CdkStepper)
    stepper: CdkStepper;

    private readonly MAX_WIDTH_BREAKPOINT = '(max-width: 1350px)';
    private _unsub$ = new Subject<void>();

    constructor(
        private _store$: Store,
        private _bpObserver: BreakpointObserver,
        private _featureFlagService: FeatureFlagService,
    ) {}

    ngOnInit(): void {
        this.senderEmail$ = this._store$.select(getSenderEmail);
        this.clientId$ = this._store$.select(getClientId);
        this.clientName$ = this._store$.select(getClientName);
        this.clientLogoUrl$ = this._store$.select(getClientLogoUrl);
        this.clientBrandingColor$ = this._store$.select(getClientBrandingColor);
        this.vendorName$ = this._store$.select(getVendorName);
        this.businessCases$ = this._store$.select(getBusinessCases);
        this.rtpFileArtifacts$ = this._store$.select(getSortedRtpFileArtifacts);
        this.artifactUploadRecommendations$ = this._store$.select(getArtifactUploadRecommendations);
        this.uploadedStatusesOfInScopeFileTypes$ = this._store$.select(getUploadedStatusesOfInScopeFileTypes);
        this.currentCollectArtifactsSubStep$ = this._store$.select(getCurrentCollectArtifactsSubStep);
        this.expiredArtifactReplacements$ = this._store$.select(getExpiredArtifactReplacements);
        this.questionnaire$ = this._store$.select(getQuestionnaireArtifact);
        this.hasSupplementalQuestionnaireConfigured$ = this._store$.select(getHasSupplementalQuestionnaireConfigured);
        this.isSubmitted$ = this._store$.select(getIsSubmitted);
        this.isExpirationExtensionAllowed$ = this._store$.select(getIsExpirationExtensionAllowed);
        this.isFollowupRequested$ = this._store$.select(getIsFollowupRequested);
        this.isRemediationAssessment$ = this._store$.select(getIsRemediationAssessment);
        this.remediationTargetDate$ = this._store$.select(getRemediationTargetDate);
        this.previousAssessmentRecommendations$ = this._store$.select(getPreviousAssessmentRecommendations);
        this.supplementalQuestionnaireLinks$ = this._store$.select(getSupplementalQuestionnaireLinks);
        this.supplementalArtifacts$ = this._store$.select(getSupplementalQuestionnaireArtifacts);
        this.getSupplementalQuestionnaireContactEmail$ = this._store$.select(getSupplementalQuestionnaireContactEmail);
        this.additionalInformationStepLabel$ = this.isFollowupRequested$.pipe(
            map((followupRequested) => (followupRequested ? 'Additional questions' : 'Additional information')),
        );
        this.collectionType$ = this._store$.select(getAssessmentCollectionType);
        this.followupControlDomainIds$ = this._store$.select(getFollowupControlDomainIds);
        this.relevantControlDomainIds$ = this._store$.select(getRelevantControlDomainIds);
        this.expiredArtifactIdsToReplace$ = this._store$.select(getExpiredArtifactIdsToReplace);

        this.disableInitialUploadContinue$ = this.shouldInitialUploadContinueBeDisabled();
        this.artifactClassificationInProgress$ = this.isClassificationInProgress();
        this.hasBrandingEnabled$ = this._featureFlagService.flagsLoaded.pipe(
            map((flagset) => flagset[FeatureFlags.DOMAINS_BRANDING]),
        );
    }

    ngAfterViewInit(): void {
        this._store$
            .select(getCurrentStepId)
            .pipe(takeUntil(this._unsub$))
            .subscribe((stepId) => this.selectStep(stepId));

        this.setupScreenReactivity();
    }

    continueFromWelcomePage(): void {
        this._store$.dispatch(continueFromWelcomePage());
    }

    navigateToCollectArtifacts(): void {
        this._store$.dispatch(navigateToCollectArtifacts());
    }

    navigateToSupplementalQuestionnaireFromSubmission(): void {
        this._store$.dispatch(navigateToSupplementalQuestionnaireFromSubmission());
    }

    optOut(): void {
        this._store$.dispatch(cancelAssessment());
    }

    forwardRequest(): void {
        this._store$.dispatch(forwardRequest());
    }

    extendExpiration(): void {
        this._store$.dispatch(extendExpiration());
    }

    declineRemediation(): void {
        this._store$.dispatch(declineRemediation());
    }

    goBackFromInitialUpload(): void {
        this._store$.dispatch(goBackFromInitialUpload());
    }

    continueFromInitialUpload(): void {
        this._store$.dispatch(continueFromInitialUpload());
    }

    expiredDocumentsWentBack(): void {
        this._store$.dispatch(expiredDocumentsScreenWentBack());
    }

    expiredDocumentsSkipped(): void {
        this._store$.dispatch(expiredDocumentsScreenSkipped());
    }

    expiredDocumentsContinued(): void {
        this._store$.dispatch(expiredDocumentsScreenContinued());
    }

    uploadArtifacts(files: File[]): void {
        this._store$.dispatch(uploadFiles({ files }));
    }

    uploadArtifactToReplace(file: File, artifactIdToReplace: number): void {
        this._store$.dispatch(uploadFileToReplace({ file, artifactIdToReplace }));
    }

    uploadAttestationArtifactForRecommendation(recommendationId: string, file: File): void {
        this._store$.dispatch(uploadFiles({ recommendationId, files: [file] }));
    }

    navigateToStepId(stepId: StepIds): void {
        this._store$.dispatch(setCurrentStep({ stepId }));
    }

    onWhenAddingFileFailed(filter: FilterFunction): void {
        this._store$.dispatch(
            onWhenAddingFileFailed({
                message:
                    filter.name === 'fileSize'
                        ? 'File size must not be empty and less than 100MB'
                        : 'Filter failed: ${filter.name}',
            }),
        );
    }

    questionnaireWentBack(): void {
        this._store$.dispatch(setCurrentStep({ stepId: StepIds.WELCOME }));
    }

    questionnaireContinued(): void {
        this._store$.dispatch(setCurrentStep({ stepId: StepIds.SUBMIT }));
    }

    saveQuestionnaire(questionnaire: QuestionnaireArtifact): void {
        this._store$.dispatch(saveQuestionnaire({ questionnaire }));
    }

    submissionSubmit(payload: AssessmentSubmissionFormPayload): void {
        this._store$.dispatch(submitAssessment({ payload }));
    }

    submissionWentBack(): void {
        this._store$.dispatch(goBackFromSubmission());
    }

    setPassword({ artifact, password }: { artifact: PublicAssessmentRTPFileArtifact; password: string }): void {
        const artifactId = artifact.artifact.id;
        this._store$.dispatch(setArtifactPassword({ artifactId, password }));
    }

    removePublicAssessmentArtifact(artifact: PublicAssessmentRTPFileArtifact): void {
        this.removeArtifact(artifact.artifact);
    }

    uploadSupplementalArtifacts(files: File[]): void {
        this._store$.dispatch(uploadSupplementalFiles({ files }));
    }

    removeArtifact({ id: artifactId, fileName, artifactType }: FileArtifact): void {
        this._store$.dispatch(removeArtifact({ artifactId, fileName, artifactType }));
    }

    downloadWithAnchorElement(url: string): void {
        this._store$.dispatch(downloadWithAnchorElement({ url }));
    }

    updateRecommendation(recommendation: ArtifactUploadRecommendation): void {
        this._store$.dispatch(setArtifactUploadRecommendation({ recommendation }));
    }

    navigateToQuestionnaire(): void {
        this._store$.dispatch(navigateToQuestionnaire());
    }

    showPoweredByVisoTrust(
        hasBrandingEnabled: boolean,
        clientBrandingColorSet: boolean,
        clientLogoUrlSet: boolean,
        clientId: number,
    ) {
        return hasBrandingEnabled && (clientBrandingColorSet || clientLogoUrlSet) && clientId !== this.VISO_ORG_ID;
    }

    ngOnDestroy(): void {
        this._unsub$.next();
    }

    private selectStep(stepId: StepIds): void {
        const index = this.stepper._steps.toArray().findIndex((step) => this.getStepId(step) === stepId);
        this.stepper.selectedIndex = index;
    }

    private getStepId(step: CdkStep): StepIds {
        return +step.content.elementRef.nativeElement.parentNode.id;
    }

    private shouldInitialUploadContinueBeDisabled(): Observable<boolean> {
        return combineLatest([this.artifactUploadRecommendations$, this.rtpFileArtifacts$]).pipe(
            map(([recommendations, uploadedArtifacts]) => {
                const artifactIdsAttachedToRecommendations = recommendations
                    .filter((rec) => !!rec.attestationArtifact)
                    .map((rec) => rec.attestationArtifact.artifact.id);

                const incompleteArtifacts = uploadedArtifacts.filter(
                    (a) =>
                        !a.requiredRTPValidationsReceived &&
                        !a.rtpUnableToClassify &&
                        !artifactIdsAttachedToRecommendations.includes(a.artifact.id),
                );

                return !uploadedArtifacts.length || incompleteArtifacts.length !== 0;
            }),
        );
    }

    private isClassificationInProgress(): Observable<boolean> {
        return this.rtpFileArtifacts$.pipe(
            map((uploadedArtifacts) =>
                uploadedArtifacts.some((a) => !a.requiredRTPValidationsReceived && !a.rtpUnableToClassify),
            ),
        );
    }

    private setupScreenReactivity(): void {
        this.useCompactOrientation$ = this._bpObserver.observe([this.MAX_WIDTH_BREAKPOINT]).pipe(
            takeUntil(this._unsub$),
            map((result) => result.matches),
        );
    }
}
