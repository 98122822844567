import { Injectable } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { getProfileDataTypes } from '../session/redux/session.selectors';

@Injectable({
    providedIn: 'root',
})
export class RequestResolver {
    constructor(private _store$: Store) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this._store$.pipe(
            select(getProfileDataTypes),
            filter((dataTypeConfigs) => !!dataTypeConfigs),
            map((dataTypeConfigs) => !!dataTypeConfigs),
            take(1),
        );
    }
}
