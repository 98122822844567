import { MemoizedSelector, createSelector } from '@ngrx/store';
import { ControlDomainAssessment } from '@entities/relationship/models/security-control-domain';
import { AppState } from '../assessment-collection.state';
import { getSecurityControlDomain } from './main.selectors';

const getControlDomainAssessments: MemoizedSelector<AppState, ControlDomainAssessment[]> = createSelector(
    getSecurityControlDomain,
    (securityControlDomains) => securityControlDomains?.controlDomainAssessments || [],
);

export const getControlDomainAssessmentsWithFollowupNeeded: MemoizedSelector<AppState, ControlDomainAssessment[]> =
    createSelector(getControlDomainAssessments, (controlDomainAssessments) =>
        controlDomainAssessments.filter((controlDomainAssessment) => controlDomainAssessment.followupNeeded),
    );
