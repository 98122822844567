import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { contactMgmtRoutes } from '.';
import { SharedModule } from '../../shared';
import { ContactManagementComponent } from './contact-management.component';
import { NgxSelectModule } from 'ngx-select-ex';
import { EffectsModule } from '@ngrx/effects';
import { ContactEffects } from './redux/contact-management.effects';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

const ENTITY_STATES = [...contactMgmtRoutes];

@NgModule({
    imports: [
        SharedModule,
        RouterModule.forChild(ENTITY_STATES),
        NgxSelectModule,
        NgxDatatableModule,
        EffectsModule.forFeature([ContactEffects]),
    ],
    declarations: [ContactManagementComponent],
    providers: [],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ContactManagementModule {}
