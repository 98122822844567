import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ContactPopupService as ContactPopupService } from './contact-popup.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { map, shareReplay, take, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import {
    ContactActions,
    createContactRequest,
    createContactRequestSuccess,
    deleteContactRequestSuccess,
    openDeleteContactConfirmationModal,
    updateContactRequest,
    updateContactRequestSuccess,
} from '../contact-management/redux/contact-management.actions';
import { VisoUser, VisoUserRole } from '../../entities/viso-user';
import { noWhitespaceValidator } from '../../shared/validators/whitespace-validator';
import { FormUtilsService } from '../../shared/utils/form-utils.service';
import {
    getBusinessUnitsRequest,
    getBusinessUnitsRequestSuccess,
} from '../business-unit-management/redux/business-unit-management.actions';
import { BusinessUnit } from '../../entities/business-unit';
import { getUserAccount } from '../session/redux/session.selectors';
import { hasDomainValidator } from '../../shared/validators/domain-validator';
import { isUniqueContactValidator } from '../../shared/validators/unique-contact-validator';
import { EnumSelectOption } from '../../shared/model/select-options';

interface UserFormValue extends Omit<VisoUser, 'authorities'> {
    authorities: VisoUserRole;
}
@Component({
    selector: 'app-contact-dialog',
    templateUrl: './contact-dialog.component.html',
    styleUrls: ['./contact-dialog.component.scss'],
})
export class ContactDialogComponent implements OnInit, OnDestroy {
    @Input()
    contact: VisoUser;

    @Input()
    contactsForOrg: VisoUser[];

    @Input()
    currentUser: VisoUser;

    Roles = VisoUserRole;

    businessUnits$: Observable<BusinessUnit[]>;

    roles: EnumSelectOption[] = [
        { name: 'Org Admin', enumValue: VisoUserRole.OrgAdmin },
        { name: 'Org User', enumValue: VisoUserRole.OrgUser },
        { name: 'Org Read Only', enumValue: VisoUserRole.ReadOnly },
    ];

    ContactActions = ContactActions;
    contactForm: UntypedFormGroup;

    private allowedRolesToChangeTo: VisoUserRole[] = [
        VisoUserRole.OrgAdmin,
        VisoUserRole.OrgUser,
        VisoUserRole.ReadOnly,
    ];
    private _unsub = new Subject<void>();

    get showContactAlreadyExistsError(): boolean {
        const emailFormControl = this.contactForm.controls.email;
        return emailFormControl.hasError('emailAlreadyExists') && this.isEmailDirtyOrTouched;
    }

    get showInvalidEmailDomainError(): boolean {
        const emailFormControl = this.contactForm.controls.email;
        return emailFormControl.hasError('invalidEmailDomain') && this.isEmailDirtyOrTouched;
    }

    get showInvalidEmailSyntaxError(): boolean {
        const emailFormControl = this.contactForm.controls.email;
        return emailFormControl.hasError('email') && this.isEmailDirtyOrTouched;
    }

    private get isEmailDirtyOrTouched(): boolean {
        const emailFormControl = this.contactForm.controls.email;
        return emailFormControl.dirty || emailFormControl.touched;
    }

    get isUserManagedByIdp(): boolean {
        return this.contact.managedByIdp == true;
    }

    get isUserOrgAdminOrOrgUserOrReadOnly(): boolean {
        return this.contact?.authorities?.some((role: VisoUserRole) => this.allowedRolesToChangeTo.includes(role));
    }

    constructor(
        private _activeModal: NgbActiveModal,
        private _fb: UntypedFormBuilder,
        private _actions$: Actions,
        private _store$: Store,
        private _formUtils: FormUtilsService,
    ) {}

    ngOnInit() {
        this.contactForm = this._fb.group({
            id: [this.contact?.id],
            email: [
                this.contact?.email || '',
                Validators.compose([
                    Validators.required,
                    Validators.email,
                    hasDomainValidator(this.currentUser?.orgDomains),
                    isUniqueContactValidator(this.contactsForOrg, false, this.contact?.email),
                    Validators.maxLength(255),
                ]),
            ],
            firstName: [this.contact?.firstName || '', [Validators.required, noWhitespaceValidator]],
            lastName: [this.contact?.lastName || '', [Validators.required, noWhitespaceValidator]],
            orgId: [this.contact?.orgId],
            businessUnitId: [this.contact?.businessUnitId, [Validators.required]],
            businessUnitName: [this.contact?.businessUnitName, [Validators.required]],
            managedByIdp: [this.contact?.managedByIdp || false],
            status: [this.contact?.status],
            authorities: !!this.contact?.authorities?.[0] ? [this.contact.authorities[0]] : [],
        });

        if (this.contact?.managedByIdp) {
            this.contactForm.controls.firstName.disable();
            this.contactForm.controls.lastName.disable();
            this.contactForm.controls.email.disable();
        } else {
            this.contactForm.controls.firstName.enable();
            this.contactForm.controls.lastName.enable();
            this.contactForm.controls.email.enable();
        }

        if (this.contact.id === this.currentUser.id) {
            this.contactForm.controls.authorities.disable();
        }

        this._store$.pipe(select(getUserAccount), takeUntil(this._unsub)).subscribe((account) => {
            this.contactForm.controls.orgId.setValue(account?.orgId);
        });

        this._store$.dispatch(getBusinessUnitsRequest(null));

        this._actions$
            .pipe(
                ofType(createContactRequestSuccess, updateContactRequestSuccess, deleteContactRequestSuccess),
                tap(() => {
                    this._activeModal.dismiss();
                }),
                takeUntil(this._unsub),
            )
            .subscribe();

        this.businessUnits$ = this._actions$.pipe(
            ofType(getBusinessUnitsRequestSuccess),
            map(({ businessUnits }) => businessUnits),
            take(1),
            shareReplay(1),
        );

        this.contactForm.controls.businessUnitId.valueChanges
            .pipe(
                withLatestFrom(this.businessUnits$),
                tap(([businessUnitId, businessUnits]) => {
                    const businessUnitName = businessUnits.find(
                        (businessUnit) => businessUnit.id === businessUnitId,
                    )?.name;
                    this.contactForm.controls.businessUnitName.setValue(businessUnitName);
                }),
                takeUntil(this._unsub),
            )
            .subscribe();
    }

    clear() {
        this._activeModal.dismiss('cancel');
    }

    save() {
        const formValue = this._formUtils.getCleanFormGroupValue<UserFormValue>(this.contactForm, true);
        const contact: VisoUser = {
            ...formValue,
            authorities: !!formValue.authorities ? [formValue.authorities] : [],
        };
        if (this.contact.id) {
            this._store$.dispatch(updateContactRequest({ contact }));
        } else {
            this._store$.dispatch(createContactRequest({ contact }));
        }
    }

    deleteContact() {
        this.clear();
        setTimeout(() => this._store$.dispatch(openDeleteContactConfirmationModal({ contact: this.contact })), 50);
    }

    ngOnDestroy(): void {
        this._unsub.next();
    }
}

@Component({
    selector: 'app-contact-popup',
    template: '',
})
export class ContactPopupComponent implements OnInit, OnDestroy {
    private _unsub = new Subject<void>();

    constructor(
        private _route: ActivatedRoute,
        private _contactPopupService: ContactPopupService,
    ) {}

    ngOnInit(): void {
        this._route.params.pipe(takeUntil(this._unsub)).subscribe((params) => {
            if (params['id']) {
                this._contactPopupService.open(ContactDialogComponent, params['id']);
            } else {
                this._contactPopupService.open(ContactDialogComponent);
            }
        });
    }

    ngOnDestroy(): void {
        this._unsub.next();
    }
}
