<form name="updateProfileForm" [formGroup]="updateProfileForm" (ngSubmit)="updateProfile()">
    <div class="modal-header">
        <img src="content/img/viso_navbar_brand.png" alt="VISO TRUST logo" height="50" />
        <h2 class="modal-title" data-pw="welcomeMessageTitle">Welcome to VISO TRUST!</h2>
    </div>
    <div class="modal-body">
        <div class="row" *ngIf="!showFirstLastNameFields && !showOrgNameField">
            <div class="col">
                <p class="text-bold">
                    Our AI-powered technology accelerates security assessments and continuously monitors cyber risk
                    across your vendor ecosystem.
                </p>
                <ul class="welcome-list pt-3">
                    <li>
                        <p class="text-sm">Manage the lifecycle of your vendors in one place</p>
                    </li>
                    <li>
                        <p class="text-sm">Save time by letting us manage your vendor assessments</p>
                    </li>
                    <li>
                        <p class="text-sm">Get real time risk analysis and data-driven insights</p>
                    </li>
                </ul>
            </div>
        </div>
        <div class="row" *ngIf="showFirstLastNameFields">
            <div class="col-12"><p class="text-center">Before we get started, tell us a little about yourself.</p></div>
            <div class="col-6 form-group">
                <label for="firstName">First Name<span class="required-indicator">*</span></label>
                <input
                    id="firstName"
                    type="text"
                    class="form-control"
                    formControlName="firstName"
                    placeholder="Enter your first name"
                    required
                    data-pw="firstNameInput"
                />
                <div class="text-danger" *ngIf="showRequiredFirstNameError">First name is required.</div>
                <div class="text-danger" *ngIf="showFirstNameMaxLengthError">
                    First name max length is 50 characters.
                </div>
            </div>
            <div class="col-6 form-group">
                <label for="lastName">Last Name<span class="required-indicator">*</span></label>
                <input
                    id="lastName"
                    type="text"
                    class="form-control"
                    formControlName="lastName"
                    placeholder="Enter your last name"
                    required
                    data-pw="lastNameInput"
                />
                <div class="text-danger" *ngIf="showRequiredLastNameError">Last name is required.</div>
                <div class="text-danger" *ngIf="showLastNameMaxLengthError">Last name max length is 50 characters.</div>
            </div>
        </div>
        <div class="row" *ngIf="showOrgNameField">
            <div class="col-12 form-group">
                <label for="organization">Organization Name<span class="required-indicator">*</span></label>
                <input
                    id="organization"
                    type="text"
                    class="form-control"
                    formControlName="orgName"
                    placeholder="Enter your organization name"
                    required
                    data-pw="orgNameInput"
                />
                <div class="text-danger" *ngIf="showRequiredOrgNameError">Organization name is required.</div>
                <div class="text-danger" *ngIf="showOrgNameMaxLengthError">
                    Organization name max length is 100 characters.
                </div>
            </div>
        </div>
    </div>

    <div class="modal-footer">
        <p class="text-bold" *jhiHasNotAuthority="VisoUserRole.ReadOnly">
            Would you like to add your first vendor relationship?
        </p>
        <div class="modal-actions my-2">
            <button
                *jhiHasNotAuthority="VisoUserRole.ReadOnly"
                type="button"
                mat-button
                visoButton="primary"
                class="me-2"
                data-dismiss="modal"
                [disabled]="!isButtonEnabled || updatingMetadata"
                (click)="updateProfile(true)"
            >
                <span class="text-bold">Add a Relationship</span>
            </button>
            <button
                type="button"
                mat-button
                visoButton="secondary"
                class="float-end"
                data-dismiss="modal"
                data-pw="exploreThePlatformButton"
                [disabled]="!isButtonEnabled || updatingMetadata"
                (click)="updateProfile(false)"
            >
                <span>Explore the Platform</span>
            </button>
        </div>
    </div>
</form>
