import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Options } from 'ngx-slider-v2';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ControlDomain, ControlDomainType } from '../../../entities/control-domain';
import { ClientProfileService } from '../client-profile.service';

@Component({
    selector: 'app-controls-config-list',
    templateUrl: './controls-config-list.component.html',
    styleUrls: ['./controls-config-list.component.scss'],
})
export class ControlsConfigListComponent {
    @Input() controlDomains: ControlDomain[];
    @Input() clientProfileId: string;
    @Output() controlStatusChange = new EventEmitter<void>();

    loadingControls: boolean = false;
    sliderOptions: Options = {
        floor: 0,
        ceil: 4,
        step: 1,
        readOnly: true,
        disabled: true,
        showSelectionBar: true,
        selectionBarGradient: {
            from: '#FFF',
            to: '#00838F',
        },
        hideLimitLabels: true,
        hidePointerLabels: false,
    };

    get isSecurityControlDomainType() {
        return this.controlDomains.some((control) => control.controlDomainType === ControlDomainType.SECURITY);
    }

    constructor(private _clientProfileService: ClientProfileService) {}

    getBadgeCssClass(status: boolean) {
        return status ? 'danger' : 'primary';
    }

    toggleControlStatus(control: ControlDomain) {
        control.disabled = !control.disabled;

        if (control.disabled) {
            this._clientProfileService
                .disableControlForOrg(this.clientProfileId, control.id)
                .pipe(catchError(() => of(null)))
                .subscribe({ next: () => this.controlStatusChange.emit() });
        } else {
            this._clientProfileService
                .enableControlForOrg(this.clientProfileId, control.id)
                .pipe(catchError(() => of(null)))
                .subscribe({ next: () => this.controlStatusChange.emit() });
        }
    }

    enableAllCurrentControls() {
        this.controlDomains.forEach((controlDomain) => {
            if (controlDomain.disabled) {
                this._clientProfileService
                    .enableControlForOrg(this.clientProfileId, controlDomain.id)
                    .pipe(catchError(() => of(null)))
                    .subscribe({ next: () => this.controlStatusChange.emit() });
            }
        });
    }

    disableAllCurrentControls() {
        this.controlDomains.forEach((controlDomain) => {
            if (!controlDomain.disabled) {
                this._clientProfileService
                    .disableControlForOrg(this.clientProfileId, controlDomain.id)
                    .pipe(catchError(() => of(null)))
                    .subscribe({ next: () => this.controlStatusChange.emit() });
                this.controlDomains = this.controlDomains.concat(controlDomain);
            }
        });
    }
}
