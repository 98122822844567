import { ChangeDetectionStrategy, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { BehaviorSubject, identity, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import {
    getDomainVerificationsRequest,
    getPreferredEmailDomainRequest,
    openConfirmDeletePreferredEmailDomainModal,
    openCustomizeEmailModal,
    openDkimConfigurationModal,
} from './redux/domain-verification.actions';
import { filter, take } from 'rxjs/operators';
import { DomainVerification } from '@entities/domain-verification';
import { MatTableDataSource } from '@angular/material/table';

@Component({
    selector: 'app-domain-verifications',
    templateUrl: './domain-verifications.component.html',
    styleUrls: ['./domain-verifications.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DomainVerificationsComponent implements OnInit, OnChanges, OnDestroy {
    @Input({ required: true })
    preference: DomainVerification;

    @Input({ required: true })
    verifications: DomainVerification[];

    @Input({ required: true })
    hasValidVerification: boolean;

    displayedColumns = ['domain', 'dkimVerified', 'rpVerified', 'dkimIcon'];
    dataSource = new MatTableDataSource<DomainVerification>();

    @Input()
    set isTabSelected(value: boolean) {
        this._isTabSelected$.next(value);
    }

    get isTabSelected() {
        return this._isTabSelected$.value;
    }

    private _isTabSelected$ = new BehaviorSubject<boolean>(false);

    private _unsub$ = new Subject<void>();

    constructor(private _store$: Store) {}

    ngOnInit(): void {
        this._isTabSelected$.pipe(filter(identity), take(1)).subscribe(() => this.refresh());
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.dataSource.data = this.verifications;
    }

    openDomainVerificationConfigurationModal(domain: string): void {
        this._store$.dispatch(openDkimConfigurationModal({ domain }));
    }

    refresh(): void {
        this._store$.dispatch(getPreferredEmailDomainRequest());
        this._store$.dispatch(getDomainVerificationsRequest());
    }

    openConfirmDeletePreferredEmailDomainModal() {
        this._store$.dispatch(openConfirmDeletePreferredEmailDomainModal());
    }

    openCustomizeEmailModal() {
        this._store$.dispatch(openCustomizeEmailModal());
    }

    ngOnDestroy(): void {
        this._unsub$.next();
    }
}
