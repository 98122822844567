import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, filter, map, switchMap, take } from 'rxjs/operators';
import { ContextTypeService } from '../../../entities/context-type';
import { getServerSessionCompleted } from '../../session/redux/session.actions';
import {
    getContextTypesQueryRequest,
    getContextTypesQueryRequestFailed,
    getContextTypesQueryRequestSuccess,
} from './context-types.actions';

@Injectable()
export class ContextTypesEffects {
    triggerGetContextTypesRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(getServerSessionCompleted),
            filter(({ error }) => !error),
            map(() => getContextTypesQueryRequest()),
            take(1),
        ),
    );

    /**
     * Context Types Query Request Effect
     * Triggers when `contextTypesQueryRequest` gets dispatched
     * Executes `ContextTypeService.query()` to retrieve the results
     */
    contextTypesQueryRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(getContextTypesQueryRequest),
            switchMap(() =>
                this._contextTypeService.query().pipe(
                    map((contextTypes) => getContextTypesQueryRequestSuccess({ contextTypes })),
                    catchError(() => of(getContextTypesQueryRequestFailed())),
                ),
            ),
        ),
    );

    constructor(
        private _actions$: Actions,
        private _contextTypeService: ContextTypeService,
    ) {}
}
