import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';

/**
 * Full-page file drop. This component relies on {@link ElementVisibilityDirective} to create
 * and remove window-level subscriptions to drag events based on whether this component is in view.
 */
@Component({
    selector: 'app-full-page-file-drop',
    templateUrl: './full-page-file-drop.component.html',
    styleUrl: './full-page-file-drop.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FullPageFileDropComponent implements OnInit, OnDestroy {
    @Input()
    uploadLabel = 'Upload File(s)';

    @Output()
    onFileDropped = new EventEmitter<File[]>();

    @ViewChild('dropContainer')
    dropContainer: ElementRef;

    lastTarget = null;

    dragEnterListener: any;
    dragLeaveListener: any;
    dragOverListener: any;
    dropListener: any;

    ngOnInit(): void {
        this.dragEnterListener = this.showDropContainer.bind(this);
        this.dragLeaveListener = this.hideDropContainer.bind(this);
        this.dragOverListener = this.preventAndStop.bind(this);
        this.dropListener = this.handleFileDrop.bind(this);
    }

    fileDropIsVisibleOnDom(elementIsVisible: boolean): void {
        if (elementIsVisible) {
            this.setupListeners();
        } else {
            this.tearDownListeners();
        }
    }

    ngOnDestroy(): void {
        this.tearDownListeners();
    }

    private setupListeners(): void {
        window.addEventListener('dragenter', this.dragEnterListener, true);
        window.addEventListener('dragleave', this.dragLeaveListener, true);
        window.addEventListener('dragover', this.dragOverListener, true);
        window.addEventListener('drop', this.dropListener, true);
    }

    private tearDownListeners(): void {
        window.removeEventListener('dragenter', this.dragEnterListener, true);
        window.removeEventListener('dragleave', this.dragLeaveListener, true);
        window.removeEventListener('dragover', this.dragOverListener, true);
        window.removeEventListener('drop', this.dropListener, true);
    }

    private showDropContainer(e: DragEvent): void {
        const itemBeingDraggedIsAFile = e.dataTransfer?.types?.indexOf('Files') !== -1;
        if (itemBeingDraggedIsAFile) {
            this.lastTarget = e.target;
            this.dropContainer.nativeElement.style.opacity = '1';
            this.dropContainer.nativeElement.style.fontSize = '3.2rem';
            this.dropContainer.nativeElement.style.pointerEvents = 'all';
        }
    }

    private preventAndStop(e: DragEvent): void {
        e.preventDefault();
        e.stopPropagation();
    }

    private hideDropContainer(e: DragEvent): void {
        this.preventAndStop(e);
        if (e.target === this.lastTarget || e.target === document) {
            this.dropContainer.nativeElement.style.opacity = 0;
            this.dropContainer.nativeElement.style.fontSize = '3rem';
            this.dropContainer.nativeElement.style.pointerEvents = 'none';
        }
    }

    private handleFileDrop(e: DragEvent): void {
        this.preventAndStop(e);
        this.hideDropContainer(e);
        if (!!e.dataTransfer?.files?.length) {
            this.onFileDropped.emit(Array.from(e.dataTransfer.files) || []);
        }
    }
}
