import { Directive, HostBinding, PLATFORM_ID, Inject, Input, ElementRef, OnChanges } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Directive({
    selector: '[externalLink]',
})
export class ExternalLinkDirective implements OnChanges {
    @HostBinding('attr.rel') relAttr = null;
    @HostBinding('attr.target') targetAttr = null;
    @Input() externalLink: string;

    constructor(
        @Inject(PLATFORM_ID) private platformId: string,
        private elementRef: ElementRef,
    ) {}

    ngOnChanges() {
        this.elementRef.nativeElement.href = this.externalLink && this.parseURL(this.externalLink);
        if (this.isLinkExternal()) {
            this.relAttr = 'noopener';
            this.targetAttr = '_blank';
        } else {
            this.relAttr = null;
            this.targetAttr = null;
        }
    }

    private isLinkExternal() {
        return (
            isPlatformBrowser(this.platformId) && !this.elementRef.nativeElement.hostname.includes(location.hostname)
        );
    }

    private parseURL(url: string) {
        if (!url?.startsWith('http://') && !url?.startsWith('https://')) {
            url = 'https://' + url;
        }
        return url;
    }
}
