import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { VendorRiskAdvisoryDialogComponent } from '../../../../shared/vendor-components/vendor-risk-advisory-dialog/vendor-risk-advisory-dialog.component';
import { Injectable } from '@angular/core';
import { openCreateRiskAdvisoryDialog } from '../actions/risk-advisory.actions';
import { MatDialogWrapperService } from '../../../../shared/modal/mat-dialog-wrapper.service';

@Injectable()
export class RiskAdvisoryEffects {
    requestOpenCreateRiskAdvisoryDialog$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(openCreateRiskAdvisoryDialog),
                tap(({ orgId, name }) =>
                    this._dialogService.open(VendorRiskAdvisoryDialogComponent, {
                        inputs: {
                            orgId,
                            name,
                        },
                        config: {
                            maxWidth: 700,
                            maxHeight: 1000,
                        },
                    }),
                ),
            ),
        { dispatch: false },
    );

    constructor(
        private _actions$: Actions,
        private _dialogService: MatDialogWrapperService,
    ) {}
}
