<div class="modal-header">
    <i class="fas fa-exclamation-triangle"></i>
    <div class="title-text">
        <span>Oh Snap, </span>
        <span class="error-title">{{ data.errorSubheader || 'something went wrong...' }}</span>
        <span class="error-title" [hidden]="true">something went wrong...</span>
    </div>
</div>

<div class="modal-body">
    <div class="message mb-2">
        <ng-container *ngIf="data.errorMainMessage || data.errorMainMessageHtml; else standardMessages">
            <div
                class="main-message"
                *ngIf="data.errorMainMessageHtml; else messageTmpl"
                [innerHTML]="data.errorMainMessageHtml"
            ></div>
            <ng-template #messageTmpl>
                <span class="main-message">{{ data.errorMainMessage }}</span>
            </ng-template>
        </ng-container>
        <ng-template #standardMessages>
            <span *ngIf="data.defaultDismiss" class="main-message"
                >Application encountered an error, however you may safely retry your last action or navigate back. Our
                Team was already informed.</span
            >
            <span *ngIf="!data.defaultDismiss" class="main-message"
                >Application encountered an error and may remain unstable. We advise you to reload application. Our Team
                was already informed.</span
            >
        </ng-template>
        <span [hidden]="true" class="main-message"
            >Application encountered an error and needs to be reloaded. Our Team was already informed.</span
        >
        <div #mainData class="main-data d-none"></div>
    </div>
    <button #moreInfoBtn mat-button *ngIf="!isProduction" (click)="showMoreInfo()">More info...</button>
    <pre #moreInfo class="more-info d-none"></pre>
</div>

<div class="modal-footer">
    <ng-container *ngIf="data.buttonText; else standardButtons">
        <button mat-flat-button color="success" (click)="closeModal()">{{ data.buttonText }}</button>
    </ng-container>
    <ng-template #standardButtons>
        <span *ngIf="data.defaultDismiss">
            <button mat-flat-button class="me-2" (click)="reloadApp()">Reload application</button>
            <button mat-flat-button color="accent" (click)="closeModal()">Continue</button>
        </span>
        <span *ngIf="!data.defaultDismiss">
            <button mat-flat-button class="me-2" (click)="closeModal()">Ignore</button>
            <button mat-flat-button color="accent" cdkFocusInitial (click)="reloadApp()">Reload application</button>
        </span>
    </ng-template>
    <span [hidden]="true">
        <button mat-flat-button color="accent" onclick="window.location.reload()">Reload application</button>
    </span>
</div>
