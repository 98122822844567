import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { FeatureFlag } from '../../entities/feature-flag/feature-flag.model';

@Injectable()
export class FeatureFlagsManagementService {
    private _resourceUrl = '/api/feature-flags';

    constructor(private _http: HttpClient) {}

    getFeatureFlags(): Observable<string[]> {
        return this._http.get<string[]>(this._resourceUrl);
    }

    getFeatureFlag(featureFlag: string): Observable<FeatureFlag> {
        return this._http.get<FeatureFlag>(`${this._resourceUrl}/${featureFlag}`);
    }

    setFeatureFlag(featureFlag: string, value: boolean): Observable<FeatureFlag> {
        return this._http.put<FeatureFlag>(`${this._resourceUrl}/${featureFlag}/enabled/${value}`, null);
    }

    addOrgPerOrgFlag(featureFlag: string, orgId: number): Observable<FeatureFlag> {
        return this._http.post<FeatureFlag>(`${this._resourceUrl}/${featureFlag}/orgIds/${orgId}`, null);
    }

    removeOrgPerOrgFlag(featureFlag: string, orgId: number): Observable<void> {
        return this._http.delete<void>(`${this._resourceUrl}/${featureFlag}/orgIds/${orgId}`);
    }
}
