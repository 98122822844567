import { Component, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { updateUserProfile } from '../../session/redux/session.actions';

@Component({
    selector: 'app-assessment-welcome-message',
    templateUrl: 'assessment-welcome-message.component.html',
    styleUrls: ['assessment-welcome-message.component.scss'],
})
export class AssessmentWelcomeMessageComponent implements OnDestroy {
    constructor(
        private _activeModal: NgbActiveModal,
        private _store$: Store,
    ) {}

    ngOnDestroy(): void {
        this._store$.dispatch(updateUserProfile({ userProfile: { showFirstAssessmentCreatedMessage: false } }));
    }

    clear() {
        this._activeModal.dismiss();
    }
}
