import { AfterViewInit, ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Actions, ofType } from '@ngrx/effects';
import { ActionCreator, select, Store } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';
import { combineLatest, merge, Observable, of, Subject } from 'rxjs';
import { delay, map, takeUntil, tap, throttleTime } from 'rxjs/operators';
import { VisoUserRole } from '../../../../entities/viso-user';
import {
    ArtifactOwnershipLevel,
    ArtifactOwnershipLevelLabels,
    UploadFileArtifactRequest,
    UploadURLArtifactRequest,
    URLArtifactMetadata,
} from '../../../../entities/artifact';
import { getUserAuthority } from '../../../../routes/session/redux/session.selectors';
import { SnackbarService } from '../../../components/snackbar/snackbar.service';
import { FileDropDirective, FileItem, FileLikeObject, FileUploader } from '../../../file-upload';
import { ControlsOf } from '../../../model/controls-of';
import { EnumSelectOption } from '../../../model/select-options';
import { FormUtilsService } from '../../../utils/form-utils.service';
import { UploadArtifactsRequest, UploadArtifactsSections } from '../../models';
import { urlValidator } from '../../../validators/url-validator';

interface UploadArtifactFormGroup {
    artifactOwnership: ArtifactOwnershipLevel;
    thirdPartyEmail: string | null;
    source: string | null;
}

export class MimeType {
    public static XLSX = new MimeType('.xlsx', ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']);
    public static XLS = new MimeType('.xls', ['application/vnd.ms-excel']);
    public static XLSM = new MimeType('.xlsm', ['application/vnd.ms-excel.sheet.macroEnabled.12']);
    public static CSV = new MimeType('.csv', ['text/csv']);
    public static PNG = new MimeType('.png', ['image/png']);
    public static ICO = new MimeType('.ico', ['image/x-icon', 'image/vnd.microsoft.icon']);
    public static JPG = new MimeType('.jpg', ['image/jpeg']);
    public static GIF = new MimeType('.gif', ['image/gif']);

    private constructor(
        public readonly extension: string,
        public readonly mimeType: string[],
    ) {}
}

@Component({
    selector: 'app-upload-artifacts-modal',
    templateUrl: './upload-artifacts-modal.component.html',
    styleUrls: ['./upload-artifacts-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UploadArtifactsModalComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input()
    invokedFor: UploadArtifactsSections;

    @Input()
    actions: {
        requestAction: ActionCreator<any, (props: UploadArtifactsRequest) => UploadArtifactsRequest & TypedAction<any>>;
        requestSuccessAction: ActionCreator;
        requestFailedAction: ActionCreator;
        requestExtraParams?: any;
    };

    @Input()
    titleText: string = 'Upload Artifacts';

    @Input()
    allowedMimeTypes: MimeType[] = [];

    @Input()
    includePassword: boolean = true;

    @Input()
    forceShowAddURLArtifact: boolean = false;

    urlArtifacts: string[] = [];
    assessmentInProgress?: boolean = null;
    artifactOwnershipFormGroup: FormGroup<ControlsOf<UploadArtifactFormGroup>>;
    addURLArtifactFormControl: FormControl<string>;
    uploader: FileUploader;
    onDrag$: Observable<boolean>;
    ownershipLevels$: Observable<EnumSelectOption[]>;
    uploadDisabled$: Observable<boolean>;
    uploadingFiles$: Observable<boolean>;
    showAddURLArtifactSection$: Observable<boolean>;
    footerMessage$: Observable<string>;

    Roles = VisoUserRole;

    @ViewChild(FileDropDirective)
    private _fileDropZone: FileDropDirective;
    private _unsub$ = new Subject<void>();
    private _fileQueueChange$ = new Subject<void>();
    private _urlQueueChange$ = new Subject<void>();

    constructor(
        private _fb: FormBuilder,
        private _store$: Store,
        private _actions$: Actions,
        private _activeModal: NgbActiveModal,
        private _formUtils: FormUtilsService,
        private _snackbarService: SnackbarService,
    ) {}

    get invokedForArtifactIntelligence(): boolean {
        return this.invokedFor === UploadArtifactsSections.ARTIFACT_INTELLIGENCE_PAGE;
    }

    get artifactOwnershipValue(): ArtifactOwnershipLevel {
        return this.artifactOwnershipFormGroup.controls.artifactOwnership.value;
    }

    get showThirdPartyEmailField(): boolean {
        return this.artifactOwnershipValue === ArtifactOwnershipLevel.THIRD_PARTY;
    }

    get showSourceField(): boolean {
        return this.artifactOwnershipValue === ArtifactOwnershipLevel.VISO;
    }

    get disabledAddURLArtifactFormControl(): boolean {
        return !(!!this.addURLArtifactFormControl.value && this.addURLArtifactFormControl.valid);
    }

    get isThereFileArtifactQueue(): boolean {
        return !!this.uploader.queue.length;
    }

    get isThereURLArtifactQueue(): boolean {
        return !!this.urlArtifacts.length;
    }

    get isThereArtifactQueue(): boolean {
        return this.isThereFileArtifactQueue || this.isThereURLArtifactQueue;
    }

    ngOnInit(): void {
        this.artifactOwnershipFormGroup = this._fb.group({
            artifactOwnership: this._fb.control(ArtifactOwnershipLevel.PRIVATE, {
                validators: Validators.required,
                nonNullable: true,
            }),
            thirdPartyEmail: this._fb.control(
                { value: '', disabled: true },
                { validators: [Validators.required, Validators.email] },
            ),
            source: this._fb.control({ value: '', disabled: true }, { validators: Validators.required }),
        });

        this.addURLArtifactFormControl = this._fb.control('', urlValidator);

        const controls = this.artifactOwnershipFormGroup.controls;
        controls.artifactOwnership.valueChanges.pipe(takeUntil(this._unsub$)).subscribe((ownership) => {
            const { thirdPartyEmail, source } = controls;
            thirdPartyEmail.setValue('');
            source.setValue('');
            switch (ownership) {
                case ArtifactOwnershipLevel.VISO:
                    thirdPartyEmail.disable();
                    source.enable();
                    break;
                case ArtifactOwnershipLevel.THIRD_PARTY:
                    thirdPartyEmail.enable();
                    source.disable();
                    break;

                case ArtifactOwnershipLevel.PRIVATE:
                default:
                    thirdPartyEmail.disable();
                    source.disable();
                    break;
            }
        });

        this.ownershipLevels$ = of([
            {
                enumValue: ArtifactOwnershipLevel.VISO,
                name: ArtifactOwnershipLevelLabels.VISO,
            },
            {
                enumValue: ArtifactOwnershipLevel.THIRD_PARTY,
                name: ArtifactOwnershipLevelLabels.THIRD_PARTY,
            },
            {
                enumValue: ArtifactOwnershipLevel.PRIVATE,
                name: ArtifactOwnershipLevelLabels.PRIVATE,
            },
            {
                enumValue: ArtifactOwnershipLevel.PUBLIC,
                name: ArtifactOwnershipLevelLabels.PUBLIC,
            },
        ]);

        this.uploader = new FileUploader({
            url: '',
            maxFileSize: 100000000,
            allowedMimeType:
                this.allowedMimeTypes?.length > 0
                    ? this.allowedMimeTypes.flatMap((mimeType) => mimeType.mimeType)
                    : null,
        });

        this.uploader.onAfterAddingFile = () => {
            this._fileQueueChange$.next();
        };

        this.uploadingFiles$ = merge(
            of(false),
            this._actions$.pipe(
                ofType(this.actions.requestAction),
                map(() => true),
            ),
            this._actions$.pipe(
                ofType(this.actions.requestSuccessAction, this.actions.requestFailedAction),
                delay(1000),
                map(() => false),
            ),
        );

        this.uploadDisabled$ = combineLatest([
            merge(
                of(this.artifactOwnershipFormGroup.invalid),
                this.artifactOwnershipFormGroup.valueChanges.pipe(map(() => this.artifactOwnershipFormGroup.invalid)),
            ),
            merge(of(!this.uploader.queue.length), this._fileQueueChange$.pipe(map(() => !this.uploader.queue.length))),
            merge(of(!this.urlArtifacts.length), this._urlQueueChange$.pipe(map(() => !this.urlArtifacts.length))),
            this.uploadingFiles$,
        ]).pipe(
            map(
                ([formInvalid, noFileQueue, noUrlQueue, onRequest]) =>
                    formInvalid || onRequest || (noFileQueue && noUrlQueue),
            ),
        );

        this.showAddURLArtifactSection$ = this.forceShowAddURLArtifact
            ? of(true)
            : this._store$.pipe(select(getUserAuthority(VisoUserRole.Auditor)));

        this.footerMessage$ = this._fileQueueChange$.pipe(
            map(() => this.uploader.queue.length),
            map((queueCount) => {
                if (!queueCount || this.assessmentInProgress === null) {
                    return '';
                }
                return this.assessmentInProgress
                    ? 'These artifacts will be added to the current assessment upon upload.'
                    : 'A new assessment to validate these artifacts will be started upon upload.';
            }),
        );

        this._actions$
            .pipe(
                ofType(this.actions.requestSuccessAction),
                tap(() => this.close()),
                takeUntil(this._unsub$),
            )
            .subscribe();

        this.uploader.onWhenAddingFileFailed = (item: FileLikeObject, filter: any, options: any) => {
            let errorMessage;
            if (filter.name === 'fileSize') {
                errorMessage = 'File size must not be empty and less than 100MB';
            } else if (filter.name === 'mimeType') {
                errorMessage = `File type must be ${this.allowedMimeTypes
                    .map((mimeType) => mimeType.extension)
                    .join(', ')}`;
            } else {
                errorMessage = `Filter failed: ${filter.name}`;
            }
            this._snackbarService.error(errorMessage);
        };
    }

    ngAfterViewInit(): void {
        this.onDrag$ = this._fileDropZone.fileOver.pipe(
            throttleTime(500, undefined, { leading: true, trailing: true }),
        );
    }

    setArtifactPassword(password: string, item: FileItem): void {
        item.formData = { password };
    }

    removeItem(fileItem: FileItem): void {
        fileItem.remove();
        this._fileQueueChange$.next();
    }

    upload(): void {
        const fileRequests = this.getFileArtifactUploadRequests();
        const urlRequests = this.getURLArtifactUploadRequests();
        const action = this.actions.requestAction({
            fileRequests,
            urlRequests,
            ...(this.actions.requestExtraParams || {}),
        });
        this._store$.dispatch(action);
    }

    addUrlArtifact(): void {
        const urlArtifact = this.addURLArtifactFormControl.value.trim();
        this.urlArtifacts.push(urlArtifact.startsWith('http') ? urlArtifact : `https://${urlArtifact}`);
        this.addURLArtifactFormControl.reset();
        this._urlQueueChange$.next();
    }

    removeUrlArtifact(index: number): void {
        this.urlArtifacts.splice(index, 1);
        this._urlQueueChange$.next();
    }

    close() {
        this.uploader.clearQueue();
        this._activeModal.dismiss('cancel');
    }

    ngOnDestroy(): void {
        this._unsub$.next();
    }

    private getFileArtifactUploadRequests(): UploadFileArtifactRequest[] {
        const artifactOwnershipMetadata = this._formUtils.getCleanFormGroupValue<UploadArtifactFormGroup>(
            this.artifactOwnershipFormGroup,
        );
        return this.uploader.queue.map<UploadFileArtifactRequest>((queueFile) => ({
            file: queueFile._file,
            fileArtifactMetaData: {
                artifactOwnershipType: artifactOwnershipMetadata.artifactOwnership,
                source: artifactOwnershipMetadata.source,
                thirdPartyEmail: artifactOwnershipMetadata.thirdPartyEmail,
                artifactPassword: queueFile.formData?.password,
            },
        }));
    }

    private getURLArtifactUploadRequests(): UploadURLArtifactRequest[] {
        const {
            artifactOwnership: artifactOwnershipType,
            source,
            thirdPartyEmail,
        } = this._formUtils.getCleanFormGroupValue<UploadArtifactFormGroup>(this.artifactOwnershipFormGroup);
        const metaData: URLArtifactMetadata = {
            artifactOwnershipType,
            source,
            thirdPartyEmail,
        };
        return this.urlArtifacts.map<UploadURLArtifactRequest>((url) => ({
            url,
            metaData,
        }));
    }
}
