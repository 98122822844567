import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { DomainVerification } from './domain-verification.model';

export type EntityResponseType = HttpResponse<DomainVerification>;

@Injectable({
    providedIn: 'root',
})
export class DomainVerificationService {
    private resourceUrl = 'api/org/domains';

    constructor(private _http: HttpClient) {}

    getVerifications(): Observable<DomainVerification[]> {
        return this._http.get<DomainVerification[]>(`${this.resourceUrl}/verifications`);
    }

    getVerification(domain: string): Observable<DomainVerification> {
        return this._http.get<DomainVerification>(`${this.resourceUrl}/verification/${domain}`);
    }
}
