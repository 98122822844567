import { Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Subject, combineLatest, takeUntil } from 'rxjs';
import { VisoUser, VisoUserRole } from '../../../entities/viso-user';
import { getUserAccount, getUserAuthority } from '../../../routes/session/redux/session.selectors';

@Directive({
    selector: '[hideIfOrgUserIsNotBusinessOwner]',
})
export class HideIfOrgUserIsNotBusinessOwnerDirective implements OnInit, OnDestroy {
    @Input({ required: true, alias: 'hideIfOrgUserIsNotBusinessOwner' })
    set businessOwner(value: VisoUser) {
        this._businessOwner$.next(value);
    }

    private _businessOwner$ = new BehaviorSubject<VisoUser>(null);
    private _unSub$ = new Subject<void>();

    constructor(
        private _store$: Store,
        private _templateRef: TemplateRef<any>,
        private _viewContainerRef: ViewContainerRef,
    ) {}

    ngOnInit(): void {
        this.removeView();
        combineLatest([
            this._businessOwner$,
            this._store$.select(getUserAuthority(VisoUserRole.OrgUser)),
            this._store$.select(getUserAccount),
        ])
            .pipe(takeUntil(this._unSub$))
            .subscribe(([businessOwner, isOrgUser, orgUser]) => {
                if (!isOrgUser) {
                    this.createViewIfDoesntExist();
                    return;
                }
                if (businessOwner.id === orgUser.id) {
                    this.createViewIfDoesntExist();
                } else {
                    this.removeView();
                }
            });
    }

    ngOnDestroy(): void {
        this._unSub$.next();
    }

    private createViewIfDoesntExist(): void {
        if (!this._viewContainerRef.length) {
            this._viewContainerRef.createEmbeddedView(this._templateRef);
        }
    }

    private removeView(): void {
        this._viewContainerRef.clear();
    }
}
