import * as LogRocket from 'logrocket';
import createNgrxMiddleware from 'logrocket-ngrx';
import { environment } from '../../../environments/environment';
import { fieldNamesToSanitize } from '../../../logrocket/sanitation-constants';

// see https://docs.logrocket.com/reference/redux-logging for options
export const logrocketMetareducer = createNgrxMiddleware(LogRocket, {
    stateSanitizer: (state) => (environment.production ? {} : state),
    actionSanitizer: (action: any) => {
        return environment.production
            ? action.type.includes('@ngrx')
                ? null
                : copyAndSanitize(JSON.parse(JSON.stringify(action)))
            : action;
    },
});

function copyAndSanitize(obj: any): any {
    // Sanitize keys - recursively if the key is an object.
    const result = { ...obj };
    for (const key in result) {
        if (typeof result[key] === 'object') {
            if (Array.isArray(result[key])) {
                // tslint:disable-next-line:forin
                for (const subKey in result[key]) {
                    result[key][subKey] = copyAndSanitize(result[key][subKey]);
                }
            } else {
                result[key] = copyAndSanitize(result[key]);
            }
        } else if (fieldNamesToSanitize.includes(key)) {
            result[key] = '*';
        }
    }
    return result;
}
