import { Inject, Injectable, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable()
export class GoogleTagInScriptInjectorService {
    constructor(
        private _renderer2: Renderer2,
        @Inject(DOCUMENT) private _document: Document,
    ) {}

    injectScript(): void {
        // Include necessary variables
        const windowRef = window as any;
        windowRef.dataLayer = windowRef.dataLayer || [];

        function gtag(...args) {
            windowRef.dataLayer.push(arguments);
        }

        gtag('js', new Date());
        gtag('config', 'GTM-PM8LL78', { cookie_flags: 'max-age=7200;secure;samesite=none' });

        // Create Script & append it to body
        const scriptTag = this._renderer2.createElement('script') as HTMLScriptElement;
        scriptTag.type = 'text/javascript';
        scriptTag.async = true;
        scriptTag.src = 'https://www.googletagmanager.com/gtm.js?id=GTM-PM8LL78';

        // Create NoScript Tag with track frame & append it to body
        const noScriptTag = this._renderer2.createElement('noscript');
        const iframeTag = this._renderer2.createElement('iframe') as HTMLIFrameElement;
        iframeTag.height = '0';
        iframeTag.width = '0';
        this._renderer2.setStyle(iframeTag, 'display', 'none');
        this._renderer2.setStyle(iframeTag, 'visibility', 'hidden');
        iframeTag.src = 'https://www.googletagmanager.com/ns.html?id=GTM-PM8LL78';
        this._renderer2.appendChild(noScriptTag, iframeTag);
        this._renderer2.appendChild(this._document.body, noScriptTag);
        this._renderer2.appendChild(this._document.body, scriptTag);
    }
}
