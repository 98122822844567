import { createAction, props } from '@ngrx/store';
import { Relationship, RelationshipOnboardedView } from '@entities/relationship';
import { Risk } from '@entities/risk-assessment';
import { OnboardRequest } from '../../models/onboarding.model';

export enum OnboardActions {
    OpenOnboardModal = '[Onboard] Open Onboard Modal',
    OnboardModalCancelled = '[Onboard] Onboard Modal Cancelled',

    OnboardRelationshipRequest = '[Onboard] Onboard Relationship Request',
    OnboardRelationshipRequestSuccess = '[Onboard] Onboard Relationship Request Success',
    OnboardRelationshipRequestFailed = '[Onboard] Onboard Relationship Request Failed',
}

export const openOnboardModal = createAction(
    OnboardActions.OpenOnboardModal,
    props<{
        relationship: Relationship;
        vendorName: string;
        risk: Risk;
        riskIsTransitional: boolean;
        dateOfNextArtifactExpiration?: Date;
    }>(),
);

export const onboardModalCancelled = createAction(OnboardActions.OnboardModalCancelled);

export const onboardRelationshipRequest = createAction(
    OnboardActions.OnboardRelationshipRequest,
    props<{ relationshipId: number; onboardRequest?: OnboardRequest }>(),
);

export const onboardRelationshipSuccess = createAction(
    OnboardActions.OnboardRelationshipRequestSuccess,
    props<{
        relationshipOnboardedView: RelationshipOnboardedView;
    }>(),
);

export const onboardRelationshipFailed = createAction(OnboardActions.OnboardRelationshipRequestFailed);
