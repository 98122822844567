<div class="row mb-3">
    <div class="col">
        <h3 class="lead">Subscribers</h3>
    </div>
    <div class="col-auto">
        <a
            *jhiHasNotAuthority="[Roles.Auditor, Roles.Admin, Roles.Support, Roles.OrgUser, Roles.ReadOnly]"
            exportRemovableAction
            class="text-primary"
            [routerLink]="addLink"
            [replaceUrl]="true"
        >
            Add
        </a>
    </div>
</div>
<div class="row" *ngIf="subscriberListExists; else noSubscribers">
    <div class="col">
        <accordion>
            <accordion-group #accordionItem *ngFor="let subscriber of relationshipSubscribers$ | async">
                <div accordion-heading class="d-flex justify-content-between cursor-pointer">
                    <p class="mb-0">{{ subscriber.fullname }}</p>
                    <div class="d-flex align-items-center">
                        <em
                            class="fa fa-xs"
                            [ngClass]="{
                                'fa-chevron-down': !accordionItem.isOpen,
                                'fa-chevron-up': accordionItem.isOpen,
                            }"
                        ></em>
                    </div>
                </div>
                <div class="d-flex justify-content-between">
                    <div>
                        <strong class="mb-0">{{ subscriber.email }}</strong>
                        <p class="text-muted mb-0">{{ subscriber.businessUnitName }}</p>
                    </div>
                    <a
                        *jhiHasNotAuthority="[Roles.Support, Roles.ReadOnly]"
                        class="text-primary cursor-pointer"
                        exportRemovableAction
                        (click)="unsubscribeSubscriber.emit(subscriber.id)"
                    >
                        Unsubscribe
                    </a>
                </div>
            </accordion-group>
        </accordion>
    </div>
</div>
<ng-template #noSubscribers>
    <p class="text-muted">No subscribers added yet.</p>
</ng-template>
