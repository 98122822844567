<div *dynamicContent="breadcrumbsContainerToken">
    <ol class="breadcrumb breadcrumb px-0 pb-0">
        <li class="breadcrumb-item"><a [routerLink]="['/']">Home</a></li>
        <li class="breadcrumb-item active">Users</li>
    </ol>
</div>

<div class="content-heading">
    <div>Users</div>
</div>
<div class="row">
    <div class="col-xl-12">
        <div class="card card-border d-flex">
            <div class="card-header px-4 py-3">
                <a class="text-bold">{{ numberOfContactsHeader$ | async }}</a>
                <button
                    *jhiHasAnyAuthority="Roles.OrgAdmin"
                    mat-button
                    visoButton="primary"
                    class="float-end"
                    [routerLink]="['/', { outlets: { popup: ['user-new'] } }]"
                >
                    Add User
                </button>
            </div>

            <ng-container
                *ngIf="{
                    contacts: contacts$ | async,
                } as ctx"
            >
                <ng-container *ngIf="ctx.contacts && ctx.contacts.length; else noContacts">
                    <div class="row table-container px-4">
                        <div class="col">
                            <ngx-datatable
                                #contactList
                                class="bootstrap"
                                [rows]="ctx.contacts"
                                [loadingIndicator]="contactsLoading"
                                [columnMode]="'force'"
                                [footerHeight]="40"
                                [rowHeight]="35"
                                [headerHeight]="30"
                                [scrollbarV]="true"
                                [externalSorting]="true"
                                (sort)="onSort($event)"
                                [sorts]="tableSort$ | async"
                                [externalPaging]="true"
                                [virtualization]="false"
                                [count]="totalCurrentItems"
                                [offset]="page"
                                [limit]="itemsPerPage"
                                [observeDims]="true"
                                [rowClass]="getRowInactiveClass"
                                data-pw="contactListTable"
                            >
                                <ngx-datatable-column name="Name">
                                    <ng-template let-row="row" ngx-datatable-cell-template>
                                        <span>{{ row.firstName + ' ' + row.lastName }}</span>
                                    </ng-template>
                                </ngx-datatable-column>
                                <ngx-datatable-column name="Email" prop="email">
                                    <ng-template let-row="row" ngx-datatable-cell-template>
                                        <a [routerLink]="['/user-profile/' + row.id]">{{ row.email }}</a>
                                    </ng-template>
                                </ngx-datatable-column>
                                <ngx-datatable-column
                                    name="Business Unit"
                                    prop="businessUnitName"
                                ></ngx-datatable-column>
                                <ngx-datatable-column name="Subscriptions" prop="subscriptionCount">
                                    <ng-template let-row="row" ngx-datatable-cell-template>
                                        <span
                                            [ngClass]="{
                                                'text-muted': row.subscriptionCount === 0,
                                            }"
                                            >{{ row.subscriptionCount }}</span
                                        >
                                    </ng-template>
                                </ngx-datatable-column>
                                <ngx-datatable-column name="Ownerships" prop="businessOwnerCount">
                                    <ng-template let-row="row" ngx-datatable-cell-template>
                                        <a
                                            [routerLink]="['/relationships']"
                                            [queryParams]="{
                                                search:
                                                    'drStatus:[NOT_ONBOARDED|ONBOARDED|ARCHIVED],businessOwnerId:' +
                                                    row.id,
                                            }"
                                        >
                                            {{ row.businessOwnerCount }}
                                        </a>
                                    </ng-template>
                                </ngx-datatable-column>
                                <ngx-datatable-column name="Role" prop="authorities" [cellClass]="'cell-centered'">
                                    <ng-template let-row="row" ngx-datatable-cell-template>
                                        <span *ngIf="row.authorities && row.authorities.length">
                                            <span
                                                class="badge badge-square bg-green"
                                                *ngFor="let role of row.authorities"
                                            >
                                                {{ role | callback: formatUserRole | titlecase }}
                                            </span>
                                        </span>
                                    </ng-template>
                                </ngx-datatable-column>
                                <ngx-datatable-column
                                    *jhiHasNotAuthority="[Roles.OrgUser, Roles.ReadOnly]"
                                    [width]="40"
                                    cellClass="order-50 text-center bg-white"
                                    headerClass="order-50"
                                    [resizable]="false"
                                    [sortable]="false"
                                    [canAutoResize]="false"
                                    [draggable]="false"
                                    [frozenRight]="false"
                                >
                                    <ng-template let-row="row" ngx-datatable-cell-template>
                                        <div
                                            class="additional-actions"
                                            placement="bottom left"
                                            dropdown
                                            container="body"
                                        >
                                            <button
                                                id="button-container"
                                                dropdownToggle
                                                type="button"
                                                class="btn-icon float-end"
                                                aria-controls="dropdown-container"
                                            >
                                                <em class="fa fa-ellipsis-v"></em>
                                            </button>
                                            <ul
                                                id="dropdown-container"
                                                *dropdownMenu
                                                class="dropdown-menu dropdown-menu-end"
                                                aria-labelledby="button-container"
                                            >
                                                <li role="menuitem">
                                                    <a
                                                        class="dropdown-item"
                                                        [routerLink]="[
                                                            '/',
                                                            { outlets: { popup: 'user/' + row.id + '/edit' } },
                                                        ]"
                                                    >
                                                        Edit
                                                    </a>
                                                </li>
                                                <li role="menuitem">
                                                    <a
                                                        [ngClass]="{
                                                            disabled:
                                                                row.subscriptionCount === 0 &&
                                                                row.businessOwnerCount === 0,
                                                        }"
                                                        class="dropdown-item"
                                                        [routerLink]="[
                                                            '/',
                                                            { outlets: { popup: 'user/' + row.id + '/transfer' } },
                                                        ]"
                                                    >
                                                        Manage Assignments
                                                    </a>
                                                </li>
                                                <hr class="m-0 border-2" />
                                                <span *ngIf="!row.managedByIdp">
                                                    <li role="menuitem" class="mt-2 mb-1">
                                                        <a
                                                            [ngClass]="{
                                                                disabled: row.managedByIdp,
                                                            }"
                                                            class="dropdown-item text-danger"
                                                            (click)="deleteContact(row)"
                                                        >
                                                            Delete
                                                        </a>
                                                    </li>
                                                </span>
                                                <span *ngIf="row.managedByIdp">
                                                    <li role="menuitem" class="mt-2 mb-1">
                                                        <a
                                                            class="dropdown-item"
                                                            [ngClass]="
                                                                row.status === UserStatus.ACTIVE
                                                                    ? 'text-danger'
                                                                    : 'text-primary'
                                                            "
                                                            (click)="updateManagedContactStatus(row)"
                                                        >
                                                            {{
                                                                row.status === UserStatus.ACTIVE
                                                                    ? 'Deactivate'
                                                                    : 'Activate'
                                                            }}
                                                        </a>
                                                    </li>
                                                </span>
                                            </ul>
                                        </div>
                                    </ng-template>
                                </ngx-datatable-column>

                                <ngx-datatable-footer>
                                    <ng-template
                                        ngx-datatable-footer-template
                                        let-page="page"
                                        let-rowCount="rowCount"
                                        let-pageSize="pageSize"
                                        let-curPage="curPage"
                                    >
                                        <datatable-pager
                                            [pagerLeftArrowIcon]="'datatable-icon-left'"
                                            [pagerRightArrowIcon]="'datatable-icon-right'"
                                            [pagerPreviousIcon]="'datatable-icon-prev'"
                                            [pagerNextIcon]="'datatable-icon-skip'"
                                            [page]="curPage"
                                            [size]="pageSize"
                                            [count]="rowCount"
                                            [hidden]="!(rowCount / pageSize > 1)"
                                            (change)="setCurrentPage($event)"
                                        ></datatable-pager>
                                    </ng-template>
                                </ngx-datatable-footer>
                            </ngx-datatable>
                        </div>
                    </div>
                </ng-container>
            </ng-container>

            <ng-template #noContacts>
                <ng-container *ngIf="contactsLoading">
                    <div class="d-flex align-items-center justify-content-center">
                        <div class="sk-spinner sk-spinner-pulse"></div>
                    </div>
                </ng-container>
                <ng-container *ngIf="!contactsLoading">
                    <div class="row">
                        <div class="block-center mt-4 wd-xl">
                            <div class="card card-flat">
                                <div class="card-body">
                                    <p class="text-center">We have nothing to show you yet</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </ng-template>
        </div>
    </div>
</div>
