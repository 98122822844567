import {
    Component,
    ContentChild,
    ElementRef,
    EventEmitter,
    forwardRef,
    Input,
    OnDestroy,
    OnInit,
    Output,
    Provider,
} from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, startWith, takeUntil, tap } from 'rxjs/operators';
import { Tag, TagColorEnum, TagColors } from '../../../../entities/tag';
import {
    bulkLinkTagsRequest,
    bulkLinkTagsRequestFailed,
    bulkLinkTagsRequestSuccess,
    linkTagRequest,
    linkTagRequestFailed,
    linkTagRequestSuccess,
    unlinkTagRequest,
    unlinkTagRequestFailed,
    unlinkTagRequestSuccess,
} from '../../../../routes/request/redux/request.actions';
import {
    createTagRequest,
    createTagRequestFailed,
    createTagRequestSuccess,
    getTagsRequest,
} from '../../../../routes/tag/redux/tag.actions';
import { getTagsSelector } from '../../../../routes/tag/redux/tag.selectors';
import { AppState } from '../../../redux/state';
import { specialCharactersValidator } from '../../../validators/special-characters-validator';
import { noWhitespaceValidator } from '../../../validators/whitespace-validator';
import { TagActionsContentDirective } from '../../directives/tag-actions-content.directive';

const TAG_SELECT_VALUE_ACCESSOR_PROVIDER: Provider = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => TagSelectComponent),
    multi: true,
};

@Component({
    selector: 'app-tag-select',
    templateUrl: 'tag-select.component.html',
    styleUrls: ['tag-select.component.scss'],
    providers: [TAG_SELECT_VALUE_ACCESSOR_PROVIDER],
})
export class TagSelectComponent implements ControlValueAccessor, OnInit, OnDestroy {
    @ContentChild(TagActionsContentDirective) additionalActionsContent!: TagActionsContentDirective;
    @Input() createTagDisabled = false;
    @Output() search = new EventEmitter<string>();
    @Output() addTag = new EventEmitter<Tag>();
    @Output() removeTag = new EventEmitter<Tag>();

    private _onChange: any;
    // @ts-ignore
    private _onTouched: any;
    disabled: boolean;

    searchControl = new UntypedFormControl(null, [
        Validators.required,
        Validators.maxLength(30),
        specialCharactersValidator(),
        noWhitespaceValidator,
    ]);

    colorValues = TagColors;
    TagColorEnum = TagColorEnum;

    filteredTags$: Observable<Tag[]>;
    selectedTags$ = new BehaviorSubject<Tag[]>([]);
    private selectedValues$ = new BehaviorSubject<Tag[]>([]);
    private availableTags$ = new BehaviorSubject<Tag[]>([]);

    private _unsub$ = new Subject<void>();
    private _tags$: Observable<Tag[]>;

    constructor(
        private _store$: Store<AppState>,
        private _actions$: Actions,
        private _el: ElementRef,
    ) {}

    ngOnInit(): void {
        this._store$.dispatch(getTagsRequest(null));

        const searchChanges$ = this.searchControl.valueChanges.pipe(
            startWith(''),
            tap((value) => this.search.emit(value)),
            debounceTime(50),
            distinctUntilChanged(),
        );

        this._tags$ = this._store$.pipe(select(getTagsSelector));

        this.filteredTags$ = combineLatest([this.availableTags$, searchChanges$]).pipe(
            map(([tags, searchHint]) =>
                searchHint ? tags.filter((tag) => tag.name.toLowerCase().includes(searchHint.toLowerCase())) : tags,
            ),
            map((tags) =>
                tags.sort((a, b) => {
                    if (a.name < b.name) {
                        return -1;
                    }
                    if (a.name > b.name) {
                        return 1;
                    }
                    return 0;
                }),
            ),
        );

        combineLatest([this._tags$, this.selectedValues$])
            .pipe(
                takeUntil(this._unsub$),
                filter(([tags]) => !!tags?.length),
            )
            .subscribe(([tags, selectedValues]) => {
                const tempSelected = [];
                const tempAvailable = [];
                tags.forEach((tag) => {
                    if (!!selectedValues?.find((selectedTag) => selectedTag.id === tag.id)) {
                        tempSelected.push(tag);
                    } else {
                        tempAvailable.push(tag);
                    }
                });
                this.selectedTags$.next(tempSelected);
                this.availableTags$.next(tempAvailable);
            });

        this._actions$
            .pipe(
                takeUntil(this._unsub$),
                ofType(createTagRequestSuccess),
                filter(({ selectTag }) => selectTag),
            )
            .subscribe(({ newTag }) => {
                this.selectTag(null, newTag, true);
            });

        this._actions$
            .pipe(
                takeUntil(this._unsub$),
                ofType(
                    createTagRequestFailed,
                    linkTagRequestSuccess,
                    linkTagRequestFailed,
                    unlinkTagRequestSuccess,
                    unlinkTagRequestFailed,
                    bulkLinkTagsRequestSuccess,
                    bulkLinkTagsRequestFailed,
                ),
            )
            .subscribe(() => {
                this.setDisabledState(false);
                this._el.nativeElement.querySelector('#search-tag-input').focus();
            });

        this._actions$
            .pipe(takeUntil(this._unsub$), ofType(linkTagRequest, unlinkTagRequest, bulkLinkTagsRequest))
            .subscribe(() => {
                this.setDisabledState(true);
            });
    }

    ngOnDestroy(): void {
        this._unsub$.next();
    }

    writeValue(value: Tag[]): void {
        if (!Array.isArray(value)) {
            return;
        }
        if (this.selectedValues$.value?.length === value?.length) {
            return;
        }
        this.selectedValues$.next(value);
    }

    registerOnChange(fn: (_: any) => void): void {
        this._onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this._onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        if (isDisabled) {
            this.searchControl.disable();
        } else {
            this.searchControl.enable();
        }
        this.disabled = isDisabled;
    }

    unselectTag(event: MouseEvent, tag: Tag) {
        event.stopPropagation();
        const selectedTags = this.selectedTags$.value.filter((currentTag) => currentTag.id !== tag.id);
        this.selectedTags$.next(selectedTags);
        this.availableTags$.next([...this.availableTags$.value, tag]);
        this._updateTags(selectedTags);
        this.emitEvent(tag, 'remove');
    }

    deleteLastItem() {
        const tempTags = [...this.selectedTags$.value];
        const lastItem = tempTags.pop();
        this.availableTags$.next([...this.availableTags$.value, lastItem]);
        this.selectedTags$.next(tempTags);
        this._updateTags(tempTags);
        this.emitEvent(lastItem, 'remove');
    }

    selectTag(event: MouseEvent | null, tag: Tag, isNewTag: boolean = false) {
        event?.stopPropagation();
        const tempSelectedTags = [...this.selectedTags$.value, tag];
        this.availableTags$.next(this.availableTags$.value.filter((current) => tag.id !== current.id));
        this.selectedTags$.next(tempSelectedTags);
        this._updateTags(tempSelectedTags);
        this.clearSelection();
        this.emitEvent(tag, 'add');
        if (isNewTag) {
            this.setDisabledState(false);
            this.writeValue(tempSelectedTags);
        }
    }

    private _updateTags(tags: Tag[]) {
        this._onChange(tags);
    }

    private clearSelection(): void {
        this.searchControl.reset();
    }

    checkKeys(event: KeyboardEvent) {
        const currentSearchValue = this.searchControl.value;

        if (event.key === 'Backspace' && !currentSearchValue && this.selectedTags$.value.length > 0) {
            this.deleteLastItem();
        }
    }

    addNewTag(tagName: string) {
        if (!/^[A-Za-z0-9 ]*$/.test(tagName)) {
            return;
        }
        this.setDisabledState(true);
        this._store$.dispatch(
            createTagRequest({
                tagPayload: { color: this.TagColorEnum.COLOR_01, name: tagName.trim() },
                selectTag: true,
            }),
        );
    }

    emitEvent(tag: Tag, event: string): void {
        switch (event) {
            case 'add':
                this.addTag.emit(tag);
                break;
            case 'remove':
                this.removeTag.emit(tag);
                break;
        }
    }
}
