import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DataSensitivityLevel } from '../../../../admin/client-profile/data-sensitivity-levels-config/data-sensitivity-level.model';

@Component({
    selector: 'app-sensitivity-meter',
    templateUrl: './sensitivity-meter.component.html',
    styleUrls: ['./sensitivity-meter.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SensitivityMeterComponent {
    @Input({ required: true })
    sensitivity: number;

    @Input({ required: true })
    sensitivityLevel: DataSensitivityLevel;

    @Input()
    legend: boolean = false; // Input to support showing a legend for the sensitivity meter

    get isLowSelected(): string {
        return !!this.legend ? 'low selected' : this.sensitivityLevel === DataSensitivityLevel.LOW ? 'selected' : '';
    }
    get isMediumSelected(): string {
        return !!this.legend
            ? 'medium selected'
            : this.sensitivityLevel === DataSensitivityLevel.MEDIUM
              ? 'selected'
              : '';
    }
    get isHighSelected(): string {
        return !!this.legend ? 'high selected' : this.sensitivityLevel === DataSensitivityLevel.HIGH ? 'selected' : '';
    }
    get isExtremeSelected(): string {
        return !!this.legend
            ? 'extreme selected'
            : this.sensitivityLevel === DataSensitivityLevel.EXTREME
              ? 'selected'
              : '';
    }

    get invertLabel(): boolean {
        return this.sensitivity < 10;
    }
}
