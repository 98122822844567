import { Route } from '@angular/router';
import { AnnouncementsManagementComponent } from './announcements-management/announcements-management.component';

export const announcementsRoute: Route = {
    path: 'announcements',
    component: AnnouncementsManagementComponent,
    data: {
        pageTitle: 'Announcements',
    },
};
