import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ExternalLinkModule } from '../external-link';
import { VisoButtonModule } from '../viso-button/viso-button.module';
import { DocumentFileComponent } from '@shared/document-file/components';

@NgModule({
    declarations: [DocumentFileComponent],
    imports: [CommonModule, ExternalLinkModule, VisoButtonModule, BsDropdownModule],
    exports: [DocumentFileComponent],
})
export class DocumentFileModule {}
