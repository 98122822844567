import { Injectable } from '@angular/core';

export function isFileTabular(fileName: string): boolean {
    const tabularFileExtensions = ['xlsx', 'xls', 'xlsm', 'csv'];
    const fileExtension = fileName?.slice(fileName?.lastIndexOf('.') + 1)?.toLowerCase();
    return tabularFileExtensions.includes(fileExtension);
}

@Injectable({
    providedIn: 'root',
})
export class FileUtilsService {
    formatFileNameForView(fileName: string): string {
        const numberOfCharactersToShow = 15;
        if (fileName.length > numberOfCharactersToShow && fileName.lastIndexOf('.') > numberOfCharactersToShow) {
            var start = fileName.substring(0, numberOfCharactersToShow);
            var end = fileName.replace(/^.*(?=\.[^.]+$)/g, '..');
            return start + end;
        }
        return fileName;
    }
}
