import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'app-assessment-summary',
    templateUrl: './assessment-summary.component.html',
    styleUrls: ['./assessment-summary.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssessmentSummaryComponent {
    @Input({ required: true })
    summary: string;
}
