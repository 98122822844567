import { Injectable, OnDestroy, TemplateRef, Type } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { ModalData, ModalRef } from '../model/modal-structures';
import { MatDialogWrapperService } from './mat-dialog-wrapper.service';

/**
 * @deprecated Please use {@link MatDialogWrapperService}.
 */
@Injectable({
    providedIn: 'root',
})
export class NgbModalWrapperService implements OnDestroy {
    private _unsub$ = new Subject<void>();
    private _onPopupOutlet = false;
    private onSuccessOrReject = () => {
        if (this._onPopupOutlet) {
            this._router.navigate(
                [
                    {
                        outlets: {
                            popup: null,
                        },
                    },
                ],
                {
                    replaceUrl: true,
                },
            );
        }
    };

    constructor(
        private _router: Router,
        private _modalService: NgbModal,
    ) {
        this._router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                takeUntil(this._unsub$),
            )
            .subscribe((event: NavigationEnd) => {
                const url = event.url;
                this._onPopupOutlet = url.indexOf('(popup:') !== -1;
            });
    }

    open<TComponent, TResult = any>(
        content: Type<TComponent>,
        data?: ModalData<TComponent>,
        options?: NgbModalOptions,
    ): ModalRef<TResult> {
        const modalRef = this._modalService.open(content, options);
        if (data) {
            Object.keys(data).forEach((property) => {
                modalRef.componentInstance[property] = data[property];
            });
        }
        modalRef.result.then(this.onSuccessOrReject, this.onSuccessOrReject);
        return modalRef as ModalRef<TResult>;
    }

    openWithTemplate<TResult = any>(templateRef: TemplateRef<any>, options?: NgbModalOptions): ModalRef<TResult> {
        const modalRef = this._modalService.open(templateRef, options);
        modalRef.result.then(this.onSuccessOrReject, this.onSuccessOrReject);
        return modalRef as ModalRef<TResult>;
    }

    ngOnDestroy(): void {
        this._unsub$.next();
    }
}
