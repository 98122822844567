import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'datatype-help-info-content',
    template: `
        <div class="modal-header">
            <h4 class="modal-title">{{ topic }}</h4>
        </div>
        <div class="modal-body">
            <p>{{ helpText }}</p>
        </div>
        <div class="modal-footer">
            <button mat-button type="button" visoButton="secondary" (click)="activeModal.dismiss('Close click')">
                Close
            </button>
        </div>
    `,
})
export class DataTypeHelpModalContent {
    @Input() topic: string;
    @Input() helpText: string;

    constructor(public activeModal: NgbActiveModal) {}
}
