import { StoreConfig } from '@ngrx/store';
import { storageMetareducer } from '@shared/redux/meta-reducers/storage.metareducer';
import { environment } from '@environments/environment';
import { RelationshipFrameworkMappingsState } from './redux/request.state';
import { FrameworkMappingActions } from './redux/actions/framework-mapping.actions';

export function getFrameworkMappingsConfig(
    localStorageKey: string,
    storeKeys: string[],
): StoreConfig<RelationshipFrameworkMappingsState> {
    return {
        metaReducers: [
            storageMetareducer(
                storeKeys,
                localStorageKey,
                [FrameworkMappingActions.GetFrameworkMappingsRequestSuccess],
                {
                    debug: !environment.production,
                },
            ),
        ],
    };
}
