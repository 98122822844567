<div class="row">
    <div class="col-xl-12">
        <div class="card">
            <tabset class="viso-tabset">
                <tab
                    *ngFor="let controlGroup of groupedControls$ | async; trackBy: trackGroupById"
                    [heading]="controlGroup.label"
                    (selectTab)="resizeWindow()"
                >
                    <div class="card-body">
                        <app-controls-config-list
                            [controlDomains]="controlGroup.controls"
                            [clientProfileId]="clientProfileId"
                            [profileControls]="profileControls"
                            (controlStatusChange)="controlStatusChange.emit()"
                        >
                        </app-controls-config-list>
                    </div>
                </tab>
            </tabset>
        </div>
    </div>
</div>
