import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import moment from 'moment';
import { AssessmentStatus, AssessmentView, CollectingInformationReasonLabels } from '@entities/assessment';
import { AssessmentEvent, AssessmentRemediationEvent, AssessmentStatusEvent } from '../../models/assessment-events';
import { RemediationRequest, RemediationStatus } from '@entities/remediation-request/remediation-request.model';
import { assessmentTimeline } from '../../assessment-status-event-constants';
import { remediationTimelineNodes } from '../../remediation-event-constants';
import _ from 'lodash';
import { AUTOMATION_EMAIL } from '@shared/constants/email.constants';

const TIMELINE_DATE_FORMAT = 'MMM DD, YYYY, h:mm:ss A';

@Component({
    selector: 'app-assessment-status-timeline',
    templateUrl: './assessment-status-timeline.component.html',
    styleUrls: ['./assessment-status-timeline.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssessmentStatusTimelineComponent implements OnInit, OnDestroy {
    @Input({ required: true })
    set assessment(value: AssessmentView) {
        this._assessment$.next(value);
    }

    assessmentEvents$ = new BehaviorSubject<AssessmentEvent[]>(null);

    private _assessment$ = new BehaviorSubject<AssessmentView>(null);
    private _unsub$ = new Subject<void>();

    ngOnInit(): void {
        this._assessment$.pipe(takeUntil(this._unsub$)).subscribe((assessment) => this.updateTimeline(assessment));
    }

    ngOnDestroy(): void {
        this._unsub$.next();
    }

    isMoreInformationNeeded(): boolean {
        const [status, submittedDate] = [
            this._assessment$.value?.status,
            this.getReviewStartedDate(this._assessment$.value),
        ];
        return status === AssessmentStatus.COLLECTING_INFORMATION && !!submittedDate;
    }

    private updateTimeline(assessment?: AssessmentView): void {
        // Deep copy constants; without this we run the risk of updating the constants' values themselves.
        const timeline: AssessmentEvent[] = _.cloneDeep(
            assessmentTimeline[assessment?.status || AssessmentStatus.NOT_ASSESSED],
        );

        if (!assessment) {
            (timeline[0] as AssessmentStatusEvent).assessmentStatus = AssessmentStatus.NOT_ASSESSED;
            timeline[0].subtitle = 'Waiting for client';
            timeline[0].current = true;
        } else if (assessment.status !== AssessmentStatus.CANCELLED) {
            this.setupStartedNode(timeline[0] as AssessmentStatusEvent);
            this.setupCollectingInformationNode(timeline[1] as AssessmentStatusEvent);
            this.setupReviewStartedNode(timeline[2] as AssessmentStatusEvent);
            this.setupCompletedNode(timeline[3] as AssessmentStatusEvent);
            this.setupRemediationNodes(timeline);
        }
        this.assessmentEvents$.next(timeline);
    }

    private setupStartedNode(node: AssessmentStatusEvent): void {
        const assessment = this._assessment$.value;
        node.subtitle = node.current
            ? 'Waiting for third party'
            : moment(assessment.createdDate).format(TIMELINE_DATE_FORMAT);
        node.lowerText.value = this.getSentToEmail(assessment);
    }

    private setupCollectingInformationNode(node: AssessmentStatusEvent): void {
        const assessment = this._assessment$.value;
        if ((assessment as AssessmentView)?.documentsOnly) {
            node.subtitle = 'Third party not involved';
        } else {
            if ((assessment as AssessmentView)?.continuedByEmail) {
                node.subtitle = this.getReviewStartedDate(assessment);
                node.lowerText.label = 'Continued with available information';
                node.lowerText.value = this.getContinuedByEmail(assessment);
            } else {
                node.subtitle = node.current
                    ? 'Waiting for third party'
                    : this.getCollectingInformationDate(assessment);
                node.lowerText.value = this.getSentToEmail(assessment);
            }
        }

        if ((assessment as AssessmentView)?.collectingInformationReason && node.current) {
            node.lowerText.label = `Returned: ${CollectingInformationReasonLabels[(assessment as AssessmentView).collectingInformationReason]}`;
            node.lowerText.value = 'VISO Auditor';
        }
    }

    private setupReviewStartedNode(node: AssessmentStatusEvent): void {
        const assessment = this._assessment$.value;
        if (this.isMoreInformationNeeded()) {
            node.subtitle = 'More information needed';
            node.current = true; // Both collecting info and review started highlighted
        } else {
            node.subtitle = node.current
                ? 'Waiting for VISO TRUST'
                : moment(this.getReviewStartedDate(assessment)).format(TIMELINE_DATE_FORMAT);
            node.lowerText.value = this.getSubmittedByEmail(assessment);
        }
    }

    private setupCompletedNode(node: AssessmentStatusEvent): void {
        const assessment = this._assessment$.value;
        node.subtitle = !!assessment.completedDate
            ? moment(assessment.completedDate).format(TIMELINE_DATE_FORMAT)
            : null;
        node.lowerText.value = this.getAssessmentDuration(assessment);
    }

    private setupRemediationNodes(timeline: AssessmentEvent[]): void {
        const assessment = this._assessment$.value as AssessmentView;
        if (!assessment?.remediationRequest) {
            return;
        }

        const remediationRequest = assessment.remediationRequest;

        // COMPLETED node as no longer the current, since we're now appending remediation node(s) to the timeline.
        // However, it is done because the client has made a verdict that they wanted to request remediation.
        const assessmentCompleteNode = timeline.find(
            (node) => (node as AssessmentStatusEvent)?.assessmentStatus === AssessmentStatus.COMPLETED,
        );
        assessmentCompleteNode.current = false;
        assessmentCompleteNode.done = true;
        const remediationNodes: AssessmentRemediationEvent[] = _.cloneDeep(
            remediationTimelineNodes[remediationRequest.status],
        );
        this.updateRemediationRequestedNode(remediationRequest, remediationNodes);
        this.updateRemediationCompletedNode(remediationRequest, remediationNodes);
        this.updateRemediationDeclinedNode(remediationRequest, remediationNodes);
        this.updateRemediationExpiredNode(remediationRequest, remediationNodes);
        this.updateRemediationCancelledNode(remediationRequest, remediationNodes);
        this.updateRemediationObsoleteNode(remediationRequest, remediationNodes);
        for (const node of remediationNodes) {
            timeline.push(node);
        }
    }

    private updateRemediationRequestedNode(
        remediationRequest: RemediationRequest,
        remediationNodes: AssessmentRemediationEvent[],
    ): void {
        const requestedByNode = remediationNodes.find((node) => node.remediationStatus === RemediationStatus.REQUESTED);
        const titleSuffix = ` ${moment(remediationRequest.targetDate).format('MMM Do, YYYY')}`;
        if (!!requestedByNode) {
            requestedByNode.title = remediationTimelineNodes.REQUESTED[0].title + titleSuffix;
            requestedByNode.subtitle = moment(remediationRequest.createdDate).format(TIMELINE_DATE_FORMAT);
            requestedByNode.lowerText = {
                label: 'Requested by',
                value: remediationRequest.creatorEmail,
            };
        }
    }

    private updateRemediationCompletedNode(
        remediationRequest: RemediationRequest,
        remediationNodes: AssessmentRemediationEvent[],
    ): void {
        const remediationCompleteNode = remediationNodes.find(
            (node) => node.remediationStatus === RemediationStatus.ARTIFACTS_PROVIDED,
        );
        const titleSuffix = ` ${moment(remediationRequest.targetDate).format('MMM Do, YYYY')}`;
        if (!!remediationCompleteNode) {
            remediationCompleteNode.title = remediationTimelineNodes.ARTIFACTS_PROVIDED[0].title + titleSuffix;
            remediationCompleteNode.subtitle = moment(remediationRequest.updatedDate).format(TIMELINE_DATE_FORMAT);
            remediationCompleteNode.lowerText = {
                label: 'Requested by',
                value: remediationRequest.creatorEmail,
            };
        }
    }

    private updateRemediationDeclinedNode(
        remediationRequest: RemediationRequest,
        remediationNodes: AssessmentRemediationEvent[],
    ): void {
        const remediationDeclinedNode = remediationNodes.find(
            (node) => node.remediationStatus === RemediationStatus.DECLINED,
        );
        if (!!remediationDeclinedNode) {
            remediationDeclinedNode.subtitle = moment(remediationRequest.updatedDate).format(TIMELINE_DATE_FORMAT);
            remediationDeclinedNode.lowerText = {
                label: 'Declined by',
                value: `${remediationRequest.declinedByEmail}`,
            };
        }
    }

    private updateRemediationExpiredNode(
        remediationRequest: RemediationRequest,
        remediationNodes: AssessmentRemediationEvent[],
    ): void {
        const remediationExpiredNode = remediationNodes.find(
            (node) => node.remediationStatus === RemediationStatus.EXPIRED,
        );
        if (!!remediationExpiredNode) {
            remediationExpiredNode.subtitle = moment(remediationRequest.updatedDate).format(TIMELINE_DATE_FORMAT);
        }
    }

    private updateRemediationCancelledNode(
        remediationRequest: RemediationRequest,
        remediationNodes: AssessmentRemediationEvent[],
    ): void {
        const remediationExpiredNode = remediationNodes.find(
            (node) => node.remediationStatus === RemediationStatus.CANCELLED,
        );
        if (!!remediationExpiredNode) {
            remediationExpiredNode.subtitle = moment(remediationRequest.updatedDate).format(TIMELINE_DATE_FORMAT);
            remediationExpiredNode.lowerText = {
                label: 'Cancelled by',
                value: this.getRemediationCancelledByText(remediationRequest.cancelledByEmail),
            };
        }
    }

    private updateRemediationObsoleteNode(
        remediationRequest: RemediationRequest,
        remediationNodes: AssessmentRemediationEvent[],
    ): void {
        const remediationObsoleteNode = remediationNodes.find(
            (node) => node.remediationStatus === RemediationStatus.OBSOLETE,
        );
        if (!!remediationObsoleteNode) {
            remediationObsoleteNode.subtitle = moment(remediationRequest.updatedDate).format(TIMELINE_DATE_FORMAT);
        }
    }

    private getRemediationCancelledByText(cancelledByEmail: string): string {
        return cancelledByEmail === AUTOMATION_EMAIL ? 'Automatic' : cancelledByEmail;
    }

    private getCollectingInformationDate(latestAssessment: AssessmentView): string {
        return moment(
            this.getLatestHistoryDateByStatus(latestAssessment, AssessmentStatus.COLLECTING_INFORMATION),
        ).format(TIMELINE_DATE_FORMAT);
    }

    private getSentToEmail(latestAssessment: AssessmentView): string {
        return (latestAssessment as AssessmentView)?.sentToEmail;
    }

    private getReviewStartedDate(latestAssessment: AssessmentView): string {
        const date = this.getLatestHistoryDateByStatus(latestAssessment, AssessmentStatus.REVIEW_STARTED);
        return !!date ? moment(date).format(TIMELINE_DATE_FORMAT) : null;
    }

    private getSubmittedByEmail(latestAssessment: AssessmentView): string {
        return (latestAssessment as AssessmentView)?.submittedByEmail;
    }

    private getContinuedByEmail(latestAssessment: AssessmentView): string {
        return (latestAssessment as AssessmentView)?.continuedByEmail;
    }

    private getAssessmentDuration(latestAssessment: AssessmentView): string {
        const duration = moment.duration(
            moment(latestAssessment.completedDate).diff(moment(latestAssessment.createdDate)),
        );

        const items = [
            [duration.months(), 'month'],
            [duration.weeks(), 'week'],
            [duration.days(), 'day'],
            [duration.hours(), 'hour'],
            [duration.minutes(), 'minute'],
            [duration.seconds(), 'second'],
        ];

        return items
            .filter(([durationItem]) => !!durationItem)
            .slice(0, 2)
            .map(([durationItem, durationLabel]) => `${durationItem} ${durationLabel}${durationItem !== 1 ? 's' : ''}`)
            .join(', ');
    }

    private getLatestHistoryDateByStatus(latestAssessment: AssessmentView, status: AssessmentStatus): Date {
        return latestAssessment?.statusHistories.find((statusHistory) => statusHistory.status === status)?.date;
    }
}
