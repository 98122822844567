import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import { openPDFArtifactView } from '../../../routes/pdf-artifact-viewer/redux/pdf-artifact-viewer.actions';
import {
    downloadFileWithAnchorElement,
    getFileNameFromResponseContentDisposition,
    saveFile,
} from '../../helpers/file-download-helpers';
import { downloadWithAnchorElement, openFileFromResBlob, saveFileFromResBlob } from './actions';
import {
    pdfArtifactViewArtifactIdParam,
    pdfArtifactViewRoute,
    pdfArtifactViewSelectedDetectionIdParam,
    pdfArtifactViewViewerPageParam,
} from '../../../routes/pdf-artifact-viewer/pdf-artifact-viewer.constants';

@Injectable()
export class FileDownloadEffects {
    saveFileFromResBlob$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(saveFileFromResBlob),
                tap(({ fileResponseBlob }) => {
                    const fileName = getFileNameFromResponseContentDisposition(fileResponseBlob);
                    const { body } = fileResponseBlob;
                    saveFile(body, fileName);
                }),
            ),
        { dispatch: false },
    );

    downloadWithAnchorElement$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(downloadWithAnchorElement),
                tap(({ url }) => downloadFileWithAnchorElement(url)),
            ),
        { dispatch: false },
    );

    openFileFromResBlob$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(openFileFromResBlob),
                tap(({ fileResponseBlob }) => {
                    const { body } = fileResponseBlob;
                    const fileName = getFileNameFromResponseContentDisposition(fileResponseBlob);
                    saveFile(body, fileName);
                }),
            ),
        { dispatch: false },
    );

    openPDFArtifactView$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(openPDFArtifactView),
                tap(({ artifactId, selectedDetectionId, viewerPage }) =>
                    this._router.navigate(
                        [
                            '/',
                            {
                                outlets: {
                                    popup: pdfArtifactViewRoute.replace(
                                        `:${pdfArtifactViewArtifactIdParam}`,
                                        artifactId.toString(),
                                    ),
                                },
                            },
                        ],
                        {
                            queryParams: {
                                [pdfArtifactViewSelectedDetectionIdParam]: selectedDetectionId,
                                [pdfArtifactViewViewerPageParam]: viewerPage,
                            },
                        },
                    ),
                ),
            ),
        { dispatch: false },
    );

    constructor(
        private _actions$: Actions,
        private _router: Router,
    ) {}
}
