import { ChartOptions, Chart } from 'chart.js';
import { ColorUtilsService } from '../../shared/utils/color-utils.service';
import { RiskUtilsService } from '../../shared/utils/risk-utils.service';

Chart.defaults.font.family = "'Niveau Grotesk', sans-serif";
Chart.register();

export const getChartColors = (colorSvc: ColorUtilsService, riskSvc: RiskUtilsService) => {
    return [
        {
            borderColor: colorSvc.graphColors.blueLight,
            backgroundColor: colorSvc.graphColors.skyBlueLighter,
            pointRadius: 0,
        },
        {
            borderColor: colorSvc.graphColors.blue,
            backgroundColor: colorSvc.graphColors.skyBlueLight,
            pointBackgroundColor: colorSvc.graphColors.blue,
            pointBorderColor: '#fff',
            pointHoverBackgroundColor: (context) => {
                let riskColor = '#fff';
                let riskScore = context.parsed.y / 100;
                riskColor = colorSvc.getRiskColor(riskSvc.getRiskLevelString(riskScore));
                return riskColor;
            },
            pointHoverBorderColor: colorSvc.graphColors.dark,
            pointHoverRadius: 10,
        },
    ];
};

export const chartOptions: ChartOptions = {
    elements: {
        line: {
            borderWidth: 2,
        },
        point: {
            radius: 5,
        },
    },
    animation: {
        duration: 0,
    },
    responsive: true,
    plugins: {
        title: {
            text: 'Risk Over Time',
        },
        legend: {
            display: true,
        },
    },
    scales: {
        gridLines: {
            display: false,
        },
        x: {
            display: true,
            type: 'time',
            ticks: {
                includeBounds: true,
                source: 'auto',
                maxRotation: 65,
                minRotation: 60,
                maxTicksLimit: 10,
                autoSkipPadding: 50,
                major: {
                    enabled: true,
                },
                autoSkip: true,
            },
            time: {
                stepSize: 1,
                parser: 'HH:mm',
                displayFormats: {
                    millisecond: 'h:mm:ss:SSS a',
                    second: 'h:mm:ss a',
                    minute: 'h:mm a',
                    hour: 'hA',
                },
            },
        },

        y: {
            beginAtZero: true,
            min: 0,
            max: 105,
            ticks: {
                callback: function (value, index, values) {
                    if (Number(value) > 100) {
                        return null;
                    }
                    return value + '%';
                },
                stepSize: 10,
            },
            grid: {
                display: true,
                drawBorder: false,
            },
            display: true,
        },
    },
};
