import { createAction, props } from '@ngrx/store';
import { ArtifactUploadRecommendation } from '../../models';

export enum AssessmentCollectionCommonArtifactUploadRecommendationActions {
    AddArtifactUploadRecommendation = '[Assessment Collection Common Artifact Upload Recommendation] Add Artifact Upload Recommendation',
    SetArtifactUploadRecommendation = '[Assessment Collection Common Artifact Upload Recommendation] Set Artifact Upload Recommendation',
    SetArtifactUploadRecommendations = '[Assessment Collection Common Artifact Upload Recommendation] Set Artifact Upload Recommendations',
    RemoveArtifactUploadRecommendation = '[Assessment Collection Common Artifact Upload Recommendation] Remove Artifact Upload Recommendation',
    OnWhenAddingFileFailed = '[Assessment Collection Common Artifact Upload Recommendation] On When Adding File Failed',
}

export const addArtifactUploadRecommendation = createAction(
    AssessmentCollectionCommonArtifactUploadRecommendationActions.AddArtifactUploadRecommendation,
    props<{ recommendation: ArtifactUploadRecommendation }>(),
);

export const setArtifactUploadRecommendations = createAction(
    AssessmentCollectionCommonArtifactUploadRecommendationActions.SetArtifactUploadRecommendations,
    props<{ recommendations: ArtifactUploadRecommendation[] }>(),
);

export const setArtifactUploadRecommendation = createAction(
    AssessmentCollectionCommonArtifactUploadRecommendationActions.SetArtifactUploadRecommendation,
    props<{ recommendation: ArtifactUploadRecommendation }>(),
);

export const removeArtifactUploadRecommendation = createAction(
    AssessmentCollectionCommonArtifactUploadRecommendationActions.RemoveArtifactUploadRecommendation,
    props<{ recommendation: ArtifactUploadRecommendation }>(),
);

export const onWhenAddingFileFailed = createAction(
    AssessmentCollectionCommonArtifactUploadRecommendationActions.OnWhenAddingFileFailed,
    props<{ message: string }>(),
);
