import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Subject } from 'rxjs';

import { BusinessUnitPopupService } from './business-unit-popup.service';
import { takeUntil } from 'rxjs/operators';
import { BusinessUnitDialogComponent } from './business-unit-dialog.component';

@Component({
    selector: 'app-business-unit-popup',
    template: '',
})
export class BusinessUnitPopupComponent implements OnInit, OnDestroy {
    private _unsub = new Subject<void>();

    constructor(
        private _route: ActivatedRoute,
        private _businessUnitPopupService: BusinessUnitPopupService,
    ) {}

    ngOnInit(): void {
        this._route.params.pipe(takeUntil(this._unsub)).subscribe((params) => {
            if (params['id']) {
                this._businessUnitPopupService.open(BusinessUnitDialogComponent, params['id']);
            } else {
                this._businessUnitPopupService.open(BusinessUnitDialogComponent);
            }
        });
    }

    ngOnDestroy(): void {
        this._unsub.next();
    }
}
