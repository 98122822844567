import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { SharedModule } from '../../shared';
import { YourOrganizationComponent, yourOrganizationPopupRoute, yourOrganizationRoute } from './';
import { FormsModule } from '@angular/forms';
import { YourOrganizationDialogComponent, YourOrganizationPopupComponent } from './your-organization-dialog.component';
import { ViewDkimConfigurationDialogComponent } from './domain-verification/dkim-configuration-dialog/view-dkim-configuration-dialog.component';
import { YourOrganizationPopupService } from './your-organization-popup.service';
import { YourOrganizationHelperService } from './your-organization-helper.service';
import { FileUploadModule } from '../../shared/file-upload';
import { YourOrganizationLogosComponent } from './your-organization-logos.component';
import { EffectsModule } from '@ngrx/effects';
import { YourOrganizationEffects } from './redux/your-organization.effects';
import { SupplementalQuestionnaireManagementComponent } from './supplemental-questionnaire-management/supplemental-questionnaire-management.component';
import { SupplementalQuestionnaireManagementEffects } from './supplemental-questionnaire-management/redux/supplemental-questionnaire-management.effects';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { AssessmentSummaryManagementComponent } from './assessment-summary-management/assessment-summary-management.component';
import { AssessmentSummaryManagementEffects } from './assessment-summary-management/redux/assessment-summary-management.effects';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';
import { MatFormFieldModule } from '@angular/material/form-field';
import { AssessmentComponentsModule } from '../../shared/assessment-components/assessment-components.module';
import { DomainVerificationsComponent } from './domain-verification/domain-verifications.component';
import { MatTableModule } from '@angular/material/table';
import { SentenceCasePipeModule } from '@shared/sentence-case-pipe';
import { DomainVerificationService } from '../../entities/domain-verification';
import { MatDialogModule } from '@angular/material/dialog';
import { StoreModule } from '@ngrx/store';
import { domainVerificationStateKey } from './domain-verification/redux/domain-verifications.state';
import { domainVerificationReducer } from './domain-verification/redux/domain-verification.reducers';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { DomainVerificationEffects } from './domain-verification/redux/domain-verification.effects';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatInputModule } from '@angular/material/input';
import { AppBrandingComponent } from './branding/branding.component';
import { NgxColorsModule } from 'ngx-colors';
import { BrandingEffects } from './branding/redux/branding.effects';
import { AssessmentCollectionCommonModule } from '../assessment-collection/assessment-collection-common/assessment-collection-common.module';
import { assessmentCollectionStateKey } from '../assessment-collection/assessment-collection-common/redux/assessment-collection.state';
import { assessmentCollectionReducer } from '../assessment-collection/assessment-collection-common/redux/assessment-collection.reducer';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { vanityDomainStateKey } from './vanity-domain/redux/vanity-domain.state';
import { vanityDomainReducer } from './vanity-domain/redux/vanity-domain.reducers';
import { VanityDomainEffects } from './vanity-domain/redux/vanity-domain.effects';
import { VanityDomainComponent } from './vanity-domain/vanity-domain.component';
import { VanityDomainService } from '@entities/vanity-domain';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSortModule } from '@angular/material/sort';
import { orgSubdomainStateKey } from './org-subdomain/redux/org-subdomain.state';
import { orgSubdomainReducer } from './org-subdomain/redux/org-subdomain.reducers';
import { OrgSubdomainEffects } from './org-subdomain/redux/org-subdomain.effects';
import { OrgSubdomainService } from '@entities/org-subdomain';
import { ManageDomainsComponent } from './manage-domains/manage-domains.component';
import { MatStepperModule } from '@angular/material/stepper';
import { MatRadioModule } from '@angular/material/radio';
import { VisoPopoverModule } from '@shared/viso-popover/viso-popover.module';
import { CustomizeDomainDialogComponent } from './manage-domains/customize-domain-dialog/customize-domain-dialog.component';
import { ManageDomainsEffects } from './manage-domains/redux/manage-domains.effects';
import { CustomizeEmailDialogComponent } from './domain-verification/customize-email-dialog/customize-email-dialog.component';
import { MatConfirmDialogModule } from '@shared/components/mat-confirm-dialog/mat-confirm-dialog.module';
import { LayoutModule } from '../../layout/layout.module';
import { SafeUrlPipe } from './branding/safe-url-pipe';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';

const ENTITY_STATES = [...yourOrganizationRoute, ...yourOrganizationPopupRoute];

@NgModule({
    imports: [
        StoreModule.forFeature(domainVerificationStateKey, domainVerificationReducer),
        StoreModule.forFeature(vanityDomainStateKey, vanityDomainReducer),
        StoreModule.forFeature(orgSubdomainStateKey, orgSubdomainReducer),
        StoreModule.forFeature(assessmentCollectionStateKey, assessmentCollectionReducer),
        SharedModule,
        RouterModule.forChild(ENTITY_STATES),
        FormsModule,
        FileUploadModule,
        NgxDatatableModule,
        EffectsModule.forFeature([
            YourOrganizationEffects,
            DomainVerificationEffects,
            OrgSubdomainEffects,
            VanityDomainEffects,
            SupplementalQuestionnaireManagementEffects,
            AssessmentSummaryManagementEffects,
            ManageDomainsEffects,
            BrandingEffects,
        ]),
        MatSlideToggleModule,
        MatButtonToggleModule,
        MatDividerModule,
        MatListModule,
        MatFormFieldModule,
        AssessmentComponentsModule,
        MatTableModule,
        SentenceCasePipeModule,
        MatDialogModule,
        MatOptionModule,
        MatSelectModule,
        MatTooltipModule,
        MatInputModule,
        MatCheckboxModule,
        MatSortModule,
        MatStepperModule,
        MatRadioModule,
        VisoPopoverModule,
        NgxColorsModule,
        AssessmentCollectionCommonModule,
        MatConfirmDialogModule,
        LayoutModule,
        NgbTooltip,
    ],
    declarations: [
        YourOrganizationComponent,
        YourOrganizationDialogComponent,
        YourOrganizationPopupComponent,
        YourOrganizationLogosComponent,
        DomainVerificationsComponent,
        VanityDomainComponent,
        SupplementalQuestionnaireManagementComponent,
        AssessmentSummaryManagementComponent,
        ViewDkimConfigurationDialogComponent,
        ManageDomainsComponent,
        CustomizeDomainDialogComponent,
        CustomizeEmailDialogComponent,
        AppBrandingComponent,
        SafeUrlPipe,
    ],
    providers: [
        YourOrganizationPopupService,
        YourOrganizationHelperService,
        DomainVerificationService,
        VanityDomainService,
        OrgSubdomainService,
    ],
    exports: [],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class YourOrganizationModule {}
