import { HttpEvent, HttpEventType } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, of } from 'rxjs';
import { catchError, mergeMap, switchMap, takeUntil, tap } from 'rxjs/operators';
import { OrgService } from '../../../entities/org';
import { SnackbarService } from '../../../shared/components/snackbar/snackbar.service';
import {
    uploadCompanyIconRequest,
    uploadCompanyIconRequestCancelled,
    uploadCompanyIconRequestFailed,
    uploadCompanyIconRequestProgress,
    uploadCompanyIconRequestSuccess,
    uploadCompanyLogoRequest,
    uploadCompanyLogoRequestCancelled,
    uploadCompanyLogoRequestFailed,
    uploadCompanyLogoRequestProgress,
    uploadCompanyLogoRequestSuccess,
} from './your-organization.actions';

@Injectable()
export class YourOrganizationEffects {
    uploadCompanyLogo$ = createEffect(() =>
        this._actions$.pipe(
            ofType(uploadCompanyLogoRequest),
            switchMap(({ file }) =>
                this._orgService.uploadCompanyLogo(file).pipe(
                    mergeMap((event: HttpEvent<any>) => {
                        switch (event.type) {
                            case HttpEventType.UploadProgress:
                                let percent = Math.round(100 * (event.loaded / event.total));
                                if (percent === 100) {
                                    percent = 99;
                                }
                                return of(uploadCompanyLogoRequestProgress({ percent }));
                            case HttpEventType.Response:
                                return [
                                    uploadCompanyLogoRequestProgress({ percent: 100 }),
                                    uploadCompanyLogoRequestSuccess(),
                                ];
                            default:
                                return EMPTY;
                        }
                    }),
                    catchError(() => of(uploadCompanyLogoRequestFailed())),
                    takeUntil(this._actions$.pipe(ofType(uploadCompanyLogoRequestCancelled))),
                ),
            ),
        ),
    );

    uploadCompanyLogoSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(uploadCompanyLogoRequestSuccess),
                tap(() => {
                    this._snackbarService.success('Logo successfully updated.');
                }),
            ),
        { dispatch: false },
    );

    uploadCompanyIcon$ = createEffect(() =>
        this._actions$.pipe(
            ofType(uploadCompanyIconRequest),
            switchMap(({ file }) =>
                this._orgService.uploadCompanyIcon(file).pipe(
                    mergeMap((event: HttpEvent<any>) => {
                        switch (event.type) {
                            case HttpEventType.UploadProgress:
                                let percent = Math.round(100 * (event.loaded / event.total));
                                if (percent === 100) {
                                    percent = 99;
                                }
                                return of(uploadCompanyIconRequestProgress({ percent }));
                            case HttpEventType.Response:
                                return [
                                    uploadCompanyIconRequestProgress({ percent: 100 }),
                                    uploadCompanyIconRequestSuccess(),
                                ];
                            default:
                                return EMPTY;
                        }
                    }),
                    catchError(() => of(uploadCompanyIconRequestFailed())),
                    takeUntil(this._actions$.pipe(ofType(uploadCompanyIconRequestCancelled))),
                ),
            ),
        ),
    );

    uploadCompanyIconSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(uploadCompanyIconRequestSuccess),
                tap(() => {
                    this._snackbarService.success('Icon successfully updated.');
                }),
            ),
        { dispatch: false },
    );

    constructor(
        private _actions$: Actions,
        private _orgService: OrgService,
        private _snackbarService: SnackbarService,
    ) {}
}
