import { catchError, tap } from 'rxjs/operators';
import {
    HttpInterceptor,
    HttpRequest,
    HttpErrorResponse,
    HttpHandler,
    HttpEvent,
    HttpContextToken,
} from '@angular/common/http';
import { from, Observable, throwError } from 'rxjs';
import { SnackbarService } from '../../shared/components/snackbar/snackbar.service';

export interface BypassSnackbarErrorProperties {
    forAnyStatus?: boolean;
    statuses?: number[];
}

/**
 * By default, the error handler HTTP interceptor will properly format and display errors from the API in a toast notification.
 * Use this token when you want to bypass this functionality for any reason, such as catching the error manually and displaying
 * an entirely different message.
 */
export const BYPASS_SNACKBAR_ON_ERROR = new HttpContextToken<BypassSnackbarErrorProperties>(() => null);

export class ErrorHandlerInterceptor implements HttpInterceptor {
    constructor(private snackbarService: SnackbarService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            catchError((err: any) => {
                if (request.responseType === 'blob' && err.error instanceof Blob) {
                    return from(
                        Promise.resolve(err).then(async (x) => {
                            throw new HttpErrorResponse({
                                error: JSON.parse(await x.error.text()),
                                headers: x.headers,
                                status: x.status,
                                statusText: x.statusText,
                                url: x.url ?? undefined,
                            });
                        }),
                    );
                }
                return throwError(() => err);
            }),
            tap({
                error: (response) => {
                    if (response instanceof HttpErrorResponse) {
                        if (this.bypassSnackbar(request, response)) {
                            return;
                        }
                        if (
                            !(
                                response.status === 401 &&
                                (response.message === '' ||
                                    (response.url && response.url.indexOf('/api/account') === 0))
                            )
                        ) {
                            const errors = response.error?.errors ?? [];
                            this.snackbarService.error(errors.length ? errors[0] : 'An error has occurred');
                        }
                    }
                },
            }),
        );
    }

    private bypassSnackbar(request: HttpRequest<any>, error: HttpErrorResponse): boolean {
        const bypassSnackbarToken = request.context.get(BYPASS_SNACKBAR_ON_ERROR);
        if (!!bypassSnackbarToken) {
            const snackbarBypassStatuses = bypassSnackbarToken.statuses ?? [];
            if (bypassSnackbarToken.forAnyStatus || snackbarBypassStatuses.includes(error.status)) {
                return true;
            }
        }
        return false;
    }
}
