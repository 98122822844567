import * as fromRoot from '../../../../shared/redux/state';
import { VanityDomainView } from '@entities/vanity-domain';

export const vanityDomainStateKey = 'vanityDomain';

export const initialState: VanityDomainsState = {
    cname: null,
    vanityDomain: null,
    orgDomains: [],
};

export interface AppState extends fromRoot.AppState {
    [vanityDomainStateKey]: VanityDomainsState;
}

export interface VanityDomainsState {
    cname: string | null;
    vanityDomain: VanityDomainView | null;
    orgDomains: string[];
}
