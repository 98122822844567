import { Component, Input, OnInit } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Risk } from '../../../entities/risk-assessment';
import { RiskUtilsService } from '../../../shared/utils/risk-utils.service';
import { OrgRiskConfig } from '../client-profile.model';
import { ClientProfileService } from '../client-profile.service';

@Component({
    selector: 'app-risk-levels-config',
    templateUrl: './risk-levels-config.component.html',
    styleUrls: ['./risk-levels-config.component.scss'],
})
export class RiskLevelsConfigComponent implements OnInit {
    @Input() riskLevels: OrgRiskConfig[];
    @Input() clientProfileId: string;

    manageRiskLevelConfigModal: NgbModalRef;
    currentRiskLevel: OrgRiskConfig;
    previousRiskLevelDisplayName: string;
    savingRiskLevel: boolean = false;
    riskLevelsOrder: string[];

    constructor(
        private _modalService: NgbModal,
        private _clientProfileService: ClientProfileService,
        private _riskUtilsService: RiskUtilsService,
    ) {}

    ngOnInit(): void {
        this.riskLevelsOrder = Object.keys(Risk).filter((f) => isNaN(+f));

        this.riskLevels.sort((itemA, itemB) => {
            let indexA = this.riskLevelsOrder.indexOf(itemA.riskLevel.toString());
            let indexB = this.riskLevelsOrder.indexOf(itemB.riskLevel.toString());
            if (indexA < indexB) return 1;
            else if (indexA > indexB) return -1;
            return 0;
        });
    }

    openEditRiskLevelConfigModal(manageRiskLevelConfigModal: any, riskLevel: OrgRiskConfig): void {
        this.currentRiskLevel = riskLevel;
        this.previousRiskLevelDisplayName = this.currentRiskLevel.displayName;

        this.manageRiskLevelConfigModal = this._modalService.open(manageRiskLevelConfigModal, {
            centered: true,
            backdrop: 'static',
        });
    }

    closeEditRiskLevelConfigModal(): void {
        this.manageRiskLevelConfigModal.close();
        this.currentRiskLevel = null;
    }

    cancel(): void {
        this.manageRiskLevelConfigModal.close();
        this.currentRiskLevel.displayName = this.previousRiskLevelDisplayName;
        this.previousRiskLevelDisplayName = null;
    }

    saveRiskLevel({ resetToDefault }): void {
        if (resetToDefault) {
            this.currentRiskLevel.displayName = this._riskUtilsService.formatRiskLevelsName(
                this.currentRiskLevel.riskLevel.toString().replace('_', ' '),
            );
        }
        this.savingRiskLevel = true;

        this._clientProfileService
            .updateRiskConfig(this.clientProfileId, this.currentRiskLevel)
            .pipe(
                tap(() => this.closeEditRiskLevelConfigModal()),
                catchError(() => of(null)),
                tap(() => (this.savingRiskLevel = false)),
            )
            .subscribe();
    }
}
