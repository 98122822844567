import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { takeUntil, tap } from 'rxjs/operators';
import { BehaviorSubject, Subject } from 'rxjs';
import { OrgDetailsResponse } from '@entities/org';
import { LogoType } from '../your-organization-logos.model';
import { FileUtilsService } from '@shared/utils/file-utils.service';
import {
    uploadCompanyIconRequest,
    uploadCompanyIconRequestCancelled,
    uploadCompanyIconRequestFailed,
    uploadCompanyIconRequestProgress,
    uploadCompanyIconRequestSuccess,
    uploadCompanyLogoRequest,
    uploadCompanyLogoRequestCancelled,
    uploadCompanyLogoRequestFailed,
    uploadCompanyLogoRequestProgress,
    uploadCompanyLogoRequestSuccess,
} from '../redux/your-organization.actions';
import { Store } from '@ngrx/store';
import { SnackbarService } from '@shared/components/snackbar/snackbar.service';
import { Actions, ofType } from '@ngrx/effects';
import {
    BrandingActions,
    deleteBrandingColorRequestFailure,
    deleteBrandingColorRequestSuccess,
    deleteCompanyImagesRequest,
    deleteCompanyImagesRequestFailure,
    deleteCompanyImagesRequestSuccess,
    openBrandingColorConfirmationModal,
    openDeleteBrandingColorConfirmationModal,
    saveBrandingColorRequestFailure,
    saveBrandingColorRequestSuccess,
} from './redux/branding.actions';
import { setPublicAssessment } from '../../assessment-collection/assessment-collection-common/redux/actions';
import { AssessmentStatus, AssessmentType, PublicAssessment } from '@entities/assessment';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { setAssessmentHeaderColor, setAssessmentLogoVisible } from '../../../layout/redux/layout.actions';
import { TALK_TO_SALES_URL } from '@shared/constants/url.constants';

export enum PreviewView {
    ASSESSMENT,
    EMAIL,
}

@Component({
    selector: 'app-branding',
    templateUrl: './branding.component.html',
    styleUrls: ['./branding.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppBrandingComponent implements OnInit, OnChanges, OnDestroy {
    @Input({ required: true }) org: OrgDetailsResponse;

    @Input({ required: true })
    set isTabSelected(value: boolean) {
        this._isTabSelected$.next(value);
    }

    @ViewChild('iframe', { static: false }) iframe;

    LogoTypes = LogoType;
    PreviewView = PreviewView;
    BrandingActions = BrandingActions;
    encodeURIComponent = encodeURIComponent;
    TALK_TO_SALES_URL = TALK_TO_SALES_URL;

    get isTabSelected() {
        return this._isTabSelected$.value;
    }

    private _isTabSelected$ = new BehaviorSubject<boolean>(false);
    private _unsub$: Subject<void> = new Subject<void>();

    previewView = PreviewView.ASSESSMENT;
    iconLogoIsUploading = false;
    fullLogoIsUploading = false;
    iconLogoPercentUploaded = 0;
    fullLogoPercentUploaded = 0;

    _color: string = null;

    fullLogoUploadedFileName: string = null;
    iconLogoUploadedFileName: string = null;

    constructor(
        private _fileUtilsService: FileUtilsService,
        private _snackbarService: SnackbarService,
        private _store$: Store,
        private _actions$: Actions,
    ) {}

    ngOnInit(): void {
        this._actions$
            .pipe(
                ofType(uploadCompanyIconRequestProgress),
                tap(({ percent }) => {
                    this.iconLogoPercentUploaded = percent;
                }),
                takeUntil(this._unsub$),
            )
            .subscribe();

        this._actions$
            .pipe(
                ofType(uploadCompanyLogoRequestProgress),
                tap(({ percent }) => {
                    this.fullLogoPercentUploaded = percent;
                }),
                takeUntil(this._unsub$),
            )
            .subscribe();

        this._actions$
            .pipe(
                ofType(
                    uploadCompanyLogoRequestFailed,
                    uploadCompanyIconRequestFailed,
                    deleteCompanyImagesRequestSuccess,
                    deleteCompanyImagesRequestFailure,
                    uploadCompanyIconRequestSuccess,
                    uploadCompanyLogoRequestSuccess,
                ),
                tap(() => {
                    this.resetIconLogoUpload();
                    this.resetFullLogoUpload();
                }),
                takeUntil(this._unsub$),
            )
            .subscribe();

        this._actions$
            .pipe(
                ofType(
                    uploadCompanyLogoRequestSuccess,
                    uploadCompanyIconRequestSuccess,
                    deleteCompanyImagesRequestSuccess,
                ),
                tap(() => {
                    this.reloadIframe();
                }),
                takeUntil(this._unsub$),
            )
            .subscribe();

        this._actions$
            .pipe(
                ofType(saveBrandingColorRequestSuccess, deleteBrandingColorRequestSuccess),
                tap((props) => {
                    this._color = 'hex' in props ? props.hex : null;
                    this.dispatchHeaderColor();
                    this._snackbarService.success('Your brand color has been updated.');
                }),
                takeUntil(this._unsub$),
            )
            .subscribe();

        this._actions$
            .pipe(
                ofType(saveBrandingColorRequestFailure, deleteBrandingColorRequestFailure),
                tap(() => {
                    this._snackbarService.error('Error updating brand color.');
                }),
                takeUntil(this._unsub$),
            )
            .subscribe();

        this._color = this.org.brandingColor;

        this.dispatchHeaderColor();
        this.dispatchAssessment();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.dispatchAssessment();
        this._store$.dispatch(setAssessmentLogoVisible({ visible: this.org.logoUrl === null }));
    }

    reloadIframe() {
        if (this.iframe?.nativeElement) {
            this.iframe.nativeElement.src += '';
        }
    }

    dispatchHeaderColor() {
        this._store$.dispatch(setAssessmentHeaderColor({ color: this._color }));
    }

    dispatchAssessment() {
        this._store$.dispatch(
            setPublicAssessment({
                publicAssessment: new PublicAssessment(
                    '',
                    [],
                    AssessmentStatus.NOT_ASSESSED,
                    AssessmentType.CERTIFICATION,
                    false,
                    this.org.id,
                    this.org.name,
                    this.org.logoUrl,
                    this._color,
                    'sender@example.com',
                    new Date(),
                    null,
                    [],
                    'contact@example.com',
                    [],
                    [],
                    [],
                    '',
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    true,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    '',
                    {},
                    [],
                ),
            }),
        );
    }

    get customized() {
        return this.color || this.org.logoUrl || this.org.faviconUrl;
    }

    get color() {
        return this._color;
    }

    set color(hex: string) {
        this._color = hex;
        this.dispatchHeaderColor();
        this.dispatchAssessment();
    }

    uploadFile(event: any, logoType: LogoType) {
        const file = event.target.files[0];

        if (!this.validateMimeType(file, logoType) || !this.validateFileSize(file)) {
            if (logoType === LogoType.IconLogo) this.resetIconLogoUpload();
            if (logoType === LogoType.FullLogo) this.resetFullLogoUpload();
            return;
        }

        if (logoType === LogoType.IconLogo) {
            this.iconLogoIsUploading = true;
            this.iconLogoUploadedFileName = this._fileUtilsService.formatFileNameForView(file.name);
            this._store$.dispatch(
                uploadCompanyIconRequest({
                    orgId: this.org.id,
                    file: file,
                }),
            );
        } else {
            this.fullLogoIsUploading = true;
            this.fullLogoUploadedFileName = this._fileUtilsService.formatFileNameForView(file.name);
            this._store$.dispatch(
                uploadCompanyLogoRequest({
                    orgId: this.org.id,
                    file: file,
                }),
            );
        }
    }

    resetIconLogoUpload(): void {
        this.iconLogoUploadedFileName = null;
        this.iconLogoIsUploading = false;
        this.iconLogoPercentUploaded = 0;
    }

    resetFullLogoUpload(): void {
        this.fullLogoUploadedFileName = null;
        this.fullLogoIsUploading = false;
        this.fullLogoPercentUploaded = 0;
    }

    cancelIconLogoUpload(): void {
        this._store$.dispatch(uploadCompanyIconRequestCancelled());
        this.resetIconLogoUpload();
    }

    cancelFullLogoUpload(): void {
        this._store$.dispatch(uploadCompanyLogoRequestCancelled());
        this.resetFullLogoUpload();
    }

    private MAX_FILE_SIZE = 100000;

    private validateFileSize(file: File): boolean {
        if (this.MAX_FILE_SIZE < file.size || file.size === 0) {
            this._snackbarService.error('File size must not be empty and less than 100kB.');
            return false;
        }
        return true;
    }

    private ICON_MIME_TYPES = new Set<string>(['image/x-icon', 'image/jpeg', 'image/png', 'image/vnd.microsoft.icon']);
    private LOGO_MIME_TYPES = new Set<string>(['image/jpeg', 'image/png', 'image/gif']);

    private validateMimeType(file: File, logoType: LogoType): boolean {
        if (logoType === LogoType.IconLogo) {
            if (!this.ICON_MIME_TYPES.has(file.type)) {
                this._snackbarService.error('File type must be .png, .ico or .jpg');
                return false;
            }
        } else if (!this.LOGO_MIME_TYPES.has(file.type)) {
            this._snackbarService.error('File type must be .jpg, .png or .gif');
            return false;
        }
        return true;
    }

    onPreviewViewChanged(event: MatButtonToggleChange) {
        this.previewView = event.value;
    }

    removeBrandColor() {
        if (this.org.brandingColor === null) {
            this.color = null;
            return;
        }
        this._store$.dispatch(openDeleteBrandingColorConfirmationModal());
    }

    deleteLogoAndIcon() {
        this._store$.dispatch(deleteCompanyImagesRequest());
    }

    saveBrandingColor() {
        this._store$.dispatch(openBrandingColorConfirmationModal({ hex: this._color }));
    }

    ngOnDestroy(): void {
        this._unsub$.next();
    }
}
