<header class="content-heading">
    <section>
        Client Offboarding
        <small>Offboard/Onboard Client Orgs</small>
    </section>
</header>

<main>
    <div class="container-fluid">
        <div class="row">
            <div class="col">
                <div class="card card-default">
                    <div class="card-header">
                        <div class="d-flex align-items-center justify-content-between">
                            <p class="card-title">Onboarded Orgs</p>
                            <div class="col-xs-3 col-sm-auto">
                                <input
                                    type="text"
                                    class="form-control"
                                    placeholder="Filter by Org name..."
                                    [(ngModel)]="onboardedSearchTerm"
                                />
                            </div>
                        </div>
                    </div>
                    <ng-container *ngIf="loadingOnboarded$ | async; else loadedOnBoarded">
                        <div class="d-flex align-items-center justify-content-center">
                            <div class="sk-spinner sk-spinner-pulse"></div>
                        </div>
                    </ng-container>
                    <ng-template #loadedOnBoarded>
                        <div class="card-body">
                            <table class="table table-striped">
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>Name</th>
                                        <th>Domains</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let onboardedOrg of onboardedOrgs$ | async">
                                        <td>
                                            {{ onboardedOrg.id }}
                                        </td>
                                        <td>
                                            <ngb-highlight
                                                [result]="onboardedOrg.name"
                                                [term]="onboardedSearchTerm"
                                            ></ngb-highlight>
                                        </td>
                                        <td>
                                            <app-badge
                                                *ngFor="let domain of onboardedOrg.domains"
                                                type="light-primary"
                                                >{{ domain }}</app-badge
                                            >
                                        </td>
                                        <td>
                                            <div *ngIf="onboardedOrg.status === OrgStatus.OFFBOARDING">
                                                Offboarding...
                                            </div>
                                            <button
                                                *ngIf="onboardedOrg.status === OrgStatus.ONBOARDED"
                                                class="ms-2"
                                                mat-button
                                                visoButton="warning"
                                                (click)="offboardOrg(onboardedOrg.id, onboardedOrg.name)"
                                            >
                                                Offboard
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="card-footer">
                            <div class="container-fluid">
                                <div class="row">
                                    <div class="col">
                                        <div class="float-start">
                                            <ngb-pagination
                                                [collectionSize]="totalOnboardedOrgs$ | async"
                                                [(page)]="onboardedPage"
                                                [pageSize]="onboardedPageSize"
                                                [maxSize]="9"
                                                [ellipses]="true"
                                                [rotate]="true"
                                            >
                                            </ngb-pagination>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="float-end">
                                            <div ngbDropdown #onboardDrop="ngbDropdown">
                                                <button
                                                    type="button"
                                                    class="me-2"
                                                    mat-button
                                                    visoButton="secondary"
                                                    id="dropdownManual"
                                                    ngbDropdownAnchor
                                                    (focus)="onboardDrop.open()"
                                                >
                                                    {{ onboardedPageSize }} items per page
                                                </button>
                                                <div ngbDropdownMenu aria-labelledby="dropdownManual">
                                                    <button
                                                        ngbDropdownItem
                                                        *ngFor="let onboardedPageSize of pageSizeOptions"
                                                        (click)="changePageSize(onboardedPageSize, TableType.ONBOARD)"
                                                    >
                                                        {{ onboardedPageSize }} items per page
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </div>
            </div>
            <div class="col">
                <div class="card card-default">
                    <div class="card-header">
                        <div class="d-flex align-items-center justify-content-between">
                            <p class="card-title">Offboarded Orgs</p>
                            <div class="col-xs-3 col-sm-auto">
                                <input
                                    type="text"
                                    class="form-control"
                                    placeholder="Filter by Org name..."
                                    [(ngModel)]="offboardedSearchTerm"
                                />
                            </div>
                        </div>
                    </div>
                    <ng-container *ngIf="loadingOffboarded$ | async; else loadedOffboarded">
                        <div class="d-flex align-items-center justify-content-center">
                            <div class="sk-spinner sk-spinner-pulse"></div>
                        </div>
                    </ng-container>
                    <ng-template #loadedOffboarded>
                        <div class="card-body">
                            <table class="table table-striped">
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>Name</th>
                                        <th>Domains</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let offboardedOrg of offboardedOrgs$ | async">
                                        <td>
                                            {{ offboardedOrg.id }}
                                        </td>
                                        <td>
                                            <ngb-highlight
                                                [result]="offboardedOrg.name"
                                                [term]="offboardedSearchTerm"
                                            ></ngb-highlight>
                                        </td>
                                        <td>
                                            <app-badge
                                                *ngFor="let domain of offboardedOrg.domains"
                                                type="light-primary"
                                                >{{ domain }}</app-badge
                                            >
                                        </td>
                                        <td>
                                            <button
                                                class="ms-2"
                                                mat-button
                                                visoButton="primary"
                                                color="success"
                                                (click)="onboardOrg(offboardedOrg.id, offboardedOrg.name)"
                                            >
                                                Onboard
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="card-footer">
                            <div class="container-fluid">
                                <div class="row">
                                    <div class="col">
                                        <div class="float-start">
                                            <ngb-pagination
                                                [collectionSize]="totalOffboardedOrgs$ | async"
                                                [(page)]="offboardedPage"
                                                [pageSize]="offboardedPageSize"
                                                [maxSize]="9"
                                                [ellipses]="true"
                                                [rotate]="true"
                                            >
                                            </ngb-pagination>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="float-end">
                                            <div ngbDropdown #offboardDrop="ngbDropdown">
                                                <button
                                                    type="button"
                                                    class="me-2"
                                                    mat-button
                                                    visoButton="secondary"
                                                    id="dropdownManual"
                                                    ngbDropdownAnchor
                                                    (focus)="offboardDrop.open()"
                                                >
                                                    {{ offboardedPageSize }} items per page
                                                </button>
                                                <div ngbDropdownMenu aria-labelledby="dropdownManual">
                                                    <button
                                                        ngbDropdownItem
                                                        *ngFor="let offboardedPageSize of pageSizeOptions"
                                                        (click)="changePageSize(offboardedPageSize, TableType.OFFBOARD)"
                                                    >
                                                        {{ offboardedPageSize }} items per page
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </div>
            </div>
        </div>
    </div>
</main>
