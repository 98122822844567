import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
    generateApiTokenRequest,
    generateApiTokenRequestFailed,
    generateApiTokenRequestSuccess,
    getApiTokenDetailsRequest,
    getApiTokenDetailsRequestFailed,
    getApiTokenDetailsRequestSuccess,
    getUserProfileRequest,
    getUserProfileRequestFailed,
    getUserProfileRequestSuccess,
    openAccessTokenModal,
    openRevokeAccessTokenModal,
    revokeApiTokenRequest,
    revokeApiTokenRequestFailed,
    revokeApiTokenRequestSuccess,
} from './user-profile.actions';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { VisoUserService } from '../../../entities/viso-user';
import { NgbModalWrapperService } from '../../../shared';
import { AccessTokenModalComponent } from '../access-token-modal/access-token-modal.component';
import { RevokeAccessTokenModalComponent } from '../revoke-access-token-modal/revoke-access-token-modal.component';

@Injectable()
export class UserProfileEffects {
    getUserProfileRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(getUserProfileRequest),
            switchMap(({ visoUserId }) =>
                this._visoUserService.find(visoUserId).pipe(
                    map((response) => response.body),
                    map((visoUser) => getUserProfileRequestSuccess({ visoUser })),
                    catchError((error) => of(getUserProfileRequestFailed({ error }))),
                ),
            ),
        ),
    );

    getApiAccessDetailsRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(getApiTokenDetailsRequest),
            switchMap(() =>
                this._visoUserService.getActiveApiTokenDetails().pipe(
                    map((activeJwtDetails) => getApiTokenDetailsRequestSuccess({ activeJwtDetails })),
                    catchError((error) => of(getApiTokenDetailsRequestFailed({ error }))),
                ),
            ),
        ),
    );

    generateApiTokenRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(generateApiTokenRequest),
            switchMap(({ payload }) =>
                this._visoUserService.generateApiToken(payload).pipe(
                    map((apiTokenResponse) => generateApiTokenRequestSuccess({ apiTokenResponse })),
                    catchError((error) => of(generateApiTokenRequestFailed({ error }))),
                ),
            ),
        ),
    );

    openAccessTokenModal$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(openAccessTokenModal),
                tap(({ accessToken }) =>
                    this._modalService.open<AccessTokenModalComponent>(
                        AccessTokenModalComponent,
                        {
                            accessToken,
                        },
                        {
                            keyboard: false,
                            backdrop: 'static',
                            centered: true,
                        },
                    ),
                ),
            ),
        { dispatch: false },
    );

    openRevokeAccessTokenModal$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(openRevokeAccessTokenModal),
                tap(() =>
                    this._modalService.open<RevokeAccessTokenModalComponent>(
                        RevokeAccessTokenModalComponent,
                        {},
                        {
                            keyboard: false,
                            backdrop: 'static',
                            centered: true,
                        },
                    ),
                ),
            ),
        { dispatch: false },
    );

    revokeApiTokenRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(revokeApiTokenRequest),
            switchMap(() =>
                this._visoUserService.revokeApiToken().pipe(
                    map((apiTokenResponse) => revokeApiTokenRequestSuccess()),
                    catchError((error) => of(revokeApiTokenRequestFailed({ error }))),
                ),
            ),
        ),
    );

    constructor(
        private _actions$: Actions,
        private _visoUserService: VisoUserService,
        private _modalService: NgbModalWrapperService,
    ) {}
}
