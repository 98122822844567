import { Injectable } from '@angular/core';
import { ROUTER_NAVIGATED, routerNavigatedAction, RouterNavigationAction } from '@ngrx/router-store';
import { select, Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { RecertificationType, RelationshipService } from '../../../../entities/relationship';
import { SnackbarService } from '../../../../shared/components/snackbar/snackbar.service';
import { ConfirmDialogService } from '../../../../shared/components/confirm-dialog/confirm-dialog.service';
import { NgbModalWrapperService } from '../../../../shared/modal/ngb-modal-wrapper.service';
import { EditRelationshipThirdPartyContactComponent } from '../../../relationships/edit-relationship-third-party-contact/edit-relationship-third-party-contact.component';
import { getUserAccount, getUserProfileSelector } from '../../../session/redux/session.selectors';
import { RelationshipDetailsFormModalComponent } from '../../relationship-details-form-modal/relationship-details-form-modal.component';
import { RelationshipContextModalComponent } from '../../relationship-context/relationship-context-modal/relationship-context-modal.component';
import { RelationshipContextModalHelperService } from '../../relationship-context/relationship-context-modal-helper.service';
import {
    calculateInherentRisk,
    calculateInherentRiskFailed,
    calculateInherentRiskSuccess,
    getRelationship,
    getRelationshipFailed,
    getRelationshipSuccess,
    getRequestTags,
    getRequestTagsFailed,
    getRequestTagsSuccess,
    updateRelationship,
    updateRelationshipFailed,
    updateRelationshipSuccess,
    updateVendorRelationship,
    updateVendorRelationshipCancel,
} from '../actions/relationship.actions';
import { openStartAssessmentModal } from '../actions/assessments.actions';

@Injectable()
export class RelationshipEffects {
    editRelationshipModal$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(ROUTER_NAVIGATED),
                map((action: RouterNavigationAction) =>
                    action.payload.routerState.root.children.find((route) => route.outlet === 'popup'),
                ),
                filter((route) => route?.routeConfig.path === 'relationship/:id/edit/details'),
                map((route) => route.params.id),
                switchMap(
                    (requestId) =>
                        (requestId && this._requestService.find(requestId).pipe(map((response) => response.body))) ||
                        of(null),
                ),
                withLatestFrom(this._store$.pipe(select(getUserAccount))),
                tap(([relationship, userAccount]) =>
                    this._modalService.open<RelationshipDetailsFormModalComponent>(
                        RelationshipDetailsFormModalComponent,
                        {
                            relationship,
                            isVendorManagedByCurrentOrg:
                                !!relationship.vendorManagedById &&
                                relationship.vendorManagedById === userAccount.orgId,
                        },
                    ),
                ),
            ),
        { dispatch: false },
    );

    openEditRelationshipThirdPartyContactModal$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(ROUTER_NAVIGATED),
                map((action: RouterNavigationAction) =>
                    action.payload.routerState.root.children.find((route) => route.outlet === 'popup'),
                ),
                filter((route) => !!route && route.routeConfig.path === 'relationships/:requestId/third-party-contact'),
                map((route) => ({
                    requestId: route.params.requestId as number,
                })),
                switchMap(({ requestId }) =>
                    this._requestService.getPrimaryVendorContact(requestId).pipe(
                        map((response) => response.body),
                        map((primaryVendorContact) => ({ requestId, primaryVendorContact })),
                    ),
                ),
                tap(({ requestId, primaryVendorContact }) =>
                    this._modalService.open<EditRelationshipThirdPartyContactComponent>(
                        EditRelationshipThirdPartyContactComponent,
                        {
                            requestId,
                            primaryVendorContact,
                        },
                        { size: 'md' },
                    ),
                ),
            ),
        { dispatch: false },
    );

    openRelationshipContextModal$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(routerNavigatedAction),
                map((action) => action.payload.routerState.root.children.find((route) => route.outlet === 'popup')),
                filter((route) => !!route && route.routeConfig.path === 'relationships/:relationshipId/context'),
                map((route) => ({
                    relationshipId: +route.params.relationshipId,
                })),
                switchMap(({ relationshipId }) => this._relationshipContextModalHelper.getModalData(relationshipId)),
                tap(([relationship, businessCases, dataTypes, profileDataSensitivityLevels]) =>
                    this._modalService.open<RelationshipContextModalComponent>(
                        RelationshipContextModalComponent,
                        {
                            relationship,
                            businessCases,
                            dataTypes,
                            profileDataSensitivityLevels,
                        },
                        { size: 'lg' },
                    ),
                ),
            ),
        { dispatch: false },
    );

    updateVendorRelationship$ = createEffect(() =>
        this._actions$.pipe(
            ofType(updateVendorRelationship),
            withLatestFrom(
                this._actions$.pipe(
                    ofType(getRelationshipSuccess),
                    map(({ relationship }) => relationship),
                ),
            ),
            mergeMap(([{ relationship }, previousRelationship]) => {
                let removingDataTypes = false;
                let removingContextTypes = false;
                if (previousRelationship.recertificationType === RecertificationType.AUTOMATIC) {
                    if (previousRelationship.dataTypes.length && !relationship.dataTypes.length) {
                        removingDataTypes = true;
                    }
                    if (previousRelationship.contextTypes.length && !relationship.contextTypes.length) {
                        removingContextTypes = true;
                    }
                    let title = '';
                    let message = '';
                    let cancelLabel = '';
                    let confirmLabel = '';
                    if (removingDataTypes && removingContextTypes) {
                        title = 'Remove Business Case and Data Types';
                        message =
                            'A relationship with no business case and no company data and no customer data cannot be assessed.\n\nAre you sure you want to remove all business case and data types selections? This will revert your recertification settings to email reminders only.';
                        cancelLabel = 'No, Keep them';
                        confirmLabel = 'Yes, Remove them';
                    } else if (removingContextTypes) {
                        title = 'Remove Business Case';
                        message =
                            'A relationship with no business case cannot be assessed.\n\nAre you sure you want to remove all business case selections? This will revert your recertification settings to email reminders only.';
                        cancelLabel = 'No, Keep Business Case';
                        confirmLabel = 'Yes, Remove it';
                    } else if (removingDataTypes) {
                        title = 'Remove Data Types';
                        message =
                            'A relationship with no company data and no customer data cannot be assessed.\n\nAre you sure you want to remove all data types selections? This will revert your recertification settings to email reminders only.';
                        cancelLabel = 'No, Keep Business Case';
                        confirmLabel = 'Yes, Remove it';
                    }
                    if (removingDataTypes || removingContextTypes) {
                        return this._confirm
                            .confirm({
                                title,
                                message,
                                cancelLabel,
                                confirmLabel,
                            })
                            .pipe(
                                map((result) =>
                                    result ? updateRelationship({ relationship }) : updateVendorRelationshipCancel(),
                                ),
                            );
                    }
                }

                return of(updateRelationship({ relationship }));
            }),
        ),
    );

    updateRelationship$ = createEffect(() =>
        this._actions$.pipe(
            ofType(updateRelationship),
            switchMap(({ relationship }) =>
                this._requestService.update(relationship).pipe(
                    map((response) => response.body),
                    map((relationship) => updateRelationshipSuccess({ relationship })),
                    catchError(() => of(updateRelationshipFailed())),
                ),
            ),
        ),
    );

    getRelationship$ = createEffect(() =>
        this._actions$.pipe(
            ofType(getRelationship),
            switchMap(({ requestId }) =>
                this._requestService.find(requestId).pipe(
                    map((response) => response.body),
                    map((relationship) => getRelationshipSuccess({ relationship })),
                    catchError((error) => of(getRelationshipFailed({ error }))),
                ),
            ),
        ),
    );

    getRelationshipTags$ = createEffect(() =>
        this._actions$.pipe(
            ofType(getRequestTags),
            switchMap(({ requestId }) =>
                this._requestService.getTags(requestId).pipe(
                    map((tags) => getRequestTagsSuccess({ tags })),
                    catchError(() => of(getRequestTagsFailed())),
                ),
            ),
        ),
    );

    createUpdateRelationshipSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(updateRelationshipSuccess),
                withLatestFrom(this._store$.select(getUserProfileSelector)),
                tap(([{ relationship }, userProfile]) => {
                    if (relationship.riskDelta) {
                        this._snackbarService.success(
                            `Relationship updated. ${relationship.riskDelta.comments.join(' ')}`,
                        );
                    } else {
                        if (userProfile?.showFirstAssessmentCreatedMessage) {
                            this._snackbarService.success('Relationship updated', {
                                action: {
                                    text: 'Start Assessment',
                                    onClick: () => {
                                        this._store$.dispatch(
                                            openStartAssessmentModal({
                                                request: relationship,
                                                primaryContact: relationship.primaryContact,
                                                recertification: false,
                                            }),
                                        );
                                    },
                                },
                            });
                        } else {
                            this._snackbarService.success('Relationship updated');
                        }
                    }
                }),
            ),
        { dispatch: false },
    );

    calculateInherentRisk$ = createEffect(() =>
        this._actions$.pipe(
            ofType(calculateInherentRisk),
            switchMap((action) =>
                this._requestService.calculateInherentRisk(action.dataTypes, action.contextTypes).pipe(
                    map((data) => calculateInherentRiskSuccess({ data })),
                    catchError(() => of(calculateInherentRiskFailed())),
                ),
            ),
        ),
    );

    constructor(
        private _actions$: Actions,
        private _store$: Store,
        private _modalService: NgbModalWrapperService,
        private _snackbarService: SnackbarService,
        private _requestService: RelationshipService,
        private _relationshipContextModalHelper: RelationshipContextModalHelperService,
        private _confirm: ConfirmDialogService,
    ) {}
}
