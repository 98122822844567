import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RiskDistributionComponent } from './risk-tolerance/risk-distribution/risk-distribution.component';
import { RiskToleranceComponent } from './risk-tolerance/risk-tolerance.component';
import { RiskModelSettingsComponent } from './risk-model-settings.component';
import { RouterModule } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { riskModelSettingsRoutes } from './risk-model-settings.route';
import { MatCardModule } from '@angular/material/card';
import { RiskModelSettingsEffects } from './redux/risk-model-settings.effects';
import { CommonModule } from '@angular/common';
import { CallbackPipeModule } from 'src/main/webapp/app/shared/pipes/callback-pipe';
import { StoreModule } from '@ngrx/store';
import { riskModelSettingsReducer } from './redux/risk-model-settings.reducer';
import { riskModelSettingsStateKey } from './redux/risk-model-settings.state';
import { RiskUtilsModule } from '@shared/risk-utils/risk-utils.module';
import { NgChartsModule } from 'ng2-charts';
import { MatDividerModule } from '@angular/material/divider';
import { MatButtonModule } from '@angular/material/button';
import { VisoButtonModule } from '@shared/viso-button/viso-button.module';
import { DynamicContentModule } from '@shared/dynamic-content/dynamic-content.module';
import { RiskThresholdsComponent } from './risk-tolerance/risk-thresholds/risk-thresholds.component';
import { RiskToleranceFormDialogComponent } from './risk-tolerance/risk-tolerance-form-dialog/risk-tolerance-form-dialog.component';
import { EnumToArrayPipeModule } from '@shared/pipes/enum-to-array-pipe/enum-to-array-pipe.module';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { ReactiveFormsModule } from '@angular/forms';
import { VisoAdminModule } from '../../admin/admin.module';
import { VisoEditorModule } from '@shared/components/viso-editor/viso-editor.module';
import { MatSliderModule } from '@angular/material/slider';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { AuthDirectivesModule } from '@shared/auth-directives';

const ENTITY_STATES = [...riskModelSettingsRoutes];

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(ENTITY_STATES),
        EffectsModule.forFeature([RiskModelSettingsEffects]),
        StoreModule.forFeature(riskModelSettingsStateKey, riskModelSettingsReducer),
        MatCardModule,
        CallbackPipeModule,
        RiskUtilsModule,
        NgChartsModule,
        MatDividerModule,
        MatButtonModule,
        VisoButtonModule,
        DynamicContentModule,
        EnumToArrayPipeModule,
        MatDatepickerModule,
        MatDialogModule,
        MatFormFieldModule,
        MatInputModule,
        MatOptionModule,
        MatSelectModule,
        ReactiveFormsModule,
        VisoAdminModule,
        VisoEditorModule,
        MatSliderModule,
        MatAutocompleteModule,
        AuthDirectivesModule,
    ],
    declarations: [
        RiskDistributionComponent,
        RiskToleranceComponent,
        RiskModelSettingsComponent,
        RiskThresholdsComponent,
        RiskToleranceFormDialogComponent,
    ],
    providers: [],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class RiskModelSettingsModule {
    constructor() {}
}
