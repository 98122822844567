import { createAction, props } from '@ngrx/store';
import { FileArtifactType, QuestionnaireArtifact } from '@entities/artifact';
import {
    AdditionalInformationSubSteps,
    AssessmentSubmissionContext,
    AssessmentSubmissionFormPayload,
    SetArtifactPasswordPayload,
    StepIds,
} from '../../models';

export enum AssessmentCollectionCommonCommandActions {
    SubmitPasscode = '[Assessment Collection Common Command] Submit Passcode',
    SubmitPasscodeSuccess = '[Assessment Collection Common Command] Submit Passcode Success',
    RedirectToAuthenticate = '[Assessment Collection Common Command] Redirect To Authenticate',
    RedirectToNotInProgressState = '[Assessment Collection Common Command] Redirect To Not In Progress State',
    SetCurrentStep = '[Assessment Collection Common Command] Set Current Step',
    SetCurrentCollectArtifactsSubStep = '[Assessment Collection Common Command] Set Current Collect Artifacts SubStep',
    CancelAssessment = '[Assessment Collection Common Command] Cancel Assessment',
    CancelAssessmentCancel = '[Assessment Collection Common Command] Cancel Assessment Cancel',
    ForwardRequest = '[Assessment Collection Common Command] Forward Request',
    ForwardRequestCancel = '[Assessment Collection Common Command] Forward Request Cancel',
    ExtendExpiration = '[Assessment Collection Common Command] Extend Expiration',
    ExtendAssessmentExpirationCancel = '[Assessment Collection Common Command] Extend Assessment Expiration Cancel',
    DeclineRemediation = '[Assessment Collection Common Command] Decline Remediation',
    DeclineRemediationCancel = '[Assessment Collection Common Command] Decline Remediation Cancel',
    UploadFiles = '[Assessment Collection Common Command] Upload Files',
    UploadFileToReplace = '[Assessment Collection Common Command] Upload Files To Replace',
    LoadRecommendations = '[Assessment Collection Common Command] Load Recommendations',
    SetArtifactPassword = '[Assessment Collection Common Command] Set Artifact Password',
    LoadSecurityControlDomain = '[Assessment Collection Common Command] Load Security Control Domain',
    RemoveArtifact = '[Assessment Collection Common Command] Remove Artifact',
    CreateQuestionnaire = '[Assessment Collection Common Command] Create Questionnaire',
    SaveQuestionnaire = '[Assessment Collection Common Command] Save Questionnaire',
    SubmitAssessment = '[Assessment Collection Common Command] Submit Assessment',
    UploadSupplementalFiles = '[Assessment Collection Common Command] Upload Supplemental Files',
    RefreshArtifactUploadRecommendations = '[Assessment Collection Common Command] Refresh Artifact Upload Recommendations',
    RefreshSiblingOrExpiredArtifactUploadRecommendations = '[Assessment Collection Common Command] Refresh Sibling Or Expired Artifact Upload Recommendations',
    ContinueFromWelcomePage = '[Assessment Collection Common Command] Continue From Welcome Page',
    GoBackFromInitialUpload = '[Assessment Collection Common Command] Go Back From Initial Upload',
    ContinueFromInitialUpload = '[Assessment Collection Common Command] Continue From Initial Upload',
    GoBackFromSubmission = '[Assessment Collection Common Command] Go Back From Submission',
    NavigateToQuestionnaire = '[Assessment Collection Common Command] Navigate To Questionnaire',
    NavigateToQuestionnaireForFollowup = '[Assessment Collection Common Command] Navigate To Questionnaire For Followup',
    NavigateToCollectArtifacts = '[Assessment Collection Common Command] Navigate To Collect Artifacts',
    NavigateToSupplementalQuestionnaireFromSubmission = '[Assessment Collection Common Command] Navigate To Supplemental Questionnaire From Submission',
    SetSubmissionContext = '[Assessment Collection Common Command] Set Submission Context',
}

export const submitPasscode = createAction(
    AssessmentCollectionCommonCommandActions.SubmitPasscode,
    props<{ passcode: string }>(),
);

export const submitPasscodeSuccess = createAction(AssessmentCollectionCommonCommandActions.SubmitPasscodeSuccess);

export const redirectToAuthenticate = createAction(AssessmentCollectionCommonCommandActions.RedirectToAuthenticate);

export const redirectToNotInProgressState = createAction(
    AssessmentCollectionCommonCommandActions.RedirectToNotInProgressState,
);

export const setCurrentStep = createAction(
    AssessmentCollectionCommonCommandActions.SetCurrentStep,
    props<{ stepId: StepIds }>(),
);

export const setCurrentCollectArtifactsSubStep = createAction(
    AssessmentCollectionCommonCommandActions.SetCurrentCollectArtifactsSubStep,
    props<{ collectArtifactsSubStep: AdditionalInformationSubSteps }>(),
);

export const cancelAssessment = createAction(AssessmentCollectionCommonCommandActions.CancelAssessment);

export const cancelAssessmentCancel = createAction(AssessmentCollectionCommonCommandActions.CancelAssessmentCancel);

export const forwardRequest = createAction(AssessmentCollectionCommonCommandActions.ForwardRequest);

export const forwardRequestCancel = createAction(AssessmentCollectionCommonCommandActions.ForwardRequestCancel);

export const extendExpiration = createAction(AssessmentCollectionCommonCommandActions.ExtendExpiration);

export const extendAssessmentExpirationCancel = createAction(
    AssessmentCollectionCommonCommandActions.ExtendAssessmentExpirationCancel,
);

export const declineRemediation = createAction(AssessmentCollectionCommonCommandActions.DeclineRemediation);

export const declineRemediationCancel = createAction(AssessmentCollectionCommonCommandActions.DeclineRemediationCancel);

export const uploadFiles = createAction(
    AssessmentCollectionCommonCommandActions.UploadFiles,
    props<{ files: File[]; recommendationId?: string }>(),
);

export const uploadFileToReplace = createAction(
    AssessmentCollectionCommonCommandActions.UploadFileToReplace,
    props<{ file: File; artifactIdToReplace: number }>(),
);

export const loadRecommendations = createAction(AssessmentCollectionCommonCommandActions.LoadRecommendations);

export const setArtifactPassword = createAction(
    AssessmentCollectionCommonCommandActions.SetArtifactPassword,
    props<SetArtifactPasswordPayload>(),
);

export const loadSecurityControlDomain = createAction(
    AssessmentCollectionCommonCommandActions.LoadSecurityControlDomain,
);

export const removeArtifact = createAction(
    AssessmentCollectionCommonCommandActions.RemoveArtifact,
    props<{ artifactId: number; fileName: string; artifactType: FileArtifactType }>(),
);

export const createQuestionnaire = createAction(AssessmentCollectionCommonCommandActions.CreateQuestionnaire);

export const saveQuestionnaire = createAction(
    AssessmentCollectionCommonCommandActions.SaveQuestionnaire,
    props<{ questionnaire: QuestionnaireArtifact }>(),
);

export const submitAssessment = createAction(
    AssessmentCollectionCommonCommandActions.SubmitAssessment,
    props<{ payload: AssessmentSubmissionFormPayload }>(),
);

export const uploadSupplementalFiles = createAction(
    AssessmentCollectionCommonCommandActions.UploadSupplementalFiles,
    props<{ files: File[] }>(),
);

export const refreshScopeRelatedArtifactUploadRecommendations = createAction(
    AssessmentCollectionCommonCommandActions.RefreshArtifactUploadRecommendations,
);

export const refreshSiblingOrExpiredArtifactUploadRecommendations = createAction(
    AssessmentCollectionCommonCommandActions.RefreshSiblingOrExpiredArtifactUploadRecommendations,
);

export const continueFromWelcomePage = createAction(AssessmentCollectionCommonCommandActions.ContinueFromWelcomePage);

export const goBackFromInitialUpload = createAction(AssessmentCollectionCommonCommandActions.GoBackFromInitialUpload);

export const continueFromInitialUpload = createAction(
    AssessmentCollectionCommonCommandActions.ContinueFromInitialUpload,
);

export const goBackFromSubmission = createAction(AssessmentCollectionCommonCommandActions.GoBackFromSubmission);

export const navigateToQuestionnaire = createAction(AssessmentCollectionCommonCommandActions.NavigateToQuestionnaire);

export const navigateToQuestionnaireForFollowup = createAction(
    AssessmentCollectionCommonCommandActions.NavigateToQuestionnaireForFollowup,
);

export const navigateToCollectArtifacts = createAction(
    AssessmentCollectionCommonCommandActions.NavigateToCollectArtifacts,
);

export const navigateToSupplementalQuestionnaireFromSubmission = createAction(
    AssessmentCollectionCommonCommandActions.NavigateToSupplementalQuestionnaireFromSubmission,
);

export const setSubmissionContext = createAction(
    AssessmentCollectionCommonCommandActions.SetSubmissionContext,
    props<{ context: AssessmentSubmissionContext }>(),
);
