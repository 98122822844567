<app-full-page-file-drop (onFileDropped)="uploadArtifacts.emit($event)" />

<div class="container p-3">
    <div class="row mb-5">
        <div class="col">
            <p class="text-bold">1. Please download and complete these additional questions.</p>
            @for (
                supplementalQuestionnaireUrl of supplementalQuestionnaireLinks;
                track supplementalQuestionnaireUrl;
                let index = $index
            ) {
                <div class="row">
                    <div class="col">
                        <button
                            class="mt-2"
                            mat-stroked-button
                            color="secondary"
                            data-pw="supplementalUrl"
                            (click)="downloadFile(supplementalQuestionnaireUrl)"
                        >
                            <mat-icon fontSet="material-symbols-outlined">download</mat-icon>
                            Compliance questionnaire {{ supplementalQuestionnaireLinks.length === 1 ? '' : index + 1 }}
                        </button>
                    </div>
                </div>
            }
        </div>
    </div>

    <div class="row mb-3">
        <div class="col">
            <p class="text-bold mb-4">2. Upload the completed questionnaire(s) and any additional related documents.</p>
            <input
                ng2FileSelect
                hidden
                type="file"
                #fileInput
                multiple
                data-pw="fileInput"
                [uploader]="uploader"
                (onFileSelected)="uploadArtifacts.emit($event)"
            />
            <div class="display-2 text-secondary d-inline-block">
                <mat-icon [inline]="true">upload_file</mat-icon>
            </div>
            <p>
                Files you upload will appear below.<br />
                Drag and drop anywhere on this page or
                <a
                    class="text-secondary text-decoration-underline"
                    href="javascript:void(0)"
                    (click)="fileInput.click()"
                    >select files</a
                >.
            </p>
        </div>
    </div>

    <div class="row">
        <div class="col">
            @for (artifact of supplementalArtifacts; track artifact.id) {
                <div class="me-4 mb-2" [ngClass]="{ 'can-delete': artifact.canBeDeleted }">
                    <div class="d-flex justify-content-between">
                        <mat-card class="w-100">
                            <mat-card-content>
                                <div class="text-truncate text-bold">{{ artifact.fileName }}</div>
                                <div class="pt-1 text-muted">
                                    Uploaded {{ artifact.uploadedDate | date: 'MM/dd/yyyy' }}
                                </div>
                            </mat-card-content>
                        </mat-card>
                        <button class="ms-3" mat-icon-button (click)="deleteFileArtifact(artifact)">
                            <mat-icon>close</mat-icon>
                        </button>
                    </div>
                </div>
            }
        </div>
    </div>

    @if (!!contactEmail) {
        <div class="row mt-5">
            <div class="col">
                <p class="text-muted pt-2 border-top" data-pw="supplementalFooter">
                    For any questions about the compliance questionnaire(s), please reach out to
                    <br />
                    <a href="{{ contactEmail }}" class="text-primary">{{ contactEmail }}</a
                    >.
                </p>
            </div>
        </div>
    }
</div>
