import { Inject, Injectable, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable()
export class HubspotScriptInjectorService {
    constructor(
        private _renderer2: Renderer2,
        @Inject(DOCUMENT) private _document: Document,
    ) {}

    injectScript(): void {
        // Create Script & append it to body
        const scriptTag = this._renderer2.createElement('script') as HTMLScriptElement;
        scriptTag.type = 'text/javascript';
        scriptTag.src = '//js.hs-scripts.com/8139123.js';
        scriptTag.async = true;
        scriptTag.defer = true;
        this._renderer2.appendChild(this._document.body, scriptTag);
    }
}
