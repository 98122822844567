import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { addRelationshipWithVendor } from '../../../routes/vendor-directory/redux/vendor-directory.actions';
import { tap } from 'rxjs/operators';
import {
    addRelationshipRoute,
    addRelationshipSelectedVendorIdParam,
} from '../../../routes/add-relationship/add-relationship.constants';
import { Router } from '@angular/router';

@Injectable()
export class GlobalVendorEffect {
    addRelationshipWithVendor$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(addRelationshipWithVendor),
                tap(({ vendor }) =>
                    this._router.navigate([{ outlets: { popup: [addRelationshipRoute] } }], {
                        queryParams: { [addRelationshipSelectedVendorIdParam]: vendor.id },
                    }),
                ),
            ),
        { dispatch: false },
    );

    constructor(
        private readonly _actions$: Actions,
        private readonly _router: Router,
    ) {}
}
