import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ContentEditableReactiveDirective } from './content-editable-reactive.directive';

@NgModule({
    declarations: [ContentEditableReactiveDirective],
    imports: [CommonModule],
    exports: [ContentEditableReactiveDirective],
    providers: [ContentEditableReactiveDirective],
})
export class ContentEditableReactiveModule {}
