import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { PrimaryVendorContactService } from '../../../primary-vendor-contact';
import {
    getPrimaryVendorContactRequest,
    getPrimaryVendorContactRequestFailed,
    getPrimaryVendorContactRequestSuccess,
} from '../actions/primary-contact.actions';

@Injectable()
export class PrimaryContactEffects {
    primaryVendorContactRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(getPrimaryVendorContactRequest),
            switchMap(({ primaryContactId }) =>
                this._primaryVendorContactService.find(primaryContactId).pipe(
                    map((response) => response.body),
                    map((primaryVendorContact) => getPrimaryVendorContactRequestSuccess({ primaryVendorContact })),
                    catchError(() => of(getPrimaryVendorContactRequestFailed())),
                ),
            ),
        ),
    );

    constructor(
        private _actions$: Actions,
        private _primaryVendorContactService: PrimaryVendorContactService,
    ) {}
}
