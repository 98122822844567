<ng-template #classificationWarningPopover>
    <div class="classification-warning-popover">
        <strong>The provided artifact was not classified as {{ AuditReportTypeName[expectedClassification] }}.</strong>
        <br />
        <br />
        Provide another artifact or continue if you believe this replacement is correct. Please note that all artifacts
        are manually verified by a human auditor.
    </div>
</ng-template>
<div class="position-relative">
    <mat-icon
        class="icon"
        [ngClass]="{ pulse: state === 'waiting' }"
        [@iconSlideRight]="state"
        (@iconSlideRight.done)="moveToNextState()"
        >auto_awesome</mat-icon
    >
    <div class="text-container" [@contentReveal]="state">
        <div class="d-flex justify-content-center">
            {{ auditReportTypeLabel }}
            @if (displayIncorrectClassificationPopover) {
                <mat-icon
                    class="text-danger ms-1"
                    [inline]="true"
                    [appPopover]="classificationWarningPopover"
                    popoverPosition="bottom"
                    popoverTheme="dark"
                >
                    info
                </mat-icon>
            }
        </div>
    </div>
</div>
