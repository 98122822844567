<div class="content-heading">
    <div>
        Risk Model
        <small>Manage and update the risk model</small>
    </div>
</div>
<div class="row">
    <div class="col-md-6">
        <div class="card card-default">
            <div class="card-header">
                <p class="card-title">Refresh risk assessments</p>
            </div>
            <div class="card-body">
                <section class="demo-tools-loading" *ngIf="isSaving">
                    <div class="d-flex align-items-center justify-content-center">
                        <div class="sk-spinner sk-spinner-pulse"></div>
                    </div>
                </section>
                <section *ngIf="!isSaving">
                    <form
                        name="riskAssessmentRefreshForm"
                        role="form"
                        novalidate=""
                        (submit)="refreshRiskModel()"
                        #riskAssessmentRefreshForm="ngForm"
                    >
                        <div class="form-group">
                            <label class="form-control-label" for="field_reasonDetail"></label>
                            <input
                                name="reasonDetail"
                                type="text"
                                class="form-control"
                                [(ngModel)]="riskAssessmentRefresh.reasonDetail"
                                id="field_reasonDetail"
                                placeholder="Detailed reason for execution"
                                required
                            />
                        </div>

                        <mat-checkbox
                            name="createNew"
                            [(ngModel)]="riskAssessmentRefresh.createNew"
                            id="field_createNew"
                            color="primary"
                            >Create New Risk Assessments?</mat-checkbox
                        >

                        <div class="invalid-feedback d-block">
                            Warning: This will create a new risk assessment and impact graphs
                        </div>
                        <div class="form-group">
                            <label class="form-control-label" for="field_clientOrg">Client Org</label>
                            <select
                                class="form-control"
                                id="field_clientOrg"
                                name="clientOrg"
                                [(ngModel)]="riskAssessmentRefresh.orgId"
                            >
                                <option [ngValue]="null"></option>
                                <option [ngValue]="clientOrg.id" *ngFor="let clientOrg of orgs; trackBy: trackOrgById">
                                    {{ clientOrg.name }}
                                </option>
                            </select>
                        </div>
                        <div class="form-group">
                            <button
                                mat-button
                                visoButton="primary"
                                class="mb-2 ms-2"
                                type="submit"
                                [disabled]="isSaving"
                            >
                                Refresh
                            </button>
                        </div>
                    </form>
                </section>
            </div>
            <div class="card-footer">Be careful, this can be dangerous</div>
        </div>
    </div>
</div>
