<div class="row mt-5">
    <div class="col-12">
        <div class="text-center mb-4">
            <div class="text-lg mb-3">
                <em class="fa fa-tractor fa-2x text-muted"></em>
            </div>
            <div class="text-lg mb-3">Stay tuned!</div>
            <p class="lead m-0">This feature is coming soon.</p>
        </div>
        <ul class="list-inline text-center text-sm mb-4">
            <li class="list-inline-item">
                <a class="text-muted" [routerLink]="'/'">Home</a>
            </li>
        </ul>
    </div>
</div>
