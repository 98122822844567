<div class="progress-container">
    <progress
        max="100"
        [value]="sensitivity"
        [ngClass]="{ legend: legend ? 'legend' : '', invert: invertLabel ? 'invert' : '' }"
    ></progress>
    <div class="progress-markers">
        <span class="low" [ngClass]="isLowSelected">Low</span>
        <span class="medium" [ngClass]="isMediumSelected">Medium</span>
        <span class="high" [ngClass]="isHighSelected">High</span>
        <span class="extreme" [ngClass]="isExtremeSelected">Extreme</span>
    </div>
</div>
