import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import {
    ArtifactFilePageLink,
    ArtifactType,
    ControlValidationDetectionType,
    OpenArtifactFileParams,
    PageRangeLink,
} from '../../../../../entities/artifact';
import { BREADCRUMB_CONTAINER_TOKEN } from '../../../../../shared/dynamic-content/dynamic-content-injector';

import { Detection } from '../../../models/detection.model';
import { AssuranceLevels } from '../../../../..//entities/relationship/models/security-control-domain';
import { AuditReportTypeCode, AuditReportTypeName } from '../../../../../entities/audit-report';
import { openArtifactFile } from '../../../../../routes/request/redux/actions/artifacts.actions';
import moment from 'moment';
import { FrameworkMappingType, FrameworkMappingTypeLabels } from '@entities/framework/models/framework-mapping.model';

@Component({
    selector: 'app-detection',
    templateUrl: './detection.component.html',
    styleUrls: ['./detection.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DetectionComponent {
    public readonly breadcrumbsContainerToken = BREADCRUMB_CONTAINER_TOKEN;
    @Input() detection: Detection;

    @Input()
    frameworkType: FrameworkMappingType;

    AssuranceLevels = AssuranceLevels;
    ArtifactType = ArtifactType;
    AuditReportType = AuditReportTypeName;
    DetectionType = ControlValidationDetectionType;

    private isoAuditReportTypes: AuditReportTypeCode[] = [
        AuditReportTypeCode.ISOIEC2700127002,
        AuditReportTypeCode.ISO27001CERTONLY,
    ];

    constructor(private _store$: Store) {}

    get subservicerNames() {
        const { subservicers } = this.detection;
        return subservicers.map((s) => s.name).join(', ');
    }

    get artifactTooltip(): string {
        const { artifactExpirationDate, artifactValidationExpired, artifactValidationAuditReportType } = this.detection;
        if (!artifactExpirationDate) {
            return 'Does not expire';
        }
        if (artifactValidationExpired) {
            if (this.isoAuditReportTypes.includes(artifactValidationAuditReportType as AuditReportTypeCode)) {
                return 'This artifact is subject to an annual surveillance audit so this date may be different than the ISO Recertification date';
            } else {
                return 'Expired on ' + moment(artifactExpirationDate).format('MMM DD, YYYY');
            }
        } else {
            return 'Expiring on ' + moment(artifactExpirationDate).format('MMM DD, YYYY');
        }
    }

    openArtifactPage(artifact: OpenArtifactFileParams): void {
        this._store$.dispatch(openArtifactFile(artifact));
    }

    getPages(pageList: string): ArtifactFilePageLink[] {
        if (!pageList) {
            return null;
        }
        const pageNumbers = pageList.replace(/, +/g, ',').split(',');

        return pageNumbers.map((page) => {
            if (isNaN(Number(page)) && page.includes('-')) {
                const pageRange = page.split('-');
                return {
                    initialPage: +pageRange[0],
                    finalPage: +pageRange[1],
                };
            } else if (typeof page === 'number') {
                return page;
            } else {
                return page;
            }
        });
    }

    getPageType(page: ArtifactFilePageLink) {
        if (page && (page as PageRangeLink).initialPage) {
            return 'range';
        }
        if (!isNaN(page as number)) {
            return 'page';
        }
        return 'text';
    }

    getFrameworkTypeLabel(frameworkType: string) {
        return FrameworkMappingTypeLabels[frameworkType];
    }
}
