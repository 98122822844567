import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Webhook } from './webhooks.model';

@Injectable()
export class WebhooksService {
    private _resourceUrl = 'api/orgs/';

    constructor(private _http: HttpClient) {}

    getWebhooks(): Observable<Webhook[]> {
        return this._http.get<Webhook[]>(`${this._resourceUrl}service-integration`);
    }

    saveWebhook(orgId: number, webhook: Webhook) {
        return this._http.put(`${this._resourceUrl}${orgId}/service-integration`, webhook);
    }

    deleteWebhook(orgId: number, webhookId: number) {
        return this._http.delete(`${this._resourceUrl}${orgId}/service-integration/${webhookId}`);
    }
}
