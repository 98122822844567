import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { RelationshipRiskToleranceView, RiskTolerance, RiskToleranceLabels } from '@entities/risk-tolerance';
import { Risk } from '@entities/risk-assessment';
import { RiskThresholdData } from '../redux/risk-model-settings.selectors';
import { OrgRiskConfig } from '../../../admin/client-profile/client-profile.model';
import { RiskUtilsService } from '@shared/utils/risk-utils.service';
import { VisoUserRole } from '@entities/viso-user';

@Component({
    selector: 'app-risk-tolerance',
    templateUrl: './risk-tolerance.component.html',
    styleUrls: ['./risk-tolerance.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RiskToleranceComponent {
    @Input({ required: true })
    clientRiskTolerance: RiskTolerance;

    @Input({ required: true })
    riskToleranceThresholds: Map<RiskTolerance, Map<Risk, RiskThresholdData>>;

    @Input({ required: true })
    relationshipRiskDistribution: Map<Risk, RelationshipRiskToleranceView[]>;

    @Input({ required: true })
    riskLevelDisplayNames: OrgRiskConfig[];

    @Output()
    customizeRiskTolerance = new EventEmitter<void>();

    RiskToleranceLabels = RiskToleranceLabels;
    RiskTolerance = RiskTolerance;
    Roles = VisoUserRole;

    constructor(private _riskUtilsService: RiskUtilsService) {}

    getRiskToleranceSummary = (riskTolerance: RiskTolerance): string => {
        return this._riskUtilsService.getRiskToleranceSummary(riskTolerance);
    };
}
