import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Actions, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { merge, Observable, of, Subject } from 'rxjs';
import { map, take, takeUntil, tap } from 'rxjs/operators';
import { EnumSelectOption } from '../../../../../shared/model/select-options';
import { FormUtilsService } from '../../../../../shared/utils/form-utils.service';
import { VisoUser, VisoUserRole } from '../../../../../entities/viso-user';
import {
    Artifact,
    ArtifactValidationStatus,
    ControlValidation,
    ControlValidationDetection,
    ControlValidationDetectionType,
    ControlValidationDetectionTypeLabels,
    ControlValidationStatus,
    ControlValidationStatusLabels,
    DetectionSourceTypeDisplayName,
    SourceType,
} from '../../../../../entities/artifact';
import { AuditReportService, AuditReportTypeName } from '../../../../../entities/audit-report';
import { Relationship } from '../../../../../entities/relationship';
import { Control } from '../../../../../entities/control-domain';
import {
    createRequestArtifactControlValidationRequest,
    createRequestArtifactControlValidationRequestFailed,
    createRequestArtifactControlValidationRequestSuccess,
    deleteControlConfirmationModalDismissed,
    deleteRequestArtifactControlValidationRequest,
    deleteRequestArtifactControlValidationRequestFailed,
    deleteRequestArtifactControlValidationRequestSuccess,
    updateRequestArtifactControlValidationRequest,
    updateRequestArtifactControlValidationRequestFailed,
    updateRequestArtifactControlValidationRequestSuccess,
} from '../../../../../routes/request/redux/actions/artifacts.actions';
import { FormRawValue } from '../../../../model/controls-of';
import { VendorSearchResult } from '../../../../vendor-components/models/vendor-search-result';
import { ArtifactControl, ArtifactControlDomain, ArtifactControlDomainOption } from '../../../models';
import { suggestedControlIds } from '../../../control-ids.constants';
import { INgxSelectOption } from 'ngx-select-ex';

interface AddControlFormGroup {
    control: FormControl<Control>;
    status: FormControl<ControlValidationStatus>;
    sourceType: FormControl<SourceType>;
    detections: FormArray<FormGroup<DetectionFormGroup>>;
}
interface DetectionFormGroup {
    id: FormControl<number>;
    type: FormControl<ControlValidationDetectionType>;
    subservicerRefArray?: FormArray<FormControl<VendorSearchResult>>;
    subservicer: FormControl<boolean>;
    viewerPage: FormControl<string>;
    sectionName: FormControl<string>;
    controlIdsText: FormControl<string | string[]>;
    notes: FormControl<string>;
    auditorComment: FormControl<string>;
    sourceType: FormControl<SourceType>;
    confidence: FormControl<number>;
    managementResponse: FormControl<string>;
    controlAssigned: FormControl<number>;
    controlDomainAssigned: FormControl<number>;
    isControlAssignedDomainLevel: FormControl<boolean>;
}

@Component({
    selector: 'app-add-control-modal',
    templateUrl: './add-control-modal.component.html',
    styleUrls: ['./add-control-modal.component.scss'],
})
export class AddControlModalComponent implements OnInit, OnDestroy {
    @Input()
    requestId: number;

    @Input()
    request: Relationship;

    @Input()
    artifact: Artifact;

    @Input()
    artifactControl: ArtifactControl;

    @Input()
    currentAccount: VisoUser;

    @Input()
    controlValidation: ControlValidation;

    @Input()
    fromSecurityControlProfile = false;

    @Input()
    allArtifactControlDomains: ArtifactControlDomain[] = null;

    addControlFormGroup: FormGroup<AddControlFormGroup>;

    controlStatus$: Observable<EnumSelectOption[]>;

    detectionTypes$: Observable<EnumSelectOption[]>;

    controlsToAssign$: Observable<ArtifactControlDomainOption[]>;

    submitButtonDisabled$: Observable<boolean>;

    deleteButtonDisabled$: Observable<boolean>;

    controlActionsStates$: Observable<boolean>[];

    showSuggestedControlIds: boolean;

    suggestedControlIds: string[];

    Roles = VisoUserRole;

    DetectionSourceTypeDisplayName = DetectionSourceTypeDisplayName;

    private _unsub$ = new Subject<void>();

    constructor(
        private _fb: FormBuilder,
        private _activeModal: NgbActiveModal,
        private _store$: Store,
        private _actions$: Actions,
        private _auditReportService: AuditReportService,
        private _formUtils: FormUtilsService,
    ) {}

    get controlDetections(): FormGroup<DetectionFormGroup>[] {
        return this.addControlFormGroup.controls.detections.controls;
    }

    get isValidationComplete(): boolean {
        return this.artifact.validation?.status === ArtifactValidationStatus.COMPLETE;
    }

    get isDomainOverride(): boolean {
        return !this.artifactControl?.control?.id;
    }

    showSubservicerSelectField(index: number): boolean {
        return this.addControlFormGroup.controls.detections.at(index).controls.subservicer.getRawValue();
    }

    showConfidenceScore(index: number): boolean {
        return !!this.addControlFormGroup.controls.detections.at(index).controls.confidence.getRawValue();
    }

    showManagementResponseInput(index: number): boolean {
        return (
            this.addControlFormGroup.controls.detections.at(index).controls.type.value ===
            ControlValidationDetectionType.EXCEPTION
        );
    }

    ngOnInit(): void {
        let controlValidationStatusOptions: EnumSelectOption[] = [
            {
                enumValue: ControlValidationStatus.PRESENT,
                name: ControlValidationStatusLabels.PRESENT,
            },
            {
                enumValue: ControlValidationStatus.NOT_PRESENT,
                name: ControlValidationStatusLabels.NOT_PRESENT,
            },
            {
                enumValue: ControlValidationStatus.NOT_APPLICABLE,
                name: ControlValidationStatusLabels.NOT_APPLICABLE,
            },
        ];

        let currentArtifactAuditType: string = this._auditReportService.getAuditTypeFriendlyName(
            this.artifact.validation?.auditReportType,
        );

        const isCurrentArtifactAuditTypeSOCType = [
            AuditReportTypeName.SOC1TYPE1.valueOf(),
            AuditReportTypeName.SOC1TYPE2.valueOf(),
            AuditReportTypeName.SOC2TYPE1.valueOf(),
            AuditReportTypeName.SOC2TYPE2.valueOf(),
        ].includes(currentArtifactAuditType);

        const isCurrentArtifactAuditTypeSOC2Type = [
            AuditReportTypeName.SOC2TYPE1.valueOf(),
            AuditReportTypeName.SOC2TYPE2.valueOf(),
        ].includes(currentArtifactAuditType);

        const isCurrentArtifactAuditTypeISOType = [AuditReportTypeName.ISOIEC2700127002.valueOf()].includes(
            currentArtifactAuditType,
        );

        if (isCurrentArtifactAuditTypeSOCType) {
            controlValidationStatusOptions.splice(2, 0, {
                enumValue: ControlValidationStatus.DESCRIPTION_ONLY,
                name: ControlValidationStatusLabels.DESCRIPTION_ONLY,
            });
        }

        if (isCurrentArtifactAuditTypeSOC2Type || isCurrentArtifactAuditTypeISOType) {
            this.showSuggestedControlIds = true;
            this.suggestedControlIds = [
                ...new Set(
                    suggestedControlIds
                        .filter((control) => {
                            if (this.isDomainOverride) {
                                return this.artifactControl.control?.controlDomainId === control.controlDomainId;
                            } else {
                                return this.artifactControl.control?.id === control.controlId;
                            }
                        })
                        .map((control) =>
                            isCurrentArtifactAuditTypeSOCType ? control.socControlIds : control.isoControlIds,
                        )
                        .flat(),
                ),
            ];
        }

        this.showSuggestedControlIds = [
            AuditReportTypeName.SOC1TYPE1.valueOf(),
            AuditReportTypeName.SOC1TYPE2.valueOf(),
            AuditReportTypeName.SOC2TYPE1.valueOf(),
            AuditReportTypeName.SOC2TYPE2.valueOf(),
            AuditReportTypeName.ISOIEC2700127002.valueOf(),
            AuditReportTypeName.ISO27001CERTONLY.valueOf(),
        ].includes(currentArtifactAuditType);

        if (
            this.isValidationComplete ||
            this.controlValidation?.sourceType === SourceType.HEURISTIC ||
            this.controlValidation?.sourceType === SourceType.INFERENCE ||
            this.controlValidation?.sourceType === SourceType.IQR ||
            this.controlValidation?.sourceType === SourceType.RTP_INFERENCE ||
            this.controlValidation?.sourceType === SourceType.RTP_IQR ||
            this.controlValidation?.sourceType === SourceType.RTP_GENERATED
        ) {
            this.showSuggestedControlIds = true;
        }

        this.controlStatus$ = of(controlValidationStatusOptions);

        this.detectionTypes$ = of([
            {
                enumValue: ControlValidationDetectionType.NORMAL,
                name: ControlValidationDetectionTypeLabels.NORMAL,
            },
            {
                enumValue: ControlValidationDetectionType.SUBSERVICE,
                name: ControlValidationDetectionTypeLabels.SUBSERVICE,
            },
            {
                enumValue: ControlValidationDetectionType.SHARED_RESPONSIBILITY_MODEL,
                name: ControlValidationDetectionTypeLabels.SHARED_RESPONSIBILITY_MODEL,
            },
            {
                enumValue: ControlValidationDetectionType.EXCEPTION,
                name: ControlValidationDetectionTypeLabels.EXCEPTION,
            },
            {
                enumValue: ControlValidationDetectionType.CUEC,
                name: ControlValidationDetectionTypeLabels.CUEC,
            },
        ]);

        if (this.controlValidation) {
            this.controlsToAssign$ = of([
                ...this.allArtifactControlDomains.map((acd) => ({
                    id: acd.controlId,
                    name: acd.controlName,
                    children: [
                        ...(this.isDomainOverride && acd.controlId === this.controlValidation.controlDomainId
                            ? []
                            : [
                                  {
                                      id: acd.controlId,
                                      domainId: acd.controlId,
                                      key: `${acd.controlId}${acd.controlName}`,
                                      name: `${acd.controlName} Domain`,
                                      domain: true,
                                  },
                              ]),
                        ...acd.artifactControls.map((ac) => {
                            if (ac.control?.id !== this.controlValidation.controlId) {
                                return {
                                    id: ac.control?.id,
                                    domainId: ac.control?.controlDomainId,
                                    key: `${ac.control?.id}${ac.control?.name}`,
                                    name: ac.control?.name,
                                };
                            }
                        }),
                    ],
                })),
            ]);
        }

        const control = this.artifactControl.control;
        this.addControlFormGroup = this._fb.group(
            {
                control: this._fb.control(control, {
                    validators: Validators.required,
                    nonNullable: true,
                }),
                status: this._fb.control(this.controlValidation?.controlValidationStatus, {
                    validators: Validators.required,
                }),
                sourceType: this._fb.control(this.controlValidation?.sourceType || SourceType.AUDITOR, {
                    nonNullable: true,
                }),
                detections: this._fb.array(
                    this.controlValidation?.validationDetections
                        .reverse()
                        .map((detection) => this.generateControlDetection(detection)) || [
                        this.generateControlDetection(),
                    ],
                ),
            },
            {
                validators: (form: FormGroup<AddControlFormGroup>): ValidationErrors | null => {
                    if (!form.controls.detections.length) {
                        return { atLeastOneDetection: true };
                    }

                    return null;
                },
            },
        );

        if (
            !this.currentAccount.authorities.includes(this.Roles.Auditor) ||
            this.artifact.validation?.status === ArtifactValidationStatus.COMPLETE
        ) {
            this.addControlFormGroup.disable();
        }

        if (this.controlValidation) {
            this.addControlFormGroup.controls.control.disable();
            if (this.isValidationComplete) {
                this.addControlFormGroup.controls.status.disable();
                this.addControlFormGroup.controls.detections.disable();
            }
        }

        this.controlActionsStates$ = [
            this._actions$.pipe(
                ofType(
                    updateRequestArtifactControlValidationRequest,
                    createRequestArtifactControlValidationRequest,
                    deleteRequestArtifactControlValidationRequest,
                ),
                map(() => true),
            ),
            this._actions$.pipe(
                ofType(
                    createRequestArtifactControlValidationRequestSuccess,
                    updateRequestArtifactControlValidationRequestSuccess,
                    deleteRequestArtifactControlValidationRequestSuccess,
                    createRequestArtifactControlValidationRequestFailed,
                    updateRequestArtifactControlValidationRequestFailed,
                    deleteRequestArtifactControlValidationRequestFailed,
                    deleteControlConfirmationModalDismissed,
                ),
                map(() => false),
            ),
        ];

        this.submitButtonDisabled$ = merge(
            of(this.addControlFormGroup.invalid),
            ...this.controlActionsStates$,
            this.addControlFormGroup.valueChanges.pipe(map(() => this.addControlFormGroup.invalid)),
        );

        this.deleteButtonDisabled$ = merge(...this.controlActionsStates$);

        this._actions$
            .pipe(
                ofType(
                    createRequestArtifactControlValidationRequestSuccess,
                    updateRequestArtifactControlValidationRequestSuccess,
                    deleteRequestArtifactControlValidationRequestSuccess,
                ),
                tap(() => this.cancel()),
                takeUntil(this._unsub$),
            )
            .subscribe();
    }

    submit(): void {
        const [requestId, artifactId] = [this.requestId, this.artifact.id];
        const controlValidations = this.getAllControlValidationsByDetections();

        controlValidations.forEach((controlValidation) => {
            const controlValidationId = controlValidation.id;
            const controlId = controlValidation.controlId;
            const {
                clientId: clientId,
                clientName: clientName,
                id: relationshipId,
                vendorName: relationshipName,
            } = this.request || {};

            let action: Action;

            if (controlValidationId) {
                if (controlValidation.markForDelete) {
                    this._actions$
                        .pipe(
                            ofType(
                                updateRequestArtifactControlValidationRequestSuccess,
                                createRequestArtifactControlValidationRequestSuccess,
                            ),
                            take(1),
                            tap(() =>
                                this._store$.dispatch(
                                    deleteRequestArtifactControlValidationRequest({
                                        requestId,
                                        artifactId,
                                        controlValidationId,
                                        isDomainOverride: !controlValidation.controlId,
                                        disableConfirmation: true,
                                    }),
                                ),
                            ),
                        )
                        .subscribe();
                } else {
                    action = updateRequestArtifactControlValidationRequest({
                        requestId,
                        artifactId,
                        controlId,
                        controlValidationId,
                        controlValidation,
                        clientId,
                        clientName,
                        relationshipId,
                        relationshipName,
                    });
                }
            } else {
                action = createRequestArtifactControlValidationRequest({
                    requestId,
                    artifactId,
                    controlId,
                    controlValidation,
                    clientId,
                    clientName,
                    relationshipId,
                    relationshipName,
                });
            }

            if (action) this._store$.dispatch(action);
        });
    }

    addControlDetection(): void {
        this.addControlFormGroup.controls.detections.insert(0, this.generateControlDetection());
    }

    deleteControlDetection(index: number): void {
        this.addControlFormGroup.controls.detections.removeAt(index);
    }

    controlDetectionTypeChanged(index: number): void {
        this.addControlFormGroup.controls.detections
            .at(index)
            .controls.subservicer.setValue(
                this.addControlFormGroup.controls.detections.at(index).controls.type.value ===
                    ControlValidationDetectionType.SUBSERVICE,
            );
    }

    controlAssignedChanged(index: number, options: INgxSelectOption[]): void {
        const [selectedControl] = options;
        const controlFormGroupControls = this.addControlFormGroup.controls.detections.at(index).controls;

        controlFormGroupControls.controlAssigned.setValue(selectedControl.data.id);
        controlFormGroupControls.controlDomainAssigned.setValue(selectedControl.data.domainId);
        controlFormGroupControls.isControlAssignedDomainLevel.setValue(!!selectedControl.data.domain);
    }

    deleteControlValidation(): void {
        const artifactControl = this.artifactControl;
        const artifact = this.artifact;
        const artifactId = artifact.id;
        const controlValidationId = this.isDomainOverride
            ? this.controlValidation?.id
            : artifactControl.controlValidation?.id;
        const requestId = this.request?.id;
        this._store$.dispatch(
            deleteRequestArtifactControlValidationRequest({
                requestId,
                artifactId,
                controlValidationId,
                isDomainOverride: this.isDomainOverride,
                disableConfirmation: false,
            }),
        );
    }

    cancel(): void {
        this._activeModal.dismiss();
    }

    ngOnDestroy(): void {
        this._unsub$.next();
    }

    private getAllControlValidationsByDetections(): ControlValidation[] {
        let controlValidationsByDetections: ControlValidation[] = [];
        let controlValidation = this.getControlValidation();
        controlValidation.validationDetections.forEach((detection) => {
            if (!!detection.controlAssigned) {
                const currentArtifactControlDomain = this.allArtifactControlDomains.find(
                    (cd) => cd.controlId === detection.controlDomainAssigned,
                );

                let controlValidationAssigned = detection.isControlAssignedDomainLevel
                    ? currentArtifactControlDomain?.controlDomainOverride
                    : currentArtifactControlDomain?.artifactControls.find(
                          (a) => a.control.id === detection.controlAssigned,
                      )?.controlValidation;

                const newControlValidationCreated = controlValidationsByDetections.find(
                    (c) =>
                        c.controlDomainId === detection.controlDomainAssigned &&
                        c.controlId === (detection.isControlAssignedDomainLevel ? null : detection.controlAssigned),
                );

                controlValidationAssigned = controlValidationAssigned || newControlValidationCreated;

                if (!controlValidationAssigned) {
                    controlValidationAssigned = new ControlValidation();
                    controlValidationAssigned.controlId = detection.isControlAssignedDomainLevel
                        ? null
                        : detection.controlAssigned;
                    controlValidationAssigned.controlDomainId = detection.controlDomainAssigned;
                    controlValidationAssigned.controlValidationStatus = ControlValidationStatus.PRESENT;
                    controlValidationAssigned.artifactValidationId = this.artifact.validation?.id;
                    controlValidationAssigned.sourceType = detection.sourceType;
                    controlValidationAssigned.validationDetections = [
                        ...(controlValidationAssigned.validationDetections || []),
                        { ...detection, id: null, controlValidationId: null },
                    ];
                } else {
                    controlValidationAssigned.validationDetections = [
                        ...controlValidationAssigned.validationDetections,
                        { ...detection, id: null, controlValidationId: controlValidationAssigned.id },
                    ];
                }

                const matchingControlValidation = controlValidationsByDetections.find(
                    (c) =>
                        c.controlDomainId === controlValidationAssigned.controlDomainId &&
                        c.controlId === controlValidationAssigned.controlId,
                );

                if (!matchingControlValidation) controlValidationsByDetections.push(controlValidationAssigned);

                controlValidation = {
                    ...controlValidation,
                    validationDetections: controlValidation.validationDetections.filter((v) => v.id !== detection.id),
                };
            }
        });

        controlValidation.markForDelete = controlValidation.validationDetections.every(
            (detection) => !!detection.controlAssigned,
        );

        controlValidationsByDetections.push(controlValidation);
        return controlValidationsByDetections;
    }

    private getControlValidation(): ControlValidation {
        const controlValidation = !!this.controlValidation
            ? Object.assign({}, this.controlValidation)
            : new ControlValidation();
        const formValue = this._formUtils.getCleanFormGroupValue<FormRawValue<FormGroup<AddControlFormGroup>>>(
            this.addControlFormGroup,
            true,
        );
        controlValidation.controlId = formValue.control.id;
        controlValidation.controlDomainId = formValue.control.controlDomainId;
        controlValidation.controlValidationStatus = formValue.status;
        controlValidation.artifactValidationId = this.artifact.validation?.id;
        controlValidation.sourceType = formValue.sourceType;
        controlValidation.validationDetections = formValue.detections
            .filter((formDetection) => {
                if (
                    !formDetection.type &&
                    !formDetection.viewerPage &&
                    !formDetection.sectionName &&
                    !formDetection.controlIdsText
                ) {
                    return false;
                }
                return true;
            })
            .map((formDetection) => {
                const {
                    id,
                    type,
                    subservicerRefArray,
                    viewerPage,
                    sectionName,
                    notes,
                    auditorComment,
                    sourceType,
                    confidence,
                    managementResponse,
                    controlAssigned,
                    controlDomainAssigned,
                    isControlAssignedDomainLevel: isControlAssignedDomainLevel,
                } = formDetection;
                const detection: ControlValidationDetection = {
                    id,
                    type,
                    subservicers: subservicerRefArray
                        .filter((subservicer) => !!subservicer)
                        .map((subservicer) => {
                            return {
                                subservicerId: subservicer?.id,
                                subservicerName: subservicer?.name,
                                subservicerHomepage: subservicer?.homepage,
                                subservicerFaviconUrl: subservicer?.faviconUrl,
                            };
                        }),
                    viewerPage,
                    sectionName,
                    controlIdsText: Array.isArray(formDetection.controlIdsText)
                        ? formDetection.controlIdsText.join(',')
                        : formDetection.controlIdsText,
                    notes,
                    auditorComment,
                    sourceType,
                    confidence,
                    managementResponse,
                    controlAssigned,
                    controlDomainAssigned,
                    isControlAssignedDomainLevel: isControlAssignedDomainLevel,
                };
                return detection.id
                    ? Object.assign(
                          controlValidation.validationDetections?.find((vd) => vd.id === formDetection.id) || {},
                          detection,
                      )
                    : detection;
            });
        return controlValidation;
    }

    private generateControlDetection(detection?: ControlValidationDetection): FormGroup<DetectionFormGroup> {
        const controlIdsText = this.showSuggestedControlIds
            ? detection?.controlIdsText
                ? detection?.controlIdsText.split(',')
                : []
            : detection?.controlIdsText;
        const subservicers = detection?.subservicers.map((subservicer) =>
            this._fb.control<VendorSearchResult>(
                !!subservicer?.subservicerName
                    ? {
                          id: subservicer?.subservicerId,
                          name: subservicer?.subservicerName,
                          homepage: subservicer?.subservicerHomepage,
                          faviconUrl: subservicer?.subservicerFaviconUrl,
                      }
                    : null,
            ),
        );
        return this._fb.group<DetectionFormGroup>(
            {
                id: this._fb.control(detection?.id),
                type: this._fb.control(
                    (detection?.type || ControlValidationDetectionType.NORMAL) as ControlValidationDetectionType,
                    {
                        validators: Validators.required,
                    },
                ),
                subservicerRefArray: this._fb.array(!!subservicers ? subservicers : [this._fb.control(null)]),
                subservicer: this._fb.control(detection?.type === ControlValidationDetectionType.SUBSERVICE),
                viewerPage: this._fb.control(detection?.viewerPage || ''),
                sectionName: this._fb.control(detection?.sectionName || ''),
                controlIdsText: this._fb.control(controlIdsText || ''),
                notes: this._fb.control(detection?.notes || ''),
                auditorComment: this._fb.control(detection?.auditorComment || ''),
                sourceType: this._fb.control(detection?.sourceType || SourceType.AUDITOR),
                confidence: this._fb.control(detection?.confidence),
                managementResponse: this._fb.control(detection?.managementResponse),
                controlAssigned: null,
                controlDomainAssigned: null,
                isControlAssignedDomainLevel: this._fb.control(false),
            },
            {
                validators: (form: FormGroup<DetectionFormGroup>): ValidationErrors | null => {
                    if (
                        form.controls.sourceType.value === SourceType.AUDITOR &&
                        !form.controls.controlIdsText.value.length &&
                        !form.controls.notes.value
                    ) {
                        return { controlIdOrNotesEmpty: true };
                    }

                    return null;
                },
            },
        );
    }

    getSubservicerFormArray(index: number): FormControl<VendorSearchResult>[] {
        return this.addControlFormGroup.controls.detections?.at(index).controls.subservicerRefArray.controls;
    }

    addSubservicer(index: number) {
        this.addControlFormGroup.controls.detections
            ?.at(index)
            .controls.subservicerRefArray.controls.push(this._fb.control(null));
    }
}
