import { isQuestionnaireArtifact } from '@entities/artifact';
import { RTPEvent, RTPEventType } from '@entities/rtp';
import { Action, createReducer, on } from '@ngrx/store';
import { AdditionalInformationSubSteps, SlimControlDomain, StepIds } from '../models';
import {
    addArtifactUploadRecommendation,
    cancelAssessmentRequestSuccess,
    declineRemediationRequestSuccess,
    deleteFileArtifactsAndSubmitAssessmentRequestSuccess,
    deleteQuestionnaireAndSubmitAssessmentRequestSuccess,
    forwardRequestRequestSuccess,
    getAuditReportsRequestSuccess,
    getPublicAssessmentRequest,
    getPublicAssessmentRequestFailed,
    getPublicAssessmentRequestSuccess,
    setPublicAssessment,
    getRecommendationsRequestSuccess,
    getSecurityControlDomainRequestSuccess,
    onRTPEvent,
    onRtpFileArtifactCreation,
    removeArtifactRequestSuccess,
    removeArtifactUploadRecommendation,
    saveQuestionnaire,
    setArtifactUploadRecommendation,
    setArtifactUploadRecommendations,
    setCurrentCollectArtifactsSubStep,
    setCurrentStep,
    setPasswordForArtifactRequest,
    setSubmissionContext,
    startRTP,
    stopRTP,
    submitAssessmentRequestSuccess,
    submitPasscode,
} from './actions';
import { AssessmentCollectionState, initialState } from './assessment-collection.state';

const reducer = createReducer(
    initialState,
    on(submitPasscode, (state, { passcode }) => ({
        ...state,
        passcode,
        authenticationFailedReason: null,
        publicAssessment: null,
        currentStepId: StepIds.WELCOME,
        collectArtifactsSubStep: AdditionalInformationSubSteps.UPLOAD_RECOMMENDATIONS,
        rtpFileArtifacts: [],
        rtpEventsMap: new Map(),
        canWaitingScreenMoveForward: false,
    })),
    on(getPublicAssessmentRequest, (state) => ({
        ...state,
        isAuthenticating: true,
    })),
    on(getPublicAssessmentRequestSuccess, (state, { publicAssessment }) => ({
        ...state,
        publicAssessment,
        inScopeControlDomains: Object.entries(publicAssessment.inScopeControlDomains).map<SlimControlDomain>(
            ([id, name]) => ({ id: +id, name }),
        ),
        followupRequested: publicAssessment.followupRequested,
        followupControlDomainIds: publicAssessment.followupControlDomainIds,
        expiredArtifactIdsToReplace: Object.fromEntries(
            Object.entries(publicAssessment.expiredArtifactReplacements).map(([expiredArtifactId, replacedById]) => [
                +expiredArtifactId,
                replacedById,
            ]),
        ),
    })),
    on(getPublicAssessmentRequestFailed, (state, { authenticationFailedReason }) => ({
        ...state,
        authenticationFailedReason,
    })),
    on(getPublicAssessmentRequestSuccess, getPublicAssessmentRequestFailed, (state) => ({
        ...state,
        isAuthenticating: false,
    })),
    on(setPublicAssessment, (state, { publicAssessment }) => ({
        ...state,
        publicAssessment,
    })),
    on(getAuditReportsRequestSuccess, (state, { auditReports }) => ({
        ...state,
        auditReports,
    })),
    on(getRecommendationsRequestSuccess, (state, { recommendations }) => ({
        ...state,
        recommendations,
    })),
    on(removeArtifactUploadRecommendation, (state, { recommendation }) => ({
        ...state,
        artifactUploadRecommendations: state.artifactUploadRecommendations.filter(
            (rec) => rec.id !== recommendation.id,
        ),
    })),
    on(startRTP, stopRTP, (state) => ({
        ...state,
        rtpEventsMap: new Map(),
    })),
    on(onRTPEvent, (state, { rtpEvent }) => {
        const prevRtpEventsMap = state.rtpEventsMap;
        const eventMap = prevRtpEventsMap.get(rtpEvent.artifactId) || new Map<RTPEventType, RTPEvent>();
        eventMap.set(rtpEvent.eventType, rtpEvent);
        prevRtpEventsMap.set(rtpEvent.artifactId, eventMap);
        return {
            ...state,
            rtpEventsMap: new Map(prevRtpEventsMap),
        };
    }),
    on(setPasswordForArtifactRequest, (state, { artifactId }) => {
        // Clean Password Error event for the given artifact on the request
        const prevRtpEventsMap = state.rtpEventsMap;
        const eventMap = prevRtpEventsMap.get(artifactId) || new Map<RTPEventType, RTPEvent>();
        eventMap.delete(RTPEventType.RTP_ARTIFACT_ERRORED);
        prevRtpEventsMap.set(artifactId, eventMap);
        return {
            ...state,
            rtpEventsMap: new Map(prevRtpEventsMap),
        };
    }),
    on(onRtpFileArtifactCreation, (state, { rtpFileArtifacts }) => ({
        ...state,
        rtpFileArtifacts,
    })),
    on(removeArtifactRequestSuccess, (state, { artifactId }) => ({
        ...state,
        rtpFileArtifacts: state.rtpFileArtifacts.filter((a) => a.artifact.id !== artifactId),
    })),
    on(addArtifactUploadRecommendation, (state, { recommendation }) => ({
        ...state,
        artifactUploadRecommendations: [...state.artifactUploadRecommendations, recommendation],
    })),
    on(setArtifactUploadRecommendation, (state, { recommendation }) => ({
        ...state,
        artifactUploadRecommendations: state.artifactUploadRecommendations.map((r) =>
            r.id === recommendation.id ? { ...recommendation } : { ...r },
        ),
    })),
    on(setArtifactUploadRecommendations, (state, { recommendations }) => ({
        ...state,
        artifactUploadRecommendations: recommendations,
    })),
    on(saveQuestionnaire, (state, { questionnaire }) => ({
        ...state,
        publicAssessment: {
            ...state.publicAssessment,
            artifacts: (state.publicAssessment.artifacts || []).map((artifact) =>
                isQuestionnaireArtifact(artifact) ? questionnaire : artifact,
            ),
        },
    })),
    on(getSecurityControlDomainRequestSuccess, (state, { securityControlDomain }) => ({
        ...state,
        securityControlDomain,
    })),
    on(
        submitAssessmentRequestSuccess,
        deleteQuestionnaireAndSubmitAssessmentRequestSuccess,
        deleteFileArtifactsAndSubmitAssessmentRequestSuccess,
        (state, {}) => ({
            ...state,
            submitted: true,
        }),
    ),
    on(setCurrentStep, (state, { stepId }) => ({
        ...state,
        currentStepId: stepId,
    })),
    on(setCurrentCollectArtifactsSubStep, (state, { collectArtifactsSubStep }) => ({
        ...state,
        collectArtifactsSubStep,
    })),
    on(cancelAssessmentRequestSuccess, (state) => ({
        ...state,
        justCancelled: true,
    })),
    on(forwardRequestRequestSuccess, (state) => ({
        ...state,
        justForwarded: true,
    })),
    on(declineRemediationRequestSuccess, (state) => ({
        ...state,
        remediationJustDeclined: true,
    })),
    on(setSubmissionContext, (state, { context }) => ({
        ...state,
        submissionContext: context,
    })),
);

export function assessmentCollectionReducer(
    state: AssessmentCollectionState = initialState,
    action: Action,
): AssessmentCollectionState {
    return reducer(state, action);
}
