import { Directive, ElementRef, HostBinding, HostListener, Input, Renderer2 } from '@angular/core';
import { AllTagColors, ColorPickerConfig, InternalTagColorEnum, TagColorEnum } from '../../../entities/tag';

@Directive({ selector: '[tagColor]' })
export class TagColorDirective {
    private _absoluteMoving = false;
    private _tagColors: ColorPickerConfig;
    @HostBinding('style.color') textColor: string;
    @HostBinding('style.background') backgroundColor: string;
    @HostListener('mouseover') onMouseOver() {
        if (this.hoverColorChange) {
            this._setColors(this._tagColors.background, this._tagColors.primary);

            const elementNextWidth = this._el.nativeElement.offsetWidth + 20;
            const containerWidth = this._el.nativeElement.parentElement.clientWidth;
            const offsetLeft = this._el.nativeElement.offsetLeft;
            const computedStyle = window.getComputedStyle(this._el.nativeElement.parentElement);
            const padding =
                parseInt(computedStyle.getPropertyValue('padding-right')) +
                parseInt(computedStyle.getPropertyValue('padding-left'));

            this._absoluteMoving = containerWidth - padding < elementNextWidth + offsetLeft;
            this._renderer.addClass(
                this._el.nativeElement,
                this._absoluteMoving ? 'tag-color__remove-a' : 'tag-color__remove',
            );
        }
    }
    @HostListener('mouseout') onMouseOut() {
        if (this.hoverColorChange) {
            this._setColors(this._tagColors.primary, this._tagColors.background);
            this._renderer.removeClass(
                this._el.nativeElement,
                this._absoluteMoving ? 'tag-color__remove-a' : 'tag-color__remove',
            );
        }
    }

    @Input('tagColor')
    set updateTagColor(selectedColor: TagColorEnum | InternalTagColorEnum) {
        const colorValues = AllTagColors[selectedColor] || null;
        if (colorValues) {
            this._renderer.addClass(this._el.nativeElement, 'tag-color');
            this._renderer.addClass(this._el.nativeElement, 'text-bold');
            this._tagColors = colorValues;
            this._setColors(colorValues.primary, colorValues.background);
        } else {
            this._setColors('initial', 'initial');
        }
    }

    @Input('hoverColor') hoverColorChange: boolean = false;

    constructor(
        private _el: ElementRef,
        private _renderer: Renderer2,
    ) {}

    private _setColors(textColor: string, backgroundColor: string) {
        this.textColor = textColor;
        this.backgroundColor = backgroundColor;
    }
}
