import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { VisoUser } from '@entities/viso-user';
import { AssessmentRecommendation } from '@entities/recommendation';
import * as moment from 'moment';
import { MatDialogRef } from '@angular/material/dialog';
import { PrimaryVendorContact } from '../../../primary-vendor-contact';
import { FormUtilsService } from '@shared/utils/form-utils.service';

export type RemediationDialogResult = RequestRemediationResult | NavigateBackResult;

export enum RemediationDialogResultAction {
    REQUEST_REMEDIATION,
    BACK,
}

export interface RequestRemediationResult {
    action: RemediationDialogResultAction.REQUEST_REMEDIATION;
    data: {
        riskReviewNote?: string;
        remediationComment?: string;
        targetDate: Date;
        sendRemediationToSubscribers: boolean;
    };
}

interface NavigateBackResult {
    action: RemediationDialogResultAction.BACK;
    data: {
        riskReviewNote?: string;
    };
}

@Component({
    selector: 'app-remediation-dialog',
    templateUrl: './remediation-dialog.component.html',
    styleUrls: ['./remediation-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RemediationDialogComponent implements OnInit {
    @Input({ required: true })
    riskReviewNote: string;

    @Input({ required: true })
    vendorName: string;

    @Input({ required: true })
    recommendations: AssessmentRecommendation[];

    @Input({ required: true })
    primaryContact: PrimaryVendorContact;

    @Input({ required: true })
    currentUser: VisoUser;

    @Input({ required: true })
    businessOwner: VisoUser;

    @Input({ required: true })
    subscribers: VisoUser[];

    today = new Date();

    remediationFormGroup: FormGroup<{
        remediationComment: FormControl<string>;
        targetDate: FormControl<Date>;
        sendRemediationToSubscribers: FormControl<boolean>;
    }>;

    get remediationCommentControl(): FormControl<string> {
        return this.remediationFormGroup.controls.remediationComment;
    }

    get targetDateControl(): FormControl<Date> {
        return this.remediationFormGroup.controls.targetDate;
    }

    get sendRemediationToSubscribersControl(): FormControl<boolean> {
        return this.remediationFormGroup.controls.sendRemediationToSubscribers;
    }

    get commentLength(): number {
        return this.remediationFormGroup.controls.remediationComment.value?.length || 0;
    }

    constructor(
        private _dialogRef: MatDialogRef<RemediationDialogComponent, RemediationDialogResult>,
        private _fb: FormBuilder,
        private _formUtils: FormUtilsService,
    ) {}

    ngOnInit(): void {
        this.remediationFormGroup = this._fb.group({
            remediationComment: this._fb.control(null, Validators.maxLength(250)),
            targetDate: this._fb.control(moment().add(30, 'days').toDate(), Validators.required),
            sendRemediationToSubscribers: this._fb.control(true),
        });
    }

    confirm(): void {
        const { remediationComment, targetDate, sendRemediationToSubscribers } =
            this._formUtils.getCleanTypedFormGroupValue(this.remediationFormGroup);
        this._dialogRef.close({
            action: RemediationDialogResultAction.REQUEST_REMEDIATION,
            data: { remediationComment, targetDate, sendRemediationToSubscribers, riskReviewNote: this.riskReviewNote },
        });
    }

    navigateBack(): void {
        this._dialogRef.close({
            action: RemediationDialogResultAction.BACK,
            data: { riskReviewNote: this.riskReviewNote },
        });
    }
}
