import { Injectable } from '@angular/core';
import { ReviewFrequency, ReviewFrequencyMonths } from '../../../entities/relationship';
import moment from 'moment';

@Injectable()
export class LifecycleManagementHelperService {
    computeBasedOnDate(
        recertificationDate?: Date,
        latestCompletedAssessmentDate?: Date,
        reviewFrequency?: ReviewFrequency,
    ): Date {
        if (!recertificationDate) {
            return latestCompletedAssessmentDate ?? new Date();
        }
        return moment(recertificationDate).subtract(ReviewFrequencyMonths[reviewFrequency], 'months').toDate();
    }
}
