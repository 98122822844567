import { Injectable } from '@angular/core';
import { AssessmentStatus } from '../../entities/assessment';
import { RelationshipAssessmentPhases, VendorRelationship } from '../../routes/relationships/models/relationship.model';
import { Risk, RiskLevelOrder } from '../../entities/risk-assessment';
import { DateUtilsService } from './date-utils.service';
import { DataType } from '../../entities/data-type';
import { RiskTolerance } from '@entities/risk-tolerance';

@Injectable({
    providedIn: 'root',
})
export class RiskUtilsService {
    private _riskThreshold = { no_context: 0.0, low: 0.07, medium: 0.16, high: 0.26 };

    constructor(private _dateUtilsService: DateUtilsService) {}

    getRiskLevel(decimalRiskValue: number): Risk {
        if (decimalRiskValue < 0 || decimalRiskValue > 1) {
            return null;
        }
        if (decimalRiskValue === this._riskThreshold.no_context) {
            return Risk.NO_CONTEXT;
        }
        if (decimalRiskValue <= this._riskThreshold.low) {
            return Risk.LOW;
        }
        if (decimalRiskValue <= this._riskThreshold.medium) {
            return Risk.MEDIUM;
        }
        if (decimalRiskValue <= this._riskThreshold.high) {
            return Risk.HIGH;
        }
        return Risk.EXTREME;
    }

    getRiskLevelString(decimalRiskValue: number): string {
        if (decimalRiskValue < 0 || decimalRiskValue > 1) {
            return null;
        }
        if (decimalRiskValue === this._riskThreshold.no_context) {
            return 'NO_CONTEXT';
        }
        if (decimalRiskValue <= this._riskThreshold.low) {
            return 'LOW';
        }
        if (decimalRiskValue <= this._riskThreshold.medium) {
            return 'MEDIUM';
        }
        if (decimalRiskValue <= this._riskThreshold.high) {
            return 'HIGH';
        }
        return 'EXTREME';
    }

    formatRiskLevelsName(riskLevel: string) {
        if (!riskLevel) return riskLevel;
        return riskLevel
            .match(/[a-z]+/gi)
            .map((word: string) => {
                return word.charAt(0).toUpperCase() + word.substr(1).toLowerCase();
            })
            .join('')
            .replace(/([A-Z+])/g, ' $1')
            .trim();
    }

    getResidualRiskTooltipTextForVendorRelationship(
        relationship: VendorRelationship,
        riskLevelDisplayName: string | null,
    ): string {
        return this.getResidualRiskTooltipText(
            relationship?.latestRiskAssessmentCreatedDate,
            relationship?.latestRiskAssessmentLegacy,
            relationship?.transitional,
            riskLevelDisplayName,
            relationship?.latestNonTransitionalRiskAssessmentLegacy,
            !!relationship?.latestNonTransitionalRiskAssessmentResidualRisk,
            relationship?.assessmentStatus,
            relationship?.createdDate,
        );
    }

    getInherentRiskTooltipTextForImportedRelationship(
        isLatestRiskAssessmentLegacy: boolean,
        relationshipCreatedDate: Date,
    ): string {
        const createdDateFormat = 'yyyy-MM-dd hh:mm a';
        const createdDate = this._dateUtilsService.formatDate(relationshipCreatedDate, createdDateFormat);
        return isLatestRiskAssessmentLegacy
            ? `Risk score imported on ${createdDate} from an assessment outside of VISO platform`
            : null;
    }

    private getResidualRiskTooltipText(
        latestRiskAssessmentCreatedDate: Date,
        latestRiskAssessmentLegacy: boolean,
        latestRiskAssessmentTransitional: boolean,
        riskLevelDisplayName: string | null,
        latestNonTransitionalRiskAssessmentLegacy: boolean,
        latestNonTransitionalRiskAssessmentExists: boolean,
        latestAssessmentStatus: string,
        relationshipCreatedDate: Date,
    ): string {
        if (!riskLevelDisplayName) return '';
        const createdDateFormat = 'yyyy-MM-dd hh:mm a';

        const riskAssessmentCreatedDate = this._dateUtilsService.formatDate(
            latestRiskAssessmentCreatedDate,
            createdDateFormat,
        );

        let residualRiskToolTipText: string;

        if (latestNonTransitionalRiskAssessmentExists) {
            if (latestNonTransitionalRiskAssessmentLegacy && latestRiskAssessmentLegacy) {
                const createdDate = this._dateUtilsService.formatDate(relationshipCreatedDate, createdDateFormat);
                residualRiskToolTipText = `Risk score imported on ${createdDate} from an assessment outside of the VISO platform`;
            } else if (latestRiskAssessmentTransitional && latestAssessmentStatus !== AssessmentStatus.CANCELLED) {
                residualRiskToolTipText = `Latest Risk score as of ${riskAssessmentCreatedDate} is ${riskLevelDisplayName} which is subject to change post completion of assessment`;
            } else {
                residualRiskToolTipText = `Updated on ${riskAssessmentCreatedDate}`;
            }
        } else {
            if (
                latestAssessmentStatus === AssessmentStatus.NOT_ASSESSED ||
                latestAssessmentStatus === RelationshipAssessmentPhases.NOT_ASSESSED ||
                !latestAssessmentStatus
            ) {
                residualRiskToolTipText = 'Residual Risk not available as assessment has not started';
            } else if (latestRiskAssessmentTransitional) {
                residualRiskToolTipText = `Latest Risk score as of ${riskAssessmentCreatedDate} is ${riskLevelDisplayName} which is subject to change post completion of assessment`;
            } else {
                residualRiskToolTipText = `Updated on ${riskAssessmentCreatedDate}`;
            }
        }

        return residualRiskToolTipText;
    }

    sortByRiskLevel(riskLevels: string[]) {
        return riskLevels.sort((a, b) => RiskLevelOrder[a] - RiskLevelOrder[b]);
    }

    sortBySensitivityThenName(a: DataType, b: DataType) {
        if (a.sensitivity === b.sensitivity) {
            // name is only important when sensitivities are the same
            return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
        }
        return b.sensitivity > a.sensitivity ? 1 : -1;
    }

    getRiskToleranceSummary(riskTolerance: RiskTolerance): string {
        switch (riskTolerance) {
            case RiskTolerance.MINIMAL:
                return 'Your organization has a very low tolerance for risk. High assurance artifacts are required to get a vendor relationship to the lowest risk level.';
            case RiskTolerance.MODERATE:
                return 'Your organization balances risk with operational efficiency. While still maintaining strong security controls, there is more flexibility in the level of assurance artifacts required to substantiate them.';
            case RiskTolerance.SIGNIFICANT:
                return 'Your organization is willing to accept a higher level of risk in exchange for greater flexibility and speed in assessing third party relationships. The focus is on core security controls, substantiated by answering questions as a default.';
        }
    }

    getRiskToleranceDescription(riskTolerance: RiskTolerance): string {
        switch (riskTolerance) {
            case RiskTolerance.MINIMAL:
                return 'This option is ideal for organizations that handle sensitive data or operate in highly regulated industries. It ensures that all vendors meet the strictest security and compliance requirements, minimizing potential risks. This setting expects that third parties conduct regular audits and continuous monitoring as part of their rigorous approach to security and compliance. Organizations choosing this option typically have a mature Third-Party Risk Management (TPRM) program with comprehensive policies and procedures in place.';
            case RiskTolerance.MODERATE:
                return 'This option is suitable for organizations that need to be agile but still prioritize security. It allows for a pragmatic approach where risks are managed effectively without overly burdening the third party risk management process. Organizations choosing this option typically perform periodic reviews and risk assessments to ensure ongoing compliance. This approach is well-suited for organizations with a dynamic TPRM program that emphasizes a balance between stringent controls and operational flexibility.';
            case RiskTolerance.SIGNIFICANT:
                return 'This option is appropriate for organizations that operate in less regulated industries or have a higher risk appetite. It enables quicker third party onboarding and integration, supporting fast-paced business environments. While there is an inherent acceptance of greater risk, organizations still perform regular assessments to manage and mitigate potential issues. This approach is generally adopted by organizations that prioritize business agility and rapid growth over strict compliance.';
        }
    }
}
