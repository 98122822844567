import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { CreateUpdateRiskAdvisoryRequest, SummaryResponse } from '@entities/risk-advisory';

export enum VendorRiskAdvisoryActions {
    CreateVendorRiskAdvisoryRequest = '[RiskAdvisoryDialog] Create Vendor Risk Advisory Request',
    CreateVendorRiskAdvisoryRequestSuccess = '[RiskAdvisoryDialog] Create Vendor Risk Advisory Request Success',
    CreateVendorRiskAdvisoryRequestFailed = '[RiskAdvisoryDialog] Create Vendor Risk Advisory Request Failed',
    UpdateVendorRiskAdvisoryRequest = '[RiskAdvisoryDialog] Update Vendor Risk Advisory Request',
    UpdateVendorRiskAdvisoryRequestSuccess = '[RiskAdvisoryDialog] Update Vendor Risk Advisory Request Success',
    UpdateVendorRiskAdvisoryRequestFailed = '[RiskAdvisoryDialog] Update Vendor Risk Advisory Request Failed',
    SummarizeRiskAdvisoryLinkRiskAdvisoryRequest = '[RiskAdvisoryDialog] Summarize Risk Advisory Link Request',
    SummarizeRiskAdvisoryLinkRiskAdvisoryRequestSuccess = '[RiskAdvisoryDialog] Summarize Risk Advisory Link Request Success',
    SummarizeRiskAdvisoryLinkRiskAdvisoryRequestFailed = '[RiskAdvisoryDialog] Summarize Risk Advisory Link Request Failed',
}

export const createVendorRiskAdvisoryRequest = createAction(
    VendorRiskAdvisoryActions.CreateVendorRiskAdvisoryRequest,
    props<{ request: CreateUpdateRiskAdvisoryRequest; name?: string }>(),
);

export const createVendorRiskAdvisoryRequestSuccess = createAction(
    VendorRiskAdvisoryActions.CreateVendorRiskAdvisoryRequestSuccess,
    props<{ name: string }>(),
);

export const createVendorRiskAdvisoryRequestFailed = createAction(
    VendorRiskAdvisoryActions.CreateVendorRiskAdvisoryRequestFailed,
    props<{ error: HttpErrorResponse }>(),
);

export const updateVendorRiskAdvisoryRequest = createAction(
    VendorRiskAdvisoryActions.UpdateVendorRiskAdvisoryRequest,
    props<{ request: CreateUpdateRiskAdvisoryRequest; name?: string }>(),
);

export const updateVendorRiskAdvisoryRequestSuccess = createAction(
    VendorRiskAdvisoryActions.UpdateVendorRiskAdvisoryRequestSuccess,
    props<{ name: string }>(),
);

export const updateVendorRiskAdvisoryRequestFailed = createAction(
    VendorRiskAdvisoryActions.UpdateVendorRiskAdvisoryRequestFailed,
    props<{ error: HttpErrorResponse }>(),
);

export const summarizeRiskAdvisoryLink = createAction(
    VendorRiskAdvisoryActions.SummarizeRiskAdvisoryLinkRiskAdvisoryRequest,
    props<{ url: string }>(),
);

export const summarizeRiskAdvisoryLinkRequestSuccess = createAction(
    VendorRiskAdvisoryActions.SummarizeRiskAdvisoryLinkRiskAdvisoryRequestSuccess,
    props<{ summary: SummaryResponse }>(),
);

export const summarizeRiskAdvisoryLinkRequestFailed = createAction(
    VendorRiskAdvisoryActions.SummarizeRiskAdvisoryLinkRiskAdvisoryRequestFailed,
    props<{ error: HttpErrorResponse }>(),
);
