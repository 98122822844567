import { Component, Input } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { HELP_DOCS_URL } from '@shared/constants/url.constants';

@Component({
    selector: 'app-help-docs',
    templateUrl: './help-docs.component.html',
    styleUrls: ['./help-docs.component.scss'],
})
export class HelpDocsComponent {
    @Input({ required: true })
    drawer: MatDrawer;

    HELP_DOCS_URL = HELP_DOCS_URL;
}
