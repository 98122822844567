import {
    AssessmentRecommendation,
    CertOnlyArtifactAssessmentRecommendation,
    CombinedAssessmentRecommendation,
    ControlDomainCoverageAssessmentRecommendation,
    ControlDomainStatusAssessmentRecommendation,
    ExpiredArtifactAssessmentRecommendation,
    HighAssuranceArtifactAssessmentRecommendation,
    RecommendationType,
} from './recommendation.model';

export function isControlDomainStatusRecommendation(
    recommendation: AssessmentRecommendation,
): recommendation is ControlDomainStatusAssessmentRecommendation {
    return recommendation.type === RecommendationType.CONTROL_DOMAINS;
}

export function isControlDomainCoverageRecommendation(
    recommendation: AssessmentRecommendation,
): recommendation is ControlDomainCoverageAssessmentRecommendation {
    return recommendation.type === RecommendationType.LOW_COVERAGE;
}

export function isExpiredArtifactRecommendation(
    recommendation: AssessmentRecommendation,
): recommendation is ExpiredArtifactAssessmentRecommendation {
    return recommendation.type === RecommendationType.EXPIRED;
}

export function isHighAssuranceArtifactRecommendation(
    recommendation: AssessmentRecommendation,
): recommendation is HighAssuranceArtifactAssessmentRecommendation {
    return recommendation.type === RecommendationType.HIGH_ASSURANCE;
}

export function isCertOnlyArtifactRecommendation(
    recommendation: AssessmentRecommendation,
): recommendation is CertOnlyArtifactAssessmentRecommendation {
    return recommendation.type === RecommendationType.CERT_ONLY;
}

export function iCombinedRecommendation(
    recommendation: AssessmentRecommendation,
): recommendation is CombinedAssessmentRecommendation {
    return recommendation.type === RecommendationType.COMBINED;
}
