import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FileArtifactType, FileArtifactTypeLabels } from '@entities/artifact';
import { AttestationType } from '@entities/attestation';
import { animate, style, transition, trigger } from '@angular/animations';
import { expectedArtifactLabels } from '../../../models';

@Component({
    selector: 'app-artifact-examples',
    templateUrl: './artifact-examples.component.html',
    styleUrls: ['./artifact-examples.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        trigger('fade', [transition('void => *', [style({ opacity: 0 }), animate(200, style({ opacity: 1 }))])]),
    ],
})
export class ArtifactExamplesComponent {
    @Input({ required: true })
    uploadedStatusesOfInScopeFileTypes: Partial<Record<FileArtifactType, boolean>>;

    @Input({ required: true })
    classificationInProgress: boolean;

    AttestationType = AttestationType;
    FileArtifactTypeLabels = FileArtifactTypeLabels;
    FileArtifactType = FileArtifactType;

    artifactExamples: Partial<Record<FileArtifactType, { title: string; examples: ReadonlyArray<string> }>> = {
        AUDIT_REPORT: {
            title: 'Third party audit artifact examples',
            examples: expectedArtifactLabels.AUDIT_REPORT,
        },
        PRIVACY: {
            title: 'Privacy artifact examples',
            examples: expectedArtifactLabels.PRIVACY,
        },
        ASSESSMENT: {
            title: 'Penetration test artifact examples',
            examples: expectedArtifactLabels.ASSESSMENT,
        },
        CYBER_INSURANCE: {
            title: 'Cyber insurance artifact example',
            examples: expectedArtifactLabels.CYBER_INSURANCE,
        },
    };

    getUploadedInScopeFileTypeEntries = (fileTypesUploadedByUser: Partial<Record<FileArtifactType, boolean>>) => {
        return Object.entries(fileTypesUploadedByUser).map(([key, value]) => ({
            fileArtifactType: key,
            hasBeenUploaded: value,
        }));
    };
}
