import { Component, Input } from '@angular/core';
import { Risk } from '../../../entities/risk-assessment';

@Component({
    selector: 'app-risk-chip',
    templateUrl: './risk-chip.component.html',
    styleUrls: ['./risk-chip.component.scss'],
})
export class RiskChipComponent {
    @Input()
    risk: Risk;

    @Input()
    riskIsTransitional: boolean;

    @Input()
    condensed: boolean = false;

    get riskSuffix(): string {
        return this.riskIsTransitional ? ' *' : '';
    }

    get riskClasses(): object {
        const riskLevel = this.risk.toLowerCase().replace('_', '-');
        return {
            [`${riskLevel}-transitional-risk`]: this.riskIsTransitional,
            [`${riskLevel}-risk`]: !this.riskIsTransitional,
            'fst-italic': this.riskIsTransitional,
            condensed: this.condensed,
        };
    }
}
