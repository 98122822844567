export interface DateRangeOptionItem {
    label: PredeterminedDatesRanges;
    value: MetabasePredeterminedDatesRanges | null;
}

export enum PredeterminedDatesRanges {
    AllTime = 'All Time',
    MonthToDate = 'This Month to Date',
    LastThirtyDays = 'Last 30 days',
    LastMonth = 'Last Month',
    QuarterToDate = 'This Quarter to Date',
    LastNinetyDays = 'Last 90 days',
    LastQuarter = 'Last Quarter',
    YearToDate = 'This Year to Date',
    Last365Days = 'Last 365 days',
    LastYear = 'Last Year',
}

export enum MetabasePredeterminedDatesRanges {
    AllTime = 'alltime',
    MonthToDate = 'thismonth',
    LastThirtyDays = 'past30days',
    LastMonth = 'past1months',
    QuarterToDate = 'thisquarter',
    LastNinetyDays = 'past90days',
    LastQuarter = 'past1quarters',
    YearToDate = 'thisyear',
    Last365Days = 'past365days',
    LastYear = 'past1years',
}

export const dateRanges: DateRangeOptionItem[] = [
    { label: PredeterminedDatesRanges.AllTime, value: null },
    { label: PredeterminedDatesRanges.MonthToDate, value: MetabasePredeterminedDatesRanges.MonthToDate },
    { label: PredeterminedDatesRanges.LastThirtyDays, value: MetabasePredeterminedDatesRanges.LastThirtyDays },
    { label: PredeterminedDatesRanges.LastMonth, value: MetabasePredeterminedDatesRanges.LastMonth },
    { label: PredeterminedDatesRanges.QuarterToDate, value: MetabasePredeterminedDatesRanges.QuarterToDate },
    { label: PredeterminedDatesRanges.LastNinetyDays, value: MetabasePredeterminedDatesRanges.LastNinetyDays },
    { label: PredeterminedDatesRanges.LastQuarter, value: MetabasePredeterminedDatesRanges.LastQuarter },
    { label: PredeterminedDatesRanges.YearToDate, value: MetabasePredeterminedDatesRanges.YearToDate },
    { label: PredeterminedDatesRanges.Last365Days, value: MetabasePredeterminedDatesRanges.Last365Days },
    { label: PredeterminedDatesRanges.LastYear, value: MetabasePredeterminedDatesRanges.LastYear },
];
