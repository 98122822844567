import { Component, OnInit } from '@angular/core';

import { Log } from './log.model';
import { LogsService } from './logs.service';

@Component({
    selector: 'app-logs',
    templateUrl: './logs.component.html',
})
export class LogsComponent implements OnInit {
    loggers: Log[];
    filter: string;
    orderProp: string;
    orderOrientation: 'asc' | 'desc';

    constructor(private logsService: LogsService) {
        this.filter = '';
        this.orderProp = 'name';
        this.orderOrientation = 'asc';
    }

    ngOnInit() {
        this.logsService.findAll().subscribe((response) => (this.loggers = response.body));
    }

    changeLevel(name: string, level: string) {
        const log = new Log(name, level);
        this.logsService.changeLevel(log).subscribe(() => {
            this.logsService.findAll().subscribe((response) => (this.loggers = response.body));
        });
    }
}
