<div class="modal-header flex-column">
    <div class="d-flex w-100 justify-content-between mb-2">
        <h4 class="modal-title">Third Party Contact</h4>
        <button type="button" class="btn-close" data-dismiss="modal" aria-hidden="true" (click)="close()"></button>
    </div>
    <p class="mb-0 w-100">The third party contact is usually the representative or account manager.</p>
</div>
<div class="modal-body">
    <app-third-party-contact-form
        formId="thirdPartyContactForm"
        [includeRemoveOption]="true"
        [primaryVendorContact]="primaryVendorContact"
        [requestId]="requestId"
        (removeContact)="removeContact()"
        (submitForm)="save(primaryVendorContactUpdated)"
        (formStatusChange)="formStatus = $event"
        (formValueChange)="primaryVendorContactUpdated = $event"
    ></app-third-party-contact-form>
</div>
<div class="modal-footer">
    <button
        toggleDisableOnActions
        mat-button
        visoButton="secondary"
        class="me-2"
        type="button"
        (click)="close()"
        [disablingActions]="[RequestActions.AddPrimaryVendorContactRequest]"
        [enablingActions]="[
            RequestActions.AddPrimaryVendorContactRequestSuccess,
            RequestActions.AddPrimaryVendorContactRequestFailed,
        ]"
    >
        Cancel
    </button>
    <button
        toggleDisableOnActions
        mat-button
        visoButton="primary"
        type="submit"
        form="thirdPartyContactForm"
        [disabled]="formStatus === 'INVALID'"
        [disablingActions]="[RequestActions.AddPrimaryVendorContactRequest]"
        [enablingActions]="[
            RequestActions.AddPrimaryVendorContactRequestSuccess,
            RequestActions.AddPrimaryVendorContactRequestFailed,
        ]"
    >
        Save
    </button>
</div>
