import { BreadcrumbItem } from '../../../shared/model/breadcrumb-item';

export const assessmentIdUrlParamName = 'assessmentId';
export const trustProfileIdUrlParamName = 'trustProfileId';

export const assessmentDetailsRoute = `assessments`;

export const uploadArtifactForAssessmentDetailsRoute = `${assessmentDetailsRoute}/:${assessmentIdUrlParamName}/add-artifact`;

export const vendorAssessmentBreadcrumb: BreadcrumbItem[] = [
    {
        active: false,
        label: 'Home',
        link: '/',
    },
    {
        active: false,
        label: 'Relationships',
        link: '/relationships',
    },
    {
        active: false,
        label: ':relationshipName',
        link: '/requests/:relationshipId',
    },
    {
        active: true,
        label: 'Assessment',
    },
];
