import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
    name: 'safeHtml',
})
export class SafeHtmlPipe implements PipeTransform {
    constructor(private sanitizer: DomSanitizer) {}

    transform(value: string, type: 'html' | 'resourceUrl' = 'html') {
        switch (type) {
            case 'resourceUrl':
                return this.sanitizer.bypassSecurityTrustResourceUrl(value);
            case 'html':
            default:
                return this.sanitizer.bypassSecurityTrustHtml(value);
        }
    }
}
