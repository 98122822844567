@if (uploadedRtpFileArtifacts$ | async; as uploadedRtpFileArtifacts) {
    @for (replacement of expiredArtifactReplacements; track replacement.expiredFileArtifact.fileName) {
        <div class="row mb-4">
            <div class="col">
                @if (!!replacement.replacedByRtpFileArtifact) {
                    <app-file-artifact-item
                        class="mb-2"
                        [fileArtifactItem]="replacement.replacedByRtpFileArtifact"
                        [auditTypeToReplace]="getArtifactAuditReportType(replacement.expiredFileArtifact)"
                        (setPassword)="emitSetPassword($event, replacement.replacedByRtpFileArtifact)"
                        (remove)="emitArtifactRemoved(replacement.replacedByRtpFileArtifact)"
                    />
                } @else {
                    <app-replace-file-artifact-item
                        [artifactToReplace]="replacement.expiredFileArtifact"
                        (uploadReplacement)="uploadAndReplace.emit($event)"
                        (onWhenAddingFileFailed)="uploadAndReplaceFailed.emit($event)"
                    />
                }
            </div>
        </div>
    }

    @for (rtpFileArtifact of uploadedRtpFileArtifacts; track rtpFileArtifact.artifact.fileName) {
        <div class="row mb-4">
            <div class="col">
                <app-file-artifact-item
                    class="mb-2"
                    [fileArtifactItem]="rtpFileArtifact"
                    (setPassword)="emitSetPassword($event, rtpFileArtifact)"
                    (remove)="emitArtifactRemoved(rtpFileArtifact)"
                />
            </div>
        </div>
    }

    @if (!uploadedRtpFileArtifacts?.length && !expiredArtifactReplacements?.length) {
        <div class="row">
            <div class="col">
                <div class="display-2 text-secondary">
                    <mat-icon [inline]="true">upload_file</mat-icon>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <p>
                    Artifacts you upload will appear here.<br />
                    Drag and drop anywhere on this page or
                    <a
                        class="text-secondary text-decoration-underline"
                        href="javascript:void(0)"
                        (click)="uploadClicked.emit()"
                        >select files</a
                    >.
                </p>
            </div>
        </div>
    }
}
