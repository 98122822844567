<div mat-dialog-title>
    Review risk
    <button mat-icon-button mat-dialog-close>
        <mat-icon>close</mat-icon>
    </button>
</div>
<mat-dialog-content>
    <div class="row mb-2" *ngIf="showRecommendations">
        <div class="col">
            <p>
                You’re in control of next steps for this relationship. Please review the risk values based on the latest
                assessment.
                @if (isRemediationActive) {
                    To enable risk acceptance, cancel the remediation request first.
                } @else {
                    If you seek remediation, VISO TRUST will automatically follow up at a date of your choosing.
                }
            </p>
        </div>
    </div>
    <div class="row mb-4">
        <div class="col">
            <h4>Risk values</h4>
            <app-risk-override
                class="mt-2"
                [riskOverrideControl]="riskReviewFormGroup.controls.riskOverride"
                [updatedByFullName]="riskOverrideLastAppliedByFullName"
                [lastUpdated]="riskOverrideLastAppliedDate"
                [inherentRisk]="inherentRisk"
                [residualRisk]="residualRisk"
                [allowChanges]="awaitingRiskReview"
            />
        </div>
    </div>
    <div class="row mb-2" *ngIf="showRecommendations">
        <div class="col">
            <h4 class="mb-1">
                <strong> Recommendations </strong>
            </h4>
            @if (isRemediationDeclined) {
                <p class="text-danger-dark">
                    <strong>
                        On {{ remediationRequest.declinedDate | date }}, {{ remediationRequest.declinedByFirstName }}
                        {{ remediationRequest.declinedByLastName }} ({{ remediationRequest.declinedByEmail }}) declined
                        to remediate the following gaps in coverage:
                    </strong>
                </p>
            } @else {
                <p class="mb-2">
                    {{ recommendationsText }}
                </p>
                @if (areThereRecommendations) {
                    <app-recommendations-list [recommendations]="recommendations" [showHeading]="false" />
                }
            }
        </div>
    </div>
    <div class="row mb-2">
        <div class="col">
            <p class="mb-2">
                <strong>The note from this risk review will post as a comment to the relationship history.</strong>
            </p>
            <mat-form-field class="w-100">
                <mat-label>Add review note (optional)</mat-label>
                <textarea
                    matInput
                    id="comment"
                    name="comment"
                    rows="3"
                    [formControl]="riskReviewFormGroup.controls.riskReviewNote"
                ></textarea>
            </mat-form-field>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions class="justify-content-between">
    <div>
        <button
            *ngIf="showRevokeRiskAcceptanceButton"
            mat-button
            visoButton="secondary"
            color="warn"
            (click)="revokeRiskAcceptance()"
        >
            <mat-icon>undo</mat-icon>
            Revoke risk acceptance
        </button>
        <button
            *ngIf="showCancelRemediationButton"
            mat-button
            visoButton="secondary"
            color="warn"
            (click)="cancelRemediationRequest()"
        >
            <mat-icon>close</mat-icon>
            Cancel remediation request
        </button>
    </div>
    <div>
        <ng-container *ngIf="showRequestRemediationButton">
            <div
                class="d-inline-flex"
                matTooltip="You cannot send a request to remediate until you add a third party contact to this relationship."
                [matTooltipDisabled]="!disableRequestRemediationButton"
                matTooltipPosition="above"
            >
                <button
                    class="me-3"
                    type="button"
                    mat-button
                    visoButton="secondary"
                    (click)="requestRemediation()"
                    [disabled]="disableRequestRemediationButton"
                    *disableOnTrial="
                        true;
                        popoverTemplate: upgradePopover;
                        lockIconColor: 'muted';
                        popoverPlacement: 'top'
                    "
                >
                    Request remediation
                    <mat-icon iconPositionEnd>chevron_right</mat-icon>
                </button>
            </div>
        </ng-container>
        <button *ngIf="awaitingRiskReview" type="button" mat-button visoButton="primary" (click)="acceptRisk()">
            <mat-icon>check</mat-icon>
            Accept risk
        </button>
    </div>
</mat-dialog-actions>
<ng-template #upgradePopover>
    <div class="header">
        <strong>Remediation is a premium feature.</strong>
        <mat-icon [inline]="true">handyman</mat-icon>
    </div>
    <div class="body">
        Remediation allows you to request that a Third Party take corrective action based on the results of a VISO TRUST
        risk assessment. Custom timelines, automated notifications, and a fully auditable trail of vendor responses
        eliminates runaround for mitigating risk with your third parties.
    </div>
</ng-template>
