<div class="custom-select__wrapper">
    <div class="custom-select__selection form-control" [ngClass]="{ 'custom-select__disabled': disabled }">
        <ng-container *ngFor="let option of selectedOptions$ | async">
            <button
                class="custom-select__selected"
                mat-button
                visoButton="link"
                [disabled]="disabled"
                [tagColor]="tagColor"
                [hoverColor]="true"
                (click)="unselect($event, option)"
            >
                {{ displayAttribute ? option[displayAttribute] : option }}
            </button>
        </ng-container>
        <div
            *ngIf="!disabled"
            class="custom-select__input-wrapper"
            dropdown
            #dropdown="bs-dropdown"
            [insideClick]="true"
            [style.flex-basis.px]="searchMinWidth"
        >
            <input
                type="text"
                placeholder="Search"
                id="search-custom-select-input"
                class="form-control custom-select__search-input"
                [formControl]="searchControl"
                (keydown)="checkKeys($event)"
                dropdownToggle
                (click)="dropdown.show()"
                (focus)="dropdown.show()"
                (blur)="searchControl.reset()"
            />
            <div
                class="dropdown-menu custom-select__results"
                *dropdownMenu
                aria-labelledby="button-container"
                id="dropdown-triggers-manual"
                role="menu"
                [ngClass]="{
                    'custom-select__hide_dropdown': (filteredOptions$ | async)?.length === 0 && !searchControl.value,
                }"
            >
                <ng-container *ngIf="filteredOptions$ | async as availableOptions">
                    <ng-container *ngIf="availableOptions.length; else noOptionsRef">
                        <button
                            *ngFor="let option of availableOptions"
                            class="custom-select__option btn"
                            (click)="selectValue($event, option)"
                        >
                            <span>{{ displayAttribute ? option[displayAttribute] : option }}</span>
                        </button>
                    </ng-container>
                </ng-container>
            </div>
        </div>
    </div>

    <ng-container *ngIf="additionalActionsContent">
        <div class="divider dropdown-divider"></div>
        <ng-container [ngTemplateOutlet]="additionalActionsContent.templateRef"></ng-container>
    </ng-container>
</div>

<ng-template #noOptionsRef>
    <button
        class="custom-select__option custom-select__create-option btn btn-link"
        *ngIf="searchControl.value"
        [disabled]="searchControl.invalid || disabled || selectedOptions$.value.length >= maxLimit"
        (mousedown)="$event.preventDefault()"
        (click)="create(searchControl.value, $event)"
    >
        <span>Add "{{ searchControl.value }}"</span>
    </button>
</ng-template>
