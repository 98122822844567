<form [formGroup]="tagForm" (ngSubmit)="save()">
    <div class="modal-header">
        <h4 class="modal-title" id="myContactLabel">Tag Details</h4>
        <button type="button" class="btn-close" data-dismiss="modal" aria-hidden="true" (click)="clear()"></button>
    </div>

    <div class="modal-body p-4">
        <div class="form-group">
            <span *ngIf="selectedTag">
                This tag will be updated on {{ selectedTag.numberOfUses || 0 }} relationship{{
                    selectedTag.numberOfUses !== 1 ? 's' : ''
                }}.
            </span>
            <span *ngIf="!selectedTag">Create a new tag.</span>
        </div>
        <div class="form-group required">
            <label for="field_tag_name">Tag Name</label>
            <input
                type="text"
                class="form-control"
                name="tag_name"
                id="field_tag_name"
                formControlName="name"
                placeholder="Enter tag name"
                required
            />
        </div>
        <div class="form-group">
            <label for="color-picker">Color</label>
            <app-color-picker id="color-picker" formControlName="color"></app-color-picker>
        </div>
    </div>

    <div class="modal-footer d-flex justify-content-end main-action">
        <button
            type="button"
            mat-button
            visoButton="secondary"
            class="float-end"
            data-dismiss="modal"
            (click)="clear()"
        >
            <span>Cancel</span>
        </button>
        <button
            type="submit"
            [disabled]="tagForm.invalid"
            mat-button
            visoButton="primary"
            class="float-end"
            toggleDisableOnActions
            [disablingActions]="[TagActionsRef.CreateTagRequest, TagActionsRef.UpdateTagRequest]"
            [enablingActions]="[
                TagActionsRef.CreateTagRequestSuccess,
                TagActionsRef.CreateTagRequestFailed,
                TagActionsRef.UpdateTagRequestSuccess,
                TagActionsRef.UpdateTagRequestFailed,
            ]"
        >
            <span> {{ !selectedTag ? 'Add Tag' : 'Edit Tag' }}</span>
        </button>
    </div>
</form>
