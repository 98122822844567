import { Directive, HostBinding, ElementRef, Input } from '@angular/core';

@Directive({
    selector: 'app-card',
})
export class CardDirective {
    @Input() fullScreen = false;
    @HostBinding('class.app-card') isHostClass = true;
    @HostBinding('class.app-card--full-screen')
    get classFullScreen(): boolean {
        return this.fullScreen;
    }

    constructor(public elementRef: ElementRef) {}
}

@Directive({
    selector: 'app-card-toolbar',
})
export class CardToolbarDirective {
    @HostBinding('class.app-card-toolbar') isHostClass = true;

    constructor(public elementRef: ElementRef) {}
}

@Directive({
    selector: '[app-card-toolbar-item]',
})
export class CardToolbarItemDirective {
    @HostBinding('class.app-card-toolbar__item') isHostClass = true;

    constructor(public elementRef: ElementRef) {}
}

@Directive({
    selector: 'app-card-toolbar-section',
})
export class CardToolbarSectionDirective {
    @Input() alignEnd = false;
    @HostBinding('class.app-card-toolbar__section') isHostClass = true;
    @HostBinding('class.app-card-toolbar__section--align-end')
    get classAlignStart(): boolean {
        return this.alignEnd;
    }

    constructor(public elementRef: ElementRef) {}
}

@Directive({
    selector: 'app-card-content',
})
export class CardContentDirective {
    @Input() relative = false;
    @HostBinding('class.app-card-content') isHostClass = true;
    @HostBinding('class.app-card-content--relative')
    get classRelative(): boolean {
        return this.relative;
    }

    constructor(public elementRef: ElementRef) {}
}
