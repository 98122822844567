import { CommonModule } from '@angular/common';
import {
    DisableAddRelationshipOnRulesDirective,
    DisableAddRelationshipWrapperComponent,
} from './directives/disable-add-relationship-on-rules.directive';
import { NgModule } from '@angular/core';
import { PopoverModule } from 'ngx-bootstrap/popover';

@NgModule({
    declarations: [DisableAddRelationshipOnRulesDirective, DisableAddRelationshipWrapperComponent],
    imports: [CommonModule, PopoverModule],
    exports: [DisableAddRelationshipOnRulesDirective],
})
export class DisableAddRelationshipOnRulesModule {}
