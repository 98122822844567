import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { BusinessUnit, BusinessUnitManagementView } from './business-unit.model';
import { createRequestOption, PaginationParams } from '../../shared';

export type EntityResponseType = HttpResponse<BusinessUnit>;

@Injectable({
    providedIn: 'root',
})
export class BusinessUnitService {
    private resourceUrl = 'api/business-units';

    constructor(private http: HttpClient) {}

    create(businessUnit: BusinessUnit): Observable<EntityResponseType> {
        const copy: BusinessUnit = Object.assign({}, businessUnit);
        return this.http.post<BusinessUnit>(this.resourceUrl, copy, { observe: 'response' });
    }

    update(businessUnit: BusinessUnit): Observable<EntityResponseType> {
        const copy: BusinessUnit = Object.assign({}, businessUnit);
        return this.http.put<BusinessUnit>(this.resourceUrl, copy, { observe: 'response' });
    }

    find(id: number): Observable<EntityResponseType> {
        return this.http.get<BusinessUnit>(`${this.resourceUrl}/${id}`, { observe: 'response' });
    }

    findAllForCurrentUserOrg(req?: PaginationParams, clientId?: number): Observable<HttpResponse<BusinessUnit[]>> {
        let options = createRequestOption(req);
        if (clientId !== undefined) {
            options = options.set('clientId', clientId);
        }
        return this.http.get<BusinessUnit[]>(this.resourceUrl, { params: options, observe: 'response' });
    }

    findAllForManagement(req?: any): Observable<HttpResponse<BusinessUnitManagementView[]>> {
        const options = createRequestOption(req);
        return this.http.get<BusinessUnitManagementView[]>(`${this.resourceUrl}/management`, {
            params: options,
            observe: 'response',
        });
    }

    findForManagement(id: number): Observable<BusinessUnitManagementView> {
        return this.http.get<BusinessUnitManagementView>(`${this.resourceUrl}/management/${id}`);
    }

    query(req?: any): Observable<HttpResponse<BusinessUnit[]>> {
        const options = createRequestOption(req);
        return this.http.get<BusinessUnit[]>(this.resourceUrl, { params: options, observe: 'response' });
    }

    delete(id: number): Observable<HttpResponse<any>> {
        return this.http.delete<any>(`${this.resourceUrl}/${id}`, { observe: 'response' });
    }

    reassign(fromBusinessUnitId: number, toBusinessUnitId: number): Observable<HttpResponse<any>> {
        return this.http.put<any>(`${this.resourceUrl}/reassign/${fromBusinessUnitId}/${toBusinessUnitId}`, {
            observe: 'response',
        });
    }
}
