<app-full-page-file-drop (onFileDropped)="uploadArtifacts.emit($event)" />

<div class="container-top-half pb-0">
    <div class="row flex-nowrap">
        <div class="col-4 fixed-height">
            <div class="row">
                <div class="col">
                    <h1 class="assessment-collection-step-title">
                        {{ title }}
                    </h1>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col">
                    {{ description }}
                </div>
            </div>
            @if (showExpiredArtifactsDescription) {
                <div class="row mb-3">
                    <div class="col">
                        Additionally, please provide any replacements for artifacts that have expired since your
                        previous assessment.
                    </div>
                </div>
            }
            @if (showArtifactExamples) {
                <div class="row mb-3">
                    <div class="col">
                        <app-artifact-examples
                            [uploadedStatusesOfInScopeFileTypes]="uploadedStatusesOfInScopeFileTypes"
                            [classificationInProgress]="classificationInProgress"
                        />
                    </div>
                </div>
            }
            @if (showPreviousAssessmentRecommendations) {
                <div class="row mb-3 pt-3">
                    <div class="col">
                        <app-recommendations-list
                            [recommendations]="previousAssessmentRecommendations"
                            [vendorName]="vendorName"
                        />
                    </div>
                </div>
            }
        </div>

        <div class="col-auto ps-0 pb-4">
            <mat-divider class="h-100" [vertical]="true"></mat-divider>
        </div>

        <input
            #fileInput
            class="d-none"
            type="file"
            data-pw="fileInput"
            ng2FileSelect
            [uploader]="uploader"
            (onFileSelected)="uploadArtifacts.emit($event)"
            multiple
        />

        <div class="col">
            <div class="row">
                <div class="col position-relative">
                    <div class="d-flex justify-content-between position-relative pb-3">
                        <strong>Your artifacts</strong>
                        @if (artifactListPopulated) {
                            <div>
                                Drag and drop anywhere on this page or
                                <a
                                    class="text-secondary text-decoration-underline"
                                    href="javascript:void(0)"
                                    (click)="fileInput.click()"
                                    >select files</a
                                >.
                            </div>
                        }
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col fixed-height">
                    <app-artifact-list
                        class="h-100"
                        [rtpFileArtifacts]="uploadedArtifacts"
                        [expiredArtifactReplacements]="expiredArtifactReplacements"
                        (uploadClicked)="fileInput.click()"
                        (uploadAndReplace)="uploadAndReplace.emit($event)"
                        (setPassword)="setPassword.emit($event)"
                        (removeArtifact)="removeArtifact.emit($event)"
                        (uploadAndReplaceFailed)="uploadFailed.emit($event)"
                    />
                </div>
            </div>
        </div>
    </div>
</div>
<div class="d-flex justify-content-end container-bottom-half">
    <button mat-stroked-button color="secondary" class="me-auto" (click)="wentBack.emit()">Go back</button>
    <button
        mat-flat-button
        color="secondary"
        [disabled]="disableContinueButton"
        (click)="continued.emit()"
        data-pw="continueButton"
    >
        Continue
    </button>
</div>
