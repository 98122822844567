<div class="checkbox-group-container" [ngClass]="{ 'row-direction': direction === 'row', outline: true }">
    <ng-container *ngFor="let item of groupItemData; let i = index">
        <mat-checkbox
            #checkboxGroupItem
            color="primary"
            class="outline-checkbox"
            [ngClass]="{
                'col-direction': direction === 'column',
                'row-direction': direction === 'row',
                'right-checkbox': checkboxPosition === 'right',
            }"
            [labelPosition]="checkboxPosition === 'right' ? 'before' : 'after'"
            [id]="checkboxGroupName + i"
            [value]="item.value"
            (click)="check(item.value, checkboxGroupItem)"
            [checked]="!item.disabled && item | callback: checkStatus : checkedValues"
            [disabled]="item.disabled"
        >
            <ng-container
                *ngTemplateOutlet="item.templateRef; context: { item: item, forItem: checkboxGroupName + i }"
            ></ng-container>
        </mat-checkbox>
    </ng-container>
</div>
