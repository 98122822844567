import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SUPPORT_EMAIL } from '@shared/constants/email.constants';
import { SHARE_FEEDBACK_URL } from '@shared/constants/url.constants';

@Component({
    selector: 'app-help-menu',
    templateUrl: './help-menu.component.html',
    styleUrl: './help-menu.component.scss',
})
export class HelpMenuComponent {
    @Input({ required: true })
    pageSupportsSpotlightTour: boolean;

    @Output()
    helpCenterOpened = new EventEmitter<void>();

    @Output()
    helpCenterClosed = new EventEmitter<void>();

    @Output()
    activateSpotlightTour = new EventEmitter<void>();

    SUPPORT_EMAIL = SUPPORT_EMAIL;
    SHARE_FEEDBACK_URL = SHARE_FEEDBACK_URL;

    get spotlightTooltipMessage(): string {
        return !this.pageSupportsSpotlightTour
            ? "We apologize, but a walkthrough for this page isn't available at the moment! Please contact support if you need assistance."
            : '';
    }
}
