import { Directive, HostListener } from '@angular/core';

@Directive({
    selector: '[pasteAsPlainText]',
})
export class PasteAsPlainTextDirective {
    constructor() {}

    @HostListener('paste', ['$event'])
    onPaste(event: ClipboardEvent) {
        event.preventDefault();
        const text = event.clipboardData?.getData('text/plain');
        if (text) {
            const selection = window.getSelection();
            if (selection && selection.rangeCount > 0) {
                const range = selection.getRangeAt(0);
                range.deleteContents();
                const textNode = document.createTextNode(text);
                range.insertNode(textNode);
                range.collapse(false); // Move cursor to the end of the inserted text
            }
        }
    }
}
