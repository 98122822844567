import { createAction, props } from '@ngrx/store';
import { DomainVerification, UpdatePreferredEmailDomainRequest } from '../../../../entities/domain-verification';

export enum DomainVerificationActions {
    GetDomainVerificationsRequest = 'Get Domain Verifications Request',
    GetDomainVerificationsRequestSuccess = 'Get Domain Verifications Request Success',
    GetDomainVerificationsRequestFailure = 'Get Domain Verifications Request Failure',

    GetDomainVerificationRequest = 'Get Domain Verification Request',
    GetDomainVerificationRequestSuccess = 'Get Domain Verification Request Success',
    GetDomainVerificationRequestFailure = 'Get Domain Verification Request Failure',

    OpenDkimConfigurationModal = 'Open DKIM Configuration Modal',
    OpenCustomizeEmailModal = 'Open Customize Email Modal',

    GetPreferredEmailDomainRequest = 'Get Preferred Email Domain Verification Request',
    GetPreferredEmailDomainRequestSuccess = 'Get Preferred Email Domain Verification Request Success',
    GetPreferredEmailDomainRequestFailure = 'Get Preferred Email Domain Verification Request Failure',

    SetPreferredEmailDomainRequest = 'Set Preferred Email Domain Verification Request',
    SetPreferredEmailDomainRequestSuccess = 'Set Preferred Email Domain Verification Request Success',
    SetPreferredEmailDomainRequestFailure = 'Set Preferred Email Domain Verification Request Failure',

    OpenConfirmDeletePreferredEmailDomainModal = 'Open Confirm Delete Preferred Email Domain Modal',

    DeletePreferredEmailDomainRequest = 'Delete Preferred Email Domain Verification Request',
    DeletePreferredEmailDomainRequestSuccess = 'Delete Preferred Email Domain Verification Request Success',
    DeletePreferredEmailDomainRequestFailure = 'Delete Preferred Email Domain Verification Request Failure',
}

export const getDomainVerificationsRequest = createAction(DomainVerificationActions.GetDomainVerificationsRequest);

export const getDomainVerificationsRequestSuccess = createAction(
    DomainVerificationActions.GetDomainVerificationsRequestSuccess,
    props<{ verifications: DomainVerification[] }>(),
);

export const getDomainVerificationsRequestFailure = createAction(
    DomainVerificationActions.GetDomainVerificationsRequestFailure,
);

export const getPreferredEmailDomainRequest = createAction(DomainVerificationActions.GetPreferredEmailDomainRequest);

export const setPreferredEmailDomainRequest = createAction(
    DomainVerificationActions.SetPreferredEmailDomainRequest,
    props<{ config: UpdatePreferredEmailDomainRequest }>(),
);

export const getPreferredEmailDomainRequestSuccess = createAction(
    DomainVerificationActions.GetPreferredEmailDomainRequestSuccess,
    props<{ verification: DomainVerification }>(),
);

export const getPreferredEmailDomainRequestFailure = createAction(
    DomainVerificationActions.GetPreferredEmailDomainRequestFailure,
);

export const setPreferredEmailDomainRequestSuccess = createAction(
    DomainVerificationActions.SetPreferredEmailDomainRequestSuccess,
);

export const setPreferredEmailDomainRequestFailure = createAction(
    DomainVerificationActions.SetPreferredEmailDomainRequestFailure,
);

export const getDomainVerificationRequest = createAction(
    DomainVerificationActions.GetDomainVerificationRequest,
    props<{ domain: string }>(),
);

export const getDomainVerificationRequestSuccess = createAction(
    DomainVerificationActions.GetDomainVerificationRequestSuccess,
    props<{ verification: DomainVerification }>(),
);

export const getDomainVerificationRequestFailure = createAction(
    DomainVerificationActions.GetDomainVerificationRequestFailure,
);

export const openDkimConfigurationModal = createAction(
    DomainVerificationActions.OpenDkimConfigurationModal,
    props<{ domain: string }>(),
);

export const openCustomizeEmailModal = createAction(DomainVerificationActions.OpenCustomizeEmailModal);

export const openConfirmDeletePreferredEmailDomainModal = createAction(
    DomainVerificationActions.OpenConfirmDeletePreferredEmailDomainModal,
);

export const deletePreferredEmailDomainRequest = createAction(
    DomainVerificationActions.DeletePreferredEmailDomainRequest,
);

export const deletePreferredEmailDomainRequestSuccess = createAction(
    DomainVerificationActions.DeletePreferredEmailDomainRequestSuccess,
);

export const deletePreferredEmailDomainRequestFailure = createAction(
    DomainVerificationActions.DeletePreferredEmailDomainRequestFailure,
);
