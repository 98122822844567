import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { AnnouncementService } from '../../../entities/announcement/announcement.service';
import { NgbModalWrapperService } from '../../../shared';
import { AnnouncementModalComponent } from '../announcement-modal/announcement-modal.component';
import { getServerSessionCompleted } from '../../session/redux/session.actions';
import {
    getMostRecentAnnouncementRequest,
    getMostRecentAnnouncementRequestFailed,
    getMostRecentAnnouncementRequestSuccess,
} from './announcement.actions';

@Injectable()
export class AnnouncementEffects {
    triggerGetMostRecentAnnouncementRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(getServerSessionCompleted),
            filter(({ error }) => !error),
            map(() => getMostRecentAnnouncementRequest()),
        ),
    );

    getMostRecentAnnouncementRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(getMostRecentAnnouncementRequest),
            switchMap(() =>
                this._announcementService.getMostRecentAnnouncement().pipe(
                    map((announcement) => getMostRecentAnnouncementRequestSuccess({ announcement })),
                    catchError((error) => of(getMostRecentAnnouncementRequestFailed({ error }))),
                ),
            ),
        ),
    );

    getMostRecentAnnouncementRequestSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(getMostRecentAnnouncementRequestSuccess),
                mergeMap(({ announcement: lastAnnouncement }) => {
                    const lastDismissedAnnouncementId = localStorage.getItem('lastDismissedAnnouncementId');

                    if (!!lastAnnouncement && lastAnnouncement?.id !== lastDismissedAnnouncementId) {
                        return this._modalService
                            .open<AnnouncementModalComponent>(
                                AnnouncementModalComponent,
                                { announcement: lastAnnouncement },
                                { size: 'md', backdrop: 'static' },
                            )
                            .closed.pipe(
                                tap(() => localStorage.setItem('lastDismissedAnnouncementId', lastAnnouncement.id)),
                            );
                    }

                    return of();
                }),
            ),
        { dispatch: false },
    );

    constructor(
        private _actions$: Actions,
        private _modalService: NgbModalWrapperService,
        private _announcementService: AnnouncementService,
    ) {}
}
