<div class="content-heading">
    <div>
        Elasticsearch Index Management
        <small>Admin controls for reindexing</small>
    </div>
</div>
<div class="row">
    <div class="col-md-6">
        <div class="card card-default">
            <div class="card-header">
                <p class="card-title">Reindexing controls</p>
            </div>
            <div class="card-body">
                <form [formGroup]="reindexESIndexFormGroup" (submit)="reindex()">
                    <div class="form-group required">
                        <label>Index</label>
                        <ngx-select
                            [allowClear]="false"
                            [items]="esIndexes$ | async"
                            formControlName="esIndex"
                            placeholder="Select an index"
                            optionTextField="name"
                            optionValueField="ref"
                        ></ngx-select>
                        <span class="text-warning" *ngIf="showIndexDeletionRecommendation"
                            >It's recommended to delete and re-create this index.</span
                        >
                    </div>
                    <div class="form-group">
                        <mat-slide-toggle
                            formControlName="deleteAndRecreateIndex"
                            color="primary"
                            labelPosition="before"
                            >Delete and re-create index?</mat-slide-toggle
                        >
                    </div>
                    <div class="form-group text-end">
                        <button type="button" mat-button visoButton="secondary" class="me-2" (click)="clearForm()">
                            Cancel
                        </button>
                        <button mat-button visoButton="primary" [disabled]="reindexESIndexFormGroup.invalid">
                            Submit
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
