import { formatDate } from '@angular/common';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { MetabasePredeterminedDatesRanges } from '../../routes/metabase-dashboard/models';

@Injectable({
    providedIn: 'root',
})
export class DateUtilsService {
    isAValidDate(dateString: string, format: string = 'YYYY-MM-DD'): boolean {
        return moment(dateString, format, true).isValid();
    }

    formatDate(date: string | number | Date, format: string = 'yyyy-MM-dd', locale: string = 'en'): string {
        return date && formatDate(date, format, locale);
    }

    convertDateTimeFromServer(date: string | number | Date): Date {
        return date && new Date(date);
    }

    convertLocalDateFromServer(date: string): Date {
        if (!date) return;

        const [year, month, day] = date.split('-');
        return new Date(+year, +month - 1, +day);
    }

    toDate(date: string): Date {
        if (!date) return;

        const dateParts = date.split(/\D+/);
        const [year, month, day, hours, minutes, seconds, miliseconds] = dateParts;

        if (dateParts.length === 7) {
            return new Date(+year, +month - 1, +day, +hours, +minutes, +seconds, +miliseconds);
        }
        if (dateParts.length === 6) {
            return new Date(+year, +month - 1, +day, +hours, +minutes, +seconds);
        }
        return new Date(+year, +month - 1, +day, +hours, +minutes);
    }

    numDaysBetween(date1: Date, date2: Date): number {
        const diff = date1.getTime() - date2.getTime();
        return diff / (1000 * 60 * 60 * 24);
    }

    numDaysFromToday(date: Date): number {
        const today = new Date();
        const diff = date.getTime() - today.getTime();
        return diff / (1000 * 60 * 60 * 24);
    }

    getDatesFromDateRangeOption(
        dateRange: MetabasePredeterminedDatesRanges,
    ): [start: moment.Moment, end: moment.Moment] {
        switch (dateRange) {
            case MetabasePredeterminedDatesRanges.MonthToDate:
                return [moment().startOf('month'), moment()];
            case MetabasePredeterminedDatesRanges.LastMonth:
                const lastMonth = moment().subtract(1, 'month');
                return [moment(lastMonth).startOf('month'), moment(lastMonth).endOf('month')];
            case MetabasePredeterminedDatesRanges.LastThirtyDays:
                return [moment().subtract(30, 'days'), moment()];

            case MetabasePredeterminedDatesRanges.QuarterToDate:
                return [moment().startOf('quarter'), moment()];
            case MetabasePredeterminedDatesRanges.LastNinetyDays:
                return [moment().set('date', -90), moment()];
            case MetabasePredeterminedDatesRanges.LastQuarter:
                const lastQuarter = moment().subtract(1, 'Q');
                return [moment(lastQuarter).startOf('quarter'), moment(lastQuarter).endOf('quarter')];

            case MetabasePredeterminedDatesRanges.YearToDate:
                return [moment().startOf('year'), moment()];
            case MetabasePredeterminedDatesRanges.Last365Days:
                return [moment().subtract(365, 'days'), moment()];
            case MetabasePredeterminedDatesRanges.LastYear:
                const lastYear = moment().subtract(1, 'year');
                return [moment(lastYear).startOf('year'), moment(lastYear).endOf('year')];
            default:
                return null;
        }
    }
}
