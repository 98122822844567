import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Attachment } from 'src/main/webapp/app/entities/attachment';

@Component({
    selector: 'app-attachment-list-export',
    templateUrl: './attachment-list-export.component.html',
    styleUrls: ['./attachment-list-export.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttachmentListExportComponent {
    @Input()
    attachments: Attachment[];
}
