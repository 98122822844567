import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { VisoUser, VisoUserRole } from '../../../../entities/viso-user';

@Component({
    selector: 'app-business-owner',
    templateUrl: './business-owner.component.html',
    styleUrls: ['./business-owner.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BusinessOwnerComponent {
    @Input()
    relationshipId: number;

    @Input()
    businessOwner: VisoUser;

    Roles = VisoUserRole;

    get editLink() {
        return [
            '/',
            {
                outlets: {
                    popup: `relationships/${this.relationshipId}/business-owner`,
                },
            },
        ];
    }

    get businessOwnerName(): string {
        const { firstName, lastName } = this.businessOwner || {};
        return (firstName && lastName && `${firstName} ${lastName}`) || '';
    }

    get businessOwnerEmail(): string {
        return this.businessOwner?.email || '';
    }

    get businessOwnerBusinessUnitName(): string {
        return this.businessOwner?.businessUnitName || '';
    }
}
