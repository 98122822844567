import { createAction, props } from '@ngrx/store';

export enum QuestionnaireActions {
    AddQuestionToQuestionnaireRequest = '[Questionnaire] Add Question To Questionnaire Request',
    AddQuestionToQuestionnaireRequestSuccess = '[Questionnaire] Add Question To Questionnaire Request Success',
    AddQuestionToQuestionnaireRequestFailed = '[Questionnaire] Add Question To Questionnaire Request Failed',
    UpdateQuestionnaireQuestionRequest = '[Questionnaire] Update Questionnaire Question Request',
    UpdateQuestionnaireQuestionRequestSuccess = '[Questionnaire] Update Questionnaire Question Request Success',
    UpdateQuestionnaireQuestionRequestFailed = '[Questionnaire] Update Questionnaire Question Request Failed',
    DeleteQuestionnaireQuestionRequest = '[Questionnaire] Delete Questionnaire Question Request',
    DeleteQuestionnaireQuestionRequestSuccess = '[Questionnaire] Delete Questionnaire Question Request Success',
    DeleteQuestionnaireQuestionRequestFailed = '[Questionnaire] Delete Questionnaire Question Request Failed',
}

export const addQuestionToQuestionnaireRequest = createAction(
    QuestionnaireActions.AddQuestionToQuestionnaireRequest,
    props<{ questionnaireId: number; question: string }>(),
);

export const addQuestionToQuestionnaireRequestSuccess = createAction(
    QuestionnaireActions.AddQuestionToQuestionnaireRequestSuccess,
);

export const addQuestionToQuestionnaireRequestFailed = createAction(
    QuestionnaireActions.AddQuestionToQuestionnaireRequestFailed,
);

export const updateQuestionnaireQuestionRequest = createAction(
    QuestionnaireActions.UpdateQuestionnaireQuestionRequest,
    props<{ questionnaireId: number; questionId: number; question: string }>(),
);

export const updateQuestionnaireQuestionRequestSuccess = createAction(
    QuestionnaireActions.UpdateQuestionnaireQuestionRequestSuccess,
);

export const updateQuestionnaireQuestionRequestFailed = createAction(
    QuestionnaireActions.UpdateQuestionnaireQuestionRequestFailed,
);

export const deleteQuestionnaireQuestionRequest = createAction(
    QuestionnaireActions.DeleteQuestionnaireQuestionRequest,
    props<{ questionnaireId: number; questionId: number; question: string }>(),
);

export const deleteQuestionnaireQuestionRequestSuccess = createAction(
    QuestionnaireActions.DeleteQuestionnaireQuestionRequestSuccess,
);

export const deleteQuestionnaireQuestionRequestFailed = createAction(
    QuestionnaireActions.DeleteQuestionnaireQuestionRequestFailed,
);
