<div class="row mb-2">
    <div class="col">
        <div class="justify-content-between d-flex">
            <h3 class="lead">Details</h3>
            <div>
                <button class="me-2" mat-button visoButton="secondary" (click)="onDoneClicked.emit()">Done</button>
            </div>
        </div>
    </div>
</div>
<form [formGroup]="vendorDetailsFormGroup">
    <ng-container *jhiHasAnyAuthority="[Roles.Admin]">
        <div class="row mb-2">
            <div class="col">
                <h4>Vendor Information</h4>
                <div class="row">
                    <div class="col">
                        <div class="form-group">
                            <mat-form-field>
                                <mat-label>Name</mat-label>
                                <input matInput id="name" formControlName="name" />
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col">
                        <div class="form-group">
                            <mat-form-field>
                                <mat-label>Legal name</mat-label>
                                <input matInput id="legalName" formControlName="legalName" />
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col">
                        <div class="form-group">
                            <mat-form-field>
                                <mat-label>Homepage</mat-label>
                                <input matInput id="homepage" formControlName="homepage" />
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div class="form-group">
                            <mat-form-field>
                                <mat-label>Industry</mat-label>
                                <input matInput id="industry" formControlName="industry" />
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col">
                        <div class="form-group">
                            <mat-form-field>
                                <mat-label>Org size</mat-label>
                                <mat-select formControlName="orgSize">
                                    <mat-option
                                        *ngFor="let orgSize of OrgSize | enumToArray: OrgSizeLabels"
                                        [value]="orgSize.value"
                                        >{{ orgSize.label }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col">
                        <div class="form-group">
                            <mat-form-field>
                                <mat-label>Stock ticker</mat-label>
                                <input matInput id="stockTicker" formControlName="stockTicker" />
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col">
                        <div class="form-group">
                            <mat-form-field>
                                <mat-label>Founded date</mat-label>
                                <div class="date-form-group">
                                    <input
                                        id="foundedDate"
                                        matInput
                                        [matDatepicker]="foundedDatePicker"
                                        class="form-control"
                                        placeholder="yyyy-mm-dd"
                                        formControlName="foundedDate"
                                    />
                                    <mat-datepicker-toggle [for]="foundedDatePicker"></mat-datepicker-toggle>
                                    <mat-datepicker #foundedDatePicker></mat-datepicker>
                                </div>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div class="form-group">
                            <mat-form-field>
                                <mat-label>Street address</mat-label>
                                <input matInput id="streetAddress" formControlName="address" />
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col">
                        <div class="form-group">
                            <mat-form-field>
                                <mat-label>City</mat-label>
                                <input matInput id="city" formControlName="city" />
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col">
                        <div class="form-group">
                            <mat-form-field>
                                <mat-label>State</mat-label>
                                <input matInput id="region" formControlName="region" />
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col">
                        <div class="form-group">
                            <mat-form-field>
                                <mat-label>ZIP or postal code</mat-label>
                                <input matInput id="zip" formControlName="postalCode" />
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col">
                        <div class="form-group">
                            <mat-form-field>
                                <mat-label>Country or region</mat-label>
                                <mat-select formControlName="countryCode">
                                    <mat-option *ngFor="let country of countries" [value]="country.code3"
                                        >{{ country.name }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div class="form-group">
                            <mat-form-field>
                                <mat-label>Description</mat-label>
                                <textarea matInput id="description" formControlName="description"></textarea>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>

    <ng-container *jhiHasAnyAuthority="[Roles.Admin, Roles.Support]">
        <div class="row mb-4">
            <div class="col-6">
                <h4>Domains</h4>
                <div class="row mb-4">
                    <div class="col">
                        <app-badge class="me-1" *ngFor="let domain of vendorDetails.domains" type="light-primary">
                            {{ domain }}
                        </app-badge>
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col">
                        <mat-form-field class="w-100" appearance="outline">
                            <mat-label>New domain</mat-label>
                            <input matInput placeholder="example.com" [formControl]="domainFormControl" />
                        </mat-form-field>
                    </div>
                </div>
                <button
                    mat-button
                    visoButton="primary"
                    (click)="addDomain()"
                    [disabled]="vendorDetailsFormGroup.invalid || domainFormControl.invalid"
                >
                    <mat-icon>add_circle</mat-icon>
                    Add Domain
                </button>
            </div>
        </div>
    </ng-container>

    <div class="row mb-4">
        <div class="col">
            <h4>Links</h4>
            <div class="row">
                <div class="col">
                    <div class="form-group">
                        <label class="text-bold" for="privacyPolicyUrl">Privacy Policy</label>
                        <mat-form-field>
                            <mat-label>URL</mat-label>
                            <input matInput id="privacyPolicyUrl" formControlName="privacyPolicyUrl" />
                            <button
                                mat-icon-button
                                matSuffix
                                [disabled]="disablePrivacyPolicyUrl"
                                (click)="goToUrl(vendorDetailsFormGroup.controls.privacyPolicyUrl.getRawValue())"
                            >
                                <mat-icon>{{ 'open_in_new' }}</mat-icon>
                            </button>
                        </mat-form-field>
                    </div>
                </div>
                <div class="col">
                    <div class="form-group">
                        <label class="text-bold" for="trustCenterUrl">Trust Center</label>
                        <mat-form-field>
                            <mat-label>URL</mat-label>
                            <input matInput id="trustCenterUrl" formControlName="trustCenterUrl" />
                            <button
                                mat-icon-button
                                matSuffix
                                [disabled]="disableTrustCenterUrl"
                                (click)="goToUrl(vendorDetailsFormGroup.controls.trustCenterUrl.getRawValue())"
                            >
                                <mat-icon>{{ 'open_in_new' }}</mat-icon>
                            </button>
                        </mat-form-field>
                    </div>
                </div>
                <div class="col">
                    <div class="form-group">
                        <label class="text-bold" for="statusPageUrl">Service Availability Status </label>
                        <mat-form-field>
                            <mat-label>URL</mat-label>
                            <input matInput id="statusPageUrl" formControlName="statusPageUrl" />
                            <button
                                mat-icon-button
                                matSuffix
                                [disabled]="disableStatusPageUrl"
                                (click)="goToUrl(vendorDetailsFormGroup.controls.statusPageUrl.getRawValue())"
                            >
                                <mat-icon>{{ 'open_in_new' }}</mat-icon>
                            </button>
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <div class="form-group">
                        <label class="text-bold mb-0" for="incidentResponseUrl">Incident Response Contact</label>
                        <p>Leave blank if none found</p>
                        <mat-form-field>
                            <mat-label>URL</mat-label>
                            <input matInput id="incidentResponseUrl" formControlName="incidentResponseUrl" />
                            <button
                                mat-icon-button
                                matSuffix
                                [disabled]="disableIncidentResponseUrl"
                                (click)="goToUrl(vendorDetailsFormGroup.controls.incidentResponseUrl.getRawValue())"
                            >
                                <mat-icon>{{ 'open_in_new' }}</mat-icon>
                            </button>
                        </mat-form-field>
                    </div>
                </div>
                <div class="col">
                    <div class="form-group">
                        <label class="text-bold mb-0" for="customerListUrl">Customer Testimonials</label>
                        <p>Must be a page owned by third party</p>
                        <mat-form-field>
                            <mat-label>URL</mat-label>
                            <input matInput id="customerListUrl" formControlName="customerListUrl" />
                            <button
                                mat-icon-button
                                matSuffix
                                [disabled]="disableCustomerListUrl"
                                (click)="goToUrl(vendorDetailsFormGroup.controls.customerListUrl.getRawValue())"
                            >
                                <mat-icon>{{ 'open_in_new' }}</mat-icon>
                            </button>
                        </mat-form-field>
                    </div>
                </div>
                <div class="col">
                    <div class="form-group">
                        <label class="text-bold mb-0" for="regulatoryFilingsUrl">Financial Regulatory Filings</label>
                        <p>SEC, FCA, EBA, APRA, MAS, etc</p>
                        <mat-form-field>
                            <mat-label>URL</mat-label>
                            <input matInput id="regulatoryFilingsUrl" formControlName="regulatoryFilingsUrl" />
                            <button
                                mat-icon-button
                                matSuffix
                                [disabled]="disableRegulatoryFilingsUrl"
                                (click)="goToUrl(vendorDetailsFormGroup.controls.regulatoryFilingsUrl.getRawValue())"
                            >
                                <mat-icon>{{ 'open_in_new' }}</mat-icon>
                            </button>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row mb-4">
        <div class="col">
            <h4>Security Leadership</h4>
            <div class="row">
                <div
                    class="col"
                    *ngFor="let leadership of vendorDetailsFormGroup.controls.leadership.controls; let i = index"
                >
                    <div class="form-group" [formGroup]="leadership">
                        <label class="text-bold" for="privacyPolicyUrl">Person #{{ i + 1 }}</label>
                        <mat-form-field class="mb-3">
                            <mat-label>First Name</mat-label>
                            <input matInput formControlName="firstName" defaultNullValue />
                        </mat-form-field>
                        <mat-form-field class="mb-3">
                            <mat-label>Last Name</mat-label>
                            <input matInput formControlName="lastName" defaultNullValue />
                        </mat-form-field>
                        <mat-form-field class="mb-3">
                            <mat-label>Email Address</mat-label>
                            <input matInput formControlName="email" defaultNullValue />
                        </mat-form-field>
                        <mat-form-field class="mb-3">
                            <mat-label>Title</mat-label>
                            <input matInput formControlName="title" defaultNullValue />
                        </mat-form-field>
                        <mat-form-field class="mb-3">
                            <mat-label>LinkedIn Profile</mat-label>
                            <input matInput formControlName="linkedinUrl" defaultNullValue />
                            <mat-error>Please enter a valid LinkedIn url</mat-error>
                            <button
                                mat-icon-button
                                matSuffix
                                [disabled]="disableLeadershipLinkedinUrl(i)"
                                (click)="
                                    goToUrl(
                                        vendorDetailsFormGroup.controls.leadership.controls[
                                            i
                                        ].controls.linkedinUrl.getRawValue()
                                    )
                                "
                            >
                                <mat-icon>{{ 'open_in_new' }}</mat-icon>
                            </button>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row mb-4">
        <div class="col">
            <h4>Compliance Standards</h4>
            <label class="text-bold">Website(s)</label>
            <div class="row">
                <div class="col">
                    <div class="form-group">
                        <mat-form-field>
                            <mat-label>URL 1</mat-label>
                            <input matInput formControlName="complianceStandardsUrl1" />
                            <button
                                mat-icon-button
                                matSuffix
                                [disabled]="disableComplianceUrl1"
                                (click)="goToUrl(vendorDetailsFormGroup.controls.complianceStandardsUrl1.getRawValue())"
                            >
                                <mat-icon>{{ 'open_in_new' }}</mat-icon>
                            </button>
                        </mat-form-field>
                    </div>
                </div>
                <div class="col">
                    <div class="form-group">
                        <mat-form-field>
                            <mat-label>URL 2</mat-label>
                            <input matInput formControlName="complianceStandardsUrl2" />
                            <button
                                mat-icon-button
                                matSuffix
                                [disabled]="disableComplianceUrl2"
                                (click)="goToUrl(vendorDetailsFormGroup.controls.complianceStandardsUrl2.getRawValue())"
                            >
                                <mat-icon>{{ 'open_in_new' }}</mat-icon>
                            </button>
                        </mat-form-field>
                    </div>
                </div>
            </div>

            <label class="text-bold">Listed Certifications</label>
            <mat-list class="columns">
                <mat-list-item
                    class="form-group flex-row"
                    *ngFor="let complianceStandard of ComplianceStandard | enumToArray: ComplianceStandardLabels"
                >
                    <div matListItemTitle class="d-inline">{{ complianceStandard.label }}</div>
                    <mat-slide-toggle
                        matListItemMeta
                        color="primary"
                        labelPosition="before"
                        [formControl]="getFormControlByComplianceStandard(complianceStandard.value)"
                    ></mat-slide-toggle>
                </mat-list-item>
            </mat-list>
        </div>
    </div>
</form>
