import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { UserProfile, VisoUser, VisoUserMetadata } from './viso-user.model';
import { ActiveJwtView, CreateJwtPayload, JwtCreatedResponse } from '../../admin/jwt-management/jwt-management.model';
import { BYPASS_SNACKBAR_ON_ERROR } from '../../blocks/interceptor/errorhandler.interceptor';

@Injectable({
    providedIn: 'root',
})
export class VisoUserService {
    private resourceUrl = 'api/viso-users';
    private jwtResourceUrl = 'api/auth/jwt';

    constructor(private http: HttpClient) {}

    find(id: number): Observable<HttpResponse<VisoUser>> {
        return this.http
            .get<VisoUser>(`${this.resourceUrl}/${id}`, { observe: 'response' })
            .pipe(map((res: HttpResponse<VisoUser>) => this.convertResponse(res)));
    }

    findAllForOrg(): Observable<HttpResponse<VisoUser[]>> {
        return this.http
            .get<VisoUser[]>(this.resourceUrl, { observe: 'response' })
            .pipe(map((res: HttpResponse<VisoUser[]>) => this.convertArrayResponse(res)));
    }

    getUserProfile() {
        return this.http.get<UserProfile>(`${this.resourceUrl}/profile`);
    }

    updateUserProfile(userProfile: UserProfile) {
        return this.http.put(`${this.resourceUrl}/profile`, userProfile);
    }

    updateVisoUserMetadata(visoUserMetadataRequest: VisoUserMetadata): Observable<void> {
        return this.http.post<void>(`${this.resourceUrl}/metadata`, visoUserMetadataRequest, {
            context: new HttpContext().set(BYPASS_SNACKBAR_ON_ERROR, { forAnyStatus: true }),
        });
    }

    getActiveApiTokenDetails(): Observable<ActiveJwtView> {
        return this.http.get<ActiveJwtView>(this.jwtResourceUrl, {
            context: new HttpContext().set(BYPASS_SNACKBAR_ON_ERROR, { forAnyStatus: true }),
        });
    }

    generateApiToken(addJwtPayload: CreateJwtPayload): Observable<JwtCreatedResponse> {
        return this.http.post<JwtCreatedResponse>(this.jwtResourceUrl, addJwtPayload);
    }

    revokeApiToken(): Observable<void> {
        return this.http.delete<void>(`${this.jwtResourceUrl}/revoke`);
    }

    private convertResponse(res: HttpResponse<VisoUser>): HttpResponse<VisoUser> {
        const body: VisoUser = this.convertItemFromServer(res.body);
        return res.clone({ body });
    }

    private convertArrayResponse(res: HttpResponse<VisoUser[]>): HttpResponse<VisoUser[]> {
        const jsonResponse: VisoUser[] = res.body;
        const body: VisoUser[] = [];
        for (let i = 0; i < jsonResponse.length; i++) {
            body.push(this.convertItemFromServer(jsonResponse[i]));
        }
        return res.clone({ body });
    }

    /**
     * Convert a returned JSON object to VisoUser.
     */
    private convertItemFromServer(visoUser: VisoUser): VisoUser {
        const copy: VisoUser = Object.assign({}, visoUser);
        return copy;
    }
}
