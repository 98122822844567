<div
    class="btn-group position-relative"
    dropdown
    [container]="'body'"
    [insideClick]="true"
    #dropdown="bs-dropdown"
    [(isOpen)]="dropdownOpen"
    (mouseenter)="toggleDropdown(true)"
    (mouseleave)="toggleDropdown(false)"
>
    <span dropdownToggle [tagColor]="TagColorEnum.DEFAULT"> +{{ tags.length }} </span>
    <div
        *dropdownMenu
        class="dropdown-menu"
        role="menu"
        (mouseleave)="toggleDropdown(false)"
        (mouseenter)="toggleDropdown(true)"
    >
        <div class="tag-list">
            <span *ngFor="let tag of tags" [tagColor]="tag.color">{{ tag.name }}</span>
        </div>
    </div>
</div>
