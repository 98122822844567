import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TagsModule } from '../tags/tags.module';
import { CustomSelectComponent } from './custom-select/custom-select.component';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
    declarations: [CustomSelectComponent],
    imports: [CommonModule, ReactiveFormsModule, TagsModule, BsDropdownModule],
    exports: [CustomSelectComponent],
})
export class CustomSelectModule {}
