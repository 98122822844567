import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ControlDomain } from './control-domain.model';
import { createRequestOption } from '../../shared';

export type EntityResponseType = HttpResponse<ControlDomain>;

@Injectable({
    providedIn: 'root',
})
export class ControlDomainService {
    private resourceUrl = 'api/controls';

    constructor(private http: HttpClient) {}

    query(req?: any): Observable<HttpResponse<ControlDomain[]>> {
        const options = createRequestOption(req);
        return this.http
            .get<ControlDomain[]>(this.resourceUrl, { params: options, observe: 'response' })
            .pipe(map((res: HttpResponse<ControlDomain[]>) => this.convertArrayResponse(res)));
    }

    private convertArrayResponse(res: HttpResponse<ControlDomain[]>): HttpResponse<ControlDomain[]> {
        const jsonResponse: ControlDomain[] = res.body;
        const body: ControlDomain[] = [];
        for (let i = 0; i < jsonResponse.length; i++) {
            body.push(this.convertItemFromServer(jsonResponse[i]));
        }
        return res.clone({ body });
    }

    /**
     * Convert a returned JSON object to Control.
     */
    private convertItemFromServer(control: ControlDomain): ControlDomain {
        const copy: ControlDomain = Object.assign({}, control);
        return copy;
    }
}
