export const WebhookEvents = [
    {
        type: 'ASSESSMENT',
        events: [
            {
                displayName: 'Completed - Certification',
                value: ['ASSESSMENT_COMPLETED'],
                selected: false,
            },
            {
                displayName: 'Completed - Recertification',
                value: ['ASSESSMENT_RECERTIFICATION_COMPLETED'],
                selected: false,
            },
            {
                displayName: 'Completed - Artifact Update',
                value: ['ASSESSMENT_ARTIFACT_UPDATE_COMPLETED'],
                selected: false,
            },
            {
                displayName: 'Completed - Artifact Update (Docs Only)',
                value: ['DOCS_ONLY_ARTIFACT_UPDATE_COMPLETED'],
                selected: false,
            },
            {
                displayName: 'Reminder Sent',
                value: ['ASSESSMENT_REMINDER'],
                selected: false,
            },
            {
                displayName: 'Cancelled',
                value: [
                    'ASSESSMENT_CANCELLED',
                    'ASSESSMENT_CANCELLED_BY_VENDOR',
                    'ASSESSMENT_CANCELLED_BY_CLIENT',
                    'ASSESSMENT_CANCELLED_BY_ADMIN',
                ],
                selected: false,
            },
            {
                displayName: 'Cancelled by Auditor',
                value: ['ASSESSMENT_CANCELLED_BY_AUDITOR'],
                selected: false,
            },
            {
                displayName: 'Automatically Cancelled',
                value: ['ASSESSMENT_AUTOMATICALLY_CANCELLED'],
                selected: false,
            },
        ],
    },
    {
        type: 'RELATIONSHIP',
        events: [
            {
                displayName: 'Upcoming Recertification',
                value: ['UPCOMING_RECERTIFICATION'],
                selected: false,
            },
            {
                displayName: 'Overdue for Recertification',
                value: ['RELATIONSHIP_OVERDUE'],
                selected: false,
            },
            {
                displayName: 'Artifact(s) Expiring Soon',
                value: ['ARTIFACT_EXPIRING'],
                selected: false,
            },
            {
                displayName: 'Artifact(s) Expired',
                value: ['ARTIFACT_EXPIRED'],
                selected: false,
            },
        ],
    },
];
