import { Action, createReducer, on } from '@ngrx/store';
import { initialState, RelationshipsState } from './relationships-state';
import { setColumnsState, setAdditionalFiltersState, clearAllFilters } from './relationships.actions';

const reducer = createReducer(
    initialState,
    on(setColumnsState, (state, { results }) => ({
        ...state,
        columnsGroups: [...results],
    })),
    on(clearAllFilters, (state) => ({
        ...state,
        additionalFilters: null,
    })),
    on(setAdditionalFiltersState, (state, { filters }) => ({
        ...state,
        additionalFilters: { ...filters },
    })),
);

export function relationshipsReducer(state: RelationshipsState = initialState, action: Action): RelationshipsState {
    return reducer(state, action);
}
