import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    Output,
    ViewChild,
} from '@angular/core';
import { NgxExtendedPdfViewerComponent, NgxExtendedPdfViewerService } from 'ngx-extended-pdf-viewer';
import { BehaviorSubject, Subject, fromEvent } from 'rxjs';
import { debounce, distinctUntilChanged, filter, map, switchMap, take, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'pdf-viewer',
    templateUrl: './pdf-viewer.component.html',
    styleUrls: ['./pdf-viewer.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PdfViewerComponent implements AfterViewInit, OnDestroy {
    @Input()
    src: string | Blob;

    @Input()
    page: number;

    @Input()
    set selectText(text: string) {
        this._selectTextChange$.next(text);
    }

    @Input()
    height: string = '600px';

    @Input()
    zoom: string;

    @Input()
    password: string;

    @Output()
    selection = new EventEmitter<string>();

    @ViewChild('pdfViewer', { read: ElementRef })
    private _pdfViewerElementRef: ElementRef<HTMLElement>;
    @ViewChild('pdfViewer', { read: NgxExtendedPdfViewerComponent })
    private _pdfViewerComponent: NgxExtendedPdfViewerComponent;
    private _selectTextChange$ = new BehaviorSubject<string>('');
    private _unsub$ = new Subject<void>();

    constructor(private _ngxExtendedPdfViewerService: NgxExtendedPdfViewerService) {}

    ngAfterViewInit(): void {
        fromEvent(this._pdfViewerElementRef.nativeElement, 'selectstart')
            .pipe(
                switchMap(() =>
                    fromEvent(document, 'selectionchange').pipe(
                        debounce(() => fromEvent(document, 'mouseup').pipe(take(1))),
                        take(1),
                        map(() => document.getSelection().toString()),
                    ),
                ),
                filter(Boolean),
                takeUntil(this._unsub$),
            )
            .subscribe((selection) => this.selection.emit(selection));

        this._pdfViewerComponent.pdfLoaded
            .pipe(
                switchMap(() => this._selectTextChange$.pipe(distinctUntilChanged())),
                takeUntil(this._unsub$),
            )
            .subscribe((selectText) => {
                this._ngxExtendedPdfViewerService.find(selectText);
            });
    }

    ngOnDestroy(): void {
        this._unsub$.next();
    }
}
