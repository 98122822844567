import { Injectable } from '@angular/core';
import { HttpClient, HttpContext } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BYPASS_SNACKBAR_ON_ERROR } from '../../blocks/interceptor/errorhandler.interceptor';

@Injectable()
export class AuthServerProvider {
    constructor(private http: HttpClient) {}

    logout(): Observable<any> {
        // logout from the server
        return this.http.post(
            'api/logout',
            {},
            {
                observe: 'response',
                context: new HttpContext().set(BYPASS_SNACKBAR_ON_ERROR, { forAnyStatus: true }),
            },
        );
    }
}
