import { UntypedFormControl } from '@angular/forms';

export function noWhitespaceValidator(control: UntypedFormControl) {
    if (control && !control.value) {
        return null;
    }
    const trimValue = control.value
        .toString()
        .replace(/\u200E/g, '')
        .trim();
    const isValid = trimValue.length !== 0;
    return isValid ? null : { whitespace: true };
}
