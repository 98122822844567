import { Injectable } from '@angular/core';
import { MatDialogWrapperService } from '../../modal/mat-dialog-wrapper.service';
import { Observable } from 'rxjs';
import { MatDialogConfig } from '@angular/material/dialog';
import { MatConfirmDialogComponent } from './mat-confirm-dialog/mat-confirm-dialog.component';
import { MatConfirmDialogInputs } from './mat-confirm-dialog.model';

@Injectable()
export class MatConfirmDialogService {
    constructor(private _modalWrapperService: MatDialogWrapperService) {}

    confirm<TResult = boolean>(inputs: MatConfirmDialogInputs, config: MatDialogConfig = null): Observable<TResult> {
        const modal = this._modalWrapperService.open<MatConfirmDialogComponent, TResult>(MatConfirmDialogComponent, {
            inputs,
            config: { ...config, autoFocus: false },
            metadata: {
                modalName: 'ConfirmationDialog',
            },
        });
        return modal.afterClosed();
    }
}
