<div class="table-responsive" *ngIf="loggers">
    <h2>Logs</h2>

    <p>There are {{ loggers.length }} loggers.</p>

    <span>Filter</span> <input type="text" [(ngModel)]="filter" class="form-control" />

    <table class="table table-sm table-striped table-bordered">
        <thead>
            <tr title="click to order">
                <th (click)="orderProp = 'name'; orderOrientation = orderOrientation === 'asc' ? 'desc' : 'asc'">
                    <span>Name</span>
                </th>
                <th (click)="orderProp = 'level'; orderOrientation = orderOrientation === 'asc' ? 'desc' : 'asc'">
                    <span>Level</span>
                </th>
            </tr>
        </thead>

        <tr *ngFor="let logger of loggers | pureFilter: { name: filter } | orderBy: orderProp : orderOrientation">
            <td>
                <small>{{ logger.name | slice: 0 : 140 }}</small>
            </td>
            <td>
                <button
                    (click)="changeLevel(logger.name, 'TRACE')"
                    mat-button
                    visoButton="primary"
                    [color]="logger.level == 'TRACE' ? 'warn' : 'default'"
                >
                    TRACE
                </button>
                <button
                    (click)="changeLevel(logger.name, 'DEBUG')"
                    mat-button
                    visoButton="primary"
                    [color]="logger.level === 'DEBUG' ? 'success' : 'default'"
                >
                    DEBUG
                </button>
                <button
                    (click)="changeLevel(logger.name, 'INFO')"
                    mat-button
                    visoButton="primary"
                    [color]="logger.level === 'INFO' ? 'secondary' : 'default'"
                >
                    INFO
                </button>
                <button
                    (click)="changeLevel(logger.name, 'WARN')"
                    mat-button
                    visoButton="primary"
                    [color]="logger.level === 'WARN' ? 'primary' : 'default'"
                >
                    WARN
                </button>
                <button
                    (click)="changeLevel(logger.name, 'ERROR')"
                    mat-button
                    visoButton="primary"
                    [color]="logger.level === 'ERROR' ? 'accent' : 'default'"
                >
                    ERROR
                </button>
            </td>
        </tr>
    </table>
</div>
