<div class="row">
    <div class="col-xl-6">
        <table mat-table [dataSource]="dataSource" class="viso-table">
            <ng-container matColumnDef="domain">
                <th mat-header-cell *matHeaderCellDef>Domain</th>
                <td mat-cell *matCellDef="let row">
                    <a
                        class="dv-view-link"
                        (click)="openDomainVerificationConfigurationModal(row.domain)"
                        [tagColor]="row.color"
                        >{{ row.domain }}</a
                    >
                </td>
            </ng-container>

            <ng-container matColumnDef="dkimVerified">
                <th mat-header-cell *matHeaderCellDef>DKIM Verified</th>
                <td mat-cell *matCellDef="let row">
                    <div *ngIf="row.verified; else notVerified">
                        <span style="color: green">Yes</span>
                    </div>
                    <ng-template #notVerified>
                        <span style="color: red">No</span>
                    </ng-template>
                </td>
            </ng-container>

            <ng-container matColumnDef="rpVerified">
                <th mat-header-cell *matHeaderCellDef>RP Domain Verified</th>
                <td mat-cell *matCellDef="let row">
                    <div *ngIf="row.returnPathDomainVerified; else notVerified">
                        <span style="color: green">Yes</span>
                    </div>
                    <ng-template #notVerified>
                        <span style="color: red">No</span>
                    </ng-template>
                </td>
            </ng-container>

            <ng-container matColumnDef="dkimIcon">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let row" style="text-align: right; padding-right: 0" class="button-cell">
                    <button
                        mat-button
                        visoButton="link"
                        style="padding: 0"
                        (click)="openDomainVerificationConfigurationModal(row.domain)"
                    >
                        <mat-icon>settings_outline</mat-icon>
                    </button>
                    <button mat-button visoButton="link" (click)="refresh()"><mat-icon>refresh</mat-icon></button>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
    </div>
</div>
<h4 class="mb-1 mt-4 text-normal">Custom email</h4>
<div>
    <ng-container *ngIf="preference?.domain; else notSet">
        Automated emails from this instance will come from your customized email address.<br />
        <div class="mt-2">
            Email address<br />
            <strong>
                "{{ preference.emailDisplay }}" &lt;{{ preference.emailLocalpart }}&#64;{{ preference.domain }}&gt;
            </strong>
        </div>
    </ng-container>
    <ng-template #notSet> Your emails are not customized, yet. </ng-template>
</div>

<div class="row">
    <div class="col">
        <div
            class="d-inline-flex mt-2"
            matTooltip="Your email settings can be customized once a domain has been DKIM-verified."
            [matTooltipDisabled]="hasValidVerification"
            matTooltipPosition="above"
        >
            <button
                mat-button
                visoButton="secondary"
                [disabled]="!hasValidVerification"
                (click)="openCustomizeEmailModal()"
            >
                <mat-icon>edit_outline</mat-icon> Edit email
            </button>

            <button
                class="mx-2"
                mat-button
                visoButton="warning"
                *ngIf="preference?.domain"
                (click)="openConfirmDeletePreferredEmailDomainModal()"
            >
                <mat-icon>delete_outline</mat-icon> Reset to default
            </button>
        </div>
    </div>
</div>
