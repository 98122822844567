import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { hasDomainValidator } from '../../../shared/validators/domain-validator';
import { noWhitespaceValidator } from '../../../shared/validators/whitespace-validator';
import { map, takeUntil, tap } from 'rxjs/operators';
import { VisoUser } from '../../../entities/viso-user';
import { getUserAccount } from '../../session/redux/session.selectors';
import { BusinessUnit } from '../../../entities/business-unit';
import { RefSelectOption } from '../../../shared/model/select-options';
import { isUniqueContactValidator } from '../../../shared/validators/unique-contact-validator';
import { FormRawValue } from '../../../shared/model/controls-of';
import { FormUtilsService } from '../../../shared/utils/form-utils.service';
import { BusinessOwnerFormGroup } from '../../add-relationship/models/add-relationship-form';
import {
    assignRelationshipBusinessOwnerRequest,
    assignRelationshipBusinessOwnerRequestSuccess,
    BusinessOwnerActions,
} from '../redux/actions/business-owner.actions';
import {
    getRelationshipClientBusinessUnits,
    getRelationshipClientContacts,
    getRelationshipClientDomains,
    getRelationshipClientId,
} from '../redux/request.selectors';

@Component({
    selector: 'edit-business-owner-modal',
    templateUrl: './edit-business-owner-modal.component.html',
    styleUrls: ['./edit-business-owner-modal.component.scss'],
})
export class EditBusinessOwnerModalComponent implements OnInit, OnDestroy {
    @Input()
    requestId: number;

    businessOwnerFormGroup: FormGroup<BusinessOwnerFormGroup>;

    businessUnits$: Observable<RefSelectOption<BusinessUnit>[]>;
    contacts$ = new BehaviorSubject<RefSelectOption<VisoUser>[]>([]);
    currentAccount$: Observable<VisoUser>;
    orgDomains$ = new BehaviorSubject<string[]>([]);
    clientId$: Observable<number>;

    BusinessOwnerActions = BusinessOwnerActions;

    private _unsub$ = new Subject<void>();

    constructor(
        private _fb: FormBuilder,
        private _store$: Store,
        private _actions$: Actions,
        private _activeModal: NgbActiveModal,
        private _formUtils: FormUtilsService,
    ) {}

    get showFirstNameLengthError(): boolean {
        return !!this.businessOwnerFormGroup.controls.firstName.errors?.maxlength;
    }

    get showLastNameLengthError(): boolean {
        return !!this.businessOwnerFormGroup.controls.lastName.errors?.maxlength;
    }

    get showEmailLengthError(): boolean {
        return !!this.businessOwnerFormGroup.controls.email.errors?.maxlength;
    }

    ngOnInit(): void {
        this.businessOwnerFormGroup = this._fb.group({
            orgId: this._fb.control(null),
            contact: this._fb.control(null),
            firstName: this._fb.control(null, {
                validators: Validators.compose([Validators.required, Validators.maxLength(50)]),
            }),
            lastName: this._fb.control(null, {
                validators: Validators.compose([Validators.required, Validators.maxLength(50)]),
            }),
            email: this._fb.control(null),
            businessUnit: this._fb.control(null, { validators: Validators.required }),
            businessUnitName: this._fb.control(null, {
                validators: Validators.compose([Validators.required, noWhitespaceValidator]),
            }),
            managedByIdp: this._fb.control(false),
        });

        const clientContacts$ = this._store$.pipe(select(getRelationshipClientContacts));
        this.currentAccount$ = this._store$.pipe(select(getUserAccount));

        combineLatest([clientContacts$, this.currentAccount$])
            .pipe(
                map(([contacts, currentAccount]) => {
                    var selectOptions = contacts.map<RefSelectOption<VisoUser>>((contact) => ({
                        ref: contact,
                        name: `${contact.firstName} ${contact.lastName}  •  ${contact.email}  •  ${contact.businessUnitName}`,
                    }));

                    let contactMatchingCurrentUser = selectOptions.find(
                        (sub) => sub.ref.email.toLowerCase() === currentAccount.email.toLowerCase(),
                    )?.ref;

                    let contactList = [
                        ...selectOptions.filter(
                            (sub) => sub.ref.email.toLowerCase() !== currentAccount.email.toLowerCase(),
                        ),
                    ];

                    contactList = contactList.sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1));

                    if (contactMatchingCurrentUser) {
                        contactList.splice(0, 0, {
                            ref: contactMatchingCurrentUser,
                            name: `${currentAccount.firstName} ${currentAccount.lastName}  •  ${currentAccount.email}  •  ${contactMatchingCurrentUser.businessUnitName}`,
                        });
                    }
                    return contactList;
                }),
                takeUntil(this._unsub$),
            )
            .subscribe((contacts) => this.contacts$.next(contacts));

        this.businessUnits$ = this._store$.pipe(
            select(getRelationshipClientBusinessUnits),
            map((businessUnits) =>
                businessUnits.map<RefSelectOption<BusinessUnit>>((businessUnit) => ({
                    name: businessUnit.name,
                    ref: businessUnit,
                })),
            ),
        );
        this._store$
            .pipe(select(getRelationshipClientDomains), takeUntil(this._unsub$))
            .subscribe((orgDomains) => this.orgDomains$.next(orgDomains));

        this.clientId$ = this._store$.pipe(select(getRelationshipClientId));

        this._actions$
            .pipe(
                ofType(assignRelationshipBusinessOwnerRequestSuccess),
                tap(() => this.close()),
                takeUntil(this._unsub$),
            )
            .subscribe();
    }

    onContactCreationOptionChanged(selectExistingContact: boolean) {
        this.businessOwnerFormGroup.controls.email.setValidators(
            Validators.compose([
                Validators.required,
                Validators.maxLength(100),
                Validators.email,
                hasDomainValidator(this.orgDomains$),
                isUniqueContactValidator(this.contacts$, selectExistingContact, null),
            ]),
        );
    }

    private getBusinessOwnerPayload(clientId: number): VisoUser {
        const { contact, firstName, lastName, email, businessUnit, businessUnitName } =
            this._formUtils.getCleanFormGroupValue<FormRawValue<FormGroup<BusinessOwnerFormGroup>>>(
                this.businessOwnerFormGroup,
                true,
            );
        return {
            ...contact,
            orgId: contact?.orgId || clientId,
            firstName,
            lastName,
            email,
            businessUnitId: businessUnit?.id,
            businessUnitName: businessUnitName || businessUnit?.name,
            managedByIdp: contact?.managedByIdp || false,
        };
    }

    save(clientId: number) {
        const requestId = this.requestId;
        const businessOwner = this.getBusinessOwnerPayload(clientId);
        this._store$.dispatch(assignRelationshipBusinessOwnerRequest({ requestId, businessOwner }));
    }

    close() {
        this._activeModal.close();
    }

    ngOnDestroy(): void {
        this._unsub$.next();
    }
}
