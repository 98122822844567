import { Action, createReducer, on } from '@ngrx/store';
import {
    getServerSessionCompleted,
    getUserProfileSuccess,
    loadClientProfile,
    loadSession,
    logoutSuccess,
    updateUserProfile,
} from './session.actions';
import { initialState, SessionState } from './session.state';
import { LicenseType } from '@entities/viso-user';

const reducer = createReducer(
    initialState,
    on(loadSession, (state, { account }) => ({
        ...state,
        isAuthenticated: !!account,
        account,
        userIsTrial: account.clientLicense.licenseType === LicenseType.TRIAL,
    })),
    on(logoutSuccess, (state) => ({
        ...state,
        isAuthenticated: false,
        account: null,
    })),
    on(getServerSessionCompleted, (state) => ({
        ...state,
        sessionLoaded: true,
    })),
    on(loadClientProfile, (state, { profile }) => ({
        ...state,
        clientProfile: profile,
    })),
    on(getUserProfileSuccess, (state, { userProfile }) => ({
        ...state,
        userProfile,
    })),
    on(updateUserProfile, (state, { userProfile }) => ({
        ...state,
        userProfile: {
            ...state.userProfile,
            ...userProfile,
        },
    })),
);

export const sessionReducer = (state: SessionState = initialState, action: Action): SessionState =>
    reducer(state, action);
