import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
    createVanityDomainRequest,
    createVanityDomainRequestFailure,
    createVanityDomainRequestSuccess,
    getVanityDomainsConfigRequest,
    getVanityDomainsConfigRequestFailure,
    getVanityDomainsConfigRequestSuccess,
    getVanityDomainRequest,
    getVanityDomainRequestFailure,
    getVanityDomainRequestSuccess,
    deleteVanityDomainRequest,
    deleteVanityDomainRequestSuccess,
    deleteVanityDomainRequestFailure,
    refreshVanityDomainRequest,
} from './vanity-domain.actions';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

import { VanityDomainService } from '@entities/vanity-domain';

@Injectable()
export class VanityDomainEffects {
    getVanityDomain$ = createEffect(() =>
        this._actions$.pipe(
            ofType(getVanityDomainRequest),
            switchMap(() =>
                this._vanityDomainService.getVanityDomain().pipe(
                    map((response) => getVanityDomainRequestSuccess({ status: response })),
                    catchError(() => of(getVanityDomainRequestFailure())),
                ),
            ),
        ),
    );

    refreshVanityDomain$ = createEffect(() =>
        this._actions$.pipe(
            ofType(refreshVanityDomainRequest),
            switchMap(() =>
                this._vanityDomainService.refreshVanityDomain().pipe(
                    map((response) => getVanityDomainRequestSuccess({ status: response })),
                    catchError(() => of(getVanityDomainRequestFailure())),
                ),
            ),
        ),
    );

    getVanityDomainsConfig$ = createEffect(() =>
        this._actions$.pipe(
            ofType(getVanityDomainsConfigRequest),
            switchMap(() =>
                this._vanityDomainService.getConfig().pipe(
                    map((response) => getVanityDomainsConfigRequestSuccess({ config: response })),
                    catchError(() => of(getVanityDomainsConfigRequestFailure())),
                ),
            ),
        ),
    );

    createVanityDomain$ = createEffect(() =>
        this._actions$.pipe(
            ofType(createVanityDomainRequest),
            switchMap(({ domain, subdomain }) =>
                this._vanityDomainService.createVanityDomain(domain, subdomain).pipe(
                    map((response) => createVanityDomainRequestSuccess({ status: response })),
                    catchError(() => of(createVanityDomainRequestFailure())),
                ),
            ),
        ),
    );

    deleteVanityDomain$ = createEffect(() =>
        this._actions$.pipe(
            ofType(deleteVanityDomainRequest),
            switchMap(() =>
                this._vanityDomainService.deleteVanityDomain().pipe(
                    map((response) => deleteVanityDomainRequestSuccess()),
                    catchError(() => of(deleteVanityDomainRequestFailure())),
                ),
            ),
        ),
    );

    constructor(
        private _actions$: Actions,
        private _vanityDomainService: VanityDomainService,
    ) {}
}
