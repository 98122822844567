import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { map, mergeMap, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';
import { EnumSelectOption, RefSelectOption } from '../../shared/model/select-options';
import { NgbModalWrapperService } from '../../shared';
import { VisoUser } from '../../entities/viso-user';
import { Org, OrgService } from '../../entities/org';
import { JwtManagementService } from './jwt-management.service';
import {
    ActiveJwtView,
    CreateJwtPayload,
    JwtExpirationPeriodLabels,
    JwtExpirationPeriods,
} from './jwt-management.model';

interface AddJwtFormGroup {
    org: Org;
    user: VisoUser;
    expirationPeriod: JwtExpirationPeriods;
    customDate?: Date;
}

@Component({
    selector: 'app-jwt-management',
    templateUrl: './jwt-management.component.html',
    styleUrls: ['./jwt-management.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JwtManagementComponent implements OnInit, OnDestroy {
    jwts$ = new BehaviorSubject<ActiveJwtView[]>([]);
    jwtsFiltered$: Observable<ActiveJwtView[]>;
    jwtCount$: Observable<number>;
    orgs$: Observable<RefSelectOption<Org>[]>;
    users$: Observable<RefSelectOption<VisoUser>[]>;
    jwtExpirationPeriods$: Observable<EnumSelectOption[]> = of([
        {
            enumValue: JwtExpirationPeriods.THIRTY_DAYS,
            name: JwtExpirationPeriodLabels.THIRTY_DAYS,
        },
        {
            enumValue: JwtExpirationPeriods.SIXTY_DAYS,
            name: JwtExpirationPeriodLabels.SIXTY_DAYS,
        },
        {
            enumValue: JwtExpirationPeriods.NINETY_DAYS,
            name: JwtExpirationPeriodLabels.NINETY_DAYS,
        },
        {
            enumValue: JwtExpirationPeriods.SIX_MONTHS,
            name: JwtExpirationPeriodLabels.SIX_MONTHS,
        },
        {
            enumValue: JwtExpirationPeriods.ONE_YEAR,
            name: JwtExpirationPeriodLabels.ONE_YEAR,
        },
        {
            enumValue: JwtExpirationPeriods.CUSTOM,
            name: JwtExpirationPeriodLabels.CUSTOM,
        },
    ]);
    showCustomDateField$: Observable<boolean>;
    addJwtFormGroup: UntypedFormGroup;
    today = new Date();
    jwtListFilter = new UntypedFormControl();
    latestAccessToken: string;

    @ViewChild('accessTokenModal')
    private accessTokenModal: TemplateRef<any>;
    private _jwtRequestTrigger$ = new BehaviorSubject<void>(null);
    private _unsub$ = new Subject<void>();

    constructor(
        private _fb: UntypedFormBuilder,
        private _modalService: NgbModalWrapperService,
        private _jwtManagementService: JwtManagementService,
        private _orgService: OrgService,
    ) {}

    ngOnInit(): void {
        this.addJwtFormGroup = this._fb.group({
            org: [null, Validators.required],
            user: [null, Validators.required],
            expirationPeriod: [null, Validators.required],
            customDate: [{ value: null, disabled: true }, Validators.required],
        });

        this._jwtRequestTrigger$
            .pipe(
                switchMap(() => this._jwtManagementService.getActiveJwts()),
                map((users) =>
                    users.map((user) => ({
                        ...user,
                        fullName: `${user.firstName} ${user.lastName}`,
                    })),
                ),
                tap((jwts) => this.jwts$.next(jwts)),
                takeUntil(this._unsub$),
            )
            .subscribe();

        this.jwtsFiltered$ = this.jwtListFilter.valueChanges.pipe(
            startWith(''),
            mergeMap((filter) =>
                this.jwts$.pipe(
                    map(
                        (jwts) =>
                            (filter &&
                                jwts.filter(
                                    (jwt) =>
                                        jwt.fullName.toLowerCase().includes(filter.toLowerCase()) ||
                                        jwt.email.toLowerCase().includes(filter.toLowerCase()),
                                )) ||
                            jwts,
                    ),
                ),
            ),
        );

        this.jwtCount$ = this.jwts$.pipe(map((jwts) => jwts.length));

        this.orgs$ = this._orgService.clients().pipe(
            map((res) => res.body),
            map((orgs) =>
                orgs.map<RefSelectOption<Org>>((org) => ({
                    name: org.name,
                    ref: org,
                })),
            ),
        );

        const controls = this.addJwtFormGroup.controls;

        this.users$ = controls.org.valueChanges.pipe(
            switchMap((org: Org) => (!!org && this._orgService.findUsersByOrgId(org.id)) || of([])),
            map((users) => users.filter((user) => user.managedByIdp)),
            map((users) =>
                users.map<RefSelectOption<VisoUser>>((user) => ({
                    name: `${user.firstName} ${user.lastName} • ${user.email}`,
                    ref: user,
                })),
            ),
            startWith([]),
        );

        controls.expirationPeriod.valueChanges
            .pipe(
                tap((expirationDate: JwtExpirationPeriods) => {
                    controls.customDate.setValue(null);
                    if (expirationDate === JwtExpirationPeriods.CUSTOM) {
                        controls.customDate.enable();
                    } else {
                        controls.customDate.disable();
                    }
                }),
                takeUntil(this._unsub$),
            )
            .subscribe();

        this.showCustomDateField$ = controls.expirationPeriod.valueChanges.pipe(
            map((expirationDate: JwtExpirationPeriods) => expirationDate === JwtExpirationPeriods.CUSTOM),
            startWith(false),
        );
    }

    addJwt(): void {
        const { user, expirationPeriod, customDate } = this.addJwtFormGroup.value as AddJwtFormGroup;
        const payload = {
            visoUserId: user.id,
            jwtExpirationPeriod: expirationPeriod,
            customExpirationDate: customDate,
        } as CreateJwtPayload;
        this._jwtManagementService.addJwt(payload).subscribe((response) => {
            this._jwtRequestTrigger$.next();
            this.clearForm();
            this.latestAccessToken = response.accessToken;
            this.openAccessTokenModal();
        });
    }

    revokeJwt(jwt: ActiveJwtView): void {
        this._jwtManagementService.revokeJwt(jwt.jwtId).subscribe(() => this._jwtRequestTrigger$.next());
    }

    clearForm(): void {
        this.addJwtFormGroup.reset();
    }

    copyAccessTokenToClipboard(): void {
        navigator.clipboard.writeText(this.latestAccessToken);
    }

    ngOnDestroy(): void {
        this._unsub$.next();
    }

    private openAccessTokenModal(): void {
        this._modalService.openWithTemplate(this.accessTokenModal, {
            keyboard: false,
            backdrop: 'static',
        });
    }
}
