import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgxSelectModule } from 'ngx-select-ex';

import { SharedModule } from '../../shared/shared.module';
import {
    BusinessUnitPopupService,
    BusinessUnitDialogComponent,
    BusinessUnitPopupComponent,
    BusinessUnitDeleteDialogComponent,
    businessUnitPopupRoute,
    BusinessUnitReassignModalComponent,
} from '.';

const ENTITY_STATES = [...businessUnitPopupRoute];

@NgModule({
    imports: [SharedModule, RouterModule.forChild(ENTITY_STATES), NgxSelectModule],
    declarations: [
        BusinessUnitDialogComponent,
        BusinessUnitDeleteDialogComponent,
        BusinessUnitPopupComponent,
        BusinessUnitReassignModalComponent,
    ],
    providers: [BusinessUnitPopupService],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class BusinessUnitModule {}
