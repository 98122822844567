import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ControlDomain, ControlDomainType, ControlDomainTypeLabels } from '../../entities/control-domain';
import { ControlHelpModalContent } from './controls-help.component';
import { getOrgControls } from './redux/controls.selectors';
import { BREADCRUMB_CONTAINER_TOKEN } from '../../shared/dynamic-content/dynamic-content-injector';

interface GroupedControls {
    label: ControlDomainTypeLabels;
    controls: ControlDomain[];
}

@Component({
    selector: 'app-controls',
    templateUrl: './controls.component.html',
    styleUrls: ['./controls.component.scss'],
})
export class ControlsComponent implements OnInit, OnDestroy {
    public readonly breadcrumbsContainerToken = BREADCRUMB_CONTAINER_TOKEN;

    groupedControls$: Observable<GroupedControls[]>;

    private _unsub: Subject<void> = new Subject<void>();

    constructor(
        private _store$: Store,
        private _modalService: NgbModal,
    ) {}

    ngOnInit() {
        this.groupedControls$ = this._store$.pipe(
            select(getOrgControls),
            map((controls) => controls.sort((a, b) => (a.name > b.name ? 1 : -1))),
            map((controls) =>
                [
                    ControlDomainType.SECURITY,
                    ControlDomainType.PRIVACY,
                    ControlDomainType.AI_TRUST,
                    ControlDomainType.CYBER_INSURANCE,
                    ControlDomainType.RESILIENCE,
                    ControlDomainType.PRODUCT_SECURITY,
                    ControlDomainType.SERVICE_LOCATIONS,
                ].map<GroupedControls>((controlDomainType) => ({
                    label: ControlDomainTypeLabels[controlDomainType],
                    controls: controls.filter((c) => c.controlDomainType === controlDomainType),
                })),
            ),
        );
    }

    openHelp(topic: string, helpText: string) {
        const modalRef = this._modalService.open(ControlHelpModalContent);
        modalRef.componentInstance.topic = topic;
        modalRef.componentInstance.helpText = helpText;
    }

    ngOnDestroy(): void {
        this._unsub.next();
    }
}
