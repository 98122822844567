import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { ContextTypesEffects } from './redux/context-types.effects';
import { contextTypesStateKey } from './redux/context-types.state';
import { contextTypesReducer } from './redux/context-types.reducers';

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        StoreModule.forFeature(contextTypesStateKey, contextTypesReducer),
        EffectsModule.forFeature([ContextTypesEffects]),
    ],
})
export class ContextTypesModule {}
