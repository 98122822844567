import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import {
    AdminUpdateVendorDetailsRequest,
    ComplianceStandard,
    ComplianceStandardLabels,
    LeadershipContactView,
    UpdateVendorDetailsRequest,
    VendorDetailsResponse,
} from '../../../../routes/request/models/vendor-details.model';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { FormRawValue, FormValue } from '../../../model/controls-of';
import { FormUtilsService } from '../../../utils/form-utils.service';
import { OrgSize, OrgUrlType, OrgSizeLabels } from '../../../../entities/org';
import { urlValidator } from '../../../validators/url-validator';
import { VisoUserRole } from '../../../../entities/viso-user';
import { Country, storedCountries } from '../../../model/countries';
import { noWhitespaceValidator } from '@shared/validators/whitespace-validator';

interface VendorDetailsFormGroup {
    id: FormControl<number>; // orgId
    legalName: FormControl<string>;
    name: FormControl<string>;
    homepage: FormControl<string>;
    description: FormControl<string>;
    industry: FormControl<string>;
    orgSize: FormControl<OrgSize>;
    countryCode: FormControl<string>;
    region: FormControl<string>;
    city: FormControl<string>;
    address: FormControl<string>;
    postalCode: FormControl<string>;
    stateCode: FormControl<string>;
    stockTicker: FormControl<string>;
    foundedDate: FormControl<Date>;
    incidentResponseUrl: FormControl<string>;
    trustCenterUrl: FormControl<string>;
    statusPageUrl: FormControl<string>;
    customerListUrl: FormControl<string>;
    regulatoryFilingsUrl: FormControl<string>;
    privacyPolicyUrl: FormControl<string>;
    complianceStandardsUrl1: FormControl<string>;
    complianceStandardsUrl2: FormControl<string>;
    complianceStandards: FormArray<FormGroup<ComplianceFormGroup>>;
    leadership: FormArray<FormGroup<LeadershipContactFormGroup>>;
}

interface ComplianceFormGroup {
    ref: FormControl<ComplianceStandard>;
    value: FormControl<boolean>;
}

interface LeadershipContactFormGroup {
    id: FormControl<number>;
    firstName: FormControl<string>;
    lastName: FormControl<string>;
    email: FormControl<string>;
    title: FormControl<string>;
    linkedinUrl: FormControl<string>;
}

@Component({
    selector: 'app-vendor-details-form',
    templateUrl: './vendor-details-form.component.html',
    styleUrls: ['./vendor-details-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VendorDetailsFormComponent implements OnInit, OnChanges, OnDestroy {
    @Input({ required: true }) vendorDetails!: VendorDetailsResponse;

    @Input({ required: true }) isCurrentUserAdmin!: boolean;

    @Output()
    onDoneClicked = new EventEmitter<void>();

    @Output()
    onUpdateVendorDetails = new EventEmitter<AdminUpdateVendorDetailsRequest | UpdateVendorDetailsRequest>();

    @Output()
    onAddDomain = new EventEmitter<string>();

    Roles = VisoUserRole;
    ComplianceStandard = ComplianceStandard;
    ComplianceStandardLabels = ComplianceStandardLabels;
    OrgSizeLabels = OrgSizeLabels;
    OrgSize = OrgSize;

    countries: Country[] = storedCountries;
    vendorDetailsFormGroup: FormGroup<VendorDetailsFormGroup>;

    linkedinUrlPattern = /^(https?:\/\/)?([a-z\d.-]+\.)?linkedin\.com/i;

    domainFormControl: FormControl<string>;

    private _unsub$: Subject<void> = new Subject<void>();

    constructor(
        private _fb: FormBuilder,
        private _formUtils: FormUtilsService,
    ) {
        this.domainFormControl = this._fb.control<string>(null, {
            validators: Validators.required,
        });

        this.vendorDetailsFormGroup = this._fb.group({
            id: this._fb.control(null, {
                validators: Validators.required,
                nonNullable: true,
            }),
            legalName: this._fb.control(null),
            name: this._fb.control(null, {
                validators: Validators.required,
                nonNullable: true,
            }),
            homepage: this._fb.control(null),
            description: this._fb.control(null),
            industry: this._fb.control(null),
            orgSize: this._fb.control(null),
            countryCode: this._fb.control(null),
            region: this._fb.control(null),
            city: this._fb.control(null),
            address: this._fb.control(null),
            postalCode: this._fb.control(null, {
                validators: Validators.maxLength(16),
            }),
            stateCode: this._fb.control(null),
            stockTicker: this._fb.control(null),
            foundedDate: this._fb.control(null),
            incidentResponseUrl: this._fb.control(null, {
                validators: urlValidator,
            }),
            trustCenterUrl: this._fb.control(null, {
                validators: urlValidator,
            }),
            statusPageUrl: this._fb.control(null, {
                validators: urlValidator,
            }),
            customerListUrl: this._fb.control(null, {
                validators: urlValidator,
            }),
            regulatoryFilingsUrl: this._fb.control(null, {
                validators: urlValidator,
            }),
            privacyPolicyUrl: this._fb.control(null, {
                validators: urlValidator,
            }),
            complianceStandardsUrl1: this._fb.control(null, {
                validators: urlValidator,
            }),
            complianceStandardsUrl2: this._fb.control(null, {
                validators: urlValidator,
            }),
            complianceStandards: this._fb.array<FormGroup<ComplianceFormGroup>>([]),
            leadership: this._fb.array<FormGroup<LeadershipContactFormGroup>>([]),
        });

        Object.keys(ComplianceStandard).forEach((standard) => {
            const toggleOption = ComplianceStandard[standard as keyof typeof ComplianceStandard];
            this.vendorDetailsFormGroup.controls.complianceStandards.push(
                this._fb.group({
                    ref: this._fb.control(toggleOption),
                    value: this._fb.control(false),
                }),
            );
        });

        for (let i = 0; i < 3; i++) {
            this.vendorDetailsFormGroup.controls.leadership.push(
                this._fb.group({
                    id: this._fb.control(null, { nonNullable: false }),
                    firstName: this._fb.control(null),
                    lastName: this._fb.control(null),
                    email: this._fb.control(null, [Validators.maxLength(100), Validators.email]),
                    title: this._fb.control(null),
                    linkedinUrl: this._fb.control(null, [urlValidator, Validators.pattern(this.linkedinUrlPattern)]),
                }),
            );
        }
    }

    get disableTrustCenterUrl() {
        return (
            this.vendorDetailsFormGroup.controls.trustCenterUrl.invalid ||
            !this.vendorDetailsFormGroup.controls.trustCenterUrl.getRawValue()
        );
    }

    get disableStatusPageUrl() {
        return (
            this.vendorDetailsFormGroup.controls.statusPageUrl.invalid ||
            !this.vendorDetailsFormGroup.controls.statusPageUrl.getRawValue()
        );
    }

    get disableRegulatoryFilingsUrl() {
        return (
            this.vendorDetailsFormGroup.controls.regulatoryFilingsUrl.invalid ||
            !this.vendorDetailsFormGroup.controls.regulatoryFilingsUrl.getRawValue()
        );
    }

    get disableIncidentResponseUrl() {
        return (
            this.vendorDetailsFormGroup.controls.incidentResponseUrl.invalid ||
            !this.vendorDetailsFormGroup.controls.incidentResponseUrl.getRawValue()
        );
    }

    get disableCustomerListUrl() {
        return (
            this.vendorDetailsFormGroup.controls.customerListUrl.invalid ||
            !this.vendorDetailsFormGroup.controls.customerListUrl.getRawValue()
        );
    }

    get disablePrivacyPolicyUrl() {
        return (
            this.vendorDetailsFormGroup.controls.privacyPolicyUrl.invalid ||
            !this.vendorDetailsFormGroup.controls.privacyPolicyUrl.getRawValue()
        );
    }

    get disableComplianceUrl1() {
        return (
            this.vendorDetailsFormGroup.controls.complianceStandardsUrl1.invalid ||
            !this.vendorDetailsFormGroup.controls.complianceStandardsUrl1.getRawValue()
        );
    }

    get disableComplianceUrl2() {
        return (
            this.vendorDetailsFormGroup.controls.complianceStandardsUrl2.invalid ||
            !this.vendorDetailsFormGroup.controls.complianceStandardsUrl2.getRawValue()
        );
    }

    ngOnInit(): void {
        this.vendorDetailsFormGroup.valueChanges
            .pipe(debounceTime(500), takeUntil(this._unsub$))
            .subscribe(() => this.updateVendorDetails());

        this.setLeadershipDynamicValidators();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.vendorDetails && !!this.vendorDetails) {
            let complianceControls: { ref: ComplianceStandard; value: boolean }[] = [];
            Object.keys(ComplianceStandard).forEach((standard) => {
                const toggleOption = ComplianceStandard[standard as keyof typeof ComplianceStandard];
                complianceControls.push({
                    ref: toggleOption,
                    value: this.vendorDetails.complianceStandards.includes(toggleOption),
                });
            });

            let leadershipControls: LeadershipContactView[] = [];
            for (let i = 0; i < 3; i++) {
                leadershipControls.push({
                    id: this.vendorDetails.leadership[i]?.id || null,
                    firstName: this.vendorDetails.leadership[i]?.firstName || null,
                    lastName: this.vendorDetails.leadership[i]?.lastName || null,
                    email: this.vendorDetails.leadership[i]?.email || null,
                    title: this.vendorDetails.leadership[i]?.title || null,
                    linkedinUrl: this.vendorDetails.leadership[i]?.linkedinUrl || null,
                });
            }

            // Update form values when vendorDetails changes
            this.vendorDetailsFormGroup.setValue(
                {
                    id: this.vendorDetails.id,

                    legalName: this.vendorDetails.legalName,
                    name: this.vendorDetails.name,
                    homepage: this.vendorDetails.homepage,
                    description: this.vendorDetails.description,
                    industry: this.vendorDetails.industry,
                    orgSize: this.vendorDetails.orgSize,
                    countryCode: this.vendorDetails.countryCode,
                    region: this.vendorDetails.region,
                    city: this.vendorDetails.city,
                    address: this.vendorDetails.address,
                    postalCode: this.vendorDetails.postalCode,
                    stateCode: this.vendorDetails.stateCode,
                    stockTicker: this.vendorDetails.stockTicker,
                    foundedDate: this.vendorDetails.foundedDate,

                    incidentResponseUrl: this.vendorDetails.urls[OrgUrlType.INCIDENT_RESPONSE] || null,
                    trustCenterUrl: this.vendorDetails.urls[OrgUrlType.TRUST_CENTER] || null,
                    statusPageUrl: this.vendorDetails.urls[OrgUrlType.STATUS_PAGE] || null,
                    customerListUrl: this.vendorDetails.urls[OrgUrlType.CUSTOMER_LIST] || null,
                    regulatoryFilingsUrl: this.vendorDetails.urls[OrgUrlType.REGULATORY_FILINGS] || null,
                    privacyPolicyUrl: this.vendorDetails.urls[OrgUrlType.PRIVACY_POLICY] || null,
                    complianceStandardsUrl1: this.vendorDetails.urls[OrgUrlType.COMPLIANCE_STANDARDS_1] || null,
                    complianceStandardsUrl2: this.vendorDetails.urls[OrgUrlType.COMPLIANCE_STANDARDS_2] || null,
                    complianceStandards: complianceControls,
                    leadership: leadershipControls,
                },
                { emitEvent: false },
            );
            this.vendorDetailsFormGroup.markAllAsTouched();
        }
    }

    setLeadershipDynamicValidators() {
        const leadershipFormArray = this.vendorDetailsFormGroup.controls.leadership;

        leadershipFormArray.controls.forEach((leadershipFormGroup: FormGroup<LeadershipContactFormGroup>) => {
            let controlList = [
                leadershipFormGroup.controls.firstName,
                leadershipFormGroup.controls.lastName,
                leadershipFormGroup.controls.email,
                leadershipFormGroup.controls.title,
                leadershipFormGroup.controls.linkedinUrl,
            ];

            leadershipFormGroup.valueChanges.pipe(takeUntil(this._unsub$)).subscribe(() => {
                // Set Validators.required based on the non-null value of any control in the FormGroup
                const anyRequiredFieldNonNull = controlList.some((ctrl) => !!ctrl.value);

                controlList
                    .filter((control) => control !== leadershipFormGroup.controls.email)
                    .forEach((control) => {
                        if (anyRequiredFieldNonNull) {
                            if (control === leadershipFormGroup.controls.linkedinUrl) {
                                control.setValidators([
                                    Validators.required,
                                    urlValidator,
                                    noWhitespaceValidator,
                                    Validators.pattern(this.linkedinUrlPattern),
                                ]);
                            } else if (control === leadershipFormGroup.controls.email) {
                                control.setValidators([
                                    Validators.required,
                                    Validators.maxLength(100),
                                    Validators.email,
                                    noWhitespaceValidator,
                                ]);
                            } else {
                                control.setValidators([Validators.required, noWhitespaceValidator]);
                            }
                        } else {
                            control.clearValidators();
                        }

                        // Update the validity of the control
                        control.updateValueAndValidity({ emitEvent: false });
                        this.vendorDetailsFormGroup.markAllAsTouched();
                    });
            });
        });
    }

    disableLeadershipLinkedinUrl(index: number) {
        return (
            this.vendorDetailsFormGroup.controls.leadership.controls[index].controls.linkedinUrl.invalid ||
            !this.vendorDetailsFormGroup.controls.leadership.controls[index].controls.linkedinUrl.getRawValue()
        );
    }

    goToUrl(url: string) {
        // Check if the URL starts with "http://" or "https://"
        if (!url.startsWith('http://') && !url.startsWith('https://')) {
            // If not, prepend "https://"
            url = 'https://' + url;
        }
        window.open(url, '_blank');
    }

    updateVendorDetails() {
        if (this.vendorDetailsFormGroup.valid) {
            this.onUpdateVendorDetails.emit(this.getVendorDetailsUpdatePayload());
        }
    }

    private getVendorDetailsUpdatePayload(): AdminUpdateVendorDetailsRequest | UpdateVendorDetailsRequest {
        const {
            id,
            legalName,
            name,
            homepage,
            description,
            industry,
            orgSize,
            countryCode,
            region,
            city,
            address,
            postalCode,
            stateCode,
            stockTicker,
            foundedDate,
            incidentResponseUrl,
            trustCenterUrl,
            statusPageUrl,
            customerListUrl,
            regulatoryFilingsUrl,
            privacyPolicyUrl,
            complianceStandardsUrl1,
            complianceStandardsUrl2,
            complianceStandards,
            leadership,
        } = this._formUtils.getCleanFormGroupValue<FormRawValue<FormGroup<VendorDetailsFormGroup>>>(
            this.vendorDetailsFormGroup,
            true,
        );

        let urls: Record<OrgUrlType, string | undefined> = {
            [OrgUrlType.COMPLIANCE_STANDARDS_1]: complianceStandardsUrl1 || undefined,
            [OrgUrlType.COMPLIANCE_STANDARDS_2]: complianceStandardsUrl2 || undefined,
            [OrgUrlType.CUSTOMER_LIST]: customerListUrl || undefined,
            [OrgUrlType.INCIDENT_RESPONSE]: incidentResponseUrl || undefined,
            [OrgUrlType.PRIVACY_POLICY]: privacyPolicyUrl || undefined,
            [OrgUrlType.REGULATORY_FILINGS]: regulatoryFilingsUrl || undefined,
            [OrgUrlType.STATUS_PAGE]: statusPageUrl || undefined,
            [OrgUrlType.TRUST_CENTER]: trustCenterUrl || undefined,
        };

        let standards: ComplianceStandard[] = complianceStandards.filter((ctrl) => ctrl.value).map((ctrl) => ctrl.ref);

        let leadershipUpdate = [];
        leadership.forEach((_leader) => {
            let leader: FormValue<FormGroup<LeadershipContactFormGroup>> = Object.entries(_leader).reduce(
                (acc, [key, value]) => {
                    if (value === '') {
                        acc[key] = null;
                    } else {
                        acc[key] = value;
                    }
                    return acc;
                },
                {},
            );

            const allNullValues = [
                leader.id,
                leader.firstName,
                leader.lastName,
                leader.title,
                leader.linkedinUrl,
                leader.email,
            ].every((value) => value === null || value === '');
            if (
                allNullValues ||
                (!!leader.id &&
                    leader.firstName === null &&
                    leader.lastName === null &&
                    leader.title === null &&
                    leader.linkedinUrl === null)
            ) {
            } else {
                leadershipUpdate.push(leader);
            }
        });

        if (this.isCurrentUserAdmin) {
            return {
                id,
                legalName,
                name,
                homepage,
                description,
                industry,
                orgSize,
                countryCode,
                region,
                city,
                address,
                postalCode,
                stateCode,
                stockTicker,
                foundedDate,
                urls,
                complianceStandards: standards,
                leadership: leadershipUpdate,
            };
        } else {
            return {
                id,
                urls,
                complianceStandards: standards,
                leadership: leadershipUpdate,
            };
        }
    }

    getFormControlByComplianceStandard(complianceStandard: ComplianceStandard) {
        for (let complianceFormGroup of this.vendorDetailsFormGroup.controls.complianceStandards.controls) {
            if (complianceFormGroup.controls.ref.getRawValue() === complianceStandard) {
                return complianceFormGroup.controls.value;
            }
        }
        return null;
    }

    addDomain() {
        this.onAddDomain.emit(this.domainFormControl.getRawValue());
        this.domainFormControl.reset();
    }

    ngOnDestroy(): void {
        this._unsub$.next();
    }
}
