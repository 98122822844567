import { Directive, InjectionToken, Injector, Input, ViewContainerRef } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Directive({
    selector: '[dynamicContainer]',
})
export class DynamicContainerDirective {
    lastSubject: Subject<ViewContainerRef>;

    constructor(
        private viewContainer: ViewContainerRef,
        private injector: Injector,
    ) {}

    ngOnDestroy() {
        if (this.lastSubject) {
            this.lastSubject.next(null);
        }
    }

    @Input() set dynamicContainer(containerToken: InjectionToken<BehaviorSubject<ViewContainerRef>>) {
        if (this.lastSubject) {
            this.lastSubject.next(null);
        }

        this.lastSubject = this.injector.get(containerToken);
        this.lastSubject.next(this.viewContainer);
    }
}
