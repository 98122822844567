<ngx-select
    [allowClear]="true"
    [items]="vendorOrgs$ | async"
    [placeholder]="placeholderText"
    [formControl]="vendorSearchFormControl"
    optionTextField="name"
    optionValueField="ref"
    [searchCallback]="searchCallbackIgnoreFilter"
    dropDownMenuOtherClasses="sticky-last-option"
>
    <ng-template ngx-select-option let-option>
        <ng-container *ngIf="option.value | asVendorSearchResult as vendorOption">
            <ng-container *ngIf="vendorOption.isNewVendor; else existingVendor">
                <span>{{ option.text }}</span>
            </ng-container>
            <ng-template #existingVendor>
                <div class="row row-height">
                    <div class="col col-icon">
                        <img
                            class="organization-icon"
                            *ngIf="vendorOption.faviconUrl"
                            [src]="vendorOption.faviconUrl"
                            [appImageFallback]="vendorImageFallback"
                            onImageErrorCssClass="no-organization-icon"
                        />
                        <div class="no-organization-icon" *ngIf="!vendorOption.faviconUrl"></div>
                    </div>

                    <div class="col ps-0">
                        <div class="row name-industry-spacing">
                            <div class="col">
                                <span>{{ vendorOption.name }}</span>
                                <span
                                    *ngIf="!!vendorOption.managedById"
                                    [tagColor]="TagColor.COLOR_01"
                                    class="custom-tag"
                                    >Custom</span
                                >
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <span class="text-muted d-block">{{ vendorOption.homepage }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </ng-container>
    </ng-template>
    <ng-template ngx-select-option-selected let-option>
        <ng-container *ngIf="option.value | asVendorSearchResult as vendorOption">
            <span
                ><img
                    class="organization-icon-selected"
                    *ngIf="vendorOption.faviconUrl"
                    [src]="vendorOption.faviconUrl"
                    [appImageFallback]="vendorImageFallback"
                    onImageErrorCssClass="d-none"
                />
            </span>
            <span>{{ vendorOption.name }}</span>
        </ng-container>
    </ng-template>
</ngx-select>
