import { createAction, props } from '@ngrx/store';
import { SubdomainResponse } from '@entities/org-subdomain';

export enum OrgSubdomainActions {
    GetOrgSubdomainRequest = '[OrgSubdomain] Get Org Subdomain Request',
    GetOrgSubdomainRequestSuccess = '[OrgSubdomain] Get Org Subdomain Request Success',
    GetOrgSubdomainRequestFailure = '[OrgSubdomain] Get Org Subdomain Request Failure',

    SetOrgSubdomainRequest = '[OrgSubdomain] Set Org Subdomain Request',
    SetOrgSubdomainRequestSuccess = '[OrgSubdomain] Set Org Subdomain Request Success',
    SetOrgSubdomainRequestFailure = '[OrgSubdomain] Set Org Subdomain Request Failure',

    DeleteOrgSubdomainRequest = '[OrgSubdomain] Delete Org Subdomain Request',
    DeleteOrgSubdomainRequestSuccess = '[OrgSubdomain] Delete Org Subdomain Request Success',
    DeleteOrgSubdomainRequestFailure = '[OrgSubdomain] Delete Org Subdomain Request Failure',
}

export const getOrgSubdomainRequest = createAction(OrgSubdomainActions.GetOrgSubdomainRequest);

export const getOrgSubdomainRequestSuccess = createAction(
    OrgSubdomainActions.GetOrgSubdomainRequestSuccess,
    props<{ response: SubdomainResponse }>(),
);

export const getOrgSubdomainRequestFailure = createAction(OrgSubdomainActions.GetOrgSubdomainRequestFailure);

export const setOrgSubdomainRequest = createAction(
    OrgSubdomainActions.SetOrgSubdomainRequest,
    props<{ subdomain: string }>(),
);

export const setOrgSubdomainRequestSuccess = createAction(
    OrgSubdomainActions.SetOrgSubdomainRequestSuccess,
    props<{ response: SubdomainResponse }>(),
);

export const setOrgSubdomainRequestFailure = createAction(OrgSubdomainActions.SetOrgSubdomainRequestFailure);

export const deleteOrgSubdomainRequest = createAction(OrgSubdomainActions.DeleteOrgSubdomainRequest);

export const deleteOrgSubdomainRequestSuccess = createAction(OrgSubdomainActions.DeleteOrgSubdomainRequestSuccess);

export const deleteOrgSubdomainRequestFailure = createAction(OrgSubdomainActions.DeleteOrgSubdomainRequestFailure);
