import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
    CardDirective,
    CardToolbarDirective,
    CardToolbarItemDirective,
    CardToolbarSectionDirective,
    CardContentDirective,
} from './card.component';

const COMPONENTS = [
    CardDirective,
    CardToolbarDirective,
    CardToolbarItemDirective,
    CardToolbarSectionDirective,
    CardContentDirective,
];

@NgModule({
    imports: [CommonModule],
    exports: COMPONENTS,
    declarations: COMPONENTS,
    schemas: [NO_ERRORS_SCHEMA],
})
export class CardModule {}
