import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { RouterModule } from '@angular/router';

import { SharedModule } from '../../shared';
import { userRegistrationRoutes } from './user-registration.route';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UserRegistrationComponent } from './user-registration.component';
import { EffectsModule } from '@ngrx/effects';
import { UserAccountEffects } from './redux/user-account.effects';
import { UserActivationComponent } from './user-activation/user-activation.component';
import { RegistrationPanelComponent } from './registration-panel/registration-panel.component';
import { NgxSelectModule } from 'ngx-select-ex';

const ENTITY_STATES = [...userRegistrationRoutes];

@NgModule({
    imports: [
        SharedModule,
        RouterModule.forChild(ENTITY_STATES),
        FormsModule,
        ReactiveFormsModule,
        EffectsModule.forFeature([UserAccountEffects]),
        NgxSelectModule,
    ],
    declarations: [UserRegistrationComponent, UserActivationComponent, RegistrationPanelComponent],
    providers: [],
    exports: [],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class UserRegistrationModule {}
