import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PrimaryVendorContact } from './primary-vendor-contact.model';
import { map } from 'rxjs/operators';

export type EntityResponseType = HttpResponse<PrimaryVendorContact>;

@Injectable({
    providedIn: 'root',
})
export class PrimaryVendorContactService {
    private _relationshipsResourceUrl = 'api/relationships';
    private _requestRecipientsResourceUrl = 'api/relationship-recipients';

    constructor(private _http: HttpClient) {}

    find(id: number): Observable<EntityResponseType> {
        return this._http
            .get<PrimaryVendorContact>(`${this._requestRecipientsResourceUrl}/${id}`, { observe: 'response' })
            .pipe(map((res: EntityResponseType) => this.convertResponse(res)));
    }

    save(primaryVendorContact: PrimaryVendorContact): Observable<void> {
        return this._http.post<void>(
            `${this._relationshipsResourceUrl}/${primaryVendorContact.relationshipId}/primary-contact`,
            primaryVendorContact,
        );
    }

    remove(relationshipId: number, primaryContactId: number): Observable<HttpResponse<any>> {
        return this._http.delete<any>(
            `${this._relationshipsResourceUrl}/${relationshipId}/primary-contact/${primaryContactId}`,
            {
                observe: 'response',
            },
        );
    }

    private convertResponse(res: EntityResponseType): EntityResponseType {
        const body: PrimaryVendorContact = this.convertItemFromServer(res.body);
        return res.clone({ body });
    }

    /**
     * Convert a returned JSON object to PrimaryVendorContact.
     */
    private convertItemFromServer(primaryVendorContact: PrimaryVendorContact): PrimaryVendorContact {
        return Object.assign({}, primaryVendorContact);
    }
}
