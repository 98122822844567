import { createAction, props } from '@ngrx/store';
import {
    RelationshipRiskToleranceView,
    RiskTolerance,
    UpdateRiskToleranceSettingsRequest,
} from '@entities/risk-tolerance';
import { Risk } from '@entities/risk-assessment';

export enum RiskModelSettingsActions {
    GetRiskToleranceThresholdsRequest = '[RiskModelSettings] Get Risk Tolerance Thresholds Request',
    GetRiskToleranceThresholdsRequestSuccess = '[RiskModelSettings] Get Risk Tolerance Settings Request Success',
    GetRiskToleranceThresholdsRequestFailed = '[RiskModelSettings] Get Risk Tolerance Settings Request Failed',

    GetRelationshipRiskDistributionRequest = '[RiskModelSettings] Get Relationship Risk Distribution Request',
    GetRelationshipRiskDistributionRequestSuccess = '[RiskModelSettings] Get Relationship Risk Distribution Request Success',
    GetRelationshipRiskDistributionRequestFailed = '[RiskModelSettings] Get Relationship Risk Distribution Request Failed',

    UpdateRiskToleranceSettingsRequest = '[RiskModelSettings] Update Risk Tolerance Settings Request',
    UpdateRiskToleranceSettingsRequestSuccess = '[RiskModelSettings] Update Risk Tolerance Settings Request Success',
    UpdateRiskToleranceSettingsRequestFailed = '[RiskModelSettings] Update Risk Tolerance Settings Request Failed',

    OpenCustomizeRiskToleranceDialog = '[RiskModelSettings] Open Customize Risk Tolerance Dialog',
    LoadRiskToleranceSettings = '[RiskModelSettings] Load Risk Tolerance Settings',
}

export const loadRiskToleranceSettings = createAction(RiskModelSettingsActions.LoadRiskToleranceSettings);

export const getRiskToleranceThresholdsRequest = createAction(
    RiskModelSettingsActions.GetRiskToleranceThresholdsRequest,
);

export const getRiskToleranceThresholdsRequestSuccess = createAction(
    RiskModelSettingsActions.GetRiskToleranceThresholdsRequestSuccess,
    props<{ riskToleranceThresholds: Map<RiskTolerance, Map<Risk, number>> }>(),
);

export const getRiskToleranceThresholdsRequestFailed = createAction(
    RiskModelSettingsActions.GetRiskToleranceThresholdsRequestFailed,
);

export const getRelationshipRiskDistributionRequest = createAction(
    RiskModelSettingsActions.GetRelationshipRiskDistributionRequest,
);

export const getRelationshipRiskDistributionRequestSuccess = createAction(
    RiskModelSettingsActions.GetRelationshipRiskDistributionRequestSuccess,
    props<{ relationshipRiskDistribution: Map<Risk, RelationshipRiskToleranceView[]> }>(),
);

export const getRelationshipRiskDistributionRequestFailed = createAction(
    RiskModelSettingsActions.GetRelationshipRiskDistributionRequestFailed,
);

export const openCustomizeRiskToleranceDialog = createAction(RiskModelSettingsActions.OpenCustomizeRiskToleranceDialog);

export const updateRiskToleranceSettingsRequest = createAction(
    RiskModelSettingsActions.UpdateRiskToleranceSettingsRequest,
    props<{ request: UpdateRiskToleranceSettingsRequest }>(),
);

export const updateRiskToleranceSettingsRequestSuccess = createAction(
    RiskModelSettingsActions.UpdateRiskToleranceSettingsRequestSuccess,
);

export const updateRiskToleranceSettingsRequestFailed = createAction(
    RiskModelSettingsActions.UpdateRiskToleranceSettingsRequestFailed,
);
