import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { RouterNavigationAction, ROUTER_NAVIGATED } from '@ngrx/router-store';
import { of } from 'rxjs';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';
import { RelationshipService } from '@entities/relationship';
import { NgbModalWrapperService } from '@shared/modal/ngb-modal-wrapper.service';
import { EditBusinessOwnerModalComponent } from '../../edit-business-owner/edit-business-owner-modal.component';
import {
    assignRelationshipBusinessOwnerRequest,
    assignRelationshipBusinessOwnerRequestFailed,
    assignRelationshipBusinessOwnerRequestSuccess,
    getRelationshipBusinessOwnerRequest,
    getRelationshipBusinessOwnerRequestFailed,
    getRelationshipBusinessOwnerRequestSuccess,
} from '../actions/business-owner.actions';

@Injectable()
export class BusinessOwnerEffects {
    openAssignBusinessOwnerModal$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(ROUTER_NAVIGATED),
                map((action: RouterNavigationAction) =>
                    action.payload.routerState.root.children.find((route) => route.outlet === 'popup'),
                ),
                filter((route) => !!route && route.routeConfig.path === 'relationships/:requestId/business-owner'),
                map((route) => ({ requestId: route.params.requestId })),
                tap(({ requestId }) =>
                    this._modalService.open<EditBusinessOwnerModalComponent>(
                        EditBusinessOwnerModalComponent,
                        {
                            requestId,
                        },
                        { size: 'md' },
                    ),
                ),
            ),
        { dispatch: false },
    );

    getRelationshipBusinessOwnerRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(getRelationshipBusinessOwnerRequest),
            switchMap(({ requestId }) =>
                this._relationshipService.getBusinessOwner(requestId).pipe(
                    map((response) => response.body),
                    map((businessOwner) => getRelationshipBusinessOwnerRequestSuccess({ businessOwner })),
                    catchError(() => of(getRelationshipBusinessOwnerRequestFailed())),
                ),
            ),
        ),
    );

    assignRelationshipBusinessOwnerRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(assignRelationshipBusinessOwnerRequest),
            switchMap(({ requestId, businessOwner }) =>
                this._relationshipService.assignBusinessOwner(requestId, businessOwner).pipe(
                    map(() => assignRelationshipBusinessOwnerRequestSuccess({ requestId })),
                    catchError(() => of(assignRelationshipBusinessOwnerRequestFailed())),
                ),
            ),
        ),
    );

    constructor(
        private _actions$: Actions,
        private _modalService: NgbModalWrapperService,
        private _relationshipService: RelationshipService,
    ) {}
}
