import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TagManagementComponent } from './tag-management/tag-management.component';
import { TagFormComponent } from './tag-form/tag-form.component';
import { TagDeleteDialogComponent } from './tag-delete-dialog/tag-delete-dialog.component';
import { RouterModule } from '@angular/router';
import { GetTagResolver, tagPopupRoutes, tagRoutes } from './tag-routing.module';
import { SharedModule } from '../../shared';
import { StoreModule } from '@ngrx/store';
import { tagsStateKey } from './redux/tag.state';
import { tagsReducer } from './redux/tag.reducer';
import { TagEffects } from './redux/tag.effects';
import { EffectsModule } from '@ngrx/effects';
import { NgxSelectModule } from 'ngx-select-ex';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

@NgModule({
    declarations: [TagManagementComponent, TagFormComponent, TagDeleteDialogComponent],
    imports: [
        CommonModule,
        RouterModule.forChild([...tagRoutes, ...tagPopupRoutes]),
        SharedModule,
        StoreModule.forFeature(tagsStateKey, tagsReducer),
        EffectsModule.forFeature([TagEffects]),
        NgxSelectModule,
        NgxDatatableModule,
    ],
    providers: [GetTagResolver],
})
export class TagModule {}
