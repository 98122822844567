<div
    class="popover-wrapper fade-element"
    @fadeAnimation
    [style.max-width]="maxWidthStyle"
    [style.max-height]="maxHeightStyle"
    [ngClass]="containerClasses"
    (mouseenter)="onMouseEnter()"
    (mouseleave)="onMouseLeave()"
>
    <div class="popover-content" [ngClass]="arrowClass">
        <ng-container *ngIf="content; else templateRef">{{ content }}</ng-container>
        <ng-template #templateRef>
            <ng-container *ngTemplateOutlet="template; context: { $implicit: context }"></ng-container>
        </ng-template>
    </div>
</div>
