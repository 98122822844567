import { Component } from '@angular/core';
import { SnackbarService } from '../../shared/components/snackbar/snackbar.service';
import { RiskAdvisoryService } from '../../entities/risk-advisory/risk-advisory.service';

@Component({
    selector: 'app-sec-import',
    templateUrl: './sec-import.component.html',
    styleUrls: ['./sec-import.component.scss'],
})
export class SecImportComponent {
    importStarted: boolean;

    constructor(
        private _riskAdvisoryService: RiskAdvisoryService,
        private _snackbarService: SnackbarService,
    ) {}

    startImportProcess() {
        this.importStarted = true;
        this._riskAdvisoryService.startImportProcess().subscribe(() => {
            this._snackbarService.info('SEC 8K Filing import process started. Watch your notifications!');
        });
    }
}
