import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { AppState } from '@shared/redux/state';
import { isPhoneViewport } from '@shared/redux/selectors';
import { getAssessmentHeaderColor, getAssessmentLogoVisible } from '../redux/layout.selectors';
import { isFgBlack } from '../layout.util';

@Component({
    selector: 'app-assessment-header',
    templateUrl: './assessment-header.component.html',
    styleUrl: './assessment-header.component.scss',
})
export class AssessmentHeaderComponent implements OnInit {
    isPhoneResolution$: Observable<boolean>;
    headerColor$: Observable<string>;
    logoVisible$: Observable<boolean>;

    isFgBlack = isFgBlack;

    constructor(private _store$: Store<AppState>) {}

    ngOnInit(): void {
        this.isPhoneResolution$ = this._store$.pipe(select(isPhoneViewport));
        this.headerColor$ = this._store$.pipe(select(getAssessmentHeaderColor));
        this.logoVisible$ = this._store$.pipe(select(getAssessmentLogoVisible));
    }
}
