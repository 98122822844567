import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SnackbarService } from '../../../shared/components/snackbar/snackbar.service';

@Component({
    selector: 'app-recertification-cycle-modal',
    templateUrl: './access-token-modal.component.html',
    styleUrls: ['./access-token-modal.component.scss'],
})
export class AccessTokenModalComponent {
    @Input()
    accessToken: string;

    constructor(
        private _activeModal: NgbActiveModal,
        private _snackbarService: SnackbarService,
    ) {}

    closeModal() {
        this._activeModal.close();
    }

    copyAccessTokenToClipboard(): void {
        navigator.clipboard.writeText(this.accessToken);
        this._snackbarService.success('Copied to clipboard!');
    }
}
