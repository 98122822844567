import { createSelector, MemoizedSelector } from '@ngrx/store';
import { FileArtifact, isFileArtifact, isQuestionnaireArtifact, QuestionnaireArtifact } from '@entities/artifact';
import { AppState } from '../assessment-collection.state';
import { getExpiredArtifactIdsToReplace, getPublicAssessment, getSortedRtpFileArtifacts } from './main.selectors';
import { getAssessmentCreatedDate } from './assessment-data.selectors';
import { ExpiredArtifactReplacement } from '../../models';

export const getFileArtifacts: MemoizedSelector<AppState, FileArtifact[]> = createSelector(
    getPublicAssessment,
    (publicAssessment) => (publicAssessment?.artifacts || []).filter(isFileArtifact),
);

export const getExpiredArtifacts: MemoizedSelector<AppState, FileArtifact[]> = createSelector(
    getFileArtifacts,
    (artifacts) => artifacts?.filter((a) => a.validation?.expired || a.validation?.expiringSoon),
);

export const getExpiredArtifactsUploadedBeforeAssessmentCreation: MemoizedSelector<AppState, FileArtifact[]> =
    createSelector(getExpiredArtifacts, getAssessmentCreatedDate, (expiredArtifacts, assessmentCreatedDate) =>
        expiredArtifacts.filter((artifact) => artifact.validation?.createdDate <= assessmentCreatedDate),
    );

export const getFileArtifactsUploadedAfterAssessmentCreation: MemoizedSelector<AppState, FileArtifact[]> =
    createSelector(getFileArtifacts, getAssessmentCreatedDate, (artifacts, assessmentCreatedDate) =>
        artifacts.filter((artifact) => new Date(artifact.createdDate) > assessmentCreatedDate),
    );

export const getExpiredArtifactReplacements: MemoizedSelector<AppState, ExpiredArtifactReplacement[]> = createSelector(
    getExpiredArtifactsUploadedBeforeAssessmentCreation,
    getSortedRtpFileArtifacts,
    getExpiredArtifactIdsToReplace,
    (existingExpiredArtifacts, newlyUploadedArtifacts, expiredArtifactIdsToReplace) =>
        Array.from(expiredArtifactIdsToReplace.entries()).map(([expiredId, replacementId]) => ({
            expiredFileArtifact: existingExpiredArtifacts.find((a) => a.id === expiredId),
            replacedByRtpFileArtifact: !!replacementId
                ? newlyUploadedArtifacts.find((rtp) => rtp.artifact.id === replacementId)
                : null,
        })),
);

export const getQuestionnaireArtifact: MemoizedSelector<AppState, QuestionnaireArtifact> = createSelector(
    getPublicAssessment,
    (publicAssessment) => (publicAssessment?.artifacts || []).find(isQuestionnaireArtifact),
);

export const areThereUploadedArtifacts: MemoizedSelector<AppState, boolean> = createSelector(
    getFileArtifactsUploadedAfterAssessmentCreation,
    (artifacts) => !!artifacts?.length,
);

export const getIsThereAQuestionnaire: MemoizedSelector<AppState, boolean> = createSelector(
    getQuestionnaireArtifact,
    (questionnaire) => !!questionnaire,
);
