import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
} from '@angular/core';
import { Attachment } from '../../../entities/attachment';
import { VisoUserRole } from '../../../entities/viso-user';
import { FileDownloadActions } from '../../../shared/file-download/redux/actions';
import { AttachmentActions } from '../redux/actions/attachments.actions';

@Component({
    selector: 'app-attachments',
    templateUrl: './attachments.component.html',
    styleUrls: ['./attachments.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttachmentsComponent implements OnChanges {
    @Input()
    attachments: Attachment[];

    @Output()
    deleteAttachment = new EventEmitter<Attachment>();

    @Output()
    downloadAttachment = new EventEmitter<Attachment>();

    @Output()
    onAttachmentSelected: EventEmitter<Map<Attachment, boolean>> = new EventEmitter<Map<Attachment, boolean>>();

    Roles = VisoUserRole;
    AttachmentActions = AttachmentActions;
    FileDownloadActions = FileDownloadActions;

    allAttachmentsSelected: boolean = false;
    anyAttachmentSelected: boolean = false;
    selectedAttachments = new Map<Attachment, boolean>();

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.attachments) {
            setTimeout(() => window.dispatchEvent(new Event('resize')), 100);
        }
    }

    initializeSelectedAttachments() {
        this.attachments.forEach((attachment) => {
            this.selectedAttachments.set(attachment, false);
        });
    }

    selectAllAttachments() {
        if (this.selectedAttachments.size === 0) {
            this.initializeSelectedAttachments();
        }

        this.allAttachmentsSelected = !this.allAttachmentsSelected;

        this.attachments.forEach((attachment) => {
            this.selectedAttachments.set(attachment, this.allAttachmentsSelected);
        });

        this.onAttachmentSelected.emit(this.selectedAttachments);
    }

    attachmentSelected(attachment: Attachment): void {
        this.selectedAttachments.set(attachment, !this.selectedAttachments.get(attachment));

        var values = Array.from(this.selectedAttachments).map(([_, value]) => value);
        if (values.every((val) => val)) {
            this.allAttachmentsSelected = true;
        } else {
            this.allAttachmentsSelected = false;
        }

        this.onAttachmentSelected.emit(this.selectedAttachments);
    }
}
