<div class="ai-qa-wrapper card mt-3">
    <div class="card-header flex-column align-items-start border-bottom">
        <p class="card-title">AI Q&A</p>
        <p>
            Answer any question about this organization's security or privacy posture using our AI engine, powered by
            Artifact Intelligence.
        </p>
    </div>
    <ng-container *ngIf="!!hasArtifactsWithValidations; else noArtifactsWithValidation">
        <div class="card-body">
            <ng-container
                *ngIf="{
                    awaitingQuestionResponse: awaitingQuestionResponse$ | async,
                } as ctx"
            >
                <form [formGroup]="questionFormGroup" (submit)="query()">
                    <div class="form-controls">
                        <textarea
                            id="questionInput"
                            class="form-control"
                            [placeholder]="'Ask a question about ' + vendorName"
                            formControlName="question"
                        ></textarea>
                        <button
                            mat-button
                            visoButton="primary"
                            type="submit"
                            [disabled]="questionFormGroup.invalid || ctx.awaitingQuestionResponse"
                        >
                            Submit
                        </button>
                    </div>
                </form>

                <section class="results" *ngIf="ctx.awaitingQuestionResponse || iiqrResponse">
                    <ng-container *ngIf="!ctx.awaitingQuestionResponse; else loading">
                        <ng-container *ngIf="iiqrResponse">
                            <div class="box question">
                                <div class="question-content">
                                    <h4 class="subtitle2">Question</h4>
                                    <p>{{ iiqrResponse.question }}</p>
                                </div>
                            </div>
                            <div class="box answer">
                                <div class="answer-content">
                                    <h4 class="subtitle2">Answer from {{ vendorName }}</h4>
                                    <p>{{ iiqrResponse.response }}</p>
                                </div>
                                <div class="copy-and-assurance">
                                    <button
                                        class="icon-button"
                                        type="button"
                                        title="copy to clipboard"
                                        (click)="copyAnswerToClipboard()"
                                    >
                                        <em class="far fa-clone fa-flip-vertical"></em>
                                    </button>
                                    <app-assurance-level-meter
                                        *ngIf="iiqrResponse.assuranceLevel"
                                        [assuranceLevel]="iiqrResponse.assuranceLevel"
                                        [hideLabel]="true"
                                    />
                                </div>
                            </div>
                            <ng-container *ngIf="iiqrResponse.validationDetections?.length">
                                <div class="box detections">
                                    <h4 class="subtitle2">Detection references</h4>
                                    <p>
                                        These are the detections identified in the provided artifacts that support the
                                        above response. The assurance of an artifact is indicated with
                                        <strong>Advanced</strong>
                                        <app-assurance-level-meter
                                            class="d-inline-block mx-2"
                                            [hideTooltip]="true"
                                            [hideLabel]="true"
                                            [assuranceLevel]="AssuranceLevels.ADVANCED"
                                        ></app-assurance-level-meter>
                                        as the highest level possible.
                                    </p>
                                    <div class="detections-list">
                                        <app-detection
                                            *ngFor="let detection of iiqrResponse.validationDetections"
                                            [detection]="detection"
                                            class="detection"
                                        />
                                    </div>
                                </div>
                            </ng-container>
                        </ng-container>
                    </ng-container>

                    <ng-template #loading>
                        <section>
                            <div class="d-flex align-items-center justify-content-center">
                                <div class="sk-spinner sk-spinner-pulse"></div>
                            </div>
                        </section>
                    </ng-template>
                </section>
            </ng-container>
        </div>
    </ng-container>

    <ng-template #noArtifactsWithValidation>
        <div class="card-body">
            <div class="justify-content-center align-items-center text-center p-2">
                <div class="text-bold mb-2">
                    <span
                        >In order for us to answer questions about this organization, we need to analyze its
                        artifacts.</span
                    >
                </div>
                <div>
                    <span
                        >Get started now and upload artifacts on the
                        <a class="viso-link" (click)="goToRiskAnalysis.emit()">Risk Analysis tab</a>.</span
                    >
                </div>
                <div class="mb-3">
                    <span>If you've already uploaded artifacts, come back here once they've been analyzed.</span>
                </div>
            </div>
        </div>
    </ng-template>
</div>
