import { createAction, props } from '@ngrx/store';
import { VisoUser } from 'src/main/webapp/app/entities/viso-user';

export enum SubscriberActions {
    OpenSubscribeContactModal = '[Request] Open Subscribe Contact Modal',
    CreateAndSubscribeContactRequest = '[Request] Create And Subscribe Contact Request',
    CreateAndSubscribeContactRequestFailed = '[Request] Create And Subscribe Contact Request Failed',
}

export const openSubscribeContactModal = createAction(
    SubscriberActions.OpenSubscribeContactModal,
    props<{ requestId: number }>(),
);

export const createAndSubscribeContactRequest = createAction(
    SubscriberActions.CreateAndSubscribeContactRequest,
    props<{ requestId: number; contact: VisoUser }>(),
);

export const createAndSubscribeContactRequestFailed = createAction(
    SubscriberActions.CreateAndSubscribeContactRequestFailed,
);
