import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { BREADCRUMB_CONTAINER_TOKEN } from '@shared/dynamic-content/dynamic-content-injector';
import { select, Store } from '@ngrx/store';
import { getProfileRiskTolerance, getRiskLevelDisplayNamesForClient } from '../session/redux/session.selectors';
import { Observable, Subject } from 'rxjs';
import { RelationshipRiskToleranceView, RiskTolerance } from '@entities/risk-tolerance';
import {
    getRelationshipRiskDistribution,
    getRiskToleranceThresholds,
    RiskThresholdData,
} from './redux/risk-model-settings.selectors';
import { Risk } from '@entities/risk-assessment';
import { loadRiskToleranceSettings, openCustomizeRiskToleranceDialog } from './redux/risk-model-settings.actions';
import { OrgRiskConfig } from '../../admin/client-profile/client-profile.model';

@Component({
    selector: 'app-risk-model-settings',
    templateUrl: './risk-model-settings.component.html',
    styleUrls: ['./risk-model-settings.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RiskModelSettingsComponent implements OnInit, OnDestroy {
    public readonly breadcrumbsContainerToken = BREADCRUMB_CONTAINER_TOKEN;

    clientRiskTolerance$: Observable<RiskTolerance>;
    riskLevelDisplayNames$: Observable<OrgRiskConfig[]>;
    riskToleranceThresholds$: Observable<Map<RiskTolerance, Map<Risk, RiskThresholdData>>>;
    relationshipRiskDistribution$: Observable<Map<Risk, RelationshipRiskToleranceView[]>>;

    private _unsub$: Subject<void> = new Subject<void>();

    constructor(private _store$: Store) {}

    ngOnInit(): void {
        this.clientRiskTolerance$ = this._store$.pipe(select(getProfileRiskTolerance));
        this.riskLevelDisplayNames$ = this._store$.pipe(select(getRiskLevelDisplayNamesForClient));
        this.riskToleranceThresholds$ = this._store$.pipe(select(getRiskToleranceThresholds));
        this.relationshipRiskDistribution$ = this._store$.pipe(select(getRelationshipRiskDistribution));
        this.loadRiskToleranceSettings();
    }

    loadRiskToleranceSettings(): void {
        this._store$.dispatch(loadRiskToleranceSettings());
    }

    openCustomizeRiskToleranceDialog() {
        this._store$.dispatch(openCustomizeRiskToleranceDialog());
    }

    ngOnDestroy() {
        this._unsub$.next();
    }
}
