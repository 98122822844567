import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Router } from '@angular/router';

import { Subject } from 'rxjs';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { BusinessUnitManagementView } from '../../entities/business-unit';
import { VisoUserRole } from '../../entities/viso-user';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { tap, takeUntil } from 'rxjs/operators';
import {
    createBusinessUnitRequestSuccess,
    updateBusinessUnitRequestSuccess,
    createBusinessUnitRequestFailed,
    updateBusinessUnitRequestFailed,
    updateBusinessUnitRequest,
    createBusinessUnitRequest,
} from '../business-unit-management/redux/business-unit-management.actions';
import { noWhitespaceValidator } from '../../shared/validators/whitespace-validator';
import { FormUtilsService } from '../../shared/utils/form-utils.service';

@Component({
    selector: 'app-business-unit-dialog',
    templateUrl: './business-unit-dialog.component.html',
})
export class BusinessUnitDialogComponent implements OnInit, OnDestroy {
    @Input()
    businessUnit: BusinessUnitManagementView;

    isSaving: boolean;

    Roles = VisoUserRole;

    businessUnitForm: UntypedFormGroup;

    private _unsub = new Subject<void>();

    constructor(
        private _activeModal: NgbActiveModal,
        private _fb: UntypedFormBuilder,
        private _actions$: Actions,
        private _store$: Store,
        private _router: Router,
        private _formUtils: FormUtilsService,
    ) {}

    ngOnInit() {
        this.businessUnitForm = this._fb.group({
            id: [this.businessUnit?.id],
            name: [this.businessUnit?.name || '', [Validators.required, noWhitespaceValidator]],
            orgId: [this.businessUnit?.orgId],
        });

        this.isSaving = false;

        this._actions$
            .pipe(
                ofType(createBusinessUnitRequestSuccess, updateBusinessUnitRequestSuccess),
                tap(() => {
                    this.isSaving = false;
                    this._activeModal.dismiss();
                }),
                takeUntil(this._unsub),
            )
            .subscribe();

        this._actions$
            .pipe(
                ofType(createBusinessUnitRequestFailed, updateBusinessUnitRequestFailed),
                tap(() => (this.isSaving = false)),
                takeUntil(this._unsub),
            )
            .subscribe();
    }

    clear() {
        this._activeModal.dismiss('cancel');
    }

    save() {
        this.isSaving = true;
        const businessUnit = this._formUtils.getCleanFormGroupValue<BusinessUnitManagementView>(
            this.businessUnitForm,
            true,
        );
        if (this.businessUnit?.id) {
            this._store$.dispatch(updateBusinessUnitRequest({ businessUnit }));
        } else {
            this._store$.dispatch(createBusinessUnitRequest({ businessUnit }));
        }
    }

    transferDismissModal() {
        this.clear();
        setTimeout(() => this._router.navigate(this.transferBusinessUnitRelationships), 50);
    }

    get transferBusinessUnitRelationships(): any[] {
        return [
            '/',
            {
                outlets: {
                    popup: `business-unit/${this.businessUnit?.id}/transfer`,
                },
            },
        ];
    }

    getSelected(selectedVals: Array<any>, option: any) {
        if (selectedVals) {
            for (let i = 0; i < selectedVals.length; i++) {
                if (option.id === selectedVals[i].id) {
                    return selectedVals[i];
                }
            }
        }
        return option;
    }

    ngOnDestroy(): void {
        this._unsub.next();
    }
}
