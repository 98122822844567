import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, merge, Observable, of, Subject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { UserStatus, VisoUserRole } from '../../../entities/viso-user';
import { Actions, ofType } from '@ngrx/effects';
import {
    deleteSupplementalQuestionnaireRequest,
    deleteSupplementalQuestionnaireRequestSuccess,
    downloadOriginalSupplementalQuestionnaireRequest,
    getSupplementalQuestionnairesRequest,
    getSupplementalQuestionnairesRequestFailed,
    getSupplementalQuestionnairesRequestSuccess,
    updateSupplementalQuestionnaireConfigRequest,
    updateSupplementalQuestionnaireConfigRequestFailed,
    updateSupplementalQuestionnaireConfigRequestSuccess,
    uploadSupplementalQuestionnairesRequest,
    uploadSupplementalQuestionnairesRequestFailed,
    uploadSupplementalQuestionnairesRequestSuccess,
} from './redux/supplemental-questionnaire-management.actions';
import { filter, map, take, takeUntil, tap } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../shared/redux/state';
import { HttpHeaders } from '@angular/common/http';
import { BREADCRUMB_CONTAINER_TOKEN } from '../../../shared/dynamic-content/dynamic-content-injector';
import {
    ArtifactOwnershipLevel,
    ArtifactType,
    SupplementalQuestionnaireResponse,
    UploadFileArtifactRequest,
    ValidationResponseStatus,
} from '../../../entities/artifact';
import { FileLikeObject, FileUploader } from '../../../shared/file-upload';
import { SnackbarService } from '../../../shared/components/snackbar/snackbar.service';
import { UpdateSupplementalQuestionnaireConfigRequest } from './model/supplemental-questionnaire-models';
import { TableSortItem } from '../../../shared/ngx-datables/table-sort-item';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { getUserAuthority } from '../../session/redux/session.selectors';

@Component({
    selector: 'app-supplemental-questionnaire-management',
    templateUrl: './supplemental-questionnaire-management.component.html',
    styleUrls: ['./supplemental-questionnaire-management.component.scss'],
})
export class SupplementalQuestionnaireManagementComponent implements OnInit, OnDestroy {
    @Input()
    set isTabSelected(value: boolean) {
        this._isTabSelected$.next(value);
    }

    get isTabSelected() {
        return this._isTabSelected$.value;
    }

    public readonly breadcrumbsContainerToken = BREADCRUMB_CONTAINER_TOKEN;
    @ViewChild('questionnaireList') questionnaireList: DatatableComponent;

    Roles = VisoUserRole;
    UserStatus = UserStatus;

    questionnairesLoading: boolean;

    queryCount: number;
    itemsPerPage: number;
    totalCurrentItems: number;
    page: number;
    predicate: any;
    reverse: any;
    supplementalQuestionnaires$ = new BehaviorSubject<SupplementalQuestionnaireResponse[]>([]);
    numberOfQuestionnairesHeader$ = new BehaviorSubject<string>('0 Questionnaires');

    tableSort$: BehaviorSubject<TableSortItem[]> = new BehaviorSubject<TableSortItem[]>([
        {
            prop: 'updatedDate',
            dir: 'desc',
        },
    ]);

    uploader: FileUploader;
    uploadingFiles$: Observable<boolean>;
    isCurrentUserOrgAdmin: boolean;

    protected readonly ArtifactType = ArtifactType;

    private _isTabSelected$ = new BehaviorSubject<boolean>(false);
    private _unsub$ = new Subject<void>();

    constructor(
        private _actions$: Actions,
        private _store$: Store<AppState>,
        private _route: ActivatedRoute,
        private _snackbarService: SnackbarService,
        private _cdr: ChangeDetectorRef,
    ) {
        this.itemsPerPage = 20;
        this.page = 0;
    }

    ngOnInit() {
        this._store$
            .pipe(select(getUserAuthority(VisoUserRole.OrgAdmin)), takeUntil(this._unsub$))
            .subscribe((isCurrentUserOrgAdmin) => {
                this.isCurrentUserOrgAdmin = isCurrentUserOrgAdmin;
            });

        this._actions$
            .pipe(
                ofType(
                    uploadSupplementalQuestionnairesRequestSuccess,
                    deleteSupplementalQuestionnaireRequestSuccess,
                    updateSupplementalQuestionnaireConfigRequestSuccess,
                    updateSupplementalQuestionnaireConfigRequestFailed,
                ),
                tap(() => {
                    this.loadAll();
                }),
                takeUntil(this._unsub$),
            )
            .subscribe();

        this._actions$
            .pipe(
                ofType(getSupplementalQuestionnairesRequestSuccess, getSupplementalQuestionnairesRequestFailed),
                tap(() => {
                    this.questionnairesLoading = false;
                }),
                takeUntil(this._unsub$),
            )
            .subscribe();

        this._actions$
            .pipe(
                ofType(getSupplementalQuestionnairesRequestSuccess),
                filter(({ headers }) => !!headers.get('x-total-count')),
                takeUntil(this._unsub$),
            )
            .subscribe(({ questionnaires, headers }) => {
                const totalQuestionnaireCount = +headers.get('x-total-count');
                this.numberOfQuestionnairesHeader$.next(
                    `${totalQuestionnaireCount} ${totalQuestionnaireCount == 1 ? 'Questionnaire' : 'Questionnaires'} `,
                );
                this.onSuccess(questionnaires, headers);
            });

        this.tableSort$
            .pipe(
                map((sortData) => sortData && sortData[0]),
                takeUntil(this._unsub$),
            )
            .subscribe((sortItem) => {
                this.predicate = sortItem.prop;
                this.reverse = sortItem.dir !== 'desc';
            });

        this._route.queryParams.pipe(take(1)).subscribe(({ sort, page }) => {
            const sortPredicateFromUrl: string = !!sort ? (Array.isArray(sort) ? sort[0] : sort) : undefined;

            if (sortPredicateFromUrl) {
                const [prop, dir] = sortPredicateFromUrl.split(',');
                this.tableSort$.next([{ prop, dir }]);
            }

            this.page = +page || this.page;
        });

        this.uploader = new FileUploader({
            url: '',
            maxFileSize: 100000000,
        });

        this.uploader.onAfterAddingAll = () => {
            this.upload();
        };

        this.uploader.onWhenAddingFileFailed = (item: FileLikeObject, filter: any, options: any) => {
            let errorMessage;
            if (filter.name === 'fileSize') {
                errorMessage = 'File too big. Max size: 100MB';
            } else {
                errorMessage = `Filter failed: ${filter.name}`;
            }
            this._snackbarService.error(errorMessage);
        };

        this.uploadingFiles$ = merge(
            of(false),
            this._actions$.pipe(
                ofType(uploadSupplementalQuestionnairesRequest),
                map(() => true),
            ),
            this._actions$.pipe(
                ofType(uploadSupplementalQuestionnairesRequestSuccess, uploadSupplementalQuestionnairesRequestFailed),
                map(() => false),
            ),
        );

        this._actions$
            .pipe(
                ofType(uploadSupplementalQuestionnairesRequestSuccess, uploadSupplementalQuestionnairesRequestFailed),
                tap(() => {
                    this.uploader.clearQueue();
                }),
                takeUntil(this._unsub$),
            )
            .subscribe();

        this._isTabSelected$
            .pipe(
                filter((isTabSelected) => isTabSelected),
                take(1),
            )
            .subscribe(() => this.loadAll());
    }

    upload(): void {
        const fileRequests = this.getFileArtifactUploadRequests();
        this._store$.dispatch(
            uploadSupplementalQuestionnairesRequest({
                fileRequests,
                urlRequests: [],
            }),
        );
    }

    loadAll() {
        this.questionnairesLoading = true;
        this._store$.dispatch(
            getSupplementalQuestionnairesRequest({
                pageable: {
                    page: this.page,
                    size: this.itemsPerPage,
                    sort: this.sort(),
                },
            }),
        );
    }

    setCurrentPage({ page }) {
        this.page = +page - 1;
        this.loadAll();
    }

    onSort({ sorts }) {
        this.predicate = sorts[0].prop;
        this.reverse = sorts[0].dir !== 'desc';
        this.questionnaireList.offset = this.page;
        this.loadAll();
    }

    getRowInactiveClass = (questionnaire: SupplementalQuestionnaireResponse) => ({
        inactive: questionnaire.validationResponseStatus !== ValidationResponseStatus.PROCESSED,
    });

    doesQuestionnaireHaveParsedQuestions(questionnaire: SupplementalQuestionnaireResponse) {
        return (
            questionnaire.parsedQuestionCount > 0 &&
            questionnaire.validationResponseStatus === ValidationResponseStatus.PROCESSED &&
            !!questionnaire.parsedQuestionnaireId
        );
    }

    downloadOriginalQuestionnaire(questionnaireToDownload: SupplementalQuestionnaireResponse) {
        this._store$.dispatch(
            downloadOriginalSupplementalQuestionnaireRequest({
                questionnaireId: questionnaireToDownload.originalQuestionnaireId,
            }),
        );
    }

    deleteQuestionnaire(questionnaireToDelete: SupplementalQuestionnaireResponse) {
        this._store$.dispatch(
            deleteSupplementalQuestionnaireRequest({ questionnaireId: questionnaireToDelete.originalQuestionnaireId }),
        );
    }

    private onSuccess(data: SupplementalQuestionnaireResponse[], headers: HttpHeaders): void {
        this.totalCurrentItems = +headers.get('x-total-count');
        this.queryCount = data.length;

        this.supplementalQuestionnaires$.next(data);

        this._cdr.detectChanges();
    }

    private sort() {
        const result = [
            'sqs.originalQuestionnaireFileArtifact.' + this.predicate + ',' + (this.reverse ? 'asc' : 'desc'),
        ];
        if (this.predicate !== 'id') {
            result.push('id');
        }
        return result;
    }

    private getFileArtifactUploadRequests(): UploadFileArtifactRequest[] {
        return this.uploader.queue.map<UploadFileArtifactRequest>((queueFile) => ({
            file: queueFile._file,
            fileArtifactMetaData: {
                artifactOwnershipType: ArtifactOwnershipLevel.PRIVATE,
                source: '',
                thirdPartyEmail: '',
                artifactPassword: queueFile.formData?.password,
            },
        }));
    }

    onIncludeInCollectionChanged(supplementalQ: SupplementalQuestionnaireResponse) {
        const payload: UpdateSupplementalQuestionnaireConfigRequest = {
            originalQuestionnaireId: supplementalQ.originalQuestionnaireId,
            answerAutomatically: supplementalQ.answerAutomatically,
            includeInCollectionRequest: !supplementalQ.includeInCollectionRequest,
        };
        this.updateSupplementalQuestionnaireConfig(payload);
    }

    onAnswerAutomaticallyChanged(supplementalQ: SupplementalQuestionnaireResponse) {
        const payload: UpdateSupplementalQuestionnaireConfigRequest = {
            originalQuestionnaireId: supplementalQ.originalQuestionnaireId,
            answerAutomatically: !supplementalQ.answerAutomatically,
            includeInCollectionRequest: supplementalQ.includeInCollectionRequest,
        };
        this.updateSupplementalQuestionnaireConfig(payload);
    }

    updateSupplementalQuestionnaireConfig(updateRequest: UpdateSupplementalQuestionnaireConfigRequest) {
        this._store$.dispatch(updateSupplementalQuestionnaireConfigRequest({ updateRequest }));
    }

    ngOnDestroy(): void {
        this._unsub$.next();
    }
}
