import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { AuditReportTypeCode } from '@entities/audit-report';
import { PublicAssessmentRTPFileArtifact } from '../../../models';
import { AnimationState } from '../file-artifact-item-animations/file-artifact-item-animations.model';

@Component({
    selector: 'app-file-artifact-item',
    templateUrl: './file-artifact-item.component.html',
    styleUrls: ['./file-artifact-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileArtifactItemComponent {
    @Input({ required: true })
    fileArtifactItem: PublicAssessmentRTPFileArtifact;

    @Input()
    auditTypeToReplace: AuditReportTypeCode;

    @Output()
    setPassword = new EventEmitter<string>();

    @Output()
    remove = new EventEmitter<void>();

    documentPasswordFormControl = new FormControl<string>('');
    hidePassword = true;

    get replacesAnotherArtifact(): boolean {
        return !!this.auditTypeToReplace;
    }

    get artifactName(): string {
        return this.fileArtifactItem.artifact.fileName;
    }

    get passwordFormControlHasValue(): boolean {
        return !!this.documentPasswordFormControl.value;
    }

    get needsPassword(): boolean {
        return this.fileArtifactItem.needsPassword;
    }

    get rtpUnableToClassify(): boolean {
        return this.fileArtifactItem.rtpUnableToClassify;
    }

    get artifactIsPlaceholder(): boolean {
        return !this.fileArtifactItem.artifact?.id;
    }

    get initialAnimationState(): AnimationState {
        const auditReportTypeCode = this.fileArtifactItem.auditReportTypeCode;
        if (!!auditReportTypeCode && auditReportTypeCode !== AuditReportTypeCode.NONE) {
            if (this.fileArtifactItem.justUploaded) {
                return AnimationState.PRE_REVEAL;
            }
            return AnimationState.REVEALED;
        }
        return AnimationState.WAITING;
    }

    emitSetPassword(): void {
        if (!!this.documentPasswordFormControl.value.length) {
            this.setPassword.emit(this.documentPasswordFormControl.value);
        }
    }
}
