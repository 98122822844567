import { MemoizedSelector, createSelector } from '@ngrx/store';
import { AuthenticationFailedReasons } from '../../models';
import { AppState } from '../assessment-collection.state';
import { getAssessmentCollectionState } from './main.selectors';

export const getIsAuthenticating: MemoizedSelector<AppState, boolean> = createSelector(
    getAssessmentCollectionState,
    (state) => state.isAuthenticating,
);

export const getAuthenticationFailedReason: MemoizedSelector<AppState, AuthenticationFailedReasons> = createSelector(
    getAssessmentCollectionState,
    (state) => state.authenticationFailedReason,
);

export const getInvalidLoginError: MemoizedSelector<AppState, boolean> = createSelector(
    getAuthenticationFailedReason,
    (authenticationFailedReason) => authenticationFailedReason === AuthenticationFailedReasons.INVALID_SECRET,
);

export const getExpiredAssessmentError: MemoizedSelector<AppState, boolean> = createSelector(
    getAuthenticationFailedReason,
    (authenticationFailedReason) => authenticationFailedReason === AuthenticationFailedReasons.EXPIRED,
);
