import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Params } from '@angular/router';
import { Relationship } from '../../../entities/relationship';
import { VisoUser, VisoUserRole } from '../../../entities/viso-user';
import { AssessmentStatus } from '../../../entities/assessment';
import { BREADCRUMB_CONTAINER_TOKEN } from '../../../shared/dynamic-content/dynamic-content-injector';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent {
    public readonly breadcrumbsContainerToken = BREADCRUMB_CONTAINER_TOKEN;
    @Input()
    request: Relationship;

    @Input()
    latestAssessmentStatus: AssessmentStatus;

    @Input()
    previousNavigatedUrlQueryParams: Params;

    @Input()
    isPreviousViewFiltered: boolean;

    @Input()
    onPrint: boolean;

    @Input()
    isUpForRecertification: boolean;

    @Input({ required: true })
    businessOwner: VisoUser;

    @Input()
    currentUser: VisoUser;

    @Input()
    hasOrgUserOnboardEnabled: boolean;

    @Input()
    hasLicenseManagementEnabled: boolean;

    @Output()
    onboard = new EventEmitter<void>();

    @Output()
    archive = new EventEmitter<void>();

    @Output()
    markAsNotOnboarded = new EventEmitter<void>();

    @Output()
    startAssessment = new EventEmitter<void>();

    @Output()
    confirmRecertifyAssessment = new EventEmitter<void>();

    @Output()
    downloadPDF = new EventEmitter<void>();

    @Output()
    openCreateRiskAdvisory = new EventEmitter<void>();

    Roles = VisoUserRole;
}
