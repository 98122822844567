import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import {
    Artifact,
    ArtifactOwnershipLevel,
    ArtifactRtpValidationStatus,
    ArtifactType,
    ArtifactValidationStatus,
    ArtifactValidationStatusLabels,
    AssociationType,
    ControlValidationStatus,
    UrlArtifact,
    URLArtifactConversionStatus,
    URLArtifactConversionStatusLabels,
} from '@entities/artifact';
import { VisoUser, VisoUserRole } from '@entities/viso-user';
import { AuditReportTypeCode } from '@entities/audit-report';
import { ArtifactUtilsService } from '@shared/utils/artifact-utils.service';
import { ARTIFACT_ACTIONS_CONTAINER_TOKEN } from '../../../tokens';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { Relationship } from 'src/main/webapp/app/entities/relationship';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, SortDirection } from '@angular/material/sort';
import { select, Store } from '@ngrx/store';
import { getUserAuthority } from '../../../../../routes/session/redux/session.selectors';
import {
    RTPArtifactAvScanned,
    RTPArtifactEvent,
    RTPEvent,
    RTPEventType,
    RTPPageDetectionsClassified,
} from '@entities/rtp';
import { isFileTabular } from '@shared/utils/file-utils.service';

export interface MappedArtifact extends Artifact {
    supersededByArtifact: Artifact;
    supersededArtifact: Artifact;
    controlsValidatedCount: number;
}

enum ArtifactRtpValidationStatusLabels {
    ANALYZING = 'Analyzing',
    ANALYZED = 'Analyzed',
    ERRORED = 'Analyzed',
}

@Component({
    selector: 'app-artifacts-list',
    templateUrl: './artifacts-list.component.html',
    styleUrls: ['./artifacts-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArtifactsListComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input()
    set artifacts(artifacts: Artifact[]) {
        this._artifacts$.next(artifacts);
    }

    @Input({ required: true })
    set artifactSupersession(artifactSupersession: Map<number, number>) {
        this._artifactSupersession$.next(artifactSupersession);
    }

    @Input()
    set artifactRtpEventMap(artifactRtpEvents: Map<number, RTPEvent>) {
        this._artifactRtpEvents$.next(artifactRtpEvents);
    }

    @Input()
    request: Relationship;

    @Input()
    assessmentId?: number;

    @Input()
    currentAccount: VisoUser;

    @Input()
    validate = false;

    @Input()
    auditable = false;

    @Input()
    showEmptyTable = false;

    @Input()
    messages: any;

    @Input()
    currentAssessmentCreatedDate: Date;

    @Input()
    includeAssociation: boolean;

    @Output()
    validateArtifact = new EventEmitter<Artifact>();

    @Output()
    editArtifact = new EventEmitter<Artifact>();

    @Output()
    deleteArtifact = new EventEmitter<Artifact>();

    @Output()
    openArtifact = new EventEmitter<{ artifactId: number; artifactName: string }>();

    Roles = VisoUserRole;
    ArtifactValidationStatus = ArtifactValidationStatus;
    ArtifactType = ArtifactType;
    ArtifactOwnershipLevel = ArtifactOwnershipLevel;
    ArtifactRtpValidationStatus = ArtifactRtpValidationStatus;

    artifacts$: Observable<MappedArtifact[]>;
    dataSource = new MatTableDataSource<MappedArtifact>();

    @ViewChild(MatSort, { static: false }) sorter: MatSort;

    columnDefinitions$: Observable<string[]>;

    columnDefinitions = [
        'artifactType',
        'artifactName',
        'validationStatus',
        'updatedDate',
        'source',
        'association',
        'assuranceLevel',
        'superseding',
        'pdfStatus',
        'expirationDate',
        'uploadedDate',
        'controlsValidatedCount',
        'subservicer',
        'auditingBody',
        'actions',
    ];

    columnHeaders = {
        artifactType: 'Artifact Type',
        artifactName: 'Artifact Name',
        validationStatus: 'Validation Status',
        source: 'Source',
        updatedDate: 'Last Updated',
        association: 'Association',
        assuranceLevel: 'Assurance Level',
        superseding: 'Superseding',
        pdfStatus: 'PDF Status',
        expirationDate: 'Valid Until',
        uploadedDate: 'Uploaded',
        controlsValidatedCount: 'Domains Validated',
        subservicer: 'Subservicer',
        auditingBody: 'Auditing Body',
        actions: 'Actions',
    };

    sortByColumnName: string = 'updatedDate';
    sortDirection: SortDirection = 'desc';

    private isoAuditReportTypes: AuditReportTypeCode[] = [
        AuditReportTypeCode.ISOIEC2700127002,
        AuditReportTypeCode.ISO27001CERTONLY,
    ];
    readonly artifactActionsContainerToken = ARTIFACT_ACTIONS_CONTAINER_TOKEN;

    private _artifactRtpEvents$ = new BehaviorSubject<Map<number, RTPEvent>>(new Map());
    private _artifacts$ = new BehaviorSubject<Artifact[]>([]);
    private _artifactSupersession$ = new BehaviorSubject<Map<number, number>>(new Map<number, number>());
    private _unsub$ = new Subject<void>();

    constructor(
        private _artifactUtils: ArtifactUtilsService,
        private _store$: Store,
    ) {}

    ngOnInit(): void {
        this.columnDefinitions$ = this._store$.pipe(
            select(getUserAuthority(VisoUserRole.Auditor)),
            map((isCurrentUserAuditor) => {
                let filteredColumns = this.columnDefinitions;

                if (isCurrentUserAuditor) {
                    // If user is an auditor, filter out 'auditingBody'
                    filteredColumns = filteredColumns.filter((col) => col !== 'auditingBody');
                } else {
                    // If user is not an auditor, filter out the next columns
                    filteredColumns = filteredColumns.filter(
                        (col) =>
                            ![
                                'superseding',
                                'pdfStatus',
                                'updatedDate',
                                'source',
                                'auditingBody',
                                'subservicer',
                            ].includes(col),
                    );
                    this.columnHeaders.artifactType = 'Type';
                    this.columnHeaders.artifactName = 'Name';
                    this.columnHeaders.assuranceLevel = 'Assurance';
                    this.columnHeaders.validationStatus = 'Status';
                }

                if (!this.includeAssociation) {
                    // If includeAssociation is false, filter out 'association'
                    filteredColumns = filteredColumns.filter((col) => col !== 'association');
                }

                return filteredColumns;
            }),
            takeUntil(this._unsub$),
        );

        this.artifacts$ = combineLatest([
            this._artifacts$,
            this._artifactSupersession$,
            this._artifactRtpEvents$,
            this._store$.pipe(select(getUserAuthority([VisoUserRole.Auditor, VisoUserRole.Support]))),
        ]).pipe(
            map(([artifacts, artifactSupersessionMap, artifactRtpEvents, isAuditorOrSupport]) => ({
                artifacts,
                artifactSupersessionMap,
                artifactSupersessionMapReversed: new Map<number, number>(
                    Array.from(artifactSupersessionMap, (a) => a.reverse() as [number, number]),
                ),
                artifactMap: new Map<number, Artifact>(artifacts.map((a) => [a.id, a])),
                artifactRtpEvents,
                isAuditorOrSupport,
            })),
            map(
                ({
                    artifacts,
                    artifactSupersessionMap,
                    artifactSupersessionMapReversed,
                    artifactMap,
                    artifactRtpEvents,
                    isAuditorOrSupport,
                }) =>
                    artifacts
                        .filter((artifact) =>
                            isAuditorOrSupport ? true : artifact.type !== ArtifactType.ATTESTATION_ARTIFACT,
                        )
                        .map<MappedArtifact>((artifact) => {
                            const supersededArtifact = artifactMap.get(artifactSupersessionMap.get(artifact.id));
                            const supersededByArtifact = artifactMap.get(
                                artifactSupersessionMapReversed.get(artifact.id),
                            );
                            const controlDomainIds = artifact.validation?.detectedControls
                                .filter((x) => x.controlValidationStatus !== ControlValidationStatus.UNVALIDATED)
                                .map((c) => c.controlDomainId);
                            const uniqueControlDomainIds = new Set([...(controlDomainIds || [])]);
                            let controlsValidatedCount = uniqueControlDomainIds.size;
                            const association = this.includeAssociation
                                ? new Date(this.currentAssessmentCreatedDate) <= artifact.createdDate
                                    ? AssociationType.ASSESSMENT
                                    : AssociationType.RELATIONSHIP
                                : null;

                            const latestRtpEvent = artifactRtpEvents.get(artifact.id);
                            if (latestRtpEvent) {
                                switch (latestRtpEvent.eventType) {
                                    case RTPEventType.RTP_ARTIFACT_AV_SCANNED:
                                        const avScannedEvent = latestRtpEvent as RTPArtifactAvScanned;
                                        artifact.avStatus = avScannedEvent.artifact?.avStatus;
                                        artifact.validation = avScannedEvent.artifact?.validation;
                                        break;
                                    case RTPEventType.RTP_ARTIFACT_CLASSIFIED:
                                    case RTPEventType.RTP_PUBLISH_DATE_SET:
                                        const rtpArtifactEvent = latestRtpEvent as RTPArtifactEvent;
                                        artifact.validation = rtpArtifactEvent.artifact?.validation;
                                        break;
                                    case RTPEventType.RTP_PAGE_DETECTIONS_CLASSIFIED:
                                    case RTPEventType.RTP_ARTIFACT_PAGES_COMPLETED:
                                        const artifactPageDetectionsEvent =
                                            latestRtpEvent as RTPPageDetectionsClassified;
                                        controlsValidatedCount =
                                            artifactPageDetectionsEvent.validatedControlDomainIds?.length;
                                        artifact.validation.rtpStatus = artifactPageDetectionsEvent.rtpStatus;
                                        break;
                                    case RTPEventType.RTP_ARTIFACT_ERRORED:
                                        artifact.validation.rtpStatus = ArtifactRtpValidationStatus.ERRORED;
                                        break;
                                }
                            }

                            return {
                                ...artifact,
                                supersededByArtifact,
                                supersededArtifact,
                                controlsValidatedCount,
                                association,
                            };
                        }),
            ),
        );

        this.artifacts$.pipe(takeUntil(this._unsub$)).subscribe((artifacts) => {
            this.dataSource.data = artifacts;
        });
    }

    ngAfterViewInit(): void {
        this.dataSource.sort = this.sorter;
        this.dataSource.sortingDataAccessor = (artifact, property) => {
            switch (property) {
                case 'artifactType':
                    return this.getArtifactDisplayName(artifact);
                case 'artifactName':
                    return ((artifact as UrlArtifact).url || artifact.fileName).toLowerCase();
                case 'source':
                    return this.getSourceTooltipText(artifact);
                case 'assuranceLevel':
                    return artifact.validation?.auditReportAssurance;
                case 'superseding':
                    return artifact.supersededArtifact?.fileName;
                case 'pdfStatus':
                    return this.getUrlConversionStatusLabel(artifact);
                case 'expirationDate':
                    return artifact.validation?.expirationDate;
                case 'validationStatus':
                    return this.getValidationStatusLabel(artifact);
                case 'auditingBody':
                    return artifact.validation?.auditingBodyName;
                case 'subservicer':
                    return artifact.validation?.subservicerName;
                default:
                    return artifact[property];
            }
        };
    }

    canDeleteArtifact = (user: VisoUser, artifact: MappedArtifact) => {
        return this._artifactUtils.canUserDeleteArtifact(user, artifact);
    };

    isArtifactStatusNotRequired(artifact: MappedArtifact): boolean {
        if (!artifact.validation) {
            return false;
        }
        return artifact.validation?.status === ArtifactValidationStatus.NOT_REQUIRED;
    }

    isArtifactExpiringSoonOrExpired(artifact: MappedArtifact): boolean {
        if (!artifact.validation || this.isArtifactStatusNotRequired(artifact) || this.isArtifactSuperseded(artifact)) {
            return false;
        }
        return artifact.validation.expiringSoon || artifact.validation.expired;
    }

    getArtifactExpireTooltip(artifact: MappedArtifact): string {
        if (!artifact.validation || this.isArtifactStatusNotRequired(artifact) || this.isArtifactSuperseded(artifact)) {
            return '';
        }
        if (artifact.validation.expiringSoon) {
            return 'This artifact is expiring soon';
        }
        if (artifact.validation.expired) {
            if (this.isoAuditReportTypes.includes(artifact.validation.auditReportType)) {
                return 'This artifact is subject to an annual surveillance audit so this date may be different than the ISO Recertification date';
            } else {
                return 'This artifact is expired';
            }
        }
    }

    getUrlConversionStatusLabel(artifact: Artifact): string {
        if (artifact.type === ArtifactType.URL_ARTIFACT) {
            const urlArtifact = artifact as UrlArtifact;
            return !!urlArtifact.conversionStatus
                ? URLArtifactConversionStatusLabels[URLArtifactConversionStatus[urlArtifact.conversionStatus]]
                : 'In Progress';
        }
        return '-';
    }

    onSelect(artifact: Artifact): void {
        if (!this.validate) {
            this.emitArtifactEvent(artifact);
        }
    }

    onSupersedesSelect(supersededArtifactId: number): void {
        if (!this.validate) {
            const supersededArtifact = this._artifacts$.value.find((a) => a.id === supersededArtifactId);
            this.emitArtifactEvent(supersededArtifact);
        }
    }

    getArtifactDisplayName = (artifact: MappedArtifact): string => {
        return this._artifactUtils.getArtifactListDisplayName(artifact);
    };

    getRowInactiveClass = (artifact: MappedArtifact) => !!artifact.supersededByArtifact;

    openArtifactFile(artifactId: number, artifactName: string) {
        this.openArtifact.emit({ artifactId, artifactName });
    }

    getSourceTooltipText(artifact: MappedArtifact): string {
        switch (artifact.ownership?.ownershipType) {
            case ArtifactOwnershipLevel.THIRD_PARTY:
                return 'Collected from the third party';
            case ArtifactOwnershipLevel.PUBLIC:
                return 'Publicly collected';
            case ArtifactOwnershipLevel.VISO:
                return 'Collected from VISO TRUST';
            case ArtifactOwnershipLevel.TRUST_SUBSCRIPTION:
            case ArtifactOwnershipLevel.PRIVATE:
                return 'Collected from the client';
            default:
                return '';
        }
    }

    private isArtifactSuperseded(artifact: MappedArtifact): boolean {
        return !!artifact.supersededByArtifact;
    }

    private emitArtifactEvent(artifact: Artifact): void {
        if (artifact.validation) {
            this.editArtifact.emit(artifact);
        } else {
            this.validateArtifact.emit(artifact);
        }
    }

    getRtpStatus(artifact: MappedArtifact): ArtifactRtpValidationStatus {
        const currentValidationStatus = artifact.validation?.status || ArtifactValidationStatus.NOT_VALIDATED;
        if (
            currentValidationStatus !== ArtifactValidationStatus.IN_PROGRESS &&
            currentValidationStatus !== ArtifactValidationStatus.NOT_VALIDATED
        ) {
            return null;
        }

        return currentValidationStatus === ArtifactValidationStatus.NOT_VALIDATED && !isFileTabular(artifact.fileName)
            ? ArtifactRtpValidationStatus.ANALYZING
            : artifact.validation?.rtpStatus;
    }

    getValidationStatusLabel = (artifact: MappedArtifact) => {
        const currentValidationStatus = artifact.validation?.status || ArtifactValidationStatus.NOT_VALIDATED;
        const rtpStatus: ArtifactRtpValidationStatus = this.getRtpStatus(artifact);

        if (!!rtpStatus) {
            const rtpStatusLabel = ArtifactRtpValidationStatusLabels[rtpStatus];
            if (rtpStatus === ArtifactRtpValidationStatus.ANALYZING) {
                return rtpStatusLabel + '...';
            } else {
                return rtpStatusLabel;
            }
        }
        return ArtifactValidationStatusLabels[currentValidationStatus];
    };

    showAutomationStatusIcon = (artifact: MappedArtifact) => {
        return !artifact.validation?.isHumanReviewed || !!this.getRtpStatus(artifact);
    };

    getExpirationExplanation(artifact: MappedArtifact): string {
        if (!artifact.validation?.expirationDate) {
            return '';
        }

        const auditReportType = artifact.validation?.auditReportType;
        switch (auditReportType) {
            case AuditReportTypeCode.ISO27001CERTONLY:
            case AuditReportTypeCode.ISO27701CERTONLY:
                return `While this ISO certification technically remains valid for three years from the initial issue date,
                        VISO treats it as having a 1-year validity period with a buffer of additional 3 months to align with
                        the annual surveillance audit required for maintaining the certification.`;
            default:
                return '';
        }
    }

    trackByArtifactId(index: number, artifact: MappedArtifact) {
        return artifact.id;
    }

    ngOnDestroy(): void {
        this._unsub$.next();
    }
}
