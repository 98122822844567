import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Actions, ofType } from '@ngrx/effects';
import { filter, take, takeUntil, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import {
    updateUserProfile,
    updateVisoUserMetadata,
    updateVisoUserMetadataFailed,
    updateVisoUserMetadataSuccess,
} from '../../session/redux/session.actions';
import { addRelationshipRoute } from '../../add-relationship/add-relationship.constants';
import { FormUtilsService } from '@shared/utils/form-utils.service';
import { noWhitespaceValidator } from '@shared/validators/whitespace-validator';
import { getUserProfileSelector } from '../../session/redux/session.selectors';
import { UserProfile, VisoUserRole } from '@entities/viso-user';
import { startSpotlightTour } from '../../../layout/redux/layout.actions';
import { SpotlightTourType } from '@shared/spotlight-tour/steps';

interface UpdateUserDetailsFormGroup {
    firstName: FormControl<string>;
    lastName: FormControl<string>;
    orgName: FormControl<string>;
}

@Component({
    selector: 'app-welcome-message',
    templateUrl: './welcome-message.component.html',
    styleUrls: ['./welcome-message.component.scss'],
})
export class WelcomeMessageComponent implements OnInit, OnDestroy {
    showFirstLastNameFields: boolean;
    showOrgNameField: boolean;
    showUpdateMetadataError: boolean;
    updateMetadataError: string;
    updateProfileForm: FormGroup<UpdateUserDetailsFormGroup>;
    updatingMetadata: boolean;
    withSuccessRedirect = false;

    VisoUserRole = VisoUserRole;

    private _unsub$ = new Subject<void>();

    get showRequiredFirstNameError(): boolean {
        const firstNameControl = this.updateProfileForm.controls.firstName;
        return firstNameControl.errors?.required && this.isFirstNameDirtyOrTouched;
    }

    get showFirstNameMaxLengthError(): boolean {
        const firstNameControl = this.updateProfileForm.controls.firstName;
        return firstNameControl.errors?.maxlength && this.isFirstNameDirtyOrTouched;
    }

    get showRequiredLastNameError(): boolean {
        const lastNameControl = this.updateProfileForm.controls.lastName;
        return lastNameControl.errors?.required && this.isLastNameDirtyOrTouched;
    }

    get showLastNameMaxLengthError(): boolean {
        const lastNameControl = this.updateProfileForm.controls.lastName;
        return lastNameControl.errors?.maxlength && this.isLastNameDirtyOrTouched;
    }

    get showRequiredOrgNameError(): boolean {
        const orgNameControl = this.updateProfileForm.controls.orgName;
        return orgNameControl.errors?.required && this.isOrgNameDirtyOrTouched;
    }

    get showOrgNameMaxLengthError(): boolean {
        const orgNameControl = this.updateProfileForm.controls.orgName;
        return orgNameControl.errors?.maxlength && this.isOrgNameDirtyOrTouched;
    }

    get isButtonEnabled(): boolean {
        return (
            (!this.showFirstLastNameFields && !this.showOrgNameField) ||
            (!this.updateProfileForm.controls.firstName.errors &&
                !this.updateProfileForm.controls.lastName.errors &&
                !this.updateProfileForm.controls.orgName.errors)
        );
    }

    constructor(
        private _fb: FormBuilder,
        private _formUtils: FormUtilsService,
        private _actions$: Actions,
        private _activeModal: NgbActiveModal,
        private _store$: Store,
        private _router: Router,
    ) {}

    ngOnInit(): void {
        const userProfile = this._store$.select(getUserProfileSelector).pipe(filter<UserProfile>(Boolean), take(1));

        userProfile.subscribe((userProfile) => {
            this.showFirstLastNameFields = userProfile.showFirstLastNameForm;
            this.showOrgNameField = userProfile.showOrganizationNameForm;
        });

        this.updateProfileForm = this._fb.group({
            firstName: this._fb.control(null, {
                validators: [
                    Validators.maxLength(50),
                    noWhitespaceValidator,
                    ...(this.showFirstLastNameFields ? [Validators.required] : []),
                ],
            }),
            lastName: this._fb.control(null, {
                validators: [
                    Validators.maxLength(50),
                    noWhitespaceValidator,
                    ...(this.showFirstLastNameFields ? [Validators.required] : []),
                ],
            }),
            orgName: this._fb.control(null, {
                validators: [
                    Validators.maxLength(100),
                    noWhitespaceValidator,
                    ...(this.showOrgNameField ? [Validators.required] : []),
                ],
            }),
        });

        this._actions$
            .pipe(
                ofType(updateVisoUserMetadataSuccess),
                tap(() => {
                    this.showUpdateMetadataError = false;
                    this.updatingMetadata = false;

                    if (this.withSuccessRedirect) {
                        this.clearWithRedirect();
                    } else {
                        this.clear();
                    }
                }),
                takeUntil(this._unsub$),
            )
            .subscribe();

        this._actions$
            .pipe(
                ofType(updateVisoUserMetadataFailed),
                tap((response) => {
                    this.updateMetadataError =
                        (response.error.error?.errors?.length ?? false)
                            ? response.error.error.errors[0]
                            : 'An error has occurred';
                    this.showUpdateMetadataError = true;
                    this.updatingMetadata = false;
                }),
                takeUntil(this._unsub$),
            )
            .subscribe();
    }

    updateProfile(withSuccessRedirect = false) {
        this.showUpdateMetadataError = false;
        this.updateProfileForm.markAllAsTouched();
        this.withSuccessRedirect = withSuccessRedirect;

        if (!withSuccessRedirect) {
            this._store$.dispatch(startSpotlightTour({ tourType: SpotlightTourType.WELCOME }));
        }

        if (!this.updateProfileForm.invalid && (this.showOrgNameField || this.showFirstLastNameFields)) {
            this.updatingMetadata = true;
            const userRegistration = this._formUtils.getCleanFormGroupValue(this.updateProfileForm);
            this._store$.dispatch(updateVisoUserMetadata({ request: userRegistration }));
        } else if (this.withSuccessRedirect) {
            this.clearWithRedirect();
            this.resetWelcomeFormUserProfile();
        } else {
            this.clear();
            this.resetWelcomeFormUserProfile();
        }
    }

    clearWithRedirect() {
        this.clear();
        setTimeout(() => {
            this._router.navigate(['/', { outlets: { popup: addRelationshipRoute } }]);
        }, 250);
    }

    clear() {
        this._activeModal.dismiss();
    }

    private get isFirstNameDirtyOrTouched(): boolean {
        const firstNameFormControl = this.updateProfileForm.controls.firstName;
        return this.showFirstLastNameFields && (firstNameFormControl.dirty || firstNameFormControl.touched);
    }

    private get isLastNameDirtyOrTouched(): boolean {
        const lastNameFormControl = this.updateProfileForm.controls.lastName;
        return this.showFirstLastNameFields && (lastNameFormControl.dirty || lastNameFormControl.touched);
    }

    private get isOrgNameDirtyOrTouched(): boolean {
        const orgNameFromControl = this.updateProfileForm.controls.orgName;
        return this.showOrgNameField && (orgNameFromControl.dirty || orgNameFromControl.touched);
    }

    private resetWelcomeFormUserProfile() {
        this._store$.dispatch(
            updateUserProfile({
                userProfile: {
                    showWelcomeMessage: false,
                },
            }),
        );
    }

    ngOnDestroy(): void {
        this._unsub$.next();
    }
}
