import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ContextType } from '../../../../entities/context-type';

@Component({
    selector: 'app-business-case',
    templateUrl: './business-case.component.html',
    styleUrls: ['./business-case.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BusinessCaseComponent {
    @Input()
    contextTypes: ContextType[];
}
