import { Component, OnInit } from '@angular/core';
import { DemoToolsService } from './demo-tools.service';
import { Org, OrgService } from '../../entities/org';
import { HttpResponse } from '@angular/common/http';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DemoData } from './demo-tools.model';
import { FormUtilsService } from '../../shared/utils/form-utils.service';
import { SnackbarService } from '../../shared/components/snackbar/snackbar.service';
import { filter, finalize, map, take } from 'rxjs/operators';
import { ConfirmDialogService } from '../../shared/components/confirm-dialog/confirm-dialog.service';
import { environment } from 'src/main/webapp/environments/environment';

@Component({
    selector: 'app-demo-tools',
    templateUrl: './demo-tools.component.html',
    styleUrls: ['./demo-tools.component.scss'],
})
export class DemoToolsComponent implements OnInit {
    orgs: Org[];
    demoDataForm: UntypedFormGroup;
    artifactUpdateForm: UntypedFormGroup;
    recertificationForm: UntypedFormGroup;
    riskNetworkForm: UntypedFormGroup;
    loadingResettingData: boolean;

    constructor(
        private _fb: UntypedFormBuilder,
        private _demoToolsService: DemoToolsService,
        private _orgService: OrgService,
        private _snackbarService: SnackbarService,
        private _formUtils: FormUtilsService,
        private _confirmDialogService: ConfirmDialogService,
    ) {}

    resetDemoData() {
        this.loadingResettingData = true;
        const formValue = this._formUtils.getCleanFormGroupValue(this.demoDataForm);
        if (formValue.clientOrgId !== null) {
            let orgName = this.orgs.find((o) => o.id == formValue.clientOrgId).name;

            this._demoToolsService
                .resetDataForClient(
                    formValue.clientOrgId,
                    new DemoData(formValue.vendorCount, formValue.validationWeight, formValue.onboardingWeight),
                )
                .pipe(finalize(() => (this.loadingResettingData = false)))
                .subscribe(() => {
                    this._snackbarService.success(`Reset data for  ${orgName}`);
                });
        }
    }

    showResetClientDataConfirmation(): void {
        this._confirmDialogService
            .confirm({
                title: 'Reset all client data',
                subtitle: `BaseUrl: ${environment.baseUrl}`,
                message: 'Are you sure you want to reset and regenerate all data for the selected client?',
                confirmLabel: 'Yes, do it!',
            })
            .pipe(
                filter((result) => result),
                map(() => this.resetDemoData()),
                take(1),
            )
            .subscribe();
    }

    showRiskNetworkDetectionsConfirmation(): void {
        this._confirmDialogService
            .confirm({
                title: 'Reload Risk Network Org Detections',
                subtitle: `BaseUrl: ${environment.baseUrl}`,
                message:
                    'Are you sure you want to regenerate Risk Network Org Detections for the selected client? This will delete ALL existing detections!',
                confirmLabel: 'Yes, do it!',
            })
            .pipe(filter((result) => result))
            .subscribe(() => this.createRiskNetworkDetections());
    }

    createArtifactUpdateAssessment() {
        this.loadingResettingData = true;
        const formValue = this._formUtils.getCleanFormGroupValue(this.artifactUpdateForm);
        if (formValue.clientOrgId !== null) {
            let orgName = this.orgs.find((o) => o.id == formValue.clientOrgId).name;

            this._demoToolsService
                .createArtifactUpdateAssessmentForClient(formValue.clientOrgId, formValue.vendorCount)
                .pipe(finalize(() => (this.loadingResettingData = false)))
                .subscribe(() => {
                    this._snackbarService.success(`Generated Artifact Update Assessment(s) for ${orgName}`);
                });
        }
    }

    createRecertificationAssessment() {
        this.loadingResettingData = true;
        const formValue = this._formUtils.getCleanFormGroupValue(this.recertificationForm);
        if (formValue.clientOrgId !== null) {
            let orgName = this.orgs.find((o) => o.id == formValue.clientOrgId).name;

            this._demoToolsService
                .createRecertificationAssessmentForClient(formValue.clientOrgId, formValue.vendorCount)
                .pipe(finalize(() => (this.loadingResettingData = false)))
                .subscribe(() => {
                    this._snackbarService.success(`Generated Recertification Assessment(s) for ${orgName}`);
                });
        }
    }

    private createRiskNetworkDetections(): void {
        this.loadingResettingData = true;
        const formValue = this._formUtils.getCleanFormGroupValue(this.riskNetworkForm);
        if (formValue.clientOrgId !== null) {
            const orgName = this.orgs.find((o) => o.id === formValue.clientOrgId).name;
            this._demoToolsService
                .createRiskNetworkDetectionsForClient(
                    formValue.clientOrgId,
                    formValue.minPerRelationship,
                    formValue.maxPerRelationship,
                )
                .pipe(finalize(() => (this.loadingResettingData = false)))
                .subscribe(() => {
                    this._snackbarService.success(`Now generating Risk Network Org Detections for ${orgName}`);
                });
        }
    }

    ngOnInit(): void {
        this.demoDataForm = this._fb.group({
            vendorCount: [0, [Validators.min(0), Validators.required]],
            validationWeight: [90, [Validators.min(0), Validators.max(100), Validators.required]],
            onboardingWeight: [90, [Validators.min(0), Validators.max(100), Validators.required]],
            clientOrgId: [],
        });

        this.artifactUpdateForm = this._fb.group({
            vendorCount: [0, [Validators.min(1), Validators.required]],
            clientOrgId: [],
        });

        this.recertificationForm = this._fb.group({
            vendorCount: [0, [Validators.min(1), Validators.required]],
            clientOrgId: [],
        });

        this.riskNetworkForm = this._fb.group({
            minPerRelationship: [1, [Validators.min(1), Validators.max(700), Validators.required]],
            maxPerRelationship: [10, [Validators.min(1), Validators.max(700), Validators.required]],
            clientOrgId: [null, [Validators.required]],
        });

        this._orgService.clients().subscribe((res: HttpResponse<Org[]>) => {
            this.orgs = res.body;
        });
    }

    trackOrgById(index: number, item: Org) {
        return item.id;
    }
}
