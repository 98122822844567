<ng-container *ngIf="processingImport; else importSpreadsheetForm" class="spinner-container">
    <div class="d-flex align-items-center justify-content-center">
        <div class="sk-spinner sk-spinner-pulse"></div>
    </div>
</ng-container>
<ng-template #importSpreadsheetForm>
    <div class="card card-border mw-40">
        <div class="card-body">
            <span class="card-text text-bold me-4">Step 1</span>
            <span class="card-text text-bold">Get your import spreadsheet</span>
            <hr />
            <form name="filtersForm" role="form" novalidate (ngSubmit)="getImportSpreadsheet()">
                <div class="form-group">
                    <div class="card-text">Import type</div>
                    <mat-radio-group
                        [(ngModel)]="hasExistingRelationships"
                        aria-label="Select an option"
                        name="assessmentOptions"
                        color="primary"
                        class="d-flex flex-column"
                    >
                        <mat-radio-button id="existingRelationshipRadio" [value]="true"
                            >Existing Relationship</mat-radio-button
                        >
                        <mat-radio-button id="newRelationshipRadio" [value]="false">New Relationship</mat-radio-button>
                    </mat-radio-group>
                </div>
                <button type="submit" mat-button visoButton="primary">Download</button>
            </form>
        </div>
    </div>

    <div class="card card-border mw-40">
        <div class="card-body">
            <span class="card-text text-bold me-4">Step 2</span>
            <span class="card-text text-bold">Share your completed import spreadsheet with us</span>
            <hr />
            <form name="filtersForm" role="form" novalidate (ngSubmit)="doImport()">
                <div class="form-group mb-2">
                    <div class="card-text mb-2">Upload your CSV spreadsheet below.</div>
                    <div *ngIf="!uploadedFileName" appFileDragAndDrop (onFileDropped)="uploadFile($event)">
                        <button
                            type="button"
                            mat-button
                            visoButton="text"
                            class="full-width p-2 mb-2 drag-drop-message-container"
                            (click)="uploader.click()"
                        >
                            <span class="text-dark">
                                Drag and Drop or <span class="text-bold text-secondary">Browse</span>
                            </span>
                        </button>
                        <input hidden type="file" #uploader (change)="uploadFile($event)" />
                    </div>

                    <div
                        *ngIf="uploadedFileName"
                        class="document-file-selector p-2"
                        [ngClass]="{ 'border border-danger': errorUploading }"
                    >
                        {{ uploadedFileName }}
                    </div>
                </div>

                <div class="mb-3">
                    <div *ngIf="isUploaded || isUploading" class="btn-group">
                        <span *ngIf="isUploading" class="text-muted">Processing ... {{ percentUploaded }}%</span>
                        <span *ngIf="isUploaded && !errorUploading" class="text-muted"
                            >{{ numberOfRelationshipsToImport }}
                            {{ numberOfRelationshipsToImport === 1 ? 'relationship' : 'relationships' }} to be
                            imported</span
                        >
                        <span *ngIf="errorUploading" class="text-muted text-danger">Error processing file</span>
                    </div>

                    <ng-template #progressActions>
                        <button
                            *ngIf="isUploading"
                            type="button"
                            class="btn-xs ms-2 progress-action"
                            mat-button
                            visoButton="link"
                            (click)="cancelUpload()"
                        >
                            Cancel
                        </button>
                        <button
                            *ngIf="isUploaded && !errorUploading"
                            type="button"
                            class="btn-xs ms-2 progress-action"
                            mat-button
                            visoButton="link"
                            (click)="resetUpload()"
                            [disabled]="isImportDisabled"
                        >
                            Reset
                        </button>
                    </ng-template>

                    <ng-container *ngIf="!errorUploading; else errorsList">
                        <div class="d-flex p-0">
                            <progressbar
                                *ngIf="isUploading || isUploaded"
                                class="progress-bar-dull progress-bar-resize"
                                [value]="percentUploaded"
                            ></progressbar>

                            <ng-container *ngTemplateOutlet="progressActions"></ng-container>
                        </div>
                    </ng-container>

                    <ng-template #errorsList>
                        <div class="p-0">
                            <div class="d-flex">
                                <progressbar
                                    class="progress-bar-resize"
                                    [value]="percentUploaded"
                                    type="danger"
                                ></progressbar>
                                <ng-container *ngTemplateOutlet="progressActions"></ng-container>
                            </div>

                            <div class="mt-3">
                                <p class="text-bold">Please address the errors and upload your file again.</p>
                                <p>
                                    Review the notes below and refer to our
                                    <a
                                        href="https://support.visotrust.com/article/r3vtvrvpie-importing-relationship"
                                        target="_blank"
                                        >help documentation</a
                                    >
                                    for more details.
                                </p>
                            </div>

                            <div class="mt-3 error-list border border-danger p-3">
                                <table>
                                    <tr>
                                        <th>Error</th>
                                        <th>Row</th>
                                    </tr>
                                    <tr *ngFor="let error of importErrors">
                                        <td class="pe-5">{{ error.key }}</td>
                                        <td class="ms-2">{{ error.value }}</td>
                                    </tr>
                                </table>
                            </div>
                        </div>

                        <div class="mt-3 mb-3">
                            <span
                                >If you are unable to resolve the issue, please contact
                                <a href="mailto:support@visotrust.com">support&#64;visotrust.com</a> and share the ID:
                                <span class="text-bold">{{ supportId }}</span> for reference.</span
                            >
                        </div>

                        <button mat-button visoButton="primary" (click)="resetUpload()">Reset Form</button>
                    </ng-template>
                </div>

                <button
                    *ngIf="!errorUploading"
                    type="submit"
                    mat-button
                    visoButton="primary"
                    [disabled]="isImportDisabled"
                >
                    Submit
                </button>
            </form>
        </div>
    </div>
</ng-template>
