<div *dynamicContent="breadcrumbsContainerToken">
    <ol class="breadcrumb breadcrumb px-0 pb-0">
        <li class="breadcrumb-item"><a [routerLink]="['/']">Home</a></li>
        <li class="breadcrumb-item active">Integrations</li>
    </ol>
</div>
<div class="content-heading">
    <div>Integrations</div>
</div>

<ng-template #slackConnectedActions>
    <a
        class="viso-link-hover text-primary p-2"
        href="https://slack.com/app_redirect?app={{ environment.slackAppId }}"
        target="_blank"
        >Open in Slack</a
    >
    <a
        class="viso-link-hover text-primary p-2 action-button-end"
        href="https://slack.com/apps/{{ environment.slackAppId }}-viso-trust?settings=1&tab=settings"
        target="_blank"
        >Disconnect</a
    >
</ng-template>

<ng-template #slackDisconnectedActions>
    <a class="viso-link-hover text-primary p-2" [href]="environment.slackAppInstallUrl" target="_blank">Add to slack</a>
</ng-template>

<ng-template #coupaConnectedActions>
    <button
        class="integration-action-button p-2"
        mat-button
        visoButton="link"
        toggleDisableOnActions
        [disablingActions]="[IntegrationActions.SyncCoupaIntegrationRequest]"
        [enablingActions]="[
            IntegrationActions.SyncCoupaIntegrationRequestSuccess,
            IntegrationActions.SyncCoupaIntegrationRequestFailed,
        ]"
        (click)="syncCoupaData()"
    >
        Sync
    </button>
    <button
        class="integration-action-button p-2"
        mat-button
        visoButton="link"
        toggleDisableOnActions
        [disablingActions]="[IntegrationActions.SyncCoupaIntegrationRequest]"
        [enablingActions]="[
            IntegrationActions.SyncCoupaIntegrationRequestSuccess,
            IntegrationActions.SyncCoupaIntegrationRequestFailed,
        ]"
        [routerLink]="['/', { outlets: { popup: ['coupa-integration'] } }]"
    >
        Edit
    </button>
    <button
        class="integration-action-button p-2 action-button-end"
        mat-button
        visoButton="link"
        toggleDisableOnActions
        [disablingActions]="[IntegrationActions.SyncCoupaIntegrationRequest]"
        [enablingActions]="[
            IntegrationActions.SyncCoupaIntegrationRequestSuccess,
            IntegrationActions.SyncCoupaIntegrationRequestFailed,
        ]"
        (click)="disconnectCoupa()"
    >
        Disconnect
    </button>
</ng-template>

<ng-template #coupaDisconnectedActions>
    <a class="viso-link-hover text-primary p-2" [routerLink]="['/', { outlets: { popup: ['coupa-integration'] } }]"
        >Connect to Coupa</a
    >
</ng-template>

<ng-template #netskopeDisconnectedActions>
    <a
        class="viso-link-hover text-primary p-2"
        href="https://visotrust.helpdocs.io/article/b5jxl8827l-how-to-configure-the-viso-trust-netskope-application-risk-exchange-are-plugin"
        target="_blank"
        >Connect to Netskope</a
    >
</ng-template>

<div class="card-grid-container">
    <h3 class="p-4">Available</h3>
    <div class="row">
        <div class="col-md m-4 mt-0" *ngFor="let integrationDefinition of availableIntegrations">
            <app-integration-tile
                [integrationDefinition]="integrationDefinition"
                [connectedIntegrations]="connectedIntegrations$ | async"
                [interestedIntegrations]="interestedIntegrations$ | async"
                (keepMeUpdated)="keepMeUpdated($event)"
            />
        </div>
    </div>
</div>
<div class="card-grid-container">
    <h3 class="p-4">Coming Soon</h3>
    <div class="row">
        <div class="col-md m-4 mt-0" *ngFor="let integrationDefinition of comingSoonIntegrations">
            <app-integration-tile
                [integrationDefinition]="integrationDefinition"
                [connectedIntegrations]="connectedIntegrations$ | async"
                [interestedIntegrations]="interestedIntegrations$ | async"
                (keepMeUpdated)="keepMeUpdated($event)"
            />
        </div>
    </div>
</div>
