import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { QuillModule } from 'ngx-quill';
import 'quill-mention';
import { defaultToolbar as toolbar } from './toolbar-module.conf';
import { defaultAllowedFormats as formats } from './formats.conf';
import { QuillEditorWrapperComponent } from './quill-editor-wrapper/quill-editor-wrapper.component';

@NgModule({
    declarations: [QuillEditorWrapperComponent],
    imports: [
        CommonModule,
        QuillModule.forRoot({
            modules: {
                toolbar,
            },
            formats,
        }),
        ReactiveFormsModule,
        TooltipModule,
    ],
    exports: [QuillModule, QuillEditorWrapperComponent],
})
export class QuillWrapperModule {}
