import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class ArrayUtilsService {
    private _comparisonOptions = {
        scalarValues: true,
        sameOrder: false,
    };

    isFirstArrayEqualsToSecondArray(array1: any[], array2: any[], options: any = this._comparisonOptions): boolean {
        if (this.validateArrays(array1, array2)) {
            if (!options.scalarValues) {
                if (options.sameOrder) {
                    return JSON.stringify(array1) === JSON.stringify(array2);
                } else {
                    // TODO: complete other cases
                    return false;
                }
            } else if (options.scalarValues) {
                if (!options.sameOrder) {
                    array1.sort();
                    array2.sort();
                }
                return array1.every((value, index) => value === array2[index]);
            } else {
                // TODO: complete other cases
                return false;
            }
        }
    }

    isFirstArrayIncludedInSecondArray(array1: any[], array2: any[]): boolean {
        if (this.validateArrays(array1, array2)) {
            return array1.every((i) => array2.includes(i));
        }
    }

    copyWithoutReference(arrayToCopy: any[]) {
        if (arrayToCopy) {
            return JSON.parse(JSON.stringify(arrayToCopy));
        }
        return [];
    }

    private validateArrays(array1: any[], array2: any[]): boolean {
        if (!array1 && !array2) {
            return false;
        }

        if (!array1 || !array2) {
            return false;
        }

        if (array1.length !== array2.length) {
            return false;
        }

        return true;
    }
}
