import { Action, createReducer, on } from '@ngrx/store';
import { DomainVerificationsState, initialState } from './domain-verifications.state';
import {
    deletePreferredEmailDomainRequestSuccess,
    getDomainVerificationsRequestSuccess,
    getPreferredEmailDomainRequestSuccess,
} from './domain-verification.actions';

const reducer = createReducer(
    initialState,
    on(getDomainVerificationsRequestSuccess, (state, { verifications }) => ({
        ...state,
        domainVerifications: verifications,
        hasValidDomainVerification: !!verifications.find((domain) => domain.verified),
    })),
    on(getPreferredEmailDomainRequestSuccess, (state, { verification }) => ({
        ...state,
        preferredDomainVerification: verification,
    })),
    on(deletePreferredEmailDomainRequestSuccess, (state, _) => ({
        ...state,
        preferredDomainVerification: null,
    })),
);

export function domainVerificationReducer(
    state: DomainVerificationsState = initialState,
    action: Action,
): DomainVerificationsState {
    return reducer(state, action);
}
