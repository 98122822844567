import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FullPageFileDropComponent } from './full-page-file-drop.component';
import { ElementVisibilityDirective } from '@shared/directives/element-visibility/element-visibility.directive';
import { MatIcon } from '@angular/material/icon';

@NgModule({
    declarations: [FullPageFileDropComponent],
    imports: [CommonModule, ElementVisibilityDirective, MatIcon],
    exports: [FullPageFileDropComponent],
})
export class FullPageFileDropModule {}
