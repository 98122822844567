import { AfterViewInit, ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Actions, ofType } from '@ngrx/effects';
import { ActionCreator, Store } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';
import { combineLatest, merge, Observable, of, Subject } from 'rxjs';
import { delay, map, takeUntil, tap, throttleTime } from 'rxjs/operators';
import { VisoUserRole } from '../../../../entities/viso-user';
import { UploadRequestAttachmentRequest } from '../../../../entities/attachment';
import { SnackbarService } from '../../../components/snackbar/snackbar.service';
import { FileDropDirective, FileItem, FileLikeObject, FileUploader } from '../../../file-upload';
import { UploadAttachmentsRequest } from '../../models';

export class MimeType {
    public static XLSX = new MimeType('.xlsx', ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']);
    public static XLS = new MimeType('.xls', ['application/vnd.ms-excel']);
    public static XLSM = new MimeType('.xlsm', ['application/vnd.ms-excel.sheet.macroEnabled.12']);
    public static CSV = new MimeType('.csv', ['text/csv']);
    public static PNG = new MimeType('.png', ['image/png']);
    public static ICO = new MimeType('.ico', ['image/x-icon', 'image/vnd.microsoft.icon']);
    public static JPG = new MimeType('.jpg', ['image/jpeg']);
    public static GIF = new MimeType('.gif', ['image/gif']);

    private constructor(
        public readonly extension: string,
        public readonly mimeType: string[],
    ) {}
}

@Component({
    selector: 'app-upload-attachments-modal',
    templateUrl: './upload-attachments-modal.component.html',
    styleUrls: ['./upload-attachments-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UploadAttachmentsModalComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input()
    actions: {
        requestAction: ActionCreator<
            any,
            (props: UploadAttachmentsRequest) => UploadAttachmentsRequest & TypedAction<any>
        >;
        requestSuccessAction: ActionCreator;
        requestFailedAction: ActionCreator;
        requestExtraParams?: any;
    };

    @Input()
    titleText: string = 'Upload Attachments';

    @Input()
    allowedMimeTypes: MimeType[] = [];

    uploader: FileUploader;
    onDrag$: Observable<boolean>;

    uploadDisabled$: Observable<boolean>;
    uploadingFiles$: Observable<boolean>;

    Roles = VisoUserRole;

    @ViewChild(FileDropDirective)
    private _fileDropZone: FileDropDirective;
    private _unsub$ = new Subject<void>();
    private _fileQueueChange$ = new Subject<void>();

    constructor(
        private _store$: Store,
        private _actions$: Actions,
        private _activeModal: NgbActiveModal,
        private _snackbarService: SnackbarService,
    ) {}

    ngOnInit(): void {
        this.uploader = new FileUploader({
            url: '',
            maxFileSize: 100000000,
            allowedMimeType:
                this.allowedMimeTypes?.length > 0
                    ? this.allowedMimeTypes.flatMap((mimeType) => mimeType.mimeType)
                    : null,
        });

        this.uploader.onAfterAddingFile = () => {
            this._fileQueueChange$.next();
        };

        this.uploadingFiles$ = merge(
            of(false),
            this._actions$.pipe(
                ofType(this.actions.requestAction),
                map(() => true),
            ),
            this._actions$.pipe(
                ofType(this.actions.requestSuccessAction, this.actions.requestFailedAction),
                delay(1000),
                map(() => false),
            ),
        );

        this.uploadDisabled$ = combineLatest([
            merge(of(!this.uploader.queue.length), this._fileQueueChange$.pipe(map(() => !this.uploader.queue.length))),
            this.uploadingFiles$,
        ]).pipe(map(([formInvalid, noFileQueue]) => formInvalid || noFileQueue));

        this._actions$
            .pipe(
                ofType(this.actions.requestSuccessAction),
                tap(() => this.close()),
                takeUntil(this._unsub$),
            )
            .subscribe();

        this.uploader.onWhenAddingFileFailed = (item: FileLikeObject, filter: any, options: any) => {
            let errorMessage;
            if (filter.name === 'fileSize') {
                errorMessage = 'File too big. Max size: 100MB';
            } else if (filter.name === 'mimeType') {
                errorMessage = `File type must be ${this.allowedMimeTypes
                    .map((mimeType) => mimeType.extension)
                    .join(', ')}`;
            } else {
                errorMessage = `Filter failed: ${filter.name}`;
            }
            this._snackbarService.error(errorMessage);
        };
    }

    ngAfterViewInit(): void {
        this.onDrag$ = this._fileDropZone.fileOver.pipe(
            throttleTime(500, undefined, { leading: true, trailing: true }),
        );
    }

    removeItem(fileItem: FileItem): void {
        fileItem.remove();
        this._fileQueueChange$.next();
    }

    upload(): void {
        const requests = this.getAttachmentUploadRequests();
        const action = this.actions.requestAction({ requests, ...(this.actions.requestExtraParams || {}) });
        this._store$.dispatch(action);
    }

    close() {
        this.uploader.clearQueue();
        this._activeModal.dismiss('cancel');
    }

    ngOnDestroy(): void {
        this._unsub$.next();
    }

    private getAttachmentUploadRequests(): UploadRequestAttachmentRequest[] {
        return this.uploader.queue.map<UploadRequestAttachmentRequest>((queueFile) => ({
            file: queueFile._file,
        }));
    }
}
