<div class="row">
    <div class="col-auto pe-0">
        Inherent Risk
        <app-risk-chip class="ms-2" [risk]="inherentRisk" [condensed]="true" />
    </div>
    <div class="col-auto px-1">
        <mat-icon>arrow_right</mat-icon>
    </div>
    <div class="col-auto ps-0">
        Residual Risk
        <app-risk-chip class="ms-2" [risk]="residualRisk" [condensed]="true" />
    </div>
</div>
