import { Pipe, PipeTransform } from '@angular/core';
import { VendorSearchResult } from './models/vendor-search-result';

@Pipe({
    name: 'asVendorSearchResult',
})
export class AsVendorSearchResultPipe implements PipeTransform {
    transform(value: any): VendorSearchResult {
        return value;
    }
}
