import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

type bootstrapBadgeType = 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'light' | 'dark';
type visoBadgeType = 'light-primary' | 'light-success' | 'light-info' | 'light-gray' | 'light-warning' | 'timeline';

@Component({
    selector: 'app-badge',
    templateUrl: './badge.component.html',
    styleUrls: ['./badge.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BadgeComponent {
    @Input()
    type: bootstrapBadgeType | visoBadgeType = 'light';

    @Input()
    noBorderRadius: boolean = false;

    @Input()
    striped: boolean = false;

    @Input()
    extraCssClasses: string = '';

    get badgeTypeClass(): string {
        return `text-bg-${this.type} badge-${this.type}`;
    }

    get borderRadiusClass(): string {
        return this.noBorderRadius ? 'no-border-radius' : '';
    }

    get stripedClass(): string {
        return this.striped ? 'striped' : '';
    }
}
