<div class="row border-bottom">
    <div class="col">
        <div class="step-header ms-3 mt-4">
            <ng-content></ng-content>
        </div>
    </div>
    <div class="col-auto bg-light d-flex justify-content-center align-items-center">
        <app-stat-box
            class="px-3"
            [risk]="!defaultState ? null : inherentRisk"
            [riskCategoryLabel]="'Inherent Risk Level'"
            [captionText]="!defaultState ? 'waiting on Data Types' : ''"
        ></app-stat-box>
    </div>
</div>
