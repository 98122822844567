import { Injectable } from '@angular/core';
import { FileArtifact, UploadFileArtifactRequest } from '@entities/artifact';
import {
    ArtifactUploadRecommendation,
    formatThirdPartyProvidedAuditReportType,
    isExpiredDocumentationRecommendation,
    isSiblingDocumentationRecommendation,
} from '../models';
import { AUDIT_TYPES_TO_FILE_ARTIFACT_TYPES } from '@entities/audit-report';

@Injectable()
export class FileUploadHelperService {
    createFilesUploadRequest(
        files: File[],
        uploadRecommendation?: ArtifactUploadRecommendation,
    ): UploadFileArtifactRequest[] {
        const artifactType = uploadRecommendation?.fileArtifactType;
        return files.map<UploadFileArtifactRequest>((file) => {
            const artifactThirdPartyProvidedAuditType = !!uploadRecommendation
                ? formatThirdPartyProvidedAuditReportType(uploadRecommendation, file.name)
                : null;
            const vendorAuditReportType = !!uploadRecommendation
                ? isExpiredDocumentationRecommendation(uploadRecommendation)
                    ? uploadRecommendation.expiredAuditType
                    : isSiblingDocumentationRecommendation(uploadRecommendation)
                      ? uploadRecommendation.requiredSiblingAuditType
                      : null
                : null;
            return {
                file,
                fileArtifactMetaData: {
                    artifactType,
                    artifactThirdPartyProvidedAuditType,
                    vendorAuditReportType,
                },
            };
        });
    }

    createFileUploadReplacementRequest(file: File, fileArtifactToReplace: FileArtifact): UploadFileArtifactRequest {
        return {
            file,
            fileArtifactMetaData: {
                artifactType: AUDIT_TYPES_TO_FILE_ARTIFACT_TYPES[fileArtifactToReplace.validation.auditReportType],
                artifactIdToSupersede: fileArtifactToReplace.id,
                vendorAuditReportType: fileArtifactToReplace.validation.auditReportType,
            },
        };
    }
}
