import { Component, OnDestroy, OnInit } from '@angular/core';
import { Org, OrgService } from '../../entities/org';
import { ClientProfileService } from './client-profile.service';
import { catchError, filter, map, mergeMap, takeUntil, tap } from 'rxjs/operators';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import {
    DataTypeConfig,
    OrgControlDomainConfig,
    OrgDataSensitivityConfig,
    OrgRiskConfig,
} from './client-profile.model';

@Component({
    selector: 'app-client-profile',
    templateUrl: './client-profile.component.html',
    styleUrls: ['./client-profile.component.scss'],
})
export class ClientProfileComponent implements OnInit, OnDestroy {
    orgs$: Observable<Org[]>;

    clientProfileId: string;
    riskLevelsConfig: OrgRiskConfig[];
    dataSensitivityLevels: OrgDataSensitivityConfig[];
    dataTypesConfig: DataTypeConfig[];
    controlsConfig: OrgControlDomainConfig[];
    loadingProfile: boolean = false;
    clientProfileForm: UntypedFormGroup;

    private _refreshClientProfileTrigger$ = new BehaviorSubject<void>(null);
    private _unsub: Subject<void> = new Subject<void>();

    constructor(
        private _orgService: OrgService,
        private _clientProfileService: ClientProfileService,
        private _fb: UntypedFormBuilder,
    ) {}

    get getOrgIdValue(): number {
        return this.clientProfileForm.controls.orgId.value;
    }

    ngOnInit(): void {
        this.orgs$ = this._orgService.clients().pipe(
            map((res) => res.body),
            catchError(() => of(null)),
        );

        this.clientProfileForm = this._fb.group({
            orgId: [null],
        });

        this.clientProfileForm.controls.orgId.valueChanges
            .pipe(
                filter((value) => !!value),
                mergeMap((value) => this._refreshClientProfileTrigger$.pipe(map(() => value))),
                tap(() => (this.loadingProfile = true)),
                tap((value: number) => {
                    this._clientProfileService
                        .getForOrg(value)
                        .pipe(
                            catchError(() => of(null)),
                            tap(() => (this.loadingProfile = false)),
                        )
                        .subscribe((profile) => {
                            this.clientProfileId = profile.id;
                            this.riskLevelsConfig = profile.riskLevels;
                            this.dataSensitivityLevels = profile.dataSensitivityLevels;
                            this.dataTypesConfig = profile.dataTypes;
                            this.controlsConfig = profile.orgControlDomainConfigs;
                        });
                }),
                takeUntil(this._unsub),
            )
            .subscribe();
    }

    refreshClientProfile() {
        this._refreshClientProfileTrigger$.next();
    }

    trackOrgById(index: number, org: Org) {
        return org.id;
    }

    updateClientProfileData() {
        this.loadingProfile = true;
        this._clientProfileService
            .getForOrg(this.getOrgIdValue)
            .pipe(
                tap(() => (this.loadingProfile = false)),
                catchError(() => of(null)),
            )
            .subscribe((profile) => {
                this.dataTypesConfig = profile.dataTypes;
            });
    }

    ngOnDestroy(): void {
        this._unsub.next();
    }
}
