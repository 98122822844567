import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap, withLatestFrom } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { MatDialogWrapperService } from '@shared/modal/mat-dialog-wrapper.service';
import { openCustomizeDomainModal } from './manage-domains.actions';
import { CustomizeDomainDialogComponent } from '../customize-domain-dialog/customize-domain-dialog.component';
import { getOrgSubdomainDomain } from '../../org-subdomain/redux/org-subdomain.selectors';
import { getOrgDomains, getVanityCname } from '../../vanity-domain/redux/vanity-domain.selectors';
import { Store } from '@ngrx/store';

@Injectable()
export class ManageDomainsEffects {
    constructor(
        private _actions$: Actions,
        private _store$: Store,
        private _dialogService: MatDialogWrapperService,
    ) {}

    openCustomizeDomainModal$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(openCustomizeDomainModal),
                withLatestFrom(
                    this._store$.select(getOrgSubdomainDomain),
                    this._store$.select(getOrgDomains),
                    this._store$.select(getVanityCname),
                ),
                tap(([, domain, orgDomains, cname]) =>
                    this._dialogService.open<CustomizeDomainDialogComponent>(CustomizeDomainDialogComponent, {
                        inputs: { domain, orgDomains, cname },
                    }),
                ),
            ),
        { dispatch: false },
    );
}
