import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { catchError, filter, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { Org, OrgService } from '@entities/org';
import { ClientLicenseDefaultView, ClientLicenseView, LicenseType, LicenseTypeLabel } from '@entities/viso-user';
import { SnackbarService } from '@shared/components/snackbar/snackbar.service';
import { FormUtilsService } from '@shared/utils/form-utils.service';
import { LicenseManagementService } from './license-management.service';
import { OrgStatus } from '@entities/org';
@Component({
    selector: 'app-license-management',
    templateUrl: './license-management.component.html',
    styleUrls: ['./license-management.component.scss'],
})
export class LicenseManagementComponent implements OnInit, OnDestroy {
    orgs$: Observable<Org[]>;

    loadingLicense: boolean;
    editing: boolean;
    licenseManagementForm: UntypedFormGroup;
    LicenseType = LicenseType;
    LicenseTypeLabel = LicenseTypeLabel;

    licenseDescriptions = [
        {
            license: LicenseTypeLabel.TRIAL,
            description: 'Trial granted as a result of a user signing up for the platform themselves (self-service).',
        },
        {
            license: LicenseTypeLabel.PROD_TRIAL,
            description: 'Trial granted as a result of the sales pipeline.',
        },
        {
            license: LicenseTypeLabel.ENTERPRISE,
            description: 'Full access to the platform.',
        },
        {
            license: LicenseTypeLabel.NO_LOGIN,
            description: 'Orgs managed by VISO TRUST.',
        },
        {
            license: LicenseTypeLabel.STARTER,
            description: 'A starter Org',
        },
    ];

    licenseTypeDefaultValues: ClientLicenseDefaultView[];

    private _unsub$: Subject<void> = new Subject<void>();
    private _triggerClientLicense$ = new Subject<number>();
    private _clientLicense$ = new BehaviorSubject<ClientLicenseView>(null);

    constructor(
        private _fb: UntypedFormBuilder,
        private _orgService: OrgService,
        private _snackbarService: SnackbarService,
        private _licenseManagementService: LicenseManagementService,
        private _formUtils: FormUtilsService,
    ) {}

    get orgIdValue(): number {
        return this.licenseManagementForm.controls.orgId.value;
    }

    get isTrialLicense(): boolean {
        const formLicenseType = this.licenseManagementForm.controls.licenseType.value;
        return formLicenseType === LicenseType.TRIAL || formLicenseType === LicenseType.PROD_TRIAL;
    }

    ngOnInit(): void {
        this.orgs$ = this._orgService
            .clients()
            .pipe(map((res) => res.body.filter((org) => org.status === OrgStatus.ONBOARDED)));

        this._licenseManagementService
            .getClientLicenseDefaults()
            .pipe(
                map((res) => {
                    this.licenseTypeDefaultValues = res;
                }),
                take(1),
            )
            .subscribe();

        this.licenseManagementForm = this._fb.group({
            orgId: [null],
            id: [null],
            licenseType: [{ value: null, disabled: true }, Validators.required],
            maxRelationshipsAssessed: [{ value: null }, Validators.required],
            relationshipsAssessedCount: [{ value: null }],
            maxRelationshipsCreated: [{ value: null }, Validators.required],
            relationshipsCreatedCount: [{ value: null }],
            maxRelationshipsOnboarded: [{ value: null }, Validators.required],
            relationshipsOnboardedCount: [{ value: null }],
            maxRelationshipsWithLifecycleManagement: [{ value: null }, Validators.required],
            relationshipsWithLifecycleManagementCount: [{ value: null }],
            maxConciergeAssessments: [{ value: null }, Validators.required],
            conciergeAssessmentCount: [{ value: null }],
            maxSupplementalQuestionnaires: [{ value: null }, Validators.required],
            supplementalQuestionnairesCount: [{ value: null }],
            maxTrustQuestionnaires: [{ value: null }, Validators.required],
            trustQuestionnairesCount: [{ value: null }],
            maxTprmAiQa: [{ value: null }, Validators.required],
            maxTrustAiQa: [{ value: null }, Validators.required],
            trustAiQaCount: [{ value: null }],
            tprmAiQaCount: [{ value: null }],
            contractStartDate: [{ value: null }],
            contractEndDate: [{ value: null }],
        });

        this.licenseManagementForm.controls.orgId.valueChanges
            .pipe(
                filter((value) => !!value),
                tap((orgId) => this._triggerClientLicense$.next(orgId)),
                takeUntil(this._unsub$),
            )
            .subscribe();

        this._triggerClientLicense$
            .pipe(
                tap(() => (this.loadingLicense = true)),
                switchMap((orgId: number) =>
                    this._licenseManagementService.getClientLicenseForOrg(orgId).pipe(
                        tap(() => this.disableEditing()),
                        tap(() => (this.loadingLicense = false)),
                        tap((clientLicense) => this.setInitialFormData(clientLicense)),
                        catchError(() => of(null)),
                    ),
                ),
                takeUntil(this._unsub$),
            )
            .subscribe((clientLicense) => this._clientLicense$.next(clientLicense));
    }

    ngOnDestroy(): void {
        this._unsub$.next();
    }

    submitLicenseChanges() {
        const { orgId, ...formValues } = this._formUtils.getCleanFormGroupValue(this.licenseManagementForm);

        this._licenseManagementService
            .updateClientLicenseForOrg(orgId, formValues)
            .pipe(
                tap(() => this._triggerClientLicense$.next(orgId)),
                tap(() => this.onSuccessfulSave()),
                catchError(() => of(null)),
            )
            .subscribe();
    }

    edit() {
        this.enableEditing();
    }

    cancel() {
        this.setInitialFormData(this._clientLicense$.value);
        this.disableEditing();
    }

    private disableEditing() {
        this.editing = false;
        const formControls = this.licenseManagementForm.controls;
        formControls.licenseType.disable();
        formControls.maxRelationshipsAssessed.disable();
        formControls.maxRelationshipsCreated.disable();
        formControls.maxRelationshipsOnboarded.disable();
        formControls.maxRelationshipsWithLifecycleManagement.disable();
        formControls.maxSupplementalQuestionnaires.disable();
        formControls.maxTrustQuestionnaires.disable();
        formControls.maxTprmAiQa.disable();
        formControls.maxTrustAiQa.disable();
        formControls.contractStartDate.disable();
        formControls.contractEndDate.disable();
        formControls.maxConciergeAssessments.disable();
    }

    private enableEditing() {
        this.editing = true;
        const formControls = this.licenseManagementForm.controls;
        formControls.licenseType.enable();
        formControls.maxRelationshipsAssessed.enable();
        formControls.maxRelationshipsCreated.enable();
        formControls.maxRelationshipsOnboarded.enable();
        formControls.maxRelationshipsWithLifecycleManagement.enable();
        formControls.maxSupplementalQuestionnaires.enable();
        formControls.maxTrustQuestionnaires.enable();
        formControls.maxTprmAiQa.enable();
        formControls.maxTrustAiQa.enable();
        formControls.contractStartDate.enable();
        formControls.contractEndDate.enable();
        formControls.maxConciergeAssessments.enable();
    }

    private setInitialFormData(clientLicense: ClientLicenseView) {
        const formControls = this.licenseManagementForm.controls;
        formControls.id.setValue(clientLicense.id);
        formControls.licenseType.setValue(clientLicense.licenseType);
        formControls.maxRelationshipsAssessed.setValue(clientLicense.maxRelationshipsAssessed);
        formControls.relationshipsAssessedCount.setValue(clientLicense.relationshipsAssessedCount);
        formControls.maxRelationshipsCreated.setValue(clientLicense.maxRelationshipsCreated);
        formControls.relationshipsCreatedCount.setValue(clientLicense.relationshipsCreatedCount);
        formControls.maxRelationshipsOnboarded.setValue(clientLicense.maxRelationshipsOnboarded);
        formControls.relationshipsOnboardedCount.setValue(clientLicense.relationshipsOnboardedCount);
        formControls.maxRelationshipsWithLifecycleManagement.setValue(
            clientLicense.maxRelationshipsWithLifecycleManagement,
        );
        formControls.relationshipsWithLifecycleManagementCount.setValue(
            clientLicense.relationshipsWithLifecycleManagementCount,
        );
        formControls.maxConciergeAssessments.setValue(clientLicense.maxConciergeAssessments);
        formControls.conciergeAssessmentCount.setValue(clientLicense.conciergeAssessmentCount);
        formControls.maxSupplementalQuestionnaires.setValue(clientLicense.maxSupplementalQuestionnaires);
        formControls.supplementalQuestionnairesCount.setValue(clientLicense.supplementalQuestionnairesCount);
        formControls.maxTrustQuestionnaires.setValue(clientLicense.maxTrustQuestionnaires);
        formControls.trustQuestionnairesCount.setValue(clientLicense.trustQuestionnairesCount);
        formControls.maxTrustAiQa.setValue(clientLicense.maxTrustAiQa);
        formControls.trustAiQaCount.setValue(clientLicense.trustAiQaCount);
        formControls.maxTprmAiQa.setValue(clientLicense.maxTprmAiQa);
        formControls.tprmAiQaCount.setValue(clientLicense.tprmAiQaCount);
        formControls.contractStartDate.setValue(clientLicense.contractStartDate);
        formControls.contractEndDate.setValue(clientLicense.contractEndDate);
    }

    private onSuccessfulSave() {
        this._snackbarService.success('License changes saved.');
    }

    onLicenseTypeChange(event) {
        const formControls = this.licenseManagementForm.controls;
        const newLicenseType = formControls.licenseType.value;
        const defaultValues = this.licenseTypeDefaultValues.filter(
            (license) => license.licenseType == newLicenseType,
        )[0];
        formControls.maxRelationshipsAssessed.setValue(defaultValues.maxRelationshipsAssessed);
        formControls.maxRelationshipsCreated.setValue(defaultValues.maxRelationshipsCreated);
        formControls.maxRelationshipsOnboarded.setValue(defaultValues.maxRelationshipsOnboarded);
        formControls.maxRelationshipsWithLifecycleManagement.setValue(
            defaultValues.maxRelationshipsWithLifecycleManagement,
        );
        formControls.maxTrustAiQa.setValue(defaultValues.maxTrustAiQa);
        formControls.maxTprmAiQa.setValue(defaultValues.maxTprmAiQa);
        formControls.maxTrustQuestionnaires.setValue(defaultValues.maxTrustQuestionnaires);
        formControls.maxSupplementalQuestionnaires.setValue(defaultValues.maxSupplementalQuestionnaires);
    }
}
