<div class="row mt-3">
    <div class="col">
        <button mat-button visoButton="primary" class="ms-auto d-block" (click)="toggleTimelineView.emit()">
            Graph View
        </button>
    </div>
</div>
<ng-container *ngIf="riskAssessments && riskAssessments.length">
    <div class="row" [style.marginTop.px]="20">
        <div class="col">
            <ngx-datatable
                class="bootstrap"
                [columnMode]="'force'"
                [rowHeight]="35"
                [headerHeight]="30"
                [scrollbarV]="true"
                [rows]="riskAssessments"
                [count]="riskAssessments.length"
                [sorts]="[{ prop: 'createdDate', dir: 'desc' }]"
                data-cy="riskTimelineListTable"
            >
                <ngx-datatable-column name="Created Date" prop="createdDate">
                    <ng-template let-value="value" ngx-datatable-cell-template>
                        {{ value | date: 'medium' }}
                    </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column name="Reason Detail" prop="reasonDetail">
                    <ng-template let-value="value" ngx-datatable-cell-template>
                        {{ value }}
                    </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column [cellClass]="'cell-centered'" name="Inherent Risk" prop="inherentRisk">
                    <ng-template let-value="value" ngx-datatable-cell-template>
                        <app-risk-chip placement="right" container="body" [risk]="value" [condensed]="true">
                        </app-risk-chip>
                    </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column [cellClass]="'cell-centered'" name="Residual Risk" prop="riskLevel">
                    <ng-template let-value="value" ngx-datatable-cell-template>
                        <app-risk-chip placement="right" container="body" [risk]="value" [condensed]="true">
                        </app-risk-chip>
                    </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column name="Residual Risk Score" prop="score">
                    <ng-template let-value="value" ngx-datatable-cell-template>
                        {{ value * 100 | number: '1.2-2' }}%
                    </ng-template>
                </ngx-datatable-column>
            </ngx-datatable>
        </div>
    </div>
</ng-container>
