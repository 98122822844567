import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Risk, SlimRiskAssessment } from '../../../../entities/risk-assessment';

@Component({
    selector: 'app-assessment-risk-diff',
    templateUrl: './assessment-risk-diff.component.html',
    styleUrls: ['./assessment-risk-diff.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssessmentRiskDiffComponent {
    @Input({ required: true })
    completedRiskAssessment: SlimRiskAssessment;

    get inherentRisk(): Risk {
        return this.completedRiskAssessment?.inherentRisk;
    }

    get residualRisk(): Risk {
        return this.completedRiskAssessment?.risk;
    }
}
