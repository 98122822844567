import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AppState } from '../../../shared/redux/state';
import { Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { catchError, map, shareReplay, take, takeUntil, tap, timeout, withLatestFrom } from 'rxjs/operators';
import {
    Notification,
    NotificationStatus,
    NotificationType,
    TipNotificationType,
} from '../../../entities/notification';
import { listItemAnimation } from '../../../shared/animation/basic';
import {
    closeNotificationsDropdown,
    getNewNotificationsSubscription,
    getNewNotificationsSubscriptionSuccess,
    markAllNotificationsAsDismissed,
    markAllNotificationsAsRead,
    markNotificationAsDismissed,
    notificationsQuerySuccess,
    notificationsStatusUpdatedSuccess,
    openNotificationsDropdown,
    refreshNotifications,
} from '../../redux/layout.actions';
import { TimeService } from '@shared/services/time.service';
import { VendorRelationshipsService } from '../../../routes/relationships/relationships.service';
import { relationshipsQuerySuccess } from '../../../routes/relationships/redux/relationships.actions';

@Component({
    selector: '[appNotificationMenu]',
    templateUrl: './notification.component.html',
    styleUrls: ['./notification.component.scss'],
    animations: [listItemAnimation],
})
export class NotificationComponent implements OnInit, OnDestroy {
    @Input() currentAccount: any;

    notifications$ = new BehaviorSubject<Notification[]>([]);
    unreadNotifications$: Observable<Notification[]>;
    notificationsTotalCount: number;
    showCountBadge: boolean;

    NotificationType = NotificationType;

    private _unsub: Subject<void> = new Subject<void>();

    constructor(
        private _store$: Store<AppState>,
        private _actions$: Actions,
        private _timeService: TimeService,
        private _relationshipsService: VendorRelationshipsService,
    ) {}

    ngOnInit() {
        if (this.currentAccount !== null) {
            this._store$.dispatch(refreshNotifications());
            this._store$.dispatch(getNewNotificationsSubscription());

            combineLatest([
                this._actions$.pipe(
                    ofType(notificationsQuerySuccess),
                    tap((response) => (this.notificationsTotalCount = response.totalCount)),
                    map((response) => response.results),
                ),
                this._actions$.pipe(
                    ofType(relationshipsQuerySuccess),
                    timeout(1000),
                    catchError(() =>
                        this._relationshipsService
                            .query({ page: 0, size: 1 })
                            .pipe(map((response) => ({ results: response.body }))),
                    ),
                    map((response) => response.results?.[0]),
                    take(1),
                ),
            ])
                .pipe(
                    map(([notifications, relationship]) =>
                        notifications.map((notification) => {
                            if (
                                notification.notificationType === NotificationType.TIP_NOTIFICATION &&
                                (notification.tipType === TipNotificationType.EXPLORE_RELATIONSHIP ||
                                    notification.tipType === TipNotificationType.VISO_RELATIONSHIP)
                            ) {
                                notification.relationshipId = relationship.id;
                            }
                            return notification;
                        }),
                    ),
                    shareReplay(1),
                )
                .subscribe((notifications) => this.notifications$.next(notifications));

            this._actions$
                .pipe(
                    ofType(getNewNotificationsSubscriptionSuccess),
                    withLatestFrom(this.notifications$),
                    tap(([{ newNotification }, existingNotifications]) => {
                        this.notificationsTotalCount++;
                        if (!existingNotifications.some((n) => n.id === newNotification.id)) {
                            // Add to beginning since existing notifications are sorted by backend
                            existingNotifications.splice(0, 0, newNotification);
                        }

                        if (existingNotifications.length === 21) {
                            existingNotifications.pop();
                        }

                        return existingNotifications;
                    }),
                    takeUntil(this._unsub),
                )
                .subscribe(([, notifications]) => this.notifications$.next(notifications));

            this.unreadNotifications$ = this.notifications$.pipe(
                map((n) => n.filter((r) => r.status === NotificationStatus.NEW)),
            );

            this._actions$
                .pipe(ofType(closeNotificationsDropdown), takeUntil(this._unsub))
                .subscribe(() => this.markAllAsRead());

            this._actions$
                .pipe(
                    ofType(openNotificationsDropdown),
                    withLatestFrom(this.notifications$),
                    tap(([, notifications]) => {
                        for (let i = 0; i < notifications.length; i++) {
                            notifications[i].humanizedCreatedDate = this._timeService.humanizeDate(
                                notifications[i].createdDate,
                            );
                        }
                    }),
                    takeUntil(this._unsub),
                )
                .subscribe(([, notifications]) => this.notifications$.next(JSON.parse(JSON.stringify(notifications))));

            this._actions$
                .pipe(ofType(notificationsStatusUpdatedSuccess), takeUntil(this._unsub))
                .subscribe(() => this._store$.dispatch(refreshNotifications()));
        }
    }

    markAsDismissed(notification: Notification, mouseEvent: MouseEvent) {
        if (!!mouseEvent) {
            mouseEvent.preventDefault();
            mouseEvent.stopPropagation();
        }
        this._store$.dispatch(markNotificationAsDismissed({ notifications: [notification] }));
    }

    markAllAsDismissed(e: MouseEvent) {
        e.stopPropagation();
        this._store$.dispatch(markAllNotificationsAsDismissed());
    }

    markAllAsRead() {
        this.unreadNotifications$
            .pipe(
                map((unreadNotifications) => unreadNotifications.map((n) => n.id)),
                take(1),
            )
            .subscribe((notificationIds) => {
                if (notificationIds.length > 0) {
                    this._store$.dispatch(
                        markAllNotificationsAsRead({
                            notificationIds,
                        }),
                    );
                }
            });
    }

    ngOnDestroy() {
        this._unsub.next();
    }

    trackNotification(idx: number, el: any): number {
        return el.id;
    }
}
