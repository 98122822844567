import {
    Component,
    ContentChild,
    ElementRef,
    EventEmitter,
    forwardRef,
    Input,
    OnDestroy,
    OnInit,
    Output,
    Provider,
} from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NG_VALUE_ACCESSOR, ValidatorFn, Validators } from '@angular/forms';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, startWith, takeUntil, tap } from 'rxjs/operators';
import { TagColorEnum } from '../../../entities/tag';
import { noWhitespaceValidator } from '../../validators/whitespace-validator';
import { TagActionsContentDirective } from '../../tags/directives/tag-actions-content.directive';

const CUSTOM_SELECT_VALUE_ACCESSOR_PROVIDER: Provider = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CustomSelectComponent),
    multi: true,
};

@Component({
    selector: 'app-custom-select',
    templateUrl: './custom-select.component.html',
    styleUrls: ['./custom-select.component.scss'],
    providers: [CUSTOM_SELECT_VALUE_ACCESSOR_PROVIDER],
})
export class CustomSelectComponent<T> implements ControlValueAccessor, OnInit, OnDestroy {
    @ContentChild(TagActionsContentDirective) additionalActionsContent!: TagActionsContentDirective;
    @Input()
    set options(options: T[]) {
        if (this.availableOptions$) {
            this._options$.next(options);
        }
    }
    @Input() valueAttribute: keyof T;
    @Input() displayAttribute: 'string';
    @Input() maxLimit: number = 100;
    @Input() searchMinWidth: number = 100;
    @Input() searchValidators: ValidatorFn[];

    @Output() add = new EventEmitter<T>();
    @Output() search = new EventEmitter<string>();
    @Output() remove = new EventEmitter<T>();

    searchControl = new UntypedFormControl(null, [Validators.maxLength(30), noWhitespaceValidator]);

    tagColor = TagColorEnum.COLOR_01;

    private _options$ = new BehaviorSubject<T[]>([]);
    selectedOptions$ = new BehaviorSubject<T[]>([]);
    availableOptions$ = new BehaviorSubject<T[]>([]);
    filteredOptions$: Observable<T[]>;

    private _onChange: any;
    // @ts-ignore
    private _onTouched: any;
    disabled: boolean;
    private _unsub$ = new Subject<void>();

    checkOption = (item: T, target: T) =>
        this.valueAttribute ? target[this.valueAttribute] !== item[this.valueAttribute] : item !== target;

    // @ts-ignore
    constructor(private _el: ElementRef) {}

    ngOnInit(): void {
        if (this.searchValidators?.length > 0) {
            this.searchControl.addValidators(this.searchValidators);
        }
        const searchChanges$ = this.searchControl.valueChanges.pipe(
            startWith(''),
            tap((value) => this.search.emit(value)),
            debounceTime(50),
            distinctUntilChanged(),
        );

        this.filteredOptions$ = combineLatest([this.availableOptions$, searchChanges$]).pipe(
            map(([options, searchHint]) =>
                searchHint
                    ? options.filter((option) => {
                          const displayValue = this.displayAttribute ? option[this.displayAttribute] : option;
                          return displayValue.toLowerCase().includes(searchHint.toLowerCase());
                      })
                    : options,
            ),
            map((options) =>
                options.sort((a, b) => {
                    const valueA = this.displayAttribute ? a[this.displayAttribute] : a;
                    const valueB = this.displayAttribute ? b[this.displayAttribute] : b;

                    if (valueA < valueB) {
                        return -1;
                    }
                    if (valueA > valueB) {
                        return 1;
                    }
                    return 0;
                }),
            ),
        );

        combineLatest([this._options$, this.selectedOptions$])
            .pipe(
                takeUntil(this._unsub$),
                filter(([options]) => !!options?.length),
            )
            .subscribe(([options, selectedValues]) => {
                const tempSelected = [];
                const tempAvailable = [];
                options.forEach((option) => {
                    const optionValue = this.displayAttribute ? option[this.displayAttribute] : option;
                    if (selectedValues.includes(optionValue)) {
                        tempSelected.push(option);
                    } else {
                        tempAvailable.push(option);
                    }
                });
                this.availableOptions$.next(tempAvailable);
            });
    }

    ngOnDestroy(): void {
        this._unsub$.next();
    }

    writeValue(value: T[]): void {
        if (this.selectedOptions$.value.length === value?.length) {
            return;
        }
        this.selectedOptions$.next(value || []);
    }

    registerOnChange(fn: (_: any) => void): void {
        this._onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this._onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        if (isDisabled) {
            this.searchControl.disable();
        } else {
            this.searchControl.enable();
        }
        this.disabled = isDisabled;
    }

    unselect(event: MouseEvent, target: T) {
        event.stopPropagation();
        const selectedItems = this.selectedOptions$.value.filter((item) => this.checkOption(item, target));
        this.availableOptions$.next([...this.availableOptions$.value, target]);
        this.selectedOptions$.next(selectedItems);
        this._updateOptions(selectedItems);
        this.emitEvent(target, 'remove');
    }

    unselectLastItem() {
        const tempOptions = [...this.selectedOptions$.value];
        const lastItem = tempOptions.pop();
        this.availableOptions$.next([...this.availableOptions$.value, lastItem]);
        this.selectedOptions$.next(tempOptions);
        this._updateOptions(tempOptions);
        this.emitEvent(lastItem, 'remove');
    }

    selectValue(event: MouseEvent, target: T) {
        event.stopPropagation();
        const tempOptions = [...this.selectedOptions$.value, target];
        this.availableOptions$.next(this.availableOptions$.value.filter((item) => this.checkOption(item, target)));
        this.selectedOptions$.next(tempOptions);
        this._updateOptions(tempOptions);
        this.clearSelection();
        this.emitEvent(target, 'add');
    }

    private _updateOptions(options: T[]) {
        this._onChange(options.map((option) => (this.valueAttribute ? option[this.valueAttribute] : option)));
    }

    private clearSelection(): void {
        this.searchControl.reset();
    }

    checkKeys(event: KeyboardEvent) {
        const currentSearchValue = this.searchControl.value;

        if (event.key === 'Backspace' && !currentSearchValue && this.selectedOptions$.value.length > 0) {
            this.unselectLastItem();
        }
        if (event.key === 'Enter') {
            event.stopPropagation();
            event.preventDefault();
            this.create(this.searchControl.value);
        }
    }

    create(name: unknown, event: MouseEvent = null) {
        event && event.stopPropagation();
        const newValue = (
            this.valueAttribute ? { [this.valueAttribute]: 0, [this.displayAttribute]: name } : name
        ) as T;
        const tempSelection = [...this.selectedOptions$.value, newValue];
        this.selectedOptions$.next(tempSelection);
        this._updateOptions(tempSelection);
        this.clearSelection();
        this.emitEvent(newValue, 'add');
    }

    emitEvent(value: T, event: string): void {
        const valueToEmit = (this.valueAttribute ? value[this.valueAttribute] : value) as T;
        switch (event) {
            case 'add':
                this.add.emit(valueToEmit);
                break;
            case 'remove':
                this.remove.emit(valueToEmit);
                break;
        }
    }
}
