import deparam from 'deparam';

interface INetworkRequestResponse {
    body?: any; // POJO or a JSON stringify equivalent
    method: string;
    headers: object;
}

export default class LogRocketFuzzySearchSanitizer {
    public fields: string[] = [];
    public headers: string[] = [];
    public maskData: boolean = true;

    constructor(privateFields: string[], privateHeaders: string[], maskData: boolean = true) {
        this.fields = privateFields;
        this.headers = privateHeaders;
        this.maskData = maskData;

        this.requestSanitizer = this.requestSanitizer.bind(this);
        this.responseSanitizer = this.responseSanitizer.bind(this);
    }

    public requestSanitizer(request: INetworkRequestResponse): object | any {
        // avoid parsing GET requests as there will be no body
        if (request.method === 'GET') {
            return request;
        }

        return this._networkHandler(request);
    }

    public responseSanitizer(response: INetworkRequestResponse): object | any {
        return this._networkHandler(response);
    }

    private _networkHandler(networkRequestResponse: INetworkRequestResponse) {
        const { body, headers } = networkRequestResponse;
        const requestContentType: string = headers && (headers['Content-Type'] || '');
        const isUrlEncodedRequest: boolean = requestContentType.includes('form-urlencoded');
        let parsedBody: object;
        let parsedHeaders: object;

        if (!this.maskData) {
            return networkRequestResponse;
        }

        try {
            parsedHeaders = JSON.parse(JSON.stringify(headers));
            this._maskHeaders(parsedHeaders);
        } catch (error) {
            return networkRequestResponse;
        }

        try {
            parsedBody = isUrlEncodedRequest ? deparam(body) : JSON.parse(body);
            this._maskBody(parsedBody);
        } catch (error) {
            return networkRequestResponse;
        }

        networkRequestResponse.headers = parsedHeaders;
        networkRequestResponse.body = parsedBody;

        return networkRequestResponse;
    }

    private _maskHeaders(headers: any = {}) {
        for (const headerName in headers) {
            this._mask(headers, headerName);
        }
    }

    private _maskBody(body: any = {}) {
        // iterate over collection of objects ex. [{}, ...]
        if (body && body.constructor === Array) {
            body.forEach((item) => this._maskBody(item));
        } else {
            for (const key in body) {
                if (body.hasOwnProperty(key)) {
                    const keyName = body[key];

                    /*
                      Objects with the following shape:
                        {
                          type: 'email',
                          value: 'secret@ex.com'
                        }
                      where type/value keynames are generic and instead
                      the value matching the type keyname should be masked.
                    */
                    const isTypeValuePair = key === 'type' && 'value' in body;

                    if (typeof keyName === 'object') {
                        if (!isTypeValuePair) {
                            this._maskBody(keyName);
                        }
                    }

                    if (isTypeValuePair) {
                        this._mask(body, body.type, 'value');
                    } else {
                        this._mask(body, key);
                    }
                }
            }
        }
    }

    private _mask(obj: object, searchKeyName: string, maskKeyName?: string) {
        maskKeyName = maskKeyName || searchKeyName;

        const isSensitiveFieldName = this._match(searchKeyName);

        if (isSensitiveFieldName) {
            obj[maskKeyName] = '*';
        }
    }

    private _match(keyName: string = ''): boolean {
        const { fields, headers } = this;
        const normalizedKeyName = keyName.toLowerCase();

        return (
            fields.some((field) => normalizedKeyName.indexOf(field.toLowerCase()) > -1) ||
            headers.some((header) => normalizedKeyName.indexOf(header.toLowerCase()) > -1)
        );
    }
}
