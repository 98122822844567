import { Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { createEffect } from '@ngrx/effects';
import { tap } from 'rxjs';

@Injectable()
export class LegacyHashUrlsRedirectEffect {
    redirectHashUrls$ = createEffect(
        () =>
            this.router.events.pipe(
                tap((event) => {
                    if (event instanceof NavigationStart) {
                        if (!!event.url && event.url.match(/^\/#/)) {
                            this.router.navigate([event.url.replace('/#', '')]);
                        }
                    }
                }),
            ),
        { dispatch: false },
    );

    constructor(private router: Router) {}
}
