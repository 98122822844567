import { HttpClient, HttpContext, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    Artifact,
    ArtifactType,
    ArtifactUploadResponse,
    QuestionnaireArtifact,
    UploadFileArtifactRequest,
    UploadURLArtifactRequest,
    isArtifactExpired,
    isArtifactExpiringSoon,
} from '@entities/artifact';
import { Email, EmailDetails } from '@entities/email';
import { AssessmentRecommendation } from '@entities/recommendation';
import { DateUtilsService } from '@shared/utils/date-utils.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BYPASS_SNACKBAR_ON_ERROR } from '../../blocks/interceptor/errorhandler.interceptor';
import { StartAssessmentPayload, StartAssessmentRequest } from '../../routes/request/models/start-assessment-payload';
import { CSRFService, createRequestOption } from '../../shared';
import {
    Assessment,
    AssessmentDetailsView,
    AssessmentSubmission,
    AssessmentView,
    AuditorCancelReason,
    CollectingInformationReason,
    CreateAssessmentQuestionnaireRequest,
    PublicAssessment,
} from './assessment.model';

export type AssessmentResponseType = HttpResponse<Assessment>;
export type AssessmentViewArrayResponseType = HttpResponse<AssessmentView[]>;
export type PublicAssessmentResponseType = HttpResponse<PublicAssessment>;
export type QuestionnaireArtifactResponseType = HttpResponse<QuestionnaireArtifact>;

@Injectable({ providedIn: 'root' })
export class AssessmentService {
    private _assessmentSubmissionResourceUrl = 'api/assessment-submission';
    private _assessmentRequestResourceUrl = 'api/assessment-request';
    private _assessmentResourceUrl = 'api/assessments';

    constructor(
        private _http: HttpClient,
        private _csrfService: CSRFService,
        private _dateUtilsService: DateUtilsService,
    ) {}

    findDetails(id: number): Observable<AssessmentDetailsView> {
        return this._http.get<AssessmentDetailsView>(`${this._assessmentResourceUrl}/${id}/details`);
    }

    findAllByRelationshipId(relationshipId: number): Observable<AssessmentViewArrayResponseType> {
        return this._http
            .get<AssessmentView[]>(`api/relationships/${relationshipId}/assessments`, {
                observe: 'response',
            })
            .pipe(map((res: AssessmentViewArrayResponseType) => this.convertArrayResponse(res)));
    }

    startAssessment(payload: StartAssessmentPayload): Observable<AssessmentResponseType> {
        const {
            relationshipId,
            recipient,
            publicDocumentsInfo,
            optionalMessage,
            documentsOnly,
            assessmentCreatorId,
            saveAsPrimaryVendorContact: saveAs3pContact,
            conciergeAssessment,
        } = payload;
        const { firstName, lastName, email: targetEmail } = recipient || {};

        const convertedPayload: Partial<StartAssessmentRequest> = {
            relationshipId,
            firstName,
            lastName,
            targetEmail,
            publicDocumentsInfo,
            optionalMessage,
            documentsOnly,
            saveAs3pContact,
            assessmentCreatorId,
            conciergeAssessment,
        };

        const formData = !!payload.files ? this.generateFormDataForUpload(payload.files) : new FormData();

        formData.append(
            'assessmentRequest',
            new Blob([JSON.stringify(convertedPayload)], {
                type: 'application/json',
            }),
        );

        return this._http
            .post<Assessment>(this._assessmentResourceUrl, formData, { observe: 'response' })
            .pipe(map((res: AssessmentResponseType) => this.convertResponse(res)));
    }

    submit(assessment: AssessmentSubmission): Observable<AssessmentResponseType> {
        const copy = { ...assessment };
        return this._http.post<AssessmentSubmission>(this._assessmentSubmissionResourceUrl, copy, {
            context: new HttpContext().set(BYPASS_SNACKBAR_ON_ERROR, { forAnyStatus: true }),
            observe: 'response',
        });
    }

    sendReminderEmail(id: number) {
        return this._http
            .post<Assessment>(`${this._assessmentResourceUrl}/${id}/reminder`, {}, { observe: 'response' })
            .pipe(map((res: AssessmentResponseType) => this.convertResponse(res)));
    }

    markAsComplete(id: number, summary: string): Observable<void> {
        return this._http.post<void>(`${this._assessmentResourceUrl}/${id}/complete`, { summary });
    }

    markAsReviewStarted(id: number): Observable<void> {
        return this._http.post<void>(`${this._assessmentResourceUrl}/${id}/submit`, {});
    }

    cancelAsAuditor(
        id: number,
        reason: AuditorCancelReason = AuditorCancelReason.OTHER,
        summary: string = 'This assessment could not be completed.',
    ): Observable<void> {
        const params = createRequestOption({ reason });
        return this._http.post<void>(`${this._assessmentResourceUrl}/${id}/cancel`, { summary }, { params });
    }

    undoCancel(id: number) {
        return this._http
            .post<Assessment>(`${this._assessmentResourceUrl}/${id}/undo-cancel`, {}, { observe: 'response' })
            .pipe(map((res: AssessmentResponseType) => this.convertResponse(res)));
    }

    markAsCollectingInformation(id: number, reason: CollectingInformationReason) {
        const params = createRequestOption({ reason });
        return this._http.post<void>(`${this._assessmentResourceUrl}/${id}/collecting-information`, {}, { params });
    }

    getEmailsForAssessment(id: number): Observable<Email[]> {
        return this._http.get<Email[]>(`${this._assessmentResourceUrl}/${id}/emails`);
    }

    summary(id: number) {
        return this._http.get<{ html: string }>(`${this._assessmentResourceUrl}/${id}/summary`);
    }

    generateExampleAssessmentSummary() {
        return this._http.get<{ html: string }>(`${this._assessmentResourceUrl}/example-summary`);
    }

    getPreRecommendations(assessmentId: number) {
        return this._http.get<AssessmentRecommendation[]>(
            `${this._assessmentResourceUrl}/${assessmentId}/recommendations`,
        );
    }

    findByToken(token: string, secret: string): Observable<PublicAssessmentResponseType> {
        return this._http
            .post<PublicAssessment>(`${this._assessmentRequestResourceUrl}/${token}`, null, {
                headers: new HttpHeaders({ secret: `"${secret}"` }),
                context: new HttpContext().set(BYPASS_SNACKBAR_ON_ERROR, { forAnyStatus: true }),
                observe: 'response',
            })
            .pipe(
                map((res: HttpResponse<PublicAssessment>) => this.convertResponse(res) as PublicAssessmentResponseType),
            );
    }

    deleteFile(token: string, secret: string, artifactId: number): Observable<HttpResponse<any>> {
        return this._http.delete<any>(`${this._assessmentSubmissionResourceUrl}/${token}/files`, {
            headers: new HttpHeaders({ secret: `"${secret}"` }),
            params: createRequestOption({ artifactId }),
            observe: 'response',
        });
    }

    uploadFilesToPublicAssessment(
        requests: UploadFileArtifactRequest[],
        secret: string,
        token: string,
    ): Observable<ArtifactUploadResponse> {
        const formData = new FormData();
        const filesMetaData = [];

        requests.forEach((r) => {
            formData.append('files', r.file);
            filesMetaData.push(r.fileArtifactMetaData);
        });

        formData.append(
            'fileArtifactMetaData',
            new Blob([JSON.stringify(filesMetaData)], {
                type: 'application/json',
            }),
        );

        return this._http.post<ArtifactUploadResponse>(
            `${this._assessmentSubmissionResourceUrl}/${token}/files`,
            formData,
            {
                headers: new HttpHeaders({
                    name: 'X-XSRF-TOKEN',
                    value: this._csrfService.getCSRF(),
                    secret: `"${secret}"`,
                }),
            },
        );
    }

    uploadFiles(
        id: number,
        fileRequests: UploadFileArtifactRequest[],
        urlRequests: UploadURLArtifactRequest[],
    ): Observable<ArtifactUploadResponse> {
        const formData = this.generateFormDataForUpload(fileRequests, urlRequests);
        return this._http.post<ArtifactUploadResponse>(`${this._assessmentResourceUrl}/${id}/artifacts`, formData);
    }

    savePasswordForArtifact(artifactId: number, password: string, secret: string, token: string) {
        const artifactPasswordBody = {
            artifactId,
            password,
        };

        return this._http.put<any>(`${this._assessmentResourceUrl}/${token}/artifact-password`, artifactPasswordBody, {
            observe: 'response',
            headers: new HttpHeaders({ secret: `"${secret}"` }),
        });
    }

    removePasswordForArtifact(artifactId: number, secret: string, token: string) {
        return this._http.delete<any>(`${this._assessmentResourceUrl}/${token}/artifact-password/${artifactId}`, {
            observe: 'response',
            headers: new HttpHeaders({ secret: `"${secret}"` }),
        });
    }

    getQuestionnaireArtifact(token: string, secret: string): Observable<QuestionnaireArtifactResponseType> {
        return this._http.get<any>(`${this._assessmentSubmissionResourceUrl}/${token}/questionnaire`, {
            observe: 'response',
            headers: new HttpHeaders({ secret: `"${secret}"` }),
            context: new HttpContext().set(BYPASS_SNACKBAR_ON_ERROR, { statuses: [404] }),
        });
    }

    createQuestionnaireArtifact(
        createAssessmentQuestionnaireRequest: CreateAssessmentQuestionnaireRequest,
    ): Observable<QuestionnaireArtifact> {
        return this._http.post<QuestionnaireArtifact>(
            `${this._assessmentSubmissionResourceUrl}/questionnaire`,
            createAssessmentQuestionnaireRequest,
        );
    }

    saveQuestionnaireArtifact(
        token: string,
        secret: string,
        questionnaireArtifact: QuestionnaireArtifact,
    ): Observable<QuestionnaireArtifactResponseType> {
        return this._http.put<any>(
            `${this._assessmentSubmissionResourceUrl}/${token}/questionnaire`,
            questionnaireArtifact,
            {
                observe: 'response',
                headers: new HttpHeaders({ secret: `"${secret}"` }),
            },
        );
    }

    completeQuestionnaireArtifact(
        token: string,
        secret: string,
        questionnaireArtifact: QuestionnaireArtifact,
    ): Observable<QuestionnaireArtifactResponseType> {
        return this._http.put<any>(
            `${this._assessmentSubmissionResourceUrl}/${token}/questionnaire/complete`,
            questionnaireArtifact,
            {
                observe: 'response',
                headers: new HttpHeaders({ secret: `"${secret}"` }),
            },
        );
    }

    deleteQuestionnaireArtifact(token: string, secret: string): Observable<void> {
        return this._http.delete<void>(`${this._assessmentSubmissionResourceUrl}/${token}/questionnaire`, {
            headers: new HttpHeaders({ secret: `"${secret}"` }),
        });
    }

    createFollowUpQuestionnaire(assessmentId: number): Observable<void> {
        return this._http.post<void>(`${this._assessmentResourceUrl}/${assessmentId}/followup/questionnaire`, null);
    }

    extendAssessmentExpiration(token: string, secret: string): Observable<void> {
        return this._http.post<void>(`${this._assessmentSubmissionResourceUrl}/${token}/extend-expiration`, null, {
            headers: new HttpHeaders({ secret }),
        });
    }

    forwardAssessmentToNewContact(
        token: string,
        secret: string,
        newContactDetails: { firstName: string; lastName: string; email: string },
    ): Observable<void> {
        return this._http.post<void>(
            `${this._assessmentSubmissionResourceUrl}/${token}/update-recipient`,
            newContactDetails,
            {
                headers: new HttpHeaders({ secret }),
            },
        );
    }

    cancelAssessmentDueToClientVendorNoLongerDoingBusiness(
        token: string,
        secret: string,
        vendorDetails: { firstName: string; lastName: string; email: string },
    ): Observable<void> {
        return this._http.post<void>(`${this._assessmentSubmissionResourceUrl}/${token}/cancel`, vendorDetails, {
            headers: new HttpHeaders({ secret }),
        });
    }

    getEmailDetails(assessmentId: number, messageid: string): Observable<EmailDetails> {
        return this._http.get<EmailDetails>(`${this._assessmentResourceUrl}/${assessmentId}/emails/${messageid}`);
    }

    downloadRiskOutput(assessmentId: number): Observable<HttpResponse<Blob>> {
        return this._http.get(`${this._assessmentResourceUrl}/${assessmentId}/risk-output`, {
            observe: 'response',
            responseType: 'blob',
        });
    }

    private convertResponse(
        res: AssessmentResponseType | PublicAssessmentResponseType,
    ): AssessmentResponseType | PublicAssessmentResponseType {
        const body: Assessment = this.convertItemFromServer(res.body);
        return res.clone({ body });
    }

    private convertArrayResponse(res: AssessmentViewArrayResponseType): AssessmentViewArrayResponseType {
        const jsonResponse = res.body;
        const body: AssessmentView[] = [];
        for (let i = 0; i < jsonResponse.length; i++) {
            body.push(this.convertItemFromServer(jsonResponse[i]));
        }
        return res.clone({ body });
    }

    private convertItemFromServer(assessment: Assessment | PublicAssessment): Assessment | PublicAssessment {
        const copy: Assessment | PublicAssessment = { ...assessment };
        copy.createdDate = this._dateUtilsService.convertDateTimeFromServer(assessment.createdDate);

        if (assessment instanceof Assessment && copy instanceof Assessment) {
            copy.updatedDate = this._dateUtilsService.convertDateTimeFromServer(assessment.updatedDate);
            copy.expirationDate = this._dateUtilsService.convertDateTimeFromServer(assessment.expirationDate);
        }

        copy.artifacts.map((artifact: Artifact) => {
            if (artifact.type === ArtifactType.QUESTIONNAIRE_ARTIFACT) {
                let questionnaireArtifact: QuestionnaireArtifact = artifact;
                questionnaireArtifact.artifactTypeName = 'Questionnaire';
                questionnaireArtifact.fileName = `Questionnaire Artifact ${this._dateUtilsService.formatDate(
                    questionnaireArtifact.createdDate,
                    'yyyy-MM-dd hh:mm a',
                )}`;
            }

            if (artifact.validation) {
                artifact.validation.createdDate =
                    artifact.validation.createdDate &&
                    this._dateUtilsService.convertDateTimeFromServer(artifact.validation.createdDate);
                artifact.validation.expirationDate =
                    artifact.validation.expirationDate &&
                    this._dateUtilsService.convertLocalDateFromServer(artifact.validation.expirationDate);
                artifact.validation.publishedDate =
                    artifact.validation.publishedDate &&
                    this._dateUtilsService.convertLocalDateFromServer(artifact.validation.publishedDate);
                artifact.validation.expiringSoon = isArtifactExpiringSoon(artifact);
                artifact.validation.expired = isArtifactExpired(artifact);
            }
        });

        return copy;
    }

    private generateFormDataForUpload(requests: UploadFileArtifactRequest[], urlRequests?: UploadURLArtifactRequest[]) {
        const formData = new FormData();
        const filesMetaData = [];

        requests.forEach((r) => {
            formData.append('files', r.file);
            filesMetaData.push(r.fileArtifactMetaData);
        });

        formData.append(
            'fileArtifactMetaData',
            new Blob([JSON.stringify(filesMetaData)], {
                type: 'application/json',
            }),
        );

        if (!!urlRequests) {
            formData.append('urlSaveRequests', new Blob([JSON.stringify(urlRequests)], { type: 'application/json' }));
        }

        return formData;
    }
}
