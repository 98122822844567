import { ResolveFn } from '@angular/router';
import { inject } from '@angular/core';
import { map } from 'rxjs/operators';
import { RelationshipService } from '../../../entities/relationship';

export const relationshipNameResolver: ResolveFn<string> = (route, state) => {
    const relationshipId = +route.paramMap.get('relationshipId');
    const relationshipService = inject(RelationshipService);
    return relationshipService.find(relationshipId).pipe(map((res) => res.body?.vendorName));
};
