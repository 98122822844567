import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { DataTypeService } from '../../../entities/data-type';
import {
    getDataTypesQueryRequest,
    getDataTypesQueryRequestFailed,
    getDataTypesQueryRequestSuccess,
} from './data-types.actions';

@Injectable()
export class DataTypesEffects {
    /**
     * Data Types Query Request Effect
     * Triggers when `getDataTypesQueryRequest` gets dispatched
     * Executes `DataTypeService.query()` to retrieve the results
     */
    getDataTypesQueryRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(getDataTypesQueryRequest),
            switchMap(({ enabled }) =>
                this._dataTypeService.query({ enabled }).pipe(
                    map((response) => getDataTypesQueryRequestSuccess({ results: response.body })),
                    catchError(() => of(getDataTypesQueryRequestFailed())),
                ),
            ),
        ),
    );

    constructor(
        private _actions$: Actions,
        private _dataTypeService: DataTypeService,
    ) {}
}
