<div *dynamicContent="breadcrumbsContainerToken">
    <ol class="breadcrumb breadcrumb px-0 pb-0">
        <li class="breadcrumb-item"><a [routerLink]="['/']">Home</a></li>
        <li class="breadcrumb-item active">User Profile</li>
    </ol>
</div>
<div class="content-heading">
    <div>User Profile</div>
</div>
<div *ngIf="user$ | async as user">
    <div *ngIf="!!user; else skeleton">
        <div class="row">
            <div class="col-xl-3">
                <div class="card card-default">
                    <div class="card-body">
                        <div class="text-center">
                            <h3 class="mt-0">{{ user.email }}</h3>
                        </div>
                        <hr />
                        <ul class="list-unstyled px-4">
                            <li>
                                <em class="fa fa-calendar fa-fw me-3"></em>{{ user.createdDate | date: 'mediumDate' }}
                            </li>
                            <li><em class="fa fa-briefcase fa-fw me-3"></em>{{ user.orgName }}</li>
                        </ul>
                    </div>
                </div>
            </div>
            <ng-container *ngIf="isLoggedInUser$ | async">
                <ng-container *jhiHasAnyAuthority="[VisoUserRole.OrgAdmin]">
                    <div class="col-xl-3">
                        <div class="card card-default">
                            <div class="card-body">
                                <div class="text-center">
                                    <h3 class="mt-0">API Token</h3>
                                </div>
                                <hr />
                                <ng-container *ngIf="hasActiveApiToken; else noActiveApiToken">
                                    <ng-container *ngIf="activeApiTokenDetails$ | async as activeToken">
                                        <div class="input-group mb-3">
                                            <input
                                                type="password"
                                                class="form-control"
                                                placeholder="****************************************"
                                                readonly
                                            />
                                            <div class="input-group-append">
                                                <button
                                                    mat-button
                                                    visoButton="warning"
                                                    mat-stroked-button
                                                    class="no-left-radius"
                                                    type="button"
                                                    (click)="revokeApiToken()"
                                                >
                                                    Revoke
                                                </button>
                                            </div>
                                        </div>
                                        <div>Created Date: {{ activeToken.createdDate | date }}</div>
                                        <div>
                                            Expiration Date:
                                            {{ activeToken.expirationDate | date }}
                                        </div>
                                    </ng-container>
                                </ng-container>
                                <ng-template #noActiveApiToken>
                                    <form [formGroup]="apiAccessFormGroup" (submit)="generateApiToken()">
                                        <div class="form-group required">
                                            <label>Expiration Period</label>
                                            <ngx-select
                                                [allowClear]="true"
                                                [items]="apiKeyExpirationPeriods$ | async"
                                                formControlName="jwtExpirationPeriod"
                                                placeholder="Select an Expiration Period"
                                                optionTextField="name"
                                                optionValueField="enumValue"
                                            ></ngx-select>
                                        </div>
                                        <div *ngIf="showCustomDateField$ | async" class="form-group required">
                                            <label for="customDate">Custom Date</label>
                                            <div class="date-form-group">
                                                <input
                                                    id="customDate"
                                                    matInput
                                                    [matDatepicker]="customDatePicker"
                                                    class="form-control"
                                                    placeholder="yyyy-mm-dd"
                                                    formControlName="customExpirationDate"
                                                    [min]="today"
                                                />
                                                <mat-datepicker-toggle [for]="customDatePicker"></mat-datepicker-toggle>
                                                <mat-datepicker #customDatePicker></mat-datepicker>
                                            </div>
                                        </div>
                                        <div class="form-group text-end">
                                            <button
                                                type="submit"
                                                mat-button
                                                visoButton="primary"
                                                [disabled]="apiAccessFormGroup.invalid"
                                                toggleDisableOnActions
                                                [disablingActions]="[UserProfileActions.GenerateApiTokenRequest]"
                                                [enablingActions]="[
                                                    UserProfileActions.GenerateApiTokenRequestSuccess,
                                                    UserProfileActions.GenerateApiTokenRequestFailed,
                                                ]"
                                            >
                                                Generate token
                                            </button>
                                        </div>
                                    </form>
                                </ng-template>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </ng-container>
        </div>
    </div>
</div>

<ng-template #skeleton>
    <div class="row">
        <div class="col-xl-3">
            <div class="card card-default">
                <div class="card-body">
                    <div class="header-text">
                        <div class="mt-0 skeleton-item" style="display: inline-block; height: 1.5rem"></div>
                    </div>
                    <hr />
                    <ul class="list-unstyled px-4">
                        <li class="list-item-group">
                            <em class="fa fa-calendar fa-fw me-3"></em>
                            <div class="skeleton-item"></div>
                        </li>
                        <li class="list-item-group">
                            <em class="fa fa-briefcase fa-fw me-3"></em>
                            <div class="skeleton-item"></div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</ng-template>
