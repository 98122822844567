import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { QuillModule } from 'ngx-quill';
import { AnnouncementEffects } from './redux/announcement.effects';
import { AnnouncementModalComponent } from './announcement-modal/announcement-modal.component';
import { SharedModule } from '../../shared';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
    declarations: [AnnouncementModalComponent],
    imports: [
        CommonModule,
        SharedModule,
        QuillModule,
        EffectsModule.forFeature([AnnouncementEffects]),
        MatButtonModule,
    ],
    exports: [AnnouncementModalComponent],
})
export class AnnouncementModule {}
