import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { NgxSelectModule } from 'ngx-select-ex';
import { FileUploadModule } from '../file-upload';
import { DocumentFileModule } from '../document-file/document-file.module';
import { AuthDirectivesModule } from '../auth-directives';
import { VisoButtonModule } from '../viso-button/viso-button.module';
import { UploadArtifactsModalComponent } from './components';

@NgModule({
    declarations: [UploadArtifactsModalComponent],
    imports: [
        CommonModule,
        FileUploadModule,
        TabsModule,
        ReactiveFormsModule,
        NgxSelectModule,
        DocumentFileModule,
        AuthDirectivesModule,
        VisoButtonModule,
        MatInputModule,
        MatFormFieldModule,
    ],
    exports: [UploadArtifactsModalComponent],
})
export class UploadArtifactsModule {}
