import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ChartDataset, ChartOptions } from 'chart.js';
import { RiskAssessment } from 'src/main/webapp/app/entities/risk-assessment';
import { chartOptions } from '../../graph-data-constants';

@Component({
    selector: 'app-risk-timeline-export',
    templateUrl: './risk-timeline-export.component.html',
    styleUrls: ['./risk-timeline-export.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RiskTimelineExportComponent {
    @Input()
    riskAssessments: RiskAssessment[] = [];

    @Input()
    riskAssessmentsDatasets: ChartDataset[] = [];

    chartOptions: ChartOptions = {
        ...chartOptions,
        responsive: false,
    };
}
