import { LayoutType } from '@shared/model/layout-type';
import { SpotlightTourType } from '@shared/spotlight-tour/steps';

export const headerStateKey = 'header';

export const initialState: LayoutState = {
    layout: LayoutType.Full,
    assessmentHeaderColor: undefined,
    assessmentLogoVisible: true,
    currentPageTourType: null,
};

export interface LayoutState {
    layout: LayoutType;
    assessmentHeaderColor?: string | null;
    assessmentLogoVisible: boolean;
    currentPageTourType: SpotlightTourType;
}
