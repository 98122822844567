import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { filter, take } from 'rxjs/operators';
import { getUserAccount } from '../../routes/session/redux/session.selectors';

@Injectable()
export class ThemesService {
    private defaultTheme = 'visoDark2_0';
    private _currentTheme: string;
    private styleLink: HTMLLinkElement;

    public get currentTheme(): string {
        return this._currentTheme ?? this.defaultTheme;
    }

    public set currentTheme(value: string) {
        this._currentTheme = value;
        this.styleLink.href = `${value}.css`;
    }

    constructor(private _store$: Store) {
        this._store$
            .pipe(
                select(getUserAccount),
                filter((account) => !!account),
                take(1),
            )
            .subscribe(() => {
                this.styleLink = document.createElement('link');
                this.styleLink.rel = 'stylesheet';
                document.head.appendChild(this.styleLink);
                this.styleLink.href = `${this.currentTheme}.css`;
            });
    }

    setTheme(name: string) {
        switch (name) {
            case 'LIGHT':
                this.currentTheme = 'visoLight2_0';
                break;
            case 'DARK':
                this.currentTheme = 'visoDark2_0';
                break;
            case 'AUDITOR':
                this.currentTheme = 'visoAuditor2_0';
                break;
        }
    }
}
