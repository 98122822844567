export enum TagColorEnum {
    COLOR_01 = 'COLOR_01',
    COLOR_02 = 'COLOR_02',
    COLOR_03 = 'COLOR_03',
    COLOR_04 = 'COLOR_04',
    COLOR_05 = 'COLOR_05',
    COLOR_06 = 'COLOR_06',
    COLOR_07 = 'COLOR_07',
    COLOR_08 = 'COLOR_08',
    COLOR_09 = 'COLOR_09',
    COLOR_10 = 'COLOR_10',
}

export enum InternalTagColorEnum {
    DEFAULT = 'DEFAULT',
}

export interface ColorPickerConfig {
    primary: string;
    background: string;
}

export type TagColor = {
    [key in keyof typeof TagColorEnum]: ColorPickerConfig;
};

export type InternalTagColor = {
    [key in keyof typeof InternalTagColorEnum]: ColorPickerConfig;
};

export const TagColors: TagColor = {
    COLOR_01: {
        primary: '#000000',
        background: '#E6EAF0',
    },
    COLOR_02: {
        primary: '#00838F',
        background: '#E9F3F4',
    },
    COLOR_03: {
        primary: '#25AAAA',
        background: '#E4EEEB',
    },
    COLOR_04: {
        primary: '#00667A',
        background: '#CBDEE3',
    },
    COLOR_05: {
        primary: '#1069CE',
        background: '#EAF3FF',
    },
    COLOR_06: {
        primary: '#4669E8',
        background: '#EDF1FF',
    },
    COLOR_07: {
        primary: '#2042D1',
        background: '#E1E7FF',
    },
    COLOR_08: {
        primary: '#B08A00',
        background: '#F5EED3',
    },
    COLOR_09: {
        primary: '#D65A00',
        background: '#F9ECE3',
    },
    COLOR_10: {
        primary: '#B73737',
        background: '#FFEBEB',
    },
};

export const InternalTagColors: InternalTagColor = {
    DEFAULT: {
        primary: '#00838F',
        background: '#F5F7FA',
    },
};

export const AllTagColors: TagColor | InternalTagColor = {
    ...TagColors,
    ...InternalTagColors,
};
