const THRESHOLD = 149;

export function isFgBlack(bgColorHex?: string, defaultValue?: boolean) {
    if (!bgColorHex) {
        return defaultValue ?? true;
    }
    const r = parseInt(bgColorHex.substring(1, 3), 16);
    const g = parseInt(bgColorHex.substring(3, 5), 16);
    const b = parseInt(bgColorHex.substring(5, 7), 16);
    return THRESHOLD < r * 0.299 + g * 0.587 + b * 0.114;
}
