<ng-container *ngIf="!onPrint">
    <div *dynamicContent="breadcrumbsContainerToken">
        <ol class="breadcrumb breadcrumb px-0 pb-0">
            <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
            <li class="breadcrumb-item">
                <a [routerLink]="'/relationships'" [queryParams]="previousNavigatedUrlQueryParams"
                    >Relationships {{ isPreviousViewFiltered ? '(filtered)' : '' }}</a
                >
            </li>
            <li class="breadcrumb-item active">{{ request?.vendorName }}</li>
        </ol>
    </div>
</ng-container>

<div class="content-heading">
    <div>
        <span class="header-title" data-pw="rdpVendorName">
            <ng-container *jhiHasAnyAuthority="[Roles.Auditor, Roles.Support]">
                {{ request?.clientName }} -
            </ng-container>
            <img *ngIf="request?.vendorFaviconUrl" [src]="request.vendorFaviconUrl" alt="Relationship logo" />
            {{ request?.vendorName }}
        </span>
    </div>
    <div class="btn-group ms-auto">
        <app-head-button-group
            exportRemovableAction
            [relationship]="request"
            [hasOrgUserOnboardEnabled]="hasOrgUserOnboardEnabled"
            [hasLicenseManagementEnabled]="hasLicenseManagementEnabled"
            [relationshipStatus]="request?.status"
            [currentUser]="currentUser"
            [onPrint]="onPrint"
            [latestAssessmentStatus]="latestAssessmentStatus"
            [isUpForRecertification]="isUpForRecertification"
            [businessOwner]="businessOwner"
            (onboard)="onboard.emit()"
            (archive)="archive.emit()"
            (markAsNotOnboarded)="markAsNotOnboarded.emit()"
            (startAssessment)="startAssessment.emit()"
            (confirmRecertifyAssessment)="confirmRecertifyAssessment.emit()"
            (downloadPDF)="downloadPDF.emit()"
            (openCreateRiskAdvisory)="openCreateRiskAdvisory.emit()"
        ></app-head-button-group>
    </div>
</div>
