import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { take } from 'rxjs/operators';
import { VisoUserRole } from '../../../entities/viso-user';
import { getUserAuthority } from '../../../routes/session/redux/session.selectors';
import { AppState } from '../../redux/state';

/**
 * @whatItDoes Conditionally includes an HTML element if current user has any
 * of the authorities passed as the `expression`.
 *
 * @howToUse
 * ```
 *     <some-element *jhiHasAnyAuthority="VisoUserRole.Admin">...</some-element>
 *
 *     <some-element *jhiHasAnyAuthority="[VisoUserRole.Admin, VisoUserRole.OrgAdmin]">...</some-element>
 * ```
 */
@Directive({
    selector: '[jhiHasAnyAuthority]',
})
export class HasAnyAuthorityDirective {
    private authorities: VisoUserRole[];

    constructor(
        private _templateRef: TemplateRef<any>,
        private _viewContainerRef: ViewContainerRef,
        private _store$: Store<AppState>,
    ) {}

    @Input()
    set jhiHasAnyAuthority(value: VisoUserRole | VisoUserRole[]) {
        this.authorities = Array.isArray(value) ? value : [value];
        this.updateView();
    }
    private updateView(): void {
        this._viewContainerRef.clear();
        this._store$.pipe(select(getUserAuthority(this.authorities)), take(1)).subscribe((hasAnyAuthority) => {
            if (hasAnyAuthority) {
                this._viewContainerRef.createEmbeddedView(this._templateRef);
            }
        });
    }
}
