<section class="sign-up-container">
    <section class="container registration-panel">
        <app-registration-panel></app-registration-panel>
    </section>
    <section class="registration-form">
        <div class="registration-form-container">
            <div *ngIf="activationInProgress" class="sk-spinner sk-spinner-pulse"></div>

            <div *ngIf="accountActivated" class="dark form-title">
                <i class="fa fa-check-circle check-circle form-title-icon" aria-hidden="true"></i>
                <div>Your email address has been verified!</div>
            </div>
            <div *ngIf="accountActivationFailed" class="panel-title dark form-title">
                <i class="fa fa-exclamation-circle exclamation-circle form-title-icon" aria-hidden="true"></i>
                <div>An error has occurred.</div>
            </div>
            <div *ngIf="accountActivationExpired" class="panel-title dark form-title">
                <i class="fa fa-exclamation-circle exclamation-circle form-title-icon" aria-hidden="true"></i>
                <div>Email verification link has expired.</div>
            </div>

            <ng-container *ngIf="accountActivated">
                <div class="feedback login-btn">
                    <button mat-button visoButton="primary" class="mt-2" (click)="login()">Log In Now</button>
                </div>
            </ng-container>

            <ng-container *ngIf="accountActivationFailed">
                <div class="feedback">
                    <div class="body-text">Please try the link again.</div>
                </div>
            </ng-container>

            <ng-container *ngIf="accountActivationExpired">
                <div class="feedback">
                    <div class="body-text">We've sent a new link to your email address.</div>
                </div>
            </ng-container>
        </div>
    </section>
</section>
