import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { RouterNavigationAction, ROUTER_NAVIGATED } from '@ngrx/router-store';
import { of } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { NgbModalWrapperService } from '../../../../shared/modal/ngb-modal-wrapper.service';
import { ContactService } from '../../../contact/contact.service';
import { AddSubscriberModalComponent } from '../../add-subscriber/add-subscriber-modal.component';
import {
    createAndSubscribeContactRequest,
    createAndSubscribeContactRequestFailed,
    openSubscribeContactModal,
} from '../actions/subscriber.actions';
import { subscribeContactRequest } from '../request.actions';

@Injectable()
export class SubscriberEffects {
    triggerOpenSubscribeContactModal$ = createEffect(() =>
        this._actions$.pipe(
            ofType(ROUTER_NAVIGATED),
            map((action: RouterNavigationAction) =>
                action.payload.routerState.root.children.find((route) => route.outlet === 'popup'),
            ),
            filter((route) => !!route && route.routeConfig.path === 'relationships/:requestId/subscriber'),
            map((route) => ({ requestId: route.params.requestId })),
            mergeMap(({ requestId }) => [openSubscribeContactModal({ requestId })]),
        ),
    );

    openSubscribeContactModal$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(openSubscribeContactModal),
                tap(({ requestId }) =>
                    this._modalService.open<AddSubscriberModalComponent>(
                        AddSubscriberModalComponent,
                        {
                            requestId,
                        },
                        { windowClass: 'subscribeContactModal' },
                    ),
                ),
            ),
        { dispatch: false },
    );

    createAndSubscribeContactRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(createAndSubscribeContactRequest),
            switchMap(({ requestId, contact }) =>
                this._contactService.create(contact).pipe(
                    map((response) => response.body),
                    map((contact) => subscribeContactRequest({ requestId, contactId: contact?.id })),
                    catchError(() => of(createAndSubscribeContactRequestFailed())),
                ),
            ),
        ),
    );

    constructor(
        private _actions$: Actions,
        private _modalService: NgbModalWrapperService,
        private _contactService: ContactService,
    ) {}
}
