import { Route } from '@angular/router';
import { ArtifactManagementComponent } from './artifact-management.component';

export const artifactManagementRoute: Route = {
    path: 'artifact-management',
    component: ArtifactManagementComponent,
    data: {
        pageTitle: 'Artifact Management',
    },
};
