import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { TabsetComponent } from 'ngx-bootstrap/tabs';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { fadeAnimation } from '../../shared/animation/basic';
import { SnackbarService } from '../../shared/components/snackbar/snackbar.service';
import { confirmUploadedImportRequestSuccess } from './redux/bulk-import.actions';
import { BREADCRUMB_CONTAINER_TOKEN } from '../../shared/dynamic-content/dynamic-content-injector';

@Component({
    selector: 'app-bulk-import-upload',
    templateUrl: './bulk-import.component.html',
    styleUrls: ['./bulk-import.scss'],
    animations: [fadeAnimation],
})
export class BulkImportComponent implements OnInit, OnDestroy {
    public readonly breadcrumbsContainerToken = BREADCRUMB_CONTAINER_TOKEN;

    @ViewChild('importTabs', { static: false }) staticTabs?: TabsetComponent;

    private _unsub = new Subject<void>();

    constructor(
        private _snackbarService: SnackbarService,
        private _actions$: Actions,
    ) {}

    ngOnInit(): void {
        this._actions$
            .pipe(
                ofType(confirmUploadedImportRequestSuccess),
                tap(() =>
                    this._snackbarService.success('Your spreadsheet has been submitted to VISO', {
                        action: {
                            text: 'View Import History',
                            onClick: () => {
                                this.selectTab(1);
                            },
                        },
                    }),
                ),
                takeUntil(this._unsub),
            )
            .subscribe();
    }

    selectTab(tabId: number) {
        if (this.staticTabs?.tabs[tabId]) {
            this.staticTabs.tabs[tabId].active = true;
        }
    }

    resizeWindow(): void {
        setTimeout(() => {
            window.dispatchEvent(new Event('resize'));
        }, 50);
    }

    ngOnDestroy(): void {
        this._unsub.next();
    }
}
