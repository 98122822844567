import { Injectable, OnDestroy, TemplateRef, Type } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { first, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { ModalData } from '../model/modal-structures';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';

@Injectable({
    providedIn: 'root',
})
export class MatDialogWrapperService implements OnDestroy {
    private _unsub$ = new Subject<void>();
    private _onPopupOutlet = false;

    constructor(
        private _router: Router,
        private _modalService: MatDialog,
    ) {
        this._router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                takeUntil(this._unsub$),
            )
            .subscribe((event: NavigationEnd) => {
                const url = event.url;
                this._onPopupOutlet = url.indexOf('(popup:') !== -1;
            });
    }

    open<TComponent, TResult = any>(
        content: Type<TComponent> | TemplateRef<any>,
        options?: {
            inputs?: ModalData<TComponent>;
            config?: MatDialogConfig;
            metadata?: Record<string, any>;
        },
    ): MatDialogRef<TComponent, TResult> {
        const { inputs, config } = options || {};
        const defaultConfig: MatDialogConfig = { width: '100vw', maxWidth: 600, autoFocus: 'first-heading' };
        const modalRef = this._modalService.open(content, {
            ...defaultConfig,
            ...config,
        });
        if (!!inputs) {
            Object.keys(inputs).forEach((property) => {
                modalRef.componentInstance[property] = inputs[property];
            });
        }
        modalRef
            .afterClosed()
            .pipe(first())
            .subscribe(() => this.onSuccessOrReject());
        return modalRef;
    }

    private onSuccessOrReject(): void {
        if (this._onPopupOutlet) {
            this._router.navigate(
                [
                    {
                        outlets: {
                            popup: null,
                        },
                    },
                ],
                {
                    replaceUrl: true,
                },
            );
        }
    }

    ngOnDestroy(): void {
        this._unsub$.next();
    }
}
