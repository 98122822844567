import {
    AfterViewInit,
    Component,
    ComponentRef,
    Directive,
    HostBinding,
    Input,
    OnDestroy,
    OnInit,
    TemplateRef,
    ViewContainerRef,
} from '@angular/core';
import { select, Store } from '@ngrx/store';
import { combineLatest, ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { VisoUser } from '@entities/viso-user';
import { getUserAccount } from '../../session/redux/session.selectors';
import { TALK_TO_SALES_URL } from '@shared/constants/url.constants';

enum DisablingReason {
    NoMoreOnboards,
}

@Component({
    selector: 'disable-onboard-wrapper',
    template: `
        <span
            #pop="bs-popover"
            [popover]="popoverDisabled ? null : noOnboardsLeftTemplate"
            [outsideClick]="true"
            [containerClass]="'assessment-disabled-popover'"
            container="body"
            (mouseenter)="pop.show()"
        >
            <ng-container *ngTemplateOutlet="elementTemplate"></ng-container>
        </span>
        <ng-template #noOnboardsLeftTemplate>
            <div class="popover-detail" (mouseleave)="pop.hide()">
                <div class="header">
                    <strong>You have reached your limit of onboarded vendors.</strong>
                    <em class="fas fa-clipboard-list text-primary"></em>
                </div>
                <div class="body mt-2">
                    Manage third-party risks with AI-powered automation that scales with your organization.
                </div>
                <div class="footer text-bold">
                    <p class="m-1">Unlock the ability to onboard more vendors.</p>
                    <p>
                        Click
                        <a class="viso-link" [externalLink]="TALK_TO_SALES_URL">
                            <strong>here</strong>
                        </a>
                        to contact sales.
                    </p>
                </div>
            </div>
        </ng-template>
    `,
})
export class DisableOnboardWrapperComponent {
    @Input()
    elementTemplate: TemplateRef<any>;

    @Input()
    disablingReason: DisablingReason;

    @HostBinding('class')
    @Input()
    customHostClass: string = '';

    TALK_TO_SALES_URL = TALK_TO_SALES_URL;

    get popoverDisabled(): boolean {
        return this.disablingReason !== DisablingReason.NoMoreOnboards;
    }

    constructor(public viewContainerRef: ViewContainerRef) {}
}

@Directive({
    selector: '[disableOnboardOnRules]',
})
export class DisableOnboardOnRulesDirective implements OnInit, AfterViewInit, OnDestroy {
    @Input()
    set disableOnboardOnRules(value: VisoUser) {
        this._relationship$.next(value);
    }

    @Input()
    disableOnboardOnRulesHostCustomClass: string = '';

    private _unsub$ = new Subject<void>();
    private _relationship$ = new ReplaySubject<VisoUser>();
    private _wrapperContainerRef: ComponentRef<DisableOnboardWrapperComponent>;

    constructor(
        private _templateRef: TemplateRef<any>,
        private _viewContainerRef: ViewContainerRef,
        private _store$: Store,
    ) {}

    ngOnInit(): void {
        this._wrapperContainerRef = this._viewContainerRef.createComponent(DisableOnboardWrapperComponent);
        this._wrapperContainerRef.instance.elementTemplate = this._templateRef;
        this._wrapperContainerRef.instance.customHostClass = this.disableOnboardOnRulesHostCustomClass;
        this._wrapperContainerRef.changeDetectorRef.detectChanges();
    }

    ngAfterViewInit(): void {
        combineLatest([this._store$.pipe(select(getUserAccount)), this._relationship$])
            .pipe(takeUntil(this._unsub$))
            .subscribe(([account, relationship]) => {
                let disablingReason: DisablingReason | null = null;
                if (!this.doesAccountHaveAvailableOnboards(account)) {
                    disablingReason = DisablingReason.NoMoreOnboards;
                }

                if (disablingReason !== null) {
                    return this.disableButtonAndSetPopover(disablingReason);
                }
                return this.enableButtonAndSetNoPopover();
            });
    }

    private getButton(): HTMLButtonElement {
        return (this._wrapperContainerRef.location.nativeElement as HTMLElement).querySelector(
            'button',
        ) as HTMLButtonElement;
    }

    private enableButtonAndSetNoPopover(): void {
        this._wrapperContainerRef.instance.disablingReason = null;
        this._wrapperContainerRef.changeDetectorRef.detectChanges();
        this.getButton().disabled = false;
    }

    private disableButtonAndSetPopover(reason: DisablingReason) {
        this._wrapperContainerRef.instance.disablingReason = reason;
        this._wrapperContainerRef.changeDetectorRef.detectChanges();
        this.getButton().disabled = true;
    }

    private doesAccountHaveAvailableOnboards(account: VisoUser): boolean {
        return (
            ((account.clientLicense?.maxRelationshipsOnboarded -
                account.clientLicense?.relationshipsOnboardedCount) as number) > 0 ||
            account.clientLicense?.maxRelationshipsOnboarded === -1
        );
    }

    ngOnDestroy(): void {
        this._unsub$.next();
    }
}
