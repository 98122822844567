<div class="modal-header">
    <h4 class="modal-title">Delete Tag</h4>
    <button type="button" class="btn-close" data-dismiss="modal" aria-hidden="true" (click)="clear()"></button>
</div>

<p class="mx-3 mt-3 pb-0">
    <span>
        Are you sure you want to delete <strong>{{ selectedTag.name }}?</strong><br />
        {{ selectedTag.numberOfUses }} relationship{{ selectedTag.numberOfUses !== 1 ? 's' : '' }} will be updated.
    </span>
</p>

<div class="modal-footer d-flex justify-content-end main-action">
    <button type="button" visoButton="secondary" mat-button class="float-end" data-dismiss="modal" (click)="clear()">
        <span>Cancel</span>
    </button>
    <button
        mat-button
        visoButton="primary"
        class="float-end"
        (click)="deleteTag(selectedTag.id)"
        toggleDisableOnActions
        [disablingActions]="[TagActionsRef.DeleteTagRequest]"
        [enablingActions]="[TagActionsRef.DeleteTagRequestSuccess, TagActionsRef.DeleteTagRequestFailed]"
    >
        <span>Delete Tag</span>
    </button>
</div>
