<ng-container *ngIf="!licenseHasManagedVendorsAvailable">
    <div class="row mt-3 mb-4">
        <div class="col">
            <div class="sales-cta rounded p-2">
                Unlock the ability to monitor this relationship. Click
                <a class="viso-link" [externalLink]="TALK_TO_SALES_URL">
                    <strong>here</strong>
                </a>
                to contact sales.
            </div>
        </div>
    </div>
</ng-container>
<div *disableOnTrial="true; hidePopover: true; displayLock: false">
    <div class="row">
        <div class="col">
            <h4>Choose lifecycle events to track for lifecycle management.</h4>
        </div>
    </div>
    <form [formGroup]="lifecycleManagementFormGroup">
        <div class="row mb-3">
            <div class="col">
                <p>
                    Onboarded relationships get access to lifecycle management. We will automatically trigger collection
                    and analysis efforts to maintain the latest information, so you don't have to.
                </p>
            </div>
        </div>
        <div class="row mb-5" formGroupName="artifactUpdateSettings">
            <div class="col form-group mb-0">
                <label>Artifact Validity</label>
                <p class="mb-0">
                    We'll reach out to the specified contact for the latest versions of artifacts 30 days before the end
                    of the validity period.
                </p>
            </div>
            <div class="col-lg-1"></div>
            <div class="col">
                <div class="row">
                    <div class="col">
                        <div class="fixed-width">
                            <app-radiobutton-group
                                formControlName="artifactUpdateEnabled"
                                [type]="'toggle'"
                                [direction]="'row'"
                                [size]="'dynamic'"
                                [radiobuttonGroupName]="'toggleArtifactUpdateButtonGroup'"
                            >
                                <ng-template radiobutton-group-item let-forItem="forItem" [value]="true">
                                    <label class="mx-2" [for]="forItem">On</label>
                                </ng-template>
                                <ng-template radiobutton-group-item let-forItem="forItem" [value]="false">
                                    <label class="mx-2" [for]="forItem">Off</label>
                                </ng-template>
                            </app-radiobutton-group>
                            <ng-container *ngIf="!(userIsTrial$ | async) && showLatestExpiringArtifactDate">
                                <div class="mt-3">
                                    <span class="hint-label">
                                        Next artifact valid until {{ dateOfNextArtifactExpiration | date }}
                                    </span>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
                <ng-container *ngIf="showArtifactUpdateOptions">
                    <div class="row mt-3">
                        <div class="col">
                            <p>Who should we contact?</p>
                            <mat-radio-group
                                class="d-flex flex-column fixed-width"
                                color="primary"
                                formControlName="artifactUpdateType"
                            >
                                <mat-radio-button [value]="RecertificationType.MANUAL">
                                    <div>Business owner</div>
                                    <div class="user-name">
                                        {{ businessOwner.firstName + ' ' + businessOwner.lastName }}
                                    </div>
                                    <div>{{ businessOwner.email }}</div>
                                </mat-radio-button>
                                <mat-radio-button
                                    class="mt-3"
                                    [disabled]="!primaryContact"
                                    [value]="RecertificationType.AUTOMATIC"
                                >
                                    <ng-container *ngIf="!!primaryContact; else noPrimaryContact">
                                        <div>Third party contact</div>
                                        <div class="user-name">
                                            {{ primaryContact.firstName + ' ' + primaryContact.lastName }}
                                        </div>
                                        <div>{{ primaryContact.email }}</div>
                                    </ng-container>
                                    <ng-template #noPrimaryContact>
                                        <div>Third party contact</div>
                                        <div class="user-name">None specified</div>
                                        <div>
                                            Provide contact details to select a third party contact for lifecycle
                                            management.
                                        </div>
                                    </ng-template>
                                </mat-radio-button>
                            </mat-radio-group>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
        <mat-divider></mat-divider>
        <div class="row mt-5" formGroupName="recertificationSettings">
            <div class="col form-group mb-0">
                <label>Recertification</label>
                <p class="mb-0">
                    We'll automatically assess or remind you to reach out to the third party and make sure you have the
                    latest business context and information.
                </p>
            </div>
            <div class="col-lg-1"></div>
            <div class="col">
                <div class="row">
                    <div class="col">
                        <div class="fixed-width">
                            <app-radiobutton-group
                                formControlName="recertificationEnabled"
                                [type]="'toggle'"
                                [direction]="'row'"
                                [size]="'dynamic'"
                                [radiobuttonGroupName]="'toggleRecertificationEnabledButtonGroup'"
                            >
                                <ng-template radiobutton-group-item let-forItem="forItem" [value]="true">
                                    <label class="mx-2" [for]="forItem">On</label>
                                </ng-template>
                                <ng-template radiobutton-group-item let-forItem="forItem" [value]="false">
                                    <label class="mx-2" [for]="forItem">Off</label>
                                </ng-template>
                            </app-radiobutton-group>
                        </div>
                    </div>
                </div>
                <ng-container *ngIf="showRecertificationOptions">
                    <div class="row mt-4">
                        <div class="col">
                            <mat-form-field class="fixed-width">
                                <mat-label>Based on</mat-label>
                                <div class="date-form-group">
                                    <input
                                        matInput
                                        [matDatepicker]="basedOnDatePicker"
                                        class="form-control"
                                        placeholder="yyyy-mm-dd"
                                        formControlName="basedOnDate"
                                    />
                                    <mat-datepicker-toggle [for]="basedOnDatePicker"></mat-datepicker-toggle>
                                    <mat-datepicker #basedOnDatePicker></mat-datepicker>
                                </div>
                                <mat-hint class="text-light">MM/DD/YYYY</mat-hint>
                            </mat-form-field>
                            <ng-container *ngIf="showDateResetLink">
                                <button class="px-0" mat-button visoButton="link" (click)="resetBasedOnDate()">
                                    Reset to last assessed date
                                </button>
                            </ng-container>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col form-group">
                            <div class="row">
                                <div class="col">
                                    <mat-form-field class="fixed-width">
                                        <mat-label>Frequency</mat-label>
                                        <mat-select formControlName="reviewFrequency">
                                            <mat-option
                                                *ngFor="
                                                    let reviewFrequency of ReviewFrequency
                                                        | enumToArray: ReviewFrequencyLabels
                                                "
                                                [value]="reviewFrequency.value"
                                            >
                                                {{ reviewFrequency.label }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ng-container *ngIf="showRecertificationDateHint">
                        <div class="row">
                            <div class="col">
                                <div class="fixed-width">
                                    <ng-container
                                        *ngIf="showNoPreviousAssessmentDateHint; else recertificationDateHint"
                                    >
                                        <span class="text-light">
                                            Because you have not assessed this third party before, the initial
                                            assessment will occur on {{ recertificationDate | date }}
                                        </span>
                                    </ng-container>
                                    <ng-template #recertificationDateHint>
                                        <span class="text-light">
                                            Next recertification on {{ nextRecertificationDate | date }}
                                        </span>
                                    </ng-template>
                                </div>
                            </div>
                        </div>
                    </ng-container>

                    <div class="row mt-3">
                        <div class="col">
                            <p>Who should we contact?</p>
                            <mat-radio-group
                                class="d-flex flex-column fixed-width"
                                color="primary"
                                formControlName="recertificationType"
                            >
                                <mat-radio-button [value]="RecertificationType.MANUAL">
                                    <div>Business owner</div>
                                    <div class="user-name">
                                        {{ businessOwner.firstName + ' ' + businessOwner.lastName }}
                                    </div>
                                    <div>{{ businessOwner.email }}</div>
                                </mat-radio-button>
                                <mat-radio-button
                                    class="mt-3"
                                    [disabled]="!primaryContact"
                                    [value]="RecertificationType.AUTOMATIC"
                                >
                                    <ng-container *ngIf="!!primaryContact; else noPrimaryContact">
                                        <div>Third party contact</div>
                                        <div class="user-name">
                                            {{ primaryContact.firstName + ' ' + primaryContact.lastName }}
                                        </div>
                                        <div>{{ primaryContact.email }}</div>
                                    </ng-container>
                                    <ng-template #noPrimaryContact>
                                        <div>Third party contact</div>
                                        <div class="user-name">None specified</div>
                                        <div>
                                            Provide contact details to select a third party contact for lifecycle
                                            management.
                                        </div>
                                    </ng-template>
                                </mat-radio-button>
                            </mat-radio-group>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </form>
</div>
