import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
    onRelationshipRtpEventCancelled,
    onRelationshipRtpEventFailed,
    onRelationshipRtpEventSuccess,
    onRelationshipRtpEvent,
} from '../actions/rtp.actions';
import { Injectable } from '@angular/core';
import { catchError, map, switchMap, takeUntil } from 'rxjs/operators';
import { ApolloError } from '@apollo/client';
import { of } from 'rxjs';
import { RelationshipService } from '../../../../entities/relationship';

@Injectable()
export class RtpEffects {
    onRelationshipRtpEvent = createEffect(() =>
        this._actions$.pipe(
            ofType(onRelationshipRtpEvent),
            switchMap(({ relationshipId }) =>
                this._relationshipService.onRelationshipRtpEvent(relationshipId).pipe(
                    map((response) =>
                        onRelationshipRtpEventSuccess({
                            relationshipRtpEvent: response,
                        }),
                    ),
                    takeUntil(this._actions$.pipe(ofType(onRelationshipRtpEventCancelled))),
                    catchError((error: ApolloError) => of(onRelationshipRtpEventFailed({ error }))),
                ),
            ),
        ),
    );

    constructor(
        private _actions$: Actions,
        private _relationshipService: RelationshipService,
    ) {}
}
