import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import {
    deleteSupplementalQuestionnaireRequest,
    deleteSupplementalQuestionnaireRequestFailed,
    deleteSupplementalQuestionnaireRequestSuccess,
    downloadOriginalSupplementalQuestionnaireRequest,
    downloadOriginalSupplementalQuestionnaireRequestFailed,
    downloadOriginalSupplementalQuestionnaireRequestSuccess,
    getSupplementalQuestionnairesRequest,
    getSupplementalQuestionnairesRequestFailed,
    getSupplementalQuestionnairesRequestSuccess,
    updateSupplementalQuestionnaireConfigRequest,
    updateSupplementalQuestionnaireConfigRequestFailed,
    updateSupplementalQuestionnaireConfigRequestSuccess,
    uploadSupplementalQuestionnairesRequest,
    uploadSupplementalQuestionnairesRequestFailed,
    uploadSupplementalQuestionnairesRequestSuccess,
} from './supplemental-questionnaire-management.actions';
import { OrgService } from '../../../../entities/org';
import { saveFileFromResBlob } from '../../../../shared/file-download/redux/actions';
import { ConfirmDialogService } from '../../../../shared/components/confirm-dialog/confirm-dialog.service';

@Injectable()
export class SupplementalQuestionnaireManagementEffects {
    getOrgQuestionnairesRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(getSupplementalQuestionnairesRequest),
            switchMap(({ pageable }) =>
                this._orgService.findAllSupplementalQuestionnairesForCurrentUser(pageable).pipe(
                    map((response) =>
                        getSupplementalQuestionnairesRequestSuccess({
                            questionnaires: response.body,
                            headers: response.headers,
                        }),
                    ),
                    catchError((error) => of(getSupplementalQuestionnairesRequestFailed(error))),
                ),
            ),
        ),
    );

    uploadOrgQuestionnairesRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(uploadSupplementalQuestionnairesRequest),
            switchMap(({ fileRequests: requests }) =>
                this._orgService.uploadSupplementalQuestionnaires(requests).pipe(
                    map(() => uploadSupplementalQuestionnairesRequestSuccess()),
                    catchError((error) => of(uploadSupplementalQuestionnairesRequestFailed({ error }))),
                ),
            ),
        ),
    );

    deleteOrgQuestionnaireRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(deleteSupplementalQuestionnaireRequest),
            switchMap(({ questionnaireId }) =>
                this._confirmDialogService
                    .confirm({
                        title: 'Delete Questionnaire',
                        message: 'Are you sure you want to delete the questionnaire?',
                        cancelLabel: 'Cancel',
                        confirmLabel: 'Delete Questionnaire',
                    })
                    .pipe(
                        filter((result) => result),
                        switchMap(() =>
                            this._orgService.deleteSupplementalQuestionnaire(questionnaireId).pipe(
                                map(() => deleteSupplementalQuestionnaireRequestSuccess()),
                                catchError((error) => of(deleteSupplementalQuestionnaireRequestFailed(error))),
                            ),
                        ),
                    ),
            ),
        ),
    );

    downloadOriginalOrgQuestionnaireRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(downloadOriginalSupplementalQuestionnaireRequest),
            switchMap(({ questionnaireId }) =>
                this._orgService.downloadSupplementalQuestionnaire(questionnaireId).pipe(
                    map((fileResponseBlob) =>
                        downloadOriginalSupplementalQuestionnaireRequestSuccess({ fileResponseBlob }),
                    ),
                    catchError((error) => of(downloadOriginalSupplementalQuestionnaireRequestFailed(error))),
                ),
            ),
        ),
    );

    saveOriginalQuestionnaire$ = createEffect(() =>
        this._actions$.pipe(
            ofType(downloadOriginalSupplementalQuestionnaireRequestSuccess),
            map(({ fileResponseBlob }) => saveFileFromResBlob({ fileResponseBlob })),
        ),
    );

    updateSupplementalQuestionnaireConfigRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(updateSupplementalQuestionnaireConfigRequest),
            switchMap(({ updateRequest }) =>
                this._orgService.updateSupplementalQuestionnaireConfig(updateRequest).pipe(
                    map(() => updateSupplementalQuestionnaireConfigRequestSuccess()),
                    catchError((error) => of(updateSupplementalQuestionnaireConfigRequestFailed({ error }))),
                ),
            ),
        ),
    );

    constructor(
        private _actions$: Actions,
        private _orgService: OrgService,
        private _confirmDialogService: ConfirmDialogService,
    ) {}
}
