<div class="aside-inner">
    <nav class="sidebar d-flex flex-column pb-0" [class.show-scrollbar]="asideScrollbar">
        <ul class="sidebar-nav">
            <li
                *ngFor="let item of menuItems$ | async"
                [ngClass]="{ 'nav-heading': item.heading }"
                [routerLinkActive]="'active'"
            >
                <!-- headingMenuItem -->
                <ng-container *ngIf="item.heading">
                    <span>{{ item.text }}</span>
                </ng-container>

                <!-- singleMenuItem -->
                <ng-container *ngIf="!item.heading && !item.submenu && !item.button && !item.elink">
                    <a
                        *disableOnTrial="
                            !!item.disableOnTrialOptions;
                            popoverTemplate: popover;
                            popoverPlacement: 'left';
                            popoverCustomClass: item.disableOnTrialOptions?.popoverClass
                        "
                        [routerLink]="item.link"
                        [attr.route]="item.link"
                        [title]="item.text"
                        (click)="toggleCollapse()"
                        [ngClass]="{ 'flex-center': isCollapsed }"
                    >
                        <mat-icon fontSet="material-icons-outlined">{{ item.icon }}</mat-icon>
                        <span>{{ item.text }}</span>
                    </a>
                    <ng-template #popover>
                        <div class="header">
                            <strong>{{ item.disableOnTrialOptions?.popoverTitle }}</strong>
                            <em
                                *ngIf="!!item.disableOnTrialOptions?.popoverIcon"
                                [ngClass]="item.disableOnTrialOptions.popoverIcon"
                                class="icon text-primary"
                            ></em>
                        </div>
                        <div class="body">
                            <p>{{ item.disableOnTrialOptions?.popoverBody }}</p>
                            <img
                                *ngIf="!!item.disableOnTrialOptions?.popoverImg"
                                [src]="item.disableOnTrialOptions.popoverImg"
                                class="img-fluid d-block mx-auto mb-2"
                            />
                        </div>
                    </ng-template>
                </ng-container>

                <!-- singleMenuItem External -->
                <ng-container *ngIf="!item.heading && !item.submenu && !item.button && item.elink">
                    <a
                        *disableOnTrial="
                            !!item.disableOnTrialOptions;
                            popoverTemplate: popover;
                            popoverPlacement: 'left';
                            popoverCustomClass: item.disableOnTrialOptions?.popoverClass
                        "
                        [externalLink]="item.elink"
                        [ngClass]="{ 'flex-center': isCollapsed }"
                    >
                        <mat-icon fontSet="material-symbols-outlined">{{ item.icon }}</mat-icon>
                        <span>{{ item.text }}</span>
                    </a>
                    <ng-template #popover>
                        <div class="header">
                            <strong>{{ item.disableOnTrialOptions?.popoverTitle }}</strong>
                            <em
                                *ngIf="!!item.disableOnTrialOptions?.popoverIcon"
                                [ngClass]="item.disableOnTrialOptions.popoverIcon"
                                class="icon text-primary"
                            ></em>
                        </div>
                        <div class="body">
                            <p>{{ item.disableOnTrialOptions?.popoverBody }}</p>
                            <img
                                *ngIf="!!item.disableOnTrialOptions?.popoverImg"
                                [src]="item.disableOnTrialOptions.popoverImg"
                                class="img-fluid d-block mx-auto mb-2"
                            />
                        </div>
                    </ng-template>
                </ng-container>

                <!-- popupButtonMenuItem -->
                <ng-container *ngIf="!item.heading && !item.submenu && item.button && item.popup">
                    <a [title]="item.text">
                        <button
                            mat-button
                            visoButton="secondary"
                            [routerLink]="['/', { outlets: { popup: [item.link] } }]"
                            (click)="toggleCollapse()"
                        >
                            <em [ngClass]="item.icon" [class.me-2]="!isCollapsed" *ngIf="item.icon"></em>
                            <span *ngIf="!isCollapsed">{{ item.text }}</span>
                        </button>
                    </a>
                </ng-container>

                <!-- menuWithSubmenus -->
                <ng-container *ngIf="!item.heading && item.submenu && checkFeatureFlagEnabled(item)">
                    <ng-container *ngIf="!isCollapsed">
                        <a
                            href="javascript:;"
                            [title]="item.text"
                            (click)="toggleCollapse(item)"
                            class="submenu-header"
                        >
                            <mat-icon fontSet="material-icons-outlined">{{ item.icon }}</mat-icon>
                            <span>{{ item.text }}</span>
                            <span *ngIf="item.isBeta" class="beta-badge">BETA</span>
                            <mat-icon fontSet="material-icons-outlined" class="ms-auto me-2"
                                >keyboard_arrow_down</mat-icon
                            >
                        </a>
                        <ul
                            *ngIf="item.submenu"
                            class="sidebar-nav sidebar-subnav"
                            [collapse]="item.isCollapsed"
                            [isAnimated]="false"
                        >
                            <ng-container *ngFor="let subitem of item.submenu">
                                <li
                                    *ngIf="
                                        checkFeatureFlagEnabled(subitem) &&
                                        (subitem.environments || [env]).includes(env)
                                    "
                                    [routerLinkActive]="'active'"
                                >
                                    <a
                                        *disableOnTrial="
                                            !!subitem.disableOnTrialOptions;
                                            popoverTemplate: popover;
                                            popoverPlacement: 'left';
                                            popoverCustomClass: item.disableOnTrialOptions?.popoverClass
                                        "
                                        [routerLink]="subitem.link"
                                        [attr.route]="subitem.link"
                                        [title]="subitem.text"
                                    >
                                        <span>{{ subitem.text }}</span>
                                        <span *ngIf="subitem.isBeta" class="beta-badge">BETA</span>
                                    </a>

                                    <ng-template #popover>
                                        <div class="header">
                                            <strong>{{ subitem.disableOnTrialOptions?.popoverTitle }}</strong>
                                            <em
                                                *ngIf="!!subitem.disableOnTrialOptions?.popoverIcon"
                                                [ngClass]="subitem.disableOnTrialOptions.popoverIcon"
                                                class="icon text-primary"
                                            ></em>
                                        </div>
                                        <div class="body">
                                            <img
                                                *ngIf="!!subitem.disableOnTrialOptions?.popoverImg"
                                                [src]="subitem.disableOnTrialOptions.popoverImg"
                                                class="img-fluid d-block mx-auto mb-2"
                                            />
                                            {{ subitem.disableOnTrialOptions?.popoverBody }}
                                        </div>
                                    </ng-template>
                                </li>
                            </ng-container>
                        </ul>
                    </ng-container>
                    <ng-container *ngIf="isCollapsed">
                        <div
                            ngbDropdown
                            container="body"
                            placement="end-top"
                            dropdownClass="sidebar-dropdown"
                            [popperOptions]="popperOptions"
                        >
                            <a ngbDropdownToggle>
                                <mat-icon fontSet="material-icons-outlined">{{ item.icon }}</mat-icon>
                            </a>
                            <div *ngIf="item.submenu" ngbDropdownMenu>
                                <ng-container *ngFor="let subitem of item.submenu">
                                    <ng-container *ngIf="checkFeatureFlagEnabled(subitem)">
                                        <a
                                            *disableOnTrial="
                                                !!subitem.disableOnTrialOptions;
                                                popoverTemplate: popover;
                                                popoverPlacement: 'left';
                                                popoverCustomClass: item.disableOnTrialOptions?.popoverClass
                                            "
                                            ngbDropdownItem
                                            [routerLink]="subitem.link"
                                            [routerLinkActive]="'active'"
                                        >
                                            {{ subitem.text }}
                                            <span *ngIf="subitem.isBeta" class="beta-badge">BETA</span>
                                        </a>

                                        <ng-template #popover>
                                            <div class="header">
                                                <strong>{{ subitem.disableOnTrialOptions?.popoverTitle }}</strong>
                                                <em
                                                    *ngIf="!!subitem.disableOnTrialOptions?.popoverIcon"
                                                    [ngClass]="subitem.disableOnTrialOptions.popoverIcon"
                                                    class="icon text-primary"
                                                ></em>
                                            </div>
                                            <div class="body">
                                                <img
                                                    *ngIf="!!subitem.disableOnTrialOptions?.popoverImg"
                                                    [src]="subitem.disableOnTrialOptions.popoverImg"
                                                    class="img-fluid d-block mx-auto mb-2"
                                                />
                                                {{ subitem.disableOnTrialOptions?.popoverBody }}
                                            </div>
                                        </ng-template>
                                    </ng-container>
                                </ng-container>
                            </div>
                        </div>
                    </ng-container>
                </ng-container>
            </li>
        </ul>
    </nav>
</div>
