import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

type visoDisclaimerType = 'error' | 'warning' | 'info';

@Component({
    selector: 'app-disclaimer',
    templateUrl: './disclaimer.component.html',
    styleUrls: ['./disclaimer.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DisclaimerComponent {
    @Input({ required: true })
    type: visoDisclaimerType;

    @Input()
    title: string;

    get disclaimerTypeClass(): string {
        return `disclaimer-${this.type}`;
    }

    get disclaimerTitle(): string {
        switch (this.type) {
            case 'info':
                return this.title ?? 'Did you know?';
            case 'error':
                return this.title ?? 'Error!';
            case 'warning':
                return this.title ?? 'Warning!';
        }
    }
}
