import { ArtifactUploadRecommendationEffects } from './artifact-upload-recommendation.effects';
import { CollectArtifactsSubStepsNavigationEffects } from './collect-artifacts-sub-steps-navigation.effects';
import { CommandsEffects } from './commands.effects';
import { EventsEffects } from './events.effects';
import { FeedbackEffects } from './feedback.effects';
import { RedirectsEffects } from './redirects.effects';
import { RefreshTriggersEffects } from './refresh-triggers.effects';
import { RequestEffects } from './requests.effects';
import { RTPEffects } from './rtp.effects';
import { StepsNavigationEffects } from './steps-navigation.effects';
import { QuestionnaireEffects } from './questionnaire.effects';
import { SnackbarEffects } from './snackbar.effects';

export const effects = [
    ArtifactUploadRecommendationEffects,
    CollectArtifactsSubStepsNavigationEffects,
    CommandsEffects,
    EventsEffects,
    FeedbackEffects,
    RedirectsEffects,
    RefreshTriggersEffects,
    RequestEffects,
    RTPEffects,
    StepsNavigationEffects,
    QuestionnaireEffects,
    SnackbarEffects,
];
