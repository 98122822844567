import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { revokeApiTokenRequest, revokeApiTokenRequestSuccess, UserProfileActions } from '../redux/user-profile.actions';

@Component({
    selector: 'app-contact-delete-dialog',
    templateUrl: './revoke-access-token-modal.component.html',
    styleUrls: ['./revoke-access-token-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RevokeAccessTokenModalComponent implements OnInit, OnDestroy {
    UserProfileActions = UserProfileActions;

    private _unsub$ = new Subject<void>();

    constructor(
        private _actions$: Actions,
        private _store$: Store,
        private _activeModal: NgbActiveModal,
    ) {}

    ngOnInit(): void {
        this._actions$
            .pipe(
                ofType(revokeApiTokenRequestSuccess),
                tap(() => this.cancel()),
                takeUntil(this._unsub$),
            )
            .subscribe();
    }

    cancel() {
        this._activeModal.dismiss('cancel');
    }

    revokeApiToken() {
        this._store$.dispatch(revokeApiTokenRequest());
    }

    ngOnDestroy(): void {
        this._unsub$.next();
    }
}
