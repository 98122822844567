import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { takeUntil, tap } from 'rxjs/operators';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { FormUtilsService } from '../../../shared/utils/form-utils.service';
import { CoupaIntegration, CoupaIntegrationFull, CoupaSupplierSyncResponse } from '../../../entities/coupa';
import { noWhitespaceValidator } from '../../../shared/validators/whitespace-validator';
import {
    IntegrationActions,
    putCoupaIntegrationRequest,
    putCoupaIntegrationRequestSuccess,
    syncCoupaIntegrationRequestSuccess,
} from '../redux/integrations.actions';
import { SnackbarService } from '../../../shared/components/snackbar/snackbar.service';

@Component({
    selector: 'app-coupa-edit-dialog',
    templateUrl: './coupa-integration-dialog.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoupaIntegrationDialogComponent implements OnInit, OnDestroy {
    @Input({ required: true })
    config: CoupaIntegration;

    configForm: UntypedFormGroup;

    IntegrationActions = IntegrationActions;

    private _unsub$ = new Subject<void>();

    constructor(
        private _activeModal: NgbActiveModal,
        private _fb: UntypedFormBuilder,
        private _actions$: Actions,
        private _store$: Store,
        private _formUtils: FormUtilsService,
        private _snackbarService: SnackbarService,
    ) {}

    ngOnInit() {
        this._actions$
            .pipe(
                ofType(syncCoupaIntegrationRequestSuccess),
                tap(({ resp }) => {
                    this.onSyncComplete(resp);
                }),
                takeUntil(this._unsub$),
            )
            .subscribe();

        this.configForm = this._fb.group({
            instance: [this.config?.instance || '', [Validators.required, noWhitespaceValidator]],
            accessKey: ['', [Validators.required, noWhitespaceValidator]],
            secretKey: ['', [Validators.required, noWhitespaceValidator]],
            scopes: [this.config?.scopes || '', [Validators.required]],
        });

        this._actions$
            .pipe(
                ofType(putCoupaIntegrationRequestSuccess),
                tap(() => this._activeModal.dismiss()),
                takeUntil(this._unsub$),
            )
            .subscribe();
    }

    onSyncComplete(resp: CoupaSupplierSyncResponse) {
        if (!resp.executed) {
            this._snackbarService.warning('Sync already in progress');
        } else {
            this._snackbarService.success(
                `Synced ${resp.supplierCount} supplier${resp.supplierCount !== 1 ? 's' : ''}`,
            );
        }
    }

    clear() {
        this._activeModal.dismiss('cancel');
    }

    save() {
        const config = this._formUtils.getCleanFormGroupValue<CoupaIntegrationFull>(this.configForm, true);
        this._store$.dispatch(putCoupaIntegrationRequest({ config }));
    }

    ngOnDestroy(): void {
        this._unsub$.next();
    }
}
