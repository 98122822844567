import { Directive, ElementRef, HostListener, Input, OnInit } from '@angular/core';

@Directive({
    selector: '[hoverClass]',
})
export class HoverClassDirective implements OnInit {
    @Input('hoverClass') hoverClass: string;
    @Input('replaceClasses') replaceClasses: boolean = false;
    @Input('removeHoverClassOnClick') removeHoverClassOnClick: boolean = false;

    private _initialClasses: string;

    constructor(private elementRef: ElementRef<HTMLElement>) {}

    @HostListener('mouseover') onMouseEnter() {
        this.addClasses();
    }

    @HostListener('mouseout') onMouseLeave() {
        this.removeClasses();
    }

    @HostListener('click') onClick() {
        if (this.removeHoverClassOnClick) {
            this.removeClasses();
        }
    }

    ngOnInit(): void {
        this._initialClasses = this.elementRef.nativeElement.className;
    }

    private addClasses() {
        if (this.replaceClasses) {
            this.elementRef.nativeElement.className = this.hoverClass;
        } else {
            this.elementRef.nativeElement.classList.add(this.hoverClass);
        }
    }

    private removeClasses() {
        if (this.replaceClasses) {
            this.elementRef.nativeElement.className = this._initialClasses;
        } else {
            this.elementRef.nativeElement.classList.remove(this.hoverClass);
        }
    }
}
