import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { VisoButtonModule } from '../../shared/viso-button/viso-button.module';
import { PdfViewerModule } from '../../shared/pdf-viewer/pdf-viewer.module';
import { BadgeModule } from '../../shared/badge';
import { AssuranceLevelMeterModule } from '../../shared/assurance-level-meter';
import { DetectionsHelperService, PdfArtifactViewerModalHelperService } from './services';
import { PdfArtifactViewerEffects } from './redux/pdf-artifact-viewer.effects';
import {
    DetectionComponent,
    DetectionsListComponent,
    PdfArtifactWithDetectionsViewerModalComponent,
} from './components';

@NgModule({
    declarations: [PdfArtifactWithDetectionsViewerModalComponent, DetectionsListComponent, DetectionComponent],
    imports: [
        CommonModule,
        VisoButtonModule,
        BadgeModule,
        PdfViewerModule,
        AssuranceLevelMeterModule,
        EffectsModule.forFeature([PdfArtifactViewerEffects]),
    ],
    providers: [DetectionsHelperService, PdfArtifactViewerModalHelperService],
    exports: [PdfArtifactWithDetectionsViewerModalComponent],
})
export class PdfArtifactViewerModule {}
