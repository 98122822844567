export enum RemediationStatus {
    REQUESTED = 'REQUESTED',
    ARTIFACTS_PROVIDED = 'ARTIFACTS_PROVIDED',
    DECLINED = 'DECLINED',
    EXPIRED = 'EXPIRED',
    CANCELLED = 'CANCELLED',
    OBSOLETE = 'OBSOLETE',
}

export interface RemediationRequest {
    id: string;
    createdDate: Date;
    updatedDate: Date;
    targetDate: Date;
    status: RemediationStatus;
    comment?: string;
    creatorEmail: string;
    declinedByEmail?: string;
    declinedByFirstName?: string;
    declinedByLastName?: string;
    declinedDate?: Date;
    cancelledByEmail?: string;
}
