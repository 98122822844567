import { Component, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { SnackbarService } from '../../shared/components/snackbar/snackbar.service';
import { FeatureFlagsManagementService } from './feature-flag-management.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder } from '@angular/forms';
import { Org, OrgService } from '../../entities/org';
import { Observable, Subject } from 'rxjs';
import { concatMap, map, takeUntil, tap } from 'rxjs/operators';
import { RefSelectOption } from '../../shared/model/select-options';
import { FeatureFlag } from '../../entities/feature-flag/feature-flag.model';

@Component({
    selector: 'app-feature-flag-management',
    templateUrl: './feature-flag-management.component.html',
    styleUrls: ['./feature-flag-management.component.scss'],
})
export class FeatureFlagManagementComponent implements OnInit, OnDestroy {
    featureFlags: { flag: string }[] = [];
    loadingFeatureFlags: boolean = false;
    loadingFeatureFlag: boolean = false;
    updatingFeatureFlags: boolean = false;
    featureFlagModal: NgbModalRef;

    selectedFeatureFlag: FeatureFlag;

    orgs$: Observable<Org[]>;

    featureFlagForm = this._fb.group({
        enabled: this._fb.control<boolean | null>(null),
        orgs: this._fb.control<number[]>([]),
    });

    private _unsub$ = new Subject<void>();

    constructor(
        private _snackbarService: SnackbarService,
        private _featureFlagsManagementService: FeatureFlagsManagementService,
        private _orgService: OrgService,
        private _modalService: NgbModal,
        private _fb: FormBuilder,
    ) {}

    ngOnInit(): void {
        this.loadingFeatureFlags = true;
        this._featureFlagsManagementService.getFeatureFlags().subscribe({
            next: (featureFlags) => (this.featureFlags = featureFlags.map((flag) => ({ flag }))),
            error: (error) => this._snackbarService.error('Error loading feature flags'),
            complete: () => (this.loadingFeatureFlags = false),
        });

        this.featureFlagForm.controls.enabled.valueChanges.pipe(takeUntil(this._unsub$)).subscribe((value) => {
            this.toggleFeatureFlag(this.selectedFeatureFlag.featureFlag, value);
        });

        this.featureFlagForm.controls.orgs.valueChanges.pipe(takeUntil(this._unsub$)).subscribe((value) => {
            this.editOrgs(value);
        });

        this.orgs$ = this._orgService.clients().pipe(
            map((res) => res.body),
            map((orgs) =>
                orgs.map<RefSelectOption<Org>>((org) => ({
                    name: org.name,
                    id: org.id,
                    ref: org,
                })),
            ),
        );
    }

    ngOnDestroy(): void {
        this._unsub$.next();
    }

    editFeatureFlag(modalRef: TemplateRef<HTMLElement>, flag: string): void {
        this.loadFeatureFlag(flag).subscribe((featureFlag) => {
            this.featureFlagForm.patchValue(
                {
                    enabled: featureFlag.enabled,
                    orgs: (featureFlag.orgs || []).map((org) => org.id),
                },
                { emitEvent: false },
            );

            this.featureFlagModal = this._modalService.open(modalRef, {
                centered: true,
                backdrop: 'static',
            });
        });
    }

    toggleFeatureFlag(flag: string, value: boolean): void {
        this.updatingFeatureFlags = true;
        this._featureFlagsManagementService.setFeatureFlag(flag, value).subscribe({
            next: () => this._snackbarService.success('Feature flag updated successfully'),
            error: (error) => this._snackbarService.error('Error updating feature flag'),
            complete: () => (this.updatingFeatureFlags = false),
        });
    }

    editOrgs(orgIds: number[]): void {
        this.updatingFeatureFlags = true;
        if (this.selectedFeatureFlag.orgs.length < orgIds.length) {
            const newOrgId = orgIds.find((orgId) => !this.selectedFeatureFlag.orgs.some((org) => org.id === orgId));
            this._featureFlagsManagementService
                .addOrgPerOrgFlag(this.selectedFeatureFlag.featureFlag, newOrgId)
                .pipe(concatMap(() => this.loadFeatureFlag(this.selectedFeatureFlag.featureFlag)))
                .subscribe({
                    next: () => this._snackbarService.success('Org Feature Flag activated successfully'),
                    error: (error) => this._snackbarService.error('Error updating orgs'),
                    complete: () => (this.updatingFeatureFlags = false),
                });
        } else {
            const removedOrgId = this.selectedFeatureFlag.orgs.find(
                (org) => !orgIds.some((orgId) => org.id === orgId),
            ).id;
            this._featureFlagsManagementService
                .removeOrgPerOrgFlag(this.selectedFeatureFlag.featureFlag, removedOrgId)
                .pipe(concatMap(() => this.loadFeatureFlag(this.selectedFeatureFlag.featureFlag)))
                .subscribe({
                    next: () => {
                        this.updatingFeatureFlags = false;
                        this._snackbarService.success('Org Feature Flag deactivated successfully');
                    },
                    error: (error) => this._snackbarService.error('Error updating orgs'),
                });
        }
    }

    loadFeatureFlag(flag: string): Observable<FeatureFlag> {
        this.loadingFeatureFlag = true;
        return this._featureFlagsManagementService.getFeatureFlag(flag).pipe(
            tap((featureFlag) => {
                this.selectedFeatureFlag = featureFlag;
                this.loadingFeatureFlag = false;
            }),
        );
    }
}
