import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Risk, RiskLabels } from '@entities/risk-assessment';
import { RiskThresholdData } from '../../redux/risk-model-settings.selectors';
import { RiskTolerance } from '@entities/risk-tolerance';
import { OrgRiskConfig } from '../../../../admin/client-profile/client-profile.model';

@Component({
    selector: 'app-risk-thresholds',
    templateUrl: './risk-thresholds.component.html',
    styleUrls: ['./risk-thresholds.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RiskThresholdsComponent {
    @Input({ required: true })
    activeRiskTolerance: RiskTolerance;

    @Input({ required: true })
    riskToleranceThresholds: Map<RiskTolerance, Map<Risk, RiskThresholdData>>;

    @Input({ required: true })
    riskLevelDisplayNames: OrgRiskConfig[];

    riskOrderReverse: Risk[] = [Risk.EXTREME, Risk.HIGH, Risk.MEDIUM, Risk.LOW, Risk.NO_CONTEXT];
    Risk = Risk;

    riskLevelClasses(riskLevel: Risk) {
        return {
            'no-context': riskLevel === Risk.NO_CONTEXT,
            'low-risk': riskLevel === Risk.LOW,
            'medium-risk': riskLevel === Risk.MEDIUM,
            'high-risk': riskLevel === Risk.HIGH,
            'extreme-risk': riskLevel === Risk.EXTREME,
        };
    }

    getRiskLevelDisplayName(riskLevel: Risk): string {
        const displayName = this.riskLevelDisplayNames?.find((config) => config.riskLevel === riskLevel)?.displayName;
        return (displayName?.length > 22 ? `${displayName.substring(0, 22)}...` : displayName) || RiskLabels[riskLevel];
    }
}
