import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { tap, takeUntil } from 'rxjs/operators';
import { ContactReassignModalComponent } from '.';
import {
    reassignContactRelationshipsRequestSuccess,
    deleteContactRequest,
    deleteContactRequestSuccess,
    ContactActions,
} from '../contact-management/redux/contact-management.actions';
import { VisoUser } from '../../entities/viso-user';

@Component({
    selector: 'app-contact-delete-dialog',
    templateUrl: './contact-delete-dialog.component.html',
    styleUrls: ['./contact-delete-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactDeleteDialogComponent implements OnInit, OnDestroy {
    @Input()
    contact: VisoUser;

    @ViewChild(ContactReassignModalComponent)
    contactReassignModalComponent: ContactReassignModalComponent;

    ContactActions = ContactActions;

    private _unsub = new Subject<void>();

    get contactFullName() {
        return `${this.contact?.firstName} ${this.contact?.lastName}`;
    }

    constructor(
        private _actions$: Actions,
        private _store$: Store,
        private _activeModal: NgbActiveModal,
    ) {}

    ngOnInit(): void {
        this._actions$
            .pipe(
                ofType(reassignContactRelationshipsRequestSuccess),
                tap(() => this._store$.dispatch(deleteContactRequest({ contactId: this.contact.id }))),
                takeUntil(this._unsub),
            )
            .subscribe();

        this._actions$
            .pipe(
                ofType(deleteContactRequestSuccess),
                tap(() => this.clear()),
                takeUntil(this._unsub),
            )
            .subscribe();
    }

    clear() {
        this._activeModal.dismiss('cancel');
    }

    deleteContact() {
        if (this.contact.businessOwnerCount === 0 && this.contact.subscriptionCount === 0) {
            this._store$.dispatch(deleteContactRequest({ contactId: this.contact.id }));
        } else {
            this.contactReassignModalComponent.reassign();
        }
    }

    ngOnDestroy(): void {
        this._unsub.next();
    }
}
