<div class="modal-header">
    <div class="modal-title">API Token</div>
    <button type="button" class="btn-close" data-dismiss="modal" aria-hidden="true" (click)="closeModal()"></button>
</div>
<div class="modal-body modal-text">
    <div class="input-group mb-3">
        <input type="text" class="form-control" [value]="accessToken" readonly />
        <div class="input-group-append">
            <button
                mat-stroked-button
                class="no-left-radius"
                type="button"
                title="copy to clipboard"
                (click)="copyAccessTokenToClipboard()"
            >
                <em class="fas fa-clipboard"></em>
            </button>
        </div>
    </div>
    <strong
        >Note: Please copy the access token to a safe location as this is the only time you’ll have access to
        it.</strong
    >
</div>
