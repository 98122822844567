import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { QuestionnaireArtifact } from '@entities/artifact';
import { QuestionnaireAnswer, QuestionnaireAnswerType, QuestionnaireAnswerTypeLabels } from '@entities/assessment';
import { ControlDomainTypeLabels } from '@entities/control-domain';
import { BehaviorSubject, combineLatest, debounceTime, filter, Subject, takeUntil } from 'rxjs';

type QuestionnaireAnswerFormControls = {
    answerId: FormControl<string>;
    answerType: FormControl<QuestionnaireAnswerType>;
    answer: FormControl<string>;
};

@Component({
    selector: 'app-answer-questionnaire',
    templateUrl: './answer-questionnaire.component.html',
    styleUrls: ['./answer-questionnaire.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnswerQuestionnaireComponent implements OnInit, OnDestroy {
    @Input({ required: true })
    set questionnaire(value: QuestionnaireArtifact) {
        this.questionnaire$.next(value);
    }

    @Input({ required: true })
    set followupControlDomainIds(value: number[]) {
        this.followupControlDomainIds$.next(value);
    }

    @Input({ required: true })
    set relevantControlDomainIds(value: number[]) {
        this.relevantControlDomainIds$.next(value);
    }

    @Input({ required: true })
    forFollowup: boolean;

    @Output()
    continued = new EventEmitter<void>();

    @Output()
    wentBack = new EventEmitter<void>();

    @Output()
    wentToCollectArtifacts = new EventEmitter<void>();

    @Output()
    questionnaireUpdated = new EventEmitter<QuestionnaireArtifact>();

    questionnaire$ = new BehaviorSubject<QuestionnaireArtifact>(null);
    followupControlDomainIds$ = new BehaviorSubject<number[]>([]);
    relevantControlDomainIds$ = new BehaviorSubject<number[]>([]);
    relevantAnswers$ = new BehaviorSubject<QuestionnaireAnswer[]>([]);

    questionnaireFormArray: FormArray<FormGroup<QuestionnaireAnswerFormControls>>;
    QuestionnaireAnswerType = QuestionnaireAnswerType;
    QuestionnaireAnswerTypeTabLabels = QuestionnaireAnswerTypeLabels;
    ControlDomainTypeLabels = ControlDomainTypeLabels;

    private _unsub$ = new Subject<void>();

    get answers(): QuestionnaireAnswer[] {
        return this.relevantAnswers$.value;
    }

    constructor(private _fb: FormBuilder) {}

    ngOnInit(): void {
        combineLatest([this.questionnaire$, this.followupControlDomainIds$, this.relevantControlDomainIds$])
            .pipe(
                filter(([questionnaire]) => !this.questionnaireFormArray && !!questionnaire),
                takeUntil(this._unsub$),
            )
            .subscribe(([questionnaire, followupControlDomainIds, relevantControlDomainIds]) =>
                this.initializeForm(questionnaire, followupControlDomainIds, relevantControlDomainIds),
            );
    }

    attemptToContinue(): void {
        if (this.questionnaireFormArray.invalid) {
            this.scrollToFirstInvalidQuestion();
        } else {
            this.continued.emit();
        }
    }

    ngOnDestroy(): void {
        this._unsub$.next();
    }

    private initializeForm(
        questionnaire: QuestionnaireArtifact,
        followupControlDomainIds: number[],
        relevantControlDomainIds: number[],
    ): void {
        const filteredAnswers = questionnaire.answers.filter((a) =>
            this.isQuestionRelevantOrAnswerBlank(a, followupControlDomainIds, relevantControlDomainIds),
        );

        this.relevantAnswers$.next(filteredAnswers);

        this.questionnaireFormArray = this._fb.array(
            filteredAnswers.map((answer) =>
                this._fb.group<QuestionnaireAnswerFormControls>({
                    answerId: this._fb.control(answer.id),
                    answerType: this._fb.control(answer.answerType, Validators.required),
                    answer:
                        answer.answerType === QuestionnaireAnswerType.DONT_DO
                            ? this._fb.control(answer.answer)
                            : this._fb.control(answer.answer, Validators.required),
                }),
            ),
            { updateOn: 'change' },
        );

        this.questionnaireFormArray.controls.forEach((formGroup) =>
            formGroup.valueChanges
                .pipe(takeUntil(this._unsub$))
                .subscribe((value) => this.updateFormGroupValidatorsForSelectedAnswerType(value.answerType, formGroup)),
        );

        this.questionnaireFormArray.valueChanges
            .pipe(debounceTime(500), takeUntil(this._unsub$))
            .subscribe(() => this.updateQuestionnaire());
    }

    private updateFormGroupValidatorsForSelectedAnswerType(
        selectedAnswerType: QuestionnaireAnswerType,
        formGroup: FormGroup<QuestionnaireAnswerFormControls>,
    ): void {
        if (selectedAnswerType === QuestionnaireAnswerType.DONT_DO) {
            formGroup.controls.answer.removeValidators(Validators.required);
        } else {
            formGroup.controls.answer.addValidators(Validators.required);
        }
        formGroup.controls.answer.updateValueAndValidity({ emitEvent: false });
    }

    private updateQuestionnaire(): void {
        const questionnaireCopy = { ...this.questionnaire$.value };
        this.questionnaireFormArray.controls.forEach((answerControl) => {
            const existingAnswerIdx = questionnaireCopy.answers.findIndex(
                (answer) => answer.id === answerControl.controls.answerId.value,
            );
            const existingAnswer = questionnaireCopy.answers[existingAnswerIdx];
            questionnaireCopy.answers[existingAnswerIdx] = { ...existingAnswer, ...answerControl.value };
        });
        this.questionnaireUpdated.emit(questionnaireCopy);
    }

    private scrollToFirstInvalidQuestion(): void {
        this.questionnaireFormArray.markAllAsTouched();
        document.querySelector('.ng-invalid').scrollIntoView({ behavior: 'smooth' });
    }

    private isQuestionRelevantOrAnswerBlank(
        questionnaireAnswer: QuestionnaireAnswer,
        followupControlDomainIds: number[],
        relevantControlDomainIds: number[],
    ): boolean {
        const isRelevantQuestion = relevantControlDomainIds.includes(questionnaireAnswer.controlDomainId);
        if (this.forFollowup) {
            const isRelevantFollowupQuestion = followupControlDomainIds.includes(questionnaireAnswer.controlDomainId);
            return isRelevantQuestion && isRelevantFollowupQuestion;
        }
        return isRelevantQuestion;
    }
}
