import { Directive, ElementRef, Renderer2, Input, AfterContentInit } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { ThemePalette } from '@angular/material/core';

export type VisoButtonTypes =
    | 'primary'
    | 'secondary'
    | 'secondary-alt'
    | 'text'
    | 'link'
    | 'warning'
    | 'success'
    | 'cancel';

export type VisoButtonColors = 'primary' | 'accent' | 'warn' | 'secondary' | 'success' | 'default';

/**
 * @deprecated Please use standard material buttons.
 * @example
 * <button mat-flat-button color="primary" (click)="greetWorld()">Hello world!</button>
 * <button mat-stroked-button color="primary" (click)="cancel()">Cancel</button>
 */
@Directive({
    selector: 'button[mat-button][visoButton]',
})
export class VisoButtonDirective implements AfterContentInit {
    @Input()
    color: VisoButtonColors = null;

    private _visoButton: VisoButtonTypes = null;

    @Input()
    set visoButton(value: VisoButtonTypes) {
        this._visoButton = value;
        this.loadVisoButton();
    }

    constructor(
        private matButton: MatButton,
        private elementRef: ElementRef,
        private renderer: Renderer2,
    ) {}

    ngAfterContentInit(): void {
        this.loadVisoButton();
    }

    loadVisoButton(): void {
        this.renderer.addClass(this.elementRef.nativeElement, 'viso-button');
        this.removeButtonClasses();
        if (this._visoButton === 'primary') {
            this.matButton.color = (this.color || 'primary') as ThemePalette;
            this.renderer.removeClass(this.elementRef.nativeElement, 'mat-mdc-button');
            this.renderer.addClass(this.elementRef.nativeElement, 'mat-mdc-unelevated-button');
            this.renderer.addClass(this.elementRef.nativeElement, 'mdc-button--unelevated');
        }
        if (this._visoButton === 'secondary') {
            this.matButton.color = (this.color || 'primary') as ThemePalette;
            this.renderer.removeClass(this.elementRef.nativeElement, 'mat-mdc-button');
            this.renderer.addClass(this.elementRef.nativeElement, 'mdc-button--outlined');
            this.renderer.addClass(this.elementRef.nativeElement, 'mat-mdc-outlined-button');
        }
        if (this._visoButton === 'secondary-alt') {
            this.matButton.color = (this.color || 'accent') as ThemePalette;
            this.renderer.addClass(this.elementRef.nativeElement, 'tonal');
        }
        if (this._visoButton === 'text') {
            this.matButton.color = (this.color || 'primary') as ThemePalette;
        }
        if (this._visoButton === 'link') {
            this.matButton.color = (this.color || 'secondary') as ThemePalette;
            this.matButton.disableRipple = true;
            this.renderer.addClass(this.elementRef.nativeElement, 'link');
            this.renderer.addClass(this.elementRef.nativeElement, 'fs-underline');
        }
        if (this._visoButton === 'cancel') {
            this.matButton.color = (this.color || 'primary') as ThemePalette;
            this.matButton.disableRipple = true;
            this.renderer.addClass(this.elementRef.nativeElement, 'cancel');
        }
        if (this._visoButton === 'warning') {
            this.matButton.color = (this.color || 'warn') as ThemePalette;
            this.renderer.removeClass(this.elementRef.nativeElement, 'mat-mdc-button');
            this.renderer.addClass(this.elementRef.nativeElement, 'mat-mdc-unelevated-button');
            this.renderer.addClass(this.elementRef.nativeElement, 'mdc-button--unelevated');
        }
        if (this._visoButton === 'success') {
            this.matButton.color = (this.color || 'warn') as ThemePalette;
            this.renderer.addClass(this.elementRef.nativeElement, 'success');
        }
    }

    removeButtonClasses(): void {
        this.renderer.removeClass(this.elementRef.nativeElement, 'mat-mdc-unelevated-button');
        this.renderer.removeClass(this.elementRef.nativeElement, 'mat-mdc-outlined-button');
        this.renderer.removeClass(this.elementRef.nativeElement, 'mdc-button--unelevated');
        this.renderer.removeClass(this.elementRef.nativeElement, 'mdc-button--outlined');
    }
}
