import { NgModule } from '@angular/core';
import { ApolloModule, APOLLO_OPTIONS } from 'apollo-angular';
import { ApolloClientOptions, InMemoryCache, split } from '@apollo/client/core';
import { HttpLink } from 'apollo-angular/http';
import { getMainDefinition } from '@apollo/client/utilities';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { environment } from '../environments/environment';

const uri = '/graphql';
export function createApollo(httpLink: HttpLink): ApolloClientOptions<any> {
    const http = httpLink.create({
        uri: uri,
    });

    const wsProtocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:';
    const subscriptionUrl = `${wsProtocol}//${window.location.host}${uri}`;

    const ws = new GraphQLWsLink(
        createClient({
            url: subscriptionUrl,
            connectionAckWaitTimeout: 30_000,
            keepAlive: 30_000,
        }),
    );

    // using the ability to split links, you can send data to each link
    // depending on what kind of operation is being sent
    const link = split(
        // split based on operation type
        ({ query }) => {
            const definition = getMainDefinition(query);
            return definition?.kind === 'OperationDefinition' && definition?.operation === 'subscription';
        },
        ws,
        http,
    );

    return {
        link: link,
        cache: new InMemoryCache(),
        connectToDevTools: !environment.production,
    };
}

@NgModule({
    exports: [ApolloModule],
    providers: [
        {
            provide: APOLLO_OPTIONS,
            useFactory: createApollo,
            deps: [HttpLink],
        },
    ],
})
export class GraphQLModule {}
