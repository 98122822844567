import { Directive, OnInit, OnDestroy, Input, Output, EventEmitter, ElementRef } from '@angular/core';
import { debounceTime, Observable, Subscription } from 'rxjs';

/**
 * This directive allows responding to whether or not an element is visible on the DOM.
 * It is useful for when components are loaded but are not yet visible to the user.
 */
@Directive({
    selector: '[appElementVisibility]',
    exportAs: 'intersection',
    standalone: true,
})
export class ElementVisibilityDirective implements OnInit, OnDestroy {
    @Input() root: HTMLElement | null = null;
    @Input() rootMargin = '0px 0px 0px 0px';
    @Input() threshold = 0;
    @Input() debounceTime = 500;
    @Input() isContinuous = false;

    @Output() isIntersecting = new EventEmitter<boolean>();

    _isIntersecting = false;
    subscription: Subscription;

    constructor(private element: ElementRef) {}

    ngOnInit(): void {
        this.subscription = this.createAndObserve();
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    createAndObserve(): Subscription {
        const options: IntersectionObserverInit = {
            root: this.root,
            rootMargin: this.rootMargin,
            threshold: this.threshold,
        };

        return new Observable<boolean>((subscriber) => {
            const intersectionObserver = new IntersectionObserver((entries) => {
                const { isIntersecting } = entries[0];
                subscriber.next(isIntersecting);
                isIntersecting && !this.isContinuous && intersectionObserver.disconnect();
            }, options);

            intersectionObserver.observe(this.element.nativeElement);

            return {
                unsubscribe(): void {
                    intersectionObserver.disconnect();
                },
            };
        })
            .pipe(debounceTime(this.debounceTime))
            .subscribe((status) => {
                this.isIntersecting.emit(status);
                this._isIntersecting = status;
            });
    }
}
