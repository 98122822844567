import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { SharedModule } from '../../shared';
import { SessionEffects } from './redux/session.effects';
import { sessionReducer } from './redux/session.reducer';
import { sessionStateKey } from './redux/session.state';

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        SharedModule,
        StoreModule.forFeature(sessionStateKey, sessionReducer),
        EffectsModule.forFeature([SessionEffects]),
    ],
})
export class SessionModule {}
