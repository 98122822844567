import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { TabsetComponent } from 'ngx-bootstrap/tabs';
import { BehaviorSubject, Subject } from 'rxjs';
import { VisoUserRole } from '../../entities/viso-user';
import { Store } from '@ngrx/store';
import { fadeAnimation } from '../../shared/animation/basic';
import {
    deleteCoupaIntegrationRequest,
    syncCoupaIntegrationRequest,
    IntegrationActions,
    getConnectedIntegrationsRequest,
    getConnectedIntegrationsRequestSuccess,
    putCoupaIntegrationRequestSuccess,
    deleteCoupaIntegrationRequestSuccess,
    getInterestedIntegrationsRequest,
    getInterestedIntegrationsRequestSuccess,
    createInterestedIntegrationRequest,
    createInterestedIntegrationRequestSuccess,
} from './redux/integrations.actions';
import { BREADCRUMB_CONTAINER_TOKEN } from '../../shared/dynamic-content/dynamic-content-injector';
import { Actions, ofType } from '@ngrx/effects';
import { takeUntil } from 'rxjs/operators';
import { IntegrationType } from './integrations.model';
import { IntegrationDefinition } from './integration-tile/integration-tile.component';
import { environment } from '../../../environments/environment';

@Component({
    selector: 'app-integrations',
    templateUrl: './integrations.component.html',
    styleUrls: ['./integrations.component.scss'],
    animations: [fadeAnimation],
})
export class IntegrationsComponent implements OnInit, AfterViewInit, OnDestroy {
    public readonly breadcrumbsContainerToken = BREADCRUMB_CONTAINER_TOKEN;
    Roles = VisoUserRole;

    @ViewChild('importTabs', { static: false }) staticTabs?: TabsetComponent;

    IntegrationActions = IntegrationActions;
    readonly environment = environment;

    connectedIntegrations$ = new BehaviorSubject<IntegrationType[]>([]);
    interestedIntegrations$ = new BehaviorSubject<IntegrationType[]>([]);

    availableIntegrations: IntegrationDefinition[];
    comingSoonIntegrations: IntegrationDefinition[];

    @ViewChild('slackConnectedActions')
    private slackConnectedActions: TemplateRef<any>;

    @ViewChild('slackDisconnectedActions')
    private slackDisconnectedActions: TemplateRef<any>;

    @ViewChild('coupaConnectedActions')
    private coupaConnectedActions: TemplateRef<any>;

    @ViewChild('coupaDisconnectedActions')
    private coupaDisconnectedActions: TemplateRef<any>;

    @ViewChild('netskopeDisconnectedActions')
    private netskopeDisconnectedActions: TemplateRef<any>;

    private _unsub$ = new Subject<void>();

    constructor(
        private _store$: Store,
        private _actions$: Actions,
        private _cdr: ChangeDetectorRef,
    ) {}

    ngAfterViewInit(): void {
        this.availableIntegrations = [
            {
                integrationType: IntegrationType.SLACK,
                faviconSrc: 'content/svg/slack.svg',
                comingSoon: false,
                description:
                    'Interact with features like AI Q&A from within your Slack team to get answers to questions from ' +
                    'both your own Trust Artifacts as well as any assessed Vendor Relationship',
                connectedTemplate: this.slackConnectedActions,
                disconnectedTemplate: this.slackDisconnectedActions,
            },
            {
                integrationType: IntegrationType.COUPA,
                faviconSrc: 'content/svg/coupa.svg',
                comingSoon: false,
                description:
                    'Import and continuously sync your supplier data from Coupa to VISO Relationships, and automate ' +
                    'supplier risk management.',
                connectedTemplate: this.coupaConnectedActions,
                disconnectedTemplate: this.coupaDisconnectedActions,
            },
            {
                integrationType: IntegrationType.NETSKOPE,
                faviconSrc: 'content/svg/netskope.svg',
                comingSoon: false,
                description:
                    'Import and continuously sync your Application Vendor data from Netskope Application Risk Exchange to VISO Relationships',
                connectedTemplate: null,
                disconnectedTemplate: this.netskopeDisconnectedActions,
            },
        ];

        this.comingSoonIntegrations = [
            {
                integrationType: IntegrationType.SERVICENOW,
                faviconSrc: 'content/svg/servicenow.svg',
                comingSoon: true,
                description:
                    'Import, assess, and manage your portfolio of third parties from the ServiceNow GRC: Third-party Risk Management (TPRM) application.',
            },
        ];

        this._cdr.detectChanges();
    }

    ngOnInit() {
        this._actions$
            .pipe(ofType(getConnectedIntegrationsRequestSuccess), takeUntil(this._unsub$))
            .subscribe(({ connectedIntegrations }) => this.connectedIntegrations$.next(connectedIntegrations));

        this._actions$
            .pipe(ofType(getInterestedIntegrationsRequestSuccess), takeUntil(this._unsub$))
            .subscribe(({ interestedIntegrations }) => this.interestedIntegrations$.next(interestedIntegrations));

        this._actions$
            .pipe(
                ofType(deleteCoupaIntegrationRequestSuccess, putCoupaIntegrationRequestSuccess),
                takeUntil(this._unsub$),
            )
            .subscribe(() => this.getConnectedIntegrations());

        this._actions$
            .pipe(ofType(createInterestedIntegrationRequestSuccess), takeUntil(this._unsub$))
            .subscribe(() => this.getInterestedIntegrations());

        this.getConnectedIntegrations();
        this.getInterestedIntegrations();
    }

    getInterestedIntegrations() {
        this._store$.dispatch(getInterestedIntegrationsRequest());
    }

    getConnectedIntegrations() {
        this._store$.dispatch(getConnectedIntegrationsRequest());
    }

    disconnectCoupa() {
        this._store$.dispatch(deleteCoupaIntegrationRequest());
    }

    syncCoupaData() {
        this._store$.dispatch(syncCoupaIntegrationRequest());
    }

    keepMeUpdated(integration: IntegrationType) {
        this._store$.dispatch(createInterestedIntegrationRequest({ integration }));
    }

    ngOnDestroy(): void {
        this._unsub$.next();
    }
}
