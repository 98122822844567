import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DisclaimerComponent } from './components/disclaimer/disclaimer.component';
import { MatIcon } from '@angular/material/icon';

@NgModule({
    declarations: [DisclaimerComponent],
    imports: [CommonModule, MatIcon],
    exports: [DisclaimerComponent],
})
export class DisclaimerModule {}
