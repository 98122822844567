import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

export class AuthExpiredInterceptor implements HttpInterceptor {
    constructor() {}
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            tap({
                error: (err: any) => {
                    if (err instanceof HttpErrorResponse) {
                        if (
                            err.status === 401 &&
                            err.url &&
                            !err.url.includes('api/account') &&
                            !err.url.includes('assessment-request') &&
                            !err.url.includes('assessment-artifact-update')
                        ) {
                            location.href = `${location.origin}`;
                        }
                    }
                },
            }),
        );
    }
}
