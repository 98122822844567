/// <reference types="../env" />
// This "environment" is meant to be used as a base template for
// production-like deployments. At the moment, this is for both production
// and for demo, but could be expanded to further environments in the future
// as needed. See README.md for a thorough explanation of how this file is used.

export const environment = {
    // These values do NOT change at runtime and therefore will apply to all prod-like
    // environments
    production: true,
    debugInfoEnabled: false,
    enableArtifactAddAllControls: false,
    enableLaunchDarkly: true,
    commitHash: import.meta.env.NG_APP_COMMIT,

    // These values DO change at runtime and their values are populated from
    // the respective environment file that is provided by way of the
    // VISO_FRONTEND_ENV variable passed to the running container. See
    // README.md for more information.
    baseUrl: '<%%VISOTRUST_baseUrl_VISOTRUST%%>',
    launchDarklyClientId: '<%%VISOTRUST_launchDarklyClientId_VISOTRUST%%>',
    profile: '<%%VISOTRUST_profile_VISOTRUST%%>',
    slackAppInstallUrl: '<%%VISOTRUST_slackAppInstallUrl_VISOTRUST%%>',
    slackAppId: '<%%VISOTRUST_slackAppId_VISOTRUST%%>',
    logrocketClientId: '<%%VISOTRUST_logrocketClientId_VISOTRUST%%>',
    oktaApplicationClientId: '<%%VISOTRUST_oktaApplicationClientId_VISOTRUST%%>',
    oktaGoogleIdpId: '<%%VISOTRUST_oktaGoogleIdpId_VISOTRUST%%>',
    oktaMicrosoftIdpId: '<%%VISOTRUST_oktaMicrosoftIdpId_VISOTRUST%%>',
};
