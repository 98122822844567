import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { PdfViewerComponent } from './pdf-viewer/pdf-viewer.component';

@NgModule({
    declarations: [PdfViewerComponent],
    imports: [CommonModule, NgxExtendedPdfViewerModule],
    exports: [PdfViewerComponent],
})
export class PdfViewerModule {}
