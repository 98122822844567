import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, QueryList } from '@angular/core';
import { CdkStep } from '@angular/cdk/stepper';

@Component({
    selector: 'app-steps-list',
    templateUrl: './steps-list.component.html',
    styleUrls: ['./steps-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StepsListComponent {
    @Input({ required: true })
    steps: QueryList<CdkStep>;

    @Input({ required: true })
    selectedIndex: number;

    @Input()
    orientation: 'horizontal' | 'vertical' = 'horizontal';

    @Output()
    stepChange = new EventEmitter<string>();
}
