import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { QuestionnaireArtifact } from '../../../entities/artifact';
import { QuestionnaireAnswer, QuestionnaireAnswerType } from '../../../entities/assessment';
import {
    addQuestionToQuestionnaireRequest,
    addQuestionToQuestionnaireRequestFailed,
    addQuestionToQuestionnaireRequestSuccess,
    deleteQuestionnaireQuestionRequest,
    deleteQuestionnaireQuestionRequestFailed,
    deleteQuestionnaireQuestionRequestSuccess,
    updateQuestionnaireQuestionRequest,
    updateQuestionnaireQuestionRequestFailed,
    updateQuestionnaireQuestionRequestSuccess,
} from '.././redux/questionnaire.actions';
import {
    downloadArtifact,
    getArtifactRequest,
    getArtifactRequestFailed,
    getArtifactRequestSuccess,
} from '../../request/redux/actions/artifacts.actions';
import { getUserAccount } from '../../session/redux/session.selectors';
import { VisoUser, VisoUserRole } from '../../../entities/viso-user';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { noWhitespaceValidator } from '../../../shared/validators/whitespace-validator';
import { ConfirmDialogService } from '../../../shared/components/confirm-dialog/confirm-dialog.service';
import { ControlValidation } from '../../../entities/artifact';
import { BREADCRUMB_CONTAINER_TOKEN } from '../../../shared/dynamic-content/dynamic-content-injector';
import { SnackbarService } from '../../../shared/components/snackbar/snackbar.service';
interface QuestionnaireFormGroup {
    questions: FormArray<FormGroup<QuestionnaireQuestionFormGroup>>;
}

interface QuestionnaireQuestionFormGroup {
    id?: FormControl<number>;
    question: FormControl<string>;

    //readonly
    optional?: FormControl<boolean>;
    answer?: FormControl<string>;
    answerType?: FormControl<QuestionnaireAnswerType>;
    controlDomainName?: FormControl<string>;
    controlDomainType?: FormControl<string>;
    controlName?: FormControl<string>;
    originalQuestion: FormControl<string>;
    manualReviewNeeded: FormControl<boolean>;
    controlValidation?: FormControl<ControlValidation>;
}

@Component({
    selector: 'app-questionnaire-edit',
    templateUrl: './questionnaire-edit.component.html',
    styleUrls: ['./questionnaire-edit.component.scss'],
})
export class QuestionnaireEditComponent implements OnInit, OnDestroy {
    questionnaireId: number;
    questionnaireArtifact: QuestionnaireArtifact;
    currentUser: VisoUser;
    loadingQuestionnaireArtifact: boolean = true;
    questionnaireFormGroup: FormGroup<QuestionnaireFormGroup>;
    newQuestion: FormGroup<QuestionnaireQuestionFormGroup>;

    readonly breadcrumbsContainerToken = BREADCRUMB_CONTAINER_TOKEN;
    readonly VisoUserRole = VisoUserRole;

    private _unsub$: Subject<void> = new Subject<void>();

    get canEditQuestionnaire() {
        return (
            this.questionnaireArtifact?.parsedExternalQuestionnaire &&
            this.questionnaireArtifact?.creatorOrgId === this.currentUser?.orgId
        );
    }

    constructor(
        private _route: ActivatedRoute,
        private _actions$: Actions,
        private _store$: Store,
        private _fb: FormBuilder,
        private _confirmDialogService: ConfirmDialogService,
        private _snackbarService: SnackbarService,
    ) {}

    ngOnInit(): void {
        this._store$.pipe(select(getUserAccount), takeUntil(this._unsub$)).subscribe((account) => {
            this.currentUser = account;
        });

        this.questionnaireFormGroup = this._fb.group({
            questions: this._fb.array<FormGroup<QuestionnaireQuestionFormGroup>>([]),
        });

        this._route.params.pipe(takeUntil(this._unsub$)).subscribe((params) => {
            this.questionnaireId = params['questionnaireId'];
            this.loadQuestionnaire();
        });

        this._actions$
            .pipe(
                ofType(
                    addQuestionToQuestionnaireRequestSuccess,
                    updateQuestionnaireQuestionRequestSuccess,
                    deleteQuestionnaireQuestionRequestSuccess,
                ),
                tap(() => {
                    this.loadQuestionnaire();
                }),
                takeUntil(this._unsub$),
            )
            .subscribe();

        this._actions$.pipe(ofType(getArtifactRequestSuccess), takeUntil(this._unsub$)).subscribe(({ artifact }) => {
            this.loadingQuestionnaireArtifact = false;
            this.questionnaireArtifact = artifact;
            this.questionnaireFormGroup.controls.questions = this._fb.array(
                this.questionnaireArtifact?.answers.map((detection) =>
                    this.generateQuestionnaireQuestionFormGroup(detection),
                ) || [],
            );
            this.questionnaireFormGroup.controls.questions.disable();
            this.sortQuestionnaireQuestions(); //this will only sort non-supplemental questions
        });

        this._actions$
            .pipe(
                ofType(
                    getArtifactRequestFailed,
                    deleteQuestionnaireQuestionRequestFailed,
                    addQuestionToQuestionnaireRequestFailed,
                    updateQuestionnaireQuestionRequestFailed,
                    getArtifactRequestSuccess,
                    deleteQuestionnaireQuestionRequestSuccess,
                    addQuestionToQuestionnaireRequestSuccess,
                    updateQuestionnaireQuestionRequestSuccess,
                ),
                takeUntil(this._unsub$),
            )
            .subscribe(() => {
                this.loadingQuestionnaireArtifact = false;
                this.newQuestion = null;
            });

        this._actions$.pipe(ofType(addQuestionToQuestionnaireRequestSuccess), takeUntil(this._unsub$)).subscribe(() => {
            this._snackbarService.success('Question added successfully');
        });
        this._actions$
            .pipe(ofType(updateQuestionnaireQuestionRequestSuccess), takeUntil(this._unsub$))
            .subscribe(() => {
                this._snackbarService.success('Question updated successfully');
            });
        this._actions$
            .pipe(ofType(deleteQuestionnaireQuestionRequestSuccess), takeUntil(this._unsub$))
            .subscribe(() => {
                this._snackbarService.success('Question deleted successfully');
            });

        this._actions$
            .pipe(
                ofType(
                    getArtifactRequest,
                    deleteQuestionnaireQuestionRequest,
                    addQuestionToQuestionnaireRequest,
                    updateQuestionnaireQuestionRequest,
                ),
                takeUntil(this._unsub$),
            )
            .subscribe(() => (this.loadingQuestionnaireArtifact = true));
    }

    downloadQuestionnaire(): void {
        this._store$.dispatch(downloadArtifact({ artifactId: this.questionnaireArtifact.id }));
    }

    editQuestion(questionnaireAnswer: FormGroup<QuestionnaireQuestionFormGroup>, index: number) {
        this.closeAllOtherEditStates(index);
        questionnaireAnswer.controls.question.enable();
    }

    cancelEditQuestion(questionnaireAnswer: FormGroup<QuestionnaireQuestionFormGroup>) {
        this.closeAllOtherEditStates(-1);
        questionnaireAnswer.controls.question.disable();
        questionnaireAnswer.controls.question.setValue(questionnaireAnswer.controls.originalQuestion.value);
    }

    deleteQuestion(questionnaireAnswer: FormGroup<QuestionnaireQuestionFormGroup>) {
        this._confirmDialogService
            .confirm({
                title: 'Delete Question',
                message: `Are you sure you want to delete the question:\n${questionnaireAnswer.controls.question.value}`,
                cancelLabel: 'Cancel',
                confirmLabel: 'Delete Question',
            })
            .pipe(filter((result) => result))
            .subscribe(() => {
                this._store$.dispatch(
                    deleteQuestionnaireQuestionRequest({
                        questionnaireId: this.questionnaireId,
                        questionId: questionnaireAnswer.controls.id.value,
                        question: questionnaireAnswer.controls.question.value,
                    }),
                );
            });
    }

    addQuestion() {
        if (!!this.newQuestion) {
            return;
        }
        const newQuestionReference = this._fb.group<QuestionnaireQuestionFormGroup>({
            id: this._fb.control<number>(null),
            question: this._fb.control<string>('', {
                validators: [Validators.required, noWhitespaceValidator],
                nonNullable: true,
            }),
            optional: this._fb.control<boolean>(false),
            answer: this._fb.control<string>(''),
            answerType: this._fb.control<QuestionnaireAnswerType>(null),
            controlDomainName: this._fb.control<string>(''),
            controlDomainType: this._fb.control<string>(''),
            controlName: this._fb.control<string>(''),
            originalQuestion: this._fb.control<string>(''),
            manualReviewNeeded: this._fb.control<boolean>(false),
        });
        this.questionnaireFormGroup.controls.questions.push(newQuestionReference);

        this.closeAllOtherEditStates(this.questionnaireFormGroup.controls.questions.length - 1);
        this.newQuestion = newQuestionReference;
        this.newQuestion.controls.question.enable();
    }

    closeAllOtherEditStates(index: number) {
        if (!!this.newQuestion) {
            this.questionnaireFormGroup.controls.questions.removeAt(
                this.questionnaireFormGroup.controls.questions.length - 1,
            );
            this.newQuestion = null;
        }
        this.questionnaireFormGroup.controls.questions.controls.forEach((ctrl, i) => {
            ctrl.controls.question.disable({ onlySelf: true });
            ctrl.controls.question.setValue(ctrl.controls.originalQuestion.value);
        });
    }

    saveQuestion(questionFormGroup: FormGroup<QuestionnaireQuestionFormGroup>) {
        const questionId = questionFormGroup.controls.id.value;
        if (!!questionId) {
            this._store$.dispatch(
                updateQuestionnaireQuestionRequest({
                    questionnaireId: this.questionnaireId,
                    questionId,
                    question: questionFormGroup.controls.question.value,
                }),
            );
        } else {
            this._store$.dispatch(
                addQuestionToQuestionnaireRequest({
                    questionnaireId: this.questionnaireId,
                    question: questionFormGroup.controls.question.value,
                }),
            );
        }
    }

    ngOnDestroy(): void {
        this._unsub$.next();
    }

    private sortQuestionnaireQuestions(): void {
        this.questionnaireFormGroup.controls.questions.controls.sort((a, b) => {
            if (
                a.value.controlDomainName?.toLowerCase() < b.value.controlDomainName?.toLowerCase() ||
                a.value.controlName?.toLowerCase() < b.value.controlName?.toLowerCase()
            ) {
                return -1;
            }
            return 0;
        });
    }

    private loadQuestionnaire() {
        this._store$.dispatch(getArtifactRequest({ artifactId: this.questionnaireId }));
    }

    private generateQuestionnaireQuestionFormGroup(
        questionnaireAnswer: QuestionnaireAnswer,
    ): FormGroup<QuestionnaireQuestionFormGroup> {
        return this._fb.group<QuestionnaireQuestionFormGroup>({
            id: this._fb.control<number>(questionnaireAnswer.questionId),
            question: this._fb.control<string>(questionnaireAnswer.question, {
                validators: [Validators.required, noWhitespaceValidator],
                nonNullable: true,
            }),
            optional: this._fb.control<boolean>(questionnaireAnswer.optional),
            answer: this._fb.control<string>(questionnaireAnswer.answer),
            answerType: this._fb.control<QuestionnaireAnswerType>(questionnaireAnswer.answerType),
            controlValidation: this._fb.control<ControlValidation>(questionnaireAnswer.controlValidation),
            controlDomainName: this._fb.control<string>(questionnaireAnswer.controlDomainName),
            controlDomainType: this._fb.control<string>(questionnaireAnswer.controlDomainType),
            controlName: this._fb.control<string>(questionnaireAnswer.controlName),
            originalQuestion: this._fb.control<string>(questionnaireAnswer.question),
            manualReviewNeeded: this._fb.control<boolean>(questionnaireAnswer.manualReviewNeeded),
        });
    }
}
