<div class="row mt-3">
    <div class="col">
        <ngx-datatable
            #importHistoryTable
            class="bootstrap"
            [columnMode]="'force'"
            [rowHeight]="35"
            [headerHeight]="30"
            [rows]="imports"
            [scrollbarV]="true"
            [sorts]="[{ prop: 'createdDate', dir: 'desc' }]"
            [count]="imports?.length"
            data-pw="importHistoryTable"
        >
            <ngx-datatable-column name="Timestamp" prop="createdDate">
                <ng-template let-row="row" ngx-datatable-cell-template
                    >{{ row.createdDate | date: 'medium' }}
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="File" prop="originalFileName"></ngx-datatable-column>
            <ngx-datatable-column name="Import Type" prop="type"
                ><ng-template let-row="row" ngx-datatable-cell-template
                    >{{ row.type ? (row.type | pascalCase) : '-' }}
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column cellClass="pt-0" [sortable]="false" [width]="100" [resizable]="false">
                <ng-template let-row="row" ngx-datatable-cell-template>
                    <button mat-button visoButton="link" (click)="downloadImportedFile(row.id)">Download</button>
                </ng-template>
            </ngx-datatable-column>
        </ngx-datatable>
    </div>
</div>
