import { createAction, props } from '@ngrx/store';
import { OpenAssessmentEmailViewParams } from '../../../entities/assessment';

export enum AssessmentEmailViewerActions {
    OpenAssessmentEmailView = '[Assessment Email Viewer] OpenAssessmentEmailView',
}

export const openAssessmentEmailView = createAction(
    AssessmentEmailViewerActions.OpenAssessmentEmailView,
    props<OpenAssessmentEmailViewParams>(),
);
