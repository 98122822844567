import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
    selector: 'app-viso-editor',
    templateUrl: './viso-editor.component.html',
    styleUrls: ['./viso-editor.component.scss'],
})
export class VisoEditorComponent {
    @Input({ required: true }) control: FormControl;
    @Input() height: number = 600; // number of pixels
    @Input() placeholder: string;

    addOrRemoveBullet() {
        const div = document.getElementById('editableDiv');
        const selection = window.getSelection();
        const range = selection.getRangeAt(0);
        const node = range.startContainer;

        // Find the <ul> right before the cursor
        let previousUnorderedList = node.parentNode.previousSibling;
        while (previousUnorderedList && previousUnorderedList.nodeName !== 'UL') {
            previousUnorderedList = previousUnorderedList.previousSibling;
        }

        // Check if the current node is a bullet point
        if (node.parentNode.nodeName === 'LI') {
            // If it is, we remove the bullet point
            const ul = node.parentNode.parentNode;
            const li = node.parentNode;
            const text = li.textContent;

            // Create a new <p> element with the same text
            const p = document.createElement('p');
            p.textContent = text;

            // Replace the <li> element with the <p> element at the same level as the <ul> - adding it to the end
            if (ul.nextSibling) {
                ul.parentNode.insertBefore(p, ul.nextSibling);
            } else {
                ul.parentNode.appendChild(p);
            }
            ul.removeChild(li);

            if (ul.childNodes.length === 0) {
                // If there are no more bullet points, we remove the list
                div.removeChild(ul);
            }
        } else if (previousUnorderedList?.nodeName === 'UL') {
            // If the current node is a text node under a <ul>, we add it as a bullet point
            const parent = node.parentNode;
            const text = node.textContent;

            // Create a new <li> element with the same text
            const li = document.createElement('li');
            li.textContent = text;

            previousUnorderedList.appendChild(li);

            // Remove the original text node
            parent.removeChild(node);
        } else {
            // If it's not, we add a bullet point
            const ul = document.createElement('ul');
            const li = document.createElement('li');

            li.textContent = '\u200B'; // Add zero-width space to allow empty list items
            ul.appendChild(li);

            range.insertNode(ul);
        }
    }
}
