import { Injectable } from '@angular/core';
import { AbstractControl, FormGroup, UntypedFormGroup } from '@angular/forms';
import { FormRawValue, FormValue } from '@shared/model/controls-of';

function cleanValue<T = any>(value: T): T {
    const iterate = (obj) => {
        Object.keys(obj).forEach((key) => {
            if (!obj[key]) {
                return;
            }
            if (typeof obj[key] === 'string') {
                obj[key] = (obj[key] as string).trim();
            } else if (typeof obj[key] === 'object') {
                iterate(obj[key]);
            }
        });
    };

    iterate(value);

    return value;
}

@Injectable({
    providedIn: 'root',
})
export class FormUtilsService {
    getCleanTypedFormGroupValue<T extends AbstractControl>(formGroup: T): FormValue<T>;
    getCleanTypedFormGroupValue<T extends AbstractControl>(formGroup: T, useRawValue: true): FormRawValue<T>;
    getCleanTypedFormGroupValue<T extends AbstractControl>(
        formGroup: T,
        useRawValue?: true,
    ): FormRawValue<T> | FormValue<T> {
        const formValue = JSON.parse(JSON.stringify(useRawValue ? formGroup.getRawValue() : formGroup.value));

        return cleanValue(formValue);
    }

    /**
     * @deprecated This method should not be used for new Typed forms
     */
    getCleanFormGroupValue<T = any>(formGroup: UntypedFormGroup | FormGroup<any>, useRawValue: boolean = false): T {
        const formValue = JSON.parse(JSON.stringify(useRawValue ? formGroup.getRawValue() : formGroup.value));

        return cleanValue<T>(formValue);
    }

    static cleanFormGroupValue<T = any>(formGroupValue: T): T {
        return cleanValue<T>(formGroupValue);
    }

    conditionalValidator(predicate, validator) {
        return (formControl) => {
            if (!formControl.parent) {
                return null;
            }
            if (predicate()) {
                return validator(formControl);
            }
            return null;
        };
    }
}
