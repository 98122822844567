import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { VanityDomainView } from '@entities/vanity-domain';

export enum VanityDomainStatus {
    PENDING,
    ERROR,
    ACTIVE,
}

enum CloudflareStatus {
    ACTIVE = 'active',
    BLOCKED = 'blocked',
    INACTIVE = 'inactive',
}

const CF_FAILED_SUFFIX = 'failed';
const CF_TIMED_OUT_SUFFIX = 'timed_out';

@Component({
    selector: 'app-vanity-domain',
    templateUrl: './vanity-domain.component.html',
    styleUrls: ['./vanity-domain.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VanityDomainComponent implements OnInit, OnChanges {
    @Input({ required: true })
    vanityDomain: VanityDomainView;

    @Output()
    refreshVanityDomains: EventEmitter<void> = new EventEmitter();

    VanityDomainStatus = VanityDomainStatus;

    status = VanityDomainStatus.ACTIVE;

    constructor() {}

    ngOnInit(): void {
        this.status = this.getStatus();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.status = this.getStatus();
    }

    getStatus() {
        if (
            this.statusIsActive(this.vanityDomain?.cloudflareStatus) &&
            this.statusIsActive(this.vanityDomain?.cloudflareSslStatus)
        )
            return VanityDomainStatus.ACTIVE;
        if (
            this.statusIsError(this.vanityDomain?.cloudflareStatus) ||
            this.statusIsError(this.vanityDomain?.cloudflareSslStatus)
        )
            return VanityDomainStatus.ERROR;
        return VanityDomainStatus.PENDING;
    }

    loadAll() {
        this.refreshVanityDomains.emit();
    }

    statusIsError(status?: string) {
        if (!status) return false;
        status = status.toLowerCase();
        return (
            status === CloudflareStatus.BLOCKED ||
            status === CloudflareStatus.INACTIVE ||
            status.endsWith(CF_FAILED_SUFFIX) ||
            status.endsWith(CF_TIMED_OUT_SUFFIX)
        );
    }
    statusIsActive(status?: string) {
        return status && status.toLowerCase() === CloudflareStatus.ACTIVE;
    }
}
