import { NgModule } from '@angular/core';
import { TimeAgoPipe } from './timeago.pipe';
import { CommonModule } from '@angular/common';

@NgModule({
    declarations: [TimeAgoPipe],
    imports: [CommonModule],
    exports: [TimeAgoPipe],
})
export class TimeAgoPipeModule {}
