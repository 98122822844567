import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AssessmentRecommendation, RecommendationGroupLabel, RecommendationType } from '@entities/recommendation';

function sortByRecommendationType(recommendations: AssessmentRecommendation[]): AssessmentRecommendation[] {
    const order = Object.values(RecommendationType);
    return recommendations?.sort((a, b) => order.indexOf(a.type) - order.indexOf(b.type));
}

@Component({
    selector: 'app-recommendations-list',
    templateUrl: './recommendations-list.component.html',
    styleUrls: ['./recommendations-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecommendationsListComponent {
    @Input({ required: true, transform: sortByRecommendationType })
    set recommendations(assessmentRecommendations: AssessmentRecommendation[]) {
        this.groupRecommendations(assessmentRecommendations || []);
    }

    @Input()
    showHeading = true;

    @Input()
    vendorName: string;

    recommendationGroups = new Map<RecommendationGroupLabel, AssessmentRecommendation[]>();

    groupRecommendations(recommendations: AssessmentRecommendation[]): void {
        this.recommendationGroups.clear();
        const artifactRecommendations: AssessmentRecommendation[] = [];
        const programRecommendations: AssessmentRecommendation[] = [];
        for (const recommendation of recommendations) {
            switch (recommendation.type) {
                case RecommendationType.HIGH_ASSURANCE:
                case RecommendationType.EXPIRED:
                case RecommendationType.CERT_ONLY:
                    artifactRecommendations.push(recommendation);
                    break;
                case RecommendationType.CONTROL_DOMAINS:
                case RecommendationType.LOW_COVERAGE:
                    programRecommendations.push(recommendation);
                    break;
            }
        }
        if (artifactRecommendations.length) {
            this.recommendationGroups.set(RecommendationGroupLabel.ARTIFACT, artifactRecommendations);
        }
        if (programRecommendations.length) {
            this.recommendationGroups.set(RecommendationGroupLabel.PROGRAM, programRecommendations);
        }
    }
}
