import { Injectable } from '@angular/core';
import { UserStatus, VisoUser, VisoUserGroupLabels, VisoUserGroups } from '../../../../entities/viso-user';
import { FastMention, QuillMentionListItem } from '../quill.model';

@Injectable({
    providedIn: 'root',
})
export class ObjectsToQuillMentionsConverterService {
    getDefaultFastMentions(): Partial<FastMention>[] {
        const userGroups = this.convertUserGroups();
        return [
            {
                mention: userGroups[0],
                mentionTooltip: 'Use this mention to send an email notification to all subscribers.',
            },
            {
                mention: userGroups[1],
                mentionTooltip: 'Use this mention to send an email notification to all auditors.',
            },
            {
                mention: userGroups[2],
                mentionTooltip: 'Use this mention to send an email notification to the primary third-party contact.',
            },
            {
                mention: userGroups[3],
                mentionTooltip: 'Use this mention to send an email notification to the Business Owner.',
            },
        ];
    }

    convertUserGroups(): Partial<QuillMentionListItem>[] {
        return [
            {
                id: `G${VisoUserGroups.SUBSCRIBERS}`,
                value: VisoUserGroupLabels.SUBSCRIBERS,
            },
            {
                id: `G${VisoUserGroups.AUDITORS}`,
                value: VisoUserGroupLabels.AUDITORS,
            },
            {
                id: `G${VisoUserGroups.THIRD_PARTY_PRIMARY}`,
                value: VisoUserGroupLabels.THIRD_PARTY_PRIMARY,
            },
            {
                id: `G${VisoUserGroups.BUSINESS_OWNER}`,
                value: VisoUserGroupLabels.BUSINESS_OWNER,
            },
        ];
    }

    convertUsers(visoUsers: VisoUser[]): Partial<QuillMentionListItem>[] {
        return visoUsers.map<Partial<QuillMentionListItem>>((user) => ({
            id: `U${user.id}`,
            value: `${user.firstName} ${user.lastName} ${user.status === UserStatus.INACTIVE ? ' (Inactive)' : ''}`,
            link: `/user-profile/${user.id}`,
        }));
    }
}
