import { Injectable, Type } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NgbModalWrapperService } from '../../shared';
import { ContactService } from './contact.service';
import { VisoUser } from '../../entities/viso-user';
import { combineLatest } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { getUserAccount } from '../session/redux/session.selectors';
import { take } from 'rxjs/operators';

@Injectable()
export class ContactPopupService {
    private ngbModalRef: NgbModalRef;

    constructor(
        private _modalService: NgbModalWrapperService,
        private _contactService: ContactService,
        private _store$: Store,
    ) {
        this.ngbModalRef = null;
    }

    open(component: Type<any>, id?: number | any): Promise<NgbModalRef> {
        return new Promise<NgbModalRef>((resolve, reject) => {
            const isOpen = this.ngbModalRef !== null;
            if (isOpen) {
                resolve(this.ngbModalRef);
            }

            combineLatest([this._store$.pipe(select(getUserAccount)), this._contactService.findAllForCurrentUserOrg()])
                .pipe(take(1))
                .subscribe(([currentUser, contactResponse]) => {
                    if (id) {
                        setTimeout(() => {
                            const contactToEdit = contactResponse.body.find((c) => c.id.toString() === id.toString());
                            this.ngbModalRef = this.contactModalRef(
                                component,
                                contactToEdit,
                                contactResponse.body,
                                currentUser,
                            );
                            resolve(this.ngbModalRef);
                        }, 0);
                    } else {
                        // setTimeout used as a workaround for getting ExpressionChangedAfterItHasBeenCheckedError
                        setTimeout(() => {
                            this.ngbModalRef = this.contactModalRef(
                                component,
                                new VisoUser(),
                                contactResponse.body,
                                currentUser,
                            );
                            resolve(this.ngbModalRef);
                        }, 0);
                    }
                });
        });
    }

    contactModalRef(
        component: Type<any>,
        contact: VisoUser,
        contactsForOrg: VisoUser[],
        currentUser: VisoUser,
    ): NgbModalRef {
        const modalRef = this._modalService.open(
            component,
            {
                contact,
                contactsForOrg,
                currentUser,
            },
            { size: 'md', backdrop: 'static' },
        );
        return modalRef;
    }
}
