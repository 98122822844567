import { FileArtifactType } from '@entities/artifact';
import { createSelector, MemoizedSelector } from '@ngrx/store';
import {
    AttestationsGroups,
    ExpiredDocumentationRecommendation,
    isCyberInsuranceRecommendation,
    isExpiredDocumentationRecommendation,
    isPrivacyRecommendation,
    isSiblingDocumentationRecommendation,
    isThirdPartyAuditUploadRecommendation,
    isThirdPartyPenTestRecommendation,
    SiblingDocumentationRecommendation,
} from '../../models';
import { AppState } from '../assessment-collection.state';
import { getIsCyberInsuranceInScope, getIsPenTestInScope, getIsPrivacyInScope } from './assessment-data.selectors';
import { getArtifactUploadRecommendations } from './main.selectors';

export const getAreThereUploadRecommendations: MemoizedSelector<AppState, boolean> = createSelector(
    getArtifactUploadRecommendations,
    (recommendations) => !!recommendations?.length,
);

export const getExpiredDocumentationRecommendations: MemoizedSelector<AppState, ExpiredDocumentationRecommendation[]> =
    createSelector(getArtifactUploadRecommendations, (recommendations) =>
        recommendations.filter(isExpiredDocumentationRecommendation),
    );

export const getSiblingDocumentationRecommendations: MemoizedSelector<AppState, SiblingDocumentationRecommendation[]> =
    createSelector(getArtifactUploadRecommendations, (recommendations) =>
        recommendations.filter(isSiblingDocumentationRecommendation),
    );

export const getAttestationGroupsFromRecommendations: MemoizedSelector<AppState, AttestationsGroups> = createSelector(
    getArtifactUploadRecommendations,
    (recommendations) => {
        const attestationGroups = recommendations
            .filter((rec) => !!rec.attestationValue)
            .reduce(
                (groups, recommendation) => {
                    if (isSiblingDocumentationRecommendation(recommendation)) {
                        groups.siblingArtifactAttestations[recommendation.artifactId] = recommendation.attestationValue;
                    } else if (isExpiredDocumentationRecommendation(recommendation)) {
                        groups.expiredArtifactAttestations[recommendation.artifactId] = recommendation.attestationValue;
                    } else {
                        groups.attestations[recommendation.attestationType] = recommendation.attestationValue;
                    }
                    return groups;
                },
                {
                    attestations: {},
                    siblingArtifactAttestations: {},
                    expiredArtifactAttestations: {},
                } as AttestationsGroups,
            );

        return attestationGroups;
    },
);

export const getUploadedStatusesOfInScopeFileTypes: MemoizedSelector<
    AppState,
    Partial<Record<FileArtifactType, boolean>>
> = createSelector(
    getArtifactUploadRecommendations,
    getIsPrivacyInScope,
    getIsPenTestInScope,
    getIsCyberInsuranceInScope,
    (recommendations, privacyInScope, penTestInScope, cyberInsuranceInScope) => {
        const result: Partial<Record<FileArtifactType, boolean>> = {
            AUDIT_REPORT: true, // audit report always in scope.
        };

        if (privacyInScope) {
            result.PRIVACY = true;
        }
        if (penTestInScope) {
            result.ASSESSMENT = true;
        }
        if (cyberInsuranceInScope) {
            result.CYBER_INSURANCE = true;
        }

        // Area is satisfied if no recommendation exists OR the recommendation has an artifact uploaded for it.
        for (const recommendation of recommendations) {
            if (isThirdPartyAuditUploadRecommendation(recommendation)) {
                result.AUDIT_REPORT = !!recommendation.attestationArtifact;
            } else if (isPrivacyRecommendation(recommendation)) {
                result.PRIVACY = !!recommendation.attestationArtifact;
            } else if (isThirdPartyPenTestRecommendation(recommendation)) {
                result.ASSESSMENT = !!recommendation.attestationArtifact;
            } else if (isCyberInsuranceRecommendation(recommendation)) {
                result.CYBER_INSURANCE = !!recommendation.attestationArtifact;
            }
        }

        return result;
    },
);
