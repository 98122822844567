import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { RelationshipService } from '../../../../entities/relationship';
import {
    getRequestMentionableUsersRequest,
    getRequestMentionableUsersRequestFailed,
    getRequestMentionableUsersRequestSuccess,
} from '../actions/mentionable-users.actions';

@Injectable()
export class MentionableUsersEffects {
    getRequestMentionableUsersRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(getRequestMentionableUsersRequest),
            switchMap(({ requestId }) =>
                this._relationshipService.getMentionableUsers(requestId).pipe(
                    map((response) => response.body),
                    map((mentionableUsers) => getRequestMentionableUsersRequestSuccess({ mentionableUsers })),
                    catchError(() => of(getRequestMentionableUsersRequestFailed())),
                ),
            ),
        ),
    );

    constructor(
        private _actions$: Actions,
        private _relationshipService: RelationshipService,
    ) {}
}
