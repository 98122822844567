import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormUtilsService } from '@shared/utils/form-utils.service';
import { MatDialogRef } from '@angular/material/dialog';
import { Actions, ofType } from '@ngrx/effects';
import {
    OrgSubdomainActions,
    setOrgSubdomainRequest,
    setOrgSubdomainRequestSuccess,
} from '../../org-subdomain/redux/org-subdomain.actions';
import { takeUntil, tap } from 'rxjs/operators';
import { BehaviorSubject, Subject } from 'rxjs';
import { SnackbarService } from '@shared/components/snackbar/snackbar.service';
import { noWhitespaceValidator } from '@shared/validators/whitespace-validator';
import { subdomainValidator } from '@shared/validators/subdomain-validator';
import {
    createVanityDomainRequest,
    createVanityDomainRequestSuccess,
    VanityDomainActions,
} from '../../vanity-domain/redux/vanity-domain.actions';
import { Store } from '@ngrx/store';
import { MatStepper } from '@angular/material/stepper';

export enum DomainType {
    VANITY = 'VANITY',
    SUBDOMAIN = 'SUBDOMAIN',
}

@Component({
    templateUrl: './customize-domain-dialog.component.html',
    styleUrls: ['./customize-domain-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class CustomizeDomainDialogComponent implements OnInit, OnDestroy {
    @Input({ required: true })
    orgDomains: String[];

    @Input({ required: true })
    cname: String;

    @Input({ required: true })
    domain: String;

    typeFormGroup: FormGroup;
    subdomainFormGroup: FormGroup;
    vanityDomainFormGroup: FormGroup;

    DomainType = DomainType;
    OrgSubdomainActions = OrgSubdomainActions;
    VanityDomainActions = VanityDomainActions;

    @ViewChild('domainStepper', { static: false }) stepper: MatStepper;

    domainType$ = new BehaviorSubject<DomainType>(DomainType.SUBDOMAIN);
    step$ = new BehaviorSubject<number>(0);

    private _unsub$ = new Subject<void>();

    constructor(
        private _snackbarService: SnackbarService,
        private _store$: Store,
        private _actions$: Actions,
        private _formBuilder: FormBuilder,
        private _formUtils: FormUtilsService,
        private _dialogRef: MatDialogRef<CustomizeDomainDialogComponent, null>,
    ) {}

    ngOnInit(): void {
        this.typeFormGroup = this._formBuilder.group({
            type: ['', Validators.required],
        });

        this.subdomainFormGroup = this._formBuilder.group({
            subdomain: ['', [Validators.required, noWhitespaceValidator, subdomainValidator]],
        });

        this.vanityDomainFormGroup = this._formBuilder.group({
            vanityDomain: ['', [Validators.required, noWhitespaceValidator, subdomainValidator]],
            domain: ['', [Validators.required, noWhitespaceValidator]],
            cnameExists: [false, [Validators.requiredTrue]],
        });

        this._actions$
            .pipe(
                ofType(setOrgSubdomainRequestSuccess),
                tap(() => {
                    this._snackbarService.success('Subdomain updated!');
                    this._dialogRef.close(null);
                }),
                takeUntil(this._unsub$),
            )
            .subscribe();

        this._actions$
            .pipe(
                ofType(createVanityDomainRequestSuccess),
                tap(() => {
                    this._snackbarService.success('Vanity domain created!');
                    this._dialogRef.close(null);
                }),
                takeUntil(this._unsub$),
            )
            .subscribe();
    }

    save() {
        const props = this._formUtils.getCleanTypedFormGroupValue(this.currentFormGroup);
        this._store$.dispatch(
            this.domainType$.value == DomainType.VANITY
                ? createVanityDomainRequest({ subdomain: props.vanityDomain, domain: props.domain })
                : setOrgSubdomainRequest(props),
        );
    }

    prev() {
        this.stepper.previous();
    }

    cancel() {
        this._dialogRef.close(null);
    }

    next(index: number) {
        if (index === 0) {
            const { type } = this._formUtils.getCleanTypedFormGroupValue(this.typeFormGroup);
            this.domainType$.next(type);
        }
        this.stepper.next();
    }

    get currentFormGroup() {
        if ((this.stepper?.selectedIndex || 0) === 0) {
            return this.typeFormGroup;
        }
        return this.domainType$.value === DomainType.SUBDOMAIN ? this.subdomainFormGroup : this.vanityDomainFormGroup;
    }

    onStepperIndexChange(index: number) {
        this.step$.next(index);
    }

    ngOnDestroy(): void {
        this._unsub$.next();
    }
}
