<form [formGroup]="businessCasesFormGroup" class="pt-3" data-pw="contextTypesContainer">
    <ng-container formArrayName="items">
        <ng-container *ngFor="let contextTypeFormItem of contextTypeFormItems; let i = index" [formGroupName]="i">
            <ng-container *ngIf="contextTypeFormItem | callback: getContextTypeRef as contextType">
                <div class="checkbox-group-container">
                    <mat-checkbox
                        color="primary"
                        class="col-direction right-checkbox outline-checkbox w-100"
                        formControlName="checked"
                        [labelPosition]="'before'"
                    >
                        <span class="label-black mb-2">{{ contextType.name }} </span>
                        <p class="fw-normal description">
                            {{
                                contextType.questionText
                                    | replace: clientOrgNameRegex : clientOrgName
                                    | replace: thirdPartyOrgNameRegex : thirdPartyOrgName
                            }}
                        </p>
                    </mat-checkbox>
                </div>
            </ng-container>
        </ng-container>
    </ng-container>
</form>
