import { Component } from '@angular/core';
import { HubspotManagementService } from './hubspot-management.service';
import { SnackbarService } from '@shared/components/snackbar/snackbar.service';
import { take } from 'rxjs/operators';

@Component({
    selector: 'app-hubspot-management',
    templateUrl: './hubspot-management.component.html',
    styleUrls: ['./hubspot-management.component.scss'],
})
export class HubspotManagementComponent {
    constructor(
        private _hubspotManagementService: HubspotManagementService,
        private _snackbarService: SnackbarService,
    ) {}

    syncCompaniesWithHubspot() {
        this._hubspotManagementService
            .syncCompaniesWithHubspot()
            .pipe(take(1))
            .subscribe((msg) => this._snackbarService.success(msg));
    }

    syncContactsWithHubspot() {
        this._hubspotManagementService
            .syncContactsWithHubspot()
            .pipe(take(1))
            .subscribe((msg) => this._snackbarService.success(msg));
    }
}
