import { Pipe, PipeTransform } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Risk } from '../../entities/risk-assessment';
import { getRiskLevelDisplayName } from '../../routes/session/redux/session.selectors';

@Pipe({
    name: 'riskLevel',
})
export class RiskLevelConfigPipe implements PipeTransform {
    constructor(private _store$: Store) {}

    transform(value?: string | Risk): Observable<string> {
        return this._store$.pipe(select(getRiskLevelDisplayName(value?.toString())));
    }
}
