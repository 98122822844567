export enum IntegrationType {
    SLACK = 'SLACK',
    COUPA = 'COUPA',
    NETSKOPE = 'NETSKOPE',
    SERVICENOW = 'SERVICENOW',
}

export enum IntegrationTypeLabels {
    SLACK = 'Slack',
    COUPA = 'Coupa',
    NETSKOPE = 'Netskope',
    SERVICENOW = 'ServiceNow',
}
