import { Action, createReducer, on } from '@ngrx/store';
import { VanityDomainsState, initialState } from './vanity-domain.state';
import {
    createVanityDomainRequestSuccess,
    getVanityDomainsConfigRequestSuccess,
    getVanityDomainRequestSuccess,
    deleteVanityDomainRequestSuccess,
} from './vanity-domain.actions';

const reducer = createReducer(
    initialState,
    on(getVanityDomainRequestSuccess, (state, { status }) => ({
        ...state,
        vanityDomain: status,
    })),
    on(createVanityDomainRequestSuccess, (state, { status }) => ({
        ...state,
        vanityDomain: status,
    })),
    on(getVanityDomainsConfigRequestSuccess, (state, { config }) => ({
        ...state,
        cname: config.cname,
        orgDomains: config.orgDomains,
    })),
    on(deleteVanityDomainRequestSuccess, (state) => ({
        ...state,
        vanityDomain: null,
    })),
);

export function vanityDomainReducer(state: VanityDomainsState = initialState, action: Action): VanityDomainsState {
    return reducer(state, action);
}
