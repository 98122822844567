import { HttpEvent, HttpEventType } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, takeUntil } from 'rxjs/operators';
import { saveFileFromResBlob } from '../../../shared/file-download/redux/actions';
import { BulkImportService } from '../bulk-import.service';
import {
    confirmUploadedImportRequest,
    confirmUploadedImportRequestFailed,
    confirmUploadedImportRequestSuccess,
    downloadImportedFileRequest,
    downloadImportedFileRequestFailed,
    getImportHistoryRequest,
    getImportHistoryRequestSuccess,
    uploadImportCsvRequest,
    uploadImportCsvRequestCancelled,
    uploadImportCsvRequestFailed,
    uploadImportCsvRequestProgress,
    uploadImportCsvRequestSuccess,
} from './bulk-import.actions';

@Injectable()
export class BulkImportEffects {
    getImportHistory$ = createEffect(() =>
        this._actions$.pipe(
            ofType(getImportHistoryRequest),
            switchMap(() =>
                this._bulkImportService.getImportHistory().pipe(
                    map((response) => getImportHistoryRequestSuccess({ importHistory: response.body })),
                    catchError(() => EMPTY),
                ),
            ),
        ),
    );

    uploadImportCsv$ = createEffect(() =>
        this._actions$.pipe(
            ofType(uploadImportCsvRequest),
            switchMap(({ file }) =>
                this._bulkImportService.uploadImportCsv(file).pipe(
                    mergeMap((event: HttpEvent<any>) => {
                        switch (event.type) {
                            case HttpEventType.UploadProgress:
                                let percent = Math.round(100 * (event.loaded / event.total));
                                if (percent === 100) {
                                    percent = 99;
                                }
                                return of(uploadImportCsvRequestProgress({ percent }));
                            case HttpEventType.Response:
                                return [
                                    uploadImportCsvRequestProgress({ percent: 100 }),
                                    uploadImportCsvRequestSuccess({ importReport: event.body }),
                                ];
                            default:
                                return EMPTY;
                        }
                    }),
                    catchError(() => of(uploadImportCsvRequestFailed())),
                    takeUntil(this._actions$.pipe(ofType(uploadImportCsvRequestCancelled))),
                ),
            ),
        ),
    );

    confirmUpload$ = createEffect(() =>
        this._actions$.pipe(
            ofType(confirmUploadedImportRequest),
            switchMap(({ importJobId }) =>
                this._bulkImportService.confirmUpload(importJobId).pipe(
                    map((response) => confirmUploadedImportRequestSuccess({ importReport: response.body })),
                    catchError(() => of(confirmUploadedImportRequestFailed())),
                ),
            ),
        ),
    );

    downloadImportedFile$ = createEffect(() =>
        this._actions$.pipe(
            ofType(downloadImportedFileRequest),
            switchMap(({ importJobId }) =>
                this._bulkImportService.downloadArtifactsOnRelationshipAsZip(importJobId).pipe(
                    map((fileResponseBlob) => saveFileFromResBlob({ fileResponseBlob })),
                    catchError(() => of(downloadImportedFileRequestFailed())),
                ),
            ),
        ),
    );

    constructor(
        private _actions$: Actions,
        private _bulkImportService: BulkImportService,
    ) {}
}
