import { createAction, props } from '@ngrx/store';
import { Attachment } from '../../../../entities/attachment';
import { UploadRequestAttachmentRequest } from '../../../../entities/attachment';

export enum AttachmentActions {
    GetRequestAttachmentRequest = '[Request] Get  Request Attachments Request',
    GetRequestAttachmentRequestSuccess = '[Request] Get  Request Attachments Request Success',
    GetRequestAttachmentRequestFailed = '[Request] Get  Request Attachments Request Failed',
    DeleteRequestAttachmentRequest = '[Request] Delete Request Attachment Request',
    DeleteRequestAttachmentRequestSuccess = '[Request] Delete Request Attachment Request Success',
    DeleteRequestAttachmentRequestFailed = '[Request] Delete Request Attachment Request Failed',
    DownloadAttachment = '[Request] Download Attachment',
    DownloadAttachmentFailed = '[Request] Download Attachment Failed',
    DownloadRelationshipAttachmentsAsZip = '[Request] Download Relationship Attachments As Zip',
    DownloadRelationshipAttachmentsAsZipFailed = '[Request] Download Relationship Attachments As Zip Failed',
    UploadRequestAttachmentRequest = '[Request] Upload  Request Attachment Request',
    UploadRequestAttachmentRequestSuccess = '[Request] Upload  Request Attachment Request Success',
    UploadRequestAttachmentRequestFailed = '[Request] Upload  Request Attachment Request Failed',
}

export const getRequestAttachmentRequest = createAction(
    AttachmentActions.GetRequestAttachmentRequest,
    props<{ requestId: number }>(),
);

export const getRequestAttachmentRequestSuccess = createAction(
    AttachmentActions.GetRequestAttachmentRequestSuccess,
    props<{ attachments: Attachment[] }>(),
);

export const getRequestAttachmentsRequestFailed = createAction(AttachmentActions.GetRequestAttachmentRequestFailed);

export const deleteRequestAttachmentRequest = createAction(
    AttachmentActions.DeleteRequestAttachmentRequest,
    props<{ requestId: number; attachmentId: number }>(),
);

export const deleteRequestAttachmentRequestSuccess = createAction(
    AttachmentActions.DeleteRequestAttachmentRequestSuccess,
    props<{ requestId: number }>(),
);

export const deleteRequestAttachmentRequestFailed = createAction(
    AttachmentActions.DeleteRequestAttachmentRequestFailed,
);

export const downloadAttachment = createAction(
    AttachmentActions.DownloadAttachment,
    props<{ requestId: number; attachmentId: number }>(),
);

export const downloadAttachmentFailed = createAction(AttachmentActions.DownloadAttachmentFailed);

export const downloadRelationshipAttachmentsAsZip = createAction(
    AttachmentActions.DownloadRelationshipAttachmentsAsZip,
    props<{ requestId: number; attachmentIds: number[] }>(),
);

export const downloadRelationshipAttachmentsAsZipFailed = createAction(
    AttachmentActions.DownloadRelationshipAttachmentsAsZipFailed,
);

export const uploadRequestAttachmentRequest = createAction(
    AttachmentActions.UploadRequestAttachmentRequest,
    props<{ requestId: number; requests: UploadRequestAttachmentRequest[] }>(),
);

export const uploadRequestAttachmentRequestSuccess = createAction(
    AttachmentActions.UploadRequestAttachmentRequestSuccess,
    props<{ requestId: number }>(),
);

export const uploadRequestAttachmentRequestFailed = createAction(
    AttachmentActions.UploadRequestAttachmentRequestFailed,
);
