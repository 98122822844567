import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { RouterNavigationAction, ROUTER_NAVIGATED } from '@ngrx/router-store';
import { of } from 'rxjs';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';
import {
    ContactReassignModalComponent,
    ContactDeleteDialogComponent,
    ContactStatusDialogComponent,
} from '../../contact';
import { NgbModalWrapperService } from '../../../shared';
import { SnackbarService } from '../../../shared/components/snackbar/snackbar.service';
import {
    createContactRequest,
    createContactRequestFailed,
    createContactRequestSuccess,
    deleteContactRequest,
    deleteContactRequestFailed,
    deleteContactRequestSuccess,
    updateManagedContactStatusRequest,
    updateManagedContactStatusRequestFailed,
    updateManagedContactStatusRequestSuccess,
    getContactsRequest,
    getContactsRequestFailed,
    getContactsRequestSuccess,
    openDeleteContactConfirmationModal,
    openUpdateContactStatusConfirmationModal,
    reassignContactRelationshipsRequest,
    reassignContactRelationshipsRequestFailed,
    reassignContactRelationshipsRequestSuccess,
    updateContactRequest,
    updateContactRequestFailed,
    updateContactRequestSuccess,
} from './contact-management.actions';
import { ContactService } from '../../contact/contact.service';

@Injectable()
export class ContactEffects {
    getContactsRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(getContactsRequest),
            switchMap(({ req, clientId }) =>
                this._contactService.findAllForCurrentUserOrg(req, clientId).pipe(
                    map((response) =>
                        getContactsRequestSuccess({
                            contacts: response.body,
                            headers: response.headers,
                        }),
                    ),
                    catchError(() => of(getContactsRequestFailed())),
                ),
            ),
        ),
    );

    createContactRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(createContactRequest),
            switchMap(({ contact }) =>
                this._contactService.create(contact).pipe(
                    map((response) => response.body),
                    map((contact) => createContactRequestSuccess({ contact })),
                    catchError((error) => of(createContactRequestFailed({ error, contact }))),
                ),
            ),
        ),
    );

    createUpdateContactRequestSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(createContactRequestSuccess, updateContactRequestSuccess),
                tap(() => {
                    this._snackbarService.success('Successfully saved user');
                }),
            ),
        { dispatch: false },
    );

    updateContactRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(updateContactRequest),
            switchMap(({ contact }) =>
                this._contactService.update(contact).pipe(
                    map((response) => response.body),
                    map((contact) => updateContactRequestSuccess({ contact })),
                    catchError((error) => of(updateContactRequestFailed({ error, contact }))),
                ),
            ),
        ),
    );

    openTransferContactModal$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(ROUTER_NAVIGATED),
                map((action: RouterNavigationAction) =>
                    action.payload.routerState.root.children.find((route) => route.outlet === 'popup'),
                ),
                filter((route) => !!route && route.routeConfig.path === 'user/:id/transfer'),
                map((route) => ({ contactId: route.params.id })),
                tap(({ contactId }) =>
                    this._modalService.open<ContactReassignModalComponent>(
                        ContactReassignModalComponent,
                        {
                            contactId,
                        },
                        { size: 'md' },
                    ),
                ),
            ),
        { dispatch: false },
    );

    reassignContactRelationshipsRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(reassignContactRelationshipsRequest),
            switchMap(({ fromContactId, toContactId, reassignOwnerships, reassignSubscriptions }) =>
                this._contactService
                    .reassignRelationships(fromContactId, toContactId, reassignOwnerships, reassignSubscriptions)
                    .pipe(
                        map(() => reassignContactRelationshipsRequestSuccess()),
                        catchError(() => of(reassignContactRelationshipsRequestFailed())),
                    ),
            ),
        ),
    );

    reassignContactRelationshipsRequestSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(reassignContactRelationshipsRequestSuccess),
                tap(() => {
                    this._snackbarService.success('Successfully reassigned relationships');
                }),
            ),
        { dispatch: false },
    );

    updateManagedContactStatusRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(updateManagedContactStatusRequest),
            switchMap(({ contact }) =>
                this._contactService.update(contact).pipe(
                    map((response) => response.body),
                    map((contact) => updateManagedContactStatusRequestSuccess({ contact })),
                    catchError((error) => of(updateManagedContactStatusRequestFailed({ error, contact }))),
                ),
            ),
        ),
    );

    deleteContactRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(deleteContactRequest),
            switchMap(({ contactId }) =>
                this._contactService.delete(contactId).pipe(
                    map((response) => deleteContactRequestSuccess()),
                    catchError(() => of(deleteContactRequestFailed())),
                ),
            ),
        ),
    );

    openDeleteContactConfirmationModal$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(openDeleteContactConfirmationModal),
                tap(({ contact }) =>
                    this._modalService.open<ContactDeleteDialogComponent>(
                        ContactDeleteDialogComponent,
                        { contact },
                        { size: 'md', backdrop: 'static' },
                    ),
                ),
            ),
        { dispatch: false },
    );

    openUpdateContactStatusConfirmationModal$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(openUpdateContactStatusConfirmationModal),
                tap(({ contact }) =>
                    this._modalService.open<ContactStatusDialogComponent>(
                        ContactStatusDialogComponent,
                        { contact },
                        { size: 'md', backdrop: 'static' },
                    ),
                ),
            ),
        { dispatch: false },
    );

    constructor(
        private _actions$: Actions,
        private _contactService: ContactService,
        private _snackbarService: SnackbarService,
        private _modalService: NgbModalWrapperService,
    ) {}
}
