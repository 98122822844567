import { ResolveFn } from '@angular/router';
import { inject } from '@angular/core';
import { Artifact, ArtifactService } from '@entities/artifact';
import { map } from 'rxjs/operators';

export const relationshipArtifactsResolver: ResolveFn<Artifact[]> = (route, state) => {
    const relationshipId = +route.paramMap.get('relationshipId');
    const artifactService = inject(ArtifactService);
    return artifactService.findByRelationship(relationshipId).pipe(map((res) => res.body));
};
