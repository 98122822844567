<ng-container *ngIf="loadingSummary; else doneLoading">
    <div class="d-flex align-items-center justify-content-center">
        <div class="sk-spinner sk-spinner-pulse"></div>
    </div>
</ng-container>
<ng-template #doneLoading>
    <app-assessment-summary-config-form
        [assessmentSummarySections]="assessmentSummaryConfig$ | async"
        [isCurrentUserOrgAdmin]="isCurrentUserOrgAdmin$ | async"
        [exampleSummary]="exampleSummary$ | async"
        (assessmentSummaryConfigChanged)="assessmentSummaryConfigChanged($event)"
    />
</ng-template>
