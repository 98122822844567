import { MigrationManifest, PersistedState } from '../../../shared/redux/migration/migration.model';
import { ColumnNames } from '../relationships.constants';
import { RelationshipsState } from './relationships-state';

export const relationshipsMigrations: MigrationManifest<RelationshipsState> = {
    1: (state): PersistedState<RelationshipsState> => {
        return {
            ...state,
            columnsGroups: state.columnsGroups.map((columnGroup) => {
                if (columnGroup.columnGroupName === 'Vendor Review') {
                    const updatedColumns = [...columnGroup.columns];
                    const riskColumnIndex = updatedColumns.findIndex((col) => col.columnName === ColumnNames.risk);
                    const newColumns = ['Residual Risk', 'Inherent Risk'];
                    newColumns.forEach((columnName) => {
                        if (!updatedColumns.some((col) => col.columnName === columnName)) {
                            updatedColumns.splice(riskColumnIndex + 1, 0, { columnName, visible: true });
                        }
                    });
                    return { ...columnGroup, columns: updatedColumns };
                }
                return columnGroup;
            }),
        };
    },
    2: (state): PersistedState<RelationshipsState> => {
        return {
            ...state,
            columnsGroups: state.columnsGroups.map((columnGroup) => {
                if (columnGroup.columnGroupName === 'Vendor Review') {
                    const updatedColumns = [...columnGroup.columns];
                    const targetColumns = ['Residual Risk', 'Inherent Risk'];
                    updatedColumns.forEach((column) => {
                        if (targetColumns.includes(column.columnName)) {
                            column.visible = false;
                        }
                    });
                    return { ...columnGroup, columns: updatedColumns };
                }
                return columnGroup;
            }),
        };
    },
    3: (state): PersistedState<RelationshipsState> => {
        return {
            ...state,
            columnsGroups: state.columnsGroups.map((columnGroup) => {
                if (columnGroup.columnGroupName === 'Metadata') {
                    const updatedColumns = [...columnGroup.columns];
                    updatedColumns.push({ columnName: 'Business Owner', visible: false });
                    return { ...columnGroup, columns: updatedColumns };
                }
                return columnGroup;
            }),
        };
    },
    4: (state): PersistedState<RelationshipsState> => {
        return {
            ...state,
            columnsGroups: state.columnsGroups.map((columnGroup) => {
                if (columnGroup.columnGroupName === 'Metadata') {
                    const updatedColumns = [...columnGroup.columns];
                    updatedColumns.push({ columnName: ColumnNames.recertificationType, visible: true });
                    return { ...columnGroup, columns: updatedColumns };
                }
                return columnGroup;
            }),
        };
    },
    5: (state): PersistedState<RelationshipsState> => {
        return {
            ...state,
            columnsGroups: state.columnsGroups.map((columnGroup) => {
                return {
                    ...columnGroup,
                    columns: columnGroup.columns.map((column) => {
                        if (column.columnName === 'Inherent Risk') {
                            return { ...column, columnName: 'Potential Risk' };
                        }
                        return column;
                    }),
                };
            }),
        };
    },
    6: (state): PersistedState<RelationshipsState> => {
        return {
            ...state,
            columnsGroups: state.columnsGroups.map((columnGroup) => {
                if (columnGroup.columnGroupName === 'Metadata') {
                    const updatedColumns = [...columnGroup.columns];
                    updatedColumns.push({ columnName: 'Primary Contact', visible: true });
                    return { ...columnGroup, columns: updatedColumns };
                }
                return columnGroup;
            }),
        };
    },
    7: (state): PersistedState<RelationshipsState> => {
        return {
            ...state,
            columnsGroups: state.columnsGroups.map((columnGroup) => {
                if (columnGroup.columnGroupName === 'Metadata') {
                    const updatedColumns = columnGroup.columns.map((column) => {
                        if (column.columnName === 'Business Owner') {
                            return { ...column, columnName: ColumnNames.businessOwnerName, visible: false };
                        }
                        if (column.columnName === 'Primary Contact') {
                            return { ...column, columnName: ColumnNames.primaryContactEmail, visible: true };
                        }
                        return column;
                    });
                    updatedColumns.push({ columnName: ColumnNames.createdBy, visible: false });
                    updatedColumns.push({ columnName: ColumnNames.primaryContactName, visible: false });
                    updatedColumns.push({ columnName: ColumnNames.businessOwnerEmail, visible: false });
                    return { ...columnGroup, columns: updatedColumns };
                }
                return columnGroup;
            }),
        };
    },
    8: (state): PersistedState<RelationshipsState> => {
        return {
            ...state,
            columnsGroups: state.columnsGroups.map((columnGroup) => {
                if (columnGroup.columnGroupName === 'Metadata') {
                    const updatedColumns = columnGroup.columns.map((column) => {
                        if (column.columnName === 'Business Owner Email') {
                            return { ...column, columnName: ColumnNames.businessOwnerEmail, visible: true };
                        }
                        if (column.columnName === 'Created By') {
                            return { ...column, columnName: ColumnNames.createdBy, visible: false };
                        }
                        return column;
                    });
                    return { ...columnGroup, columns: updatedColumns };
                }
                return columnGroup;
            }),
        };
    },
    9: (state): PersistedState<RelationshipsState> => {
        return {
            ...state,
            columnsGroups: state.columnsGroups.map((columnGroup) => {
                if (columnGroup.columnGroupName === 'Metadata') {
                    const updatedColumns = [...columnGroup.columns];
                    updatedColumns.unshift({ columnName: ColumnNames.tags, visible: true });
                    return { ...columnGroup, columns: updatedColumns };
                }
                return columnGroup;
            }),
        };
    },
    10: (state): PersistedState<RelationshipsState> => {
        return {
            ...state,
            columnsGroups: state.columnsGroups.map((columnGroup) => {
                return {
                    ...columnGroup,
                    columns: columnGroup.columns.map((column) => {
                        if (column.columnName === 'Potential Risk') {
                            return { ...column, columnName: 'Inherent Risk' };
                        }
                        return column;
                    }),
                };
            }),
        };
    },
    11: (state): PersistedState<RelationshipsState> => {
        return {
            ...state,
            columnsGroups: state.columnsGroups.map((columnGroup) => {
                if (columnGroup.columnGroupName === 'Metadata') {
                    const updatedColumns = columnGroup.columns.map((column) => {
                        if (column.columnName === 'Artifact Updates') {
                            return { ...column, columnName: 'Artifact Updates', visible: false };
                        }
                        return column;
                    });
                    return { ...columnGroup, columns: updatedColumns };
                }
                return columnGroup;
            }),
        };
    },
    12: (state): PersistedState<RelationshipsState> => {
        return {
            ...state,
            columnsGroups: state.columnsGroups.map((columnGroup) => {
                if (columnGroup.columnGroupName === 'Vendor Review') {
                    const updatedColumns = columnGroup.columns.map((column) => {
                        if (column.columnName === 'Residual Risk Score') {
                            return { ...column, columnName: ColumnNames.score, visible: false };
                        }
                        if (column.columnName === 'Inherent Risk Score') {
                            return { ...column, columnName: ColumnNames.inherentRiskScore, visible: false };
                        }
                        return column;
                    });
                    updatedColumns.push({ columnName: ColumnNames.score, visible: false });
                    updatedColumns.push({ columnName: ColumnNames.inherentRiskScore, visible: false });
                    return { ...columnGroup, columns: updatedColumns };
                }
                return columnGroup;
            }),
        };
    },
    13: (state): PersistedState<RelationshipsState> => {
        return {
            ...state,
            columnsGroups: state.columnsGroups.map((columnGroup) => {
                if (columnGroup.columnGroupName === 'Metadata') {
                    const updatedColumns = columnGroup.columns.map((column) => {
                        if (column.columnName === 'Business Owner Name') {
                            return { ...column, columnName: ColumnNames.businessOwnerName, visible: true };
                        }
                        if (column.columnName === 'Third Party Contact Email') {
                            return { ...column, columnName: ColumnNames.primaryContactEmail, visible: false };
                        }
                        if (column.columnName === 'Created By') {
                            return { ...column, columnName: ColumnNames.createdBy, visible: true };
                        }
                        return column;
                    });
                    return { ...columnGroup, columns: updatedColumns };
                }
                return columnGroup;
            }),
        };
    },
    14: (state): PersistedState<RelationshipsState> => {
        return {
            ...state,
            columnsGroups: state.columnsGroups.map((columnGroup) => {
                if (columnGroup.columnGroupName === 'Assessment Details') {
                    const updatedColumns = [...columnGroup.columns];
                    updatedColumns.push({ columnName: ColumnNames.recommendationCount, visible: true });
                    return { ...columnGroup, columns: updatedColumns };
                }
                return columnGroup;
            }),
        };
    },
    15: (state): PersistedState<RelationshipsState> => {
        return {
            ...state,
            columnsGroups: state.columnsGroups.map((columnGroup) => {
                if (columnGroup.columnGroupName === 'Vendor Review') {
                    const updatedColumns = [...columnGroup.columns];
                    updatedColumns.push({ columnName: ColumnNames.remediationRequested, visible: true });
                    return { ...columnGroup, columns: updatedColumns };
                }
                return columnGroup;
            }),
        };
    },
    16: (state): PersistedState<RelationshipsState> => {
        return {
            ...state,
            columnsGroups: state.columnsGroups.map((columnGroup) => {
                if (columnGroup.columnGroupName === 'Metadata') {
                    const updatedColumns = columnGroup.columns.map((column) => {
                        if (column.columnName === 'Artifact Updates') {
                            return { ...column, columnName: ColumnNames.artifactUpdateType, visible: true };
                        }
                        return column;
                    });
                    return { ...columnGroup, columns: updatedColumns };
                }
                return columnGroup;
            }),
        };
    },
};
