import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { VendorRelationship } from './models/relationship.model';
import { createRequestOption } from '../../shared';
import { map } from 'rxjs/operators';
import { DateUtilsService } from '../../shared/utils/date-utils.service';
import { RiskNetworkGraphNode } from './models/risk-network-graph-node';

@Injectable()
export class VendorRelationshipsService {
    private _relationshipsUrl = 'api/search-relationships';
    private _riskNetworkGraphUrl = 'api/risk-network/graph/search-relationships';

    constructor(
        private _http: HttpClient,
        private _dateUtilsService: DateUtilsService,
    ) {}

    query(req?: any): Observable<HttpResponse<VendorRelationship[]>> {
        const options = createRequestOption(req);
        return this._http
            .get<any[]>(this._relationshipsUrl, { params: options, observe: 'response' })
            .pipe(map((res: HttpResponse<VendorRelationship[]>) => this.convertArrayResponse(res)));
    }

    queryRiskNetworkGraph(search?: string, networkExposureOrgId?: number): Observable<RiskNetworkGraphNode[]> {
        const options = createRequestOption({ search, networkExposureOrgId });
        return this._http.get<RiskNetworkGraphNode[]>(this._riskNetworkGraphUrl, { params: options });
    }

    private convertArrayResponse(res: HttpResponse<VendorRelationship[]>): HttpResponse<VendorRelationship[]> {
        const jsonResponse: VendorRelationship[] = res.body;
        const body: VendorRelationship[] = [];
        for (let i = 0; i < jsonResponse.length; i++) {
            body.push(this.convertItemFromServer(jsonResponse[i]));
        }
        return res.clone({ body });
    }

    private convertItemFromServer(relationship: VendorRelationship): VendorRelationship {
        const copy: VendorRelationship = { ...relationship };
        copy.assessmentCreatedDate = this._dateUtilsService.convertDateTimeFromServer(
            relationship.assessmentCreatedDate,
        );
        copy.assessmentCompletedDate = this._dateUtilsService.convertDateTimeFromServer(
            relationship.assessmentCompletedDate,
        );
        copy.recertificationDate = this._dateUtilsService.convertDateTimeFromServer(relationship.recertificationDate);
        copy.createdDate = this._dateUtilsService.convertDateTimeFromServer(relationship.createdDate);
        copy.updatedDate = this._dateUtilsService.convertDateTimeFromServer(relationship.updatedDate);
        return copy;
    }
}
