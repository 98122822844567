import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { IntegrationType, IntegrationTypeLabels } from '../integrations.model';

export interface IntegrationDefinition {
    integrationType: IntegrationType;
    faviconSrc: string;
    comingSoon: boolean;
    description: string;
    connectedTemplate?: TemplateRef<any>;
    disconnectedTemplate?: TemplateRef<any>;
}

@Component({
    selector: 'app-integration-tile',
    templateUrl: './integration-tile.component.html',
    styleUrls: ['./integration-tile.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IntegrationTileComponent {
    @Input({ required: true })
    integrationDefinition: IntegrationDefinition;

    @Input({ required: true })
    connectedIntegrations: IntegrationType[];

    @Input({ required: true })
    interestedIntegrations: IntegrationType[];

    @Output()
    keepMeUpdated = new EventEmitter<IntegrationType>();

    readonly IntegrationTypeLabels = IntegrationTypeLabels;

    get isConnectedToIntegration() {
        return this.connectedIntegrations.includes(this.integrationDefinition.integrationType);
    }

    get isInterestedInIntegration() {
        return this.interestedIntegrations.includes(this.integrationDefinition.integrationType);
    }
}
