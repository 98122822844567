import { Routes } from '@angular/router';

import { AuthGuard } from '../../shared';
import { ContactManagementComponent } from './contact-management.component';
import { VisoUserRole } from '../../entities/viso-user';

export const contactMgmtRoutes: Routes = [
    {
        path: 'users',
        component: ContactManagementComponent,
        data: {
            authorities: [VisoUserRole.OrgAdmin, VisoUserRole.OrgUser, VisoUserRole.ReadOnly],
            pageTitle: 'Contact Management',
        },
        canActivate: [AuthGuard],
    },
];
