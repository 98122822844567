import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DomainVerification } from '@entities/domain-verification';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { noWhitespaceValidator } from '@shared/validators/whitespace-validator';
import { MatDialogRef } from '@angular/material/dialog';
import { FormUtilsService } from '@shared/utils/form-utils.service';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { SnackbarService } from '@shared/components/snackbar/snackbar.service';
import {
    setPreferredEmailDomainRequest,
    setPreferredEmailDomainRequestSuccess,
} from '../redux/domain-verification.actions';
import { takeUntil, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
    templateUrl: './customize-email-dialog.component.html',
    styleUrls: ['./customize-email-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomizeEmailDialogComponent implements OnInit, OnDestroy {
    @Input({ required: true })
    preference: DomainVerification;

    @Input({ required: true })
    verifications: DomainVerification[];

    formGroup: FormGroup;

    private _unsub$ = new Subject<void>();

    constructor(
        private _snackbarService: SnackbarService,
        private _store$: Store,
        private _actions$: Actions,
        private _formBuilder: FormBuilder,
        private _formUtils: FormUtilsService,
        private _dialogRef: MatDialogRef<CustomizeEmailDialogComponent, null>,
    ) {}

    ngOnInit(): void {
        this.formGroup = this._formBuilder.group({
            domain: [this.preference?.domain || this.verifications[0].domain, [Validators.required]],
            emailLocalpart: [this.preference?.emailLocalpart, [Validators.required, noWhitespaceValidator]],
            emailDisplay: [this.preference?.emailDisplay, [Validators.required, noWhitespaceValidator]],
        });

        this._actions$
            .pipe(
                ofType(setPreferredEmailDomainRequestSuccess),
                tap(() => {
                    this._snackbarService.success('Email settings updated.');
                    this._dialogRef.close(null);
                }),
                takeUntil(this._unsub$),
            )
            .subscribe();
    }

    save() {
        this._store$.dispatch(
            setPreferredEmailDomainRequest({
                config: this._formUtils.getCleanTypedFormGroupValue(this.formGroup),
            }),
        );
    }

    ngOnDestroy(): void {
        this._unsub$.next();
    }
}
