import { HttpHeaders } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { BulkStatusUpdateResponse } from '@entities/relationship/models/bulk-status-update-response';
import { Org } from '@entities/org';
import { VisoUser } from '@entities/viso-user';
import { RelationshipAdditionalFilters } from '../models/relationship-additional-filters.model';
import { RelationshipColumnGroup } from '../models/relationship-column.model';
import { RelationshipQueryRequest } from '../models/relationship-query-request.model';
import { VendorRelationship } from '../models/relationship.model';
import { RiskNetworkGraphNode } from '../models/risk-network-graph-node';
import { CompleteVendorSearchResult } from '@shared/vendor-components/models/vendor-search-result';

export enum RelationshipsActions {
    RelationshipsQueryRequest = '[Relationships] Relationships Query Request',
    RelationshipsQuerySuccess = '[Relationships] Relationships Query Success',
    RelationshipsQueryFailed = '[Relationships] Relationships Query Failed',
    ConvertToCsvRequest = '[Relationships] Convert to Csv Request',
    ConvertToCsvSuccess = '[Relationships] Convert to Csv Success',
    SetColumnsState = '[Relationships] Set Columns State',
    SetAdditionalFiltersState = '[Relationships] Set Filters State',
    ClearAllFilters = '[Relationships] Clear All Filters',

    SendAssessmentsForRelationshipsRequest = '[Relationships] Send Assessments For Relationships Request',
    SendAssessmentsForRelationshipsRequestSuccess = '[Relationships] Send Assessments For Relationships Request Success',
    SendAssessmentsForRelationshipsRequestFailed = '[Relationships] Send Assessments For Relationships Request Failed',

    CancelAssessmentsForRelationshipsRequest = '[Relationships] Cancel Assessments For Relationships Request',
    CancelAssessmentsForRelationshipsRequestSuccess = '[Relationships] Cancel Assessments For Relationships Request Success',
    CancelAssessmentsForRelationshipsRequestFailed = '[Relationships] Cancel Assessments For Relationships Request Failed',

    DisableArtifactUpdatesForRelationshipsRequest = '[Relationships] Disable Artifact Updates For Relationships Request',
    DisableArtifactUpdatesForRelationshipsRequestSuccess = '[Relationships] Disable Artifact Updates For Relationships Request Success',
    DisableArtifactUpdatesForRelationshipsRequestFailed = '[Relationships] Disable Artifact Updates For Relationships Request Failed',

    EnableArtifactUpdatesForRelationshipsRequest = '[Relationships] Enable Artifact Updates For Relationships Request',
    EnableArtifactUpdatesForRelationshipsRequestSuccess = '[Relationships] Enable Artifact Updates For Relationships Request Success',
    EnableArtifactUpdatesForRelationshipsRequestFailed = '[Relationships] Enable Artifact Updates For Relationships Request Failed',

    GetRiskNetworkGraphRequest = '[Relationships] Get Risk Network Graph Request',
    GetRiskNetworkGraphRequestSuccess = '[Relationships] Get Risk Network Graph Request Success',
    GetRiskNetworkGraphRequestFailed = '[Relationships] Get Risk Network Graph Request Failed',

    //TODO: should go in a different module
    VisoUsersQueryRequest = '[Relationships] Viso Users Query Request',
    VisoUsersQuerySuccess = '[Relationships] Viso Users Query Success',
    OrgClientsQueryRequest = '[Relationships] Org Clients Query Request',
    OrgClientsQuerySuccess = '[Relationships] Org Clients Query Success',

    GetVendorByIdRequest = '[Relationships] Get Vendor By Id',
    GetVendorByIdRequestSuccess = '[Relationships] Get Vendor By Id Success',
    GetVendorByIdRequestFailed = '[Relationships] Get Vendor By Id Failed',
}

/**
 * Relationships Query Request Redux Action
 * Gets dispatched when a relationships query request is triggered.
 */
export const relationshipsQueryRequest = createAction(
    RelationshipsActions.RelationshipsQueryRequest,
    props<{ params: RelationshipQueryRequest }>(),
);

/**
 * Relationships Query Success Redux Action
 * Gets dispatched when a relationshipsQueryRequest action is successful.
 */
export const relationshipsQuerySuccess = createAction(
    RelationshipsActions.RelationshipsQuerySuccess,
    props<{ results: VendorRelationship[]; headers: HttpHeaders }>(),
);

/**
 * Relationships Query Failed Redux Action
 * Gets dispatched when a relationshipsQueryRequest action fails.
 */
export const relationshipsQueryFailed = createAction(RelationshipsActions.RelationshipsQueryFailed);

/**
 * Convert to Csv Request Redux Action
 * Gets dispatched when a convert to csv request is triggered.
 */
export const convertToCsvRequest = createAction(
    RelationshipsActions.ConvertToCsvRequest,
    props<{ params: RelationshipQueryRequest }>(),
);

/**
 * Convert to Csv Success Redux Action
 * Gets dispatched when a convertToCsvRequest action is successful.
 */
export const convertToCsvSuccess = createAction(
    RelationshipsActions.ConvertToCsvSuccess,
    props<{ results: VendorRelationship[] }>(),
);

/**
 * Set Filters State Redux Action
 * Gets dispatched when filters state change is triggered.
 */
export const setAdditionalFiltersState = createAction(
    RelationshipsActions.SetAdditionalFiltersState,
    props<{ filters: RelationshipAdditionalFilters }>(),
);

/**
 * Set Columns State Redux Action
 * Gets dispatched when a columns state change is triggered.
 */
export const setColumnsState = createAction(
    RelationshipsActions.SetColumnsState,
    props<{ results: RelationshipColumnGroup[] }>(),
);

/**
 * Clear All Filters Redux Action
 * Gets dispatched when clear all filters request is triggered inside relationships view.
 */
export const clearAllFilters = createAction(RelationshipsActions.ClearAllFilters);

/**
 * Viso Users Query Request Redux Action
 * Gets dispatched when viso users query request is triggered.
 */
export const visoUsersQueryRequest = createAction(RelationshipsActions.VisoUsersQueryRequest);

/**
 * Viso Users Query Success Redux Action
 * Gets dispatched when a `VisoUsersQueryRequest` is successful.
 */
export const visoUsersQuerySuccess = createAction(
    RelationshipsActions.VisoUsersQuerySuccess,
    props<{ results: VisoUser[] }>(),
);

/**
 * Org Clients Query Request Redux Action
 * Gets dispatched when org clients query request is triggered
 */
export const orgClientsQueryRequest = createAction(RelationshipsActions.OrgClientsQueryRequest);

/**
 * Org Clients Query Success Redux Action
 * Gets dispatched when a `OrgClientsQueryRequest` is successful.
 */
export const orgClientsQuerySuccess = createAction(
    RelationshipsActions.OrgClientsQuerySuccess,
    props<{ results: Org[] }>(),
);

export const sendAssessmentsForRelationshipsRequest = createAction(
    RelationshipsActions.SendAssessmentsForRelationshipsRequest,
    props<{ ids: number[]; assessmentsInProgress: boolean }>(),
);

export const sendAssessmentsForRelationshipsRequestSuccess = createAction(
    RelationshipsActions.SendAssessmentsForRelationshipsRequestSuccess,
    props<{ ids: number[]; response: BulkStatusUpdateResponse }>(),
);

export const sendAssessmentsForRelationshipsRequestFailed = createAction(
    RelationshipsActions.SendAssessmentsForRelationshipsRequestFailed,
);

export const cancelAssessmentsForRelationshipsRequest = createAction(
    RelationshipsActions.CancelAssessmentsForRelationshipsRequest,
    props<{ ids: number[]; showConfirmationModal: boolean }>(),
);

export const cancelAssessmentsForRelationshipsRequestSuccess = createAction(
    RelationshipsActions.CancelAssessmentsForRelationshipsRequestSuccess,
    props<{ ids: number[]; response: BulkStatusUpdateResponse }>(),
);

export const cancelAssessmentsForRelationshipsRequestFailed = createAction(
    RelationshipsActions.CancelAssessmentsForRelationshipsRequestFailed,
);

export const disableArtifactUpdatesForRelationshipsRequest = createAction(
    RelationshipsActions.DisableArtifactUpdatesForRelationshipsRequest,
    props<{ ids: number[]; showConfirmationModal: boolean }>(),
);

export const disableArtifactUpdatesForRelationshipsRequestSuccess = createAction(
    RelationshipsActions.DisableArtifactUpdatesForRelationshipsRequestSuccess,
    props<{ ids: number[]; response: BulkStatusUpdateResponse }>(),
);

export const disableArtifactUpdatesForRelationshipsRequestFailed = createAction(
    RelationshipsActions.DisableArtifactUpdatesForRelationshipsRequestFailed,
);

export const enableArtifactUpdatesForRelationshipsRequest = createAction(
    RelationshipsActions.EnableArtifactUpdatesForRelationshipsRequest,
    props<{ ids: number[]; showConfirmationModal: boolean }>(),
);

export const enableArtifactUpdatesForRelationshipsRequestSuccess = createAction(
    RelationshipsActions.EnableArtifactUpdatesForRelationshipsRequestSuccess,
    props<{ ids: number[]; response: BulkStatusUpdateResponse }>(),
);

export const enableArtifactUpdatesForRelationshipsRequestFailed = createAction(
    RelationshipsActions.EnableArtifactUpdatesForRelationshipsRequestFailed,
);

export const getRiskNetworkGraphRequest = createAction(
    RelationshipsActions.GetRiskNetworkGraphRequest,
    props<{ searchString?: string; networkExposureOrgId?: number }>(),
);

export const getRiskNetworkGraphRequestSuccess = createAction(
    RelationshipsActions.GetRiskNetworkGraphRequestSuccess,
    props<{ riskNetworkGraphNodes: RiskNetworkGraphNode[] }>(),
);

export const getRiskNetworkGraphRequestFailed = createAction(RelationshipsActions.GetRiskNetworkGraphRequestFailed);

export const getVendorByIdRequest = createAction(
    RelationshipsActions.GetVendorByIdRequest,
    props<{ vendorId: number }>(),
);

export const getVendorByIdRequestSuccess = createAction(
    RelationshipsActions.GetVendorByIdRequestSuccess,
    props<{ vendor: CompleteVendorSearchResult }>(),
);

export const getVendorByIdRequestFailed = createAction(RelationshipsActions.GetVendorByIdRequestFailed);
