import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { OrgAssessmentSummarySections } from '../../../../admin/client-profile/client-profile.model';
import { Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import {
    generateExampleAssessmentSummaryRequest,
    generateExampleAssessmentSummaryRequestSuccess,
} from '../../../your-organization/assessment-summary-management/redux/assessment-summary-management.actions';
import { getProfileAssessmentSummarySections, getUserAuthority } from '../../../session/redux/session.selectors';
import { VisoUserRole } from '../../../../entities/viso-user';
import { takeUntil, withLatestFrom } from 'rxjs/operators';
import { AssessmentSummarySection } from '../../../../shared/assessment-components/components/assessment-summary-config/model/assessment-summary-config-models';
import {
    deleteRelationshipAssessmentSummaryConfigRequest,
    deleteRelationshipAssessmentSummaryConfigRequestFailed,
    deleteRelationshipAssessmentSummaryConfigRequestSuccess,
    getRelationshipAssessmentSummaryConfigRequest,
    getRelationshipAssessmentSummaryConfigRequestSuccess,
    updateRelationshipAssessmentSummaryConfigRequest,
    updateRelationshipAssessmentSummaryConfigRequestFailed,
    updateRelationshipAssessmentSummaryConfigRequestSuccess,
} from '../../redux/actions/assessment-summary-config.actions';
import { MatDialogRef } from '@angular/material/dialog';
import { getRelationshipAssessmentSummaryConfig } from '../../redux/request.selectors';

@Component({
    selector: 'app-assessment-summary-config-dialog',
    templateUrl: './assessment-summary-config-dialog.component.html',
    styleUrls: ['./assessment-summary-config-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssessmentSummaryConfigDialogComponent implements OnInit, OnDestroy {
    @Input({ required: true })
    relationshipId: number;

    loadingSummary: boolean = true;
    savingConfig = false;

    isCurrentUserOrgAdmin$: Observable<boolean>;
    exampleSummary$ = new BehaviorSubject<string>(null);
    assessmentSummaryConfig$ = new BehaviorSubject<OrgAssessmentSummarySections[]>([]);

    isUsingOrgProfileAssessmentConfig: boolean = true;

    summaryConfigPayload: Record<AssessmentSummarySection, boolean>;

    private _unsub$ = new Subject<void>();

    constructor(
        private _actions$: Actions,
        private _store$: Store,
        private _cdr: ChangeDetectorRef,
        public _dialogRef: MatDialogRef<AssessmentSummaryConfigDialogComponent>,
    ) {}

    ngOnInit(): void {
        combineLatest([
            this._store$.pipe(select(getProfileAssessmentSummarySections)),
            this._store$.pipe(select(getRelationshipAssessmentSummaryConfig)),
            this._actions$.pipe(ofType(generateExampleAssessmentSummaryRequestSuccess)),
        ])
            .pipe(takeUntil(this._unsub$))
            .subscribe(([clientProfileSummaryConfig, assessmentSummarySections, { summary }]) => {
                this.exampleSummary$.next(summary);

                let summaryConfig: OrgAssessmentSummarySections[];
                if (assessmentSummarySections?.length > 0) {
                    this.isUsingOrgProfileAssessmentConfig = false;
                    summaryConfig = assessmentSummarySections;
                } else {
                    summaryConfig = clientProfileSummaryConfig;
                    this.isUsingOrgProfileAssessmentConfig = true;
                }

                this.assessmentSummaryConfig$.next(summaryConfig);
                this.loadingSummary = false;
                this._cdr.detectChanges();
            });

        this._actions$
            .pipe(ofType(updateRelationshipAssessmentSummaryConfigRequestSuccess), takeUntil(this._unsub$))
            .subscribe(() => {
                this._dialogRef.close();
            });

        this._actions$
            .pipe(
                ofType(
                    deleteRelationshipAssessmentSummaryConfigRequestSuccess,
                    deleteRelationshipAssessmentSummaryConfigRequestFailed,
                    updateRelationshipAssessmentSummaryConfigRequestSuccess,
                    updateRelationshipAssessmentSummaryConfigRequestFailed,
                ),
                takeUntil(this._unsub$),
            )
            .subscribe(() => {
                this.savingConfig = false;
                this.summaryConfigPayload = null;
                this._store$.dispatch(
                    getRelationshipAssessmentSummaryConfigRequest({ relationshipId: this.relationshipId }),
                );
            });

        this._actions$
            .pipe(
                ofType(getRelationshipAssessmentSummaryConfigRequestSuccess),
                withLatestFrom(this._store$.pipe(select(getProfileAssessmentSummarySections))),
                takeUntil(this._unsub$),
            )
            .subscribe(([{ assessmentSummarySections }, clientProfileSummaryConfig]) => {
                let summaryConfig: OrgAssessmentSummarySections[];
                if (assessmentSummarySections?.length > 0) {
                    this.isUsingOrgProfileAssessmentConfig = false;
                    summaryConfig = assessmentSummarySections;
                } else {
                    summaryConfig = clientProfileSummaryConfig;
                    this.isUsingOrgProfileAssessmentConfig = true;
                }

                this.assessmentSummaryConfig$.next(summaryConfig);
            });

        this.loadAssessmentSummaryConfig();
    }

    assessmentSummaryConfigChanged(payload: Record<AssessmentSummarySection, boolean>) {
        this.summaryConfigPayload = payload;
    }

    updateRelationshipAssessmentSummaryConfig() {
        this.savingConfig = true;
        this._store$.dispatch(
            updateRelationshipAssessmentSummaryConfigRequest({
                relationshipId: this.relationshipId,
                updateAssessmentSummarySectionConfigRequest: {
                    assessmentSummarySectionConfig: this.summaryConfigPayload,
                },
            }),
        );
    }

    deleteRelationshipAssessmentSummaryConfig() {
        this._store$.dispatch(
            deleteRelationshipAssessmentSummaryConfigRequest({
                relationshipId: this.relationshipId,
            }),
        );
    }

    private loadAssessmentSummaryConfig() {
        this.loadingSummary = true;
        this._store$.dispatch(getRelationshipAssessmentSummaryConfigRequest({ relationshipId: this.relationshipId }));
        this._store$.dispatch(generateExampleAssessmentSummaryRequest());

        this.isCurrentUserOrgAdmin$ = this._store$.pipe(
            select(getUserAuthority(VisoUserRole.OrgAdmin)),
            takeUntil(this._unsub$),
        );
    }

    ngOnDestroy(): void {
        this._unsub$.next();
    }
}
