<div class="container-top-half pb-0">
    <div class="row">
        <div class="col-xl-4 col fixed-height">
            <div class="row">
                <div class="col">
                    <h1 class="assessment-collection-step-title">
                        {{ clientName }} has a few additional questions they’d like to ask.
                    </h1>
                </div>
            </div>
            <div class="row mb-4">
                <div class="col">
                    <p>
                        These questions, which are specific to {{ clientName }}, will be reviewed alongside your
                        artifacts to determine risk in the partnership.
                    </p>
                    <p>You may safely close the page when finished.</p>
                </div>
            </div>
        </div>

        <div class="col-auto ps-0 pb-4">
            <mat-divider class="h-100" [vertical]="true"></mat-divider>
        </div>

        <div class="col pb-0">
            <div class="row fixed-height">
                <ng-content></ng-content>
            </div>
        </div>
    </div>
</div>
<div class="container-bottom-half no-divider"></div>
