<h2 mat-dialog-title>Onboard {{ vendorName }}</h2>
<mat-dialog-content>
    <form [formGroup]="onboardingFormGroup">
        <app-lifecycle-management-form
            [businessOwner]="relationship.businessOwner"
            [primaryContact]="relationship.primaryContact"
            [licenseHasManagedVendorsAvailable]="clientLicenseHasAvailableManagedVendors"
            [lifecycleManagementFormGroup]="onboardingFormGroup.controls.lifecycleManagementFormGroup"
            [dateOfNextArtifactExpiration]="dateOfNextArtifactExpiration"
            [latestCompletedAssessmentDate]="latestCompletedAssessmentDate"
            [relationshipHasPrimaryContact]="!!relationship.primaryContact"
        />
        <mat-form-field class="w-100 mt-5">
            <mat-label>Onboarding approval message (optional)</mat-label>
            <textarea
                [formControl]="onboardingFormGroup.controls.approvalSummary"
                matInput
                id="approvalSummary"
                name="approvalSummary"
                rows="3"
            ></textarea>
        </mat-form-field>
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button visoButton="secondary" type="button" (click)="cancel()">Cancel</button>
    <button mat-button visoButton="primary" type="button" (click)="confirm()">Onboard</button>
</mat-dialog-actions>
