import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { UserRegistrationRequest } from '../../../entities/user-registration/user-registration.model';

export enum UserAccountActions {
    SignUpRequest = '[UserAccount] Sign Up Request',
    SignUpRequestSuccess = '[UserAccount] Sign Up Request Success',
    SignUpRequestFailed = '[UserAccount] Sign Up Request Failed',
    ActivateAccountRequest = '[UserAccount] Activate Account Request',
    ActivateAccountRequestSuccess = '[UserAccount] Activate Account Request Success',
    ActivateAccountRequestFailed = '[UserAccount] Activate Account Request Failed',
}

export const signUpRequest = createAction(
    UserAccountActions.SignUpRequest,
    props<{ request: UserRegistrationRequest }>(),
);

export const signUpRequestSuccess = createAction(UserAccountActions.SignUpRequestSuccess);

export const signUpRequestFailed = createAction(
    UserAccountActions.SignUpRequestFailed,
    props<{ error: HttpErrorResponse }>(),
);

export const activateAccountRequest = createAction(
    UserAccountActions.ActivateAccountRequest,
    props<{ activationToken: string }>(),
);

export const activateAccountRequestSuccess = createAction(UserAccountActions.ActivateAccountRequestSuccess);

export const activateAccountRequestFailed = createAction(
    UserAccountActions.ActivateAccountRequestFailed,
    props<{ error: HttpErrorResponse }>(),
);
