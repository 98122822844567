import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { assessmentEmailViewRoute } from '../../../routes/assessment-email-viewer/assessment-email-viewer.constants';
import { Router } from '@angular/router';
import { tap } from 'rxjs';
import { openAssessmentEmailView } from '../../../routes/assessment-email-viewer/redux/assessment-email-viewer.actions';

@Injectable()
export class GlobalEmailViewerEffects {
    openAssessmentEmailView$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(openAssessmentEmailView),
                tap(({ assessmentId, messageId }) =>
                    this._router.navigate([
                        '/',
                        {
                            outlets: {
                                popup: assessmentEmailViewRoute
                                    .replace(`:assessmentId`, assessmentId.toString())
                                    .replace(`:messageId`, messageId.toString()),
                            },
                        },
                    ]),
                ),
            ),
        { dispatch: false },
    );

    constructor(
        private _actions$: Actions,
        private _router: Router,
    ) {}
}
