import { ChangeDetectionStrategy, Component, Input, Optional } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Announcement, AnnouncementTypesLabels } from '../../../entities/announcement/announcement.model';

@Component({
    selector: 'app-announcement-modal',
    templateUrl: './announcement-modal.component.html',
    styleUrls: ['./announcement-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnnouncementModalComponent {
    @Input()
    announcement: Announcement;

    @Input()
    previewMode: boolean = false;

    constructor(@Optional() private _activeModal: NgbActiveModal) {}

    get announcementType(): string {
        return AnnouncementTypesLabels[this.announcement?.announcementType];
    }

    get title(): string {
        return this.announcement?.title;
    }

    get message(): string {
        return this.announcement?.message;
    }

    get images(): string[] {
        return this.announcement?.announcementImages
            ?.sort((a, b) => a.imageOrder - b.imageOrder)
            .map((image) => image.url);
    }

    get externalLinkLabel(): string {
        return this.announcement?.externalLinkLabel;
    }

    get externalLinkUrl(): string {
        return this.announcement?.externalLinkUrl;
    }

    get hasManyImages(): boolean {
        return this.images?.length > 1;
    }

    dismiss(): void {
        if (this.previewMode) {
            return;
        }

        this._activeModal.close();
    }
}
