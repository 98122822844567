<div class="content-heading">
    <div>
        Announcement
        <small>Manage announcements for Users</small>
    </div>
</div>
<div class="row">
    <div class="col-md-8">
        <div class="card card-default">
            <div class="card-header">
                <p class="card-title">Announcements List</p>
            </div>
            <div class="card-body">
                <div class="row">
                    <div *ngFor="let announcement of announcements$ | async" class="col-4">
                        <div class="d-flex align-items-center justify-content-between">
                            <strong>Created on: {{ announcement.createdDate | date }}</strong>
                            <button
                                type="button"
                                mat-button
                                visoButton="primary"
                                (click)="deleteAnnouncement(announcement)"
                            >
                                Remove
                            </button>
                        </div>
                        <div class="modal-dialog mt-3">
                            <div class="modal-content">
                                <app-announcement-modal
                                    [announcement]="announcement"
                                    [previewMode]="true"
                                ></app-announcement-modal>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-4">
        <div
            class="card card-default dropzone"
            ng2FileDrop
            [uploader]="uploader"
            [ngClass]="{ 'on-drag': onDrag$ | async }"
        >
            <div
                class="on-drag-area border border-primary text-primary rounded justify-content-center align-items-center bg-light"
            >
                <p>Drop files here.</p>
            </div>
            <div class="card-header">
                <p class="card-title">Create new announcement</p>
            </div>
            <div class="card-body">
                <form [formGroup]="addAnnouncementFormGroup" (submit)="addAnnouncement()">
                    <div class="form-group required">
                        <label>Announcement Type</label>
                        <ngx-select
                            [allowClear]="true"
                            [items]="announcementTypes$ | async"
                            [autoSelectSingleOption]="true"
                            formControlName="announcementType"
                            placeholder="Select an Announcement Type"
                            optionTextField="name"
                            optionValueField="enumValue"
                        ></ngx-select>
                    </div>
                    <div class="form-group required">
                        <label>Title</label>
                        <input id="title" type="text" class="form-control" formControlName="title" />
                    </div>
                    <div class="form-group required">
                        <label>Message</label>
                        <quill-editor formControlName="message" [modules]="modules" [sanitize]="true"> </quill-editor>
                    </div>
                    <div class="form-group">
                        <label for="customDate">Images</label>
                        <div>
                            <button
                                type="button"
                                mat-button
                                visoButton="secondary-alt"
                                class="full-width p-3 mb-2 drag-drop-message-container"
                                (click)="fileInput.click()"
                            >
                                <span class="text-dark">
                                    Drag and Drop or <span class="text-bold text-secondary">Browse</span>
                                </span>
                            </button>
                            <input
                                #fileInput
                                type="file"
                                ng2FileSelect
                                multiple
                                class="d-none"
                                [uploader]="uploader"
                                data-pw="requestFileDialogInputFile"
                            />
                        </div>
                        <ng-container *ngIf="imagesPreviews$ | async as imagesPreview">
                            <ngb-carousel *ngIf="!!imagesPreview.length">
                                <ng-template ngbSlide *ngFor="let imagePreview of imagesPreview; let index = index">
                                    <img
                                        [src]="imagePreview | safeHtml: 'resourceUrl'"
                                        style="display: block; width: 100%"
                                    />
                                    <button
                                        type="button"
                                        mat-button
                                        visoButton="primary"
                                        class="remove"
                                        (click)="removeImage(index)"
                                    >
                                        Remove
                                    </button>
                                </ng-template>
                            </ngb-carousel>
                        </ng-container>
                    </div>
                    <div class="form-group">
                        <label>External Link Label</label>
                        <input
                            id="externalLinkLabel"
                            type="text"
                            class="form-control"
                            formControlName="externalLinkLabel"
                        />
                    </div>
                    <div class="form-group">
                        <label>External Link URL</label>
                        <input
                            id="externalLinkUrl"
                            type="text"
                            class="form-control"
                            formControlName="externalLinkUrl"
                        />
                    </div>
                    <div class="form-group text-end">
                        <button mat-button visoButton="secondary" type="button" class="me-2" (click)="clearForm()">
                            Clear
                        </button>
                        <button
                            mat-button
                            visoButton="primary"
                            type="submit"
                            [disabled]="addAnnouncementFormGroup.invalid"
                        >
                            Submit
                        </button>
                    </div>
                </form>
            </div>
        </div>
        <div *ngIf="announcementPreview$ | async as announcementPreview" class="modal-dialog mt-3">
            <div class="modal-content">
                <app-announcement-modal
                    [announcement]="announcementPreview"
                    [previewMode]="true"
                ></app-announcement-modal>
            </div>
        </div>
    </div>
</div>
