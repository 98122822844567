import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { RiskUtilsService } from '../../../shared/utils/risk-utils.service';
import { NgbModalWrapperService } from '../../../shared';
import { noWhitespaceValidator } from '../../../shared/validators/whitespace-validator';
import { OrgDataSensitivityConfig } from '../client-profile.model';
import { ClientProfileService } from '../client-profile.service';
import { DataSensitivityLevel } from './data-sensitivity-level.model';

@Component({
    selector: 'app-data-sensitivity-levels-config',
    templateUrl: './data-sensitivity-levels-config.component.html',
    styleUrls: ['./data-sensitivity-levels-config.component.scss'],
})
export class DataSensitivityLevelsConfigComponent implements OnInit {
    @Input() dataSensitivityLevels: OrgDataSensitivityConfig[];
    @Input() clientProfileId: string;

    @Output() refreshClientProfile = new EventEmitter<void>();

    manageDataSensitivityLevelConfigModal: NgbModalRef;
    currentDataSensitivityLevel: OrgDataSensitivityConfig;
    previousDataSensitivityLevelDisplayName: string;
    savingDataSensitivityLevel: boolean = false;
    dataSensitivityLevelsOrder: string[];

    editDataSensitivityLevelForm: UntypedFormGroup;

    constructor(
        private _modalService: NgbModalWrapperService,
        private _clientProfileService: ClientProfileService,
        private _riskUtilsService: RiskUtilsService,
        private _fb: UntypedFormBuilder,
    ) {}

    ngOnInit(): void {
        this.editDataSensitivityLevelForm = this._fb.group({
            displayName: [
                this.currentDataSensitivityLevel?.displayName || '',
                [Validators.required, noWhitespaceValidator],
            ],
        });

        this.dataSensitivityLevelsOrder = Object.keys(DataSensitivityLevel).filter((f) => isNaN(+f));

        this.dataSensitivityLevels.sort((itemA, itemB) => {
            let indexA = this.dataSensitivityLevelsOrder.indexOf(itemA.dataSensitivityLevel.toString());
            let indexB = this.dataSensitivityLevelsOrder.indexOf(itemB.dataSensitivityLevel.toString());
            if (indexA < indexB) return 1;
            else if (indexA > indexB) return -1;
            return 0;
        });
    }

    openEditDataSensitivityLevelConfigModal(
        manageDataSensitivityLevelConfigModal: any,
        dataSensitivityConfig: OrgDataSensitivityConfig,
    ): void {
        this.currentDataSensitivityLevel = dataSensitivityConfig;
        this.editDataSensitivityLevelForm.patchValue({ displayName: this.currentDataSensitivityLevel.displayName });
        this.previousDataSensitivityLevelDisplayName = this.currentDataSensitivityLevel.displayName;

        this.manageDataSensitivityLevelConfigModal = this._modalService.open(
            manageDataSensitivityLevelConfigModal,
            {},
            {
                centered: true,
                backdrop: 'static',
            },
        );
    }

    closeEditDataSensitivityLevelConfigModal(): void {
        this.manageDataSensitivityLevelConfigModal.close();
        this.currentDataSensitivityLevel = null;
    }

    cancel(): void {
        this.manageDataSensitivityLevelConfigModal.close();
        this.editDataSensitivityLevelForm.patchValue({ displayName: this.previousDataSensitivityLevelDisplayName });
        this.previousDataSensitivityLevelDisplayName = null;
    }

    saveDataSensitivityLevel({ resetToDefault }): void {
        if (resetToDefault) {
            const formattedDataSensitivityLevel = this._riskUtilsService.formatRiskLevelsName(
                this.currentDataSensitivityLevel.dataSensitivityLevel.toString().replace('_', ' '),
            );

            this.editDataSensitivityLevelForm.patchValue({ displayName: formattedDataSensitivityLevel });
        }
        this.savingDataSensitivityLevel = true;

        this._clientProfileService
            .updateDataSensitivityConfig(
                this.clientProfileId,
                this.currentDataSensitivityLevel.dataSensitivityLevel,
                this.editDataSensitivityLevelForm.value.displayName,
            )
            .pipe(
                tap(() => this.closeEditDataSensitivityLevelConfigModal()),
                catchError(() => of(null)),
                tap(() => (this.savingDataSensitivityLevel = false)),
                tap(() => this.refreshClientProfile.emit()),
            )
            .subscribe();
    }
}
