import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpContext, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ImportJobView, ImportReport } from './import-job.model';
import { CSRFService } from '../../shared';
import { BYPASS_SNACKBAR_ON_ERROR } from '../../blocks/interceptor/errorhandler.interceptor';

export type EntityResponseType = HttpResponse<ImportReport>;

export const ImportErrorType = {
    IO_ERROR: 'Unable to read file',
    HEADER_MATCH: "Bad File Format: Header row doesn't match",
    UNKNOWN_COLUMNS: 'Bad File Format: Unknown columns',
    MISSING_COLUMNS: 'Bad File Format: Missing columns',
    UNKNOWN_CONTEXT: 'Unknown context types',
    UNKNOWN_DATA_TYPE: 'Unknown Data Types',
    UNKNOWN_USER: 'Could not find user for Creator Username',
    DATE_FORMAT_ERROR: 'Date parse error for string',
    MISSING_START_DATE: 'Missing required start date',
    INVALID_REVIEW_FREQUENCY: 'Invalid review frequency value',
    MISSING_REVIEW_FREQUENCY: 'Missing required review frequency',
    INVALID_RECERTIFICATION_TYPE: 'Invalid recertification type value',
    MISSING_RECERTIFICATION_TYPE: 'Missing required recertification type',
    VALIDATION_ERROR_RECERTIFICATION_TYPE_AUTOMATIC:
        'For Automatic recertification, review frequency and a primary contact must be provided',
    VALIDATION_ERROR_RECERTIFICATION_TYPE_MANUAL: 'For Manual recertification, review frequency must be provided',
    INVALID_INHERENT_RISK: 'Invalid inherent risk value',
    MISSING_INHERENT_RISK: 'Missing required inherent risk',
    INVALID_RESIDUAL_RISK: 'Invalid residual risk value',
    MISSING_RESIDUAL_RISK: 'Missing required residual risk',
    INVALID_ASSESSMENT_DATE: 'Assessment completion date must be in the past',
    MISSING_ASSESSMENT_DATE: 'Missing required assessment completion date',
    MISSING_VENDOR_NAME: 'Missing vendor name',
    MISSING_VENDOR_URL: 'Missing vendor url',
    INVALID_VENDOR_URL: 'Vendor url is an invalid url',
    INVALID_CONTACT_PHONE: 'Contact phone number seems to be an invalid phone number',
    INVALID_CONTACT_EMAIL: 'Contact email address seems to be an invalid email address',
    MISSING_CREATOR: 'Missing creator email address',
    UNAUTHORIZED_CREATOR: 'Creator is not in the same org as current user and current user is not admin',
    INVALID_BUSINESS_OWNER_EMAIL: 'Business owner email address not valid',
    INVALID_DOMAIN_BUSINESS_OWNER_EMAIL: 'The business owner has a domain outside of your organization',
    MISSING_BUSINESS_OWNER_EMAIL: 'Missing business owner email address',
    MISSING_BUSINESS_OWNER_FIRST_NAME: 'Missing business owner first name',
    MISSING_BUSINESS_OWNER_LAST_NAME: 'Missing business owner last name',
    MISSING_BUSINESS_OWNER_BUSINESS_UNIT: 'Missing business unit for business owner',
    VALIDATION_ERROR: 'Validation errors',
    UNMATCHED_VENDOR_RECORD: 'Found two vendors with same name, but different homepage and industry',
    UNMATCHED_PRIMARY_CONTACT:
        'Primary contact email matches an existing record, but first and last name are different',
    EMAIL_MAPPED_DIFFERENT_VENDOR: 'Primary contact cannot be used across different vendors',
    EMAIL_MAPPED_EXISTING_DIFFERENT_VENDOR:
        'Primary contact already assigned to a different vendor - please check your relationships',
    EXTRA_VALUES: 'Bad File Format: Values in line exceed header column count',
    MISSING_VALUES: 'Bad File Format: Line is missing values',
    BUSINESS_OWNER_BUSINESS_UNIT_CHANGED: 'Business owner cannot be assigned to multiple business units',
    ALL_PRIMARY_CONTACT_FIELDS_REQUIRED:
        'All primary contact fields are required if email, first name or last name are provided',
    MAXIMUM_TAG_NAME_LENGTH: 'Tag name may not exceed 30 characters in length',
    INVALID_TAG_FORMAT: 'Tag name may only contain letters, numbers and spaces',
    MAXIMUM_ORG_TAGS: 'Attempting to create too many new tags, Orgs may only have a maximum of 100 tags',
    MAXIMUM_RELATIONSHIP_TAGS: 'Relationships may only have a maximum of 20 tags',
    DUPLICATE_TAG_IN_LINE: 'Found more than one occurrence of the same tag name',
    INVALID_AUTOMATIC_ARTIFACT_UPDATE_VALUE: 'Invalid Automatic Artifact Update value, must be Yes or No',
    MISSING_AUTOMATIC_ARTIFACT_UPDATE_VALUE: 'Automatic Artifact Update value is missing',
    EXCEEDS_AVAILABLE_RELATIONSHIP_CREATIONS_ON_CLIENT_LICENSE:
        'The number of relationships in your import would exceed your license limit. Contact sales@visotrust.com to add more.',
    EXCEEDS_AVAILABLE_RELATIONSHIP_ONBOARDINGS_ON_CLIENT_LICENSE:
        'The number of onboarded relationships in your import would exceed your license limit. Contact sales@visotrust.com to add more.',
    EXCEEDS_AVAILABLE_RELATIONSHIP_LIFECYCLE_MANAGEMENT_ON_CLIENT_LICENSE:
        'The number of relationships with lifecycle management in your import would exceed your license limit. Contact sales@visotrust.com to add more.',
};

@Injectable()
export class BulkImportService {
    private resourceUrl = 'api/bulk-import';
    constructor(
        private _http: HttpClient,
        private _csrfService: CSRFService,
    ) {}

    confirmUpload(jobId: number): Observable<any> {
        return this._http
            .post<ImportReport>(`${this.resourceUrl}/import/${jobId}`, {}, { observe: 'response' })
            .pipe(map((response: EntityResponseType) => response.body));
    }

    getImportHistory(): Observable<HttpResponse<ImportJobView[]>> {
        return this._http.get<ImportJobView[]>(`${this.resourceUrl}/history`, {
            observe: 'response',
        });
    }

    downloadArtifactsOnRelationshipAsZip(jobId: number): Observable<HttpResponse<any>> {
        return this._http.get(`${this.resourceUrl}/import/${jobId}`, {
            observe: 'response',
            responseType: 'blob',
        });
    }

    uploadImportCsv(file: File): Observable<any> {
        const formData = new FormData();
        formData.append('file', file);

        return this._http.post<any>(`${this.resourceUrl}/upload-csv`, formData, {
            headers: new HttpHeaders({
                name: 'X-XSRF-TOKEN',
                value: this._csrfService.getCSRF(),
            }),
            context: new HttpContext().set(BYPASS_SNACKBAR_ON_ERROR, { forAnyStatus: true }),
            reportProgress: true,
            observe: 'events',
        });
    }

    parseImportDataByLine(dataByLine: {}): any[] {
        const parsedData = [];
        for (let key in dataByLine) {
            parsedData.push({ line: key, ...dataByLine[key] });
        }
        return parsedData;
    }

    parseImportErrors(errors: any) {
        if (errors && errors.errorCount > 0 && errors.typesToLines) {
            const parsedErrors = Object.keys(errors.typesToLines)
                .map((key) => {
                    if (key in ImportErrorType) {
                        const lineRef =
                            errors.typesToLines[key][0] !== 0 ? `${errors.typesToLines[key].join(', ')}` : '';
                        return { key: ImportErrorType[key], value: lineRef };
                    }
                })
                .filter((item) => {
                    return !!item;
                });
            return parsedErrors;
        } else {
            return errors;
        }
    }
}
