import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { first, map, switchMap, withLatestFrom } from 'rxjs';
import { getPublicAssessmentRequestSuccess, startRTP, submitPasscode, submitPasscodeSuccess } from '../actions';
import { getAssessmentToken } from '../selectors';

@Injectable()
export class EventsEffects {
    onSuccessSubmitPasscode$ = createEffect(() =>
        this._actions$.pipe(
            ofType(submitPasscode),
            switchMap(() => this._actions$.pipe(ofType(getPublicAssessmentRequestSuccess), first())),
            withLatestFrom(this._store$.select(getAssessmentToken)),
            map(() => submitPasscodeSuccess()),
        ),
    );

    triggerStartRTP$ = createEffect(() =>
        this._actions$.pipe(
            ofType(submitPasscodeSuccess),
            map(() => startRTP()),
        ),
    );

    constructor(
        private _actions$: Actions,
        private _store$: Store,
    ) {}
}
