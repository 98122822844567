import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { filter, take, takeUntil, tap } from 'rxjs/operators';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { AppState } from '@shared/redux/state';
import { Actions, ofType } from '@ngrx/effects';
import {
    addDomainToVendor,
    deleteVendorDetailsTabRiskAdvisory,
    deleteVendorDetailsTabRiskAdvisoryRequestSuccess,
    getVendorDetailsRequest,
    getVendorDetailsRequestSuccess,
    getVendorDetailsTabRiskAdvisoriesRequest,
    getVendorDetailsTabRiskAdvisoriesRequestSuccess,
    refreshVendorDetailsVendorDetailsTabRequest,
    refreshVendorDetailsVendorDetailsTabRequestFailed,
    refreshVendorDetailsVendorDetailsTabRequestSuccess,
    updateVendorDetailsRequest,
    updateVendorDetailsRequestFailed,
    updateVendorDetailsRequestSuccess,
} from '../redux/actions/vendor-details-tab.actions';
import { VisoUserRole } from '@entities/viso-user';
import {
    AdminUpdateVendorDetailsRequest,
    UpdateVendorDetailsRequest,
    VendorDetailsResponse,
} from '../models/vendor-details.model';
import { getUserAuthority } from '../../session/redux/session.selectors';
import {
    createVendorRiskAdvisoryRequestSuccess,
    updateVendorRiskAdvisoryRequestSuccess,
} from '@shared/vendor-components/vendor-risk-advisory-dialog/redux/actions/vendor-risk-advisory.actions';
import { RiskAdvisory } from '@entities/risk-advisory';
import { SortDirection } from '@angular/material/sort';
import { FeatureFlags } from '@shared/enums/feature-flags';
import { FeatureFlagService } from '@shared/services/featureflag.service';

@Component({
    selector: 'app-vendor-details-tab',
    templateUrl: './vendor-details-tab.component.html',
    styleUrls: ['./vendor-details-tab.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VendorDetailsTabComponent implements OnInit, OnDestroy {
    @Input({ required: true })
    vendorOrgId: number;

    @Input({ required: true })
    set isTabSelected(value: boolean) {
        this._isTabSelected$.next(value);
    }

    get isTabSelected() {
        return this._isTabSelected$.value;
    }

    Roles = VisoUserRole;

    isCurrentUserAdmin$: Observable<boolean>;
    isCurrentUserSupportAuditAdmin$: Observable<boolean>;
    vendorDetails$ = new BehaviorSubject<VendorDetailsResponse>(null);
    riskAdvisories$ = new BehaviorSubject<RiskAdvisory[]>(null);
    isEditingDirectoryRecord: boolean = false;

    totalRiskAdvisoryCount$ = new BehaviorSubject<number>(0);

    riskAdvisorySortByColumnName: string = 'publishedDate';
    riskAdvisorySortDirection: SortDirection = 'desc';
    riskAdvisoryCurrentPage: number = 0;
    riskAdvisoryItemsPerPage: number = 10;
    showRefreshVendorDetailsButton: boolean = false;
    refreshingVendorDetails: boolean = false;

    private _isTabSelected$ = new BehaviorSubject<boolean>(false);
    private _unsub$ = new Subject<void>();

    constructor(
        private _store$: Store<AppState>,
        private _actions$: Actions,
        private _featureFlagService: FeatureFlagService,
    ) {}

    ngOnInit(): void {
        this.loadFeatureFlags();

        this._isTabSelected$
            .pipe(
                filter((isTabSelected) => isTabSelected),
                take(1),
            )
            .subscribe(() => {
                this.loadVendorDetails();
                this.loadRiskAdvisories();
            });

        this._actions$
            .pipe(
                ofType(
                    refreshVendorDetailsVendorDetailsTabRequestSuccess,
                    refreshVendorDetailsVendorDetailsTabRequestFailed,
                ),
                tap(() => {
                    this.refreshingVendorDetails = false;
                }),
                takeUntil(this._unsub$),
            )
            .subscribe();

        this._actions$
            .pipe(
                ofType(refreshVendorDetailsVendorDetailsTabRequest),
                tap(() => {
                    this.refreshingVendorDetails = true;
                }),
                takeUntil(this._unsub$),
            )
            .subscribe();

        this._actions$
            .pipe(
                ofType(
                    updateVendorDetailsRequestSuccess,
                    updateVendorDetailsRequestFailed,
                    refreshVendorDetailsVendorDetailsTabRequestSuccess,
                ),
                tap(() => {
                    this.loadVendorDetails();
                }),
                takeUntil(this._unsub$),
            )
            .subscribe();

        this._actions$
            .pipe(ofType(getVendorDetailsRequestSuccess), takeUntil(this._unsub$))
            .subscribe(({ vendorDetails }) => this.vendorDetails$.next(vendorDetails));

        this.isCurrentUserAdmin$ = this._store$.pipe(select(getUserAuthority(VisoUserRole.Admin)));
        this.isCurrentUserSupportAuditAdmin$ = this._store$.pipe(
            select(getUserAuthority([VisoUserRole.Auditor, VisoUserRole.Support, VisoUserRole.Admin])),
        );

        this._actions$
            .pipe(
                ofType(
                    createVendorRiskAdvisoryRequestSuccess,
                    updateVendorRiskAdvisoryRequestSuccess,
                    deleteVendorDetailsTabRiskAdvisoryRequestSuccess,
                ),
                tap(() => {
                    this.loadRiskAdvisories();
                }),
                takeUntil(this._unsub$),
            )
            .subscribe();

        this._actions$
            .pipe(ofType(getVendorDetailsTabRiskAdvisoriesRequestSuccess), takeUntil(this._unsub$))
            .subscribe(({ riskAdvisories, httpHeaders }) => {
                this.totalRiskAdvisoryCount$.next(+httpHeaders.get('x-total-count'));
                this.riskAdvisories$.next(riskAdvisories);
            });
    }

    toggleEdit() {
        this.isEditingDirectoryRecord = !this.isEditingDirectoryRecord;
    }

    addNewDomain(domain: string) {
        this._store$.dispatch(
            addDomainToVendor({
                vendorDetails: this.vendorDetails$.value,
                newDomain: domain,
            }),
        );
    }

    updateVendorDetails(
        request: AdminUpdateVendorDetailsRequest | UpdateVendorDetailsRequest,
        isCurrentUserAdmin: boolean,
    ) {
        this._store$.dispatch(
            updateVendorDetailsRequest({
                updateRequest: request,
                isCurrentUserAdmin,
            }),
        );
    }

    onRiskAdvisorySortChange(event: { column: string; direction: SortDirection }) {
        this.riskAdvisorySortByColumnName = event.column;
        this.riskAdvisorySortDirection = event.direction;
        this.loadRiskAdvisories();
    }

    onRiskAdvisoryPageChange(event: { page: number; pageSize: number }) {
        this.riskAdvisoryCurrentPage = event.page;
        this.riskAdvisoryItemsPerPage = event.pageSize;
        this.loadRiskAdvisories();
    }

    refreshVendorDetails(vendorDetails: VendorDetailsResponse) {
        this._store$.dispatch(
            refreshVendorDetailsVendorDetailsTabRequest({
                vendorId: vendorDetails.id,
            }),
        );
    }

    deleteRiskAdvisory(event: { riskAdvisory: RiskAdvisory }) {
        this._store$.dispatch(deleteVendorDetailsTabRiskAdvisory(event));
    }

    private sortRiskAdvisories(): string[] {
        const result = [this.riskAdvisorySortByColumnName + ',' + this.riskAdvisorySortDirection];
        if (this.riskAdvisorySortByColumnName !== 'id') {
            result.push('id');
        }
        return result;
    }

    private loadVendorDetails() {
        this._store$.dispatch(getVendorDetailsRequest({ orgId: this.vendorOrgId }));
    }

    private loadRiskAdvisories() {
        this._store$.dispatch(
            getVendorDetailsTabRiskAdvisoriesRequest({
                params: {
                    page: this.riskAdvisoryCurrentPage,
                    size: this.riskAdvisoryItemsPerPage,
                    sort: this.sortRiskAdvisories(),
                },
                vendorId: this.vendorOrgId,
            }),
        );
    }

    private loadFeatureFlags(): void {
        this._featureFlagService.flagsLoaded.pipe(takeUntil(this._unsub$)).subscribe((flags) => {
            this.showRefreshVendorDetailsButton = flags[FeatureFlags.BIG_PICTURE_PUBLIC_SEARCH_API] ?? false;
        });
    }

    ngOnDestroy(): void {
        this._unsub$.next();
    }
}
