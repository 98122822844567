import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DataTypeConfig } from '../../../../admin/client-profile/client-profile.model';

@Component({
    selector: 'app-data-types',
    templateUrl: './data-types.component.html',
    styleUrls: ['./data-types.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataTypesComponent {
    @Input()
    dataTypesConfig: DataTypeConfig[];
}
