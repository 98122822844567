import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DemoData } from './demo-tools.model';

@Injectable()
export class DemoToolsService {
    private demoResourceUrl = 'api/demo';

    constructor(private http: HttpClient) {}

    resetDataForClient(id: number, demoData: DemoData): Observable<Object> {
        return this.http.post(this.demoResourceUrl + '/reset-demo/' + id, demoData);
    }

    createArtifactUpdateAssessmentForClient(id: number, vendorCount: number): Observable<Object> {
        return this.http.post(
            this.demoResourceUrl + '/artifact-update-assessment/' + id + '?vendorCount=' + vendorCount,
            null,
        );
    }

    createRecertificationAssessmentForClient(id: number, vendorCount: number): Observable<Object> {
        return this.http.post(
            this.demoResourceUrl + '/recertification-assessment/' + id + '?vendorCount=' + vendorCount,
            null,
        );
    }

    createRiskNetworkDetectionsForClient(
        id: number,
        minPerRelationship: number,
        maxPerRelationship: number,
    ): Observable<any> {
        return this.http.post(
            `${this.demoResourceUrl}/risk-network/${id}?minPerRelationship=${minPerRelationship}&maxPerRelationship=${maxPerRelationship}`,
            null,
        );
    }
}
