<div class="row mt-2">
    <div class="col">
        <p class="text-light text-sm">Label</p>
    </div>
    <div class="col">
        <p class="text-light text-sm">Threshold</p>
    </div>
</div>
<ng-container *ngFor="let riskLevel of riskOrderReverse">
    <div class="row mt-2">
        <div class="col">
            <p [ngClass]="riskLevel | callback: riskLevelClasses" class="text-bold">
                {{ getRiskLevelDisplayName(riskLevel) }}
            </p>
        </div>
        <div class="col">
            <p>
                {{ riskToleranceThresholds?.get(activeRiskTolerance)?.get(riskLevel)?.threshold }}
            </p>
        </div>
    </div>
</ng-container>
