import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { FrameworkMapping } from './models/framework-mapping.model';

@Injectable({
    providedIn: 'root',
})
export class FrameworkMappingsService {
    private _resourceUrl = 'api/framework-mappings';

    constructor(private _http: HttpClient) {}

    getFrameworkMappingsByType(frameworkType: string): Observable<FrameworkMapping> {
        return this._http.get<FrameworkMapping>(`${this._resourceUrl}/${frameworkType}`);
    }
}
