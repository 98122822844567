import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
    activateAccountRequest,
    activateAccountRequestFailed,
    activateAccountRequestSuccess,
    signUpRequest,
    signUpRequestFailed,
    signUpRequestSuccess,
} from './user-account.actions';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { AccountService } from '../../../shared';

@Injectable()
export class UserAccountEffects {
    signUpRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(signUpRequest),
            switchMap(({ request }) =>
                this._accountService.signUp(request).pipe(
                    map((response) => {
                        window['dataLayer'] = window?.['dataLayer'] || [];
                        window['dataLayer'].push({
                            event: 'signupSuccess', // Custom event name for successful login
                            currentDate: new Date(), // Pushing the current date to the data layer
                        });
                        return signUpRequestSuccess();
                    }),
                    catchError((error) => of(signUpRequestFailed({ error }))),
                ),
            ),
        ),
    );

    activateAccountRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(activateAccountRequest),
            switchMap(({ activationToken }) =>
                this._accountService.activate(activationToken).pipe(
                    map((response) => activateAccountRequestSuccess()),
                    catchError((error) => of(activateAccountRequestFailed({ error }))),
                ),
            ),
        ),
    );

    constructor(
        private _actions$: Actions,
        private _accountService: AccountService,
    ) {}
}
