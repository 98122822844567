import { Action, createReducer, on } from '@ngrx/store';
import { initialState, OrgSubdomainState } from './org-subdomain.state';
import {
    deleteOrgSubdomainRequestSuccess,
    getOrgSubdomainRequestSuccess,
    setOrgSubdomainRequestSuccess,
} from './org-subdomain.actions';

const reducer = createReducer(
    initialState,
    on(getOrgSubdomainRequestSuccess, (state, { response }) => ({
        ...state,
        domain: response.domain,
        subdomain: response.subdomain,
    })),
    on(setOrgSubdomainRequestSuccess, (state, { response }) => ({
        ...state,
        subdomain: response.subdomain,
    })),
    on(deleteOrgSubdomainRequestSuccess, (state) => ({
        ...state,
        subdomain: null,
    })),
);

export function orgSubdomainReducer(state: OrgSubdomainState = initialState, action: Action): OrgSubdomainState {
    return reducer(state, action);
}
