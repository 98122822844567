import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { filter, map, withLatestFrom } from 'rxjs';
import { getQuestionnaireArtifact } from '../selectors';
import { createQuestionnaire, navigateToQuestionnaire } from '../actions';

@Injectable()
export class QuestionnaireEffects {
    triggerCreateQuestionnaire$ = createEffect(() =>
        this._actions$.pipe(
            ofType(navigateToQuestionnaire),
            withLatestFrom(this._store$.select(getQuestionnaireArtifact)),
            filter(([, questionnaireArtifact]) => !questionnaireArtifact),
            map(() => createQuestionnaire()),
        ),
    );

    constructor(
        private _store$: Store,
        private _actions$: Actions,
    ) {}
}
