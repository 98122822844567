import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'sentenceCase',
})
export class SentenceCasePipe implements PipeTransform {
    transform(value: string) {
        return !!value ? value.charAt(0).toUpperCase() + value.substr(1).toLowerCase() : '';
    }
}
