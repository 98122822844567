import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import {
    DataTypeConfig,
    OrgAssessmentSummarySections,
    OrgDataSensitivityConfig,
    OrgRiskConfig,
} from '../../../admin/client-profile/client-profile.model';
import { UserProfile, VisoUser, VisoUserRole } from '../../../entities/viso-user';
import { AppState } from '../../../shared/redux/state';
import { initialState, SessionState, sessionStateKey } from './session.state';
import { RiskTolerance } from '@entities/risk-tolerance';

export const getSessionState: MemoizedSelector<AppState, SessionState> =
    createFeatureSelector<SessionState>(sessionStateKey);

export const getIsAuthenticated: MemoizedSelector<AppState, boolean> = createSelector(
    getSessionState,
    (state) => (state && state.isAuthenticated) || initialState.isAuthenticated,
);

export const getUserAccount: MemoizedSelector<AppState, VisoUser> = createSelector(
    getSessionState,
    (state) => (state && state.account) || initialState.account,
);

export const getIsSessionLoaded: MemoizedSelector<AppState, boolean> = createSelector(
    getSessionState,
    (state) => (state && state.sessionLoaded) || initialState.sessionLoaded,
);

export const getUserAuthority = (authorities: VisoUserRole[] | VisoUserRole) =>
    createSelector(getSessionState, (state: SessionState) => {
        if (!state.isAuthenticated || !state.account || !state.account.authorities) {
            return false;
        }

        if (!Array.isArray(authorities)) {
            authorities = [authorities];
        }

        return authorities.some((authority) => state.account.authorities.includes(authority));
    });

export const getRiskLevelDisplayName = (riskLevel: string) =>
    createSelector(getSessionState, (state: SessionState) => {
        const matchingRiskLevel = state.clientProfile?.riskLevels.find(
            (r) => r.riskLevel.toString() === riskLevel?.toUpperCase(),
        );
        return matchingRiskLevel ? matchingRiskLevel.displayName : '';
    });

export const getRiskLevelDisplayNamesForClient: MemoizedSelector<AppState, OrgRiskConfig[]> = createSelector(
    getSessionState,
    (state) => state.clientProfile?.riskLevels,
);

export const getAccountRiskLevels = createSelector(
    getSessionState,
    (state: SessionState) => state.clientProfile?.riskLevels,
);

export const getProfileAssessmentSummarySections: MemoizedSelector<AppState, OrgAssessmentSummarySections[]> =
    createSelector(getSessionState, (state) => state.clientProfile?.assessmentSummarySections);

export const getProfileDataTypes: MemoizedSelector<AppState, DataTypeConfig[]> = createSelector(
    getSessionState,
    (state) => state.clientProfile?.dataTypes,
);

export const getProfileDataSensitivityLevels: MemoizedSelector<AppState, OrgDataSensitivityConfig[]> = createSelector(
    getSessionState,
    (state) => state.clientProfile?.dataSensitivityLevels,
);

export const getProfileRiskTolerance: MemoizedSelector<AppState, RiskTolerance> = createSelector(
    getSessionState,
    (state) => state.clientProfile?.riskTolerance,
);

export const getUserProfileSelector: MemoizedSelector<AppState, UserProfile> = createSelector(
    getSessionState,
    (state) => state.userProfile,
);

export const getUserIsTrial: MemoizedSelector<AppState, boolean> = createSelector(
    getSessionState,
    (state) => state.userIsTrial,
);
