import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { Subject } from 'rxjs';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { AssessmentSummarySection, AssessmentSummarySectionLabels } from './model/assessment-summary-config-models';
import { OrgAssessmentSummarySections } from '../../../../admin/client-profile/client-profile.model';
import { FeatureFlagService } from '../../../services/featureflag.service';
import { FeatureFlags } from '../../../enums/feature-flags';

interface AssessmentSummaryConfigFormGroup {
    assessmentSections: FormArray<FormGroup<AssessmentSectionFormGroup>>;
}

interface AssessmentSectionFormGroup {
    section: FormControl<AssessmentSummarySection>;
    visible: FormControl<boolean>;
}

@Component({
    selector: 'app-assessment-summary-config-form',
    templateUrl: './assessment-summary-config-form.component.html',
    styleUrls: ['./assessment-summary-config-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssessmentSummaryConfigFormComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
    @Input({ required: true })
    assessmentSummarySections: OrgAssessmentSummarySections[];

    @Input({ required: true })
    isCurrentUserOrgAdmin: boolean;

    @Input({ required: true })
    exampleSummary: string;

    @Output() assessmentSummaryConfigChanged = new EventEmitter<Record<AssessmentSummarySection, boolean>>();

    @ViewChild('assessmentSummary')
    private _assessmentSummary: ElementRef;

    AssessmentSummarySection = AssessmentSummarySection;
    AssessmentSummarySectionLabels = AssessmentSummarySectionLabels;

    assessmentSummaryConfigFormGroup: FormGroup<AssessmentSummaryConfigFormGroup>;

    private _unsub$ = new Subject<void>();

    constructor(
        private _fb: FormBuilder,
        private _featureFlagService: FeatureFlagService,
    ) {}

    ngOnInit(): void {
        this.assessmentSummaryConfigFormGroup = this._fb.group({
            assessmentSections: this._fb.array<FormGroup<AssessmentSectionFormGroup>>(
                this.assessmentSummarySections.map((assessmentSection) =>
                    this._fb.group({
                        section: this._fb.control(AssessmentSummarySection[assessmentSection.section]),
                        visible: this._fb.control({
                            value: assessmentSection.visible,
                            disabled:
                                AssessmentSummarySection[assessmentSection.section] ===
                                    AssessmentSummarySection.PRIVACY_EVALUATION ||
                                AssessmentSummarySection[assessmentSection.section] ===
                                    AssessmentSummarySection.RISK_SUMMARY,
                        }),
                    }),
                ),
            ),
        });

        if (!this.isCurrentUserOrgAdmin) {
            this.assessmentSummaryConfigFormGroup.disable();
        }

        this._featureFlagService
            .hasFeatureFlagEnabled(FeatureFlags.PRIVACY_MODULE)
            .pipe(takeUntil(this._unsub$))
            .subscribe((isPrivacyEnabled) => {
                const privacyEvalControl =
                    this.assessmentSummaryConfigFormGroup.controls.assessmentSections.controls.find(
                        (ctrl) => ctrl.controls.section.value === AssessmentSummarySection.PRIVACY_EVALUATION,
                    );
                isPrivacyEnabled && this.isCurrentUserOrgAdmin
                    ? privacyEvalControl?.enable()
                    : privacyEvalControl?.disable();
            });
    }

    ngAfterViewInit(): void {
        this.greyOutSections();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (
            changes.assessmentSummarySections &&
            !!this.assessmentSummarySections &&
            !!this.assessmentSummaryConfigFormGroup
        ) {
            this.updateSummarySectionsForm(this.assessmentSummarySections);
        }
    }

    getVisibleFormControlBySummarySection = (
        assessmentSummarySection: AssessmentSummarySection,
    ): FormControl<boolean> => {
        for (let assessmentSectionFormGroup of this.assessmentSummaryConfigFormGroup.controls.assessmentSections
            .controls) {
            if (assessmentSectionFormGroup.controls.section.getRawValue() === assessmentSummarySection) {
                return assessmentSectionFormGroup.controls.visible;
            }
        }
        return null;
    };

    greyOutSections(): void {
        const sections = this._assessmentSummary?.nativeElement?.querySelectorAll('section');
        sections?.forEach((section: HTMLElement) => {
            const sectionId = section?.id;
            const sectionData = this.assessmentSummaryConfigFormGroup.controls.assessmentSections.controls.find(
                (sec) => sec.controls.section.value.toString() === sectionId,
            );
            if (!sectionData?.value?.visible && sectionData?.value?.section !== AssessmentSummarySection.RISK_SUMMARY) {
                section.className = 'text-muted';
            } else {
                section.className = '';
            }
        });
    }

    toggleAssessmentSummarySection(): void {
        const assessmentSectionValues = this.assessmentSummaryConfigFormGroup.controls.assessmentSections.value;
        const payload = assessmentSectionValues.reduce<Record<AssessmentSummarySection, boolean>>(
            (record, { section, visible }) => {
                record[section] = visible;
                return record;
            },
            {} as Record<AssessmentSummarySection, boolean>,
        );

        this.assessmentSummaryConfigChanged.emit(payload);

        this.greyOutSections();
    }

    private updateSummarySectionsForm(profileAssessmentSummarySections: OrgAssessmentSummarySections[]): void {
        profileAssessmentSummarySections.forEach((summarySection) => {
            const matchingFormControl = this.assessmentSummaryConfigFormGroup.controls.assessmentSections.controls.find(
                (ctrl) => ctrl.controls.section.getRawValue() === summarySection.section,
            );
            if (matchingFormControl) {
                matchingFormControl.patchValue({ visible: summarySection.visible });
            }
        });
        this.greyOutSections();
    }

    ngOnDestroy(): void {
        this._unsub$.next();
    }
}
