import { Route } from '@angular/router';

import { ClientProfileComponent } from './client-profile.component';

export const clientProfileRoute: Route = {
    path: 'client-profile',
    component: ClientProfileComponent,
    data: {
        pageTitle: 'Client Profile',
    },
};
