import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Risk } from '../../../../entities/risk-assessment';

@Component({
    selector: 'step-header-with-risk-preview',
    templateUrl: 'step-header-with-risk-preview.component.html',
    styleUrls: ['step-header-with-risk-preview.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StepHeaderWithRiskPreviewComponent {
    @Input() inherentRisk: Risk;
    @Input() defaultState: boolean;
}
