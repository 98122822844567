import { createAction, props } from '@ngrx/store';

export enum RiskAdvisoryActions {
    OpenCreateRiskAdvisoryDialog = '[Request] Open Create Risk Advisory Dialog',
}

export const openCreateRiskAdvisoryDialog = createAction(
    RiskAdvisoryActions.OpenCreateRiskAdvisoryDialog,
    props<{
        orgId: number;
        name: string;
    }>(),
);
