import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { RecertificationType, Relationship, RequestStatus } from '@entities/relationship';
import { Risk, RiskAssessment } from '@entities/risk-assessment';
import { AssessmentUtilsService } from '@shared/utils/assessment-utils.service';
import { AssessmentStatus, AssessmentType, AssessmentView } from '@entities/assessment';
import { VisoUserRole } from '@entities/viso-user';
import { RemediationStatus } from '@entities/remediation-request/remediation-request.model';

enum ResidualRiskHelpText {
    NOT_ASSESSED = 'Not assessed',
    ASSESSMENT_STARTED = 'Assessment Started',
    ASSESSMENT_COLLECTING_INFORMATION = 'Collecting Information',
    ASSESSMENT_REVIEW_STARTED = 'Review Started',
    RECERTIFICATION_IN_PROGRESS = 'Recertification in progress',
    ARTIFACT_UPDATE_IN_PROGRESS = 'Artifact Update in progress',
    ASSESSMENT_COMPLETED = 'Assessment is complete',
    AWAITING_REVIEW = 'Awaiting review',
    RISK_ACCEPTED = 'Risk accepted',
    REMEDIATION_REQUESTED = 'Remediation requested',
    REMEDIATION_CANCELLED = 'Remediation cancelled',
    REMEDIATION_DECLINED = 'Remediation declined',
}

enum LifecycleStatus {
    UP_TO_DATE = 'Up To Date',
    UPDATING_ARTIFACTS = 'Updating Artifacts',
    RECERTIFYING_RELATIONSHIP = 'Recertifying Relationship',
    RECERTIFICATION_OVERDUE = 'Recertification Overdue',
    NOT_APPLY = 'N/A',
    NOT_ONBOARDED = 'Not Onboarded',
}

@Component({
    selector: 'app-stat-boxes',
    templateUrl: './stat-boxes.component.html',
    styleUrls: ['./stat-boxes.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatBoxesComponent implements OnInit {
    @Input({ required: true })
    set request(value: Relationship) {
        this._relationship$.next(value);
    }

    @Input({ required: true })
    set latestAssessment(value: AssessmentView) {
        this._latestAssessment$.next(value);
    }

    @Input({ required: true })
    set latestRiskAssessment(value: RiskAssessment) {
        this._latestRiskAssessment$.next(value);
    }

    @Input({ required: true })
    set latestNonTransitionalRiskAssessment(value: RiskAssessment) {
        this._latestNonTransitionalRiskAssessment$.next(value);
    }

    @Input({ required: true })
    set isRecertificationOverdue(value: boolean) {
        this.isRecertificationOverdue$.next(value);
    }

    @Input({ required: true })
    set isAssessmentInProgress(value: boolean) {
        this.isAssessmentInProgress$.next(value);
    }

    @Input({ required: true })
    set recertificationDueDaysLeftCount(value: number) {
        this.recertificationDueDaysLeftCount$.next(value);
    }

    @Input({ required: true })
    set isUpForRecertification(value: boolean) {
        this.isUpForRecertification$.next(value);
    }

    @Output()
    editRiskReview = new EventEmitter<void>();

    lifecycleStatus$: Observable<LifecycleStatus>;
    isNotOnboarded$: Observable<boolean>;
    isUpdatingArtifactsOrRecertifying$: Observable<boolean>;
    inherentRiskLabel$: Observable<Risk>;
    residualRiskLabel$: Observable<Risk>;
    residualRiskHelpText$: Observable<ResidualRiskHelpText>;
    isResidualRiskTransitionalAndNotOverridden$: Observable<boolean>;
    inherentRiskBoxCssClasses$: Observable<string>;
    residualRiskBoxCssClasses$: Observable<string[]>;
    recertificationDate$: Observable<Date>;
    isRecertificationDisabled$: Observable<boolean>;
    riskIsOverridden$: Observable<boolean>;

    isRecertificationOverdue$ = new BehaviorSubject<boolean>(null);
    isAssessmentInProgress$ = new BehaviorSubject<boolean>(null);
    recertificationDueDaysLeftCount$ = new BehaviorSubject<number>(null);
    isUpForRecertification$ = new BehaviorSubject<boolean>(null);

    Roles = VisoUserRole;

    private _relationship$ = new BehaviorSubject<Relationship>(null);
    private _latestAssessment$ = new BehaviorSubject<AssessmentView>(null);
    private _latestRiskAssessment$ = new BehaviorSubject<RiskAssessment>(null);
    private _latestNonTransitionalRiskAssessment$ = new BehaviorSubject<RiskAssessment>(null);

    constructor(private _assessmentUtils: AssessmentUtilsService) {}

    ngOnInit(): void {
        const latestRiskAssessment$ = this._latestRiskAssessment$.pipe(filter((riskAssessment) => !!riskAssessment));
        this.inherentRiskLabel$ = latestRiskAssessment$.pipe(map((riskAssessment) => riskAssessment.inherentRisk));
        this.residualRiskLabel$ = combineLatest([
            latestRiskAssessment$,
            this._latestNonTransitionalRiskAssessment$,
        ]).pipe(
            map(([latestRiskAssessment, latestNonTransitionalRiskAssessment]) => {
                if (latestRiskAssessment.legacy) {
                    return latestRiskAssessment;
                }
                return latestNonTransitionalRiskAssessment || latestRiskAssessment;
            }),
            map((riskAssessment) => riskAssessment?.risk),
        );

        this.isResidualRiskTransitionalAndNotOverridden$ = latestRiskAssessment$.pipe(
            map((riskAssessment) => riskAssessment.transitional && !riskAssessment.legacy),
        );

        this.inherentRiskBoxCssClasses$ = this.inherentRiskLabel$.pipe(
            map((inherentRisk) => this.getRisk(inherentRisk)),
        );

        this.residualRiskBoxCssClasses$ = combineLatest([
            this.residualRiskLabel$,
            this.isResidualRiskTransitionalAndNotOverridden$,
        ]).pipe(
            map(([residualRisk, isTransitionalAndNotOverridden]) => [
                this.getRisk(residualRisk),
                isTransitionalAndNotOverridden ? 'transitional' : '',
            ]),
        );

        this.residualRiskHelpText$ = combineLatest([this._relationship$, this._latestAssessment$]).pipe(
            map(([relationship, latestAssessment]) => {
                if (!latestAssessment) {
                    return ResidualRiskHelpText.NOT_ASSESSED;
                }
                const { status, type } = latestAssessment;
                if (this._assessmentUtils.isAssessmentComplete(status)) {
                    return (
                        this.tryGetRiskAcceptanceStatusText(relationship) ??
                        this.tryGetRemediationRequestStatusText(latestAssessment) ??
                        ResidualRiskHelpText.AWAITING_REVIEW
                    );
                }

                if (this._assessmentUtils.isAssessmentInProgress(status)) {
                    switch (type) {
                        case AssessmentType.ARTIFACT_UPDATE:
                            return ResidualRiskHelpText.ARTIFACT_UPDATE_IN_PROGRESS;
                        case AssessmentType.RECERTIFICATION:
                            return ResidualRiskHelpText.RECERTIFICATION_IN_PROGRESS;
                        case AssessmentType.CERTIFICATION:
                        case AssessmentType.REMEDIATION:
                            switch (status) {
                                case AssessmentStatus.STARTED:
                                    return ResidualRiskHelpText.ASSESSMENT_STARTED;
                                case AssessmentStatus.COLLECTING_INFORMATION:
                                    return ResidualRiskHelpText.ASSESSMENT_COLLECTING_INFORMATION;
                                case AssessmentStatus.REVIEW_STARTED:
                                    return ResidualRiskHelpText.ASSESSMENT_REVIEW_STARTED;
                            }
                    }
                }
            }),
        );

        const filteredRequest$ = this._relationship$.pipe(filter<Relationship>(Boolean));

        this.isRecertificationDisabled$ = filteredRequest$.pipe(
            map((request) => request.recertificationType === RecertificationType.NONE),
        );

        this.recertificationDate$ = filteredRequest$.pipe(map((rec) => rec.recertificationDate));

        this.lifecycleStatus$ = combineLatest([
            this._latestAssessment$,
            filteredRequest$,
            this.isRecertificationOverdue$,
            this.isAssessmentInProgress$,
            this.isRecertificationDisabled$,
        ]).pipe(
            map(
                ([
                    assessment,
                    request,
                    isRecertificationOverdue,
                    isAssessmentInProgress,
                    isRecertificationDisabled,
                ]) => {
                    if (request.status !== RequestStatus.ONBOARDED) {
                        return LifecycleStatus.NOT_ONBOARDED;
                    }

                    if (request.status === RequestStatus.ONBOARDED) {
                        if (
                            isRecertificationDisabled ||
                            !assessment ||
                            (assessment.type === AssessmentType.CERTIFICATION &&
                                this._assessmentUtils.isAssessmentInProgress(assessment.status))
                        ) {
                            return LifecycleStatus.NOT_APPLY;
                        }

                        if (
                            assessment?.type === AssessmentType.ARTIFACT_UPDATE &&
                            this._assessmentUtils.isAssessmentInProgress(assessment.status)
                        ) {
                            return LifecycleStatus.UPDATING_ARTIFACTS;
                        }

                        if (isAssessmentInProgress) {
                            return LifecycleStatus.RECERTIFYING_RELATIONSHIP;
                        }

                        if (isRecertificationOverdue) {
                            return LifecycleStatus.RECERTIFICATION_OVERDUE;
                        }

                        if (assessment?.status === AssessmentStatus.COMPLETED) {
                            return LifecycleStatus.UP_TO_DATE;
                        }
                    }
                },
            ),
        );

        this.isNotOnboarded$ = this.lifecycleStatus$.pipe(map((status) => status === LifecycleStatus.NOT_ONBOARDED));

        this.isUpdatingArtifactsOrRecertifying$ = this.lifecycleStatus$.pipe(
            map(
                (status) =>
                    status === LifecycleStatus.UPDATING_ARTIFACTS ||
                    status === LifecycleStatus.RECERTIFYING_RELATIONSHIP,
            ),
        );

        this.riskIsOverridden$ = this._relationship$.pipe(
            map((request) => request.latestRiskAssessment?.legacy ?? false),
        );
    }

    private getRisk(risk: Risk): string {
        switch (risk) {
            case Risk.EXTREME:
            case Risk.HIGH:
            case Risk.MEDIUM:
            case Risk.LOW:
                return risk.toString().toLowerCase();
        }
        return '';
    }

    private tryGetRemediationRequestStatusText(latestAssessment: AssessmentView): ResidualRiskHelpText {
        switch (latestAssessment.remediationRequest?.status) {
            case RemediationStatus.REQUESTED:
            case RemediationStatus.ARTIFACTS_PROVIDED:
                return ResidualRiskHelpText.REMEDIATION_REQUESTED;
            case RemediationStatus.CANCELLED:
                return ResidualRiskHelpText.REMEDIATION_CANCELLED;
            case RemediationStatus.DECLINED:
                return ResidualRiskHelpText.REMEDIATION_DECLINED;
            default:
                return null;
        }
    }

    private tryGetRiskAcceptanceStatusText(relationship: Relationship): ResidualRiskHelpText {
        return relationship.riskAccepted ? ResidualRiskHelpText.RISK_ACCEPTED : null;
    }
}
