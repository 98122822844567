import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ControlsOf } from '@shared/model/controls-of';
import { VendorDetails } from '../../../models';
import { MatDialogRef } from '@angular/material/dialog';
import { FormUtilsService } from '@shared/utils/form-utils.service';

@Component({
    selector: 'app-confirm-decline-remediation-dialog',
    templateUrl: './confirm-decline-remediation-dialog.component.html',
    styleUrls: ['./confirm-decline-remediation-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmDeclineRemediationDialogComponent {
    @Input({ required: true })
    clientName: string;

    @Input({ required: true })
    vendorName: string;

    declineRemediationFormGroup: FormGroup<ControlsOf<VendorDetails>>;

    constructor(
        private dialogRef: MatDialogRef<ConfirmDeclineRemediationDialogComponent>,
        private _fb: FormBuilder,
        private _formUtils: FormUtilsService,
    ) {}

    ngOnInit(): void {
        this.declineRemediationFormGroup = this._fb.group({
            firstName: this._fb.control('', { validators: Validators.required }),
            lastName: this._fb.control('', { validators: Validators.required }),
            email: this._fb.control('', { validators: [Validators.required, Validators.email] }),
        });
    }

    confirm(): void {
        this.dialogRef.close(this._formUtils.getCleanTypedFormGroupValue(this.declineRemediationFormGroup));
    }
}
