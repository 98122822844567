import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
    deleteRelationshipAssessmentSummaryConfigRequest,
    deleteRelationshipAssessmentSummaryConfigRequestFailed,
    deleteRelationshipAssessmentSummaryConfigRequestSuccess,
    getRelationshipAssessmentSummaryConfigRequest,
    getRelationshipAssessmentSummaryConfigRequestFailed,
    getRelationshipAssessmentSummaryConfigRequestSuccess,
    updateRelationshipAssessmentSummaryConfigRequest,
    updateRelationshipAssessmentSummaryConfigRequestFailed,
    updateRelationshipAssessmentSummaryConfigRequestSuccess,
} from '../actions/assessment-summary-config.actions';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { ROUTER_NAVIGATED, RouterNavigationAction } from '@ngrx/router-store';
import { AssessmentSummaryConfigDialogComponent } from '../../assessments/assessment-summary-config-dialog/assessment-summary-config-dialog.component';
import { MatDialogWrapperService } from '../../../../shared/modal/mat-dialog-wrapper.service';
import { RelationshipService } from '../../../../entities/relationship';
import { SnackbarService } from '../../../../shared/components/snackbar/snackbar.service';

@Injectable()
export class AssessmentSummaryConfigEffects {
    getRelationshipAssessmentSummaryConfigRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(getRelationshipAssessmentSummaryConfigRequest),
            switchMap(({ relationshipId }) =>
                this._requestService.findAssessmentSummarySectionConfigForRelationship(relationshipId).pipe(
                    map((assessmentSummarySections) =>
                        getRelationshipAssessmentSummaryConfigRequestSuccess({ assessmentSummarySections }),
                    ),
                    catchError(() => of(getRelationshipAssessmentSummaryConfigRequestFailed())),
                ),
            ),
        ),
    );

    updateRelationshipAssessmentSummaryConfigRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(updateRelationshipAssessmentSummaryConfigRequest),
            switchMap(({ relationshipId, updateAssessmentSummarySectionConfigRequest }) =>
                this._requestService
                    .updateAssessmentSummarySectionConfigForRelationship(
                        relationshipId,
                        updateAssessmentSummarySectionConfigRequest,
                    )
                    .pipe(
                        map(() => updateRelationshipAssessmentSummaryConfigRequestSuccess()),
                        catchError(() => of(updateRelationshipAssessmentSummaryConfigRequestFailed())),
                    ),
            ),
        ),
    );

    deleteRelationshipAssessmentSummaryConfigRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(deleteRelationshipAssessmentSummaryConfigRequest),
            switchMap(({ relationshipId }) =>
                this._requestService.deleteAssessmentSummarySectionConfigForRelationship(relationshipId).pipe(
                    map(() => deleteRelationshipAssessmentSummaryConfigRequestSuccess()),
                    catchError(() => of(deleteRelationshipAssessmentSummaryConfigRequestFailed())),
                ),
            ),
        ),
    );

    updateRelationshipAssessmentSummaryConfigRequestSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(updateRelationshipAssessmentSummaryConfigRequestSuccess),
                tap(() => {
                    this._snackbarService.success('Configuration updated');
                }),
            ),
        { dispatch: false },
    );

    deleteRelationshipAssessmentSummaryConfigRequestSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(deleteRelationshipAssessmentSummaryConfigRequestSuccess),
                tap(() => {
                    this._snackbarService.success('Configuration reset to org defaults');
                }),
            ),
        { dispatch: false },
    );

    openRelationshipAssessmentSummaryConfigModal$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(ROUTER_NAVIGATED),
                map((action: RouterNavigationAction) =>
                    action.payload.routerState.root.children.find((route) => route.outlet === 'popup'),
                ),
                filter(
                    (route) =>
                        !!route &&
                        route.routeConfig.path === 'relationships/:relationshipId/assessment-summary-configuration',
                ),
                map((route) => route.params.relationshipId),
                tap((relationshipId) =>
                    this._dialogService.open(AssessmentSummaryConfigDialogComponent, {
                        inputs: {
                            relationshipId,
                        },
                        config: {
                            maxWidth: 1000,
                            minWidth: 1000,
                            minHeight: 600,
                        },
                    }),
                ),
            ),
        { dispatch: false },
    );

    constructor(
        private _actions$: Actions,
        private _dialogService: MatDialogWrapperService,
        private _requestService: RelationshipService,
        private _snackbarService: SnackbarService,
    ) {}
}
