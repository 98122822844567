import { ActionReducer, Action } from '@ngrx/store';
import { pick, omit } from 'lodash-es';
import { DEFAULT_VERSION, MigrationConfig, PersistState } from '../migration/migration.model';

export function storageMetareducer<T, S, A extends Action = Action>(
    storeKeys: string[],
    localStorageKey: string,
    updateOnActions: string[] = [],
    migrationConfig: MigrationConfig<T>,
) {
    let onInit = true; // after load/refresh…
    return function (reducer: ActionReducer<S, A>) {
        return function (state: S, action: A): S {
            const nextState = reducer(state, action);
            const version = migrationConfig.version !== undefined ? migrationConfig.version : DEFAULT_VERSION;
            const persist: PersistState = {
                version,
                rehydrated: false,
            };
            if (onInit) {
                onInit = false;
                const restoredState = fetchData(localStorageKey);
                const migratedState =
                    migrationConfig && restoredState ? migrateState(restoredState, migrationConfig) : nextState;
                persist.rehydrated = true;
                const rehydratedState = { ...nextState, ...migratedState, _persist: persist };
                saveData(localStorageKey, serializeData(rehydratedState));
                return rehydratedState;
            }

            if (updateOnActions.includes(action.type)) {
                persist.rehydrated = false;
                let stateToSave = nextState;
                if (storeKeys?.length) {
                    stateToSave = pick(nextState, [...storeKeys, '_persist']);
                }

                if (migrationConfig.blackList?.length) {
                    stateToSave = omit(stateToSave, migrationConfig.blackList);
                }
                saveData(localStorageKey, serializeData(stateToSave));
                return { ...nextState, _persist: persist };
            }
            return nextState;
        };
    };
}

export function migrateState<T>(state, { version, migrate }: MigrationConfig<T>) {
    return migrate ? migrate(state as any, version || DEFAULT_VERSION) : state;
}

export function fetchData(key: string): unknown {
    let retrieveData = localStorage.getItem(key);
    if (retrieveData) {
        try {
            retrieveData = JSON.parse(retrieveData);
        } catch {
            localStorage.removeItem(key);
            return null;
        }
    }
    return retrieveData;
}

export function saveData(key: string, value: string): void {
    localStorage.setItem(key, value);
}

export function serializeData(value: unknown): string {
    return JSON.stringify(value);
}
