import { createAction, props } from '@ngrx/store';
import { OrgAssessmentSummarySections } from '../../../../admin/client-profile/client-profile.model';
import { UpdateAssessmentSummarySectionConfigRequest } from '../../../../shared/assessment-components/components/assessment-summary-config/model/assessment-summary-config-models';

export enum AssessmentSummaryConfigActions {
    GetRelationshipAssessmentSummaryConfigRequest = '[Request] Get Relationship Assessment Summary Config Request',
    GetRelationshipAssessmentSummaryConfigRequestSuccess = '[Request] Get Relationship Assessment Summary Config Request Success',
    GetRelationshipAssessmentSummaryConfigRequestFailed = '[Request] Get Relationship Assessment Summary Config Request Failed',
    UpdateRelationshipAssessmentSummaryConfigRequest = '[Request] Update Relationship Assessment Summary Config Request',
    UpdateRelationshipAssessmentSummaryConfigRequestSuccess = '[Request] Update Relationship Assessment Summary Config Request Success',
    UpdateRelationshipAssessmentSummaryConfigRequestFailed = '[Request] Update Relationship Assessment Summary Config Request Failed',
    DeleteRelationshipAssessmentSummaryConfigRequest = '[Request] Delete Relationship Assessment Summary Config Request',
    DeleteRelationshipAssessmentSummaryConfigRequestSuccess = '[Request] Delete Relationship Assessment Summary Config Request Success',
    DeleteRelationshipAssessmentSummaryConfigRequestFailed = '[Request] Delete Relationship Assessment Summary Config Request Failed',
}

export const getRelationshipAssessmentSummaryConfigRequest = createAction(
    AssessmentSummaryConfigActions.GetRelationshipAssessmentSummaryConfigRequest,
    props<{ relationshipId: number }>(),
);

export const getRelationshipAssessmentSummaryConfigRequestSuccess = createAction(
    AssessmentSummaryConfigActions.GetRelationshipAssessmentSummaryConfigRequestSuccess,
    props<{ assessmentSummarySections: OrgAssessmentSummarySections[] }>(),
);

export const getRelationshipAssessmentSummaryConfigRequestFailed = createAction(
    AssessmentSummaryConfigActions.GetRelationshipAssessmentSummaryConfigRequestFailed,
);

export const updateRelationshipAssessmentSummaryConfigRequest = createAction(
    AssessmentSummaryConfigActions.UpdateRelationshipAssessmentSummaryConfigRequest,
    props<{
        relationshipId: number;
        updateAssessmentSummarySectionConfigRequest: UpdateAssessmentSummarySectionConfigRequest;
    }>(),
);

export const updateRelationshipAssessmentSummaryConfigRequestSuccess = createAction(
    AssessmentSummaryConfigActions.UpdateRelationshipAssessmentSummaryConfigRequestSuccess,
);

export const updateRelationshipAssessmentSummaryConfigRequestFailed = createAction(
    AssessmentSummaryConfigActions.UpdateRelationshipAssessmentSummaryConfigRequestFailed,
);

export const deleteRelationshipAssessmentSummaryConfigRequest = createAction(
    AssessmentSummaryConfigActions.DeleteRelationshipAssessmentSummaryConfigRequest,
    props<{
        relationshipId: number;
    }>(),
);

export const deleteRelationshipAssessmentSummaryConfigRequestSuccess = createAction(
    AssessmentSummaryConfigActions.DeleteRelationshipAssessmentSummaryConfigRequestSuccess,
);

export const deleteRelationshipAssessmentSummaryConfigRequestFailed = createAction(
    AssessmentSummaryConfigActions.DeleteRelationshipAssessmentSummaryConfigRequestFailed,
);
