<div class="modal-header">
    <h4 class="modal-title">Are you sure you want to revoke this token?</h4>
    <button type="button" class="btn-close" data-dismiss="modal" aria-hidden="true" (click)="cancel()"></button>
</div>
<div class="modal-header mb-0">
    <p>Any applications or scripts using this token will no longer be able to access the VISO TRUST API.</p>
</div>
<div class="d-block justify-content-start main-action m-3">
    <button
        mat-button
        visoButton="warning"
        class="float-end mb-3"
        toggleDisableOnActions
        [disablingActions]="[UserProfileActions.RevokeApiTokenRequest]"
        [enablingActions]="[
            UserProfileActions.RevokeApiTokenRequestSuccess,
            UserProfileActions.RevokeApiTokenRequestFailed,
        ]"
        (click)="revokeApiToken()"
    >
        <span>Revoke token</span>
    </button>
    <button
        type="button"
        mat-button
        visoButton="secondary"
        class="float-end mb-3 me-2"
        data-dismiss="modal"
        (click)="cancel()"
    >
        <span>Cancel</span>
    </button>
</div>
