import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Subject, Observable } from 'rxjs';
import { takeUntil, tap, map, take, shareReplay } from 'rxjs/operators';
import {
    deleteBusinessUnitRequest,
    deleteBusinessUnitRequestFailed,
    deleteBusinessUnitRequestSuccess,
    reassignBusinessUnitRequest,
    reassignBusinessUnitRequestFailed,
    reassignBusinessUnitRequestSuccess,
    getBusinessUnitsForManagementRequestSuccess,
    getBusinessUnitsForManagementRequest,
} from '../business-unit-management/redux/business-unit-management.actions';
import { RefSelectOption } from '../../shared/model/select-options';
import { BusinessUnitManagementView } from '../../entities/business-unit';

@Component({
    selector: 'app-business-unit-delete-dialog',
    templateUrl: './business-unit-delete-dialog.component.html',
    styleUrls: ['./business-unit-delete-dialog.component.scss'],
})
export class BusinessUnitDeleteDialogComponent implements OnInit, OnDestroy {
    @Input()
    businessUnit: BusinessUnitManagementView;

    businessUnits$: Observable<BusinessUnitManagementView[]>;
    businessUnitSelectOptions$: Observable<RefSelectOption<BusinessUnitManagementView | number>[]>;

    businessUnitReassignFormGroup: UntypedFormGroup;
    deleting: boolean;

    private _unsub = new Subject<void>();

    constructor(
        private _actions$: Actions,
        private _store$: Store,
        private _fb: UntypedFormBuilder,
        private _activeModal: NgbActiveModal,
    ) {}

    ngOnInit(): void {
        this.businessUnitReassignFormGroup = this._fb.group({
            reassignFromName: [''],
            reassignToId: ['', Validators.required],
        });

        this._actions$
            .pipe(
                ofType(reassignBusinessUnitRequestSuccess),
                tap(() => this._store$.dispatch(deleteBusinessUnitRequest({ businessUnitId: this.businessUnit.id }))),
                takeUntil(this._unsub),
            )
            .subscribe();

        this._actions$
            .pipe(
                ofType(deleteBusinessUnitRequestSuccess),
                tap(() => this.clear()),
                takeUntil(this._unsub),
            )
            .subscribe();

        this._actions$
            .pipe(
                ofType(
                    deleteBusinessUnitRequestSuccess,
                    deleteBusinessUnitRequestFailed,
                    reassignBusinessUnitRequestFailed,
                ),
                tap(() => (this.deleting = false)),
                takeUntil(this._unsub),
            )
            .subscribe();

        this._store$.dispatch(getBusinessUnitsForManagementRequest(null));

        this.businessUnits$ = this._actions$.pipe(
            ofType(getBusinessUnitsForManagementRequestSuccess),
            map(({ businessUnits }) => businessUnits),
            take(1),
            shareReplay(1),
        );

        this.businessUnitSelectOptions$ = this.businessUnits$.pipe(
            map((businessUnits) =>
                businessUnits.map<RefSelectOption<BusinessUnitManagementView>>((businessUnit) => ({
                    ref: businessUnit,
                    name: `${businessUnit.name}`,
                    id: `${businessUnit.id}`,
                })),
            ),
            map((selectOptions) => {
                let businessUnitList = [...selectOptions.filter((sub) => sub.ref.id !== this.businessUnit.id)];

                businessUnitList = businessUnitList.sort((a, b) =>
                    a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1,
                );

                return businessUnitList;
            }),
        );
    }

    clear() {
        this._activeModal.dismiss('cancel');
    }

    confirmDelete() {
        this.deleting = true;
        if (this.businessUnit.userCount === 0) {
            this._store$.dispatch(deleteBusinessUnitRequest({ businessUnitId: this.businessUnit.id }));
        } else {
            this._store$.dispatch(
                reassignBusinessUnitRequest({
                    fromBusinessUnitId: this.businessUnit.id,
                    toBusinessUnitId: this.businessUnitReassignFormGroup.controls.reassignToId.value,
                }),
            );
        }
    }

    ngOnDestroy(): void {
        this._unsub.next();
    }
}
