import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class HubspotManagementService {
    private _hubspotResource = 'api/hubspot';

    constructor(private _http: HttpClient) {}

    syncCompaniesWithHubspot(): Observable<string> {
        return this._http.get(`${this._hubspotResource}/companies`, { responseType: 'text' });
    }

    syncContactsWithHubspot(): Observable<string> {
        return this._http.get(`${this._hubspotResource}/contacts`, { responseType: 'text' });
    }
}
