import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FastMention, QuillMentionListItem } from '../../../../shared/components/quill-wrapper/quill.model';
import { VisoUserRole } from '../../../../entities/viso-user';
import { noWhitespaceValidator } from '../../../../shared/validators/whitespace-validator';

@Component({
    selector: 'app-create-comment',
    templateUrl: './create-comment.component.html',
    styleUrls: ['./create-comment.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateCommentComponent implements OnInit {
    @Input()
    set cleanForm(value: void) {
        this.commentFormControl.reset('');
    }

    @Input()
    mentions: QuillMentionListItem[];

    @Input()
    fastMentions: FastMention[];

    @Output()
    createComment = new EventEmitter<string>();

    Roles = VisoUserRole;

    commentFormControl = new UntypedFormControl('', [Validators.required, noWhitespaceValidator]);

    isFormControlInvalid$: Observable<boolean>;

    ngOnInit(): void {
        this.isFormControlInvalid$ = this.commentFormControl.valueChanges.pipe(
            map(
                (value) =>
                    this.commentFormControl.invalid || value.match(/^ *$/) !== null || this.isOnlyWhitespace(value),
            ),
        );
    }

    isFormControlInvalid() {
        return this.commentFormControl.invalid || this.commentFormControl.value.match(/^ *$/) !== null;
    }

    addComment() {
        this.createComment.emit(this.commentFormControl.value);
    }

    isOnlyWhitespace(value: String) {
        let updatedValue = value.replace(/(<([^>]+)>)/gi, '');
        return updatedValue.trim().length === 0;
    }
}
