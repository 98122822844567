import { Routes } from '@angular/router';

import { AuthGuard } from '../../shared';
import { BusinessUnitPopupComponent } from './business-unit-popup.component';
import { VisoUserRole } from '../../entities/viso-user';

export const businessUnitPopupRoute: Routes = [
    {
        path: 'business-unit-new',
        component: BusinessUnitPopupComponent,
        data: {
            authorities: [VisoUserRole.OrgAdmin, VisoUserRole.OrgUser],
            pageTitle: 'BusinessUnits',
        },
        canActivate: [AuthGuard],
        outlet: 'popup',
    },
    {
        path: 'business-unit/:id/edit',
        component: BusinessUnitPopupComponent,
        data: {
            authorities: [VisoUserRole.OrgAdmin, VisoUserRole.OrgUser],
            pageTitle: 'BusinessUnits',
        },
        canActivate: [AuthGuard],
        outlet: 'popup',
    },
    {
        path: 'business-unit/:id/transfer',
        data: {
            authorities: [VisoUserRole.OrgAdmin, VisoUserRole.OrgUser],
            pageTitle: 'BusinessUnits',
        },
        children: [],
        canActivate: [AuthGuard],
        outlet: 'popup',
    },
];
