import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Store, select } from '@ngrx/store';
import { Subject } from 'rxjs';
import { startWith, takeUntil } from 'rxjs/operators';
import { FormUtilsService } from '../../../shared/utils/form-utils.service';
import { noWhitespaceValidator } from '../../../shared/validators/whitespace-validator';
import { PrimaryVendorContact } from '../../primary-vendor-contact';
import { getRelationshipSuggestedContacts } from '../../request/redux/request.selectors';
import { SuggestedContact } from '../../../entities/relationship';

type SuggestedContactOption = {
    id: SuggestedContact;
    text: string;
};

enum ContactType {
    SUGGESTED = 'suggested',
    PROVIDE_DETAILS = 'provideDetails',
    REMOVE_CONTACT = 'removeContact',
}

@Component({
    selector: 'app-third-party-contact-form',
    templateUrl: 'third-party-contact-form.component.html',
    styleUrls: ['third-party-contact-form.component.scss'],
})
export class ThirdPartyContactFormComponent implements OnInit, OnDestroy {
    @Input() requestId: number;
    @Input() formId: string = 'thirdPartyContactForm';
    @Input() primaryVendorContact: PrimaryVendorContact;
    @Input() includeRemoveOption: boolean;

    @Output() formValueChange = new EventEmitter<PrimaryVendorContact>();
    @Output() formStatusChange = new EventEmitter<string>();
    @Output() submitForm = new EventEmitter<void>();
    @Output() removeContact = new EventEmitter<void>();

    private _unsub$ = new Subject<void>();

    get isFormInvalid(): boolean {
        return this.thirdPartyContactFormGroup.invalid;
    }

    get showFirstNameLengthError(): boolean {
        return !!this.thirdPartyContactFormGroup.controls.firstName.errors?.maxlength;
    }

    get showLastNameLengthError(): boolean {
        return !!this.thirdPartyContactFormGroup.controls.lastName.errors?.maxlength;
    }

    get showEmailLengthError(): boolean {
        return !!this.thirdPartyContactFormGroup.controls.email.errors?.maxlength;
    }

    get showInvalidEmailSyntaxError(): boolean {
        return !!this.thirdPartyContactFormGroup.controls.email.errors?.email;
    }

    thirdPartyContactFormGroup: UntypedFormGroup;
    suggestedContacts: SuggestedContactOption[];
    suggestedContact: SuggestedContact | undefined;
    contactType: ContactType;
    constructor(
        private _fb: UntypedFormBuilder,
        private _formUtilsService: FormUtilsService,
        private _store$: Store,
    ) {}

    /**
     * Cases:
     *
     * has >= 1 suggested contacts, primary is suggested (edit case)
     *  - starts on suggested w/ suggested contact matching primary selected
     *
     * has >= 1 suggested contacts, primary is not suggested (edit case)
     *  - starts on provideDetails w/ primary details pre-filled
     *
     * has >= 1 suggested contacts, has no primary (new case)
     *  - starts on suggested with first suggestion selected
     *
     * has no suggested contact, has primary (edit case)
     *  - starts on provideDetails w/ primary details set
     *
     * has no suggested contact, has no primary (new case)
     *  - starts on provideDetails w/ empty details, untouched state
     */
    ngOnInit() {
        this._store$
            .pipe(select(getRelationshipSuggestedContacts), takeUntil(this._unsub$))
            .subscribe((suggestedContacts) => {
                this.suggestedContacts = suggestedContacts.map((contact) => ({
                    id: contact,
                    text: `${contact.firstName} ${contact.lastName} • ${contact.email}`,
                }));
            });

        this.suggestedContact = this.getDefaultSuggestedContact();
        this.contactType = this.getDefaultContactType(!!this.suggestedContact);
        const { defaultFirstName, defaultLastName, defaultEmail } = this.getFormDefaults(
            this.primaryVendorContact,
            this.suggestedContact,
        );

        this.thirdPartyContactFormGroup = this._fb.group({
            firstName: [
                defaultFirstName,
                Validators.compose([Validators.required, noWhitespaceValidator, Validators.maxLength(255)]),
            ],
            lastName: [
                defaultLastName,
                Validators.compose([Validators.required, noWhitespaceValidator, Validators.maxLength(255)]),
            ],
            email: [
                defaultEmail,
                Validators.compose([Validators.required, Validators.email, Validators.maxLength(255)]),
            ],
            relationshipId: [this.requestId],
        });

        this.formStatusChange.emit(this.thirdPartyContactFormGroup.status);

        this.thirdPartyContactFormGroup.valueChanges
            .pipe(takeUntil(this._unsub$), startWith(this.thirdPartyContactFormGroup.value))
            .subscribe((_) =>
                this.formValueChange.emit(
                    this._formUtilsService.getCleanFormGroupValue<PrimaryVendorContact>(
                        this.thirdPartyContactFormGroup,
                    ),
                ),
            );

        this.thirdPartyContactFormGroup.statusChanges.pipe(takeUntil(this._unsub$)).subscribe((status) => {
            this.formStatusChange.emit(status);
        });
    }

    private getFormDefaults(primaryVendorContact?: PrimaryVendorContact, suggestedContact?: SuggestedContact) {
        const {
            firstName: defaultFirstName = '',
            lastName: defaultLastName = '',
            email: defaultEmail = '',
        } = primaryVendorContact || suggestedContact || {};

        return {
            defaultFirstName,
            defaultLastName,
            defaultEmail,
        };
    }

    private getDefaultContactType(isContactSuggested: boolean) {
        const defaultContactType = isContactSuggested ? ContactType.SUGGESTED : ContactType.PROVIDE_DETAILS;
        return defaultContactType;
    }

    /**
     *
     * @returns The initial suggested contact.
     *
     * If there's no existing primary contact, this returns the first suggested contact option if present, otherwise undefined.
     *
     * If there's a primary contact, this returns the matching suggested contact if present, otherwise undefined.
     */
    private getDefaultSuggestedContact(): SuggestedContact | undefined {
        if (!this.primaryVendorContact) {
            return this.suggestedContacts[0]?.id;
        }
        return this.suggestedContacts.find(({ id }) => this.isPrimaryVendorContactSuggestedContact(id))?.id;
    }

    private isPrimaryVendorContactSuggestedContact(suggestedContact?: SuggestedContact) {
        if (!this.primaryVendorContact) {
            return false;
        }
        const { email, firstName, lastName } = suggestedContact || {};
        return (
            this.primaryVendorContact.email === email &&
            this.primaryVendorContact.firstName === firstName &&
            this.primaryVendorContact.lastName === lastName
        );
    }

    setThirdPartyContactDetails(newContact?: SuggestedContact | PrimaryVendorContact) {
        const { firstName = '', lastName = '', email = '' } = newContact || {};
        this.thirdPartyContactFormGroup.patchValue({
            firstName,
            lastName,
            email,
        });
    }

    contactTypeChanged(contactType: ContactType) {
        if (contactType === ContactType.REMOVE_CONTACT) {
            // Always allow removing a contact by setting the parent's status to valid.
            this.formStatusChange.emit('VALID');
            return;
        }

        if (contactType === ContactType.PROVIDE_DETAILS) {
            // If primary contact is present, reset fields to match primary contact (as long as they're not the suggested contact)
            // When primary contact not present or present but suggested, reset fields to empty to allow easier editing
            if (this.primaryVendorContact && !this.isPrimaryVendorContactSuggestedContact(this.suggestedContact)) {
                this.setThirdPartyContactDetails(this.primaryVendorContact);
            } else {
                this.setThirdPartyContactDetails();
            }
        } else if (contactType === ContactType.SUGGESTED) {
            if (this.suggestedContact) {
                this.setThirdPartyContactDetails(this.suggestedContact);
            }
        }

        // Reset the parent's status to the actual form status when going to
        // any section other than removeDetails
        this.formStatusChange.emit(this.thirdPartyContactFormGroup.status);
    }

    ngOnDestroy(): void {
        this._unsub$.next();
        this._unsub$.complete();
    }

    submit() {
        if (this.contactType === ContactType.REMOVE_CONTACT) {
            this.removeContact.emit();
        } else {
            this.submitForm.emit();
        }
    }
}
