import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { VisoUserRole } from '../../entities/viso-user';
import { Actions, ofType } from '@ngrx/effects';
import { tap, takeUntil, filter, map, take } from 'rxjs/operators';
import {
    createBusinessUnitRequestSuccess,
    deleteBusinessUnitRequestSuccess,
    openDeleteBusinessUnitConfirmationModal,
    reassignBusinessUnitRequestSuccess,
    updateBusinessUnitRequestSuccess,
    getBusinessUnitsForManagementRequestSuccess,
    getBusinessUnitsForManagementRequestFailed,
    getBusinessUnitsForManagementRequest,
} from './redux/business-unit-management.actions';
import { Store } from '@ngrx/store';
import { BusinessUnitManagementView } from '../../entities/business-unit';
import { HttpHeaders } from '@angular/common/http';
import { BREADCRUMB_CONTAINER_TOKEN } from '../../shared/dynamic-content/dynamic-content-injector';
interface TableSortItem {
    prop: string;
    dir: string;
}

@Component({
    selector: 'app-business-unit-management',
    templateUrl: './business-unit-management.component.html',
    styleUrls: ['./business-unit-management.component.scss'],
})
export class BusinessUnitManagementComponent implements OnInit, OnDestroy {
    public readonly breadcrumbsContainerToken = BREADCRUMB_CONTAINER_TOKEN;
    @ViewChild('businessUnitList') businessUnitList: any;

    Roles = VisoUserRole;

    businessUnits$ = new BehaviorSubject<BusinessUnitManagementView[]>([]);
    error: any;
    success: any;
    links: any;
    totalItems: any;
    queryCount: any;
    itemsPerPage: any;
    page: any;
    predicate: any;
    previousPage: any;
    reverse: any;
    numberOfBusinessUnitsHeader$ = new BehaviorSubject<string>('0 Business Units');

    tableSort$: BehaviorSubject<TableSortItem[]> = new BehaviorSubject<TableSortItem[]>([{ prop: 'name', dir: 'asc' }]);

    private _unsub = new Subject<void>();

    constructor(
        private _router: Router,
        private _store$: Store,
        private _actions$: Actions,
        private _route: ActivatedRoute,
    ) {
        this.itemsPerPage = 20;
        this.page = 0;
    }

    ngOnInit() {
        this._actions$
            .pipe(
                ofType(
                    createBusinessUnitRequestSuccess,
                    updateBusinessUnitRequestSuccess,
                    deleteBusinessUnitRequestSuccess,
                    reassignBusinessUnitRequestSuccess,
                ),
                tap(() => {
                    this.loadAll();
                }),
                takeUntil(this._unsub),
            )
            .subscribe();

        this._actions$
            .pipe(
                ofType(getBusinessUnitsForManagementRequestSuccess, getBusinessUnitsForManagementRequestFailed),
                takeUntil(this._unsub),
            )
            .subscribe();

        this._actions$
            .pipe(
                ofType(getBusinessUnitsForManagementRequestSuccess),
                filter(({ headers }) => !!headers.get('x-total-count')),
                takeUntil(this._unsub),
            )
            .subscribe(({ businessUnits, headers }) => {
                const totalBusinessUnitsCount = +headers.get('x-total-count');
                this.numberOfBusinessUnitsHeader$.next(
                    `${totalBusinessUnitsCount} ${totalBusinessUnitsCount == 1 ? 'Business Unit' : 'Business Units'} `,
                );
                this.onSuccess(businessUnits, headers);
            });

        this.tableSort$
            .pipe(
                map((sortData) => sortData && sortData[0]),
                takeUntil(this._unsub),
            )
            .subscribe((sortItem) => {
                this.predicate = sortItem.prop;
                this.reverse = sortItem.dir !== 'desc';
            });

        this._route.queryParams.pipe(take(1)).subscribe(({ sort, page }) => {
            const sortPredicateFromUrl: string = !!sort ? (Array.isArray(sort) ? sort[0] : sort) : undefined;

            if (sortPredicateFromUrl) {
                const [prop, dir] = sortPredicateFromUrl.split(',');
                this.tableSort$.next([{ prop, dir }]);
            }

            this.page = +page || this.page;
        });

        this.loadAll();
    }

    loadAll() {
        this._store$.dispatch(
            getBusinessUnitsForManagementRequest({
                req: {
                    page: this.page,
                    size: this.itemsPerPage,
                    sort: this.sort(),
                },
            }),
        );
    }

    loadPage(page: number) {
        if (page !== this.previousPage) {
            this.previousPage = page;
            this.transition();
        }
    }

    transition() {
        this._router.navigate(['/business-units'], {
            queryParams: {
                page: this.page,
                size: this.itemsPerPage,
                sort: this.predicate + ',' + (this.reverse ? 'asc' : 'desc'),
            },
        });
        this.loadAll();
    }

    clear() {
        this.page = 0;
        this._router.navigate([
            '/business-units',
            {
                page: this.page,
                sort: this.predicate + ',' + (this.reverse ? 'asc' : 'desc'),
            },
        ]);
        this.loadAll();
    }

    trackId(index: number, item: BusinessUnitManagementView) {
        return item.id;
    }

    onSort({ sorts }) {
        this.predicate = sorts[0].prop;
        this.reverse = sorts[0].dir !== 'desc';
        this.businessUnitList.offset = this.page;
        this._router.navigate([], {
            relativeTo: this._route,
            queryParams: { sort: `${this.predicate},${sorts[0].dir}` },
            queryParamsHandling: 'merge',
        });
        this.loadAll();
    }

    setCurrentPage({ page }) {
        this.page = +page - 1;
        this._router.navigate([], {
            relativeTo: this._route,
            queryParams: { page: this.page },
            queryParamsHandling: 'merge',
        });
        this.loadAll();
    }

    deleteBusinessUnit(businessUnit: BusinessUnitManagementView) {
        this.clear();
        setTimeout(
            () => this._store$.dispatch(openDeleteBusinessUnitConfirmationModal({ businessUnit: businessUnit })),
            50,
        );
    }

    private onSuccess(data: BusinessUnitManagementView[], headers: HttpHeaders): void {
        this.totalItems = +headers.get('x-total-count');
        this.queryCount = data.length;

        this.businessUnits$.next(data);
    }

    private sort() {
        if (this.predicate === 'name') {
            this.predicate = 'name';
        } else if (this.predicate === 'users') {
            this.predicate = 'userCount';
        } else if (this.predicate === 'relationships') {
            this.predicate = 'relationshipCount';
        }
        const result = [this.predicate + ',' + (this.reverse ? 'asc' : 'desc')];
        if (this.predicate !== 'id') {
            result.push('id');
        }
        return result;
    }

    ngOnDestroy(): void {
        this._unsub.next();
    }
}
