<h2 mat-dialog-title>
    <span>{{ isEditing ? 'Update' : 'Create' }} risk advisory for {{ name }}</span>
</h2>
<mat-dialog-content>
    <div *ngIf="!isEditing">
        <strong>By publishing this risk advisory, you will send an alert to every client of VISO TRUST.</strong>
        <strong class="d-block">Please exercise caution.</strong>
    </div>
    <div class="mt-4">
        <form [formGroup]="riskAdvisoryFormGroup">
            <div class="row">
                <div class="col">
                    <div class="form-group">
                        <label>Please provide the URL artifact for the advisory</label>
                        <mat-form-field class="d-block">
                            <input matInput id="url" formControlName="url" placeholder="URL artifact" />
                        </mat-form-field>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-6">
                    <div class="form-group">
                        <label>Select the type of advisory</label>
                        <mat-form-field class="d-block">
                            <mat-select formControlName="type" placeholder="Type of advisory">
                                <mat-option
                                    *ngFor="
                                        let riskAdvisoryType of RiskAdvisoryType | enumToArray: RiskAdvisoryTypeLabels
                                    "
                                    [value]="riskAdvisoryType.value"
                                    >{{ riskAdvisoryType.label }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col">
                    <div class="form-group">
                        <label class="mb-0">Please select the date for this notice</label>
                        <span class="d-block mb-2"
                            >This date should reflect the date from the URL artifact. Only advisories from the past 30
                            calendar days will generate a notification.</span
                        >
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-6">
                    <div class="form-group">
                        <mat-form-field class="w-100">
                            <input
                                matInput
                                [max]="maxDateAllowed"
                                [matDatepicker]="publishedDatePicker"
                                formControlName="publishedDate"
                                placeholder="yyyy-mm-dd"
                            />
                            <mat-datepicker-toggle matIconSuffix [for]="publishedDatePicker"></mat-datepicker-toggle>
                            <mat-datepicker #publishedDatePicker></mat-datepicker>
                        </mat-form-field>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-4">
                    <div class="form-group">
                        <button
                            mat-stroked-button
                            color="secondary"
                            [disabled]="riskAdvisoryFormGroup.controls.url.invalid"
                            (click)="summarizeRiskAdvisoryLink()"
                        >
                            <mat-icon>auto_fix_high</mat-icon>
                            Generate with AI
                        </button>
                    </div>
                </div>
                <div class="col-md-8 column-gap-0">
                    <mat-progress-bar *ngIf="isSummarizing" mode="indeterminate"></mat-progress-bar>
                </div>
            </div>

            <div class="row">
                <div class="col">
                    <div class="form-group mb-0">
                        <label>Please create a title and description for this advisory</label>
                        <mat-form-field class="d-block">
                            <input matInput id="title" formControlName="title" placeholder="Advisory title" />
                        </mat-form-field>
                        <app-viso-editor
                            class="d-block"
                            [height]="225"
                            [placeholder]="'Advisory description (optional)'"
                            [control]="riskAdvisoryFormGroup.controls.description"
                        />
                    </div>
                </div>
            </div>
        </form>
    </div>
</mat-dialog-content>
<mat-dialog-actions class="d-flex justify-content-between">
    <button mat-button color="warn" visoButton="text" type="button" mat-dialog-close>Cancel</button>
    <button
        *ngIf="!isEditing"
        type="submit"
        mat-button
        visoButton="warning"
        [disabled]="riskAdvisoryFormGroup.invalid || isSummarizing"
        (click)="createRiskAdvisory()"
    >
        <mat-icon>notifications</mat-icon>
        Create advisory
    </button>
    <button
        *ngIf="isEditing"
        type="submit"
        mat-button
        visoButton="warning"
        [disabled]="riskAdvisoryFormGroup.invalid || isSummarizing"
        (click)="editRiskAdvisory()"
    >
        <mat-icon>notifications</mat-icon>
        Update advisory
    </button>
</mat-dialog-actions>
