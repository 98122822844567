import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Email } from '../../../../entities/email';
import { VisoUser } from '../../../../entities/viso-user';
import { NgbModalWrapperService } from '../../../../shared';
import { openAssessmentEmailView } from '../../../../routes/assessment-email-viewer/redux/assessment-email-viewer.actions';
import { Store } from '@ngrx/store';
import { OpenAssessmentEmailViewParams } from '../../../../entities/assessment';
import moment from 'moment';

enum EmailTypeMapper {
    INTERNAL = 'Internal',
    EXTERNAL = 'External',
    VISO = 'Viso',
}

@Component({
    selector: 'app-assessment-email-history',
    templateUrl: './assessment-email-history.component.html',
    styleUrls: ['./assessment-email-history.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssessmentEmailHistoryComponent {
    @Input({ required: true })
    assessmentEmails: Email[];

    @Input({ required: true })
    currentUser: VisoUser;

    assessmentEmailViewParams: OpenAssessmentEmailViewParams;

    formatTemplateRegex = /\b.\b/gi;

    constructor(
        private _modalService: NgbModalWrapperService,
        private _store$: Store,
    ) {}

    openBounceDetails(email: Email) {
        this._modalService.open<EmailDetailsContent>(EmailDetailsContent, {
            details: email.bounce,
            title: 'Bounce Details',
        });
    }

    showViewEmailButton(email: Email) {
        // This is a temporary fix to prevent emails from being viewed prior to the retention history update on 4/5/2024
        // Retention was originally set to 45 days and was updated to 365 days on 4/5/2024
        // Remove this check after 4/5/2025
        const earliestEmailInPostmark = moment().set({ year: 2024, month: 4, date: 5 }).subtract(45, 'days');

        const basePostmarkRetentionPeriod = moment().subtract(365, 'days');
        return (
            earliestEmailInPostmark.isBefore(email.createdDate) &&
            basePostmarkRetentionPeriod.isBefore(email.createdDate)
        );
    }

    emailTypeClass(email: Email) {
        const type = this.getEmailType(email);
        switch (type) {
            case EmailTypeMapper.INTERNAL: {
                return 'badge badge-square text-bg-primary';
            }
            case EmailTypeMapper.EXTERNAL: {
                return 'badge badge-square text-bg-secondary';
            }
            default: {
                return 'badge badge-square text-bg-info';
            }
        }
    }

    getEmailType(email: Email) {
        const domains = this.currentUser.orgDomains;
        const targetDomain = email.targetEmail.split('@').pop();

        if (!domains.includes(targetDomain)) {
            return EmailTypeMapper.EXTERNAL;
        }
        return EmailTypeMapper.INTERNAL;
    }

    viewEmailDetails(email: Email) {
        this.assessmentEmailViewParams = {
            assessmentId: Number(email.recordId),
            messageId: email.externalMessageId,
        };

        this._store$.dispatch(openAssessmentEmailView(this.assessmentEmailViewParams));
    }
}

@Component({
    selector: 'email-details-content',
    template: `
        <div class="modal-header">
            <h4 class="modal-title">Email Details</h4>
            <button mat-button type="button" visoButton="secondary" (click)="activeModal.dismiss('Close click')">
                Close
            </button>
        </div>
        <div class="modal-body">
            <pre>{{ details | json }}</pre>
        </div>
    `,
})
export class EmailDetailsContent {
    @Input() details: object;
    @Input() title: string;

    constructor(public activeModal: NgbActiveModal) {}
}
