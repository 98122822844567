import { Directive, Output, EventEmitter, HostBinding, HostListener } from '@angular/core';

@Directive({
    selector: '[appFileDragAndDrop]',
})
export class FileDragAndDropDirective {
    @Output() onFileDropped = new EventEmitter<any>();
    @HostBinding('style.opacity') public opacity = '1';

    @HostListener('dragover', ['$event']) onDragOver(event: DragEvent) {
        event.preventDefault();
        event.stopPropagation();
        this.opacity = '0.5';
    }

    @HostListener('dragleave', ['$event']) public onDragLeave(event: DragEvent) {
        event.preventDefault();
        event.stopPropagation();
        this.opacity = '1';
    }

    @HostListener('drop', ['$event']) public ondrop(event: DragEvent) {
        event.preventDefault();
        event.stopPropagation();
        this.opacity = '1';
        let files = event.dataTransfer.files;
        if (files.length > 0) {
            this.onFileDropped.emit(files);
        }
    }

    constructor() {}
}
